import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import ShopListTable from "../../shared/components/ShopListTable";
import Select from "react-select";
import * as branchOptionsSelector from "../../shared/selector/branchOptionsSelector";
import * as shopActions from "../../shared/actions/shopActions";
// import CreateUserFormSignUp from '../../pages/SignUp/CreateUserForm'
// import BranchDetailModal from '../../shared/components/BranchDetailModal'
import moment from "moment";
import swal from "sweetalert";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import * as Amplify from "../../shared/awsAPIs/Amplify";

class InventoryShops extends React.Component {
  state = {
    shopList: [],
    selectedBranch: { value: "all", label: "All" },
    signUp: {
      shopId: "",
      timestamp: moment().toISOString()
    }
  };

  componentDidMount = () => {
    this.setShopList("all");
  };

  setShopList = filterBranchUid => {
    const { inventoryLink } = this.props;

    let shopList = [];

    if (filterBranchUid === "all") {
      shopList = inventoryLink;
    } else {
      shopList = _.filter(inventoryLink, i => i.uid === filterBranchUid);
    }

    this.setState({ shopList });
  };

  handleChangeBranch = selectedBranch => {
    this.setShopList(selectedBranch.value);
    this.setState({ selectedBranch });
  };

  redirectToSignUp = () => {
    const { loggedIn } = this.props;
    window.location.href = `/signup/${loggedIn.shopId}`;
    // this.setState({
    //     signUp: {
    //         timestamp: moment().toISOString(),
    //         shopId: loggedIn.shopId
    //     }
    // })
  };

  deleteShopMain = async shop => {
    let res = window.prompt(`คุณต้องการลบ "${shop.name}" ? กรุณากรอก รหัสสาขา`);
    if (res !== shop.branchId) return;
    this.deleteUser(shop);
    await this.deleteShop(shop);
    swal("สำเร็จ", `ลบ "${shop.name}" สำเร็จ`, "success");
    this.props.actions.fetchShopList(() => {
      this.handleChangeBranch({ value: "all", label: "All" });
    });
  };

  deleteShop = shop => {
    var params = {
      TableName: "Minor_Shops",
      Key: {
        uid: shop.uid
      }
    };
    return documentClient.delete(params).promise();
  };

  deleteUser = async shop => {
    var params = {
      TableName: "Minor_Users",
      IndexName: "shopId-index",
      KeyConditionExpression: "shopId = :shopId",
      ExpressionAttributeValues: {
        ":shopId": shop.uid
      }
    };

    let users = await documentClient.query(params).promise();

    _.forEach(users.Items, async item => {
      var params = {
        TableName: "Minor_Users",
        Key: {
          username: item.username
        }
      };
      this.deleteUserAuth(item.username);
      await documentClient.delete(params).promise();
    });
  };

  deleteUserAuth = username => {
    Amplify.deleteUser(username);
  };

  render() {
    const { match, branchOptions } = this.props;
    const { shopList, selectedBranch, signUp } = this.state;
    return (
      <div>
        {/* <BranchDetailModal signUp={signUp} /> */}
        <div
          className="col-lg-10 offset-lg-1 col-md-12 "
          style={{ padding: 0 }}
        >
          <div className="card card-table">
            <div className="card-header">
              รายการสาขา 
              <div className="tools dropdown">
                <span
                  style={{ color: "blue" }}
                  onClick={() => this.redirectToSignUp()}
                  className="icon button mdi mdi-plus"
                />
              </div>
            </div>
            <div className="card-body">
              <div className="row m-0 mb-1">
                <div className="col-md-4 p-0">
                  <Select
                    value={selectedBranch}
                    onChange={this.handleChangeBranch}
                    options={branchOptions}
                  /> 
                </div>
              </div>
              <ShopListTable
                {...this.props}
                shops={shopList}
                match={match}
                deleteShop={this.deleteShopMain}
                fetchShop={() => this.setShopList(selectedBranch.value)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let branchOptions = [{ value: "all", label: "All" }];
  branchOptions = [
    ...branchOptions,
    ...branchOptionsSelector.branchOptionsSelector(state)
  ];

  return {
    inventoryLink: state.shop.list.inventory,
    loggedIn: state.user.loggedIn,
    branchOptions
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...shopActions }, dispatch);
  return {
    actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryShops);
