import React, { useState } from "react";
import useDrawerReducer from "../hook.drawer.reducer";
import _ from "lodash";
import ReceiptRateCard from "../../SpaReport/ReceiptRateCard";

function DashboardSatRate({ receipts }) {
  return (
    <div class=' pt-5 pb-7' style={{backgroundColor : '#eee'}}>
       <div class='offset-3 col-6'>
       {_.map(receipts, rec => {
        return <ReceiptRateCard receipt={rec} showUncompletedSatRate={true}/>;
      })}
           </div> 
     
    </div>
  );
}

export default DashboardSatRate;
