import React, { useState, useEffect } from "react";
import countryOptions from "../../../shared/exampleData/countryOptions";
import _ from "lodash";
import moment from "moment";
import swal from "sweetalert";
import documentClient from "../../../shared/awsAPIs/dynamoDb";
import "react-intl-tel-input/dist/main.css";
import { makeid } from "../../PosCart/actions";
import MobileForm from "./MobileForm";
import DesktopForm from "./DesktopForm";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const mobileInitData = {
  status: false,
  inputNumber: "",
  computedNumber: "",
  countryData: {
    areaCodes: null,
    dialCode: "66",
    iso2: "th",
    name: "Thailand (ไทย)",
    priority: 0,
  },
};

function ManageCustomerInfo(props) {
  const { initData } = props;

  const [gender, setGender] = useState(null);
  const [mobile, setMobile] = useState(mobileInitData);
  const [country, setCountry] = useState({
    value: "Thailand",
    label: "Thailand",
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [mode, setMode] = useState("create");
  const [expireDate, setExpireDate] = useState(null); //new Date()
  const [friendList, setFriendlist] = useState([]);
  const [tags, setTags] = useState([]);
  const [businessUnit, setBusinessUnit] = useState("spa");

  const [cardId, setCardId] = useState("");
  const [memberId, setMemberId] = useState("");

  const startYear = getCurrentYearInt();

  useEffect(() => {
    clearData();
    setTimeout(() => {
      if (initData) {
        //EDIT
        setMode("edit");
        setInitData();
      } else {
        //CREATE
        if (props.isRetail) {
          setBusinessUnit("retail"); 
        }
      }
    }, 200);
  }, [props.timestamp]);

  const setInitData = () => {
    initData.gender && setGender(initData.gender);
    initData.mobileLocal &&
      setMobile({ ...mobile, inputNumber: initData.mobileLocal, status: true });
    initData.mobileData && setMobile(initData.mobileData);
    initData.country &&
      setCountry({ value: initData.country, label: initData.country });
    initData.name && setName(initData.name);
    initData.email && setEmail(initData.email);
    initData.cardId && setCardId(initData.cardId);
    initData.memberId && setMemberId(initData.memberId);
    initData.expireDate && setExpireDate(new Date(initData.expireDate));
    initData.friendList && setFriendlist(initData.friendList);
    initData.tags && setTags(initData.tags);
    initData.businessUnit && setBusinessUnit(initData.businessUnit);

    if (initData.birthDate) {
      let splited = _.split(initData.birthDate, "-");
      setDate(splited[2]);
      setMonth(splited[1]);
      setYear(splited[0]);
    }
  };
  const clearData = () => {
    setGender(null);
    setMode("create");
    setMobile(mobileInitData);
    setCountry({
      value: "Thailand",
      label: "Thailand",
    });
    setName("");
    setEmail("");
    setDate("");
    setMonth("");
    setYear("");
    setFriendlist([]);
    setExpireDate(null);
    setTags([]);
  };

  const handleInputMobile = (status, value, countryData, number) => {
    setMobile({
      status,
      inputNumber: value,
      computedNumber: number,
      countryData,
    });
  };
  const handleSelectMobileCountry = (
    inputNumber,
    countryData,
    computedNumber,
    status
  ) => {
    setMobile({
      status,
      inputNumber,
      computedNumber,
      countryData,
    });
  };
  const validatePhoneStatus = () => {
    if (mobile.inputNumber === "") {
      return false;
    }
    if (!mobile.status) {
      return true;
    }
  };
  function checkMemberIdExist() {
    let hqUid = props.linked || props.shop.uid;

    var params = {
      TableName: "Crm_Users",
      IndexName: "hqUid-memberId-index",
      KeyConditionExpression: "hqUid = :hqUid and memberId =:memberId",
      ExpressionAttributeValues: {
        ":hqUid": hqUid,
        ":memberId": memberId,
      },
    };
    return documentClient.query(params).promise();
  }
  function validate(mode) {
    return new Promise(async (resolve) => {
      // if (country === null) {
      //   return "Please select your country";
      // }
      // if (validatePhoneStatus()) {
      //   return "Wrong phone number format";
      // }
      // if (gender === null) {
      //   return "Please select your gender";
      // }
      if (name === "") {
        resolve("กรุณากรอกชื่อลูกค้า");
      }
      if (memberId === "") {
        resolve("กรุณากรอก Member ID");
      }

      let isDuplicateMemberId = false;

      if (
        mode === "create" ||
        (mode === "edit" && memberId != initData.memberId)
      ) {
        let res = await checkMemberIdExist();
        isDuplicateMemberId = res.Items;
      }

      if (isDuplicateMemberId && _.size(isDuplicateMemberId) > 0) {
        resolve("Member ID นี้ถูกใช้แล้ว");
      }

      if (mode === "edit" && !expireDate) {
        resolve("กรุณาระบุ Expire Date");
      }
      // if (email === "") {
      //   return "Please enter your email";
      // }
      // if (date === "") {
      //   return "Please select your birth date";
      // }
      // if (month === "") {
      //   return "Please select your birth month";
      // }
      // if (year === "") {
      //   return "Please select your birth year";
      // }
      resolve(null);
    });
  }
  const createCustomer = async () => {
    let isFailValidate = await validate("create");

    if (isFailValidate) {
      swal("เกิดข้อผิดพลาด", isFailValidate, "error");
      return;
    }

    let hqUid = props.linked || props.shop.uid;

    let data = {
      // uid: props.linked + "#" + deleteBlankSpace(mobile.computedNumber),
      uid: makeid(8),
      gender,
      businessUnit,
      birthDate: `${year}-${month}-${date}`,
      createAt: moment().toISOString(),
      date: moment().format("YYYY-MM-DD"),
      month: moment().format("YYYY-MM"),
      email: cutEmptyStr(email),
      cardId: cutEmptyStr(cardId),
      memberId: cutEmptyStr(memberId),
      mobile: cutEmptyStr(deleteBlankSpace(mobile.computedNumber)),
      mobileLocal: cutEmptyStr(mobile.inputNumber),
      mobileData: mobile.inputNumber !== "" ? mobile : null,
      country: country.label,
      name: name.trim(),
      hqUid,
    };

    //BUG PROTECT
    if (year === "" || month === "" || date === "") {
      delete data["birthDate"];
    }

    var params = {
      TableName: "Crm_Users",
      Item: data,
      // ConditionExpression: "attribute_not_exists(#uid)",
      // ExpressionAttributeNames: {
      //   "#uid": "uid"
      // }
    };
    try {
      await documentClient.put(params).promise();
      swal("Completed !", `${"Registration"} completed`, "success").then(() => {
        props.closeModal && props.closeModal();
        props.fetchCustomers && props.fetchCustomers();
        props.selectCustomer &&
          props.selectCustomer({
            ...data,
            value: data.uid,
            label: data.name,
          });
      });
    } catch (error) {
      // swal(
      //   "Failed !",
      //   `หมายเลข ${mobile.inputNumber} ได้ถูกใช้ลงทะเบียนแล้ว`,
      //   "error"
      // );
      console.log(error);
    }
  };
  const editCustomer = async () => {
    //VALIDATE
    let isFailValidate = await validate("edit");

    if (isFailValidate) {
      swal("เกิดข้อผิดพลาด", isFailValidate, "error");
      return;
    }

    //MOBILE BUG PROTECT
    if (mobile.inputNumber !== "" && mobile.computedNumber === "") {
      mobile.computedNumber = mobile.inputNumber;
    }

    var params = {
      TableName: "Crm_Users",
      Key: { uid: initData.uid },
      UpdateExpression:
        "set gender = :gender, email = :email, mobile = :mobile, mobileLocal = :mobileLocal, mobileData = :mobileData, country = :country, #name = :name , cardId =:cardId,memberId =:memberId, expireDate =:expireDate, friendList =:friendList,tags =:tags,businessUnit =:businessUnit",
      ExpressionAttributeNames: { "#name": "name" },
      ExpressionAttributeValues: {
        ":gender": gender,
        ":businessUnit": businessUnit,
        ":email": cutEmptyStr(email),
        ":expireDate": expireDate ? expireDate.toISOString() : null,
        ":cardId": cutEmptyStr(cardId),
        ":memberId": cutEmptyStr(memberId),
        ":mobile": cutEmptyStr(deleteBlankSpace(mobile.computedNumber)),
        ":mobileLocal": cutEmptyStr(mobile.inputNumber),
        ":mobileData": mobile.inputNumber !== "" ? mobile : null,
        ":country": country.label,
        ":name": name.trim(),
        ":friendList": friendList,
        ":tags": tags,
      },
    };

    if (year !== "" && month !== "" && date !== "") {
      params.UpdateExpression += ", birthDate = :birthDate";
      params.ExpressionAttributeValues[
        ":birthDate"
      ] = `${year}-${month}-${date}`;
    }

    try {
      await documentClient.update(params).promise();

      swal("Completed !", `Edit completed`, "success").then(() => {
        props.closeModal && props.closeModal();
        props.fetchCustomers && props.fetchCustomers();
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (props.isDesktop) {
    return (
      <DesktopForm
        {...props}
        country={country}
        setCountry={setCountry}
        countryOptions={countryOptions}
        name={name}
        setName={setName}
        gender={gender}
        setGender={setGender}
        RadioBox={RadioBox}
        mobile={mobile}
        handleSelectMobileCountry={handleSelectMobileCountry}
        handleInputMobile={handleInputMobile}
        preferredCountries={preferredCountries}
        date={date}
        setDate={setDate}
        year={year}
        setYear={setYear}
        email={email}
        setEmail={setEmail}
        mode={mode}
        editCustomer={editCustomer}
        month={month}
        setMonth={setMonth}
        startYear={startYear}
        createCustomer={createCustomer}
        setExpireDate={setExpireDate}
        expireDate={expireDate}
        friendList={friendList}
        setFriendlist={setFriendlist}
        tags={tags}
        setTags={setTags}
        memberId={memberId}
        setMemberId={setMemberId}
        businessUnit={businessUnit}
        setBusinessUnit={setBusinessUnit}
      />
    );
  }

  return (
    <MobileForm
      {...props}
      country={country}
      setCountry={setCountry}
      countryOptions={countryOptions}
      name={name}
      setName={setName}
      gender={gender}
      setGender={setGender}
      RadioBox={RadioBox}
      mobile={mobile}
      handleSelectMobileCountry={handleSelectMobileCountry}
      handleInputMobile={handleInputMobile}
      preferredCountries={preferredCountries}
      date={date}
      setDate={setDate}
      year={year}
      setYear={setYear}
      email={email}
      setEmail={setEmail}
      mode={mode}
      editCustomer={editCustomer}
      month={month}
      setMonth={setMonth}
      startYear={startYear}
      createCustomer={createCustomer}
      memberId={memberId}
      setMemberId={setMemberId}
      businessUnit={businessUnit}
      setBusinessUnit={setBusinessUnit}
    />
  );
}

const RadioBox = ({ checked, label, onClick }) => {
  return (
    <div className="form-check form-check-inline" onClick={onClick}>
      <input className="form-check-input" type="radio" checked={checked} />
      <label className="form-check-label">{label}</label>
    </div>
  );
};

const deleteBlankSpace = (text) => {
  let newText = "";
  _.forEach(text, (char) => {
    if (char !== " ") {
      newText += char;
    }
  });
  return newText;
};

function getCurrentYearInt() {
  let currentYear = moment().format("YYYY");

  return parseInt(currentYear);
}

const preferredCountries = [
  "th",
  "us",
  "cn",
  "de",
  "ru",
  "ch",
  "hk",
  "ph",
  "my",
  "sg",
  "jp",
  "au",
  "gb",
];

const cutEmptyStr = (text) => {
  if (text === "") {
    return null;
  }

  return text;
};

const mapStateToProps = (state) => {
  // let productObj = {};

  // _.map(state.spa.pos.items, item => {
  //   productObj[item.timestamp.toString()] = item;
  // });

  return {
    isRetail: state.appState.isRetail,
  };
};

export default connect(mapStateToProps)(ManageCustomerInfo);
