import React, { useState } from "react";
import DatePicker from "react-datepicker";
import useFilterList from "./useFilterList";
import _ from "lodash";
import moment from "moment";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { downloadableLinkUrl } from "../../shared/utils/index";
import { Link } from "react-router-dom";
import Select from "react-select";
import swal from "sweetalert";

const FORMAT_DATE = "YYYY-MM-DD";
const BASE_PATH = "/reports3/spa";

function ReportQuery({
  title,
  dateMode,
  reportDispatch,
  reportReducer = {},
  showButtons,
  filterList = null,
  webPath = "",
  history,
  absPath,
  match,
  shop,
  pdf = {},
  excel = {},
  isHq,
  hqViewBranch,
  branchOptions,
  actions,
}) {
  let [selectedFilter, FilterComponent] = useFilterList(filterList);

  const [loading, setLoading] = useState(false);

  function getEndDateISOString() {
    const suffix = ""; //'#zzzzzzzzzzzzzzzzz'''
    if (dateMode === "single") {
      return (
        moment(reportReducer.startDate).endOf("day").format(FORMAT_DATE) +
        suffix
      );
    } else if (dateMode === "range") {
      return (
        moment(reportReducer.endDate).endOf("day").format(FORMAT_DATE) + suffix
      );
    } else if (dateMode === "month") {
      return (
        moment(reportReducer.endDate).endOf("month").format(FORMAT_DATE) +
        suffix
      );
    }
  }

  function submitDownloadPdf() {
    const URL = pdf.url;

    let shopUid = shop.uid;
    let shopName = shop.name;

    //HQ REPORT
    if (isHq && !hqViewBranch) {
      return swal("กรุณาเลือกสาขาที่ต้องการดูรายงาน", "", "error");
    } else if (isHq && hqViewBranch) {
      shopUid = hqViewBranch.uid;
      shopName = hqViewBranch.name;
    }

    let params = {
      mode: pdf.mode, //sales||company||entertains||therapist เลือกข้อมูลที่ต้องการ
      shopName: shopName,
      shopId: shopUid, //ไอดีของร้าน

      startDate: moment(reportReducer.startDate).format("YYYY-MM-DD"), //วันที่เริ่มต้นของข้อมูลที่ต้องการ

      endDate: getEndDateISOString(), //วันที่สิ้นสุดของข้อมูลที่ต้องการ
      // filter:['1234','EX234','SRT123'] //array uid,filter:[] === all uid
      filter: genFilterObj(selectedFilter),
    };

    console.log(params);
    setLoading(true);
    axios
      .post(URL, params)
      .then((res) => {
        downloadableLinkUrl("report-summary-excel.xlsx", res.data);
        alert("Download Complete !");
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setLoading(false);
        // dispatch(appStateAction.loading(false));
      });
  }

  function submitDownloadExcel() {
    const URL = excel.url;

    let shopUid = shop.uid;
    let shopName = shop.name;

    //HQ REPORT
    if (isHq && !hqViewBranch) {
      return swal("กรุณาเลือกสาขาที่ต้องการดูรายงาน", "", "error");
    } else if (isHq && hqViewBranch) {
      shopUid = hqViewBranch.uid;
      shopName = hqViewBranch.name;
    }

    let params = {
      storeUid: shopUid, //ไอดีของร้าน
      shopName: shopName,
      startDate: moment(reportReducer.startDate)
        .startOf("day")
        .format(FORMAT_DATE), //วันที่เริ่มต้นของข้อมูลที่ต้องการ

      endDate: getEndDateISOString(), //วันที่สิ้นสุดของข้อมูลที่ต้องการ,
      timestamp: moment(reportReducer.month).format("YYYY-MM"),
      shopId: shopUid,

      filter: genFilterObjHolderExcel(selectedFilter),
      ...(excel || {}),
      ...(excel.options || {}),
    };

    if(dateMode == "month"){
      params.startDate =  moment(reportReducer.startDate)
      .startOf("month")
      .format(FORMAT_DATE)
    }
    debugger;

    setLoading(true);

    console.log(params);

    axios
      .post(URL, params)
      .then((res) => {
        downloadableLinkUrl("report-summary-excel.xlsx", res.data);
      })
      .catch((err) => alert(err))
      .finally(() => {
        alert("Download Complete !");
        setLoading(false);

        // dispatch(appStateAction.loading(false));
      });
  }

  function handleStartDateChange(date) {
    console.log(date);
    reportDispatch({
      type: "SET",
      key: "startDate",
      payload: date,
    });
  }

  function handleEndDateChange(date) {
    console.log(date);
    reportDispatch({
      type: "SET",
      key: "endDate",
      payload: date,
    });
  }
  function handleMonthChange(date) {
    reportDispatch({
      type: "SET",
      key: "month",
      payload: date,
    });
  }

  function renderDateMode() {
    switch (dateMode) {
      case "month":
        return (
          <InputRow title="Month">
            <DatePicker
              className="form-control form-control-sm"
              selected={reportReducer.month}
              onChange={handleMonthChange}
              dateFormat="MMMM-yyyy" /// more version chage yyyy to YYYY
              minDate={moment("01/01/2020", "DD/MM").toDate()}
            />
          </InputRow>
        );

      case "single":
        return (
          <InputRow title="Start Date">
            <DatePicker
              className="form-control form-control-sm"
              selected={reportReducer.startDate}
              onChange={handleStartDateChange}
              dateFormat="dd/MM/yyyy"
              minDate={moment("01/01/2020", "DD/MM/YYYY").toDate()}
            />
          </InputRow>
        );

      case "range":
        return [
          <InputRow title="Start Date">
            <DatePicker
              className="form-control form-control-sm"
              selected={reportReducer.startDate}
              onChange={handleStartDateChange}
              dateFormat="dd/MM/yyyy"
              minDate={moment("01/2020", "MM/YYYY").toDate()}
            />
          </InputRow>,

          <InputRow title="End Date">
            <DatePicker
              className="form-control form-control-sm"
              selected={reportReducer.endDate}
              onChange={handleEndDateChange}
              dateFormat="dd/MM/yyyy"
              minDate={moment("01/01/2020", "DD/MM/YYYY").toDate()}
            />
          </InputRow>,
        ];
      default:
        break;
    }
  }

  return (
    <div class="card card-border-color card-border-color-primary">
      <div class="card-header card-header-divider">
        <div class="row">
          <div class="col-3">
            <Link to={BASE_PATH}>
              <button class="btn btn-rounded btn-space btn-secondary">
                Back
              </button>
            </Link>
          </div>

          <div class="col-9">
            <h3 class="m-0">{title || "NO TITLE"}</h3>
          </div>
        </div>
      </div>
      <div class="card-body">
        {isHq && (
          <InputRow title="เลือกสาขา">
            <div style={{ width: 300 }}>
              <Select
                value={hqViewBranch}
                onChange={(branch) => actions.setViewBranch(branch)}
                options={branchOptions}
              />
            </div>
          </InputRow>
        )}

        {renderDateMode()}

        {filterList && (
          <InputRow title={filterList}>{FilterComponent}</InputRow>
        )}

        {loading == false && (
          <InputRow>
            {_.includes(showButtons, "web") && (
              <button
                class="btn btn-space btn-primary"
                onClick={() => {
                  if (isHq && !hqViewBranch) {
                    return swal(
                      "กรุณาเลือกสาขาที่ต้องการดูรายงาน",
                      "",
                      "error"
                    );
                  }
                  if (absPath) {
                    history.push(absPath);
                  } else {
                    history.push(`${match.path}/${webPath}`);
                  }
                }}
              >
                View Web
              </button>
            )}

            {_.includes(showButtons, "excel") && (
              <button
                onClick={submitDownloadExcel}
                class="btn btn-space btn-primary"
              >
                Download Excel
              </button>
            )}

            {_.includes(showButtons, "pdf") && (
              <button
                onClick={submitDownloadPdf}
                class="btn btn-space btn-primary"
              >
                Download PDF
              </button>
            )}

            {_.includes(showButtons, "thermal") && (
              <button class="btn btn-space btn-primary">Print Thermal</button>
            )}

            {_.includes(showButtons, "email") && (
              <button class="btn btn-space btn-primary">Send eMail</button>
            )}
          </InputRow>
        )}

        {loading == true && (
          <InputRow>
            <h3>Loading....</h3>
          </InputRow>
        )}
      </div>
    </div>
  );
}

export default withRouter(ReportQuery);

function InputRow({ title, children }) {
  return (
    <div class="form-group row">
      <label class="col-12 col-sm-3 col-form-label text-sm-right">
        {title}
      </label>
      <div class="col-12 col-sm-6">{children}</div>
    </div>
  );
}

function genFilterObj(selectedFilter) {
  if (selectedFilter == null) {
    return [];
  }
  let res = selectedFilter.map((l, i) => {
    return { uid: l.value };
  });
  return res;
}

function genFilterObjHolderExcel(selectedFilter) {
  let holder = {};
  let found = false;
  _.forEach(selectedFilter, (v, k) => {
    holder[v.uid] = true;
    found = true;
  });
  if (found) {
    return holder;
  } else {
    return null;
  }
}
