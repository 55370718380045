import React, { useState } from "react";

function Numpad(props) {
  const {
    setAmount,
    delAmount,
    enter,
    clear,
    enablePayButton,
    makePayment,
    fillAmount,
    // dispatch
    spaPos
  } = props;

  const getIsPop = () => {
    let isPop = JSON.parse(window.localStorage.getItem("isPopEvaluate"));
    if (isPop === null) {
      isPop = true;
    }
    return isPop;
  };

  const [isPopEvaluate, setIsPopEvaluate] = useState(getIsPop());

  const handleSetIsPopEvaluate = () => {
    if (isPopEvaluate) {
      window.localStorage.setItem("isPopEvaluate", false);
      setIsPopEvaluate(false);
    } else {
      window.localStorage.setItem("isPopEvaluate", true);
      setIsPopEvaluate(true);
    }
  };

  return (
    <div className="card card-flat" style={{ backgroundColor: "transparent" }}>
      <div className="card-header card-header-divider">Numpad / QuickPay</div>
      <div className="card-body">
        <ul id="keyboard">
          <li className="letter" onClick={() => setAmount("7")}>
            7
          </li>
          <li className="letter" onClick={() => setAmount("8")}>
            8
          </li>
          <li className="letter" onClick={() => setAmount("9")}>
            9
          </li>
          <li className="letter" onClick={() => setAmount(".")}>
            .
          </li>
          <li className="letter clearl" onClick={() => setAmount("4")}>
            4
          </li>
          <li className="letter" onClick={() => setAmount("5")}>
            5
          </li>
          <li className="letter" onClick={() => setAmount("6")}>
            6
          </li>
          <li className="letter" onClick={() => setAmount("0")}>
            0
          </li>
          <li className="letter clearl" onClick={() => setAmount("1")}>
            1
          </li>
          <li className="letter" onClick={() => setAmount("2")}>
            2
          </li>
          <li className="letter" onClick={() => setAmount("3")}>
            3
          </li>
          <li className="delete lastitem" onClick={delAmount}>
            Del
          </li>
          <li className="switch" onClick={clear}>
            C
          </li>
          <li className="return" onClick={enter}>
            Return
          </li>
          <li className="delete lastitem" onClick={fillAmount}>
            ==
          </li>
        </ul>
        {enablePayButton && (
          <button
            class="btn btn-space btn-success mt-3"
            style={{ width: "100%", height: 60 }}
            onClick={makePayment}
          >
            <span style={{ fontSize: 20, fontWeight: "bold" }}>PAY</span>
          </button>
        )}

        {/* {spaPos.meta.room && (
          <div
            class="be-checkbox custom-control custom-checkbox"
            onClick={handleSetIsPopEvaluate}
          >
            <input
              class="custom-control-input"
              type="checkbox"
              checked={isPopEvaluate}
            />
            <label class="custom-control-label" for="check1">
              Evaluate
            </label>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default Numpad;
