import React, { useState, useEffect } from "react";
import useFetchReceipt from "./useFetchReceipt";
import moment from "moment";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import Select from "react-select";
import ReceiptRateCard from "./ReceiptRateCard";

function SatRate(props) {
  const [satRateSummary, setSatRateSummary] = useState({});
  const [receipts, setReceipts] = useState([]);

  return (
    <div>
      <DataFilter
        {...props}
        setSatRateSummary={setSatRateSummary}
        setReceipts={setReceipts}
      />
      {!_.isEmpty(satRateSummary) && (
        <RowCol6
          col1={<ScoreSummary satRateSummary={satRateSummary} />}
          col2={<RateReceipt receipts={receipts} />}
        />
      )}
    </div>
  );
}

function RateReceipt({ receipts }) {
  return (
    <div>
      {_.map(receipts, rec => {
        return <ReceiptRateCard receipt={rec} />;
      })}
    </div>
  );
}

const optionAll = { label: "All", value: "all" };

const DataFilter = props => {
  const [startDateObj, setStartDateObj] = useState(Date.now());
  const [endDateObj, setEndDateObj] = useState(Date.now());
  const [selectedEmp, selectEmp] = useState(optionAll);
  // const [branch, setBranch] = useState(null);

  const { branch } = props;

  const { receipts, fetchReceipts } = useFetchReceipt(props.user.shopId, {
    dateRange: {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD") + "zzzzzzzzzzzzzzzzz"
    }
  });

  useEffect(() => {
    computeData();
  }, [receipts, selectedEmp]);

  const computeData = () => {
    let definitionObj = {};
    let filteredReceipts = [];

    _.forEach(receipts, rec => {
      let emp = rec.therapist;
      if (!emp || !rec.satRate) return;

      if (selectedEmp.value !== "all" && emp.uid !== selectedEmp.value) return;
      filteredReceipts.push(rec);

      const { definition, answers } = rec.satRate.form_response;

      _.forEach(definition.fields, (field, idx) => {
        if (!definitionObj[field.ref]) {
          definitionObj[field.ref] = {
            title: field.title,
            id: field.id,
            ref: field.ref,
            rates: [],
            row: idx
          };
        }
      });

      _.forEach(answers, answer => {
        if (!definitionObj[answer.field.ref]) return;
        definitionObj[answer.field.ref].rates.push(answer.number);
      });

      _.map(definitionObj, obj => {
        let sumScore = _.sum(obj.rates);
        obj.avgScore = sumScore / _.size(obj.rates);
      });
    });
    props.setReceipts(filteredReceipts);
    props.setSatRateSummary(definitionObj);
  };

  const searchByDateRange = () => {
    let aaa = moment(startDateObj)
      .format("YYYY-MM-DD");
    let bbb = moment(endDateObj)
      .format("YYYY-MM-DD");
    bbb += "zzzzzzzzzzzzzzzz";
    fetchReceipts(getCurrentBranch(), {
      dateRange: {
        startDate: aaa,
        endDate: bbb
      },
      hqUid: branch && branch.value === "all" ? props.user.shopId : null
    });
  };

  const getCurrentBranch = () => {
    if (branch && branch.value === "all") {
      return props.user.shopId;
    }

    if (branch) {
      return branch.value;
    } else {
      return props.user.shopId;
    }
  };

  const selectedOptions = props.employeeOptions;

  const handleChangeBranch = branch => {
    // setBranch(branch);

    let aaa = moment(startDateObj)
      .startOf("day")
      .format("YYYY-MM-DD");
    let bbb = moment(endDateObj)
      .endOf("day")
      .format("YYYY-MM-DD");
    bbb += "zzzzzzzzzzzzzzzz";

    fetchReceipts(branch.value, {
      dateRange: {
        startDate: aaa,
        endDate: bbb
      },
      //ทุกสาขา
      hqUid: branch.value === "all" ? props.user.shopId : null
    });
  };

  useEffect(() => {
    branch && handleChangeBranch(branch);
  }, [branch]);

  return (
    <div className="card mb-3">
      <div className="card-content">
        <div className="p-3">
          <div className="row m-0 justify-content-between">
            <div>
              <Row
                col1={
                  <Row
                    col1={<span style={{ fontSize: 15 }}>Start: </span>}
                    col2={
                      <DatePicker
                        className="form-control"
                        selected={startDateObj}
                        onChange={setStartDateObj}
                        dateFormat="dd/MM/yyyy"
                        id="date-pick"
                        style={{ width: 200 }}
                      />
                    }
                  />
                }
                col2={
                  <Row
                    col1={<span style={{ fontSize: 15 }}>End: </span>}
                    col2={
                      <DatePicker
                        className="form-control"
                        selected={endDateObj}
                        onChange={setEndDateObj}
                        dateFormat="dd/MM/yyyy"
                        id="date-pick"
                        style={{ width: 200 }}
                      />
                    }
                  />
                }
                col3={
                  <button
                    class="btn btn-space btn-primary"
                    style={{ width: 70, height: 47.984, border: 0 }}
                    onClick={searchByDateRange}
                  >
                    GO
                  </button>
                }
              />
            </div>
            <div style={{ width: 300 }}>
              <Select
                value={selectedEmp}
                onChange={selectEmp}
                options={[optionAll, ...selectedOptions]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SatRate;

const Row = ({ col1, col2, col3 }) => {
  return (
    <div className="row m-0">
      <div className="align-self-center">{col1}</div>
      <div className="ml-2">{col2}</div>
      {col3 && <div className="ml-2">{col3}</div>}
    </div>
  );
};

const BigText = props => {
  return (
    <span
      style={{
        fontWeight: "bold",
        fontSize: 18,
        color: props.color || "black"
      }}
    >
      {props.children}
    </span>
  );
};

const ScoreSummary = props => {
  const { satRateSummary } = props;

  const Question = ({ Ref }) => {
    if (!satRateSummary[Ref]) {
      return <div />;
    }
    return (
      <div className="row m-0 mb-3">
        <div className="col-9 p-0 pr-1">
          <BigText>{satRateSummary[Ref].title}</BigText>
        </div>
        <div className="col-3 p-0 pl-1">
          <BigText color="blue">
            {satRateSummary[Ref].avgScore.toFixed(1)}
          </BigText>
        </div>
      </div>
    );
  };

  return (
    <div className="card">
      <div className="card-content p-4">
        <RowCol6
          col1={
            <u>
              <BigText>Summary</BigText>
            </u>
          }
        />
        <Question Ref="program" />
        <div class="dropdown-divider" />
        <Question Ref="ambience" />
        <div class="dropdown-divider" />
        <Question Ref="cleanliness" />
        <div class="dropdown-divider" />
        <Question Ref="receptionist" />
        <div class="dropdown-divider" />
        <Question Ref="therapist" />
        <div class="dropdown-divider" />
        <Question Ref="overall" />
      </div>
    </div>
  );
};

const RowCol6 = ({ col1, col2 }) => {
  return (
    <div className="row m-0 mb-3">
      <div className="col-6 p-0 pr-1">{col1}</div>
      <div className="col-6 p-0 pl-1">{col2}</div>
    </div>
  );
};
