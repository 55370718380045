import * as type from "../../types/spa.sales";

let initData = { employees: [], agents: [], entertains: [], sales: [] };
const reducer = (state = initData, action) => {
  switch (action.type) {
    case type.FETCH_SPA_SALES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
