import React, { useEffect, useState } from "react";
import useFormInput from "../../shared/useFormInput";
import randomUid from "../randomUid";
import documentClient from "../awsAPIs/dynamoDb";
import swal from "sweetalert";
import moment from "moment";
import Select from "react-select";
import _ from "lodash";

function SpaEmpManageModal(props) {
  const name = useFormInput("");
  const mobile = useFormInput("");
  const email = useFormInput("");
  const id = useFormInput("");

  const commissionRate = useFormInput("");
  const note = useFormInput("");
  const [branch, setBranch] = useState(null);

  let { fetch } = props;

  useEffect(() => {
    if (props.mode === "Create") {
      clearState();
    } else {
      clearState();
      name.setValue(props.selectedEmp.name);
      id.setValue(props.selectedEmp.id || "");
      mobile.setValue(props.selectedEmp.mobile || "");

      email.setValue(props.selectedEmp.email || "");
      commissionRate.setValue(props.selectedEmp.commissionRate || "");
      if (props.view === "Agent") {
        note.setValue(props.selectedEmp.note || "");
      }
      if (props.view === "Employee") {
        setBranch(props.selectedEmp.branch || null);
      }
    }
  }, [props.selectedEmp, props.mode, props.time]);

  const clearState = () => {
    name.setValue("");
    id.setValue("");
    mobile.setValue("");

    email.setValue("");
    commissionRate.setValue("");
    note.setValue("");
    setBranch(null);
  };

  const validate = () => {
    if (name.value === "") {
      return `กรุณาระบุชื่อ ${props.view}`;
    }

    return null;
  };

  const createEmp = async () => {
    let isFail = validate();
    if (isFail) {
      return swal("เกิดข้อผิดพลาด", isFail, "error");
    }

    let uid = randomUid(6);

    let checkExistId = _.find(
      props.empList,
      i => i.uid !== uid && i.id === id.value
    );

    if (checkExistId) {
      return swal("ID นี้ถูกใช้แล้ว", "", "error");
    }

    let data = {
      row: moment().valueOf(),
      entityUid: props.shop.uid,
      uid,
      name: name.value,
      id: id.value !== "" ? id.value : null,

      mobile: mobile.value !== "" ? mobile.value : null,
      email: email.value !== "" ? email.value : null,
      commissionRate:
        commissionRate.value !== "" ? parseInt(commissionRate.value) : null
    };

    var params = {
      TableName: props.table,
      Item: data
    };
    //TEMP FLAG
    if (props.table === "Spa_Employees") {
      params.Item.flag = "2020";
    }

    if (props.view === "Agent") {
      params.Item["note"] = note.value !== "" ? note.value : null;
    }
    if (props.view === "Employee") {
      if (branch !== null) {
        params.Item["branch"] = branch;
        params.Item["branchUid"] = branch.uid;
      } else {
        params.Item["branch"] = null;
        params.Item["branchUid"] = null;
      }
    }

    try {
      await documentClient.put(params).promise();
      fetch && fetch();
      swal("Complete", `Create ${props.view} complete`, "success").then(() => {
        window.$("#spaEmpManageModal").modal("hide");
      });
    } catch (error) {
      console.log(error);
    }
  };

  const editEmp = async () => {
    let isFail = validate();
    if (isFail) {
      return swal("เกิดข้อผิดพลาด", isFail, "error");
    }

    let currentEmpUid = props.selectedEmp.uid;

    let checkExistId = _.find(
      props.empList,
      i => i.uid !== currentEmpUid && i.id === id.value
    );

    if (checkExistId) {
      return swal("ID นี้ถูกใช้แล้ว", "", "error");
    }

    var params = {
      TableName: props.table,
      Key: {
        entityUid: props.selectedEmp.entityUid,
        uid: currentEmpUid
      },
      UpdateExpression:
        "set #name = :name , #id = :id,  mobile = :mobile, #email = :email, commissionRate = :commissionRate",
      ExpressionAttributeNames: {
        "#name": "name",
        "#email": "email",
        "#id": "id"
      },
      ExpressionAttributeValues: {
        ":name": name.value,
        ":id": id.value !== "" ? id.value : null,
        ":mobile": mobile.value !== "" ? mobile.value : null,
        ":email": email.value !== "" ? email.value : null,
        ":commissionRate":
          commissionRate.value !== "" ? parseInt(commissionRate.value) : null
      }
    };
    if (props.view === "Agent") {
      params["UpdateExpression"] += ",note = :note";
      params["ExpressionAttributeValues"][":note"] =
        note.value !== "" ? note.value : null;
    }
    if (props.view === "Employee" && branch !== null) {
      params["UpdateExpression"] += ",branch = :branch, branchUid = :branchUid";
      if (branch !== null) {
        params["ExpressionAttributeValues"][":branch"] = branch;
        params["ExpressionAttributeValues"][":branchUid"] = branch.uid;
      } else {
        params["ExpressionAttributeValues"][":branch"] = null;
        params["ExpressionAttributeValues"][":branchUid"] = null;
      }
    }

    try {
      await documentClient.update(params).promise();
      fetch && fetch();
      //   window.$("#spaEmpManageModal").modal("hide");
      swal("Complete", `Edit ${props.view} complete`, "success");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      class="modal"
      id="spaEmpManageModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="spaEmpManageModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style={{ width: 400 }}>
          <div class="modal-header">
            <h4 class="modal-title" id="spaEmpManageModalLabel">
              {props.mode} {props.view}
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {props.view === "Employee" && (
              <div class="form-group">
                <label>Branch:</label>
                <Select
                  value={branch}
                  onChange={setBranch}
                  options={props.branchOptions}
                />
              </div>
            )}
            <div class="form-group">
              <label>Name:</label>
              <input
                class="form-control"
                type="text"
                placeholder="Name"
                {...name}
              />
            </div>
            <div class="form-group">
              <label>ID:</label>
              <input
                class="form-control"
                type="text"
                placeholder="Id."
                {...id}
              />
            </div>

            <div class="form-group">
              <label>Mobile:</label>
              <input
                class="form-control"
                type="text"
                placeholder="Mobile No."
                {...mobile}
              />
            </div>
            <div class="form-group">
              <label>E-Mail:</label>
              <input
                class="form-control"
                type="text"
                placeholder="E-Mail"
                {...email}
              />
            </div>
            <div class="form-group">
              <label>Commission Rate:</label>
              <input
                class="form-control"
                type="number"
                placeholder="%"
                {...commissionRate}
              />
            </div>
            {props.view === "Agent" && (
              <div class="form-group">
                <label>Note:</label>
                <textarea class="form-control" {...note} />
              </div>
            )}
          </div>
          <div class="modal-footer pt-2">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            {props.mode === "Create" ? (
              <button type="button" class="btn btn-primary" onClick={createEmp}>
                Save
              </button>
            ) : (
              <button type="button" class="btn btn-warning" onClick={editEmp}>
                Edit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpaEmpManageModal;
