import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import * as inventoryGroupActions from "../../shared/actions/inventoryGroupActions";
import * as inventoryTypeActions from "../../shared/actions/inventoryTypeActions";
import LeftAsideContainer from "../../shared/components/LeftAsideContainer";
import swal from "@sweetalert/with-react";
import * as linkedSelector from "../../shared/selector/linkedSelector";
import Select from "react-select";
import * as cartHqActions from "../../shared/actions/cartHqActions";
import SortableGroupAndTypeList from "./SortableGroupAndTypeList";
import excelReportMenu from "../../shared/services/service.export.menuCsv";
import * as inventorySelector from "../../shared/selector/inventorySelector";

class InventoryGroupList extends Component {
  state = {
    branchOptions: []
  };

  componentDidMount = () => {
    this.createBranchOptions();
  };

  createBranchOptions = () => {
    const { shopList } = this.props;
    let branchOptions = [];

    _.map(shopList, branch => {
      branchOptions.push({
        value: branch.uid,
        label: branch.name + ` (${branch.branchId})`,
        branchName: branch.name
      });
    });

    this.setState({ branchOptions });
  };
  moveToAnotherType = group => {
    const { types, actions, clearGroupAndItems } = this.props;
    let selectedTypeUid = "none";
    swal({
      buttons: ["Cancel", "Move"],
      content: (
        <div>
          <span>Move "{group.name}" to Type</span>
          <div style={{ marginTop: 15 }} />
          <select
            className="form-control"
            onChange={e => (selectedTypeUid = e.target.value)}
          >
            <option value="1" selected={selectedTypeUid === "none"}>
              เลือก Type
            </option>
            {_.map(types, type => {
              return (
                <option
                  value={type.uid}
                  selected={type.uid === selectedTypeUid}
                >
                  {type.name}
                </option>
              );
            })}
          </select>
        </div>
      )
    }).then(confirm => {
      if (confirm && selectedTypeUid !== "none") {
        actions.moveGroupToAnotherType(group, selectedTypeUid, () => {
          swal("สำเร็จ!", "เปลี่ยน Type สำเร็จ", "success");
          clearGroupAndItems();
        });
      }
    });
  };

  handleChangeBranch = selectedBranch => {
    const { actions } = this.props;
    actions.setDestinationBranch(selectedBranch);
  };
  editGroupName = (group, isType) => {
    const { actions } = this.props;
    let newName = window.prompt(
      isType ? "เปลี่ยนชื่อ Type" : "เปลี่ยนชื่อกลุ่ม",
      group.name
    );
    if (newName === null || newName === undefined) {
      return;
    }
    if (isType) {
      actions.editTypeName(newName, group);
    } else {
      actions.editGroupName(newName, group);
    }
  };
  deleteGroup = (group, isType) => {
    const { actions, clearGroupAndItems, clearType } = this.props;

    swal({
      title: `${isType ? "คุณต้องการลบ Type " : "คุณต้องการลบกลุ่ม "}"${
        group.name
      }" ?`,
      text: isType
        ? "กลุ่มทั้งหมดใน Type จะย้ายไปอยู่ Type Other"
        : "Items ทั้งหมดใน Group จะลูกลบด้วย กรุณากรอกชื่อกลุ่มเพื่อยืนยัน",
      icon: "warning",
      dangerMode: true,
      content: "input",
      buttons: ["Cancel", "Submit"]
    }).then(reason => {
      if (reason) {
        if (reason !== group.name) {
          return;
        }
        if (isType) {
          actions.deleteType(group, () => {
            clearType();
          });
        } else {
          actions.deleteGroup(group, () => {
            clearGroupAndItems();
          }); 
        }
      }
    });
  };

  exportMenuCsv = () => {
    new excelReportMenu(this.props.menu).build();
  };

  render() {
    const { branchOptions } = this.state;
    let {
      groups,
      types,
      actions,
      handleClickGroup,
      handleClickType,
      pickedGroupUid,
      history,
      match,
      selectedBranch,
      timestamp,
      readOnly,
      enableTopSpace,
      pickedTypeUid,
      hasOtherType,
      isLinked,
      isEditInvoice
    } = this.props;

    if (selectedBranch === null && readOnly && !isLinked) {
      return (
        <LeftAsideContainer enableTopSpace={enableTopSpace}>
          <div style={{ height: 600, overflowY: "scroll" }}>
            {!isLinked &&
              readOnly && [
                <h5>
                  <u>เลือกสาขา</u>
                </h5>,
                <div style={{ paddingTop: 0 }}>
                  <Select
                    value={selectedBranch}
                    onChange={this.handleChangeBranch}
                    options={branchOptions}
                    isDisabled={isEditInvoice}
                  />
                </div>
              ]}
          </div>
        </LeftAsideContainer>
      );
    }

    return (
      <LeftAsideContainer enableTopSpace={enableTopSpace}>
        <div style={{ height: 600, overflowY: "scroll" }}>
          {!isLinked &&
            readOnly && [
              <h5>
                <u>เลือกสาขา</u>
              </h5>,
              <div style={{ paddingTop: 0 }}>
                <Select
                  value={selectedBranch}
                  onChange={this.handleChangeBranch}
                  options={branchOptions}
                  isDisabled={isEditInvoice}
                />
              </div>
            ]}
          {/* {readOnly && <h3><u>Filter</u></h3>} */}
          <button
            class="btn btn-space btn-secondary"
            style={{ width: "100%", backgroundColor: "lightgrey" }}
            onClick={this.exportMenuCsv}
          >
            Export Menu CSV
          </button>

          <div className="row m-0 justify-content-between">
            <div>
              <h5>1. เลือกชนิด</h5>
            </div>
            <div>
              {readOnly !== true && (
                <h4>
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#manageTypeModal"
                  >
                    <u>Manage Type</u>
                  </a>
                </h4>
              )}
            </div>
          </div>

          <select
            className="form-control form-control-sm"
            onChange={e => handleClickType(e.target.value)}
          >
            <option value="all" selected={pickedTypeUid === "all"}>
              All
            </option>
            {_.map(types, type => {
              return (
                <option value={type.uid} selected={pickedTypeUid === type.uid}>
                  {type.name}
                </option>
              );
            })}
            {hasOtherType && (
              <option value="other" selected={pickedTypeUid === "other"}>
                Other
              </option>
            )}
          </select>

          <h5>2. เลือกลุ่ม</h5>
          {readOnly !== true &&
            pickedTypeUid !== "none" &&
            pickedTypeUid !== "other" && (
              <button
                className="btn btn-space btn-primary"
                onClick={() => {
                  history.push(`${match.url}/addGroup/${pickedTypeUid}`);
                }}
                style={{ marginTop: 10, width: "100%" }}
              >
                <i className="icon icon-left mdi mdi-plus-circle-o" />
                Add Group
              </button>
            )}
          <SortableGroupAndTypeList
            readOnly={readOnly}
            pickedGroupUid={pickedGroupUid}
            handleClickGroup={handleClickGroup}
            groups={groups}
            actions={actions}
            timestamp={timestamp}
            editGroupName={this.editGroupName}
            deleteGroup={this.deleteGroup}
            pickedTypeUid={pickedTypeUid}
            moveToAnotherType={this.moveToAnotherType}
          />
        </div>
      </LeftAsideContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    types: state.inventory.types,
    timestamp: state.inventory.timestamp,
    isLinked: linkedSelector.isLinkedSelector(state),
    shopList: state.shop.list.inventory,
    selectedBranch: state.cart.cartHqInfo.destination,
    menu: inventorySelector.typeGroupItemSelector(state)
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators(
    { ...inventoryGroupActions, ...inventoryTypeActions, ...cartHqActions },
    dispatch
  );
  return {
    actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryGroupList);
