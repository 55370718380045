import React, { useState, useEffect, useReducer } from "react";
import DashboardReceipts from "./DashboardReceipts";
import DashboardActionsBar from "./DashboardActionsBar";
import SummaryChart from "./DashboardSpaSummaryCart";
import moment from "moment";
import * as aws from "../../../shared/awsAPIs/instance";
import DashboardDrawer from "./DashboardDrawer";
import DashboardProducts from "./DashboardProducts";
import DashboardAgents from "./DashboardAgent";
import DashboardTherapist from "./DashboardTherapist";
import DashboardTherapistTable from "./DashboardTherapistTable";
import DashboardProductTable from "./DashboardProductTable";
import DashboardVoidBills from "./DashboardVoids";
import _ from "lodash";
import SpaBillModal from "../../../shared/components/SpaBillModal";
import DashboardSatRate from "./DashboardSatRate";
import DashboardExport from "./DashboardExport";
import DashboardEntertain from "./DashboardPaymentEntertain";
import DashboardPayment from "./DashboardPayment";
import DashboardRangeDatesBar from "./DashboardRangeDatesBar";
import DashboardSales from "./DashboardSales";
import { connect } from "react-redux";
import * as appStateAction from "../../../shared/actions/appStateActions";
import * as spaSalesSelector from "../../../shared/selector/spaSalesSelector";
import { useDispatch, useSelector } from "react-redux";
import Receipts from "../../SpaReport3/web/Receipts";
// import { initState, reducer } from "../../SpaReport3/reducer";
import * as reducerSpaReport3 from "../../SpaReport3/reducer";
import Products from "../../SpaReport3/web/Products";
import PaymentTypes from "../../SpaReport3/web/PaymentTypes";



let initState = {
  view: "receipts",
  chartGroupBy: "time",
  receipts: [],
  receiptVoids: [],
  computed: {},

  // startDate: moment()
  // //   // .add(-4,'d')
  //   .startOf("day")
  //   .toISOString(), //moment().format("YYYY-MM-DD"),

  // endDate: moment()
  //   .endOf("day")
  //   .toISOString(),

  startDate: moment().toISOString(),//TODO: Uncomment
  endDate: moment().toISOString(),
  // startDate: moment().subtract(1, "day").toISOString(), //TODO: Delete
  // endDate: moment().subtract(1, "day").toISOString(),

  modal: {
    data: null,
    visible: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return { ...state, [action.key]: action.payload };
    case "SET_COMPUTE":
      let newCompute = { ...state.computed, [action.key]: action.payload };
      return { ...state, computed: newCompute };
    case "SET_MODAL":
      return { ...state, modal: action.payload };
    default:
      return state;
  }
};

function Dashboard(props) {
  const {
    shop,
    menuItems,
    businessDate,
    inventory,
    dateRange = false,
    chartGroupBy = "time",
  } = props;

  const [state, dispatch] = useReducer(reducer, initState);
  const dispatch2 = useDispatch();

  useEffect(() => {
    if (shop) {
      if (props.match.path === "/reports2/spa") {
        let newStartDate = moment().subtract(7, "days").toISOString();
        dispatch({
          type: "SET",
          key: "startDate",
          payload: newStartDate,
        });
        fetch(newStartDate);
      } else {
        fetch();
      }
    }

    if (chartGroupBy === "date") {
      dispatch({ type: "SET", key: "chartGroupBy", payload: "date" });
    }
  }, []);
  async function fetch(forceStartDate) {
    dispatch2(appStateAction.loading(true));

    var params = {
      TableName: "Spa_Receipts",
      KeyConditionExpression:
        "storeUid = :hkey  AND #timestamp BETWEEN :startDate AND :endDate ",
      FilterExpression: "#version = :version", //"contains(#flag, :flag)",

      ExpressionAttributeNames: {
        "#timestamp": "timestamp",
        "#version": "version",
      },
      ExpressionAttributeValues: {
        ":hkey": shop.uid,
        ":version": 2020,

        ":startDate": moment(businessDate || state.startDate).format(
          "YYYY-MM-DD"
        ),
        ":endDate":
          moment(businessDate || state.endDate).format("YYYY-MM-DD") +
          "#zzzzzzzzzzzzzzzzzzzzzzzzzzz",
      },
    };

    var lasyKey = null;
    var res = [];
    do {
      lasyKey ? (params.ExclusiveStartKey = lasyKey) : (lasyKey = null);
      var data = await aws.documentClient.query(params).promise();
      res = res.concat(data.Items);
      lasyKey = data.LastEvaluatedKey;
    } while (data.LastEvaluatedKey);

    // var data = await aws.documentClient.query(params).promise();
    dispatch({
      type: "SET",
      key: "receipts",
      payload: _.filter(res, (r) => !r.void && !r.entertain),
    });

    dispatch({
      type: "SET",
      key: "receipts_entertain",
      payload: _.filter(res, (r) => !r.void && r.entertain),
    });

    dispatch({
      type: "SET",
      key: "receiptVoids",
      payload: _.filter(res, (r) => r.void),
    });
    dispatch2(appStateAction.loading(false));
  }

  const { view, modal } = state;

  const { match, history, branchOptions } = props;

  const [reportReducer, reportDispatch] = useReducer(
    reducerSpaReport3.reducer,
    reducerSpaReport3.initState
  );
  const { shopDetail, user } = useSelector((state) => {
    return {
      user: state.user.loggedIn,
      shopDetail: state.shop.detail,
    };
  });

  function _genReducer() {
    return {
      reportReducer,
      reportDispatch,
      shop,
    };
  }
  const hideBackBtn = true;
  return [
    // <SpaBillModal dispatch={dispatch} {...modal.data} />,
    <SpaBillModal
      dispatch={dispatch}
      {...modal.data}
      reportDispatch={reportDispatch}
      {...reportReducer.modal.data}
    />,
    dateRange && (
      <DashboardRangeDatesBar {...props} fetch={fetch} dispatch={dispatch} />
    ),
    <DashboardActionsBar {...state} dispatch={dispatch} />,
    <div>
      {view === "receipts" && (
        <div>
          <Receipts {..._genReducer()} hideBackBtn={hideBackBtn} />
        </div>
      )}
      {view === "export" && <DashboardExport {...state} />}

      {view === "sales" && (
        <DashboardSales {...props} {...state} dashboardDispatch={dispatch} />
      )}

      {view === "drawer" && <DashboardDrawer />}
      {view === "products" && (
        // <DashboardProducts
        //   {...props}
        //   {...state}
        //   groups={menuItems}
        //   inventory={inventory}
        // />
        <Products {..._genReducer()} hideBackBtn={hideBackBtn} />

      )}
      {view === "agent" && (
        <DashboardAgents {...props} {...state} dashboardDispatch={dispatch} />
      )}
      {view === "therapist" && (
        <DashboardTherapist
          {...props}
          {...state}
          dashboardDispatch={dispatch}
        />
      )}
      {view === "therapist-table" && (
        <DashboardTherapistTable
          {...props}
          {...state}
          dashboardDispatch={dispatch}
        />
      )}
      {view === "product-table" && (
        <DashboardProductTable
          {...props}
          {...state}
          dashboardDispatch={dispatch}
        />
      )}

      {view === "payment" && (
        // <DashboardPayment {...state} dashboardDispatch={dispatch} />
        <PaymentTypes  {..._genReducer()} hideBackBtn={hideBackBtn} />

      )}
      {view === "entertain" && (
        <DashboardEntertain
          {...props}
          fetch={fetch}
          {...state}
          dashboardDispatch={dispatch}
        />
      )}
      {view === "satrate" && (
        <DashboardSatRate {...props} {...state} dashboardDispatch={dispatch} />
      )}

      {view === "voids" && (
        <DashboardVoidBills {...state} dashboardDispatch={dispatch} />
      )}
    </div>,
  ];
}

// export default Dashboard;

const mapStateToProps = (state) => {
  // let link = { inventory: null };
  // if (state.shop.detail.link) {
  //   link = state.shop.detail.link.inventory;
  // }
  return {
    // linked: link,
    // shop: state.shop.detail,
    employeeObj: spaSalesSelector.employeeObjSelector(state),
    agentOptions: spaSalesSelector.agentOptionsSelector(state),
    entertainOptions: spaSalesSelector.entertainOptionsSelector(state),
    salesOptions: spaSalesSelector.salesOptionsSelector(state),
    therapistOptions: spaSalesSelector.employeeOptionsSelector(state),
  };
};
// function mapDispatchToProps(dispatch) {
//   let actions = bindActionCreators(
//     {
//       ...shopActions,
//       ...spaRoomsActions
//     },
//     dispatch
//   );
//   return {
//     actions,
//     dispatch
//   };
// }

export default connect(mapStateToProps)(Dashboard);
