import * as type from "../../types/appState";
import _ from "lodash";
const initData = {
  loading: false,
  lastReceiptId: 0,
  pendingPushTransactions: 0,
  room: null,
  businessDate : null,
};

const reducer = (state = initData, action) => {
  switch (action.type) {
    case type.APP_STATE_LOADING:
      return { ...state, loading: action.payload };
    case type.APP_STATE_INCREMENT_LAST_RECEIPT_ID:
      return { ...state, lastReceiptId: state.lastReceiptId + 1 };
    case type.APP_STATE_PENDING_PUSH_TRANSACTIONS:
      return { ...state, pendingPushTransactions: action.payload };
    case type.APP_STATE_SET:
      return {...state, [action.key] : action.payload}  
    default:
      return state;
  }
};

export default reducer;
