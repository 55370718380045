import _ from "lodash";
import documentClient from "../../shared/awsAPIs/dynamoDb";

const computeReceiptGrandTotal = (receipts) => {
  let grandTotal = 0;
  _.map(receipts, (rec) => {
    grandTotal += rec.payment.grandTotal;
  });
  return grandTotal;
};
const fetchMemberReceipts = (memberUid) => {
  var params = {
    TableName: "Spa_Receipts",
    IndexName: "memberUid-timestamp-index",
    KeyConditionExpression: "memberUid = :memberUid",
    ExpressionAttributeValues: {
      ":memberUid": memberUid,
    },
  };

  return documentClient.query(params).promise();
};
const fetchMembers = async (shopUid, lastkey) => {
  let params = {
    TableName: "Crm_Users",
    IndexName: "hqUid-createAt-index",
    KeyConditionExpression: "hqUid = :hqUid",
    ExpressionAttributeValues: {
      ":hqUid": shopUid,
    },
  };

  if (lastkey) {
    params.ExclusiveStartKey = lastkey;
  }
  return documentClient.query(params).promise();
};

const asyncFetchMembers = (shopUid, isRetail) => {
  return new Promise(async (resolve, reject) => {
    let initial = true;
    let members = [];
    let lastKey = "first";

    const setValue = (rec, key) => {
      initial = false;
      members = [...members, ...rec];
      lastKey = key;
    };

    while (initial || lastKey) {
      let res = await fetchMembers(
        shopUid,
        lastKey !== "first" ? lastKey : null
      );

      let tmpMember = [];
      if (isRetail) {
        tmpMember = _.filter(
          res.Items,
          (m) => m.businessUnit && m.businessUnit === "retail"
        );
      } else {
        tmpMember = _.filter(
          res.Items,
          (m) =>
            !m.businessUnit || (m.businessUnit && m.businessUnit != "retail")
        );
      }
      setValue(tmpMember, res.LastEvaluatedKey);
      console.log("Fetched");
    }
    resolve(members);
  });
};

const getTier = (grandTotal) => {
  if (grandTotal >= -9999999999 && grandTotal <= 1000) {
    return "Standard";
  } else if (grandTotal >= 1001 && grandTotal <= 10000) {
    return "Emerald";
  } else if (grandTotal >= 10001 && grandTotal <= 100000) {
    return "Gold";
  } else if (grandTotal >= 100001 && grandTotal <= 1000000) {
    return "Platinum";
  }
};

const exportAllMemberCsv = async (shopUid, isRetail) => {
  return new Promise(async (resolve) => {
    let members = await asyncFetchMembers(shopUid, isRetail);
    let computedMember = [];
    for (let index in members) {
      let member = members[index];
      let resMemberReceipts = await fetchMemberReceipts(member.uid);
      let grandTotal = computeReceiptGrandTotal(resMemberReceipts.Items);
      let tier = getTier(grandTotal);
      computedMember.push({ ...member, grandTotal, tier });
    }
    resolve(computedMember);
  });
};

export default exportAllMemberCsv;
