import firebase from "firebase";
import "firebase/firestore";

var config = {
  apiKey: "AIzaSyDrPLVj4PRDjKb6mrQIVufeCmB39AQTngY",
  authDomain: "divana-project.firebaseapp.com",
  databaseURL: "https://divana-project.firebaseio.com",
  projectId: "divana-project",
  storageBucket: "divana-project.appspot.com",
  messagingSenderId: "96393886787",
  appId: "1:96393886787:web:1b2cf109f2073e9c"
};
firebase.initializeApp(config);

//firebase
export const rootRef = firebase.database().ref();
export const notificationRef = rootRef.child("inbox_lastupdate");
export const spaPosTimeStampRef = rootRef.child("spaPosTimeStamp");

//firestore
export const fireStoreRootRef = firebase.firestore();
export const globalMessageRef = fireStoreRootRef.collection("global_messages"); //.doc("wizzy")
export const alertMessageRef = fireStoreRootRef.collection("alert_messages");
export const appVersionRef = fireStoreRootRef
  .collection("app_version")
  .doc("spa_pos");
export const prepaidPaymentRef = fireStoreRootRef.collection("prepaid_payment");
export const appStateRef = fireStoreRootRef.collection("app_state"); //.doc("wizzy")
