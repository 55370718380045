// import React from "react";
// import ReactDOM from "react-dom";
// import AppBooking from "./AppBooking";
// import _ from "lodash";
// import store from "./store";

// import "./index.css";

// ReactDOM.render(<AppBooking store={store} />, document.getElementById("root"));
// registerServiceWorker();

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {
  BrowserRouter as Router,
  withRouter,
  Route,
  Switch
} from "react-router-dom";
import LogIn from "./pages/LogIn";
import SignUp from "./pages/SignUp";
import _ from "lodash";
import { Auth } from "aws-amplify";
import store from "./store";
import { Provider } from "react-redux";
import AWS from "aws-sdk";
import { getUser } from "./shared/awsAPIs/Cognito";
import exportReceiptsWin from "./shared/services/exportReceiptsWin";

import "./index.css";

var pathName = window.location.pathname;

const signUpRoute = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/signup/:shopId" component={SignUp} />
          <Route path="/signup" component={SignUp} />
        </Switch>
      </Router>
    </Provider>,
    document.getElementById("root")
  );
};

var pathResult = _checkPath(pathName);
switch (pathResult) {
  case "/":
    // getUser()
    // CHECK AUTH
    Auth.currentAuthenticatedUser()
      .then(function(user) {
        //
        ReactDOM.render(
          <App store={store} loggedInUser={user} />,
          document.getElementById("root")
        );
      })
      .catch(err => {
        console.log(err);
        ReactDOM.render(<LogIn />, document.getElementById("root"));
      });
    break;
  case "login":
    ReactDOM.render(<LogIn />, document.getElementById("root"));
    break;
  case "signup":
    signUpRoute();
    break;
  case "/signUp":
    signUpRoute();
    break;
  default:
    window.location.href = "/";
    break;
}

function _checkPath(pathName) {
  var res = "/";
  if (_.includes(pathName, "signup")) {
    return "signup";
  }

  if (_.includes(pathName, "login")) {
    return "login";
  }
  return pathName;
}

// ReactDOM.render(<App store={store} />, document.getElementById('root'));
// registerServiceWorker();
