import React, { useState, useEffect } from "react";
import BookingForm from "./BookingForm";
import BookingChart from "./BookingChart";
import BookingMain from "./BookingMain";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as spaPosActions from "../../shared/actions/spaPosActions";
import * as roomsSelector from "../../shared/selector/roomsSelector";
import * as branchSelector from "../../shared/selector/branchOptionsSelector";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import _ from "lodash";

function Index(props) {
  const [view, setView] = useState(2);
  const [initEditData, setInitEditData] = useState(null);
  const [mainPageBranch, setMainPageBranch] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(props.match.params)) {
      setView(parseInt(props.match.params.pageNo));
    }
  }, []);

  const fetchLocalBranchRooms = (storeUid, cb) => {
    var params = {
      TableName: "Spa_Rooms",
      KeyConditionExpression: "storeUid = :storeUid",
      ExpressionAttributeValues: {
        ":storeUid": storeUid
      }
    };

    documentClient.query(params, function(err, data) {
      if (err) console.log(err);
      else {
        cb && cb(data.Items);
      }
    });
  };

  return (
    <div className="main-content p-0">
      <div class="card">
        {view === 2 && (
          <BookingMain
            {...props}
            goToBookingForm={() => setView(90)}
            setInitEditData={setInitEditData}
            hqMode={props.linked.inventory === null}
            mainPageBranch={mainPageBranch}
            setMainPageBranch={setMainPageBranch}
            fetchLocalBranchRooms={fetchLocalBranchRooms}
          />
        )}

        {view === 90 && (
          <BookingForm
            {...props}
            goToBookingChart={() => setView(2)}
            initEditData={initEditData}
            clearInitData={() => setInitEditData(null)}
            hqMode={props.linked.inventory === null} 
            mainPageBranch={mainPageBranch}
            setMainPageBranch={setMainPageBranch}
            fetchLocalBranchRooms={fetchLocalBranchRooms}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user.loggedIn,
    linked: state.shop.linked,
    shop: state.shop.detail,
    spaRooms: state.spa.rooms,
    bedOptions: roomsSelector.bedOptionsSelector(state),
    branchOptions: branchSelector.branchOptionsSelector(state)
  };
};
function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...spaPosActions }, dispatch);
  return {
    actions,
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);
