import React, { useState, useEffect } from "react";
import useFetchReceipt from "./useFetchReceipt";
import moment from "moment";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import SpaBillModal from "../../shared/components/SpaBillModal";
import Select from "react-select";

function EmpAndAgent(props) {
  const [computedData, setComputedData] = useState([]);
  const [modalContent, setModalContent] = useState({});

  return (
    <div>
      <SpaBillModal {...props} {...modalContent} />
      <DataFilter {...props} setComputedData={setComputedData} />
      <Card
        {...props}
        computedData={computedData}
        setModalContent={setModalContent}
      />
    </div>
  );
}

export default EmpAndAgent;

const Card = props => {
  const { computedData, setModalContent, target } = props;

  let empObj = [];
  if (target === "emp") {
    empObj = props.employeeObj;
  } else if (target === "agent") {
    empObj = props.agentObj;
  } else if (target === "entertain") {
    empObj = props.entertainObj;
  } else if (target === "sales") {
    empObj = props.salesObj;
  }

  return (
    <div className="card card-table">
      <div className="card-body">
        <table className="table table-striped table-borderless">
          <thead>
            <tr>
              <th>Date</th>
              <th>Start - End</th>
              <th>Guest</th>
              {/* <th>Sales</th> */}
              <th>Grand Total</th>
              {/* <th className="number">Sales</th> */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="no-border-x">
            {_.map(computedData, (data, empUid) => {
              if (!empObj[empUid]) return;

              return [
                <tr style={{ backgroundColor: "lightblue" }}>
                  <th scope="row" colSpan={6}>
                    {empObj[empUid].name} ({data.hours.asHours().toFixed(2)}{" "}
                    ชั่วโมง)
                  </th>
                </tr>,
                _.map(data.receipts, rec => {
                  let meta = rec.meta || {};
                  return (
                    <tr>
                      <th scope="row">
                        {/* {moment(rec.timestamp).format("DD/MM/YYYY HH:mm A")} */}
                        {moment(rec.timestamp).format("DD/MM/YYYY")}
                      </th>
                      <td className="cell-detail">
                        <span>
                          {moment(meta.startDate).format("HH:mm a")} -{" "}
                          {moment(meta.endDate).format("HH:mm a")}
                        </span>
                      </td>
                      <td className="cell-detail">
                        <span>{rec.member && rec.member.name}</span>
                      </td>
                      {/* <td className="cell-detail">
                        <span>{rec.agent && rec.agent.label}</span>
                      </td> */}
                      <td>
                        <span style={{ color: "blue", fontWeight: "bold" }}>
                          {(rec.payment.grandTotal || 0).toLocaleString()}
                        </span>
                      </td>
                      <td>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            setModalContent(rec);
                          }}
                          data-toggle="modal"
                          data-target="#spaBillModal"
                        >
                          View
                        </a>
                      </td>
                    </tr>
                  );
                })
              ];
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const optionAll = { label: "All", value: "all" };

const DataFilter = props => {
  const { target } = props;
  const [startDateObj, setStartDateObj] = useState(Date.now());
  const [endDateObj, setEndDateObj] = useState(Date.now());
  const [selectedEmp, selectEmp] = useState(optionAll);
  // const [branch, setBranch] = useState(null);
  const { branch } = props;

  const data = useFetchReceipt(props.user.shopId, {
    dateRange: {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD") + "zzzzzzzzzzzzzzzzz"
    }
  });

  const { fetchReceipts, receipts } = data;

  // const receipts =
  //   target === "entertain" ? data.entertainReceipts : data.receipts;

  useEffect(() => {
    branch && handleChangeBranch(branch);
  }, [branch]);

  useEffect(() => {
    computeEmpAndAgent();
  }, [receipts, selectedEmp]);

  const computeEmpAndAgent = () => {
    let empUidObj = {};
    _.forEach(receipts, rec => {
      let emp = null;

      if (target === "emp") {
        if (rec.therapistList) {
          emp = rec.therapistList;
        } else {
          emp = rec.therapist;
        }
      } else if (target === "agent") {
        // emp = rec.agent;

        let foundAgent = _.find(
          rec.payment.paymentTypes,
          payment => payment.type === "cityLedger"
        );
        if (!foundAgent || !foundAgent.payload.name) return;
        emp = foundAgent.payload;
      } else if (target === "entertain") {
        let foundEntertain = _.find(
          rec.payment.options,
          payment => payment.uid === "entertain001"
        );
        if (!foundEntertain || !foundEntertain.payload.name) return;
        emp = foundEntertain.payload;
      } else if (target === "sales") {
        emp = rec.sales;
      }

      if (!emp) return;
      //COMPUTE MULTI THERAPIST
      if (_.isArray(emp)) {
        _.forEach(emp, empLine => {
          computeMultiEmpHours(empLine, rec, empUidObj);
        });
      } else {
        //COMPUTE SINGLE THERAPIST
        computeEmpHours(emp, rec, empUidObj);
      }
    });

    //FILTER THERAPIST
    if (selectedEmp.value !== "all") {
      if (empUidObj[selectedEmp.value]) {
        let newEmpUidObj = {};
        //pick only selected
        newEmpUidObj[selectedEmp.value] = empUidObj[selectedEmp.value];
        empUidObj = newEmpUidObj;
      } else {
        empUidObj = {};
      }
    }

    props.setComputedData(empUidObj);
  };
  const getCurrentBranch = () => {
    if (branch && branch.value === "all") {
      return props.user.shopId;
    }
    if (branch) {
      return branch.value;
    } else {
      return props.user.shopId;
    }
  };
  const searchByDateRange = () => {
    let aaa = moment(startDateObj)
      .startOf("day")
      .format("YYYY-MM-DD");
    let bbb = moment(endDateObj)
      .endOf("day")
      .format("YYYY-MM-DD");
    bbb += "zzzzzzzzzzzzzzzz";
    fetchReceipts(getCurrentBranch(), {
      dateRange: {
        startDate: aaa,
        endDate: bbb
      },
      //ทุกสาขา
      hqUid: branch && branch.value === "all" ? props.user.shopId : null
    });
  };

  const handleChangeBranch = branch => {
    // setBranch(branch);

    let aaa = moment(startDateObj)
      .startOf("day")
      .format("YYYY-MM-DD");
    let bbb = moment(endDateObj)
      .endOf("day")
      .format("YYYY-MM-DD");
    bbb += "zzzzzzzzzzzzzzzz";

    fetchReceipts(branch.value, {
      dateRange: {
        startDate: aaa,
        endDate: bbb
      },
      //ทุกสาขา
      hqUid: branch.value === "all" ? props.user.shopId : null
    });
  };

  let selectedOptions = null;
  if (target === "emp") {
    selectedOptions = props.employeeOptions;
  } else if (target === "agent") {
    selectedOptions = props.agentOptions;
  } else if (target === "entertain") {
    selectedOptions = props.entertainOptions;
  } else if (target === "sales") {
    selectedOptions = props.salesOptions;
  }

  return (
    <div className="card mb-3">
      <div className="card-content">
        <div className="p-3">
          <div className="row m-0 justify-content-between">
            <div>
              <Row
                col1={
                  <Row
                    col1={<span style={{ fontSize: 15 }}>Start: </span>}
                    col2={
                      <DatePicker
                        className="form-control"
                        selected={startDateObj}
                        onChange={setStartDateObj}
                        dateFormat="dd/MM/yyyy"
                        id="date-pick"
                        style={{ width: 200 }}
                      />
                    }
                  />
                }
                col2={
                  <Row
                    col1={<span style={{ fontSize: 15 }}>End: </span>}
                    col2={
                      <DatePicker
                        className="form-control"
                        selected={endDateObj}
                        onChange={setEndDateObj}
                        dateFormat="dd/MM/yyyy"
                        id="date-pick"
                        style={{ width: 200 }}
                      />
                    }
                  />
                }
                col3={
                  <button
                    class="btn btn-space btn-primary"
                    style={{ width: 70, height: 47.984, border: 0 }}
                    onClick={searchByDateRange}
                  >
                    GO
                  </button>
                }
              />
            </div>
            <div style={{ width: 300 }}>
              <Select
                value={selectedEmp}
                onChange={selectEmp}
                options={[optionAll, ...selectedOptions]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Row = ({ col1, col2, col3 }) => {
  return (
    <div className="row m-0">
      <div className="align-self-center">{col1}</div>
      <div className="ml-2">{col2}</div>
      {col3 && <div className="ml-2">{col3}</div>}
    </div>
  );
};

const computeEmpHours = (emp, rec, empUidObj) => {
  if (!empUidObj[emp.uid]) {
    empUidObj[emp.uid] = {
      receipts: [],
      hours: moment.duration()
    };
  }

  empUidObj[emp.uid].receipts.push(rec);
  let { startDate, endDate } = rec.meta;
  //compute time
  var duration = moment.duration(moment(endDate).diff(moment(startDate)));
  empUidObj[emp.uid].hours.add(duration.asMinutes(), "minutes");

  //   var minutes = empUidObj[emp.uid].hours.asMinutes();
  //   var hours = empUidObj[emp.uid].hours.asHours();
};

const computeMultiEmpHours = (empLine, rec, empUidObj) => {
  if (!empUidObj[empLine.uid]) {
    empUidObj[empLine.uid] = {
      receipts: [],
      hours: moment.duration()
    };
  }

  empUidObj[empLine.uid].receipts.push(rec);

  _.forEach(empLine.items, item => {
    if (!item.timeMinutes) return;
    let totalTimeMinute = 0;
    let timeMinutes = item.qty * item.timeMinutes;
    totalTimeMinute += timeMinutes;

    empUidObj[empLine.uid].hours.add(totalTimeMinute, "minutes");
  });
};
