import React, { useState, useEffect, useReducer } from "react";
import _ from "lodash";
import moment from "moment";

const DRAWER_LOCAL = "DRAWER_LOCAL";
let init = genInit();

function genInit() {
  return {
    enable: true,
    cashInit: 0,
    startDate: null,
    businessDate: null,
    shift: null,

    cashIn: 0,
    cashOut: 0,
    cashVoided: 0,

    cashInCount: 0,
    cashOutCount: 0,
    cashVoidedCount: 0,

    actualCash: 0,
    endDate: null
  };
}

function reducer(state, action) {
  if (typeof state === "undefined") {
    return init;
  }

  switch (action.type) {
    case "DRAWER_ENABLE":
      return _.assign({}, state, {
        enable: true
      });
    case "DRAWER_RESET":
      return _.assign({}, genInit());
    case "DRAWER_START":
      let newState = _.assign({}, state, {
        cashInit: parseFloat(action.payload || 0),
        startDate: moment().format(),
        businessDate: moment().format("YYYY-MM-DD"),
        shift: moment().format("YYYY-MM-DD"),
        enable: true
      });

      action.callback && action.callback(newState);
      return newState;
    case "DRAWER_END":
      return _.assign({}, state, {
        actualCash: parseFloat(action.payload || 0),
        endDate: moment().format()
      });

    case "DRAWER_CASH_IN":
      return _.assign({}, state, {
        cashIn: parseFloat(action.payload || 0) + state.cashIn,
        cashInCount: state.cashInCount + 1
      });
    case "DRAWER_CASH_VOIDED":
      if (action.payload.PaymentType === "Cash") {
        return _.assign({}, state, {
          cashVoided: action.payload.GrandTotal + state.cashVoided,
          cashVoidedCount: state.cashVoidedCount + 1
        });
      } else {
        return state;
      }

    case "DRAWER_CASH_OUT":
      return _.assign({}, state, {
        cashOut: parseFloat(action.payload || 0) + state.cashOut,
        cashOutCount: state.cashOutCount + 1
      });
    default:
      return state;
  }
}

function useDrawerReducer() {
  const [state, dispatch] = useReducer(
    reducer,
    loadState(DRAWER_LOCAL) || init
  );

  useEffect(() => {
    save();
  }, [state]);
  function save() {
    saveState(DRAWER_LOCAL, state);
  }
  return {
    state,
    dispatch
  };
}

export default useDrawerReducer;

//--- internal
function loadState(name) {
  try {
    const serializedState = window.localStorage.getItem(name + "_");
    if (serializedState === null) {
      return undefined;
    } else {
      return JSON.parse(serializedState);
    }
  } catch (error) {
    return undefined;
  }
}

function saveState(name, state) {
  try {
    const serializedState = JSON.stringify(state);
    window.localStorage.setItem(name + "_", serializedState);
  } catch (error) {
    console.log(error.message);
  }
}
