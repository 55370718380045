import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  ReceiptRow,
  ReceiptHeader,
  ReceiptGroup,
  ReceiptGroupRow,
  ReceiptGroupRow2
} from "./ReceiptRow";
import { useRadioViewButtons } from "./Components";
import axios from "axios";
import { downloadableLinkUrl } from "../../../shared/utils";
import { useDispatch } from "react-redux";
import * as appStateAction from "../../../shared/actions/appStateActions";
import Select from "react-select";
import { voidBill } from "../../../shared/actions/spaPosActions";
import { checkPermission } from "../../../shared/utils";
import useEmployeeHook from "../../../shared/useHook/useEmployeeHook";

function DashboardEntertain(props) {
  const { receipts, receipts_entertain, dashboardDispatch, fetch } = props;
  const [agentGroups, setAgentGroups] = useState([]);
  const [view, Component] = useRadioViewButtons("detail");
  const [filterEntertain, setFilterEntertain] = useState([]);
  const [currentEmployee, employeeList] = useEmployeeHook();

  const dispatch = useDispatch();

  useEffect(() => {
    let _res = computeGroup(receipts_entertain);
    setAgentGroups(_res);
  }, [receipts]);

  function viewCallback(receipt) {
    dashboardDispatch({
      type: "SET_MODAL",
      payload: {
        data: receipt,
        visible: true
      }
    });
  }

  const downloadPdf = () => {
    let URL =
      "https://asia-east2-niceloop-dev.cloudfunctions.net/PDFSummaryByDate";
    let data = {
      mode: "entertains",
      startDate: props.startDate,
      endDate: props.endDate,
      shopId: props.shop.uid,
      filter: filterEntertain.length > 0 ? filterEntertain : null
    };

    dispatch(appStateAction.loading(true));

    axios
      .post(URL, data)
      .then(res => {
        downloadableLinkUrl("report-summary-excel.xlsx", res.data);
      })
      .catch(err => alert(err))
      .finally(() => {
        dispatch(appStateAction.loading(false));
      });
  };

  function deleteCallback(receipt) {
    let access = checkPermission(currentEmployee, employeeList, "voidBills");
    if (access === false) {
      return alert("You don't have permission to delete bills.");
    }

    let reason = window.prompt("Enter reason.");
    if (reason) {
      dispatch(
        voidBill(receipt, reason, () => {
          fetch();

          dashboardDispatch({
            type: "SET",
            key: "view",
            payload: "receipts"
          });
        })
      );
    }
  }

  return (
    <div class="">
      <div class="col-lg-12">
        <div class="card card-table">
          <div class="card-header">
            {Component}
            Group by {_.keys(agentGroups).length} Entertains
            <span style={{ marginLeft: 36, color: "blue" }}>
              found {_.reduce(agentGroups, (acc, v) => acc + v.length, 0)} bills
              from {receipts.length} bills
            </span>
            <div className="row m-0">
              <div className="col-6 pl-0">
                <Select
                  isMulti
                  value={filterEntertain}
                  onChange={setFilterEntertain}
                  options={props.entertainOptions}
                  placeholder="All"
                />
              </div>
              <div>
                <button
                  class="btn btn-space btn-secondary"
                  onClick={downloadPdf}
                >
                  Export PDF
                </button>
              </div>
            </div>
          </div>
          <div class="card-body pb-7">
            <table class="table table-small table-striped">
              {view === "detail" && (
                <ReceiptHeader showAgent={false} showTherapist={false} />
              )}
              <tbody>
                {_.map(agentGroups, (receipts, gName) => {
                  return [
                    <ReceiptGroupRow2
                      name={gName}
                      count={receipts.length}
                      col1Text={
                        "GrandTotal : " +
                        _.sumBy(receipts, "payment.grandTotal") +
                        ` (${receipts.length})`
                      }
                    />,
                    view === "detail" &&
                      _.map(receipts, (r, index) => (
                        <ReceiptRow
                          linkToKey="entertain"
                          viewCallback={() => viewCallback(r)}
                          deleteCallback={() => deleteCallback(r)}
                          showAgent={false}
                          showTherapist={false}
                          showVoidButton={true}
                          index={index + 1}
                          {...r}
                        />
                      ))
                  ];
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardEntertain;

function computeGroup(receipts) {
  let onlyEntertainReceipts = _.filter(receipts, r => {
    let found = _.some(r.payment.options, { uid: "entertain001" });
    return found;
  });


  return _.groupBy(onlyEntertainReceipts, r => {
    let found = _.find(r.payment.options, { uid: "entertain001" });
    return found.payload.name || "UNKNOWN";

    // return (r.agent && r.agent.name) || "UNKNOWN";
  });
}
