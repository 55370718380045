import React, { useState } from "react";
import _ from "lodash";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import swal from "sweetalert";
function VoucherList(props) {
  // const [count, setCount] = useState(0);
  const { match, history, isHq } = props;

  const goToManageVoucher = () => {
    history.push(`${match.url}/manage`);
  };

  const editVoucher = voucher => {
    history.push(
      `${match.url}/manage/${voucher.storeUid}/${voucher.voucherCampaignUid}`
    );
  };

  const removeVoucher = async voucher => {
    let confirm = await swal({
      title: `ลบ ${voucher.name} ?`,
      icon: "warning",
      buttons: true
    });

    if (!confirm) return;

    var params = {
      TableName: "Spa_Voucher",
      Key: {
        storeUid: voucher.storeUid,
        voucherCampaignUid: voucher.voucherCampaignUid
      }
    };
    try {
      await documentClient.delete(params).promise();
      props.actions.fetchSpaVoucher(() => {
        swal("ลบ Voucher สำเร็จ", "", "success");
      });
    } catch (error) {
      console.log(error);
    }
  };

  let vouchers = props.voucherCampaign;

  return (
    <div class="card card-table">
      <div class="card-header">
        Vouchers
        {isHq && (
          <div class="tools dropdown">
            <button
              class="btn btn-space btn-success"
              onClick={goToManageVoucher}
            >
              <i
                class="icon icon-left mdi mdi-plus"
                style={{ color: "white" }}
              ></i>
              Voucher
            </button>
          </div>
        )}
      </div>
      <div class="card-body">
        <table class="table table-striped table-borderless">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Qty</th>
              <th>Age (Day)</th>
              {isHq && <th class="actions">Actions</th>}
            </tr>
          </thead>
          <tbody class="no-border-x">
            {_.map(vouchers, voucher => {
              return (
                <tr>
                  <td>{voucher.name}</td>
                  <td>{voucher.description}</td>
                  <td>{voucher.qty}</td>
                  <td>{voucher.age}</td>
                  {isHq && (
                    <td class="actions">
                      <a
                        class="icon"
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          editVoucher(voucher);
                        }}
                      >
                        <i class="mdi mdi-edit"></i>
                      </a>
                      <a
                        class="icon pl-3"
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          removeVoucher(voucher);
                        }}
                      >
                        <i class="mdi mdi-delete"></i>
                      </a>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default VoucherList;
