import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Step1BuyProduct from './Step1BuyProduct'
import Step2ProductSummary from './Step2ProductSummary'
import swal from 'sweetalert'
import * as cartAction from '../../shared/actions/cartActions'
import * as linkedSelector from '../../shared/selector/linkedSelector'
import { bindActionCreators } from 'redux'

const stepWidth = 150

class Shopping extends Component {

    state = {
        step: 1,
        isEdit: false
    }
    componentDidMount = () => {
        const { match, actions, cartMetaData } = this.props
        if (match.params.invoiceId) {
            this.setState({
                isEdit: true
            })
            return
        }

        if (cartMetaData.isNotClearCart) {
            // actions.dontClearCartNextTime(false)
        } else {
            actions.clearCart()
        }

    }

    computeStep = (stopNo) => {
        const { step } = this.state
        let stepClass
        if (step === stopNo) {
            stepClass = 'active'
        }
        if (step > stopNo) {
            stepClass = 'complete'
        }
        return stepClass
    }
    handleClickNextStep = () => {
        const { step } = this.state
        const { cartItems, isLinked, cartHqInfo } = this.props

        if (!isLinked && cartHqInfo.destination === null) { //HQ MUST SELECT BRANCH
            swal("เกิดข้อผิดพลาด", 'กรุณาเลือกสาขา', "error");
            return
        }

        if (_.isEmpty(cartItems)) {
            swal("เกิดข้อผิดพลาด", 'กรุณาเลือกสินค้า', "error");
            return
        }


        if (step === 3) {
            return
        }
        this.setState({ step: step + 1 })
    }
    handleClickBackStep = () => {
        const { step } = this.state

        if (step === 1) {
            return
        }
        this.setState({ step: step - 1 })
    }
    getClassNameAside = () => {
        switch (this.state.step) {
            case 1: return 'main-content'
            case 2: return 'main-content'
            default: return ""
        }


    }

    componentWillUnmount(){
        const { actions } = this.props
        actions.setCartFailList([])
    }
    render() {
        const { step, isEdit } = this.state
        const { match, history } = this.props
        // const { shopId, timestamp } = match.params

        const wizardClass = {
            position: 'fixed',
            zIndex: 9999,
            width: '85%',
            //borderBottom: '1px solid #d4d4d4'
        }
        if(step === 1){
            return <Step1BuyProduct isEdit={isEdit} handleClickNextStep={this.handleClickNextStep} />
        }
         
        if(step === 2){
            return <Step2ProductSummary
            isEdit={isEdit}
            handleClickBackStep={this.handleClickBackStep}
            params={match.params}
            history={history} />
       }
         
        
        return [
            // widzard
            // <div className="row wizard-row" style={wizardClass}>
            //     <div className="col-md-12 fuelux" style={{ paddingRight: 0 }}>
            //         <div className="block-wizard" style={{ borderBottom: '1px solid #d4d4d4' }}>
            //             <div className="wizard wizard-ux" id="wizard1">
            //                 <div className="steps-container">
            //                     <ul className="steps" style={{ marginLeft: 0 }}>
            //                         <li style={{ width: stepWidth }} className={(step === 1 ? "active" : 'complete')} data-step="1">Step 1<span className="chevron"></span></li>
            //                         <li style={{ width: stepWidth }} className={this.computeStep(2)} data-step="2">Step 2<span className="chevron"></span></li>
            //                     </ul>
            //                 </div>
            //                 <div className="actions">
            //                     <button disabled={step === 1} className="btn btn-xs btn-prev btn-secondary" type="button"
            //                         onClick={this.handleClickBackStep} ><i className="icon mdi mdi-chevron-left"></i>  Prev</button>
            //                     <button disabled={step === 2} onClick={this.handleClickNextStep} className="btn btn-xs btn-next btn-secondary" type="button" data-last="Finish">Next<i className="icon mdi mdi-chevron-right"></i></button>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>
            ,
            <div className={this.getClassNameAside()} >
                <div className="row">
                    <div className="col-12 col-md-12 ">
                        {/* <div className="step-content">
                            <div className={"step-pane active"} > */}
                                <div className="container p-0">
                                     
                                        {step === 1 && <Step1BuyProduct isEdit={isEdit} handleClickNextStep={this.handleClickNextStep} />}
                                        {step === 2 && <Step2ProductSummary
                                            isEdit={isEdit}
                                            handleClickBackStep={this.handleClickBackStep}
                                            params={match.params}
                                            history={history} />}
                                     
                                </div>
                            </div>
                        {/* </div>
                    </div> */}
                </div>
            </div>
        ]
    }
}

const mapStateToProps = (state) => {
    return {
        cartItems: state.cart.items,
        cartHqInfo: state.cart.cartHqInfo,
        isLinked: linkedSelector.isLinkedSelector(state),
        cartMetaData: state.cart.cartMetaData
    }
}
function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ ...cartAction }, dispatch)
    return {
        actions,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Shopping)