import _ from "lodash";

function computePaymentType(_paymentTypes, _grandTotal, _change) {
  //===Compute Payment Type===
  let tmpPaymentTypes = [];
  if (_paymentTypes.length < 1) return [];
  //Filter
  _.forEach(_paymentTypes, line => {
    let tmpAmount = parseInt(line.amount);
    if (line.amount === "" || _.isNaN(tmpAmount)) return;
    tmpPaymentTypes.push({ ...line, amount: tmpAmount });
  });

  let newPaymentType = _.cloneDeep(tmpPaymentTypes);

  if (_.size(_paymentTypes) === 1) {
    newPaymentType[0].amount -= _change;
  } else {
    let sliced = tmpPaymentTypes.slice(0, _paymentTypes.length - 1);
    let sum = 0;
    _.forEach(sliced, item => {
      sum += item.amount;
    });
    newPaymentType[_paymentTypes.length - 1].amount = Math.abs(
      sum - _grandTotal
    );
  }
  return newPaymentType;
}

export default computePaymentType;
