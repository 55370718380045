import React, { useState, useEffect } from "react";
import SummaryCard from "../../../pages/SpaReport/SummaryCard";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TopUpForm from "./topUpForm";
import * as memberTxActions from "../../actions/memberTxActions";
import documentClient from "../../awsAPIs/dynamoDb";
import MemberTxTable from "./MemberTxTable";

function WalletModal(props) {
  const [txViewMode, setTxViewMode] = useState("topUp");
  const [txList, setTxList] = useState([]);

  const customer = props.customer || {};
  const { currentEmp } = props;

  const handleChangeTxViewMode = mode => {
    fetchMemberTx(mode);
    setTxViewMode(mode);
  };

  const fetchCustomer = () => {
    if (!customer.uid) return;

    var params = {
      TableName: "Crm_Users",
      Key: {
        uid: customer.uid
      }
    };

    documentClient.get(params, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        props.setCustomer && props.setCustomer(data.Item);
      }
    });
  };

  const fetchMemberTx = forceMode => {
    let mode = forceMode || txViewMode;

    if (mode === "topUp") {
      setTxList([]);
      return;
    }

    props.actions.fetchMemberTx(customer.uid, mode, items => {
      setTxList(items);
    });
  };

  useEffect(() => {
    fetchMemberTx();
    fetchCustomer();
  }, [props.timestamp]);

  const getTopupPermission = mode => {
    if (!currentEmp) {
      return false;
    }
    if (currentEmp.role === "admin") {
      return true;
    }
    let memberPermission = currentEmp.memberPermission || {};
    if (memberPermission.all === true) {
      return true;
    }
    if (mode === "wallet" && memberPermission.topupWallet === true) {
      return true;
    }
    if (mode === "point" && memberPermission.topupPoint === true) {
      return true;
    }

    return false;
  };

  return (
    <div
      class="custom-width modal"
      id="walletModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="walletModalLabel"
      aria-hidden="true"
      //   style={{ visibility: visible ? "visible" : "hidden" }}
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style={{ width: 1000 }}
      >
        <div class="modal-content" style={{ width: 1000 }}>
          <div class="modal-header pb-0">
            {/* <h3 class="modal-title" id="walletModalLabel"> */}
            {/* <span style={{ fontSize: 23, fontWeight: "bold" }}>
              {customer.name}
            </span> */}
            {/* </h3> */}
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div className="row m-0">
              <div className="col-4">
                <center>
                  <span style={{ fontSize: 35, fontWeight: "bold" }}>
                    {customer.name}
                  </span>
                </center>
                <div class="dropdown-divider"></div>
                <BalanceCard
                  amount={(customer.walletBalance || 0).toLocaleString()}
                  label={"Balance"}
                />
                {/* <BalanceCard
                  amount={(customer.pointBalance || 0).toLocaleString()}
                  label={"Points"}
                /> */}
              </div>
              <div
                className="col-8"
                style={{
                  height: 500,
                  borderLeftWidth: 1,
                  borderLeftColor: "lightgrey",
                  borderLeftStyle: "solid",
                  overflowY: "auto"
                }}
              >
                {/* <h3 class="modal-title" id="walletModalLabel">
                  Transactions
                </h3> */}
                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#"
                      role="tab"
                      className={`nav-link ${
                        txViewMode === "topUp" ? "active" : ""
                      }`}
                      onClick={e => {
                        e.preventDefault();
                        handleChangeTxViewMode("topUp");
                      }}
                    >
                      Top Up
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      className={`nav-link ${
                        txViewMode === "wallet" ? "active" : ""
                      }`}
                      href="#"
                      role="tab"
                      onClick={e => {
                        e.preventDefault();
                        handleChangeTxViewMode("wallet");
                      }}
                    >
                      Wallet
                    </a>
                  </li>
                  {/* <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#"
                      role="tab"
                      className={`nav-link ${
                        txViewMode === "point" ? "active" : ""
                      }`}
                      onClick={e => {
                        e.preventDefault();
                        handleChangeTxViewMode("point");
                      }}
                    >
                      Point
                    </a>
                  </li> */}
                </ul>
                <div className="mt-3">
                  {txViewMode === "wallet" && <MemberTxTable txList={txList} />}
                  {txViewMode === "point" && <MemberTxTable txList={txList} />}
                  {txViewMode === "topUp" && (
                    <div className="row m-0">
                      <div className="col-8 offset-2">
                        <TopUpForm
                          {...props}
                          customer={customer}
                          getTopupPermission={getTopupPermission}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div class="modal-footer pt-2">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary">
              Save changes
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    linked: state.shop.linked.inventory,
    shop: state.shop.detail
  };
};
function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...memberTxActions }, dispatch);

  return {
    actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletModal);

const BalanceCard = ({ amount, label }) => {
  return [
    <div className="mb-3 mt-2">
      <div>
        <center>
          <span style={{ fontSize: 30, color: "blue" }}>{amount}</span>
        </center>
      </div>
      <div className="mt-2">
        <center>
          <span style={{ fontSize: 17 }}>{label}</span>
        </center>
      </div>
    </div>,
    <div class="dropdown-divider"></div>
  ];
};
