import React, { useState } from "react";
import Employee from "./Employee";
import { connect } from "react-redux";
import * as spaPosActions from "../../shared/actions/spaPosActions";
import * as spaSalesActions from "../../shared/actions/spaSalesActions";
import * as branchSelector from "../../shared/selector/branchOptionsSelector";

import { bindActionCreators } from "redux";
// import * as inventorySelector from "../../shared/selector/inventorySelector";

function SpaEmpContainer(props) {
  const [view, setView] = useState("Employee");

  return (
    <div className="main-content">
      <div className="card">
        {/* <div className="card-header">Icon Text Tabs</div> */}
        <div className="tab-container">
          <ul className="nav nav-tabs" role="tablist">
            <Tab
              onClick={() => {
                setView("Employee");
              }}
              title="Therapist"
              icon="icon mdi mdi-home"
              isActive={true}
            />
            <Tab
              onClick={() => {
                setView("Agent");
              }}
              title="Agent"
              icon="icon mdi mdi-grid"
              isActive={false}
            />
            <Tab
              onClick={() => {
                setView("Entertain");
              }}
              title="Entertain"
              icon="icon mdi mdi-grid"
              isActive={false}
            />
            <Tab
              onClick={() => {
                setView("Sales");
              }}
              title="Sales"
              icon="icon mdi mdi-grid"
              isActive={false}
            />
          </ul>
          <div className="tab-content m-0 p-0">
            <div className="tab-pane active" id="home5" role="tabpanel">
              {/* {view === 0 && <Employee {...props} />} */}
              <Employee {...props} view={view} />
              {/*{view === 2 && <Products {...props} />} */}
            </div>
          </div>
        </div>
      </div>
      {/* {view === 0 && <Summary {...props} />} */}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    // spaPos: state.spa.pos,
    shop: state.shop.detail,
    branchOptions: branchSelector.branchOptionsSelector(state)
  };
};
function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators(
    { ...spaPosActions, ...spaSalesActions },
    dispatch
  );
  return {
    actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SpaEmpContainer);

const Tab = ({ title, icon, isActive, onClick }) => {
  return (
    <li className="nav-item">
      <a
        class={`nav-link ${isActive ? "active" : ""}`}
        href="#home5"
        data-toggle="tab"
        role="tab"
        onClick={onClick}
      >
        <span class={icon} />
        {title}
      </a>
    </li>
  );
};
 