import React, { useState, useEffect } from "react";
import BookingGrid from "./BookingGrid";
import BookingList from "./BookingList";
import BookingGridRoom from "./BookingGridRoom";
import _ from 'lodash';
import documentClient from "../../shared/awsAPIs/dynamoDb";
 
function BookingMain(props) {
  const [tab, setTab] = useState("grid-room"); 
  const [view, setView] = useState(0)  // 0  - room,  1 = detail
  useEffect(() => {
    window.addEventListener("beforeunload", ev => {
      ev.preventDefault();
      unmount();
      // return (ev.returnValue = "Are you sure you want to close?");
    });
  }, []);

  const unmount = () => {
    localStorage.removeItem("bookingFilterDate");
  };

  return (
    <div>
      <div class="tab-container">
        <ul class="nav nav-tabs" role="tablist">
        <Tab
            label="Room"
            active={tab === "grid-room"}
            onClick={() => setTab("grid-room")}
          />
          <Tab
            label="Detail"
            active={tab === "grid"}
            onClick={() => setTab("grid")}
          />
          <Tab
            label="List"
            active={tab === "list"}
            onClick={() => setTab("list")}
          />
        </ul>
        {tab === "grid-room" && <BookingGridRoom {...props}  Capacity={Capacity}  setTab={() => setTab("grid")}/>}

        {tab === "grid" && <BookingGrid {...props}  Capacity={Capacity}/>}
        {tab === "list" && <BookingList {...props}  Capacity={Capacity}/>}

      </div>
    </div>
  );
}

export default BookingMain;

const Tab = ({ active, label, onClick }) => {
  return (
    <li class="nav-item">
      <a
        class={`nav-link ${active ? "active" : ""}`}
        href="#home"
        data-toggle="tab"
        role="tab"
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
      >
        {label}
      </a>
    </li>
  );
};



// store = storeuid
function Capacity ({date, storeUid ={} }){
  const [capacity, setCapacity] = useState(99);

  async function fetch(){

     var params = {
      TableName: "Settings",
      KeyConditionExpression: "uid = :hkey",

      ExpressionAttributeValues: {
        ":hkey": (storeUid|| 999).toString()
      }
    };
    let settingsRes = await documentClient.query(params).promise();

    let foundCapacity = _.find(settingsRes.Items, s=> s.name == 'capacity') 
    if(foundCapacity && foundCapacity.value ){
        let _capacity =  foundCapacity.value[date] || 5
        setCapacity (parseInt(_capacity))
    }

  }

  useEffect(() => {
    if(storeUid != null ){
      fetch()
    }
  }, [storeUid])

  useEffect(() => {
    if(  date != null){
      fetch()
    }
  }, [ date])

  return (
    <div className="ml-2" style={{  }}>
    <label>Capacity</label>
    <div style={{display : 'block'}}>
      <h3 class="m-0">{capacity} </h3>
    </div>
    
  </div>
  )

}