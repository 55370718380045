import React, { useState, useEffect } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import * as posCartActions from "./actions";
import _ from "lodash";
const css = {
  height: 32
};
const cssPassingTop = {
  paddingTop: 12
};

const salesList = [
  { name: "Mr. sales-X", uid: "b0", row: 0 },
  { name: "Mr. sales-Y", uid: "b1", row: 0 },
  { name: "Mr. sales-Z", uid: "b2", row: 0 }
];

const memberList = [
  { name: "Mr. member-A", uid: "a0", row: 0 },
  { name: "Mr. member-B", uid: "a1", row: 0 },
  { name: "Mr. member-C", uid: "a2", row: 0 }
];

function ShoppingCartContainer(props) {
  // const [scanRef, setscanRef] = useState(React.createRef());
  const [item, setItem] = useState(null);
  const [qty, setQty] = useState(1);
  const [member, setMember] = useState(null);
  const [sales, setSales] = useState(null);
  const [barcodeInput, setBarcodeInput] = useState("");
  function confimScan(evt) {
    var text = evt.target.value;
    const { dispatch } = props;

    var res = _.find(
      props.menu_items_original,
      itemLine => itemLine.barcode === text
    );
    if (res) {
      dispatch(posCartActions.addItem(res, 1));
      setBarcodeInput("");
    } else {
      setBarcodeInput(text);
    }
  }
  function addItem(selectedOption) {
    console.log(`Option selected:`, selectedOption);

    const { dispatch } = props;

    dispatch(posCartActions.addItem(selectedOption.value, qty));
    setQty(1);
  }

  function setOptionSelected(setKey, value) {}
  useEffect(() => {
    // scanRef.current.focus();
  }, []);
  const { setView, view, qtyCounts } = props;
  return (
    <div className="card card-flat">
      <div className="tab-container">
        <ul className="nav nav-tabs card-nav-tabs" role="tablist">
          <li className="nav-item" style={cssPassingTop}>
            <a
              className={`nav-link  ${view===0 ? 'active' : ''}  show`}
              // href="#active2"
              // data-toggle="tab"
              // role="tab"
              // aria-selected="true"
              onClick={() => {
                setView(0);
                // setTimeout(() => {
                //   scanRef.current.focus();
                // }, 200);
              }}
            >
              Cart {qtyCounts !== 0 && <span>({qtyCounts})</span>}
            </a>
          </li>
          <li className="nav-item" style={cssPassingTop}>
            <a
              className={`nav-link ${view==1 ? 'active' : ''} show`}
              // href="#link2"
              // data-toggle="tab"
              // role="tab"
              // aria-selected="false"
              onClick={() => setView(1)}
            >
              Product List
            </a>
          </li>
          {view === 0 && (
            <li className="nav-item" style={{ width: "70%" }}>
              <a className="nav-link   show">
                <div className="row">
                  {/* SKU */}
                  <div className="col-lg-12 d-flex flex-row">
                    <div className="align-self-end">
                      <label
                        className=""
                        for="inputText3"
                        style={{ paddingtop: 8 }}
                      >
                        Scan
                      </label>
                    </div>
                    <div className="flex-grow-1 " style={{ marginLeft: 16 }}>
                      <input
                        // ref={scanRef}
                        className="form-control"
                        type="text"
                        style={css}
                        value={barcodeInput}
                        onChange={confimScan}
                        style={{ height: 38 }}
                      />
                    </div>
                  </div>

                  {/* QTY */}
                  {/* <div className="col-lg-4 d-flex flex-row">
                    {" "}
                    <div className="align-self-end">
                      {" "}
                      <label className="" for="inputText3">
                        Qty
                      </label>{" "}
                    </div>
                    <div
                      className="input-group mb-0"
                      style={{ marginLeft: 16 }}
                    >
                      <div
                        className="input-group-prepend"
                        onClick={() => setQty(qty - 1 === 0 ? 1 : qty - 1)}
                        style={css}
                      >
                        <span className="input-group-text">-</span>
                      </div>
                      <input
                        className="form-control"
                        type="text"
                        style={css}
                        value={qty}
                      />
                      <div
                        className="input-group-append"
                        style={css}
                        onClick={() => setQty(qty + 1)}
                      >
                        <span className="input-group-text">+</span>
                      </div>
                    </div>
                  </div>
                 */}
                </div>{" "}
              </a>
            </li>
          )}
          {view === 1 && (
            <li className="nav-item" style={{ width: "70%" }}>
              <a className="nav-link   show">
                <div className="row justify-content-end">
                  {/* SKU */}
                  <div className="col-lg-8 d-flex justify-content-end flex-row">
                    <div className="align-self-end">
                      <label className="" for="inputText3">
                        Search
                      </label>
                    </div>
                    <div className="flex-grow-1 " style={{ marginLeft: 16 }}>
                      <Select
                        styles={{
                          container: () => {
                            return { height: 36 };
                          }
                        }}
                        onChange={addItem}
                        value={item}
                        options={props.menu_items}
                      />
                    </div>
                  </div>
                </div>{" "}
              </a>
            </li>
          )}
        </ul>
      </div>

      <div className="card-body">{props.children}</div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  //REMOVE HIDDEN ITEMS
  let filtered = _.filter(state.menu_items, item => item.hidden !== true);
   
  let search = _.map(filtered, line => {
    return {
      label: line.barcode ? line.name  +  ` (${line.barcode})`  : line.name ,
      value: line
    };
  });

  let memberOptions = _.map(memberList, line => {
    return {
      label: line.name,
      value: line
    };
  });

  let salesOptions = _.map(salesList, line => {
    return {
      label: line.name,
      value: line
    };
  });

  return {
    menu_items_original: state.menu_items,
    menu_items: search,
    memberOptions,
    salesOptions,
    qtyCounts: _.reduce(
      state.qtyHolder,
      (acc, value) => {
        acc += value;
        return acc;
      },
      0
    )
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingCartContainer);
