import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import ManageCustomerModal from "../../shared/components/ManageCustomerModal";
import WalletModal from "../../shared/components/WalletModal";
import swal from "sweetalert";
function MemberListTable(props) {
  const { isRetail } = props;

  const [customerList, setCustomerList] = useState([]);
  const [inputCustomer, setInputCustomer] = useState("");
  const [lastKey, setLastKey] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [filterMode, setFilterMode] = useState("name"); //name,mobileLocal,memberId
  const [uidHolder, setUidHolder] = useState({});

  useEffect(() => {
    setInputCustomer("");
  }, [filterMode]);

  const fetchCustomers = (pageQty = 0, isClear) => {
    //ALREADY HAS
    let nextPage = pageNo + pageQty;
    if (pageQty > 0 && customerList[nextPage]) {
      setPageNo(nextPage);
      return;
    }

    //FETCH MORE
    var params = {
      TableName: "Crm_Users",
      IndexName: "hqUid-expireDate-index",
      KeyConditionExpression: "hqUid = :hqUid AND expireDate < :expireDate",
      //   FilterExpression: "contains(#expiredDate, :expireDate)",
      //   ExpressionAttributeNames: {
      //     "#expireDate": "expireDate"
      //   },
      ExpressionAttributeValues: {
        ":expireDate": moment().toISOString(),
        ":hqUid": props.linked || props.shop.uid,
      },
      ScanIndexForward: false,
      Limit: 50,
    };

    if (isRetail) {
      params.FilterExpression = "businessUnit = :businessUnit";
      params.ExpressionAttributeValues[":businessUnit"] = "retail";
    } else {
      params.FilterExpression = "businessUnit <> :businessUnit";
      params.ExpressionAttributeValues[":businessUnit"] = "retail";
    }

    if (lastKey !== "max" && isClear !== true) {
      params.ExclusiveStartKey = lastKey;
    }

    documentClient.query(params, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        //SET LASTKEY
        if (data.LastEvaluatedKey) {
          setLastKey(data.LastEvaluatedKey);
        } else {
          setLastKey("max");
        }

        setInputCustomer("");
        setModalTimestamp(moment());

        if (isClear) {
          setCustomerList([data.Items]);
          setPageNo(0);
          return;
        }

        if (data.Items.length > 0) {
          setCustomerList([...customerList, data.Items]);
          setPageNo(nextPage);
        }
      }
    });

    setUidHolder({});
  };

  const fetchCustomersByFilter = () => {
    let params = {
      TableName: "Crm_Users",
      IndexName: "hqUid-createAt-index",
      KeyConditionExpression: "hqUid = :hqUid",
      FilterExpression: "contains(#filterMode, :input)",
      ExpressionAttributeNames: {
        "#filterMode": filterMode,
      },
      ExpressionAttributeValues: {
        ":input": inputCustomer,
        ":hqUid": props.linked || props.shop.uid,
      },
      ScanIndexForward: false,
    };

    documentClient.query(params, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        // let ordered = _.orderBy(data.Items, "createAt", "desc");
        // let options = getOptions(ordered);
        setPageNo(0);
        setLastKey(null);
        setCustomerList([data.Items]);
      }
    });
  };

  const backPage = () => {
    if (pageNo === 0) return;

    setPageNo(pageNo - 1);

    setUidHolder({});
  };
  useEffect(() => {
    fetchCustomers();
  }, []);

  const { setModalTimestamp, setCustomer, handleSetCustomer } = props;

  const filterHeight = 35;

  const handleKeyPressSearchByName = (event) => {
    if (event.key === "Enter") {
      fetchCustomersByFilter();
    }
  };

  const PageButtons = () => {
    return [
      <button
        style={{ height: filterHeight }}
        class="btn btn-primary"
        type="button"
        onClick={() => fetchCustomers(0, true)}
      >
        {"<< First"}
      </button>,

      <button
        style={{ height: filterHeight }}
        class="btn btn-primary ml-1"
        type="button"
        onClick={backPage}
        disabled={pageNo === 0}
      >
        {"< Back"}
      </button>,
      <div
        className="ml-3 mr-3 align-self-center"
        style={{ display: "inline" }}
      >
        <b>{pageNo + 1}</b>
      </div>,
      <button
        style={{ height: filterHeight }}
        class="btn btn-primary mr-2"
        type="button"
        onClick={() => fetchCustomers(1)}
        disabled={lastKey === "max" && pageNo + 1 === customerList.length}
      >
        {"Next >"}
      </button>,
    ];
  };

  const handleSetUidHoler = (customer) => {
    let tmpUid = _.cloneDeep(uidHolder);
    if (tmpUid[customer.uid]) {
      delete tmpUid[customer.uid];
    } else {
      tmpUid[customer.uid] = customer;
    }

    setUidHolder(tmpUid);
  };

  const setBalanceToZero = async () => {
    let customerNames = "";
    _.forEach(uidHolder, (c, idx) => {
      if (idx === _.size(uidHolder) - 1) {
        customerNames += c.name;
      } else {
        customerNames += c.name + ",";
      }
    });
    let confirm = await swal({
      title: `Adjust customers wallet balance to 0 ?`,
      text: customerNames,
      icon: "warning",
      buttons: true,
    });

    if (!confirm) return;

    await new Promise((resolve) => {
      let count = 0;
      _.map(uidHolder, (customer, uid) => {
        let amount = 0;
        if (customer.walletBalance === undefined) return;
        if (customer.walletBalance === 0) {
          count++;
          return;
        }

        if (customer.walletBalance > 0) {
          amount = -customer.walletBalance;
        } else {
          amount = customer.walletBalance * -1;
        }
        props.actions.pushMemberTx(
          uid,
          "wallet",
          { amount, type: "ADJUST" },
          () => {
            count++;
            if (count === _.size(uidHolder)) {
              resolve("Complete !");
            }
          }
        );
      });
    });

    fetchCustomers(0, true);
    setUidHolder({});
  };

  const addDays = async () => {
    let dayQty = await swal({
      text: "จำนวนวันท่ี่ต้องการเพิ่ม Ex: 30",
      content: "input",
      buttons: true,
    });

    //validate
    if (dayQty === null || dayQty === "" || _.isNaN(parseInt(dayQty))) return;

    let newExpireDate = moment().add(parseInt(dayQty), "day").toISOString();

    await new Promise((resolve) => {
      let count = 0;

      _.forEach(uidHolder, async (customer) => {
        var params = {
          TableName: "Crm_Users",
          Key: { uid: customer.uid },
          UpdateExpression: "set expireDate = :expireDate",
          ExpressionAttributeValues: {
            ":expireDate": newExpireDate,
          },
        };
        await documentClient.update(params).promise();
        count++;
        if (count === _.size(uidHolder)) {
          resolve("Complete !");
        }
      });
    });

    fetchCustomers(0, true);
    setUidHolder({});
  };

  return (
    <div>
      <ManageCustomerModal
        {...props}
        title="Edit Customer"
        fetchCustomers={() => fetchCustomers(0, true)}
      />
      <WalletModal {...props} fetchCustomers={() => fetchCustomers(0, true)} />
      <div class="card card-table">
        <div class="card-header">
          <div className="row m-0 justify-content-between">
            <div className="align-self-center">
              <div className="row m-0">
                <div>Member Expired</div>
                {/* Filter Input */}
                <div className="ml-2">
                  <button
                    style={{ height: filterHeight }}
                    class="btn btn-primary"
                    type="button"
                    onClick={setBalanceToZero}
                    disabled={_.size(uidHolder) < 1}
                  >
                    Set Balance to Zero
                  </button>
                  <button
                    style={{ height: filterHeight }}
                    class="btn btn-primary ml-1"
                    type="button"
                    onClick={addDays}
                    disabled={_.size(uidHolder) < 1}
                  >
                    Add Days
                  </button>
                </div>
              </div>
            </div>

            <div>
              <div>
                <div>
                  {inputCustomer === "" && (
                    <div className="row m-0">
                      <PageButtons />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-striped table-sm table-borderless">
            <thead>
              <tr>
                <th></th>
                <th>Member ID</th>
                <th>Name</th>
                <th>Gender</th>
                <th>Country</th>
                <th>Mobile</th>
                {/* <th>Create At</th> */}
                <th>Prepaid</th>
                {/* <th>Point</th> */}
                {/* <th>Last Visited</th> */}
                <th>Tags</th>
                {/* <th>Create At</th> */}
                <th class="actions" style={{ textAlign: "right" }}></th>
              </tr>
            </thead>
            <tbody class="no-border-x">
              {_.map(customerList[pageNo], (customer) => {
                return (
                  <tr
                    style={{
                      backgroundColor: uidHolder[customer.uid] ? "#aefcf1" : "",
                    }}
                    onClick={() => handleSetUidHoler(customer)}
                  >
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input
                          class="custom-control-input"
                          type="checkbox"
                          checked={uidHolder[customer.uid]}
                        />
                        <label class="custom-control-label" for="check3">
                          {/* Option 1 */}
                        </label>
                      </div>
                    </td>
                    <td>{customer.memberId}</td>
                    <td>{customer.name}</td>
                    <td>{customer.gender}</td>
                    <td>{customer.country}</td>
                    <td>{customer.mobileLocal}</td>
                    {/* <td>
                    {moment(customer.createAt).format("DD/MM/YYYY HH:mm")}
                  </td> */}
                    <td>
                      {customer.walletBalance &&
                        customer.walletBalance.toLocaleString()}
                    </td>
                    {/* <td>
                      {customer.pointBalance &&
                        customer.pointBalance.toLocaleString()}
                    </td> */}
                    {/* <td>
                      {customer.lastVisited
                        ? moment(customer.lastVisited).format(
                            "DD-MM-YYYY HH:mm"
                          )
                        : ""}
                    </td> */}
                    <td>
                      {_.map(customer.tags, (tag) => {
                        return (
                          <span>
                            &nbsp;
                            <span class="badge badge-pill badge-primary">
                              {tag.name}
                            </span>
                          </span>
                        );
                      })}
                    </td>
                    {/* <td>
                      {moment(customer.createAt).format("DD/MMM/YYYY HH:mm")}
                    </td> */}
                    <td class="actions">
                      <button
                        class="btn btn-space btn-primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSetCustomer(customer);
                          setModalTimestamp(moment());
                          // window.$("#manageCustomerModal").modal("show");
                        }}
                      >
                        Profile
                      </button>
                      <button
                        class="btn btn-space btn-primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCustomer(customer);
                          setModalTimestamp(moment());
                          window.$("#walletModal").modal("show");
                        }}
                      >
                        Wallet
                      </button>
                      {/* <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setCustomer(customer);
                        setModalTimestamp(moment());
                        window.$("#manageCustomerModal").modal("show");
                      }}
                    >
                      View
                    </a> */}
                      {/* <a class="icon" href="#">
                      <i class="mdi mdi-delete"></i>
                    </a> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {customerList.length < 1 && (
            <div className="pt-3 pb-3">
              <center>
                <h3 style={{ color: "grey" }}>No expired member yet.</h3>
              </center>
            </div>
          )}
        </div>
      </div>
      {inputCustomer === "" && (
        <div className="mt-4 mb-4" style={{ textAlign: "center" }}>
          <PageButtons />
        </div>
      )}
    </div>
  );
}

export default MemberListTable;
