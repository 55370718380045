import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as employeeActions from '../actions/employeeActions'
const EmployeeLockBtn = props => {
  
  return (  <li 
    onClick={e => props.actions.setLoggedInEmployee(null)}
  class="nav-item align-self-center ml-3 mr-5 btn btn-space btn-secondary hover show">
  <div class="d-flex pl-4 pr-4 ">
    <div
      class="d-flex mr-1"
      style={{ flexDirection: "column", justifyContent: "center" }}
    >
      <i style={{fontSize : 24}} class="icon icon-left mdi mdi-lock-outline" />
    </div>
    <div
      style={{
        height: 46,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}
    >
      <div>
        {/* class="ml-5 mr-3" */}
        <h4 class="mt-1 mb-0"> {props.currentEmp.name || "Locked"}</h4>
      </div>
      <div style={{ color: "gray", fontSize: "0.85em" }}>
        <h6 class="mt-1 mb-0">Employee Pin</h6>
      </div>
    </div>
  </div>
</li>
)
  return (
    <button
      class="btn btn-space btn-primary"
      onClick={e => props.actions.setLoggedInEmployee(null)}
    >
      <i class="icon icon-left mdi mdi-lock-outline" />
      {props.currentEmp.name || ""}
    </button>
  );
};

const mapStateToProps = state => {
  return {
    currentEmp: state.employee.current || {}
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...employeeActions }, dispatch);
  return {
    actions
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeLockBtn);
