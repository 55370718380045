import React, { useState } from "react";

function useFormInput(initValue) {
  const [value, setValue] = useState(initValue);
  function handleChange(evt) {
    setValue(evt.target.value);
  }

  return {
    value,
    onChange: handleChange,
    getInt() {
      return parseInt(value);
    },
    setValue,
    getValue() {
      return value || null;
    }
  };
}

export default useFormInput;
