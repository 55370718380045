import React, { Component } from "react";
import * as Amplify from "../../shared/awsAPIs/Amplify";
// import { LogIn as logg } from "../../shared/awsAPIs/TestLogin";

import swal from "sweetalert";

class LogIn extends Component {
  state = { username: "", password: "", onValidate: false };

  handleClickLogin = () => {
    const { username, password } = this.state;
    this.setState({ onValidate: true });

    if (username === "" || password === "") {
      swal("เกิดข้อผิดพลาด", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
      return;
    }
    Amplify.logIn(username, password);
    // logg(username, password);
    // Cognito.LogIn()
  };

  handleOnChangeInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      onValidate: false
    });
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.handleClickLogin();
    }
  };

  render() {
    let { onValidate, username, password } = this.state;

    return (
      <div className="be-splash-screen">
        <div className="be-wrapper be-login">
          <div className="be-content">
            <div className="main-content container-fluid">
              <div className="splash-container">
                <div className="card card-border-color card-border-color-primary">
                  <div className="card-header">
                    <img
                      className="logo-img"
                      src={require("../../shared/image/flower.png")}
                      alt="logo"
                      width="100"
                      height="100"
                    />
                    <h2>
                      <b>Niceloop Spa Pos</b>
                    </h2>
                    {/* <h3>Log In</h3> */}
                    <span className="splash-description">
                      Please enter your username and password.
                    </span>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="form-group">
                        <label>Username</label>
                        <input
                          className="form-control parsley-error"
                          onChange={this.handleOnChangeInput}
                          name="username"
                          type="text"
                          parsley-trigger="change"
                          required=""
                          placeholder="Enter username"
                          autoComplete="1o3g00eas2g"
                          data-parsley-id="5"
                          aria-describedby="parsley-id-5"
                          onKeyPress={this.handleKeyPress}
                        />
                        {onValidate === true && username === "" && (
                          <ul
                            className="parsley-errors-list filled"
                            id="parsley-id-5"
                          >
                            <li className="parsley-required">
                              This value is required.
                            </li>
                          </ul>
                        )}
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          className="form-control parsley-error"
                          onChange={this.handleOnChangeInput}
                          type="password"
                          name="password"
                          parsley-trigger="change"
                          required=""
                          placeholder="Enter password"
                          autoComplete="1o3g00eas2g"
                          data-parsley-id="5"
                          aria-describedby="parsley-id-6"
                          onKeyPress={this.handleKeyPress}
                        />
                        {onValidate === true && password === "" && (
                          <ul
                            className="parsley-errors-list filled"
                            id="parsley-id-6"
                          >
                            <li className="parsley-required">
                              This value is required.
                            </li>
                          </ul>
                        )}
                      </div>
                      {/* <div className="form-group row login-tools">
                                                <div className="col-6 login-remember">
                                                    <label className="custom-control custom-checkbox">
                                                        <input className="custom-control-input" type="checkbox" /><span className="custom-control-label">Remember Me</span>
                                                    </label>
                                                </div>
                                                <div className="col-6 login-forgot-password"><a href="pages-forgot-password.html">Forgot Password?</a></div>
                                            </div> */}
                      <div className="form-group login-submit">
                        <a
                          className="btn btn-primary btn-xl"
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            this.handleClickLogin();
                          }}
                          data-dismiss="modal"
                        >
                          Sign me in
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
                {/* //TODO: Create HQ */}
                <div className="splash-footer"><span>Don't have an account? <a href="/signUp">Sign Up</a></span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="be-scroll-top" />
      </div>
    );
  }
}

export default LogIn;
