import axios from "axios";
import moment from "moment";

class LinePushMessageApi {
  URL_LINE_PUSH =
    "https://asia-east2-divana-project.cloudfunctions.net/linePush";

  closeShop(storeUid) {
     

    let data = {
      action: "CLOSE_SHOP",
      live: true,

      payload: {
        storeUid,
        startDate: moment()
          .startOf("day")
          .toISOString(),
        endDate: moment()
          .endOf("day")
          .toISOString()
      }
    };
    return axios.post(this.URL_LINE_PUSH, data);
  }

  entertain(receipt) {

    return
      //TODO:   remove block  store uid later
    if (receipt.storeUid  !== "32276347") {
      return;
    }
    let data = {
      action: "ENTERTAIN",
      live: true,

      payload: {
        ...receipt
      }
    };
    return axios.post(this.URL_LINE_PUSH, data);
  }

  voidBill(receiptVoided) {
    return
    if (receiptVoided.storeUid  !== "32276347") {
      return;
    }
    let data = {
      action: "VOID_BILL",
      live: true,
      payload: {
        ...receiptVoided
      }
    };
    return axios.post(this.URL_LINE_PUSH, data);
  }
}

export default LinePushMessageApi;
