import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as linkedSelector from "../shared/selector/linkedSelector";
import { withRouter } from "react-router-dom";
import _ from "lodash";

const isLocalhost =
  _.includes(window.location.href, "localhost") ||
  _.includes(window.location.href, "192.");

class LeftSideBar extends Component {
  componentDidMount() {}

  render() {
    let { isLinked, location, history } = this.props;
    const pathname = location.pathname;
    return (
      <div className="be-left-sidebar">
        <div className="left-sidebar-wrapper">
          <a className="left-sidebar-toggle" href="#">
            Dashboard
          </a>
          <div className="left-sidebar-spacer">
            <div className="left-sidebar-scroll ps ps--active-y">
              <div className="left-sidebar-content">
                <ul className="sidebar-elements">
                  {/* <li>
                    <button
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        history.push("/loading");
                        setTimeout(() => {
                          history.push("/shopping");
                        }, 50);
                      }}
                      className="btn btn-space btn-secondary btn-lg"
                      style={{ width: 180, marginTop: 16, marginLeft: 16 }}
                    >
                      New Order
                    </button>
                  </li> */}
                  {/* retail */}
                  {isLinked === false &&
                    isLocalhost && [
                      <li className="divider" key="12">
                        Divana Retail 
                      </li>,
                      // <li
                      //   className={pathname === "/pos_retail" ? "active" : ""}
                      // >
                      //   <Link to="/pos_retail">
                      //     <i className="icon mdi mdi-settings" />
                      //     <span>POS Retail</span>
                      //   </Link>
                      // </li>,

                      <li className={pathname === "/spa" ? "active" : ""}>
                        <Link to="/spa">
                          <i className="icon mdi mdi-settings" />
                          <span>POS</span>
                        </Link>
                      </li>,

                      <li
                        className={
                          pathname === "/promotion_retail" ? "active" : ""
                        }
                      >
                        <Link to="/promotion_retail">
                          <i className="icon mdi mdi-settings" />
                          <span>Promotions</span>
                        </Link>
                      </li>,

                      // <li
                      //   className={
                      //     pathname === "/promotion_retail" ? "active" : ""
                      //   }
                      // >
                      //   <Link to="/spa_pos">
                      //     <i className="icon mdi mdi-settings" />
                      //     <span>Spa POS</span>
                      //   </Link>
                      // </li>,
                      <li
                        className={pathname === "/reports/spa" ? "active" : ""}
                      >
                        <Link to="/reports/spa">
                          <i className="icon mdi mdi-settings" />
                          <span>Reports</span>
                        </Link>
                      </li>,
                        <li
                        className={pathname === "/reports3/spa" ? "active" : ""}
                      >
                        <Link to="/reports3/spa">
                          <i className="icon mdi mdi-settings" />
                          <span>SPA Reports3</span>
                        </Link>
                      </li>,

                      <li
                        className={pathname === "/setting/spa_employee" ? "active" : ""}
                      >
                        <Link to="/setting/spa_employee">
                          <i className="icon mdi mdi-settings" />
                          <span>Employees</span>
                        </Link>
                      </li>,
                      <li className={pathname === "/setting/spa_room" ? "active" : ""}>
                        <Link to="/setting/spa_room">
                          <i className="icon mdi mdi-settings" />
                          <span>Rooms</span>
                        </Link>
                      </li>,
                      <li
                        className={
                          pathname === "/spa_agent_items" ? "active" : ""
                        }
                      >
                        <Link to="/spa_agent_items">
                          <i className="icon mdi mdi-settings" />
                          <span>Setting Agen Items</span>
                        </Link>
                      </li>
                    ]}
                  {/* {isLocalhost && [
                    <li className="divider">TEST</li>,
                    <li
                      className={
                        pathname === "/promotion_retail" ? "active" : ""
                      }
                    >
                      <Link to="/test">
                        <i className="icon mdi mdi-settings" />
                        <span>Test</span>
                      </Link>
                    </li>
                  ]} */}
                  {/* <li className="divider">Main</li>
                  <li
                    className={
                      pathname === "/" ||
                      _.includes(pathname, "purchaseHistory")
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/">
                      <i className="icon mdi mdi-home" />
                      <span>Inbox</span>
                    </Link>
                  </li>
                  {isLinked === false && [
                    <li className="divider">Report</li>,
                    <li className={pathname === "/report" ? "active" : ""}>
                      <Link to="/report">
                        <i className="icon mdi mdi-star" />
                        <span>Report</span>
                      </Link>
                    </li>
                  ]} */}
                  <li className="divider">Management</li>
                  {isLinked === false && (
                    <li className={pathname === "/inventory" ? "active" : ""}>
                      <Link to="/inventory">
                        <i className="icon mdi mdi-shape" />
                        <span> Menu-Items</span>
                      </Link>
                    </li>
                  )}
                  {isLinked === false && (
                    <li
                      className={
                        _.includes(pathname, "manageStock") ? "active" : ""
                      }
                    >
                      <Link to="/manageStock">
                        <i className="icon mdi mdi-dropbox" />
                        <span> Stock</span>
                      </Link>
                    </li>
                  )}
                  {isLinked === false && (
                    <li className={pathname === "/manageShop" ? "active" : ""}>
                      <Link to="/manageShop">
                        <i className="icon mdi mdi-store" />
                        <span> Branches</span>
                      </Link>
                    </li>
                  )}
                  <li className={pathname === "/manageUser" ? "active" : ""}>
                    <Link to="/manageUser">
                      <i className="icon mdi mdi-face" />
                      <span> Users</span>
                    </Link>
                  </li>
                  {isLocalhost && (
                    <li>
                      <Link to="/wallet">
                        <i className="icon mdi mdi-balance-wallet" />
                        <span>Wallet</span>
                      </Link>
                    </li>
                  )}
                  {/* {isLinked === false && [ */}
                  <li className="divider">Setting</li>,
                  <li className={pathname === "/setting" ? "active" : ""}>
                    <Link to="/setting">
                      <i className="icon mdi mdi-settings" />
                      <span>Setting</span>
                    </Link>
                  </li>
                  {/* ]} */}
                  {isLinked === false &&
                    isLocalhost && [
                      <li className="divider">Setting</li>,
                      <li className={pathname === "/employee" ? "active" : ""}>
                        <Link to="/employee">
                          <i className="icon mdi mdi-face" />
                          <span>Employee</span>
                        </Link>
                      </li>,
                      <li className={pathname === "/setting" ? "active" : ""}>
                        <Link to="/setting">
                          <i className="icon mdi mdi-settings" />
                          <span>Setting</span>
                        </Link>
                      </li>
                    ]}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLinked: linkedSelector.isLinkedSelector(state)
  };
};

export default withRouter(connect(mapStateToProps)(LeftSideBar));
