import React from "react";
import { connect } from "react-redux";
import { subTotalAmountSelector } from "../PosCart/selector";

const grandTotalStyle = {
  border: "1px solid rgba(0,0,0,0.125)",
  borderRadius: 8,

  cursor: "pointer"
};

const grandTotal_hoder_Style = {
  border: "1px solid rgba(0,0,0,0.125)",
  cursor: "pointer",
  color: "white",
  borderRadius: 8,

  backgroundColor: "#4285f4"
};

function NextButton({
  isGrandTotalHover,
  subTotal,
  onClick,
  setIsGrandTotalHover,
  disableOnClickNext
}) {
  return (
    <div
      onClick={onClick}
      onMouseEnter={() => !disableOnClickNext && setIsGrandTotalHover(true)}
      onMouseLeave={() => !disableOnClickNext && setIsGrandTotalHover(false)}
      className="card-header  text-center card-header p-1"
      style={isGrandTotalHover ? grandTotal_hoder_Style : grandTotalStyle}
    >
      <b>{(subTotal || 0).toLocaleString()}</b>
      <span
        className="card-subtitle"
        style={isGrandTotalHover ? { color: "white" } : {}}
      >
        {disableOnClickNext ? "Total" : "Next"}
      </span>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    subTotal: subTotalAmountSelector(state)
  };
};
// function mapDispatchToProps(dispatch) {
//   let actions = bindActionCreators({ ...spaPosActions }, dispatch);
//   return {
//     actions
//   };
// }

export default connect(
  mapStateToProps
  //   mapDispatchToProps
)(NextButton);
