import React, { useState } from "react";
import PosCartSummary from "../PosCart/Summary";
import PaymentType from "./PaymentType";
import { connect } from "react-redux";
import { subTotalAmountSelector } from "../PosCart/selector";
import Numpad from "./Numpad";
import _ from "lodash";
import * as settingSelector from "../../shared/selector/settingSelector";

function PosPayment(props) {
  const [amount, setAmount] = useState("");

  const getChange = () => {
    if (amount === "") {
      return "";
    }
    let change = parseFloat(amount) - (parseFloat(props.subTotal) || 0);

    return change.toLocaleString();
  };

  return (
    <div>
      <div className="row">
        <div className="col-8">
          <div className="card card-flat">
            <div className="row no-gutters">
              <div className="col-6">
                <PosCartSummary showSubmitButton={false} />
              </div>
              <div className="col-6  ">
                <div className=" card card-flat">
                  <div className="card-header card-header-divider">Payment</div>
                  <div className="card-header">
                    GrandTotal
                    <span className="" style={{ float: "right" }}>
                      {(props.subTotal || 0).toLocaleString()}
                    </span>
                  </div> 

                  <div className="card-body">
                    <PaymentType
                      amount={amount}
                      setAmount={setAmount}
                      {...props}
                    />
                  </div>
                  {parseInt(getChange()) > 0 && (
                    <div className="card-header" style={{ color: "blue" }}>
                      Change
                      <span className="" style={{ float: "right" }}>
                        {/* 50000 */}
                        {getChange()}
                      </span>
                    </div>
                  )}

                  {/* <div className="card-header">
                  <div className="row">
                    <div className="col-3">Note</div>
                        
                    <div className="col-9">
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div> */}

                  <div
                    //   onMouseEnter={() => setIsGrandTotalHover(true)}
                    //   onMouseLeave={() => setIsGrandTotalHover(false)}
                    className="card-header  text-center card-header p-1"
                    //   style={
                    //     isGrandTotalHover ? grandTotal_hoder_Style : grandTotalStyle
                    //   }
                  >
                    <button
                      className="btn btn-space btn-secondary btn-big w-100"
                      style={{ height: 48, borderRadius: 8 }}
                    >
                      PAY
                    </button>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>

        <div className="col-4">
          <Numpad
            setAmount={char => setAmount(amount + char)}
            // delAmount={() => setAmount(amount.slice(0, amount.length - 1))}
            delAmount={() => setAmount("")}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    subTotal: subTotalAmountSelector(state),
    paymentTypes: settingSelector.paymentTypeSelector(state)  
  };
};
// function mapDispatchToProps(dispatch) {
//   let actions = bindActionCreators({ ...spaPosActions }, dispatch);
//   return {
//     actions
//   };
// }

export default connect(
  mapStateToProps
  //   mapDispatchToProps
)(PosPayment);

// function SummaryAll() {
//   return (
//     <div className="card card-flat">
//       <div className="card-header card-header-divider">Customer</div>

//       <div className="card-body">
//         <div className="row">
//           <div className="col-3">Name</div>

//           <div className="col-9">Warit Tantiti</div>
//         </div>

//         <div className="row">
//           <div className="col-3">Tier</div>

//           <div className="col-9">Sliver</div>
//         </div>

//         <div className="row">
//           <div className="col-3">Points</div>

//           <div className="col-9">800</div>
//         </div>
//       </div>

//       <div className="card-header card-header-divider">Summary</div>

//       <div className="card-body">
//         <div className="row">
//           <div className="col-3">Name</div>

//           <div className="col-9">Warit Tantiti</div>
//         </div>

//         <div className="row">
//           <div className="col-3">Tier</div>

//           <div className="col-9">Sliver</div>
//         </div>

//         <div className="row">
//           <div className="col-3">Points</div>

//           <div className="col-9">800</div>
//         </div>
//       </div>
//     </div>
//   );
// }
