import { createSelector } from "reselect";
import _ from "lodash";

const purchaseHistoryComplete = state => state.history.purchaseComplete.transactions
const purchaseHistorySearch = state => state.history.purchaseSearch.transactions
const purchaseHistoryPending = state => state.history.purchasePending.transactions



export const purchaseCompleteObjSelector = createSelector(
    [purchaseHistoryComplete],
    (complete) => {
        let purchaseObj = {}
        _.forEach(complete, page => {
            _.forEach(page, tx => {
                purchaseObj[tx.invoiceId] = tx
            })
        })
        return purchaseObj
    })
export const purchaseSearchObjSelector = createSelector(
    [purchaseHistorySearch],
    (search) => {
        let purchaseObj = {}
        _.forEach(search, page => {
            _.forEach(page, tx => {
                purchaseObj[tx.invoiceId] = tx
            })
        })
        return purchaseObj
    })
export const purchaseCompleteArraySelector = createSelector(  
    [purchaseHistoryComplete],
    (complete) => {
        let purchaseArray = _.orderBy(complete, ['timestamp'], ['desc', 'asc']);
        return purchaseArray
    })

export const purchasePendingObjSelector = createSelector(
    [purchaseHistoryPending],
    (pending) => {
        let purchaseObj = {}
        _.forEach(pending, tx => {
            purchaseObj[tx.invoiceId] = tx
        })
        return purchaseObj
    })
export const purchasePendingArraySelector = createSelector(
    [purchaseHistoryPending],
    (pending) => {
        let purchaseArray = _.orderBy(pending, ['timestamp'], ['desc', 'asc']);
        return purchaseArray
    })
