import * as type from "../types/override/vatOverride";
import _ from "lodash";
import documentClient from "../awsAPIs/dynamoDb";
// import moment from "moment";

export const fetchOverride = () => {
  return (dispatch, getState) => {
    const { shop } = getState();

    var params = {
      TableName: "Spa_Override",
      //   IndexName: "hqUid-index",
      KeyConditionExpression: "storeUid = :storeUid",
      ExpressionAttributeValues: {
        ":storeUid": shop.detail.uid
      }
    };

    documentClient.query(params, function(err, data) {
      if (err) console.log(err);
      else {
        dispatch({
          type: type.FETCH_VAT_BRANCH_OVERRIDE,
          payload: data.Items
        });
      }
    });
  };
};
