import React from "react";
import _ from "lodash";
import swal from "sweetalert";
import { s3 } from "../../shared/awsAPIs/s3";
import moment from "moment";

const labelClass = "col-12 col-sm-3 col-form-label text-sm-right";
const inputContainerClass = "col-12 col-sm-8 col-lg-6";

class Setting extends React.Component {
  state = {
    name: "",
    emailReport: ""
  };

  componentDidMount() {
    const { shopDetail } = this.props;
    this.setState({
      name: shopDetail.name,
      emailReport: shopDetail.emailReport || ""
      // shippingAddress: getAddress(shopDetail, 'shipping')
    });
  }

  onChangeInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onClickSave = () => {
    const { name, emailReport } = this.state;
    const { actions } = this.props;

    if (name === "") {
      swal("เกิดข้อผิดพลาด", "กรุณากรอกชื่อร้าน", "error");
      return;
    }

    const data = {
      name,
      emailReport: emailReport === "" ? null : emailReport
    };

    actions.selfUpdateShopDetail(data, () => {
      swal("สำเร็จ !", "บันทึกข้อมูลร้านค้าสำเร็จ", "success");
      return;
    });
  };

  updateLogo = image => {
    const { actions, loggedIn } = this.props;
    let fileName = moment().valueOf();
    var params = {
      Bucket: "inventory-management",
      Key: `${loggedIn.shopId}/logo/${fileName}`,
      Body: image
      // ContentType: "application/pdf",
      // ContentDisposition: 'inline'
    };

    s3.upload(params, (err, res) => {
      console.log("upload complete pdf");
      if (err) {
        alert(err);
        return;
      }
      actions.updateLogoUrl(res.Location);
    });
  };

  render() {
    const { name, emailReport } = this.state;

    return (
      <div>
        <form>
          <div className="form-group row">
            <label class={labelClass} for="inputText3">
              ชื่อร้าน/สาขา
            </label>
            <div class={inputContainerClass}>
              <input
                className="form-control"
                value={name}
                name="name"
                onChange={this.onChangeInput}
                type="text"
              />
            </div>
          </div>
          <div className="form-group row">
            <label class={labelClass} for="inputText3">
              Email Report
            </label>
            <div class={inputContainerClass}>
              <input
                className="form-control"
                value={emailReport}
                name="emailReport"
                onChange={this.onChangeInput}
                type="text"
              />
            </div>
          </div>
          <div className="form-group row">
            <label class={labelClass} for="inputText3">
              Logo บริษัท
            </label>
            <div class={inputContainerClass}>
              <input
                className="inputfile"
                id="file-2"
                type="file"
                name="file-2"
                data-multiple-caption="{count} files selected"
                onChange={e => {
                  const file = e.target.files[0];
                  this.updateLogo(file);
                }}
                accept="image/*"
              />
              <label className="btn-primary" for="file-2">
                {" "}
                <i className="mdi mdi-upload" />
                <span>Browse files...</span>
              </label>
            </div>
          </div>
          <div className="form-group row">
            <label class={labelClass} />
            <div class={inputContainerClass}>
              <button
                className="btn btn-space btn-success hover"
                onClick={e => {
                  e.preventDefault();
                  this.onClickSave();
                }}
              >
                Save
              </button>
            </div>
          </div>
          {/* <span style={{color:'red'}}>ทำแค่บันทึกชื่อ HQ</span> */}
        </form>
      </div>
    );

    // return (
    //   <div className="main-content container-fluid">
    //     <div className="row">
    //       <div className="col-md-12">
    //         <div className="card card-border-color card-border-color-primary">
    //           <div className="card-header card-header-divider">
    //             About Cafe
    //             {/* <span className="card-subtitle">These are the basic bootstrap form elements</span> */}
    //           </div>
    //           <div className="card-body">
    //             <form>
    //               <div className="form-group row">
    //                 <label class={labelClass} for="inputText3">
    //                   ชื่อร้าน/สาขา
    //                 </label>
    //                 <div class={inputContainerClass}>
    //                   <input
    //                     className="form-control"
    //                     value={name}
    //                     name="name"
    //                     onChange={this.onChangeInput}
    //                     type="text"
    //                   />
    //                 </div>
    //               </div>
    //               <div className="form-group row">
    //                 <label class={labelClass} for="inputText3">
    //                   Email Report
    //                 </label>
    //                 <div class={inputContainerClass}>
    //                   <input
    //                     className="form-control"
    //                     value={emailReport}
    //                     name="emailReport"
    //                     onChange={this.onChangeInput}
    //                     type="text"
    //                   />
    //                 </div>
    //               </div>
    //               <div className="form-group row">
    //                 <label class={labelClass} for="inputText3">
    //                   Logo บริษัท
    //                 </label>
    //                 <div class={inputContainerClass}>
    //                   <input
    //                     className="inputfile"
    //                     id="file-2"
    //                     type="file"
    //                     name="file-2"
    //                     data-multiple-caption="{count} files selected"
    //                     onChange={e => {
    //                       const file = e.target.files[0];
    //                       this.updateLogo(file);
    //                     }}
    //                     accept="image/*"
    //                   />
    //                   <label className="btn-primary" for="file-2">
    //                     {" "}
    //                     <i className="mdi mdi-upload" />
    //                     <span>Browse files...</span>
    //                   </label>
    //                 </div>
    //               </div>
    //               <div className="form-group row">
    //                 <label class={labelClass} />
    //                 <div class={inputContainerClass}>
    //                   <button
    //                     className="btn btn-space btn-success hover"
    //                     onClick={e => {
    //                       e.preventDefault();
    //                       this.onClickSave();
    //                     }}
    //                   >
    //                     Save
    //                   </button>
    //                 </div>
    //               </div>
    //               {/* <span style={{color:'red'}}>ทำแค่บันทึกชื่อ HQ</span> */}
    //             </form>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );
  }
}

export default Setting;
