import React, { useState, useEffect } from "react";
import ReactDataSheet from "react-datasheet";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import swal from "sweetalert";
import _ from "lodash";
import * as overrideActions from "../../shared/actions/overrideActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Select from "react-select";

function VatOverride(props) {
  const [grid, setGrid] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const { pickedGroupUid, branchOptions } = props;

  useEffect(() => {
    createRows();
  }, [pickedGroupUid, selectedBranch]);

  const createRows = async () => {
    if (!selectedBranch) return;

    let items = _.filter(props.items, item => {
      return item.groupUid === pickedGroupUid;
    });

    let grid = [
      [
        { readOnly: true, value: "#", width: 50 },
        { readOnly: true, value: "Name", width: "40%" },
        { readOnly: true, value: "Price", width: 70 },
        { readOnly: true, value: "Global Value", width: 70 },
        { readOnly: true, value: "Default", width: 70 },
        { readOnly: true, value: "Override" }
      ]
    ];

    let branch = getSelectedBranch();
    let globalVal = "";
    if (branch.vat && branch.vat.mode !== 0) {
      globalVal = branch.vat.value;
    }

    let resVat = await fetchSpecificVatBranch();

    let vatOverride = {};
    if (resVat.Item) {
      vatOverride = resVat.Item.value;
    }

    _.forEach(items, (item, idx) => {
      let vatValue = "";
      if (vatOverride[item.uid]) {
        vatValue = vatOverride[item.uid];
      }

      grid.push([
        { readOnly: true, value: idx + 1, uid: item.uid },
        { readOnly: true, value: item.name },
        { readOnly: true, value: item.price.toLocaleString() },
        { readOnly: true, value: globalVal },
        { readOnly: true, value: item.vat || "" },
        { value: vatValue }
      ]);
    });

    setGrid(grid);
  };

  const fetchSpecificVatBranch = async () => {
    if (!selectedBranch) return;

    var params = {
      TableName: "Spa_Override",
      Key: {
        storeUid: selectedBranch.uid,
        name: "vat"
      }
    };

    return documentClient.get(params).promise();
  };

  const getSelectedBranch = () => {
    return _.find(props.branchList, i => i.uid === selectedBranch.uid);
  };

  const getWarnText = () => {
    if (!selectedBranch) {
      return <h1>กรุณาเลือกสาขา</h1>;
    }

    if (pickedGroupUid === "none") {
      return <h1>กรุณาเลือกลุ่ม</h1>;
    }

    return null;
  };

  const updateVatBranchOverride = async () => {
    let value = getUpdateValue();

    // var params = {
    //   TableName: "Spa_Override",
    //   Key: { storeUid: selectedBranch.uid, name: "branchVat" },
    //   UpdateExpression: "set value = :value",
    //   ExpressionAttributeValues: {
    //     ":value": value
    //   }
    // };

    // loadedVat

    // documentClient.update(params).promise();

    // actions.
  };

  const getUpdateValue = () => {
    const gridToBeComputed = [...grid.slice(1)];

    _.forEach(gridToBeComputed, (data, idx) => {
      //5 = Vat Value
      const uid = data[0].uid;
      debugger;
    });
  };

  return (
    <div>
      <div className="row m-0 justify-content-between">
        <div className="col-6 mb-2 pl-0">
          <Select
            value={selectedBranch}
            onChange={setSelectedBranch}
            options={branchOptions}
          />
        </div>
        {selectedBranch && pickedGroupUid !== "none" && (
          <div>
            <button
              class="btn btn-space btn-success hover"
              style={{ width: 100 }}
              onClick={updateVatBranchOverride}
            >
              <i class="icon icon-left mdi mdi-check"></i> Save
            </button>
          </div>
        )}
      </div>
      {getWarnText() || (
        <div className="card">
          <div className="p-2">
            <ReactDataSheet
              // width="100%"
              data={grid}
              valueRenderer={cell => cell.value}
              onCellsChanged={changes => {
                const newGrid = grid.map(row => [...row]);
                changes.forEach(({ cell, row, col, value }) => {
                  newGrid[row][col] = { ...newGrid[row][col], value };
                });
                setGrid(newGrid);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...overrideActions }, dispatch);
  return {
    actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VatOverride);
