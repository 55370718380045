import React from "react";

function MemberActionBar(props) {
  const { page, setPage } = props;
  return (
    <div
      className="d-flex flex-row justify-content-between align-items-stretch"
      style={{
        backgroundColor: "white",
        height: 48,
        zIndex: 99,
        borderBottom: "1px solid rgb(212, 212, 212)"
      }}
    >
      <div className="mt-2 " style={{ backgroundColor: " ", paddingLeft: 25 }}>
        <button
          style={{ width: 80 }}
          onClick={() => {
            props.backPage();
            // props.history.goBack();
            // props.history.push(backPath);
          }}
          className={`btn btn-rounded btn-space btn-secondary`}
        >
          <i className="icon icon-left mdi mdi-arrow-left" />
          Back
        </button>
        <label style={{ marginLeft: 24, marginTop: 8, marginRight: 8 }}>
          Page
        </label>
        <button
          onClick={props.saveToDatabase}
          className={`btn btn-rounded btn-space btn-${
            page === "detail" ? "primary" : "secondary"
          }`}
          onClick={() => setPage("detail")}
        >
          Detail
        </button>
        <button
          onClick={props.saveToDatabase}
          className={`btn btn-rounded btn-space btn-${
            page === "voucher" ? "primary" : "secondary"
          }`}
          onClick={() => setPage("voucher")}
        >
          Voucher
        </button>
      </div>
      {/* <div
        className=" mt-2 d-flex  justify-content-end"
        style={{ backgroundColor: " ", marginRight: 25 }}
      >
        <label style={{ marginLeft: 24, marginTop: 8, marginRight: 8 }}>
          Page
        </label>

        <button
          onClick={props.saveToDatabase}
          className={`btn btn-rounded btn-space btn-${
            page === "detail" ? "primary" : "secondary"
          }`}
        >
          Detail
        </button>
      </div> */}
    </div>
  );
}

export default MemberActionBar;
