import React, { useState } from "react";
import PosPayment from "../../pages/PosPayment/SpaPayment";

function SpaPaymentModal(props) {
  const { setModalVisible } = props;

  return (
    <div
      class="modal fade show"
      id="spaPaymentModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="spaPaymentModalLabel"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div
        class="modal-dialog full-width modal-dialog-centered"
        role="document"
        style={{ width: 1000 }}
      >
        <div class="modal-content">
          <div class="modal-header">
            {/* <h5 class="modal-title" id="spaPaymentModalLabel">
                Payment
              </h5> */}
            <button
              type="button"
              class="close"
              // data-dismiss="modal"
              onClick={() => setModalVisible(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <PosPayment {...props} disableSummary={true} />
          </div>
          <div class="modal-footer pt-2">
            <button
              type="button"
              class="btn btn-secondary"
              // data-dismiss="modal"
              onClick={() => setModalVisible(false)}
              style={{ width: 100 }}
            >
              Close 
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpaPaymentModal;
