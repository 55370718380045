import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import EmployeeInfoModal from "../../shared/components/EmployeeInfoModal";
import moment from "moment";
import * as employeeAction from "../../shared/actions/employeeActions";
import swal from "sweetalert";

class Employee extends React.Component {
  state = {
    currentEmp: {},
    timestamp: moment().toISOString(),
    mode: "create"
  };

  deleteEmployee = emp => {
    const { actions } = this.props;
    swal({
      title: `คุณต้องการลบ "${emp.name}" ?`,
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        actions.deleteEmployee(emp, () => {
          swal("สำเร็จ", "ลบ Employee สำเร็จ", "success");
        });
      }
    });
  };

  render() {
    const { employeeList } = this.props;
    const { mode, timestamp, currentEmp } = this.state;
    return (
      <div className="main-content container-fluid"> 
        <EmployeeInfoModal
          mode={mode} 
          timestamp={timestamp}
          currentEmp={currentEmp}
        />
        <div className="row m-0">
          <div className="col-md-8 offset-md-2">
            <div className="card card-table">
              <div className="card-header">
                <div className="row m-0 justify-content-between">
                  <div>Employee Management</div>
                  <div>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#employeeInfoModal"
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.setState({
                          mode: "create",
                          timestamp: moment().toISOString()
                        });
                      }}
                    >
                      Create
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>PIN</th>
                      <th>Role</th>
                      <th>Access Pages</th>
                      <th className="actions" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(employeeList, i => {
                      return (
                        <tr>
                          <td>{i.name}</td>
                          <td>******</td>
                          <td>{i.role}</td>
                          <td>
                            {(i.role === 'admin' || i.role === undefined )&& <span>All</span>}
                            {_.map(i.permissionPage, (v, permissionKey) => permissionKey && i.role === 'custom' && <button class="btn    btn-rounded btn-space btn-primary  btn-xs">{permissionKey}</button>   )}
                          </td>
                          <td className="actions">
                            <a
                              className="icon"
                              href="#"
                              data-toggle="modal"
                              data-target="#employeeInfoModal"
                              style={{ color: "orange" }}
                              onClick={() => {
                                this.setState({
                                  currentEmp: i,
                                  mode: "edit",
                                  timestamp: moment().toISOString()
                                });
                              }}
                            >
                              <i className="mdi mdi-edit" />
                            </a>
                            <a
                              className="icon pl-2"
                              href="#"
                              style={{ color: "red" }}
                              onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.deleteEmployee(i);
                              }}
                            >
                              <i className="mdi mdi-delete" />
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    employeeList: state.employee.list
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...employeeAction }, dispatch);
  return {
    actions
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Employee);
