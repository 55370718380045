import * as type from "../../types/employee";

const secretAdminPin = {
  name: "Secret Admin",
  permission: {},
  permissionPage: {},
  pin: "niceloop",
  role: "admin",
  shopId: "3181",
  uid: "secretAdmin"
};

const reducer = (
  state = {
    list: [],
    current: null
  },
  action
) => {
  switch (action.type) {
    case type.FETCH_EMPLOYEE:
      return { ...state, list: [...action.payload] };
    case type.SET_LOGGED_IN_EMPLOYEE:
      return { ...state, current: action.payload };
    default:
      return state;
  }
};

export default reducer;
