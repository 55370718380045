import { createSelector } from "reselect";
import _ from "lodash";

const itemsSelector = state => state.inventory.items;
const groupsSelector = state => state.inventory.groups;
const cartItemsSelector = state => state.cart.items;
const typeSelector = state => state.inventory.types;

export const groupObjSelector = createSelector(
  [groupsSelector],
  groups => {
    let groupObj = {};
    _.forEach(groups, group => {
      groupObj[group.uid] = group;
    });
    return groupObj;
  }
);
export const typeObjSelector = createSelector(
  [typeSelector],
  types => {
    let typesObj = {};
    _.forEach(types, group => {
      typesObj[group.uid] = group;
    });
    return typesObj;
  }
);

export const groupItemSelector = createSelector( 
  [itemsSelector, groupObjSelector],
  (items, groupObj) => {
    //COMBINE
    let newGroupObj = _.cloneDeep(groupObj);
    _.forEach(items, item => {
      if (newGroupObj[item.groupUid] === undefined) {
        return;
      }
      if (newGroupObj[item.groupUid].items === undefined) {
        newGroupObj[item.groupUid].items = {};
      }
      newGroupObj[item.groupUid].items[item.uid] = item;
    });

    //SORT ITEM ROW
    _.forEach(newGroupObj, (group, uid) => {
      group.items = _.orderBy(group.items, "row", "asc");
    });

    //SORT GROUP ROW
    let newGroupArray = _.orderBy(newGroupObj, "row", "asc");

    return newGroupArray;

    //RETURN ARRAY LIKE THIS
    // [{
    //     name:"GROUP NAME"
    //     items:{
    //         [{
    //             name:"ITEM NAME"
    //         }]
    //     }
    // }]
  }
);

export const typeGroupItemSelector = createSelector( 
  [groupItemSelector, typeSelector],
  (groups, types) => {
    let typeArr = [];
    let tmpTypes = _.cloneDeep(types);
    let findOtherGroups = _.find(groups, group => group.type === "other");
    if (findOtherGroups) {
      tmpTypes.push({
        name: "Other",
        uid: "other",
        row: 999999999
      });
    }
    _.forEach(tmpTypes, type => {
      let foundGroups = _.filter(groups, group => group.type === type.uid);
      //   if (foundGroups.length < 1) return;
      typeArr.push({ ...type, groups: foundGroups });
    });
    return typeArr;
  }
);

export const cartSummarySelector = createSelector(
  [groupItemSelector, cartItemsSelector, typeSelector],
  (groups, cartItems, types) => {
    let groupArr = [];
    let totalQty = 0;
    let totalPrice = 0;
    _.forEach(groups, group => {
      let tmpItems = [];
      _.forEach(group.items, item => {
        if (cartItems[item.uid] === undefined) {
          return;
        }

        tmpItems.push({
          ...item,
          qty: cartItems[item.uid],
          totalPrice: item.price * cartItems[item.uid],
          type: "item"
        });

        totalQty += cartItems[item.uid];
        totalPrice += item.price * cartItems[item.uid];
      });

      if (_.size(tmpItems) > 0) {
        groupArr.push({
          ...group,
          items: tmpItems
        });
      }
    });

    //sort with type
    let typeArr = [];

    _.forEach([...types, { name: "Other", uid: "other" }], type => {
      let filteredGroups = _.filter(groupArr, group => group.type === type.uid);

      if (_.size(filteredGroups) > 0) {
        typeArr.push({ ...type, type: "type" });

        _.forEach(filteredGroups, group => {
          typeArr.push({
            name: group.name,
            row: group.row,
            shopId: group.shopId,
            typeUid: group.type,
            uid: group.uid,
            type: "group"
          });
          _.forEach(group.items, item => {
            typeArr.push({ ...item, type: "item" });
          });
        });
      }
    });

    let res = { items: typeArr, totalQty, totalPrice };
    return res;
  }
);

export const itemObjSelector = createSelector(
  //ONLY ITEMS
  [itemsSelector],
  items => {
    let objItem = {};
    _.forEach(items, item => {
      objItem[item.uid] = item;
    });

    return objItem;
  }
);
