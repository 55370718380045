import _ from "lodash";

const getTherapist = (mode, items, therapistObj) => {
  //ONLY COURSE ITEMS
  let courseItems = [];
  _.forEach(items, item => {
    if (item.timeMinutes && item.timeMinutes > 0) {
      courseItems.push(item);
    }
  });
  
  let therapist = null;

  if (courseItems.length < 1) return null;

  if (mode === "single") {
    therapist = computeSingleTher(therapistObj.therapist, items);
  }

  if (mode === "multiple") {
    therapist = computeMultiTher(therapistObj.therapistList, items);
  }

  return therapist;
};

export default getTherapist;

const computeSingleTher = (therapist, items) => {
  let therObj = {};
  _.forEach(items, item => {
    let courseMinutes = item.timeMinutes * item.qty;
    let pickTime = item.timestamp.toString();
    therObj[pickTime] = {
      courseUid: item.uid,
      courseMinutes,
      courseQty: item.qty,
      courseName: item.name,
      pickTime,
      therapists: [
        {
          ...therapist,
          workMinutes: parseInt(courseMinutes)
        }
      ]
    };
  });

  return _.orderBy(therObj, "pickTime", "asc");
};

const computeMultiTher = (coureList, items) => {
  let therObj = {};
  debugger
  let productObj = {};
  _.map(items, item => {
    productObj[item.timestamp.toString()] = item;
  });

  _.map(coureList, (therList, key) => {
    let product = productObj[key];
    let courseMinutes = product.timeMinutes * product.qty;
    let pickTime = product.timestamp.toString();
    therObj[pickTime] = {
      courseUid: product.uid,
      courseMinutes,
      courseQty: product.qty,
      courseName: product.name,
      pickTime,
      therapists: []
    };

    _.forEach(therList, ther => {
      therObj[pickTime].therapists.push({
        ...ther.therapist,
        workMinutes: parseInt(ther.workMinutes)
      });
    });
  });

  return _.orderBy(therObj, "pickTime", "asc");
};

//ตอนคำณวนเสร็จแล้ว
// const therapist = {
//   //PICKTIME UID
//   "2492490140": {
//     courseUid: "ABCXYZ",
//     courseMinutes: 4, // time * qty,
//     courseName: item.name,
//     therapists: [
//       {
//         ...therapist, //THERAPIST DETAIL A
//         workMinutes: 2 //จำนวนชั่วโมงที่ทำงาน
//       },
//       {
//         ...therapist, //THERAPIST DETAIL B
//         workMinutes: 2 //จำนวนชั่วโมงที่ทำงาน
//       }
//     ]
//   },
//   //PICKTIME UID
//   "314904091": {
//     courseUid: "ABCXYZ",
//     courseMinutes: 2, // time * qty
//     therapists: [
//       {
//         ...therapist, //THERAPIST DETAIL A
//         workMinutes: 2 //จำนวนชั่วโมงที่ทำงาน
//       }
//     ]
//   }
// };
