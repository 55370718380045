import React, { useState, useEffect } from "react";
import SpaEmpManageModal from "../../shared/components/SpaEmpManageModal";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import EmployeeGroupModal from "./EmployeeGroupModal";
import _ from "lodash";
import swal from "sweetalert";
import moment from "moment";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle
} from "react-sortable-hoc";
import async from "async";
import Select from "react-select";

const EMPLOYEE_TABLE = "Spa_Employees";
const AGENT_TABLE = "Spa_Agents";
const ENTERTAIN_TABLE = "Spa_Entertains";
const SALES_TABLE = "Spa_Sales";

function Employee(props) {
  const [mode, setMode] = useState("Create");
  const [selectedEmp, selectEmp] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [sortedEmp, setSortedEmp] = useState([]);
  const [table, setTable] = useState(EMPLOYEE_TABLE);
  const [multiEmpHolder, setMultiEmpHolder] = useState({});
  const [time, setTime] = useState(moment());
  const [groupObj, setGroupObj] = useState({});
  const [selectedGroup, selectGroup] = useState("");
  const [inputSearchEmp, setInputSearchEmp] = useState(null);

  useEffect(() => {
    setInputSearchEmp("");
    if (props.view === "Employee") {
      setTable(EMPLOYEE_TABLE);
      fetchEmployees(EMPLOYEE_TABLE, true);
    } else if (props.view === "Agent") {
      setTable(AGENT_TABLE);
      fetchEmployees(AGENT_TABLE, true);
    } else if (props.view === "Entertain") {
      setTable(ENTERTAIN_TABLE);
      fetchEmployees(ENTERTAIN_TABLE, true);
    } else if (props.view === "Sales") {
      setTable(SALES_TABLE);
      fetchEmployees(SALES_TABLE, true);
    }

    selectGroup("");
  }, [props.view]);

  const fetchEmployees = async (forceTable, isNoFilter) => {
    let currentTable = forceTable || table;
    let params = {
      TableName: currentTable,
      KeyConditionExpression: "entityUid = :entityUid",
      ExpressionAttributeValues: {
        ":entityUid": props.shop.uid
      }
    };
    if (currentTable === "Spa_Employees") {
      params = {
        ...params,
        FilterExpression: "contains(#flag, :flag)",
        ExpressionAttributeNames: {
          "#flag": "flag"
        },
        ExpressionAttributeValues: {
          ":entityUid": props.shop.uid,
          ":flag": "2020"
        }
      };
    }
    let data = await documentClient.query(params).promise();
    if (data.Items) {
      // let ordered = _.orderBy(data.Items, ["group", "row"], ["asc", "asc"]);
      let ordered = _.orderBy(data.Items, "row", "asc");

      setEmployees(ordered);
      if (isNoFilter) {
        setSortedEmp(ordered);
      } else if (selectedGroup !== "") {
        filterEmpByGroup(selectedGroup, ordered);
      } else {
        setSortedEmp(ordered);
      }
    } else {
      setEmployees([]);
      setSortedEmp([]);
    }

    props.actions.fetchSpaSales();
    // setMultiEmpHolder({});
  };

  useEffect(() => {
    setGroupObj(extractGroup(employees));
  }, [employees]);

  useEffect(() => {
    return () => {
      props.actions.fetchSpaSales();
    };
  }, []);

  const deleteEmp = emp => {
    swal({
      title: `คุณต้องการลบ "${emp.name}" ?`,
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(async willDelete => {
      if (willDelete) {
        var params = {
          TableName: table,
          Key: {
            entityUid: emp.entityUid,
            uid: emp.uid
          }
        };
        try {
          await documentClient.delete(params).promise();
          fetchEmployees();
          swal("สำเร็จ", "ลบ Employee สำเร็จ", "success");
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const setEmpHolder = emp => {
    if (multiEmpHolder[emp.uid]) {
      let newHolder = _.cloneDeep(multiEmpHolder);
      delete newHolder[emp.uid];
      setMultiEmpHolder(newHolder);
      return;
    }
    setMultiEmpHolder({ ...multiEmpHolder, [emp.uid]: true });
  };

  const handleSelectGroup = group => {
    selectGroup(group);
    filterEmpByGroup(group);
  };

  const filterEmpByGroup = (branchUid, forceNewEmps) => {
    if (branchUid === "") {
      let ordered = _.orderBy(employees, ["group", "row"], ["asc", "asc"]);
      setSortedEmp(ordered);
      return;
    }
    let filtered = _.filter(
      forceNewEmps || employees,
      i => i.branchUid === branchUid
    );
    let ordered = _.orderBy(filtered, "row", "asc");
    setSortedEmp(ordered);
  };

  const filterEmpByInput = () => {
    let empList = sortedEmp;
    if (inputSearchEmp !== "") {
      empList = [];
      _.forEach(sortedEmp, emp => {
        if (_.includes(emp.name, inputSearchEmp) || _.includes(emp.id, inputSearchEmp) ) {
          empList.push(emp);
        }
      });
    }
    return empList;
  };

  const empList = filterEmpByInput();

  return (
    <div>
      <SpaEmpManageModal
        {...props}
        empList={empList}
        mode={mode}
        selectedEmp={selectedEmp}
        table={table}
        fetch={fetchEmployees}
        time={time}
        groupObj={groupObj}
      />
      <EmployeeGroupModal
        {...props}
        employees={employees}
        mode={mode}
        multiEmpHolder={multiEmpHolder}
        setMultiEmpHolder={setMultiEmpHolder}
        time={time}
        table={table}
        fetch={fetchEmployees}
        groupObj={groupObj}
      />
      <div className="card card-table">
        <div className="card-body">
          <div className="row m-3 mt-2">
            {props.view === "Employee" && (
              <div className="col-3">
                <span style={{ color: "gray", fontSize: 14 }}>สาขา</span>
                <br />
                <select
                  className="form-control"
                  onChange={e => handleSelectGroup(e.target.value)}
                  style={{ height: 30, width: "auto" }}
                >
                  <option value={""}>All</option>
                  {_.map(props.branchOptions, branch => {
                    return (
                      <option
                        value={branch.value}
                        selected={selectedGroup === branch.value}
                      >
                        {branch.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <div className="col-4">
              <span style={{ color: "gray", fontSize: 14 }}>Search</span>
              <input
                type="text"
                className="form-control form-control-xs"
                value={inputSearchEmp}
                onChange={e => setInputSearchEmp(e.target.value.toUpperCase())}
                placeholder="Search by name or ID"
              />
             
            </div>
          </div>
          <hr className="m-0" />
          <table className="table table-striped table-borderless">
            <thead>
              <tr>
                <th style={{ width: "4%" }} />
                {/* <th style={{ width: "4%" }} /> */}
                {props.view === "Employee" && <th>Branch</th>}
                <th>ID</th>
                <th>Name</th>
                <th>Mobile</th>

                <th>E-Mail</th>
                {props.view === "Agent" && <th>Note</th>}
                {/* {props.view === "Employee" && <th>Branch</th>} */}
                <th>
                  {/* <button
                    className="btn btn-space btn-secondary m-0 mr-2"
                    onClick={() => {
                      setTime(moment());
                    }}
                    data-toggle="modal"
                    data-target="#employeeGroupModal"
                    disabled={_.isEmpty(multiEmpHolder)}
                  >
                    Move Group
                  </button> */}
                  <button
                    className="btn btn-space btn-primary m-0"
                    onClick={() => {
                      setMode("Create");
                      setTime(moment());
                    }}
                    data-toggle="modal"
                    data-target="#spaEmpManageModal"
                  >
                    Create
                  </button>
                </th>
              </tr>
            </thead>
            <SortableComponent
              {...props}
              items={empList}
              selectedGroup={selectedGroup}
              setEmpHolder={setEmpHolder}
              multiEmpHolder={multiEmpHolder}
              selectEmp={selectEmp}
              setMode={setMode}
              deleteEmp={deleteEmp}
              table={table}
              fetchEmployees={fetchEmployees}
            />
          </table>
        </div>
      </div>
    </div>
  );
}
const DragHandle = SortableHandle(() => (
  <i className="icon icon-left mdi mdi-sort-amount-desc" />
));
class SortableComponent extends React.Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    let { actions, items } = this.props;
    let newItems = arrayMove(items, oldIndex, newIndex);

    let index = 1;
    let self = this;
    async.eachSeries(
      newItems,
      (item, cb) => {
        let params = {
          TableName: self.props.table,
          Key: {
            entityUid: item.entityUid,
            uid: item.uid
          },
          UpdateExpression: "set #row = :newRow",
          ExpressionAttributeNames: { "#row": "row" },
          ExpressionAttributeValues: {
            ":newRow": index
          }
        };
        documentClient.update(params, function(err, data) {
          if (err) {
            // alert(err)
            console.log(err);
          } else {
            index++;
            cb();
          }
        });
      },
      err => {
        if (err) {
          console.log(err);
        } else {
          this.props.fetchEmployees();
          swal("สำเร็จ !", "Reorder Complete", "success");
        }
      }
    );
  };
  render() {
    const { items } = this.props;
    return (
      <SortableList
        {...this.props}
        useDragHandle={true}
        items={items}
        onSortEnd={this.onSortEnd}
        pressDelay={200}
      />
    );
  }
}

const SortableList = SortableContainer(props => {
  return (
    <tbody className="no-border-x">
      {_.map(props.items, (item, index) => {
        return (
          <SortableItem
            {...props}
            key={`item-${index}`}
            i={item}
            index={index}
          />
        );
      })}
    </tbody>
  );
});

const SortableItem = SortableElement(props => {
  let emp = props.i;

  return (
    <tr
    // onClick={() => props.setEmpHolder(emp)}
    >
      {/* <td>
        <div className="custom-control custom-control-sm custom-checkbox">
          <input
            className="custom-control-input"
            type="checkbox"
            checked={props.multiEmpHolder[emp.uid]}
          />
          <label className="custom-control-label" for="check2" />
        </div>
      </td> */}
      <td>
        <DragHandle />
      </td>
      {/* <td>{emp.group || "-"}</td> */}
      {props.view === "Employee" && <th>{emp.branch && emp.branch.name}</th>}
      <td>{emp.id}</td>
      <td>{emp.name}</td>
      <td>{emp.mobile}</td>

      <td>{emp.email}</td>
      {props.view === "Agent" && <td>{emp.note}</td>}
      <td>
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            props.selectEmp(emp);
            props.setMode("Edit");
          }}
          data-toggle="modal"
          data-target="#spaEmpManageModal"
        >
          View
        </a>
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            props.deleteEmp(emp);
          }}
          style={{ color: "red", paddingLeft: 10 }}
        >
          Delete
        </a>
      </td>
    </tr>
  );
});

// const getEmpOptions = employees => {
//   let arr = [];
//   _.forEach(employees, emp => {
//     let name = emp.name;
//     if (emp.id) {
//       name += ` (${emp.id})`;
//     }
//     arr.push({
//       label: name,
//       value: emp.uid
//     });
//   });
//   return arr;
// };

const extractGroup = data => {
  let groupObj = {};
  _.map(data, i => {
    if (i.group) {
      groupObj[i.group] = true;
    }
  });
  return groupObj;
};

export default Employee;
