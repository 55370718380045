import * as inventorySelector from "../shared/selector/inventorySelector";
import documentClient from "../shared/awsAPIs/dynamoDb";
import _ from "lodash";

const ComputeProductMiddle = store => next => {
  setTimeout(() => {
    let state = store.getState();
    let typeGroupItem = inventorySelector.typeGroupItemSelector(state);
    let shop = state.shop;

    let storeUid = shop.detail.uid;

    // if (shop.linked.inventory) {
    //   storeUid = shop.linked.inventory;
    // }

    //put dynamodb
    var params = {
      TableName: "Spa_Compute_Data",
      Item: {
        storeUid: storeUid,
        name: "products",
        value: typeGroupItem
      }
    };
    documentClient.put(params, (err, data) => {
      if (err) {
        console.log(err);
        return;
      }
    });
  }, 5000);

  return action => {
    return next(action);
  };
};

export default ComputeProductMiddle;

const editItemTime = async () => {
  var params = {
    TableName: "Minor_Inventory_Items"
    // Key: {
    //   HashKey: "hashkey"
    // }
  };
  let resItems = await documentClient.scan(params).promise();
  let items = resItems.Items;
  let tmpItemsToPut = [];
  _.forEach(items, item => {
    if (item.name) return;
    //  ;
    // let timeSplit = _.split(item.time, ".");
    // let hours = parseInt(timeSplit[0]) * 60;
    // let minutes = parseInt(timeSplit[1]);
    // let timeMinutes = hours + minutes;
    // tmpItemsToPut.push({ ...item, timeMinutes });
    tmpItemsToPut.push({ ...item });
  });

  //PUT TIME
  let putCompleted = 0;

  await new Promise((resolve, reject) => {
    _.forEach(tmpItemsToPut, item => {
      var params = {
        TableName: "Minor_Inventory_Items",
        Item: {
          // shopId: item.shopId,
          // uid: item.uid
          ...item
        }
      };
      documentClient.put(params, (err, data) => {
        if (err) {
          console.log(err);
          reject(err);
          return;
        }
        putCompleted++;

        if (putCompleted === tmpItemsToPut.length) {
          resolve("Complete !");
        }
      });
    });
  });
};
