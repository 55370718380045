import _ from "lodash";
import documentClient from "../awsAPIs/dynamoDb";
// import swal from 'sweetalert'
import moment from "moment";
import swal from "sweetalert";
// import * as type from "../types/memberTx";

export const pushMemberTx = (memberUid, txType, options, cb) => {
  //options - amount,remark,type,refId

  //! Function Stream อยู่ใน Project "divana-api" ชื่อ "updateMemberWalletBalance"

  return (dispatch, getState) => {
    const { shop, employee } = getState();

    if (!options.amount || !options.type) {
      alert("กรุณาระบุ Amount และ Type");
      return;
    }

    var params = {
      TableName: getTxTypeTable(txType),
      Item: {
        memberUid,
        timestamp: moment().toISOString(), 
        amount: options.amount,
        remark: options.remark === "" ? null : options.remark,
        type: options.type.toUpperCase(), //TOPUP, SPEND,ADJUST
        store: shop.detail,
        hqUid: shop.linked.invetory || shop.detail.uid,
        employee: employee.current,
        refId: options.refId === "" ? null : options.refId
      }
    };
    documentClient.put(params, (err, res) => {
      if (err) {
        console.log(err);
        return;
      }

      cb && cb();
    });
  };
};

export const fetchMemberTx = (memberUid, txType, cb) => {
  return (dispatch, getState) => {
    var params = {
      TableName: getTxTypeTable(txType),
      //   IndexName: "Index",
      KeyConditionExpression:
        // "memberUid = :memberUid and timestamp = :timestamp",
        "memberUid = :memberUid",
      //   ExpressionAttributeNames: { "#timestamp": "timestamp" },
      ExpressionAttributeValues: {
        ":memberUid": memberUid
        // ":timestamp": timestamp
      }
    };

    documentClient.query(params, function(err, data) {
      if (err) {
        console.log(err);
      } else {
        let orderedDesc = _.orderBy(data.Items, "timestamp", "desc");
        cb && cb(orderedDesc);
      }
    });
  };
};

const getTxTypeTable = type => {
  switch (type) {
    case "wallet":
      return "Wallet_Transactions";
    case "point":
      return "Point_Transactions";
    default:
      break;
  }
};
