import documentClient from "../shared/awsAPIs/dynamoDb";
import _ from "lodash";
// import swal from "sweetalert";
import Swal from "sweetalert2";

const fixMemberIdToUpperCase = async () => {
  const members = await asyncFetchMembers(); //WILL TAKE A MINUTE
  _.forEach(members, member => updateMember(member));
};

const asyncFetchMembers = lastkey => {
  return new Promise(async (resolve, reject) => {
    let initial = true;
    let members = [];
    let lastKey = "first";

    const setValue = (rec, key) => {
      initial = false;
      members = [...members, ...rec];
      lastKey = key;
    };

    while (initial || lastKey) {
      let res = await fetchMembers(lastKey !== "first" ? lastKey : null);
      setValue(res.Items, res.LastEvaluatedKey);
      console.log("Fetched");
    }
    resolve(members);
  });
};

const fetchMembers = async lastkey => {
  let params = {
    TableName: "Crm_Users",
    FilterExpression: "attribute_exists(memberId)"
    // FilterExpression: "version <> :version",
    // ExpressionAttributeValues: {
    //   ":version": "2020-1"
    // }
  };

  if (lastkey) {
    params.ExclusiveStartKey = lastkey;
  }

  return documentClient.scan(params).promise();
};

const updateMember = async member => {
  if (!member.memberId) return;

  let newMemberId = member.memberId.toUpperCase();
  if (member.memberId === "Dii0553") {
    debugger;
  }
  var params = {
    TableName: "Crm_Users",
    Key: { uid: member.uid },
    UpdateExpression: "set memberId = :memberId",
    ExpressionAttributeValues: {
      ":memberId": newMemberId
    }
  };

  try {
    await documentClient.update(params);
    console.log(`Updated: ${newMemberId}`);
  } catch (error) {
    console.log(error);
  }
};

window.fixMemberIdToUpperCase = fixMemberIdToUpperCase;

const testSweetAlert = async () => {
  const { value: formValues } = await Swal.fire({
    title: "บันทึกข้อมูลอื่น ๆ",
    html:
      '<div style="color:gray">' +
      `เพิ่ม Internal Note สำหรับ ` +
      "</div>" +
      '<div class="mb-2"><textarea id="swal-input1" class="swal2-input" placeholder="หากไม่ต้องเพิ่ม Note ไม่ต้องกรอกข้อมูล"></textarea></div>' +
      '<div><input id="swal-input2" class="form-control" type="checkbox"> <span style="color:gray">Evaluate</span></div>',
    focusConfirm: false,
    preConfirm: () => {
      let text = document.getElementById("swal-input1").value;
      let isEvaluate = document.getElementById("swal-input2").checked;
      return [text, isEvaluate];
    }
  });

  if (formValues) {
    Swal.fire(JSON.stringify(formValues));
  }
};
window.testSweetAlert = testSweetAlert;
