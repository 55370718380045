import React, { useState } from "react";




export const SummaryList = function(props) {
  return (
    <div class="card">
      <div class="card-body">
        <ul class="summary-list">{props.children}</ul>
      </div>
    </div>
  );
};

export const RowButton = function({ title, onClick, buttonLabel }) {
  return (
    <li class="summary-list-item">
      <p>
        <span class=" item-left">{title}</span>

        <span class=" item-right">
          <button class="btn btn-space btn-secondary" onClick={onClick}>
            {buttonLabel}
          </button>
        </span>
      </p>
    </li>
  );
};

export const RowDropDown = function({ title, onClick, buttonLabel, value, onChange }) {
  return (
    <li class="summary-list-item">
      <p>
        <span class=" item-left">{title}</span>

        <span class=" item-right">
              <select class=" " style={{width : 60}}   value={value}  onChange={onChange}>
                          <option value="1" selected="">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
          
        </span>
      </p>
    </li>
  );
};
 

export const Row = function(props) {
  return (
    <li class="summary-list-item">
      <p>{props.children}</p>
    </li>
  );
};

export const Right = function(props) {
  return <span class=" item-right">{props.children}</span>;
};


  
export const   Left = function(props) {
  return <span class=" item-left">{props.children}</span>;
};

export const Radio = ({ label, checked, onClick, name }) => {
  return (
    <label class="custom-control custom-radio custom-control-inline" onClick={onClick}>
      <input
        class="custom-control-input"
        type="radio"
        name={ name || "radio-inline"}
        checked={checked}
      />
       <span class="custom-control-label">{label}</span> 
    </label>
  );
};



export const  useRadioViewButtons = function useRadioViewButtons(startView, { groupDate = false } = {}) {

  const [view, setView] = useState(startView || 'summary');
 
  
  const render =  [
    <span style={{marginRight : 32}}>Select View </span>,
    <Radio label="Summary"  checked={view === 'summary'}  onClick={()=> setView('summary')}   /> ,
    groupDate &&  <Radio label="Date"  checked={view === 'date'}  onClick={()=> setView('date')}   /> ,
    <Radio label="Detail" checked={view === 'detail'}  onClick={()=> setView('detail')}/> ,
    <span style={{width : 60}}>  </span>

  ]
    

  return [view,  render  ];


}