import moment from "moment";
import Paper from "niceloop-paper";
import _ from "lodash";
 
// payment receipts
class VoidReceipt {
  receipt = null;
  dispatch = null;
  printCmdResult = null;
  printType = "RECEIPT_FULL";

  constructor(dispatch, receipt, options = {}) {
    this.dispatch = dispatch;
    this.receipt = receipt;
    this.reason = options.reason;
    this.employeeName = options.employeeName;
    this.build();
    this.print();
  }

  build() {
    //obj is table instance

    var receipt = this.receipt;
    var Order = {};

    Order.UniqueId = this.getRandomInt(99999999999);

    Order.Printer = [0];
    Order.Title1 = "VOID BILL";
    Order.Title2 = null;

    Order.isPreview = true;

    Order.CustomHeaders1 = null;
    Order.CustomHeaders2 = this.genHeader();
    Order.CustomHeaders3 = this.genHeaderReceipt();
    Order.CustomHeaders4 = this.genVoidData();
    Order.CustomHeaders5 = null;
    Order.CustomHeaders6 = null;
    Order.CustomHeaders7 = null;
    Order.CustomHeaders8 = null;
    Order.CustomHeaders9 = null;
    Order.CustomHeaders10 = null;

    Order.Table = null;
    Order.Watermark = "VOIDED";
    Order.WaterMark = "VOIDED";
    Order.waterMark = "VOIDED";

    Order.OrderItems = this.convertItemsToPrint(receipt.items);
    Order.Summary1 = [];
    Order.Summary2 = [];
    Order.GrandTotal = null;
    Order.CustomFooters1 = null;
    Order.CustomFooters2 = null;
    Order.CustomFooters3 = null;
    Order.CustomFooters4 = null;
    Order.CustomFooters5 = null;
    Order.CustomFooters6 = null;
    Order.CustomFooters7 = null;
    Order.CustomFooters8 = null;
    Order.CustomFooters9 = this.sign();
    Order.CustomFooters10 = null;
    Order.Note = null;

    this.Order = Order;
    return this;
  }

  sign() {
    var paper = new Paper();

    paper.lineFeed();

    paper.lineFeed();

    paper.textLine("___________________________");
    paper.textLine("  Sign ");

    return paper.gen();
  }

  genVoidData() {
    var paper = new Paper();
    let { meta, member, receiptId } = this.receipt;
    paper.keyValue("VOID Date", moment().format("DD/MM/YYYY HH:mm:ss"));
    paper.keyValue("VOID By", this.employeeName);
    paper.lineFeed();

    paper.comment("Reason : " + this.reason);
    paper.lineFeed();

    paper.lineFeed();
    return paper.gen();
  }

  genHeaderReceipt() {
    var paper = new Paper();
    let {
      meta,
      member,
      receiptId,
      therapist,
      payment,
      timestamp,
      localTime,
      refId,
      orderId
    } = this.receipt;
    // member &&
    paper.keyValue("Receipt ID", receiptId);
    paper.keyValue("Ref ID", refId || orderId);

    // therapist &&
    paper.keyValue(
      "Receipt Date",
      moment(localTime).format("DD/MM/YYYY HH:mm:ss")
    );
    paper.keyValue("Grand Total", payment.grandTotal);
    paper.lineFeed();
    return paper.gen();
  }

  genHeader() {
    var paper = new Paper();
    let { meta, member, therapist, therapistList } = this.receipt;
    member && paper.keyValue("Member", member.name);

    if (therapist) {
      therapist && paper.keyValue("Therapist", therapist.name);
    }

    if (therapistList) {
      _.forEach(therapistList, (ther, idx) => {
        paper.keyValue(`Therapist ${idx + 1}`, ther.name);
      });
    }

    paper.lineFeed();
    return paper.gen();
  }
  genSummaryHeader() {
    var paper = new Paper();
    let { therapist, receiptId, jobUid, meta = {}, member } = this.receipt;

    paper.keyValue(`Order No : ${jobUid}`);
    paper.keyValue(`Print Date : ${moment().format("DD/MM/YYYY")}`);
    therapist && paper.keyValue(`Therapist : ${therapist.name || "-"}`);
    paper.keyValue(`Invoice No : ${receiptId}`);
    member && paper.keyValue(`Guest : ${member.name || "-"}`);
    paper.keyValue(
      `Time : ${moment(meta.startDate).format("HH:mm")} - ${moment(
        meta.endDate
      ).format("HH:mm")}`
    );

    return paper.gen();
  }

  convertItemsToPrint(items) {
    var paper = new Paper();
    //ITEMS
    _.forEach(items, (item, i) => {
      if (item.promotion) return;

      if (item.name.length > 30) {
        let chunkName = chunkString(item.name, 30);

        _.forEach(chunkName, (text, idx) => {
          if (idx === 0) {
            paper.keyValue(
              `${item.qty}  ${text}`,
              item.discountedPrice.toLocaleString() ||
                item.extendedPrice.toLocaleString()
            );
          } else {
            paper.keyValue(`    ${text}`);
          }
        });
      } else {
        paper.keyValue(
          `${item.qty}  ${item.name}`,
          item.discountedPrice.toLocaleString() ||
            item.extendedPrice.toLocaleString()
        );
      }
    });

    //Promotion
    let promotions = _.filter(items, item => item.promotion === true);
    if (promotions.length > 0) {
      paper.textLine("-------------------------");
      _.forEach(promotions, promo => {
        if (promo.printName) {
          paper.keyValue(`    ${promo.printName[0]}`, "");
          paper.keyValue(
            `${promo.qty}   ${promo.printName[1]}`,
            promo.price.toLocaleString()
          );
        } else {
          paper.keyValue(
            `${promo.qty}   ${promo.name}`,
            promo.price.toLocaleString()
          );
        }
      });
    }

    return paper.gen();
  }

  print() {
    this.dispatch({
      type: "PRINT",
      meta: {
        printType: this.printType,
        type: "PRINT"
      },
      payload: this.Order
    });
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
}

export default VoidReceipt;

const convertMinuteToHour = timeMinutes => {
  var hours = Math.floor(timeMinutes / 60);
  var minutes = timeMinutes % 60;
  let time = hours + "." + minutes;
  return time;
};

const chunkString = (str, len) => {
  var _size = Math.ceil(str.length / len),
    _ret = new Array(_size),
    _offset;
  for (var _i = 0; _i < _size; _i++) {
    _offset = _i * len;
    _ret[_i] = str.substring(_offset, _offset + len);
  }
  return _ret;
};
