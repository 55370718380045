import React from 'react'

const getStatusColorButton = (status) => {
    let name = 'Edited'
    let color = 'secondary'
    switch (status.toString()) {
        case '200':
            name = 'Pending';
            color = 'primary'
            break;
        // case '400':
        //     name = 'Process';
        //     break;
        // case '500':
        //     name = 'Shipping';
        //     break;
        case '600':
            name = 'Confirmed';
            color = 'success'
            break;
        case '900':
            name = 'Void';
            color = 'danger'
            break;
        case '300':
            name = 'Edited';
            color = 'warning'
            break;
        default:
            break;
    }

    return <span className={`badge badge-${color}`}>{name}</span>
}

export default getStatusColorButton