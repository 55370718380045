// element.style {
//     background: bisque;
//     width: 100%;
//     position: fixed;
//     left: 230px;
//     top: 60px;
//     height: 100%;

import React, { useState, useEffect, useRef } from "react";
import BlockUi from "react-block-ui";

const textList = [
  "Loading.",
  "Loading &nbsp; . &nbsp;",
  "Loading &nbsp; &nbsp; ."
];

function BlockScreenMainContent() {
  const [text, setText] = useState(textList[0]);
  const [count, setCount] = useState(0);
//   useInterval(() => {
//     let newCount = count + 1;
//     if (newCount >= 3) {
//       newCount = 0;
//     }
//     setCount(newCount);
//     setText(textList[newCount]);
//   }, 200);

  return (
    <div class="text-center block-screen-maincontent ">
      <BlockUi blocking={true} message="Loading...">
        {/* <h1>Loading...</h1> */}
      </BlockUi>
    </div>
  );
}

export default BlockScreenMainContent;

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
