import React, { useState, useEffect } from "react";
import _ from "lodash";
import swal from "sweetalert";
import moment from "moment";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle
} from "react-sortable-hoc";
import randomUid from "../../shared/randomUid";

function SettingMultiInputForm(props) {
  const form = MultipleInput([]);
  const [mode, setMode] = useState("current");

  useEffect(() => {
    setDataList();
  }, [props.setting]);

  function setDataList(forceMode) {
    let tmpMode = forceMode || mode;

    try {
      let list = props.setting[tmpMode][props.keyNode].value || [];
      form.setValue(list);
      setMode(tmpMode);
    } catch (error) {
      console.log(error);
    }
  }

  function saveData(options = {}) {
    let list = form.value;
    if (options.newText) {
      list = [...list, { name: options.newText, row: moment().valueOf() }];
    }
    props.actions.updateSetting(props.keyNode, list, () => {
      swal("สำเร็จ", "บันทึกข้อมูลสำเร็จ", "success");
      props.actions.fetchSetting();
      options.callBack && options.callBack();
    });
  }

  let readOnly = mode === "mother";
  return (
    <div>
      {/* <h3>{mode.toLocaleUpperCase()}</h3> */}

      <div className="row m-0">
        <div className="col-md-8 offset-md-2">
          {props.isLinked && (
            <Row
              col3={
                <div>
                  <label>เลือกสาขา</label>
                  <select
                    className="form-control"
                    onChange={e => setDataList(e.target.value)}
                    style={{ width: 150 }}
                    value={mode}
                  >
                    <option value="current">Current</option>
                    <option value="mother">HQ</option>
                  </select>
                </div>
              }
            />
          )}

          {props.keyNode === "paymentType" && <PaymentTypeStatic />}

          <SortableComponent
            {...props}
            items={form.value}
            form={form}
            readOnly={readOnly}
          />
          {!readOnly && <RowAdd addRow={form.addRow} saveData={saveData} />}
        </div>
      </div>
    </div>
  );
}

class SortableComponent extends React.Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    let newItems = arrayMove(this.props.items, oldIndex, newIndex);
    _.forEach(newItems, (data, idx) => {
      data.row = idx;
    });
    this.props.form.setValue(newItems);
  };
  render() {
    return (
      <SortableList
        {...this.props}
        useDragHandle={true}
        items={this.props.items}
        onSortEnd={this.onSortEnd}
      />
    );
  }
}

const SortableList = SortableContainer(props => {
  return (
    <div>
      {_.map(props.items, (value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          index2={index}
          value={value}
          {...props}
        />
      ))}
    </div>
  );
});

const SortableItem = SortableElement(props => {
  let { value, index2, form, keyNode, mode } = props;

  return (
    <RowInput
      {...props}
      lineNo={
        index2 + (keyNode === "paymentType" && mode === "current" ? 4 : 1)
      }
      nameInput={{
        value: value.name,
        onChange: e => form.onChange(e.target.value, "name", index2)
      }}
      isCreditCard={value.isCreditCard}
      setIsCreditCard={() => {
        form.onChange(!value.isCreditCard, "isCreditCard", index2);
      }}
      removeLine={() => form.removeLine(index2)}
    />
  );
});

const RowInput = props => {
  const {
    readOnly,
    keyNode,
    nameInput = {},
    isCreditCard = false,
    setIsCreditCard
  } = props;
  return (
    <Row
      col1={!readOnly && <DragHandle />}
      col2={props.lineNo}
      col3={
        <div className="input-group">
          <input
            className="form-control"
            type="text"
            // {...props}
            value={nameInput.value || ""}
            onChange={nameInput.onChange}
            disabled={readOnly}
          />
          {!readOnly && (
            <div className="input-group-append">
              <button
                className="btn btn-danger"
                type="button"
                style={{ width: 52 }}
                onClick={props.removeLine}
              >
                <span className="mdi mdi-delete" style={{ fontSize: 22 }} />
              </button>
            </div>
          )}
        </div>
      }
      col4={
        (keyNode === "paymentType" && (
          <div
            class="be-checkbox custom-control custom-checkbox m-2"
            onClick={setIsCreditCard}
          >
            <input
              class="custom-control-input"
              type="checkbox"
              disabled={readOnly}
              checked={isCreditCard}
            />
            <label class="custom-control-label">Credit Card</label>
          </div>
        )) ||
        ""
      }
    />
  );
};

const Row = ({ col1, col2, col3, col4 }) => {
  return (
    <div className="row m-0 mb-3">
      <div className="col-md-1 align-self-center">{col1}</div>
      <div className="col-md-1 align-self-center">{col2}</div>
      <div className="col-md-8">{col3}</div>
      <div className="col-md-2 pl-0 pr-0">{col4}</div>
    </div>
  );
};

const RowAdd = props => {
  let [text, setText] = useState("");
  function handleAddRow() {
    props.addRow(text, () => {
      setText("");
    });
  }
  return [
    <div className="row m-0">
      <div className="col-md-8 offset-md-2">
        <div className="input-group mb-3">
          <input
            className="form-control"
            type="text"
            value={text}
            onChange={e => setText(e.target.value)}
            // placeholder="New Payment Type..."
          />
          <div className="input-group-append">
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleAddRow}
              style={{ width: 52 }}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>,
    <Row
      col3={
        <button
          className="btn btn-space btn-primary"
          style={{ width: "100%" }}
          onClick={() => {
            if (text !== "") {
              props.saveData({
                newText: text,
                callBack: () => {
                  setText("");
                }
              });
            } else {
              props.saveData();
            }
          }}
        >
          <span>SAVE</span>
        </button>
      }
    />
  ];
};

function MultipleInput(initValue) {
  const [value, setValue] = useState(initValue || []);

  function handleChange(text, key, index) {
    let newVal = _.cloneDeep(value);
    newVal[index][key] = text;
    setValue(newVal);
  }

  function addRow(name, cb) {
    if (name === "") {
      return swal("ข้อมูลไม่ครบถ้วน", "กรุณากรอกชื่อ Payment Type", "error");
    }
    let newVal = _.cloneDeep(value);
    newVal.push({ name, row: moment().valueOf(), uid: randomUid(10) });
    setValue(newVal);
    cb && cb();
  }

  function removeLine(idx) {
    let newVal = [...value.slice(0, idx), ...value.slice(idx + 1)];
    setValue(newVal);
  }

  return {
    value,
    onChange: handleChange,
    setValue,
    addRow,
    removeLine
  };
}

const DragHandle = SortableHandle(() => (
  <i
    style={{ fontSize: 20 }}
    className="icon icon-left mdi mdi-sort-amount-desc"
  />
));

export default SettingMultiInputForm;

const PaymentTypeStatic = () => {
  return [
    <RowInput lineNo={1} nameInput={{ value: "Cash" }} readOnly={true} />,
    <RowInput lineNo={2} nameInput={{ value: "City Ledger" }} readOnly={true} />
    // <RowInput lineNo={3} nameInput={{ value: "Entertain" }} readOnly={true} />
  ];
};
