import moment from "moment";
import * as aws from "../awsAPIs/instance";

export const fetch = async (storeUid, startDate, endDate = null) => {
  var params = {
    TableName: "Spa_Receipts",
    KeyConditionExpression:
      "storeUid = :hkey  AND #timestamp BETWEEN :startDate AND :endDate ",
    ExpressionAttributeNames: {
      "#timestamp": "timestamp"
    },
    ExpressionAttributeValues: {
      ":hkey": storeUid,
      // ":startDate": moment(startDate).format("YYYY-MM-DD"),
      // ":endDate":
      //   moment(endDate || startDate).format("YYYY-MM-DD") +
      //   "zzzzzzzzzzzzzzzzzzzzzzzzzzz"

      ":startDate": moment(startDate)
        .startOf("day")
        .toISOString(),
      ":endDate":
        moment(endDate || startDate)
          .endOf("day")
          .toISOString() + "zzzzzzzzzzzzzzzzzzzzzzzzzzz"
    }
  };

  var lasyKey = null;
  var res = [];
  do { 
    lasyKey ? (params.ExclusiveStartKey = lasyKey) : (lasyKey = null);
    var data = await aws.documentClient.query(params).promise();
    res = res.concat(data.Items);
    lasyKey = data.LastEvaluatedKey;
  } while (data.LastEvaluatedKey);

  return res;
};

export const fetchBusinessDate = async (
  storeUid,
  startDate,
  endDate = null
) => {
  var params = {
    TableName: "Spa_Receipts",
    KeyConditionExpression:
      "storeUid = :hkey  AND        #timestamp BETWEEN :startDate AND :endDate ",
    FilterExpression: "#version = :version OR #version = :version1", //"contains(#flag, :flag)",

    ExpressionAttributeNames: {
      "#timestamp": "timestamp",
      "#version": "version"
    },
    ExpressionAttributeValues: {
      ":hkey": storeUid,
      ":version": 2020,
      ":version1": "2020-1",

      ":startDate": moment(startDate)
        .startOf("day")
        .format('YYYY-MM-DD'),
      ":endDate":
        moment(endDate || startDate)
          .endOf("day")
          .format('YYYY-MM-DD') + "#zzzzzzzzzzzzzzzzzzzzzzzzzzz"
    }
  };

  var lasyKey = null;
  var res = [];
  do {
    lasyKey ? (params.ExclusiveStartKey = lasyKey) : (lasyKey = null);
    var data = await aws.documentClient.query(params).promise();
    res = res.concat(data.Items);
    lasyKey = data.LastEvaluatedKey;
  } while (data.LastEvaluatedKey);

  return res;
};
