import React, { useState, useEffect } from "react";
import RoomPage from "./RoomPage";
import BedPage from "./BedPage";
import { connect } from "react-redux";
// import documentClient from "../../shared/awsAPIs/dynamoDb";
import _ from "lodash";

import { bindActionCreators } from "redux";
import * as spaRoomActions from "../../shared/actions/spaRoomsActions";

function SpaRoomAndBed(props) {
  const [selectedRoom, setSelectedRoom] = useState("");
  const [roomObj, setRoomObj] = useState({});
  const { actions, rooms } = props;
  const { fetchSpaRooms } = actions;

  useEffect(() => {
    let tmpRoomObj = {};
    _.forEach(props.rooms, room => {
      tmpRoomObj[room.roomUid] = room;
    });
    setRoomObj(tmpRoomObj);
  }, [props.rooms]);

  useEffect(() => {
    fetchSpaRooms();
  }, []);
  useEffect(() => {
    //RESET SELECTED ROOM
    if (!roomObj[selectedRoom]) {
      setSelectedRoom("");
    }
  }, [roomObj]);

  return [
    <RoomPage
      selectedRoom={selectedRoom}
      setSelectedRoom={setSelectedRoom}
      {...props}
      rooms={rooms}
      fetchRooms={fetchSpaRooms}
    />,
    selectedRoom !== "" && (
      <BedPage
        selectedRoomData={roomObj[selectedRoom] || {}}
        fetchRooms={fetchSpaRooms}
        {...props}
      />
    )
  ];
}

const mapStateToProps = state => {
  return {
    shop: state.shop.detail,
    rooms: state.spa.rooms
    // linked: state.shop.linked.inventory
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...spaRoomActions }, dispatch);
  return {
    actions
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpaRoomAndBed);
