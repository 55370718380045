import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as linkedSelector from "../shared/selector/linkedSelector";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import pkg from "../../package.json";
// const isLocalhost =
//   _.includes(window.location.href, "localhost") ||
//   _.includes(window.location.href, "192.");

class LeftSideBar extends Component {
  render() {
    let { isLinked, location, history } = this.props;
    const pathname = location.pathname;
    // isLinked === false ?
    return (
      <div className="be-left-sidebar">
        <div className="left-sidebar-wrapper">
          <a className="left-sidebar-toggle" href="#">
            Dashboard
          </a>
          <div className="left-sidebar-spacer">
            <div className="left-sidebar-scroll ps ps--active-y">
              <div className="left-sidebar-content">
                {isLinked === false ? (
                  <HqTabs pathname={pathname} />
                ) : (
                  <LocalTabs pathname={pathname} />
                )}
                {/* </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const HqTabs = ({ pathname }) => {
  return (
    <div>
      <ul className="sidebar-elements">
        <li className="divider">General</li>
        {/* <Tab
          title="Report"
          path="/reports/spa"
          pathname={pathname}
          icon="mdi mdi-book"
        /> */}
        <Tab
          title="Report 2020"
          path="/reports3/spa"
          pathname={pathname}
          icon="mdi mdi-book"
        />
        <Tab
          title="Booking"
          path="/hqBooking"
          pathname={pathname}
          icon="mdi mdi-book"
        />
        <li className="divider">Management</li>
        <Tab
          title="Menu-Items"
          path="/inventory"
          pathname={pathname}
          icon="mdi mdi-collection-item"
        />
        <Tab
          title="Members"
          path="/member"
          pathname={pathname}
          icon="mdi mdi-accounts-outline"
        />
        <Tab
          title="Members Retail"
          path="/memberRetail"
          pathname={pathname}
          icon="mdi mdi-accounts-outline"
        />
        <Tab
          title="Voucher"
          path="/voucher"
          pathname={pathname}
          icon="mdi mdi-accounts-outline"
        />
        {/* <Tab
          title="Stock"
          path="/manageStock"
          pathname={pathname}
          icon="mdi mdi-dropbox"
        /> */}
        {/* <Tab
          title="Promotions"
          path="/promotion_retail"
          pathname={pathname}
          icon="mdi mdi-cake"
        /> */}
        <Tab
          title="Branch"
          path="/manageShop"
          pathname={pathname}
          icon="mdi mdi-store"
        />
        <Tab
          title="Entertain/Agent/TH"
          path="/setting/spa_employee"
          pathname={pathname}
          icon="mdi mdi-run"
        />
        <Tab
          title="Employee PIN"
          path="/setting/employee"
          pathname={pathname}
          icon="mdi mdi-account-circle"
        />
        <Tab
          title="Account"
          path="/setting/manageUser"
          pathname={pathname}
          icon="mdi mdi-accounts-list"
        />
        <li className="divider">Setting</li>
        <Tab
          title="Setting"
          path="/setting"
          pathname={pathname}
          icon="mdi mdi-settings"
        />
      </ul>
    </div>
  );
};

const LocalTabs = ({ pathname }) => {
  return (
    <div>
      <ul className="sidebar-elements">
        <li className="divider">Divana Retail</li>
        <Tab
          title="POS"
          path="/pos"
          pathname={pathname}
          // icon="mdi mdi-play"
          icon="mdi mdi-money-box"
        />
        {/* <Tab
          title="Report"
          path="/reports/spa"
          pathname={pathname}
          icon="mdi mdi-book"
        /> */}

        {/* <Tab
          title="Report 2019"
          path="/reports2/spa"
          pathname={pathname}
          icon="mdi mdi-book"
        /> */}

        <Tab
          title="Report 2020"
          path="/reports3/spa"
          pathname={pathname}
          icon="mdi mdi-book"
        />

        <li className="divider">Management</li>
        <Tab
          title="Members"
          path="/member"
          pathname={pathname}
          icon="mdi mdi-accounts-outline"
        />
        <Tab
          title="Members Retail"
          path="/memberRetail"
          pathname={pathname}
          icon="mdi mdi-accounts-outline"
        />
        <Tab
          title="Room & Bed"
          path="/setting/spa_room"
          pathname={pathname}
          icon="mdi mdi-grid"
        />
        <Tab
          title="Employee PIN"
          path="/setting/employee"
          pathname={pathname}
          icon="mdi mdi-account-circle"
        />
        <Tab
          title="Account"
          path="/setting/manageUser"
          pathname={pathname}
          icon="mdi mdi-accounts-list"
        />
        <Tab
          title="Voucher"
          path="/voucher"
          pathname={pathname}
          icon="mdi mdi-accounts-outline"
        />
        {/* <li className="divider">Setting</li>
        <Tab
          title="Setting"
          path="/setting"
          pathname={pathname}
          icon="mdi mdi-settings"
        /> */}

        <li className="divider" style={{ fontSize: "0.75rem" }}>
          app_version : {pkg.version}
          {/* app_version : 21-11-2019 */}
        </li>
      </ul>
    </div>
  );
};

const Tab = ({ pathname, title, path, icon }) => {
  return (
    <li className={pathname === path ? "active" : ""}>
      <Link to={path}>
        <i className={`icon mdi ${icon || "mdi mdi-crop-3-2"}`} />
        <span>{title}</span>
      </Link>
    </li>
  );
};

const mapStateToProps = (state) => {
  return {
    isLinked: linkedSelector.isLinkedSelector(state),
  };
};

export default withRouter(connect(mapStateToProps)(LeftSideBar));
