import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import useFetchReceipt from "./useFetchReceipt";
import SummaryTable from "./SummaryTable";
import SummaryPaymentType from "./SummaryPaymentType";
import SummaryChart from "./SummaryChart";
import SummaryMonthPicker from "./SummaryMonthPicker";
import ReceiptSummaryView from "../../shared/components/ReceiptSummaryView";

function Summary(props) {
  const [month, setMonth] = useState(moment().format("YYYY-MM"));
  const [sumGrandTotal, setSumGrandTotal] = useState(0);
  const { receipts, fetchReceipts } = useFetchReceipt(props.user.shopId);
  const [computedReceipts, setComputedReceipts] = useState([]);
  const [sumPaymentType, setSumPaymentType] = useState({});
  // const [branch, setBranch] = useState(null);
  const [tab, setTab] = useState(1);

  const { branch } = props;

  useEffect(() => {
    computeBills();
  }, [receipts]);

  const computeBills = () => {
    let tmpSumGrandTotal = 0;
    let sumBills = 0;
    // let sumGuest = 0;
    let summary = {};

    let datesArr = getDateListDesc(month);
    let datesObj = {};

    _.forEach(datesArr, date => {
      //make 30 days
      datesObj[date] = {
        timestamp: date
      };
    });

    let paymentTypeObj = {};

    _.forEach(receipts, bill => {
      let currentTime = moment(bill.timestamp).format("YYYY-MM-DD");
      //   i["avgBills"] = i.grandTotal / i.bills; //TODO: compute avg/bill
      if (!summary[currentTime]) {
        summary[currentTime] = {
          grandTotal: 0,
          timestamp: currentTime
        };
      }
      summary[currentTime].grandTotal += bill.payment.grandTotal;
      //   summary[currentTime] = i;
      //-------------------------
      tmpSumGrandTotal += bill.payment.grandTotal;
      sumBills++;

      //Form Payment Type Object
      _.forEach(bill.payment.paymentTypes, line => {
        if (!paymentTypeObj[line.name]) {
          paymentTypeObj[line.name] = 0;
        }
        paymentTypeObj[line.name] += line.amount;
      });
    });

    summary = { ...datesObj, ...summary }; //put exist data and blank data
    summary = _.orderBy(summary, ["timestamp"], ["desc"]);
    setComputedReceipts(summary);
    setSumGrandTotal(tmpSumGrandTotal);
    setSumPaymentType(paymentTypeObj);
  };

  const getDateListDesc = month => {
    const startOfMonth = moment(month).startOf("month");
    const endOfMonth = moment(month).endOf("month");

    let diff = endOfMonth.diff(startOfMonth, "days") + 1;
    if (month === moment().format("YYYY-MM")) {
      //ถ้าเดือนปัจจุบัน เอาแค่ถึงวันที่ปัจจุบัน
      diff = moment().diff(startOfMonth, "days") + 1;
    }

    let datesOfMonth = [];

    _.times(diff, index => {
      datesOfMonth.push(
        moment(month)
          .startOf("month")
          .add(index, "days")
          .format("YYYY-MM-DD")
      );
    });

    return datesOfMonth;
  };

  const getMonthPicker = mode => {
    return (
      <div>
        <small style={{ color: "grey" }}>Select Month</small>
        <input
          style={{ width: 200 }}
          className="form-control"
          type="month"
          value={month}
          onChange={e => handleSetMonth(e)}
        />
      </div>
    );
  };

  const getCurrentBranch = () => {
    if (branch && branch.value === "all") {
      return props.user.shopId;
    }
    if (branch) {
      return branch.value;
    } else {
      return props.user.shopId;
    }
  };

  const handleSetMonth = e => {
    fetchReceipts(getCurrentBranch(), {
      timestamp: e.target.value,
      hqUid: branch && branch.value === "all" ? props.user.shopId : null
    });
    setMonth(e.target.value);
  };

  useEffect(() => {
    branch && handleChangeBranch(branch);
  }, [branch]);

  const handleChangeBranch = () => {
    // setBranch(branch);
    fetchReceipts(branch.value, {
      timestamp: month,
      hqUid: branch.value === "all" ? props.user.shopId : null
    });
  };

  return (
    <div className="p-3">
      {/* <div> */}
      <Tabs tab={tab} setTab={setTab} />
      <div className="row">
        <div className="col-8">
          <SummaryMonthPicker
            getMonthPicker={getMonthPicker}
            sumGrandTotal={sumGrandTotal}
          />
          {tab === 0 && <SummaryTable computedReceipts={computedReceipts} />}
          {tab === 1 && (
            <SummaryChart
              {...props}
              getDateListDesc={getDateListDesc}
              computedReceipts={computedReceipts}
              month={month}
            />
          )}
        </div>
        {/* receipts */}
        <div className="col-4">
          <ReceiptSummaryView receipts={receipts} />
          <SummaryPaymentType sumPaymentType={sumPaymentType} />
        </div>
      </div>
    </div>
  );
}

export default Summary;

const Tabs = ({ tab, setTab }) => {
  return (
    <div className="pt-3">
      <button
        style={{ width: 100 }}
        onClick={() => setTab(1)}
        class={`btn btn-space btn-${tab === 1 ? "primary" : "secondary"}`}
      >
        Chart
      </button>

      <button
        style={{ width: 100 }}
        class={`btn btn-space btn-${tab === 0 ? "primary" : "secondary"} pl-2`}
        onClick={() => setTab(0)}
      >
        Table
      </button>
    </div>
  );
};
