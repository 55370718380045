export let initState = {
  startDate: new Date(), //use this for singel
  endDate: new Date(),
  month: new Date(),

  fillter: [], //
  modal: {
    data: null,
    visible: false
  },

  title: "",
  dateMode: "single", // range
  showButtons: [],

  webPath: null // '/receipts'
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return {
        ...state,
        [action.key]: action.payload
      };
    case "SET_MODAL":
      return { ...state, modal: action.payload };
    default:
      return state;
  }
};
