import * as type from "../../types/shop";

const reducer = (
  state = {
    inventory: null,
    setting: null
  },
  action
) => {
  switch (action.type) {
    case type.SET_LINK_INVENTORY:
      return { ...state, inventory: action.payload };
    case type.SET_LINK_SETTING:
      return { ...state, setting: action.payload };
    default:
      return state;
  }
};

export default reducer;
