import _ from "lodash";
import documentClient from "../awsAPIs/dynamoDb";
import * as type from "../types/spa.sales";

export const fetchSpaSales = () => {
  return async (dispatch, getState) => {
    let { shop } = getState();

    //LOCAL OR HQ
    let shopUid = shop.linked.inventory || shop.detail.uid;

    let params = {
      TableName: "Spa_Agents",
      KeyConditionExpression: "entityUid = :entityUid",
      ExpressionAttributeValues: {
        ":entityUid": shopUid
      }
    };

    let params3 = _.cloneDeep(params);
    params3.TableName = "Spa_Entertains";
    let params4 = _.cloneDeep(params);
    params4.TableName = "Spa_Sales";

    let params2 = _.cloneDeep(params);
    params2.TableName = "Spa_Employees";
    params2 = {
      ...params2,
      FilterExpression: "contains(#flag, :flag)",
      ExpressionAttributeNames: {
        "#flag": "flag"
      },
      ExpressionAttributeValues: {
        ":entityUid": shopUid,
        ":flag": "2020"
      }
    };

    try {
      let resAgent = await documentClient.query(params).promise();
      let resEmp = await documentClient.query(params2).promise();
      let resEntertain = await documentClient.query(params3).promise();
      let resSales = await documentClient.query(params4).promise();

      dispatch({
        type: type.FETCH_SPA_SALES,
        payload: {
          employees: orderByRow(resEmp.Items),
          agents: orderByRow(resAgent.Items),
          entertains: orderByRow(resEntertain.Items),
          sales: orderByRow(resSales.Items)
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const orderByRow = arr => {
  return _.orderBy(arr, "row", "asc");
};
