const getVatName = mode => {
  switch (mode) {
    case 0:
      return "None";
    case 1:
      return "Inclusive";

    case 2:
      return "Exclusive";
  }
};

export default getVatName;
