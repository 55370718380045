import React, { useState } from "react";
import PosCartShoppingCartItemList from "./ShoppingCartItemList";
import PosCartShoppingCartPromotionList from "./ShoppingCartPromotion";
import NextButton from "./NextButton";
import { connect } from "react-redux";
import _ from "lodash";
import * as posCartActions from "./actions";
import { promotionItems } from "./selector";

function CartItemList(props) {
  const [isGrandTotalHover, setIsGrandTotalHover] = useState(false);

  return (
    <div className="card card-flat">
      <div className="card-header">Items</div>
      <div className="card-body">
        <PosCartShoppingCartItemList {...props} />
        <PosCartShoppingCartPromotionList {...props} />
        <NextButton
          isGrandTotalHover={isGrandTotalHover}
          onClick={props.next}
          setIsGrandTotalHover={setIsGrandTotalHover}
          disableOnClickNext={props.disableOnClickNext}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    items: state.items,
    qtyHolder: state.qtyHolder,
    promotionItems: promotionItems(state) 
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    removeLine: (index, uid) => {
      dispatch(posCartActions.removeItemLine(index, uid));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartItemList);
