
import axios from "axios";
import { sqs } from "../awsAPIs/instance";


const EXCEL_INVOICE_URL ='https://us-central1-minor-hackathon.cloudfunctions.net/DQInvoiceReceipt'
const PDF_INVOICE_URL = 'https://us-central1-minor-hackathon.cloudfunctions.net/DQInvoiceReceiptPDF'
export const  exportInvoiceExcel = (invoice)=>{
    return axios.post(EXCEL_INVOICE_URL, invoice)
}

export const exportInvoicePdf = (invoice)=>{
    return axios.post(PDF_INVOICE_URL, invoice)
    
}


export const exportSendEmail = async (emailList , invoice) =>{
    invoice.emailList = emailList.split(',')
    var params = {
        MessageBody: JSON.stringify(invoice), /* required */
        QueueUrl: 'https://sqs.ap-southeast-1.amazonaws.com/708553455326/storeman-send-email-invoice', /* required */
      };

      await sqs.sendMessage(params).promise()
}   