import React, { useState } from "react";
import _ from "lodash";

function PosCartShoppingCartPromotionList(props) {
  const { promotionItems = [] } = props; 
  return (
    <div className="row">
      <div className="col-lg-12">
        {promotionItems.length >= 1 && (
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th style={{ width: "5%" }}>Qty</th>

                <th style={{ width: "55%" }}>Promotion Discount</th>
                <th className="number" style={{ width: "15%" }}>
                  Price
                </th>
                <th className="number" style={{ width: "15%" }}>
                  Total
                </th>

                <th className="actions" style={{ width: "10%" }} />
              </tr>
            </thead>
            <tbody>
              {_.map(promotionItems, line => {
                return (
                  <tr>
                    <td className=" " style={{ width: 48 }}>
                      <b>{line.qty}</b>
                    </td>

                    <td>{line.name}</td>
                    <td className="number">{line.price}</td>
                    <td className="number">{line.qty * line.price}</td> 

                    <td className="actions">
                      {/* <a className="icon" href="#">
                        <i className="mdi mdi-settings" />
                      </a> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default PosCartShoppingCartPromotionList;
