import React from 'react'
// import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as Amplify from '../../shared/awsAPIs/Amplify'
import swal from 'sweetalert'
import * as userActions from '../../shared/actions/userActions'
import { bindActionCreators } from 'redux'
import CreateUserModal from './CreateAccountModal'
import moment from 'moment'
import Select from 'react-select';
import documentClient from '../../shared/awsAPIs/dynamoDb'
import * as branchOptionsSelector from '../../shared/selector/branchOptionsSelector'
import * as linkedSelector from "../../shared/selector/linkedSelector";
 

class UserList extends React.Component {
    state = {
        selectedUser: {},
        timestamp: moment().toISOString(),
        userList: [],
        // branchOptions: [],
        selectedBranch: {}
    }

    componentDidMount = () => {
        const { hqShop } = this.props
        this.setFirstOption()
        this.fetchUserList(hqShop.uid)
    }

    setFirstOption = () => {
        const { hqShop } = this.props
        let hqOption = { value: hqShop.uid, label: hqShop.name + ` (${hqShop.branchId})` } //HQ OPTION

        this.setState({
            selectedBranch: hqOption
        })
    }

    fetchUserList = (shopId) => {
        let params = {
            TableName: "Minor_Users",
            IndexName: 'shopId-index',
            KeyConditionExpression: "shopId = :shopId",
            ExpressionAttributeValues: {
                ":shopId": shopId
            }
        };

        documentClient.query(params, (err, data) => {
            if (err) {
                alert(err)
            } else {
                let sorted = (data && _.sortBy(data.Items, i => i.username)) || []
                this.setState({
                    userList: sorted
                })
            }
        });
    }

    deleteUser = (username) => {
        const { actions } = this.props
        const { selectedBranch } = this.state
        swal({
            title: `คุณต้องการลบ ${username} ?`,
            text: `User ${username} will be deleted`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Amplify.deleteUser(username, () => {
                        actions.deleteUser(username, () => {
                            this.fetchUserList(selectedBranch.value)
                        })
                    })
                }
            });
    }
    getActions = (user) => {
        const { loggedIn } = this.props
        const { userList } = this.state
        // if (username === loggedIn.username) {
        //     return <td><span style={{ color: 'blue' }}>Currently logged in</span></td>
        // }
        // else 
        return (<td>
            <a href="#"
                data-toggle="modal" data-target="#createUserModal"
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    this.selectUser(user)
                }}
                style={{ color: 'orange', fontSize: 20 }}>
                <span className="mdi mdi-edit"></span>
            </a>
            {
                user.username !== loggedIn.username &&
                <a href="#"
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        this.deleteUser(user.username)
                    }}
                    style={{ color: 'red', fontSize: 20, paddingLeft: 12 }}>
                    <i className="icon mdi mdi-close-circle"></i>
                </a>
            }
        </td>)

    }

    handleChangeBranch = (selectedBranch) => {
        this.fetchUserList(selectedBranch.value)

        this.setState({ selectedBranch })
    }

    selectUser = (user) => { //FOR EDIT OR CREATE USER
        this.setState({
            selectedUser: user,
            timestamp: moment().toISOString()
        })
    }

    render() {
        const { selectedUser, timestamp, selectedBranch, userList } = this.state
        const { branchOptions, isLinked } = this.props

        return (
            <div>
                <CreateUserModal isEdit={!_.isEmpty(selectedUser)}
                    timestamp={timestamp}
                    selectedUser={selectedUser}
                    selectedBranch={selectedBranch}
                    fetchUserList={() => this.fetchUserList(selectedBranch.value)}

                />
                <div className="col-lg-8 offset-lg-2 col-md-12 " style={{ padding: 0 }}>
                    {isLinked === false &&
                        <div className="row m-0 mb-1">
                            <div className="col-md-4 p-0">
                                <Select
                                    value={selectedBranch}
                                    onChange={this.handleChangeBranch}
                                    options={branchOptions}
                                />
                            </div>
                        </div>
                    }
                    <div className="card card-table">
                        <div className="card-header">Manage User
                        <div className="tools dropdown"> <a style={{ color: 'blue' }}
                                href="#"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    this.selectUser({}) //FOR CREATE NEW USER
                                }}
                                className="icon button mdi mdi-account-add"
                                data-toggle="modal" data-target="#createUserModal"
                            ></a>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive noSwipe">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Username</th>
                                            <th>ชื่อจริง</th>
                                            <th>นามสกุล</th>
                                            {/* <th>E-mail</th> */}
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.map(userList, user => {
                                                return (
                                                    <tr key={user.username}>
                                                        <td><span>{user.username}</span></td>
                                                        <td><span>{user.firstName}</span></td>
                                                        <td><span>{user.lastName}</span></td>
                                                        {/* <td><span>{user.email}</span></td> */}
                                                        {this.getActions(user)}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // userList: state.user.userList,
        loggedIn: state.user.loggedIn,
        shopList: state.shop.list.inventory,
        hqShop: state.shop.detail,
        branchOptions: branchOptionsSelector.branchOptionsWithHqSelector(state),
        isLinked: linkedSelector.isLinkedSelector(state)

    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ ...userActions }, dispatch)
    return {
        actions,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList)