import React, { useState, useEffect } from "react";
import ManageCustomer from "../../pages/SpaPos/ManageCustomer/index";

function ManageCustomerModal(props) {
  const [visible, setVisible] = useState(true);

  // useEffect(() => {
  //   // if (props.initData) return;
  //   setVisible(false);
  //   setTimeout(() => {
  //     setVisible(true);
  //   }, 200);
  // }, [props.timestamp]);

  return (
    <div
      class="modal"
      id="manageCustomerModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="manageCustomerModalLabel"
      aria-hidden="true"
      //   style={{ visibility: visible ? "visible" : "hidden" }}
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="manageCustomerModalLabel">
              {props.title}
            </h3>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ManageCustomer 
              {...props} 
              closeModal={() => {
                window.$("#manageCustomerModal").modal("hide");
              }}
            />
            {/* {visible ? (
              <ManageCustomer
                {...props}
                closeModal={() => {
                  window.$("#manageCustomerModal").modal("hide");
                }}
              />
            ) : (
              <h2>Loading...</h2>
            )} */}
          </div>
          {/* <div class="modal-footer pt-2">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary">
              Save changes
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ManageCustomerModal;
