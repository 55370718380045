import React, { useState } from "react";
import { connect } from "react-redux";
import { subTotalAmountSelector } from "./selector";
import * as computeSelector from "./selector";
import * as actions from "./actions";
import _ from "lodash";
import Option2_Container from "./SummaryOptionsContainer";
import * as settingSelector from "../../shared/selector/settingSelector";
import combineJobItemsToBillItems from "../../shared/functions/combineJobItemsToBillItems";
import swal from "sweetalert";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import * as spaSalesSelector from "../../shared/selector/spaSalesSelector";

const grandTotalStyle = {
  border: "1px solid rgba(0,0,0,0.125)",
  borderRadius: 8,

  cursor: "pointer"
};

const grandTotal_hoder_Style = {
  border: "1px solid rgba(0,0,0,0.125)",
  cursor: "pointer",
  color: "white",
  borderRadius: 8,

  backgroundColor: "#4285f4"
};

function PosCartSummary(props) {
  const {
    subTotal,
    vat,
    rounding,
    grandTotal,
    options,
    promotions,
    showSubmitButton = true,
    setModalVisible,
    dispatch,
    spaPos = {},
    isNoStartTime,
    match,
    items,
    voucherDiscount
  } = props;

  const [isGrandTotalHover, setIsGrandTotalHover] = useState(false);

  const printPreview = () => {
    let cartStoreData = props.getCartStoreData();
    let payment = _.cloneDeep(cartStoreData);
    delete payment["items"];
    delete payment["promotionItems"];
    delete payment["qtyHolder"];

    payment = {
      ...payment
      // received: amount,
      // change: getChange().int,
      // paymentTypes: computePaymentType(
      //   payments,
      //   getGrandTotal(),
      //   getChange().int
      // )
    };

    let data = {
      ...data,
      items: combineJobItemsToBillItems(
        cartStoreData.items,
        cartStoreData.promotionItems
      ),
      payment
    };
    updatePreviewStatus();
    dispatch(actions.preview(() => {}, data, { printPreview: true }));
  };

  const updatePreviewStatus = () => {
    let { jobUid, storeUid } = match.params;
    if (!jobUid) return;
    var params = {
      TableName: "Spa_Jobs",
      Key: { jobUid, storeUid },
      UpdateExpression: "set isPrintedPreview = :value",
      ExpressionAttributeValues: {
        ":value": true
      }
    };
    documentClient.update(params, (err, res) => {
      if (err) {
        console.log(err);
      }
    });
  };

  let isCourse = spaPos.meta && spaPos.meta.room; //ROOM EXIST

  let revertBill = props.spaPosSetting.revertBill;

  const clickPayment = () => {
    if (_.isNaN(grandTotal)) {
      return swal(
        "GrandTotal ไม่ถูกต้อง, กรุณา ตรวจสอบสินค้าอีกครั้ง ",
        "",
        "error"
      );
    }
    if (items.length === 0) {
      return swal("ยังไม่ได้ทำการเลือกสินค้า", "", "error");
    }

    if (isCourse && isNoStartTime) {
      return swal("กรุณากรอกเวลาเริ่ม", "", "error");
    }

    //VALIADATE MEMBER
    if (isCourse && _.isEmpty(spaPos.member)) {
      return swal("กรุณากรอกข้อมูล Member", "", "error");
    }

    if (
      isCourse &&
      spaPos.member.type === "temporary" &&
      spaPos.member.name === ""
    ) {
      return swal("กรุณากรอกข้อมูล Member", "", "error");
    }

    if (
      isCourse &&
      spaPos.member.type === "temporary" &&
      !spaPos.member.country
    ) {
      return swal("กรุณาระบุประเทศของ Member", "", "error");
    }

    //VALIADATE THERAPIST
    let noTher = false;
    if (
      isCourse &&
      (!spaPos.therapistList || _.isEmpty(spaPos.therapistList)) &&
      !spaPos.therapist
    ) {
      noTher = true;
    }

    if (noTher) {
      return swal("กรุณาเลือก Therapist", "", "error");
    }

    // swal({
    //   title: `No Therapist !`,
    //   text: "ต้องการดำเนินการต่อโดยไม่มี Therapist ?",
    //   icon: "warning",
    //   dangerMode: true,
    //   buttons: true
    // }).then(willDelete => {
    //   if (willDelete) {
    //     setModalVisible(true);
    //   }
    // });

    setModalVisible(true);
  };

  return (
    <div>
      <div className="card card-flat">
        <div className="card-header card-header ">
          {(subTotal || 0).toLocaleString()}
          <span className="card-subtitle">Items Price</span>
        </div>
        <div className="card-header card-header pt-0">
          {promotions}
          <span className="card-subtitle">Promotion Discount</span>
        </div>
        {/* VOUCHER */}
        {voucherDiscount < 0 && (
          <div className="card-header card-header pt-0">
            {voucherDiscount}
            <span className="card-subtitle">Voucher Discount</span>
          </div>
        )}

        <Option2_Container {...props} />

        <div className="card-header card-header pt-0">
          {vat}
          <span className="card-subtitle">Vat (Included)</span>
        </div>

        <div className="card-header card-header pt-0">
          {rounding}
          <span className="card-subtitle">Rounding</span>
        </div>
        {showSubmitButton && (
          <div
            // onDoubleClick={() => props.doPayment()}
            onMouseEnter={() => setIsGrandTotalHover(true)}
            onMouseLeave={() => setIsGrandTotalHover(false)}
            // data-toggle="modal"
            // data-target="#spaPaymentModal"
            onClick={clickPayment}
            className="card-header text-center card-header p-1"
            style={isGrandTotalHover ? grandTotal_hoder_Style : grandTotalStyle}
          >
            <b>{(grandTotal || 0).toLocaleString()}</b>
            <span
              className="card-subtitle"
              style={isGrandTotalHover ? { color: "white" } : {}}
            >
              GrandTotal
            </span>
          </div>
        )}
        {isCourse && (
          <div className="row m-0 pt-2">
            <div className="col-6 pl-4 pr-1">
              <div
                onClick={() => {
                  if (revertBill) {
                    swal(
                      "ไม่สามารถบันทึกงานได้เนื่องจากอยู่ในโหมดบันทึกบิลย้อนหลัง",
                      "",
                      "error"
                    );
                    return;
                  }
                  if (isCourse && isNoStartTime) {
                    swal("กรุณากรอกเวลาเริ่ม", "", "error");
                    return;
                  }
                  props.save && props.save();
                }}
                className="card-header text-center card-header m-0 pl-1"
                style={{
                  ...grandTotalStyle,
                  backgroundColor: revertBill !== true ? "white" : "lightgrey"
                }}
              >
                <span className="card-subtitle">Save</span>
              </div>
            </div>

            <div className={`col-6 pr-4 pl-1`}>
              <div
                className="card-header text-center card-header m-0 pr-1"
                style={grandTotalStyle}
                onClick={() => printPreview()}
              >
                <span className="card-subtitle">Preview</span>
              </div>
            </div>
          </div>
        )}

        {/* <div className="card-body" /> */}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  let cantAddOption = state.items && state.items.length > 0 ? false : true;
  let paymentOptions = [];
  let mainAppState = null;
  let entertainOptions = [];
  let spaPos = {};

  if (state.mainAppStore) {
    mainAppState = state.mainAppStore.getState();
    paymentOptions = settingSelector.optionsSelector(mainAppState);
    entertainOptions = spaSalesSelector.entertainOptionsSelector(mainAppState);
    spaPos = mainAppState.spa.pos;
  }

  return {
    subTotal: subTotalAmountSelector(state),
    options: computeSelector.optionsAmountSelector(state),
    promotions: computeSelector.promotionItemsAmountSelector(state),
    vat: computeSelector.vatAmountSelector(state),
    grandTotal: computeSelector.grandTotalSelector(state),
    rounding: computeSelector.roundingAmountSelector(state),
    cantAddOption,
    paymentOptions,
    items: state.items || [],
    // optionList: paymentOptions
    optionList: state.options,
    entertainOptions,
    spaPos,
    mainAppState,
    products: combineJobItemsToBillItems(
      state.items,
      state.promotionItems,
      state.qtyHolder,
      state.discountHolder
    ),
    voucherDiscount: computeSelector.voucherDiscountSelector(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addNewOptionLine: index => {
      dispatch(actions.optionsInsert());
    },

    editOptionLine(index, name, value, sign) {
      dispatch(actions.optionsEdit(index, name, value, sign));
    },

    removeOptionLine(index) {
      dispatch(actions.optionsRemove(index));
    },

    saveOptions(data) {
      dispatch(actions.optionsSave(data));
    },

    tmpPayment() {
      dispatch(actions.payment()); //
    },

    dispatch,
    doPayment() {
      dispatch(actions.payment());
      dispatch(actions.clear());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PosCartSummary);
