// dont nver use this.

import React, { useState, useEffect } from "react";
import _ from "lodash";
import * as utilRooms from "../../shared/utils/tmp";
import useFetchJobs from "./useFetchJobs";
import moment from "moment";
import { withRouter } from "react-router-dom";
// actions.restore({
//   place: {
//     zone: { label: tx.label, value: tx.value },
//     room,
//     roomUid: room.roomUid
//   }
// });
// setTimeout(() => history.push(`/spa_pos`), 100);

const zones = utilRooms.genRooms();

function Booking({ actions, history }) {
  const [count, setCount] = useState(0);
  const [startHH, setStartHH] = useState(8);
  const [endHH, setEndHH] = useState(21);


  const { jobs } = useFetchJobs("3180");

  const [rooms_computed, set_rooms_comuted] = useState([]);
  useEffect(() => {
    let res = computeReserved(jobs);
    set_rooms_comuted(res);
  }, [jobs]);

  function onClickSlot(startDate, job, room, zone) {
    if (job) {
      history.push(`/spa_pos/3180/${job.jobUid}`);
    } else {
      // return alert(startDate)
      actions.restore({
        place: {
          zone: { label: zone.label, value: zone.value },
          room,
          roomUid: room.roomUid,
          startDate : startDate
        }
      });
      setTimeout(() => history.push(`/spa_pos`), 100);
    }
  }

  return (
    <div>
      <TimeHourHeader startHH={0} endHH={12} />
      <p className="">Time</p>

      {_.reduce(
        rooms_computed,
        (acc, z) => {
          _.forEach(z.list, room => {
            acc.push(
              <RoomLine
                slots={room.slots}
                room={room}
                zone={z}
                onClick={onClickSlot}
              />
            );
          });

          return acc;
        },
        []
      )}
    </div>
  );
}

export default withRouter(Booking);

function TimeHourHeader({ startHH, EndHH }) {
  return (
    <div className="row " style={{ borderBottom: "1px solid gray", margin: 0 }}>
      <div className="col-2"> </div>
      <div className="col-10">
        <div className="grid-container hh">
          {_.times(12, i => {
            return <div className="hh">{i + startHH}</div>;
          })}
        </div>
      </div>
    </div>
  );
}

function RoomLine({ zone, room, slots, onClick }) {
  return (
    <div className="row" style={{ borderBottom: "1px solid gray", margin: 0 }}>
      <div className="col-2">
        <h4>
          {zone.name} - {room.name}
        </h4>{" "}
      </div>
      <div className="col-10 ">
        <div className="grid-container">
          {_.map(slots, s => {
            if (s.job) {
              return (
                <ReservedSlot
                  zone={zone}
                  room={room}
                  {...s}
                  onClick={onClick}
                />
              );
            } else {
              return (
                <EmptySlot zone={zone} room={room} {...s} onClick={onClick} />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

function ReservedSlot({
  startSlot,
  endSlot,
  startDate,
  endDate,
  job,
  onClick,
  room,
  zone
}) {
  return (
    <div
      className="active"
      style={{
        gridColumnStart: startSlot,
        gridColumnEnd: endSlot
      }}
      onClick={() => onClick(startDate, job, room, zone)}
    >
      <span style={{ display: "block" }}>{job.customer.name}</span>

      <span
        style={{ display: "block", fontSize: "0.9em", fontStyle: "italic" }}
      >
        {moment(startDate).format("HH:mm")} - {moment(endDate).format("HH:mm")}
      </span>
    </div>
  );
}

function EmptySlot({
  startSlot,
  endSlot,
  startDate,
  endDate,
  onClick,
  room,
  zone
}) {
  return (
    <div
      className=""
      style={{
        gridColumnStart: startSlot,
        gridColumnEnd: endSlot,
        backgroundColor: "inherit"
      }}
      onClick={() => onClick(startDate, null, room, zone)}
    >
      {/* <span style={{}}>{startSlot} - {endSlot}</span> */}
    </div>
  );
}

function computeReserved(jobs) {
  var roomsHolder = {};
  _.forEach(jobs, j => {
    let { roomUid, startDate, endDate, startSlot, endSlot } = j.place;
    if (!roomUid) {
      return;
    }

    if (roomsHolder[roomUid] === undefined) {
      roomsHolder[roomUid] = {};
    }

    let key = moment(startDate).format("HH:mm");
    roomsHolder[roomUid][key] = {
      startDate,
      endDate,
      roomUid,
      job: j,
      jobUid: j.jobUid
    };
  });

  let rrr = _.forEach(
    zones,
    z => {
      z.list.forEach(r => {
        // r = room

        var m = moment().startOf("day");
        r.slots = [];
        var count = 0;
        var _startLine = 1;
        _.times(12, t => {
          let k = m.format("HH:mm");

          let startTime = m.format();
          m.add(60, "minute");

          let endTime = m.format();

          var res = roomsHolder[r.roomUid] && roomsHolder[r.roomUid][k];
          var offset = 1;

          if (res) {
            var mm = moment(res.endDate).diff(
              moment(res.startDate),
              "m",
              false
            );
            offset = Math.ceil(mm / 60);
          }

          r.slots.push({
            startSlot: _startLine, //+1  start at line 1
            endSlot: _startLine + offset,
            startDate: (res && moment(res.startDate).format()) || startTime,
            endDate: (res && moment(res.endDate).format()) || endTime,
            jobUid: (res && res.jobUid) || null,
            job: (res && res.job) || null
          });

          _startLine = _startLine + offset;
        });
      });
    },
    {}
  );

  //
  // filter

  var z_cloned = [];
  _.forEach(zones, z => {
    z_cloned.push({ ...z });
    _.forEach(z_cloned.list, r => {
      r.slots = _.filter(r.slots, s => {
        // console.log(s.startDate + " : " + moment(s.startDate).get("h"));
        return moment(s.startDate).get("h") < 6;
      });
    });
  });

  console.log(z_cloned);

  return z_cloned;
}
