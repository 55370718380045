import moment from "moment";
import Paper from "niceloop-paper";
import _ from "lodash";

class PrintOpenShop {
  drawerReducer = null;
  printType = "PRINT_CUSTOM_V2";

  constructor(state, dispatch, drawerReducer) {
     
    this.state = state;
    this.dispatch = dispatch;
    this.drawerReducer = drawerReducer;
  }

  build() {
    var Order = {};

    Order.UniqueId = _.random(99999999999);

    Order.Printer = [0];
    // Order.Title1 = this.shop.detail.name;
    // Order.Title2 = "สรุปรายงานขาย"; //this.isCloseBill ? '***** Close Bill *****' : 'ใบจัดส่งสินค้า'

    Order.isPreview = true;
    Order.CustomHeadersArrays = [this.gen1()];

    Order.CustomFootersArrays = [];
    this.Order = Order;
    return this;
  }

  gen1() {
    var paper = new Paper();
    paper.title("Open Batch");
    paper.lineFeed();
    let date = moment(this.drawerReducer.startDate).format("DD/MM/YYYY");
    let time = moment(this.drawerReducer.startDate).format("HH:mm:ss");

    paper.keyValue(`เปิดร้าน `, ` ${date}`);
    paper.keyValue("เวลา", time);
    paper.lineFeed();

    let initMoney = this.drawerReducer.cashInit;
    let userName = this.state.employee.current.name;

    paper.keyValue("ยอดเงินเริ่มต้น", initMoney);
    paper.keyValue("User", userName);
    paper.lineFeed();
    paper.lineFeed();

    paper.comment("Printed at :   " + moment().format("DD/MM/YYYY HH:mm:ss"));
    return paper.gen();
  }

  print() {
    this.dispatch({
      type: "PRINT",
      meta: {
        printType: this.printType,
        type: "PRINT"
      },
      payload: this.Order
    });
  }
}

export default PrintOpenShop;
