import React, { useState, useEffect } from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle
} from "react-sortable-hoc";
import _ from "lodash";
import swal from "sweetalert";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import randomUid from "../../shared/randomUid";
import moment from "moment";

const DragHandle = SortableHandle(() => (
  <i className="icon icon-left mdi mdi-sort-amount-desc" />
)); // This can be any component you want

function BedPage(props) {
  const { fetchRooms, selectedRoomData } = props;

  const createBed = () => {
    swal({
      title: "Create Bed",
      text: "กรอกชื่อ Bed",
      content: "input",
      buttons: ["Cancel", "Create"]
    }).then(name => {
      if (!name) throw null;
      addBed(name);
    });
  };
  const putBed = async data => {
    var params = {
      TableName: "Spa_Rooms",
      Item: {
        ...selectedRoomData,
        ...data
      }
    };
    try {
      await documentClient.put(params).promise();
      fetchRooms();
    } catch (error) {
      console.log(error);
    }
  };
  const addBed = async name => {
    let newBeds = [];
    let bedData = {
      bedUid: randomUid(6),
      name,
      row: moment().valueOf()
    };

    if (!selectedRoomData.beds) {
      newBeds = [bedData];
    } else {
      newBeds = [...selectedRoomData.beds, bedData];
    }

    putBed({ beds: newBeds });
  };
  const deleteBed = (room, index) => {
    swal({
      title: `ลบ ${room.name} ?`,
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (!willDelete) return;
      let beds = selectedRoomData.beds;
      let newBeds = [...beds.slice(0, index), ...beds.slice(index + 1)];
      putBed({ beds: newBeds });
    });
  };
  const editBedName = async (bed, index) => {
    let params = {
      // title: ``,
      text: "เปลี่ยนชื่อ Bed",
      content: {
        element: "input",
        attributes: {
          inputValue: bed.name || ""
        }
      },
      buttons: ["Cancel", "Submit"],
      buttons: true
    };

    swal(params).then(name => {
      if (name) {
        let newBeds = _.cloneDeep(selectedRoomData.beds);
        newBeds[index].name = name;
        putBed({ beds: newBeds });
      }
    });
    setTimeout(() => {
      window.$("input.swal-content__input").val(bed.name || "");
    }, 10);
  };

  return (
    <div className="main-content container-fluid" style={{ paddingTop: 17 }}>
      <div className="row m-0">
        <div className="col-md-8 offset-md-2">
          <Row
            col1={<h3 className="m-0">Bed</h3>}
            col2={
              <button className="btn btn-space btn-primary" onClick={createBed}>
                Create
              </button>
            }
          />
          <div className="dropdown-divider" />
          <div className="mt-3" />
          <SortableComponent 
            {...props}
            items={_.orderBy(selectedRoomData.beds, "row", "ASC")}
            fetchRooms={fetchRooms}
            deleteBed={deleteBed}
            editBedName={editBedName}
            putBed={putBed}
          />
        </div>
      </div>
    </div>
  );
}

class SortableComponent extends React.Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    let newItems = arrayMove(this.props.items, oldIndex, newIndex);
    _.forEach(newItems, (data, idx) => {
      data.row = idx;
    });
    this.props.putBed({ beds: newItems });
  };
  render() {
    return (
      <SortableList
        {...this.props}
        useDragHandle={true}
        items={this.props.items}
        onSortEnd={this.onSortEnd}
      />
    );
  }
}

const SortableList = SortableContainer(props => {
  return (
    <div className="list-group d-flex">
      {_.map(props.items, (value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          index2={index}
          value={value}
          {...props}
        />
      ))}
    </div>
  );
});

const SortableItem = SortableElement(props => {
  let { pickedBedUid, value, index2 } = props;

  return (
    // active
    <span
      href={`#${value.bedUid}`}
      class={
        "list-group-item  list-group-item-action " +
        (value.bedUid === pickedBedUid ? "active" : "")
      }
      style={{
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 10,
        paddingBottom: 10
      }}
    >
      <div className="row">
        <div className="col-md-1">
          <DragHandle />
        </div>
        <div className="col-md-6">
          <span
            className="text"
            style={{ color: value.bedUid === pickedBedUid ? "white" : "" }}
          >
            {value.name}
          </span>
        </div>
        <div className="col-md-4">
          <div className="row justify-content-between">
            <div />
            <div>
              <a
                href="#"
                style={{ width: 24 }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.editBedName(value, index2);
                }}
              >
                <i className="icon mdi mdi-edit" />
              </a>
              <a
                href="#"
                style={{ width: 24 }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.deleteBed(value, index2);
                }}
              >
                <i className="icon mdi mdi-delete" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
});

const Row = ({ col1, col2 }) => {
  return (
    <div className="row m-0 mb-3 justify-content-between">
      <div className="align-self-center">{col1}</div>
      <div>{col2}</div>
    </div>
  );
};

export default BedPage;
