import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import swal from "sweetalert";
// import moment from "moment";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import * as branchOptionsSelector from "../../shared/selector/branchOptionsSelector";
import api from "../../shared/api/index";
import * as appStateAction from "../../shared/actions/appStateActions";

class ReportProductEmail extends React.Component {
  state = {
    startDate: new Date(),
    endDate: new Date(),
    selectedBranchs: null,
    isCustom: false,
    email: ""
  };
  componentDidMount() {
    const { shopDetail } = this.props;
    if (shopDetail.emailReport) {
      this.setState({ email: shopDetail.emailReport });
    }
  }
  handleChange = (dateName, date) => {
    this.setState({
      [dateName]: date
    });
  };
  selectBranch = selectedBranchs => {
    this.setState({ selectedBranchs });
  };
  setIsCustomBranch = isCustom => {
    const { selectedBranchs } = this.state;
    const newBranchs = isCustom ? selectedBranchs : [];
    this.setState({ isCustom, selectedBranchs: newBranchs });
  };

  sendEmail = () => {
    const { startDate, endDate, selectedBranchs, isCustom, email } = this.state;
    const { loggedIn, actions } = this.props;
    if (email === "") {
      swal("เกิดข้อผิดพลาด", "กรุณากรอก Email", "error");
      return;
    }
    actions.loading(true);
    let data = {
      shopId: loggedIn.shopId,
      startDate: startDate.toISOString().substring(0, 10),
      endDate: endDate.toISOString().substring(0, 10),
      email
    };
    if (isCustom) {
      var customBranch = [];
      _.forEach(selectedBranchs, branch => {
        customBranch.push(branch.value);
      });
      data.customBranch = customBranch;
    }

    api
      .sendReportProductEmail(data)
      .then(res => {
        if (res.status === 200) {
          swal(
            "สำเร็จ !",
            "ส่ง Email สำเร็จ (Email จะถึงผู้รับในเวลาไม่เกิน 10 นาที)",
            "success"
          );
          actions.loading(false);
        } else {
          swal("เกิดข้อผิดพลาด", res.message, "error");
          actions.loading(false);
        }
      })
      .catch(err => {
        swal("เกิดข้อผิดพลาด", err, "error");
        actions.loading(false);
      });
  };

  render() {
    const { branchOptions } = this.props;
    const { selectedBranchs, isCustom, email } = this.state;
    return (
      <div className="main-content container-fluid">
        <div className="card">
          <div style={{ padding: 15 }}>
            <h2>รายงานยอดขาย</h2>
            <div className="row m-0">
              <div>
                <div>Start Date</div>
                <div>
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={date => this.handleChange("startDate", date)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="pl-4">
                <div>End Date</div>
                <div>
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={date => this.handleChange("endDate", date)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="pt-3">
              <label
                className="custom-control custom-radio custom-control-inline"
                onClick={() => this.setIsCustomBranch(false)}
              >
                <input
                  className="custom-control-input"
                  type="radio"
                  name="radio-inline"
                  checked={isCustom === false}
                />
                <span className="custom-control-label">ทุกสาขา</span>
              </label>
              <label
                className="custom-control custom-radio custom-control-inline"
                onClick={() => this.setIsCustomBranch(true)}
              >
                <input
                  className="custom-control-input"
                  type="radio"
                  name="radio-inline"
                  checked={isCustom === true}
                />
                <span className="custom-control-label">เลือกสาขาเอง</span>
              </label>
            </div>
            {isCustom && (
              <div style={{ width: 500 }} className="pt-3">
                เลือกสาขา
                <Select
                  value={selectedBranchs}
                  onChange={this.selectBranch}
                  options={branchOptions}
                  isMulti={true}
                  isDisabled={!isCustom}
                />
              </div>
            )}
            <div className="pt-3" style={{ width: 500 }}>
              <div className="form-group">
                <label>Email address</label>
                <input
                  className="form-control"
                  type="email"
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                  placeholder="abc@niceloop.com, xyz@niceloop.com"
                />
                <div style={{ paddingTop: 10 }} />
                <span style={{ color: "grey", fontSize: 13 }}>
                  E-Mail จะถึงผู้รับในเวลาไม่เกิน 10 นาที
                </span>
              </div>
            </div>
            <div style={{ width: 500 }} className="pt-3">
              <button
                className="btn btn-space btn-primary"
                onClick={this.sendEmail}
              >
                Send <span className="mdi mdi-mail-send" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    shopDetail: state.shop.detail,
    loggedIn: state.user.loggedIn,
    branchOptions: branchOptionsSelector.branchOptionsSelector(state),
    loading: state.appState.loading
  };
};
function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...appStateAction }, dispatch);
  return {
    actions
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportProductEmail);

// this.state.startDate.toISOString().substring(0, 10)
