import moment from "moment";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import * as computeSelector from "../PosCart/selector";
import _ from "lodash";
import PrintSettlePreview from "../../printer/service.print.receipt.preview";
import receiptIdService from "../../shared/api/receipt_id";
import {
  spaPosTimeStampRef,
  prepaidPaymentRef,
} from "../../shared/firebaseApi/firebaseRef";
import LinePushMessageApi from "../../shared/api/line.message.api";
import swal from "sweetalert";
import * as memberTxActions from "../../shared/actions/memberTxActions";
import getTherapist from "../../shared/functions/computeTherData";
import Swal2 from "sweetalert2";

export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const CART_CLEAR = "CART_CLEAR";
export const CART_REMOVE_ITEMLINE = "CART_REMOVE_ITEMLINE";
export const CART_ITEM_SELECTED = "CART_ITEM_SELECTED";
export const CART_ITEMS_DISCOUNT_LINE = "CART_ITEMS_DISCOUNT_LINE";
export const CART_OPTIONS_INSERT = "CART_OPTIONS_INSERT";
export const CART_OPTIONS_EDIT = "CART_OPTIONS_EDIT";
export const CART_OPTIONS_REMOVE = "CART_OPTIONS_REMOVE";
export const CART_RESTORE = "CART_RESTORE";

export const CART_OPTIONS_SAVE = "CART_OPTIONS_SAVE";
export const CART_TIMESTAMP_SAVE = "CART_TIMESTAMP_SAVE";

export const addItem = (item, qty = 1) => {
  return { type: CART_ADD_ITEM, payload: { item: item, qty } };
};

export const removeItemLine = (indexRow, uid) => {
  return (dispatch, getState) => {
    //DELETE VOUCHER------------------------
    let { mainAppStore } = getState();
    let cartState = getState();
    let cartItem = cartState.items[indexRow];
    let itemPickTime = cartItem.timestamp.toString();

    let mainAppState = mainAppStore.getState();

    const { matchVoucher, therapistMode, therapistList } = mainAppState.spa.pos;

    let saveData = {};

    //THERAPIST
    let productObj = {};
    _.map(cartState.items, (item) => {
      productObj[item.timestamp.toString()] = item;
    });

    let newTherObj = {};
    if (therapistMode === "multiple") {
      _.map(therapistList, (prod, key) => {
        if (productObj[key] && key !== itemPickTime) {
          newTherObj[key] = prod;
        }
      });
    }

    saveData.therapistList = newTherObj;

    //VOUCHER
    let newMatchVoucher = {};

    if (matchVoucher) {
      _.map(matchVoucher, (voucher, key) => {
        if (key === itemPickTime) return;
        newMatchVoucher[key] = voucher;
      });

      saveData.matchVoucher = newMatchVoucher;
    }
    //DELETE VOUCHER------------------------

    mainAppStore.dispatch({
      type: "SPA_RESTORE_JOB_DATA",
      payload: saveData,
    });
    dispatch({
      type: CART_RESTORE,
      payload: saveData,
    });

    setTimeout(() => {
      if (_.size(newMatchVoucher) < 1) {
        mainAppStore.dispatch({
          type: "SPA_RESTORE_JOB_DATA",
          payload: { matchVoucher: null },
        });
      }
    }, 300);

    dispatch({
      type: CART_REMOVE_ITEMLINE,
      payload: {
        index: indexRow,
        uid,
      },
    });
  };
};

export const discountLine = (mode, value) => {
  return {
    type: "CART_ITEMS_DISCOUNT_LINE",
    payload: {
      mode: mode,
      value: parseInt(value),
    },
  };
};

export const clear = () => {
  return { type: CART_CLEAR };
};

export const save = () => (dispatch, getState) => {};

export const optionsInsert = (index, name, value) => {
  return {
    type: CART_OPTIONS_INSERT,
    payload: {
      index,
      name,
      value,
    },
  };
};
export const optionsEdit = (index, name, value, sign) => {
  return {
    type: CART_OPTIONS_EDIT,
    payload: {
      index,
      name,
      value,
      sign,
    },
  };
};
export const optionsRemove = (index, name, value) => {
  return {
    type: CART_OPTIONS_REMOVE,
    payload: {
      index,
      name,
      value,
    },
  };
};
export const optionsSave = (data) => {
  return {
    type: CART_OPTIONS_SAVE,
    payload: data,
  };
};
// export const cartStoreTimestampSave = data => {
//   return {
//     type: CART_TIMESTAMP_SAVE,
//     payload: data
//   };
// };

export const payment = (cb, extendData = {}, options = {}) => {
  return async (dispatch, getState) => {
    var state = getState();

    var mainAppStore = state.mainAppStore.getState();

    var posState = mainAppStore.spa.pos;

    let { posSetting } = mainAppStore.spa;

    let drawerState = extendData.drawerState;

    // ###AUTO BUSINESS DATE
    // let businessDate =
    //   drawerState.businessDate || moment().format("YYYY-MM-DD");
    let businessDate =
      mainAppStore.appState.businessDate || moment().format("YYYY-MM-DD");

    const { detail, linked } = mainAppStore.shop;

    let store = posState.store || {
      ...detail,
      hqUid: linked.inventory,
    };

    //COMPUTE THERAPIST
    let therapist = getTherapist(posState.therapistMode, extendData.items, {
      therapist: posState.therapist,
      therapistList: posState.therapistList,
    });

    let hasMember = false;

    if (posState.member && posState.member.name !== "") {
      hasMember = true;
    }
    let data = {
      voucherOwner: posState.voucherOwner,
      orderId: posState.orderId,
      refId: posState.refId === "" ? null : posState.refId,
      inventory: posState.inventory,
      jobUid: posState.jobUid,
      member: hasMember ? posState.member : null,
      // memberUid: hasMember ? posState.member.uid : "unknow",
      meta: posState.meta,
      remark: posState.remark,
      requireEmp: posState.requireEmp,
      tax: posState.tax,
      therapist,
      // therapist: posState.therapist,
      // therapistList,
      sales: posState.sales,
      matchVoucher: posState.matchVoucher
        ? _.orderBy(posState.matchVoucher, "cartRow", "asc")
        : null,

      // later for override
      // timestamp: moment().format("YYYY-MM-DD") +'#'+ moment().toISOString(),
      timestamp: businessDate + "#" + moment().toISOString(),
      // timestamp: moment().toISOString(),
      storeUid: mainAppStore.shop.detail.uid,
      businessDate: businessDate,

      receiptId: receiptIdService.generateNextReceiptId(),

      shift: drawerState.shift || null,
      ...extendData,
      store,
      hqUid: linked.inventory,
      cashier: mainAppStore.employee.current
        ? {
            name: mainAppStore.employee.current.name,
            uid: mainAppStore.employee.current.uid,
          }
        : null,
      user: mainAppStore.user.loggedIn,
      tag: {},
      version: 2020,
      flag: "2020-01-28",
      localTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
      therapistMode: posState.therapistMode,
      // store: mainAppStore.spa.pos.store
    };

    //TAG
    let receiptType = "retail";
    if (posState.meta && posState.meta.room) {
      receiptType = "course";
    }

    data.tag[receiptType] = true;

    //BOOKING DETAIL
    if (posState.bookingDetail) {
      data.bookingDetail = posState.bookingDetail;
    }
    //REVERT BILL
    let revertBillDate = _.cloneDeep(posSetting.revertBillDate);
    if (posSetting.revertBill) {
      let startDateObj = new Date(data.meta.startDate);
      let startDateHour = startDateObj.getHours();
      let startDateMinute = startDateObj.getMinutes();

      revertBillDate.setMinutes(startDateMinute);
      revertBillDate.setHours(startDateHour);
      revertBillDate.setSeconds(getRandomInt(60));
      data.meta.startDate = moment(revertBillDate).toISOString();
    }

    //PROTECT START OF DAY RETAIL
    if (!data.meta.room && _.includes(data.meta.startDate, "T17:00")) {
      data.meta.startDate = moment().toISOString();
    }

    //ATTACH MEMBER
    if (hasMember) {
      data.memberUid = posState.member.uid;
    }

    //compute End Date
    const totalItemTime = computeSelector.totalItemTimeSelector(state);
    let endTime = _.cloneDeep(new Date(data.meta.startDate));
    endTime.setMinutes(endTime.getMinutes() + totalItemTime);
    data.meta.endDate = moment(endTime).toISOString();

    //compute timestamp
    let today = moment();
    let tmpStartDate = moment(data.meta.startDate);
    let diffDay = tmpStartDate.diff(today, "day");

    // let timestamp = new Date();
    // if (diffDay >= 0) {
    //   let startDateObj = new Date(data.meta.startDate);
    //   let startDateNumber = startDateObj.getDate();
    //   timestamp.setDate(startDateNumber);
    //   data.timestamp = moment(timestamp).toISOString();
    // } else {
    //   data.timestamp = data.meta.startDate;
    // }

    //validate paymentTtype
    let isPaymentTypeFail = false;

    _.forEach(data.payment.paymentTypes, (payment, idx) => {
      if (payment.type === "cityLedger" && _.isEmpty(payment.payload)) {
        isPaymentTypeFail = "กรุณาระบุชื่อ Agent ที่ Payment Type";
      }
    });

    //CHECK HAS PREPAID
    let hasPrepaid = null;
    let prepaidIdx = null;

    _.forEach(data.payment.options, (option, idx) => {
      if (option.uid === "prepaid001") {
        hasPrepaid = true;
        prepaidIdx = idx;
      }
    });

    if (isPaymentTypeFail) {
      swal("คุณกรอกข้อมูลไม่ครบถ้วน", isPaymentTypeFail, "error");
      return;
    }

    //PUSH PREPAID SPEND TRANSACTION
    if (hasPrepaid) {
      let doc = await prepaidPaymentRef.doc(posState.jobUid).get();
      if (!doc.exists) return;
      let docData = doc.data();

      let resMember = await fetchMemberAsync(docData.member.uid);
      if (!resMember.Item) return swal("Error", "Member not found", "error");
      let member = resMember.Item;

      data.payment.options[prepaidIdx].prepaidData = docData.member;

      //ข้อมูล Prepaid
      data.prepaidDetail = {
        member: member,
        before: member.walletBalance,
        used: -parseInt(docData.amount),
        after: member.walletBalance - parseInt(docData.amount),
      };

      state.mainAppStore.dispatch(
        memberTxActions.pushMemberTx(docData.member.uid, "wallet", {
          amount: -parseInt(docData.amount),
          refId: null,
          remark: null,
          type: "SPEND",
        })
      );
    }

    //ENTERTAIN
    let findEntertain = _.find(
      data.payment.options,
      (i) => i.uid === "entertain001"
    );

    if (findEntertain) {
      data.entertain = findEntertain.payload;
    }

    //CITY LEDGER
    let findAgent = _.find(
      data.payment.paymentTypes,
      (i) => i.type === "cityLedger"
    );
    if (findAgent) {
      data.agentDetail = findAgent;
    }

    if (findAgent && findAgent.mode == "transfered" && !findAgent.bank) {
      return swal("Error", "City Ledger: กรุณาระบุธนาคาร", "error");
    }

    if (findAgent && findAgent.mode == "billing") {
      findAgent.bank = null;
    }
    //UPDATE USE VOUCHER
    if (data.matchVoucher) {
      useVoucher(data.matchVoucher, data.store, data.receiptId);
    }

    var params = {
      TableName: "Spa_Receipts",
      Item: data,
    };

    let key = data.timestamp;
    let pushBillKey = `${key}pushBill`;

    dispatch(saveOffline(pushBillKey, params, "put"));
    documentClient.put(params, (err, data) => {
      if (err) {
        console.log(err);
        return;
      }

      dispatch(releaseOffline(pushBillKey));
    });

    var params2 = {
      TableName: "Spa_Jobs",
      Key: {
        storeUid: mainAppStore.shop.detail.uid,
        jobUid: posState.jobUid,
      },
    };
    let deleteJobKey = `${key}deleteJob`;
    dispatch(saveOffline(deleteJobKey, params2, "delete"));

    // await documentClient.delete(params).promise();
    documentClient.delete(params2, (err, data) => {
      if (err) {
        console.log(err);
        return; 
      }
      dispatch(releaseOffline(deleteJobKey));
    });

    cb &&
      cb(data, async () => {
        let formString = "";
        let isNotAlert = true;
        if (posState.member.type !== "temporary" && posState.member.name) {
          isNotAlert = false;
          formString +=
            '<div style="color:gray">' +
            `เพิ่ม Internal Note สำหรับ ${posState.member.name}` +
            "</div>" +
            '<div class="mb-2"><textarea id="swal-input1" style="height:60px" class="swal2-input" placeholder="หากไม่ต้องเพิ่ม Note ไม่ต้องกรอกข้อมูล"></textarea></div>';
        }
        if (posState.meta.room) {
          let initCheckEvaluate = localStorage.getItem("isEvaluate");

          let checkEvaluate = false;
          if (initCheckEvaluate === "on") {
            checkEvaluate = true;
          }

          isNotAlert = false;
          formString +=
            '<div><input id="swal-input2" type="checkbox" ' +
            (checkEvaluate ? "checked" : "") +
            '> <span style="color:gray">Evaluate (Sat Rate)</span></div>';
        }

        if (isNotAlert) return;

        const { value: formValues } = await Swal2.fire({
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-space btn-primary w-100",
          },
          title: "บันทึกข้อมูลอื่น ๆ",
          html: formString,
          focusConfirm: false,
          preConfirm: () => {
            //ADD MEMBER INTERNAL NOTE
            let internalNote = document.getElementById("swal-input1");
            if (internalNote && internalNote.value !== "") {
              updateMemberInternalNote(
                posState.member,
                internalNote.value,
                data.cashier
              );
            }
            //EVALUATE
            let isEvaluate = document.getElementById("swal-input2");
            if (isEvaluate && isEvaluate.checked) {
              window.localStorage.setItem("isEvaluate", "on");

              let storeuid = store.uid;
              let timestampEncode = encodeURIComponent(key);

              const urlSatrate = `https://payment-confirm-dev-web.firebaseapp.com/satrate/${storeuid}/${timestampEncode}`;

              window.open(urlSatrate, "_blank");
            } else {
              window.localStorage.setItem("isEvaluate", "off");
            }
            // return [text, isEvaluate];
          },
        });

        // if (formValues) {
        //   Swal.fire(JSON.stringify(formValues));
        // }

        //ADD MEMBER INTERNAL NOTE
        // if (posState.member.type !== "temporary") {
        //   const { value: text } = await Swal2.fire({
        //     title: `เพิ่ม Internal Note สำหรับ ${posState.member.name}`,
        //     // icon:'success',
        //     input: "textarea",
        //     // inputPlaceholder: "Type your message here...",
        //     showCancelButton: true
        //   });

        //   if (text) {
        //     updateMemberInternalNote(posState.member, text, data.cashier);
        //   }
        // }

        //EVALUATE
        // if (posState.meta.room) {
        //   let confirm = await swal({
        //     title: `Payment success ! Continue evaluate ?`,
        //     icon: "success",
        //     buttons: ["No", "Evaluate"]
        //   });
        //   if (confirm) {
        //     let storeuid = store.uid;
        //     let timestampEncode = encodeURIComponent(key);

        //     const urlSatrate = `https://payment-confirm-dev-web.firebaseapp.com/satrate/${storeuid}/${timestampEncode}`;

        //     window.open(urlSatrate, "_blank");
        //   }
        // }
      });

    //DELETE PREPAID_PAYMENT DATA
    prepaidPaymentRef.doc(posState.jobUid).delete();

    dispatch(postPointsTx(data));
    dispatch(clear());
    spaPosTimeStampRef
      .child(mainAppStore.shop.detail.uid)
      .set(moment().toISOString());

    state.mainAppStore.dispatch({
      type: "APP_STATE_INCREMENT_LAST_RECEIPT_ID",
    });

    updateBookingStatus(data);

    // push to line
    if (data.entertain) {
      new LinePushMessageApi().entertain(data);
    }
  };
};

export const preview = (cb, extendData = {}, options) => {
  return async (dispatch, getState) => {
    var state = getState();

    var mainAppStore = state.mainAppStore.getState();

    var posState = mainAppStore.spa.pos;
    var lastReceiptId = mainAppStore.appState.lastReceiptId;

    let businessDate = moment().format("YYYY-MM-DD");

    const { detail, linked } = mainAppStore.shop;

    //TODO: CHANGE TO ORIGINAL JOB DATA LATER
    let store = mainAppStore.spa.pos.store || {
      // uid: detail.uid,
      // branchId: detail.branchId,
      // name: detail.name,
      ...detail,
      hqUid: linked.inventory,
    };

    //COMPUTE THERAPIST

    let therapist = getTherapist(posState.therapistMode, extendData.items, {
      therapist: posState.therapist,
      therapistList: posState.therapistList,
    });

    let data = {
      // ...posState,
      orderId: posState.orderId,
      refId: posState.refId === "" ? null : posState.refId,
      agent: posState.agent,
      inventory: posState.inventory,
      jobUid: posState.jobUid,
      member: posState.member,
      meta: posState.meta,
      remark: posState.remark,
      requireEmp: posState.requireEmp,
      tax: posState.tax,
      // therapist: posState.therapist,
      therapist,

      // later for override
      // timestamp: moment().format("YYYY-MM-DD") +'#'+ moment().toISOString(),
      // timestamp: businessDate + '#' +moment().toISOString(),
      // timestamp: moment().toISOString(),
      storeUid: mainAppStore.shop.detail.uid,
      businessDate: businessDate,
      receiptId: lastReceiptId + 1,
      shift: 1 || null,
      ...extendData,
      store,
      hqUid: linked.inventory,
      cashier: mainAppStore.employee.current
        ? {
            name: mainAppStore.employee.current.name,
            uid: mainAppStore.employee.current.uid,
          }
        : null,
      user: mainAppStore.user.loggedIn,

      // store: mainAppStore.spa.pos.store
    };

    data.timestamp = data.meta.startDate;

    //compute End Date
    const totalItemTime = computeSelector.totalItemTimeSelector(state);
    let endTime = _.cloneDeep(new Date(data.meta.startDate));
    endTime.setMinutes(endTime.getMinutes() + totalItemTime);
    data.meta.endDate = moment(endTime).toISOString();

    var params = {
      TableName: "Spa_Receipts",
      Item: data,
    };

    if (options.printPreview) {
      let p = new PrintSettlePreview(state.mainAppStore.dispatch, params.Item, {
        employeeName: mainAppStore.employee.current.name,
      });
      p.build().print();

      return;
    }
  };
};

export const restore = (jobItem) => {
  return {
    type: CART_RESTORE,
    payload: {
      items: jobItem.items,
      options: jobItem.options,
      qtyHolder: jobItem.qtyHolder,
    },
  };
};

export const postPointsTx = (data) => {
  return async (dispatch, getState) => {
    if (data.customer === undefined) {
      return;
    }
    var params = {
      TableName: "Crm_Points_Transactions",
      Item: {
        amount: data.grandTotal, //1 : 1 points raito
        brandUid_userUid: "1234qazx_" + data.customer.uid,
        timestamp: data.timestamp,
        userUid: data.customer.uid,
        grandTotal: data.grandTotal,
        brandUid: "1234qazx",
        type: "earn",
      },
    };
    let dataResponse = await documentClient.put(params).promise();

    // {
    //     "amount": 10000,  // **
    //     "brandUid": "1234qazx",
    // "grandTotal" : 7000,
    //     "brandUid_userUid": "1234qazx_+66818460621", **
    //     "employee": {
    //       "approve": true,
    //       "hqUid": "qqqqq",
    //       "name": "rice (supv.)",
    //       "password": "123456",
    //       "pin": "1234",
    //       "username": "123456"
    //     },
    //     "store": {
    //       "brandUid": "1234qazx",
    //       "hqUid": "qqqqq",
    //       "name": "SiamSquare 2nd floor",
    //       "password": "1234",
    //       "storeUid": "zzzzz"
    //     },
    //     "timestamp": "2019-05-29T07:47:27.086Z",  **
    //     "type": "earn",
    //     "userUid": "+66818460621", **
    //     "verify": "auto",
    //     "verifyResponse": "ok"
    //   }
  };
};

export function makeid(length) {
  var result = "";
  var characters = "ABCDEF0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const saveOffline = (key, params, mode = "put") => {
  return async (dispatch, getState) => {
    var state = getState();

    var mainAppStore = state.mainAppStore;

    mainAppStore.dispatch({
      type: "SAVE_OFFLINE",
      payload: params, 
      key: key,
      meta: {
        autosave: "RECEIPT",
        mode: mode,
      },
    });
  };
};

export const releaseOffline = (key) => {
  return async (dispatch, getState) => {
    var state = getState();

    var mainAppStore = state.mainAppStore;

    mainAppStore.dispatch({
      type: "SAVE_OFFLINE",
      payload: null,
      key: key,
      meta: {
        autosave: "RECEIPT",
      },
    });
  };
};

const fetchMemberAsync = (uid) => {
  var params = {
    TableName: "Crm_Users",
    Key: {
      uid: uid,
    },
  };
  return documentClient.get(params).promise();
};

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function useVoucher(vouchers, store, receiptId) {
  _.forEach(vouchers, (voucher) => {
    var params = {
      TableName: "Spa_Member_Voucher",
      Key: { hqUid: voucher.hqUid, voucherUid: voucher.voucherUid },
      UpdateExpression: "set used = :used, #status =:status",
      ExpressionAttributeNames: { "#status": "status" },
      ExpressionAttributeValues: {
        ":used": {
          store,
          timestamp: moment().toISOString(),
          remark: `Receipt ID: ${receiptId}`,
        },
        ":status": "used",
      },
    };
    documentClient.update(params, (err) => {
      console.log(err);
    });
  });
}

const updateMemberInternalNote = async (member, text, cashier) => {
  let notes = member.internalNotes || [];
  notes.push({
    text,
    employee: cashier,
    timestamp: moment().toISOString(),
  });
  var params = {
    TableName: "Crm_Users",
    Key: { uid: member.uid },
    UpdateExpression: "set internalNotes = :internalNotes",
    ExpressionAttributeValues: {
      ":internalNotes": notes,
    },
  };
  try {
    await documentClient.update(params).promise();
  } catch (error) {
    throw error;
  }
};

const updateBookingStatus = async (receipt)=>{

  if(receipt.bookingDetail){

  
  var params = {
    TableName: "Spa_Booking",
    Key: { storeUid: receipt.storeUid, bookUid: receipt.bookingDetail.bookUid },
    UpdateExpression:
      "set #status = :status",
    ExpressionAttributeNames: {  "#status": "status" },
    ExpressionAttributeValues: {
       ":status": "used",
    },
  };

  await documentClient.update(params).promise();
  }

}