import React, { useState } from "react";
// import useDrawerReducer from "../hook.drawer.reducer";
import _ from "lodash";
// import ReceiptRateCard from "../../SpaReport/ReceiptRateCard";
import { SummaryList, RowButton, Row, Left, Right } from "./Components";
import axios from "axios";
import { downloadableLinkUrl } from "../../../shared/utils";
import * as appStateAction from "../../../shared/actions/appStateActions";
import { useDispatch, useSelector } from "react-redux";
import PrintReportSummary from "../../../printer/service.print.report.summary";
import PrintReportProduct from "../../../printer/service.print.report.product";
import moment from "moment";

import useComputeProductsReport from "../../../shared/useHook/useComputeProductsReport";

function DashboardExport({
  receipts,
  receiptVoids,
  receipts_entertain,
  startDate,
  endDate,
}) {
  const dispatch = useDispatch();

  const shopInfo = useSelector((state) => {
    return state.shop.detail;
  });
  const shopFull = useSelector((state) => {
    return state.shop;
  });
  const mainState = useSelector((state) => state);
  let products = useComputeProductsReport(
    _.concat(receipts, receipts_entertain),
    mainState
  );

  const downloadExcel = (mediaLink) => {
    // let URL =
    //   "https://us-central1-divana-project.cloudfunctions.net/spaSummaryReport";
    // let data = {
    //   startDate,
    //   endDate,
    //   shopName: shopInfo.name || "unknown",
    //   storeUid: shopInfo.uid,
    // };
    const EXCEL_URL =
      "https://asia-east2-divana-project.cloudfunctions.net/excelReport";
    let data = {
      storeUid: shopInfo.uid, //ไอดีของร้าน
      shopName: shopInfo.name || "unknown",
      startDate: moment(startDate).format("YYYY-MM-DD"), //วันที่เริ่มต้นของข้อมูลที่ต้องการ
      endDate: moment(endDate).format("YYYY-MM-DD"), //วันที่สิ้นสุดของข้อมูลที่ต้องการ,
      timestamp: moment(startDate).format("YYYY-MM"),
      shopId: shopInfo.uid,
      filter: null,
      type: "RECEIPTS",
      url: "https://asia-east2-divana-project.cloudfunctions.net/excelReport",
    };

    dispatch(appStateAction.loading(true));

    axios
      .post(EXCEL_URL, data)
      .then((res) => {
        downloadableLinkUrl("report-summary-excel.xlsx", res.data);
      })
      .catch((err) => alert(err))
      .finally(() => {
        dispatch(appStateAction.loading(false));
      });
  };

  const downloadPDF = () => {
    let URL =
      "https://us-central1-divana-project.cloudfunctions.net/pdfSummaryReport";
    let data = {
      // startDate: moment().format("YYYY-MM-DD"),
      // endDate: moment().format("YYYY-MM-DD"),
      startDate,
      endDate,
      shopName: shopInfo.name || "unknown",
      storeUid: shopInfo.uid,
    };

    dispatch(appStateAction.loading(true));

    axios
      .post(URL, data)
      .then((res) => {
        downloadableLinkUrl("report-summary-excel.xlsx", res.data);
      })
      .catch((err) => alert(err))
      .finally(() => {
        dispatch(appStateAction.loading(false));
      });
  };

  const printSummary = () => {
    let print = new PrintReportSummary(dispatch, shopFull, receipts, {
      voidReceipts: receiptVoids, 
      entertainReceipts: receipts_entertain,
    });
    print.build().print();
  };

  const printProduct = () => {
    // shop
    let data = {
      products,
      // startDate: moment().format(),
      // endDate: moment().format(),
      startDate,
      endDate,
    };

    let shopName = shopFull.detail.name;

    let print = new PrintReportProduct(dispatch, shopName, data);
    print.build();
    print.print();
  };

  return (
    <div class=" pt-5 pb-7">
      <div class="row mb-5">
        <div class="offset-0 col-4">
          <h4 class="text-right">THERMAL PRINTER</h4>
        </div>
        <div class="col-4">
          <div style={{ marginTop: -16 }}>
            <SummaryList>
              <RowButton
                title="Summary"
                buttonLabel="print"
                onClick={printSummary}
              />
              <RowButton
                title="Products"
                buttonLabel="print"
                onClick={printProduct}
              />
              {/* <RowButton title="Void" buttonLabel="print" /> */}
            </SummaryList>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="offset-0 col-4">
          <h4 class="text-right">PDF</h4>
        </div>
        <div class="col-4">
          <div style={{ marginTop: -16 }}>
            <SummaryList>
              <RowButton
                title="Overview"
                buttonLabel="download"
                onClick={downloadPDF}
              />
            </SummaryList>
          </div>
        </div>
      </div>

      <div class="row mb-5">
        <div class="offset-0 col-4">
          <h4 class="text-right">Excel</h4>
        </div>
        <div class="col-4">
          <div style={{ marginTop: -16 }}>
            <SummaryList>
              <RowButton
                title="Overview"
                buttonLabel="download"
                onClick={downloadExcel}
              />
            </SummaryList>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardExport;
