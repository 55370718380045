import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails
} from "amazon-cognito-identity-js";
import AWS from "aws-sdk";
const poolData = {
  UserPoolId: "ap-southeast-1_cGBlOkVLn",
  ClientId: "7hdaqppemlqpgkacaa115cnmq7"
};

const userPool = new CognitoUserPool(poolData);

export const SignUp = () => {
  var attributeList = [];
  var dataEmail = {
    Name: "email",
    Value: "abc@niceloop.com"
  };

  var dataPhoneNumber = {
    Name: "phone_number",
    Value: "+66999999999"
  };

  var attributeEmail = new CognitoUserAttribute(dataEmail);
  var attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);

  attributeList.push(attributeEmail);
  attributeList.push(attributePhoneNumber);

  userPool.signUp("comboxxx", "winza0074", attributeList, null, function(
    err,
    result
  ) {
    if (err) {
      alert(err.message || JSON.stringify(err));
      return;
    }
    let cognitoUser = result.user;
    console.log("username is " + cognitoUser.getUsername());
  });
};

export const LogIn = () => {
  var authenticationData = {
    Username: "comboxxx",
    Password: "123456"
  };
  var authenticationDetails = new AuthenticationDetails(authenticationData);

  var userData = {
    Username: "comboxxx",
    Pool: userPool
  };

  var cognitoUser = new CognitoUser(userData);
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: function(result) {
      var accessToken = result.getAccessToken().getJwtToken();
      //POTENTIAL: Region needs to be set if not already set previously elsewhere.
      AWS.config.region = "ap-southeast-1";

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: "ap-southeast-1:9b4a6853-a87e-4eaf-9c4e-908014bbbe04", // your identity pool id here
        Logins: {
          // Change the key below according to the specific region your user pool is in.
          "cognito-idp.ap-southeast-1.amazonaws.com/ap-southeast-1_yU6sc3qqh": result
            .getIdToken()
            .getJwtToken()
        }
      });

      //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
      AWS.config.credentials.refresh(error => {
        if (error) {
          console.error(error);
        } else {
          // Instantiate aws sdk service objects now that the credentials have been updated.
          // example: var s3 = new AWS.S3();
          console.log("Successfully logged!");
        }
      });
    },

    onFailure: function(err) {
      alert(err.message || JSON.stringify(err));
    }
  });
};

export const getUser = () => {
  const userPool = new CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();
  cognitoUser.signOut();

  return cognitoUser;
};

export const cognitoSignOut = () => {
  const userPool = new CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();
  cognitoUser.signOut();
};
