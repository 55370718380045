import { createSelector } from "reselect";
import _ from "lodash";

const itemsState = state => state.items;
const optionsState = state => state.options;
const inventoryState = state => state.inventoryReducer;
const promotionListState = state => state.promotionList;
const mainAppState = state =>
  (state.mainAppStore && state.mainAppStore.getState()) || null;
const timestampState = state => state.timestampState;

//ตรงตัว
const promotion1 = {
  name: "department VEt dis-20%",
  uid: {
    "76Z7R412": "department"
  },
  type: "qty",
  discountList: [{ mode: 0, value: 20 }]
};

//ตรงตัว
const promotion2 = {
  name: "buy 2 free 1,  in test sX",
  uid: {
    YEvLAuIM: "group"
  },
  type: "qty",

  discountList: [
    { mode: 0, value: 0 },
    { mode: 0, value: 0 },
    { mode: 0, value: 100 }
  ]
};

export const subTotalAmountSelector = createSelector([itemsState], items => {
  var total = _.reduce(
    items,
    (acc, itemLine) => {
      let disAmount = 0;
      let disMode = itemLine.discount && itemLine.discount.mode;
      if (disMode === 0) {
        disAmount = (itemLine.price * itemLine.discount.value) / 100;
      } else if (disMode === 1) {
        disAmount = itemLine.discount.value;
      } else {
      }

      acc += (itemLine.price - disAmount) * itemLine.qty;
      return acc;
    },
    0
  );

  return total;
});
export const totalItemTimeSelector = createSelector([itemsState], items => {
  let minutes = 0;
  _.forEach(items, item => {
    if (!item.time) return;
    let splited = _.split(item.time, ".");
    let tmpMinutes = 0;
    //plus hour
    tmpMinutes += parseInt(splited[0]) * 60;
    //plus minute
    tmpMinutes += parseInt(splited[1]);
    //multiply by qty
    tmpMinutes = tmpMinutes * item.qty;

    minutes += tmpMinutes;
    // qtyHolder
  });
  return minutes;
});

export const promotionItems = createSelector(
  [itemsState, inventoryState, promotionListState],
  (items, inventoryState, promotionList) => {
    // compute pure items
    var pureItems = _.reduce(
      items,
      (acc, itemLine) => {
        var qty = itemLine.qty;
        _.times(qty, () => {
          acc.push({
            qty: 1,
            name: itemLine.name,
            price: itemLine.price,
            uid: itemLine.uid
          });
        });
        return acc;
      },
      []
    );

    pureItems = _.sortBy(pureItems, line => -line.price);
    //loop each promotion

    let promotionItems = [];

    _.forEach(promotionList, (pro, index) => {
      var proUidHolder = exactUid(pro.uidHolder, inventoryState);

      // ดึงเฉพาะ ที่จะใช้ได้
      var item_to_used_promotion = _.filter(
        pureItems,
        (pureItemLine, index2) => {
          //   return pureItemLine.isUsed === undefined;
          return (
            proUidHolder[pureItemLine.uid] && pureItemLine.isUsed === undefined
          );
        }
      );

      //ไม่พอ
      if (item_to_used_promotion.length < pro.discountList.length) {
        return;
      }

      var countLoop = Math.floor(
        item_to_used_promotion.length / pro.discountList.length
      );

      _.times(countLoop, i => {
        _.forEach(pro.discountList, (discLine, index2) => {
          var { mode, value } = discLine;

          var findUnusedItem = _.find(
            item_to_used_promotion,
            p_itemLine_to_used => p_itemLine_to_used.isUsed === undefined
          );
          if (findUnusedItem === null) {
            return;
          }
          var amount = getDiscountAmount(findUnusedItem, mode, value);
          promotionItems.push({
            qty: 1,
            name: pro.name + " (" + findUnusedItem.name + ")",
            price: amount,

            printName: [pro.name, findUnusedItem.name]
          });

          findUnusedItem.isUsed = true;
        });
      });
    });

    return _.filter(promotionItems, l => l.price !== 0);
  }
);

export const promotionItemsAmountSelector = createSelector(
  [promotionItems],
  dis_items => {
    return RoundToDecimal(
      _.reduce(
        dis_items,
        (acc, line) => {
          acc += line.qty * line.price;
          return acc;
        },
        0
      ),
      2
    );
  }
);


export const voucherDiscountSelector = createSelector(
  [itemsState, mainAppState, timestampState],
  (itemsState, mainAppState, timestampState) => {
    let voucherDiscount = 0;
    let matchVoucher = mainAppState && mainAppState.spa.pos.matchVoucher;
    if (matchVoucher) {
      _.forEach(itemsState, item => {
        if (matchVoucher[item.timestamp]) {
          voucherDiscount += item.price * item.qty;
        }
      });
    }
    // if (mainAppState) {
    //   _.forEach(mainAppState.spa.pos.matchVoucher, voucher => {
    //     voucherDiscount += voucher.productPrice;
    //   });
    // }
    let amount = -voucherDiscount;
    return amount;
  }
);

export const optionsAmountSelector = createSelector(
  [subTotalAmountSelector, promotionItemsAmountSelector, voucherDiscountSelector, optionsState],
  (subTotal, promotion, voucherAmount, optionsList) => {
    var newResult = _.reduce(
      optionsList,
      (acc, optionLine) => {
        if (optionLine.mode === 0) {
          var tAmount =
            optionLine.sign * ((acc * parseFloat(optionLine.value)) / 100);

          optionLine.amount = tAmount;
          acc += tAmount;
        } else {
          var tAmount = optionLine.sign * parseFloat(optionLine.value);

          optionLine.amount = tAmount;
          acc += tAmount;
        }

        return acc;
      },
      subTotal + promotion - Math.abs(voucherAmount)
    );

    return RoundToDecimal(newResult - (subTotal + promotion - Math.abs(voucherAmount) ), 2);
  }
);

export const vatAmountSelector = createSelector(
  [subTotalAmountSelector, voucherDiscountSelector,  optionsAmountSelector],
  (subTotal,voucherAmount, optionsAmount) => {
    return RoundToDecimal((subTotal - Math.abs(voucherAmount) +optionsAmount) * 0.07, 2);
    // return ((subTotal + optionsAmount) * 7) / 107;
  }
);

export const roundingAmountSelector = createSelector(
  [
    subTotalAmountSelector,
    promotionItemsAmountSelector,
    voucherDiscountSelector,
    optionsAmountSelector,
    vatAmountSelector
  ],
  (subTotal, promotion, voucherAmount, optionsAmount, vat) => {
    return RoundToDecimal(
      RoundToDecimal(subTotal + promotion - Math.abs(voucherAmount) + optionsAmount, 0) -
        RoundToDecimal(subTotal + promotion - Math.abs(voucherAmount) + optionsAmount, 2),
      2
    );
  }
);


export const grandTotalSelector = createSelector(
  [
    subTotalAmountSelector,
    promotionItemsAmountSelector,
    optionsAmountSelector,
    roundingAmountSelector,
    vatAmountSelector,
    voucherDiscountSelector
  ],
  (
    subTotal,
    promotion,
    optionsAmount,
    roundingAmount,
    vat,
    voucherAmount
  ) => {
    //GET VOUCHER DISCOUNT

    return RoundToDecimal(
      subTotal + promotion + optionsAmount + roundingAmount - Math.abs(voucherAmount),
      0
    );
  }
);

function RoundToDecimal(number, decimal) {
  var zeros = new String((1.0).toFixed(decimal));
  zeros = zeros.substr(2);
  var mul_div = parseInt("1" + zeros);
  var increment = parseFloat("." + zeros + "01");
  if ((number * (mul_div * 10)) % 10 >= 5) {
    number += increment;
  }
  return Math.round(number * mul_div) / mul_div;
}

function getDiscountAmount(item, mode, value) {
  var amount = 0;
  switch (parseInt(mode)) {
    case 0:
      amount = RoundToDecimal((item.price * value) / 100, 2);
      break;
    case 1:
      amount = value;
      break;

    case 3: // FIX THB
      amount = item.price - value;
      break;

    case 2:
      amount = RoundToDecimal(
        item.price - RoundToDecimal((item.price * value) / 100, 2),
        2
      );
      break;

    default:
      break;
  }

  return -amount;
}

function departmentToItemUid(inventoryState, depUid) {
  var groupList = _.filter(inventoryState.groups, g => g.type === depUid);

  var res = _.reduce(
    groupList,
    (acc, g) => {
      var gUid = g.uid;
      var items = _.filter(
        inventoryState.items,
        item => item.groupUid === gUid
      );
      var itemKeys = _.reduce(
        items,
        (acc2, line) => {
          acc2[line.uid] = true;
          return acc2;
        },
        {}
      );

      return { ...acc, ...itemKeys };
    },
    {}
  );

  return res;
}

function groupToItemUid(inventoryState, gUid) {
  var itemList = _.filter(inventoryState.items, item => item.groupUid === gUid);

  var itemKeys = _.reduce(
    itemList,
    (acc2, line) => {
      acc2[line.uid] = true;
      return acc2;
    },
    {}
  );

  return itemKeys;
}

function exactUid(holder, inventoryState) {
  var res = {};
  _.forEach(holder, (value, keyUid) => {
    var res1 = {};
    if (value === "item") {
      return (res[keyUid] = true);
    }
    if (value === "group") {
      res1 = groupToItemUid(inventoryState, keyUid);
    }

    if (value === "department" || value === "type") {
      res1 = departmentToItemUid(inventoryState, keyUid);
    }
    res = { ...res, ...res1 };
  });

  return res;
}
