import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import StarRatings from "react-star-ratings";

function ReceiptRateCard(props) {
  const { receipt, showUncompletedSatRate = false } = props;
  const { satRate, items } = receipt;

  if (!satRate && showUncompletedSatRate === false) return <div />;

  if (!satRate && showUncompletedSatRate === true)
    return <UnCompleteSatRate   receipt={ receipt} />;

  const { definition, answers } = satRate.form_response;

  let definitionObj = {};
  let answerObj = {};

  _.forEach(definition.fields, (field, idx) => {
    if (!definitionObj[field.ref]) {
      definitionObj[field.ref] = field;
    }
  });

  _.forEach(answers, answer => {
    answerObj[answer.field.ref] = answer;
  });

  if(receipt.therapist === null || receipt.member === null){
    return null
  }
  return (
    <div className="card mb-2">
      <div className="p-4">
        <b>
          <RowBetween
            col1={
              <IconWithLabel
                icon="mdi mdi-calendar-alt"
                label={moment(receipt.timestamp).format("HH:mm, DD MMM YYYY ")}
              />
            }
            col2={receipt.payment.grandTotal}
          />
        </b>
        <RowBetween
          col1={
            <IconWithLabel
              icon="mdi mdi-account-box"
              label={receipt.member.name}
            />
          }
          col2={
            <span style={{ fontStyle: "italic", color: "grey" }}>
              by {receipt.therapist.name}
            </span>
          }
        />
        {/* <RowBetween
          col1={
            <IconWithLabel
              icon="mdi mdi-collection-item"
              label={<span style={{ color: "grey" }}>{itemsString}</span>}
            />
          }
        /> */}
        {definitionObj["suggestion"] && (
          <RowBetween
            col1={
              <IconWithLabel
                icon="mdi mdi-comment-alt-text"
                label={answerObj["suggestion"].text}
              />
            }
          />
        )}

        <div className="pt-3" />
        <Row3Col
          col1={
            <Rate
              title={definitionObj["program"].title}
              rate={answerObj["program"].number}
            />
          }
          col2={
            <Rate
              title={definitionObj["ambience"].title}
              rate={answerObj["ambience"].number}
            />
          }
          col3={
            definitionObj["cleanliness"] && (
              <Rate
                title={definitionObj["cleanliness"].title}
                rate={answerObj["cleanliness"].number}
              />
            )
          }
        />
        <Row3Col
          col1={
            <Rate
              title={definitionObj["receptionist"].title}
              rate={answerObj["receptionist"].number}
            />
          }
          col2={
            <Rate
              title={definitionObj["therapist"].title}
              rate={answerObj["therapist"].number}
            />
          }
          col3={
            <Rate
              title={definitionObj["overall"].title}
              rate={answerObj["overall"].number}
            />
          }
        />

        {/* {definitionObj["mj5gWmE2TM2L"] && (
          <div className="row m-0 ">
            <div className="col-6">{definitionObj["mj5gWmE2TM2L"].title}</div>
            <div className="col-6">
              {answerObj["mj5gWmE2TM2L"].boolean ? "Yes" : "No"}
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default ReceiptRateCard;

const RateRow = props => {
  return (
    <div className="row m-0 ">
      <div className="col-6">{props.title}</div>
      <div
        className="col-6"
        style={{ color: "orange", fontSize: 18, top: -10 }}
      >
        <StarRatings
          rating={props.rate}
          starRatedColor="orange"
          //   changeRating={this.changeRating}
          numberOfStars={5}
          name="rating"
          starDimension="20px"
          starSpacing="3px"
        />
      </div>
    </div>
  );
};

const RowBetween = ({ col1, col2, col3 }) => {
  return (
    <div className="row m-0 justify-content-between pb-1">
      <div>{col1}</div> 
      <div>{col2}</div>
      {col3 && <div>{col3}</div>}
    </div>
  );
};

const Row3Col = ({ col1, col2, col3 }) => {
  return (
    <div className="row m-0 pb-1">
      <div className="col-4 p-0">{col1}</div>
      <div className="col-4 p-0">{col2}</div>
      <div className="col-4 p-0">{col3}</div>
    </div>
  );
};

const IconWithLabel = ({ icon, label }) => {
  return (
    <div className="row m-0">
      <div style={{ paddingRight: 8 }}>
        <span class={icon} style={{ fontSize: 18, color: "grey" }} />
      </div>
      <div>{label}</div>
    </div>
  );
};

const Rate = ({ title, rate }) => {
  return (
    <div>
      <div>
        <b>{title}</b>
      </div>
      <div>
        <StarRatings
          rating={rate}
          starRatedColor="orange"
          //   changeRating={this.changeRating}
          numberOfStars={5}
          name="rating"
          starDimension="20px"
          starSpacing="3px"
        />
      </div>
    </div>
  );
};

const UnCompleteSatRate = ({ receipt ,   }) => {
  if(receipt.therapist === null || receipt.member === null){
    return null
  }
  return (
    <div class="card mb-2">
      <div class="p-4">

      <b>
          <RowBetween
            col1={
              <IconWithLabel
                icon="mdi mdi-calendar-alt"
                label={moment(receipt.timestamp).format("HH:mm, DD MMM YYYY ")}
              />
            }
            col2={receipt.payment.grandTotal}
          />
        </b>
        <RowBetween
          col1={
            <IconWithLabel
              icon="mdi mdi-account-box"
              label={receipt.member.name}
            />
          }
          col2={
            <span style={{ fontStyle: "italic", color: "grey" }}>
              by {receipt.therapist.name}
            </span>
          }
        />

        <p class='text-center'>UNCOMPLETED SAT_RATE</p>
     </div>
    </div>
  );
};
