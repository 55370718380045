import * as type from "../../types/spa.pos.setting";
// import moment from "moment";

let revertBillDate = window.localStorage.getItem("revertBillDate");
if (revertBillDate) {
  revertBillDate = new Date(revertBillDate);
} else {
  revertBillDate = new Date(); 
}

let revertBill = window.localStorage.getItem("revertBill");
if (revertBill === "true") {
  revertBill = true;
} else {
  revertBill = false;
}

const initState = {
  revertBill,
  revertBillDate
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case type.SET_REVERT_BILL:
      return { ...state, revertBill: action.payload };
    case type.SET_REVERT_BILL_DATE:
      return { ...state, revertBillDate: action.payload };
    default:
      return state;
  }
};

export default reducer;
