import _ from "lodash";
import documentClient from "../awsAPIs/dynamoDb";
import * as type from "../types/spa.room";

export const fetchSpaRooms = (cb, forceShopUid) => {
  return async (dispatch, getState) => {
    let { shop } = getState();
    var params = {
      TableName: "Spa_Rooms",
      KeyConditionExpression: "storeUid = :storeUid",
      ExpressionAttributeValues: {
        ":storeUid": forceShopUid || shop.detail.uid
      }
    };
    try {
      let res = await documentClient.query(params).promise();
      if (res.Items) {
        dispatch({
          type: type.FETCH_SPA_ROOM,
          payload: _.orderBy(res.Items, "row", "ASC")
        });
        cb && cb();
      }
    } catch (error) {
      console.log(error);
    }
  };
};
