import React, { useState } from "react";
import PaymentType from "./PaymentType";
import { connect } from "react-redux";
import * as computeSelector from "../PosCart/selector";
import * as spaSalesSelector from "../../shared/selector/spaSalesSelector";
import combineJobItemsToBillItems from "../../shared/functions/combineJobItemsToBillItems";

const PaymentCard = props => { 
  const { grandTotal, getChange } = props;
  return (
    <div className="card card-flat m-0">
      <div className="card-header card-header-divider">Payment</div>
      <div className="card-header">
        <span style={{ fontSize: 24 }}>GrandTotal</span>
        <span className="" style={{ float: "right", fontSize: 24 }}>
          {grandTotal}
        </span>
      </div>

      <div className="card-body">
        <PaymentType {...props} />
      </div>

      {getChange().int > 0 && (
        <div className="card-header" style={{ color: "blue" }}>
          <span style={{ fontSize: 24 }}>Change</span>
          <span className="" style={{ float: "right", fontSize: 24 }}>
            {getChange().string}
          </span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  let agentOptions = [];
  let entertainOptions = [];

  let mainAppState = null;
  let spaPos = null;

  if (state.mainAppStore) {
    mainAppState = state.mainAppStore.getState();

    agentOptions = spaSalesSelector.agentOptionsSelector(mainAppState);
    entertainOptions = spaSalesSelector.entertainOptionsSelector(mainAppState);
    spaPos = mainAppState.spa.pos;
  }

  return {
    grandTotal: computeSelector.grandTotalSelector(state),
    agentOptions,
    entertainOptions,
    spaPos,
    mainAppState,
    products: combineJobItemsToBillItems(
      state.items,
      state.promotionItems,
      state.qtyHolder,
      state.discountHolder
    ) 
  };
};

export default connect(mapStateToProps)(PaymentCard);
