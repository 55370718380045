import React, { useState, useEffect } from "react";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import _ from "lodash";
import ManageCustomerModal from "../../shared/components/ManageCustomerModal";
import moment from "moment";
// import Select from "react-select";
import Async from "react-select/async";
import Select from "react-select";
import countryOptions from "../../shared/exampleData/countryOptions";
import { connect } from "react-redux";
import swal from 'sweetalert';

function SpaPosCustomerSelect(props) {
  const [customers, setCustomers] = useState([]);
  const [customersProfiles, setCustomersProfiles] = useState([]);
  const [selectedCustomer, selectCustomer] = useState(null);

  // const [modalTimeStamp, setModalTimeStamp] = useState({});

  const {
    spaPos,
    memberMode,
    fetchVoucherList,
    setMatchVoucher,
    isRetail,
  } = props;

  useEffect(() => {
    // fetchCustomersPoints();

    fetchCustomers(10);

    if (!spaPos.member || _.isEmpty(spaPos.member)) return;

    //FETCH MEMBER VOUCHER LIST
    if (spaPos.member.type !== "temporary") {
      fetchVoucherList(spaPos.voucherOwner.uid, {
        onlyActive: true,
        onlyItem: true,
        owner: spaPos.voucherOwner,
      });
    }

    restoreNewMemberData(spaPos.member);
  }, [props.timestamp]);

  useEffect(() => {
    if (memberMode === 0) {
      let uid = `temp${moment().valueOf()}`;
      selectCustomer({
        name: "",
        uid: uid,
        label: "",
        value: uid,
        type: "temporary",
      });
    } else {
      selectCustomer(null);
    }

    props.actions.setSpaPosStepData("member", null);
  }, [memberMode]);

  useEffect(() => {
    // return function() {
    //   setPageData();
    // };
    //checkMemberExpired();
    setPageData();
  }, [selectedCustomer]);

  const setPageData = async () => {
    props.actions.setSpaPosStepData("member", { ...selectedCustomer });
  };



  const checkMemberExpired = () => {
    if (selectedCustomer !== null && selectedCustomer.expireDate !== undefined) {
      let dateNow = moment().toISOString()
      if (selectedCustomer.expireDate < dateNow) {
        swal("Member Expired", "", "error");
        selectCustomer(null)
      }
    }

  }

  const restoreNewMemberData = async (member) => {
    try {
      let tmpMember = await getMember(member);
      let memberOption = {
        ...tmpMember.Item,
        value: tmpMember.Item.uid,
        label: tmpMember.Item.name,
      };
      props.actions.setSpaPosStepData("member", memberOption);
      selectCustomer(memberOption);
    } catch (error) {
      console.log(error);
      selectCustomer(member);
    }
  };

  const getMember = (member) => {
    var params = {
      TableName: "Crm_Users",
      Key: {
        uid: member.uid,
      },
    };

    return documentClient.get(params).promise();
  };

  const fetchCustomers = (Limit) => {
    let params = {
      TableName: "Crm_Users",
      IndexName: "hqUid-index",
      KeyConditionExpression: "hqUid = :hqUid",
      ExpressionAttributeValues: {
        ":hqUid": props.linked,
      },
    };

    if (isRetail) {
      params.FilterExpression = "businessUnit = :businessUnit";
      params.ExpressionAttributeValues[":businessUnit"] = "retail";
    } else {
      params.FilterExpression = "businessUnit <> :businessUnit";
      params.ExpressionAttributeValues[":businessUnit"] = "retail";
    }

    if (Limit) {
      params.Limit = Limit;
    }
    documentClient.query(params, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        let ordered = _.orderBy(data.Items, "createAt", "desc");
        let options = getOptions(ordered);
        setCustomers(options);
      }
    });
  };

  const asyncSearchCustomer = (async function  (inputName, callback)  {

    if(inputName.length <= 2){
      return
    }
    let params = {
      TableName: "Crm_Users",
      IndexName: "hqUid-index",
      KeyConditionExpression: "hqUid = :hqUid",
      FilterExpression:
        "contains(#name, :name) or contains(memberId, :memberId) or contains(mobileLocal, :memberId)",
      ExpressionAttributeNames: {
        "#name": "name",
        // "#memberId": "memberId"
      },
      ExpressionAttributeValues: {
        // ":memberId": inputName,
        ":name": (inputName || "").toUpperCase(),
        ":memberId": (inputName || "").toUpperCase(),
        ":hqUid": props.linked,
        // ":businessUnit": "retail",
      },
    };

    var lasyKey = null;
    var res = [];
    try {


      do {
        let a = lasyKey ? (params.ExclusiveStartKey = lasyKey) : null;
        var data = await documentClient.query(params).promise()
        res = res.concat(data.Items);
        lasyKey = data.LastEvaluatedKey;
      } while (data.LastEvaluatedKey);
    } catch (error) {
      if(error){
        callback([]);
        return console.log(error);
      }
    }

     

    let tmpMember = [];
    if (isRetail) {
      tmpMember = _.filter(
        res,
        (m) => m.businessUnit && m.businessUnit === "retail"
      );
    } else {
      tmpMember = _.filter(
        res,
        (m) =>
          !m.businessUnit || (m.businessUnit && m.businessUnit != "retail")
      );
    }

    let ordered = _.orderBy(tmpMember, "createAt", "desc");
    let options = getOptions(ordered);
    callback(options);


   
  });
  
  function getOptions(list) {
    let options = [];
    _.map(list, (i) => {
      let label = i.name;
      if (i.memberId) {
        label += ` (${i.memberId})`;
      }
      if (i.mobileLocal) {
        label += ` (${i.mobileLocal})`;
      }
      options.push({
        ...i,
        value: i.uid,
        label,
      });
    });
    return options;
  }

  const fetchCustomersPoints = () => {
    let params = {
      TableName: "Crm_User_Profile",
    };

    documentClient.scan(params, (err, data) => {
      if (err) {
        console.log(err);
        // alert(err)
      } else {
        setCustomersProfiles(data.Items);
      }
    });
  };

  const getPointsAndTier = (uid) => {
    var f = _.find(customersProfiles, (c) => c.userUid === uid);
    if (f) {
      return (
        "" +
        f.tier.toUpperCase() +
        ", Points: " +
        f.pointsBalance.toLocaleString()
      );
    }
    return null;
  };

  const handleSelectCustomer = (customer) => {
    if (customer.value === "create") {
      window.$("#manageCustomerModal").modal("show");
      return;
    }

    fetchVoucherList(customer.uid, {
      onlyActive: true,
      onlyItem: true,
      owner: customer,
    });
    setMatchVoucher({});
    setTimeout(() => {
      setMatchVoucher(null);
    }, 300);
    selectCustomer(customer);
  };

  return [
    <ManageCustomerModal
      {...props}
      title="Create Customer"
      // timestamp={props.timestamp}
      fetchCustomers={fetchCustomers}
      selectCustomer={selectCustomer}
    // props.actions.setSpaPosStepData("member", { ...selectedCustomer });
    />,

    memberMode === 1 ? (
      <div>
        <Async
          value={selectedCustomer}
          onChange={handleSelectCustomer}
          // options={[{ label: "+ Create Customer", value: "create" }, ...customers]}
          // onMenuOpen={fetchCustomers}
          cacheOptions
          // loadOptions={loadOptions}
          loadOptions={asyncSearchCustomer}
          // defaultOptions={[
          //   { label: "+ Create Customer", value: "create" },
          //   ...customers
          // ]}
          defaultOptions={customers}
        // onInputChange={handleSelectCustomer}
        />
        <div style={{ color: "grey", fontSize: 14, paddingTop: 3 }}>
          ค้นหาด้วยชื่อ รหัสสมาชิก หรือหมายเลขโทรศัพท์
        </div>
      </div>
    ) : (
        <div className="row m-0">
          <div className="col-7 pl-0 pr-1">
            <input
              type="text"
              class="form-control"
              value={(selectedCustomer && selectedCustomer.name) || ""}
              placeholder="กรอกชื่อลูกค้า"
              onChange={(e) => {
                let upper = e.target.value.toUpperCase();
                selectCustomer({
                  ...selectedCustomer,
                  name: upper,
                  label: upper,
                });
              }}
            />
          </div>
          <div className="col-5 pr-0 pl-1">
            <Select
              // className="form-control"
              minMenuHeight={46}
              value={(selectedCustomer && selectedCustomer.countryObj) || null}
              onChange={(data) => {
                selectCustomer({
                  ...selectedCustomer,
                  country: data.value,
                  countryObj: data,
                });
              }}
              options={countryOptions}
            />
          </div>
        </div>
      ),
  ];
}

const mapStateToProps = (state) => {
  return {
    isRetail: state.appState.isRetail,
  };
};

export default connect(mapStateToProps)(SpaPosCustomerSelect);
