import React, { useState } from "react";
import Select from "react-select";

const options = [
  { label: "RICE", value: "Rice" },
  { label: "RICE", value: "Rice" },
  { label: "RICE", value: "Rice" },
  { label: "RICE", value: "Rice" },
  { label: "RICE", value: "Rice" },
  { label: "RICE", value: "Rice" },
  { label: "RICE", value: "Rice" }
];
function InPending() {
  const [count, setCount] = useState(0);
  return (
    <div className="main-content" style={{ backgroundColor: "#eeeeee" }}>
      <ActionBar />

      <div className="row mt-8">
        <div className="col-md-8 pt-0">
          <Left />
          <Left2 />
          
        </div>

        <div className="col-md-4">
          <Right />
        </div>
      </div>
    </div>
  );
}

export default InPending;

function ActionBar() {
  return (
    <div
      className="d-flex flex-row justify-content-between align-items-stretch"
      style={{
        backgroundColor: "white",
        margin: -25,
        height: 48,
        // width: "calc(100% - 230px)",
        zIndex: 99,
        // position: "fixed",
        borderBottom: "1px solid rgb(212, 212, 212)"
      }}
    >
      <div className="mt-2 " style={{ backgroundColor: " ", paddingLeft: 25 }}>
        <button
          style={{ widht: 80 }}
          onClick={() => {}}
          className="btn btn-rounded btn-space btn-secondary"
        >
          <i className="icon icon-left mdi mdi-arrow-left" />
          Back
        </button>
        <label style={{ marginLeft: 12, marginTop: 8, marginRight: 8 }}>
          Current Status : <strong style={{}}>Pending</strong>{" "}
        </label>
      </div>
      <div
        className=" mt-2 d-flex  justify-content-end"
        style={{ backgroundColor: " ", marginRight: 25 }}
      >
        <label style={{ marginLeft: 24, marginTop: 8, marginRight: 8 }}>
          Export
        </label>

        <button
          onClick={() => {}}
          className="btn btn-rounded btn-space btn-secondary"
        >
          <i className="icon icon-left mdi mdi-border-all" /> Action1
        </button>
        <button
          onClick={() => {}}
          className="btn btn-rounded btn-space btn-secondary"
        >
          <i className="icon icon-left mdi mdi-collection-pdf" /> Action2
        </button>
        <button
          onClick={() => {}}
          className="btn btn-rounded btn-space btn-secondary"
        >
          <i className="icon icon-left mdi mdi-mail-send" />
          Action3
        </button>
      </div>
    </div>
  );
}

function Left2() {
  const [sales, setSales] = useState(null);

  return (
    <div class=" p-5 card card-border-color card-border-color-primary">
      <div class="card-header">
        <div class="row">
          <div class="col-9">
            <div class="row">
              <div class="col-6">
                <div
                  className="counter counter-big"
                  //   style={isColumn ? { marginTop: 30, marginLeft: 0 } : {}}
                >
                  <div className="value">
                    {" "}
                    <Select
                      value={sales}
                      onChange={setSales}
                      options={options}
                    />
                  </div>
                  <div className="desc">Member</div>
                </div>
              </div>
              <div class="col-6">
                <div className="counter counter-big">
                  <div className="value">16:30</div>
                  <div className="desc">Start Time</div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <div className="counter counter-big">
                  <div className="value">
                    <Select
                      value={sales}
                      onChange={setSales}
                      options={options}
                    />
                  </div>
                  <div className="desc">Therpist</div>
                </div>
              </div>
              <div class="col-6">
                <div className="counter counter-big">
                  <div className="value">
                    <Select
                      value={sales}
                      onChange={setSales}
                      options={options}
                    />
                  </div>
                  <div className="desc">Agent</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="col-12">
              <div className="counter counter-big">
                {/* <div className="desc">Channel</div> */}
                <div class=" ">
                        <div class="custom-control custom-checkbox">
                          <input class="custom-control-input" type="checkbox" checked="" id="check3"/>
                          <label class="custom-control-label" for="check3">Option 1</label>
                        </div>
                        <div class="custom-control custom-checkbox">
                          <input class="custom-control-input" type="checkbox" checked="" id="check3"/>
                          <label class="custom-control-label" for="check3">Option 1</label>
                        </div>
                        <div class="custom-control custom-checkbox">
                          <input class="custom-control-input" type="checkbox" checked="" id="check3"/>
                          <label class="custom-control-label" for="check3">Option 1</label>
                        </div>
                        <div class="custom-control custom-checkbox">
                          <input class="custom-control-input" type="checkbox" checked="" id="check3"/>
                          <label class="custom-control-label" for="check3">Option 1</label>
                        </div>
                        <div class="custom-control custom-checkbox">
                          <input class="custom-control-input" type="checkbox" id="check4"/>
                          <label class="custom-control-label" for="check4">Option 2</label>
                        </div>
                        <div class="custom-control custom-checkbox">
                          <input class="custom-control-input" type="checkbox" id="check5"/>
                          <label class="custom-control-label" for="check5">Option 3</label>
                        </div>
                      </div>
                {/* <div className="value">21432434</div>
                <div className="value">21432434</div>
                <div className="value">21432434</div>
                <div className="value">21432434</div> */}

                <div className="desc">Channel</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div
              className="counter counter-big"
              //   style={isColumn ? { marginTop: 30, marginLeft: 0 } : {}}
            >
              <div className="value">
                {" "}
                <Select value={sales} onChange={setSales} options={options} />
              </div>
              <div className="desc">Member</div>
            </div>
          </div>
          <div class="col-6">
            <div className="counter counter-big">
              <div className="value">16:30</div>
              <div className="desc">Start Time</div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-4">
            <div className="counter counter-big">
              <div className="value">
                <Select value={sales} onChange={setSales} options={options} />
              </div>
              <div className="desc">Therpist</div>
            </div>
          </div>
          <div class="col-4">
            <div className="counter counter-big">
              <div className="value">
                <Select value={sales} onChange={setSales} options={options} />
              </div>
              <div className="desc">Agent</div>
            </div>
          </div>

          <div class="col-4">
            <div className="counter counter-big">
              <div className="value">21432434 wewrwer</div>
              <div className="desc">Channel</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <p>
          {" "}
          Quisque gravida aliquam diam at cursus, quisque laoreet ac lectus a
          rhoncusac tempus odio.{" "}
        </p>
        <p>
          Aliquam posuere volutpat turpis, ut euimod diam pellentesque at. Sed
          sit amet nulla a dui dignisim euismod. Morbi luctus elementum dictum.
          Donec convallis mattis elit id varius. Quisque facilisis sapien quis
          mauris, erat condimentum.
        </p>
      </div>
    </div>
  );
}

function Left({}) {
  return (
    <div class=" p-5 card card-border-color card-border-color-primary">
      <div class="card-header">
        <form>
          <div class="form-group row pos">
            <label
              class="col-12 col-sm-3   text-sm-right"
              for="inputText3"
            >
              Member
            </label>
            <div class="col-12 col-sm-8 col-lg-6">
              <input class="form-control" id="inputText3" type="text" />
            </div>
          </div>

          <div class="form-group row pos">
            <label
              class="col-12 col-sm-3   text-sm-right"
              for="inputExtraSmall"
            >
              Therprist
            </label>
            <div class="col-12 col-sm-8 col-lg-6 pt-1">
              <input
                class="form-control form-control-xs"
                id="inputExtraSmall"
                type="text"
                value="Extra small input"
              />
            </div>
          </div>

          <div class="form-group row pos">
            <label
              class="col-12 col-sm-3   text-sm-right"
              for="inputExtraSmall"
            >
              Agent
            </label>
            <div class="col-12 col-sm-8 col-lg-6 pt-1">
              <input
                class="form-control form-control-xs"
                id="inputExtraSmall"
                type="text"
                value="Extra small input"
              />
            </div>
          </div>

          <div class="form-group row pos">
            <label
              class="col-12 col-sm-3   text-sm-right"
              for="inputExtraSmall"
            >
              Start Time
            </label>
            <div class="col-12 col-sm-8 col-lg-6 pt-1">
              <input
                class="form-control form-control-xs"
                id="inputExtraSmall"
                type="text"
                value="Extra small input"
              />
            </div>
          </div>

          <div class="form-group row pos">
            <label
              class="col-12 col-sm-3   text-sm-right"
              for="inputExtraSmall"
            >
              Channel
            </label>
            <div class="col-12 col-sm-8 col-lg-6 pt-1">
              <input
                class="form-control form-control-xs"
                id="inputExtraSmall"
                type="text"
                value="Extra small input"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="card-body">
        <p>
          {" "}
          Quisque gravida aliquam diam at cursus, quisque laoreet ac lectus a
          rhoncusac tempus odio.{" "}
        </p>
        <p>
          Aliquam posuere volutpat turpis, ut euimod diam pellentesque at. Sed
          sit amet nulla a dui dignisim euismod. Morbi luctus elementum dictum.
          Donec convallis mattis elit id varius. Quisque facilisis sapien quis
          mauris, erat condimentum.
        </p>
      </div>
    </div>
  );
}

function Right({}) {
  return (
    <div class=" p-5 card card-border-color card-border-color-primary">
      <div class="card-header">asdsdas</div>
      <div class="card-body">
        <p>
          {" "}
          Quisque gravida aliquam diam at cursus, quisque laoreet ac lectus a
          rhoncusac tempus odio.{" "}
        </p>
        <p>
          Aliquam posuere volutpat turpis, ut euimod diam pellentesque at. Sed
          sit amet nulla a dui dignisim euismod. Morbi luctus elementum dictum.
          Donec convallis mattis elit id varius. Quisque facilisis sapien quis
          mauris, erat condimentum.
        </p>
      </div>
    </div>
  );
}
