import React, { useState, useEffect } from "react";
import * as aws from "../../shared/awsAPIs/instance";

function useFetchDynamodb(_TABLE_NAME, initShopId, rangeKey, cb) {
  const [items, setItems] = useState([]);
  const [TABLE_NAME, setTABLE_NAME] = useState(_TABLE_NAME);
  const [shopId, setSHOP_ID] = useState(initShopId);
  async function fetchReceipts() {
    var params = {
      TableName: TABLE_NAME,
      KeyConditionExpression: "entityUid = :hkey",
      ExpressionAttributeValues: {
        ":hkey": shopId
      }
    };

    var data = await aws.documentClient.query(params).promise();
    setItems(data.Items || []);
    cb && cb(data.Items|| []);
  }

  useEffect(() => {
    fetchReceipts();
  }, []);

  return {
    items,
    setItems,
    reload: fetchReceipts
  };
}

export default useFetchDynamodb;
