import React from "react";
import _ from "lodash";
import moment from "moment";

const MODAL_NAME = "memberVoucherModal";

function MemberVoucherModal(props) {
  const { memberName, voucherName, voucherCampaignUid } = props;

  return (
    <div
      class="modal"
      id={MODAL_NAME}
      tabindex="-1"
      role="dialog"
      aria-labelledby={`${MODAL_NAME}Label`}
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id={`${MODAL_NAME}Label`}>
              <b>
                {memberName} | {voucherName} (ID: {voucherCampaignUid})
              </b>
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-sm table-hover table-bordered table-striped">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Branch</th>
                  <th>Quantity</th>
                  <th>Remain</th>
                </tr>
              </thead>
              <tbody>
                {_.times(10, idx => {
                  return (
                    <tr>
                      <td>{moment().format("DD/MM/YYYY HH:mm")}</td>
                      <td>Branch A</td>
                      <td>1</td>
                      <td>{10 - (idx + 1)}/10</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div class="modal-footer pt-2">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            {/* <button type="button" class="btn btn-primary">
                  Save changes
                </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberVoucherModal;
