import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ActionBar(props) {
  const { match, isBuyProduct, viewPanel, setViewPanel } = props;

  const { params = {} } = match;
  let backNumber = params.backNumber;
  let backPath = "/pos";
  if (backNumber) {
    backPath += `/${backNumber}`;
  }

  //AUTO CLOSE REVERT BILL
  // useEffect(() => {
  //   props.actions.setRevertBill(false);
  // }, []);

  const getRevertBill = () => {
    return props.spaPosSetting.revertBill;
  };

  const setRevertBill = e => {
    e.preventDefault();
    e.stopPropagation();
    props.actions.setRevertBill(!getRevertBill());
  };

  return (
    <div
      // justify-content-between
      className="d-flex flex-row align-items-stretch"
      style={{
        backgroundColor: "white",
        // margin: -25,
        // height: 48,
        // height: 100,
        // width: "calc(100% - 230px)",
        zIndex: 99,
        // position: "fixed",
        borderBottom: "1px solid rgb(212, 212, 212)"
      }}
    >
      <div
        className="mt-2 align-self-center"
        style={{ backgroundColor: " ", paddingLeft: 25 }}
      >
        <button
          style={{ width: 80 }}
          onClick={() => {
            props.history.push(backPath);
            // props.history.goBack();
          }}
          className={`btn btn-rounded btn-space btn-secondary`}
        >
          <i className="icon icon-left mdi mdi-arrow-left" />
          Back
        </button>

        {/* <button
          style={{ width: 80 }}
          onClick={() => props.setPage("place")}
          className={`btn btn-rounded btn-space btn-${
            props.page === "place" ? "primary" : "secondary"
          }`}
        >
          Place
        </button>
        <label style={{ marginLeft: 12, marginTop: 8, marginRight: 8 }}>
          Current Status : <strong style={{}}>Pending</strong>{" "}
        </label> */}

        {isBuyProduct && (
          <button
            style={{ width: 160, marginLeft: 36 }}
            onClick={() => setViewPanel("member")}
            className={`btn btn-rounded btn-space btn-${
              props.viewPanel === "member" ? "primary" : "secondary"
            }`}
          >
            Member
          </button>
        )}

        {isBuyProduct && (
          <button
            style={{ width: 160 }}
            onClick={() => setViewPanel("cart")}
            className={`btn btn-rounded btn-space btn-${
              props.viewPanel === "cart" ? "primary" : "secondary"
            }`}
          >
            Cart
          </button>
        )}
      </div>
      {/* <div
        className=" mt-2 d-flex  justify-content-end"
        style={{ backgroundColor: " ", marginRight: 25 }}
      >
        <label style={{ marginLeft: 24, marginTop: 8, marginRight: 8 }}>
          Export
        </label>

        <button
          onClick={props.saveToDatabase}
          className="btn btn-rounded btn-space btn-secondary"
        >
          <i className="icon icon-left mdi mdi-border-all" /> Save
        </button>
       
      </div> */}

      {/* <div className="mt-2 ml-2 align-self-center">
        <label
          class="custom-control custom-checkbox custom-control-inline"
          onClick={setRevertBill}
        >
          <input
            class="custom-control-input"
            type="checkbox"
            checked={getRevertBill()}
          />
          <span
            class="custom-control-label custom-control-color"
            style={
              getRevertBill()
                ? { color: "red", fontWeight: "bold", fontSize: 17 }
                : { fontSize: 17 }
            }
          >
            บันทึกบิลย้อนหลัง
          </span>
        </label>
      </div> */}

      {getRevertBill() && (
        <div className="mt-2 ml-2 mb-2">
          <DatePicker
            selected={props.spaPosSetting.revertBillDate}
            onChange={date => {
              props.actions.setRevertBillDate(date);
            }}
            maxDate={new Date()}
            // showTimeSelect
            // timeIntervals={5}
            // timeFormat="HH:mm"
            // dateFormat="dd/MM/yyyy HH:mm"
            dateFormat="dd/MM/yyyy"
            className="form-control"
          />
        </div>
      )}
    </div>
  );
}

export default ActionBar;

const getStartOfDay = () => {
  let date = new Date();
  date.setHours(0, 0);
  return date;
};
