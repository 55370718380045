import React, { Component } from "react";
import AddInventoryItem from "./AddInventoryItem";
import AddInventoryGroupAndType from "./AddInventoryGroupAndType";
import _ from "lodash";
import { Route, Switch } from "react-router-dom";
import Overview from "./Overview";
class Inventory extends Component {
  render() {
    let { match } = this.props;
    return (
      <Switch>
        <Route 
          path={`${match.url}/addType`}
          render={props => <AddInventoryGroupAndType {...props} mode="type" />}
        />
        <Route
          path={`${match.url}/addGroup/:typeUid`}
          render={props => <AddInventoryGroupAndType {...props} mode="group" />}
        />
        <Route
          path={`${match.url}/addItem/:groupUid`}
          component={AddInventoryItem}
        />
        <Route path={`${match.url}`} component={Overview} />
      </Switch>
    );
  }
}

export default Inventory;
