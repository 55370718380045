import React, { useState, useEffect } from "react";
import LeftAsideContainer from "../../shared/components/LeftAsideContainer";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle
} from "react-sortable-hoc";
import _ from "lodash";
import swal from "sweetalert";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import randomUid from "../../shared/randomUid";
import moment from "moment";
import async from "async";

const DragHandle = SortableHandle(() => (
  <i className="icon icon-left mdi mdi-sort-amount-desc" />
)); // This can be any component you want

function RoomPage(props) {
  const { rooms, fetchRooms } = props;

  const createRoom = () => {
    swal({
      title: "Create Room",
      text: "กรอกชื่อ Room",
      content: "input",
      buttons: ["Cancel", "Create"]
    }).then(name => {
      if (!name) throw null;
      dynamoPutRoom(name);
    });
  };
  const dynamoPutRoom = async name => {
    var params = {
      TableName: "Spa_Rooms",
      Item: {
        storeUid: props.shop.uid,
        roomUid: randomUid(6),
        name,
        row: moment().valueOf()
      }
    };
    try {
      await documentClient.put(params).promise();
      fetchRooms();
    } catch (error) {
      console.log(error);
    }
  };
  const deleteRoom = room => {
    swal({
      title: `ลบ ${room.name} ?`,
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(async willDelete => {
      if (willDelete) {
        var params = {
          TableName: "Spa_Rooms",
          Key: {
            storeUid: room.storeUid,
            roomUid: room.roomUid
          }
        };
        try {
          await documentClient.delete(params).promise();
          fetchRooms();
          swal("สำเร็จ", "ลบ Room สำเร็จ", "success");
        } catch (error) {
          console.log(error);
        }
      }
    });
  };
  const editRoomName = async room => {
    let params = {
      // title: ``,
      text: "เปลี่ยนชื่อ Room",
      content: {
        element: "input",
        attributes: {
          inputValue: room.name || ""
        }
      },
      buttons: ["Cancel", "Submit"],
      buttons: true
    };

    swal(params).then(name => {
      if (name) {
        dynamoUpdateRoom(room, name);
      }
    });
    setTimeout(() => {
      window.$("input.swal-content__input").val(room.name || "");
    }, 10);
  };
  const dynamoUpdateRoom = async (room, name) => {
    var params = {
      TableName: "Spa_Rooms",
      Key: { storeUid: props.shop.uid, roomUid: room.roomUid },
      UpdateExpression: "set #name = :name",
      ExpressionAttributeNames: { "#name": "name" },
      ExpressionAttributeValues: {
        ":name": name
      }
    };
    try {
      await documentClient.update(params).promise();
      fetchRooms();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <LeftAsideContainer enableTopSpace={true}>
      <div style={{ height: 600, overflowY: "scroll" }}>
        <Row
          col1={<h3 className="m-0">Room</h3>}
          col2={
            <button className="btn btn-space btn-primary" onClick={createRoom}>
              Create
            </button>
          }
        />
        <div className="dropdown-divider" />
        <div className="mt-3" />
        <SortableComponent
          {...props}
          items={rooms}
          fetchRooms={fetchRooms}
          deleteRoom={deleteRoom}
          editRoomName={editRoomName}
        />
      </div>
    </LeftAsideContainer>
  );
}

class SortableComponent extends React.Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    let newItems = arrayMove(this.props.items, oldIndex, newIndex);

    let index = 1;
    async.eachSeries(
      newItems,
      (item, cb) => {
        let params = {
          TableName: "Spa_Rooms",
          Key: {
            storeUid: item.storeUid,
            roomUid: item.roomUid
          },
          UpdateExpression: "set #row = :newRow",
          ExpressionAttributeNames: { "#row": "row" },
          ExpressionAttributeValues: {
            ":newRow": index
          }
        };
        documentClient.update(params, function(err, data) {
          if (err) {
            console.log(err);
          } else {
            index++;
            cb();
          }
        });
      },
      err => {
        if (err) {
          console.log(err);
        } else {
          this.props.fetchRooms();
          // swal("สำเร็จ !", "Reorder Complete", "success");
        }
      }
    );
  };
  render() {
    return (
      <SortableList
        {...this.props}
        useDragHandle={true}
        items={this.props.items}
        onSortEnd={this.onSortEnd}
      />
    );
  }
}

const SortableList = SortableContainer(props => {
  return (
    <div className="list-group d-flex">
      {_.map(props.items, (value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          value={value}
          {...props}
        />
      ))}
    </div>
  );
});

const SortableItem = SortableElement(props => {
  let { selectedRoom, value } = props;
  return (
    // active
    <span
      href={`#${value.roomUid}`}
      class={
        "list-group-item  list-group-item-action " +
        (value.roomUid === selectedRoom ? "active" : "")
      }
      onClick={e => {
        e.preventDefault();
        props.setSelectedRoom(value.roomUid);
      }}
      style={{
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 10,
        paddingBottom: 10
      }}
    >
      <div className="row">
        <div className="col-md-1">
          <DragHandle />
        </div>
        <div className="col-md-6">
          <span
            className="text"
            style={{ color: value.roomUid === selectedRoom ? "white" : "" }}
          >
            {value.name}
          </span>
        </div>
        <div className="col-md-4">
          <div className="row justify-content-between">
            <div />
            <div>
              <a
                href="#"
                style={{ width: 24 }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.editRoomName(value);
                }}
              >
                <i className="icon mdi mdi-edit" />
              </a>
              <a
                href="#"
                style={{ width: 24 }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.deleteRoom(value);
                }}
              >
                <i className="icon mdi mdi-delete" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
});

const Row = ({ col1, col2 }) => {
  return (
    <div className="row m-0 mb-3 justify-content-between">
      <div className="align-self-center">{col1}</div>
      <div>{col2}</div>
    </div>
  );
};

export default RoomPage;
