import React, { useState, useEffect, useRef } from "react";
import swal from "sweetalert";
import _ from "lodash";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomeSelect.css";

const bankList = [
  {
    label: `SCB, บริษัท ดีวานา เวลเนส จำกัด, 038-471039-3`,
    bank: "SCB",
    ownerName: "บริษัท ดีวานา เวลเนส จำกัด",
    accountNo: "038-471039-3",
    value: "038-471039-3"
  },
  {
    label: `SCB, บริษัท ดีวีเอ็น อินเตอร์เนชั่นแนล จำกัด, 246-217770-4`,
    bank: "SCB",
    ownerName: "บริษัท ดีวีเอ็น อินเตอร์เนชันแนล จำกัด",
    accountNo: "246-217770-4",
    value: "246-217770-4"
  },


  {
    label: `SCB, บริษัท ดีวานา สปา จำกัด, 246-217769-9`,
    bank: "SCB",
    ownerName: "บริษัท ดีวานา สปา จำกัด",
    accountNo: "246-217769-9",
    value: "246-217769-9"
  },
   
  {
    label: `BBL, บริษัท ดีวานา สปา จำกัด, 197-051733-8`,
    bank: "BBL",
    ownerName: "บริษัท ดีวานา สปา จำกัด",
    accountNo: "197-051733-8",
    value: "197-051733-8"
  },
  {
    label: `BBL, บริษัท ดี วี เอ็น อินเตอร์เนชันแนล จำกัด, 197-0-63535-3`,
    bank: "BBL",
    ownerName: "บริษัท ดี วี เอ็น อินเตอร์เนชันแนล จำกัด",
    accountNo: "197-0-63535-3",
    value: "197-0-63535-3"
  },
  
]

function PaymentType(props) {
  const {
    setAmount,
    paymentTypes,
    setPayments,
    grandTotal = 0,
    amount = 0,
    numInput,
    setNumInput
  } = props;

  const { jobUid } = props.spaPos;

  const payments = useMultipleInput([]);
  const latestPayments = useRef(payments);
  const [count, setCount] = useState(0);

  function addPaymentType() {
    setNumInput("");
    if (paymentTypes[0]) {
      payments.addRow({ ...paymentTypes[0], amount: 0 });
    }
  }

  useEffect(() => {
    grandTotal > 0 && addPaymentType();
    // listenPrepaid();
  }, []);

  useEffect(() => {
    // if (stopRender) return;
    try {
      payments.onChangeKey("amount", numInput, payments.value.length - 1);
      latestPayments.current = payments;
    } catch (err) {}
  }, [numInput]);

  useEffect(() => {
    if (!setAmount) return;
    //handle sum amount
    let sum = 0;
    _.forEach(payments.value, val => {
      let tmpAmount = parseInt(val.amount);
      if (val.amount === "" || _.isNaN(tmpAmount)) return;
      sum += tmpAmount;
    });
    setAmount(sum);
    setPayments(payments.value);
  }, [payments.value]);

  function handleSetAmount(text, index) {
    payments.onChangeKey("amount", text, index);
    if (index === payments.value.length - 1) {
      setNumInput(text);
    }
  }

  const pickPaymentType = (e, index) => {
    let uid = e.target.value;

    // let currentPayments = latestPayments.current;
    // let paymentsValues = currentPayments.getValue();

    //VALIDATE PREPAID ONLY ONCE
    let findExistPrepaid = _.find(
      payments.value,
      payment => payment.uid === "prepaid001"
    );

    //BLOCK DELETE PAID PREPAID TYPE
    if (uid === "prepaid001" && findExistPrepaid) {
      swal(
        "Only one prepaid at a Time !",
        "สามารถจ่ายแบบ Prepaid ได้ 1 ครั้งต่อ 1 ใบเสร็จ",
        "error"
      );

      return;
    }

    //BLOCK DELETE PAID PREPAID TYPE
    let tmpAmount = parseInt(payments.value[index].amount);
    if (payments.value[index].uid === "prepaid001" && tmpAmount > 0) {
      swal(
        "หากต้องการยกเลิกรายการ Prepaid กรุณาคลิกที่ปุ่ม Cancel Prepaid",
        "",
        "error"
      );
      return;
    }

    let picked = _.find(paymentTypes, type => type.uid === uid);
    if (!picked.type) {
      payments.handleSelect(picked.name, index, { uid: picked.uid });

      // payments.removePayload(index);
      return;
    }
    if (picked.type === "cityLedger" || picked.type === "entertain") {
      payments.handleSelect(picked.name, index, {
        type: picked.type,
        isAddPayload: true,
        uid: picked.uid
      });

      // setTimeout(() => {
      //   payments.addPayload(index, picked.type);
      // }, 500);
    }
  };

  return (
    <div className="list-group d-flex">
      {_.map(payments.value, (payment, index) => {
        return (
          <a className="list-group-item d-flex  flex-column ">
            {/* list-group-item-action  */}
            <div className="row mb-1">
              <div className="col-3">Type</div>
              <div className="col-9">
                <select
                  className="form-control"
                  value={payment.uid}
                  onChange={e => pickPaymentType(e, index)}
                >
                  {_.map(paymentTypes, line => {
                    return (
                      <option
                        value={line.uid}
                        selected={line.uid === payment.uid}
                      >
                        {line.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-3">Amount</div>
              <div className="col-9">
                <input
                  className="form-control"
                  type="number"
                  value={payment.amount}
                  onChange={e => handleSetAmount(e.target.value, index)}
                  disabled={payment.uid === "prepaid001"}
                />
              </div>
            </div>

            {payment.payload && payment.type === "cityLedger" && (
              <div className="row mt-1">
                <div className="col-3 align-self-center">Mode</div>
                <div class="col-9 form-check">
                  <label
                    class="custom-control custom-radio custom-control-inline mt-3 mb-3"
                    onClick={() => {
                      payments.onChangeKey("mode", "billing", index);
                    }}
                  >
                    <input
                      class="custom-control-input"
                      type="radio"
                      name="radio-inline"
                      checked={payment.mode === "billing"}
                    />
                    <span class="custom-control-label">Billing</span>
                  </label>
                  <label
                    class="custom-control custom-radio custom-control-inline"
                    onClick={() => {
                      payments.onChangeKey("mode", "transfered", index);
                    }}
                  >
                    <input
                      class="custom-control-input"
                      type="radio"
                      name="radio-inline"
                      checked={payment.mode === "transfered"}
                    />
                    <span class="custom-control-label">Transfered</span>
                  </label>
                </div>
              </div>
            )}

            {payment.payload && (
              <div className="row mt-1">
                <div className="col-3">
                  {payment.type === "cityLedger" ? "Agent" : "Entertain"}
                </div>
                <div className="col-9">
                  {/* <input className="form-control" type="text" /> */}
                  <Select
                    value={payment.payload}
                    onChange={payload =>
                      payments.onChangeKey("payload", payload, index)
                    }
                    options={
                      payment.type === "cityLedger"
                        ? props.agentOptions
                        : props.entertainOptions
                    }
                    placeholder={`Select ${
                      payment.type === "cityLedger" ? "Agent" : "Entertain"
                    }`}
                  />
                </div>
              </div>
            )}
            {/* BANK */}
            {payment.payload && payment.type === "cityLedger" && payment.mode== "transfered" && (
              <div className="row mt-1">
                <div className="col-3">Bank</div>
                <div class="col-9 form-check">
                  <Select
                    value={payment.bank}
                    onChange={bank => payments.onChangeKey("bank", bank, index)}
                    style={{ height: 200 }}
                    // styles={customStyles()}
                    options={bankList}
                  />
                  {/* <select
                    className="form-control"
                    value={payment.bank}
                    onChange={e =>
                      payments.onChangeKey("bank", e.target.value, index)
                    }
                  >
                    <option value="none">เลือกธนาคาร</option>
                    <option>KBANK,xxx-xxx-xxxx</option>
                    <option>SCB,xxx-xxx-xxxx</option>
                    <option>KTB,xxx-xxx-xxxx</option>
                  </select> */}
                </div>
              </div>
            )}
            {/* BANK  Date*/}
            {payment.payload && payment.type === "cityLedger" && payment.mode== "transfered" && (
              <div className="row mt-1">
                <div className="col-3">Date</div>
                <div class="col-9 form-check">
                  <DatePicker
                    selected={Date.parse(payment.date)}
                    onChange={date =>
                      payments.onChangeKey(
                        "date",
                        moment(date).toISOString(),
                        index
                      )
                    }
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                </div>
              </div>
            )}

            <div className="row mt-1">
              <div className="col-3">Remark</div>
              <div className="col-9">
                <input
                  className="form-control"
                  type="text"
                  value={payment.remark || ""}
                  onChange={e => {
                    let text = e.target.value;
                    if (text === "") {
                      text = null;
                    }
                    payments.onChangeKey("remark", text, index);
                  }}
                />
              </div>
            </div>
            {index !== 0 && (
              <div className="mt-2">
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    payments.removeRow(index);

                    if (index === payments.value.length - 1) {
                      let text = payments.value[index - 1].amount;
                      setTimeout(() => {
                        setNumInput(text);
                      }, 150);
                    }
                  }}
                  style={{ color: "red" }}
                >
                  Remove
                </a>
              </div>
            )}
          </a>
        );
      })}
      {/* AMOUNT เกิน Grand Total */}
      {amount < grandTotal && grandTotal > 0 && (
        <div className="mt-2">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              addPaymentType();
            }}
          >
            <span className="mdi mdi-plus" /> Payment Type
          </a>
        </div>
      )}
      {grandTotal === 0 && (
        <span style={{ fontSize: 23, fontWeight: "bold" }}>
          No more payment needed. Click{" "}
          <span style={{ color: "green" }}>PAY</span> to proceed.
        </span>
      )}
    </div>
  );
}

function useMultipleInput(initValue) {
  const [value, setValue] = useState(initValue || []);

  function onChangeKey(key, data, index) {
    let newVal = _.cloneDeep(value);
    newVal[index][key] = data;
    //CAN'T EDIT PREPAID DATA
    if (key === "amount" && newVal[index].uid === "prepaid001") {
      return;
    }

    setValue(newVal);
  }

  function addRow(data) {
    let newVal = _.cloneDeep(value);
    newVal.push(data);
    setValue(newVal);
  }

  function removeRow(idx) {
    let newVal = [...value.slice(0, idx), ...value.slice(idx + 1)];
    setValue(newVal);
  }

  function handleSelect(name, index, options = {}) {
    let newVal = _.cloneDeep(value);

    if (options.uid) {
      newVal[index].uid = options.uid;
    }

    if (options.uid === "prepaid001") {
      newVal[index].amount = 0;
    }

    newVal[index].name = name;
    if (options.isAddPayload) {
      addPayload(newVal, index, options.type);
    } else {
      removePayload(newVal, index);
    }
    // setValue(newVal);
  }

  function addPayload(value, index, type) {
    let newVal = _.cloneDeep(value);

    newVal[index].payload = {};
    newVal[index].type = type;
    if (type === "cityLedger") {
      newVal[index].mode = "billing";
      newVal[index].date = moment().toISOString();
      newVal[index].bank = null;
    }
    setValue(newVal);
  }

  function removePayload(val, index) {
    let newVal = _.cloneDeep(val);
    delete newVal[index].payload;
    delete newVal[index].type;
    delete newVal[index].mode;
    delete newVal[index].bank;
    delete newVal[index].date;

    setValue(newVal);
  }

  function getValue() {
    return value;
  }
  return {
    value,
    getValue,
    onChangeKey,
    handleSelect,
    setValue,
    addRow,
    removeRow,
    addPayload,
    removePayload
  };
}

//PREPAID PAYMENT OBJECT
// {
//   name:'Prepaid',
//   amount:5000,
//   status:'submit',
//   remark:'-'
// }

export default PaymentType;

const customStyles = (height = 45) => {
  return {
    // container: base => ({
    //   ...base,
    //   display: "inline-block"
    //   // width: width
    // }),
    valueContainer: base => ({
      ...base,
      // minHeight: height
      height
    })
  };
};
