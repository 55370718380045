import React, { useState, useEffect } from "react";
import useFormInput from "../../shared/useFormInput";
import _ from "lodash";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import randomUid from "../../shared/randomUid";
import swal from "sweetalert";
import async from "async";
import { connect } from "react-redux";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import * as roomsSelector from "../../shared/selector/roomsSelector";
import cartStore from "../PosCart/store";
import PrintCourse from "../../printer/service.print.bookingCourse";
import { useDispatch } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import {
  displayBookingStatusFromWeb,
  displayBookingPaymentFromWeb,
} from "../../shared/utils/bookingFromWeb";

// import { bindActionCreators } from "redux";
// import * as spaPosActions from "../../shared/actions/spaPosActions";

const styleColumnValue = { position: "absolute", left: 160 };

function BookingForm(props) {
  const name = useFormInput("");
  const mobile = useFormInput("");
  const remark = useFormInput("");
  const [bed, setBed] = useState(null);
  const [startDate, setStartDate] = useState(getTimeRound(new Date()));
  const [endDate, setEndDate] = useState(getTimeRound(new Date()));
  const [originDate, setOriginDate] = useState(getTimeRound(new Date()));

  const [isPaid, setIsPaid] = useState(false);
  const [mode, setMode] = useState("create");
  const [branch, setBranch] = useState(null);
  const [localBranchRooms, setLocalBranchRooms] = useState([]);
  const [hasBookingStatus, setHasBookingStatus] = useState(false);

  const [book_status, setBook_status] = useState(null);
  const [selectTime, setSelectTime] = useState(false);

  const {
    initEditData,
    hqMode,
    mainPageBranch,
    actions,
    dispatch,
    history,
  } = props;

  // const dispatch = useDispatch();

  useEffect(() => {
    if (initEditData) {
      setMode("edit");
      setSelectTime(true);
      name.setValue(initEditData.name);
      initEditData.mobile && mobile.setValue(initEditData.mobile);
      initEditData.remark && remark.setValue(initEditData.remark);
      setBed([initEditData.bed]);
      setStartDate(new Date(initEditData.startDate));
      setOriginDate(new Date(initEditData.startDate));
      setEndDate(new Date(initEditData.endDate));
      initEditData.isPaid && setIsPaid(initEditData.isPaid);
    } else {
      let initStartDate = localStorage.getItem("bookingInitStartDate");
      if (initStartDate) {
        setStartDate(getTimeRound(new Date(initStartDate)));
        setEndDate(getTimeRound(new Date(initStartDate)));
      }
    }
    if (
      initEditData &&
      initEditData.data &&
      initEditData.data.book_status == "3"
    ) {
      setIsPaid(true);
    }

    if (initEditData && initEditData.data && initEditData.data.book_status) {
      setHasBookingStatus(true);

      setBook_status(initEditData.data.book_status);
    }

    if (hqMode) {
      //INIT SET PREVIOUS BRANCH
      mainPageBranch && setBranch(mainPageBranch);
      props.fetchLocalBranchRooms(mainPageBranch.value, (items) => {
        setLocalBranchRooms(items);
      });
    }

    return () => {
      props.clearInitData();
    };
  }, [initEditData]);

  let hasCourse = mode === "edit" && initEditData.course;

  const clearState = () => {
    name.setValue("");
    mobile.setValue("");
    remark.setValue("");
    setBed(null);
    setStartDate(getTimeRound(new Date()));
    setEndDate(getTimeRound(new Date()));
    setIsPaid(false);
  };
  const handleSetStartDate = (date) => {
    setStartDate(getTimeRound(date));
    let tmpDate = _.cloneDeep(date);
    tmpDate.setHours(endDate.getHours());
    setEndDate(getTimeRound(tmpDate));
  };
  const validateAlert = (msg) => {
    return swal("Missing required Information", msg, "error");
  };
  const getText = (text) => {
    if (text === "") {
      return null;
    }

    return text;
  };
  const handleSetBranch = (branch) => {
    props.fetchLocalBranchRooms(branch.value, (items) => {
      setLocalBranchRooms(items);
    });
    setBranch(branch);
    setBed(null);
  };
  const submitReserveData = () => {
    if (validate()) {
      return;
    }

    let storeUid = hqMode ? branch.value : props.shop.uid;
    async.each(
      bed,
      async (item, cb) => {
        var params = {
          TableName: "Spa_Booking",
          Item: {
            storeUid: storeUid,
            bookUid: `${moment(startDate).format("YYYY-MM-DD")}#${randomUid(
              6
            )}`,
            name: getText(name.value),
            mobile: getText(mobile.value),
            remark: getText(remark.value),
            bed: item,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            timestamp: moment().toISOString(),
            isPaid: isPaid ? isPaid : null,
            fromPos: true,
          },
        };
        try {
          await documentClient.put(params).promise();
          cb();
        } catch (error) {
          console.log(error);
          cb(error);
        }
      },
      (err) => {
        if (err) {
          return console.log(err);
        }
        setBookingDate();

        swal("Success", "จองห้องสำเร็จ", "success").then(() => {
          props.goToBookingChart();
        });
        clearState();
      }
    );
  };
  const updateBooking = async (noSwal, newStatus) => {
    if (name === "") {
      return validateAlert("กรุณากรอกชื่อลูกค้า");
    }
    if (startDate === null) {
      return validateAlert("กรุณาเลือกเวลา");
    }
    if (bed.length < 1) {
      return validateAlert("กรุณาเลือกห้อง");
    }
    debugger
 
    if(initEditData.bookUid && moment(startDate).format("DD")  !==  moment(originDate).format("DD")){
      updateChangeDate()
      return 
    }

    var params = {
      TableName: "Spa_Booking",
      Key: { storeUid: initEditData.storeUid, bookUid: initEditData.bookUid },
      UpdateExpression:
        "set #name = :name, mobile = :mobile, remark = :remark,  #status = :status,  bed = :bed, startDate = :startDate, endDate = :endDate, isPaid = :isPaid",
      ExpressionAttributeNames: { "#name": "name", "#status": "status" },
      ExpressionAttributeValues: {
        ":name": getText(name.value),
        ":mobile": getText(mobile.value),
        ":remark": getText(remark.value),
        ":bed": bed[0],
        ":startDate": startDate.toISOString(),
        ":endDate": endDate.toISOString(),
        ":isPaid": isPaid, //initEditData.data.book_status == "3" ? true : false,
        ":status": newStatus || "active",
      },
    };

    await documentClient.update(params).promise();

    setBookingDate();

    if (noSwal) return;
    swal("Success", "บันทึกสำเร็จ", "success").then(() => {
      props.goToBookingChart();
    });
  };
  const removeBooking = async () => {
    let confirm = await swal({
      title: `Remove ?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (!confirm) return;

    var params = {
      TableName: "Spa_Booking",
      Key: {
        storeUid: initEditData.storeUid,
        bookUid: initEditData.bookUid,
      },
    };
    window.localStorage.setItem("bookingFilterDate", initEditData.startDate);
    await documentClient.delete(params).promise();
    props.goToBookingChart();
  };
  const handleBackPage = () => {
    setBookingDate();
    props.goToBookingChart();
  };
  const setBookingDate = () => {
    let initStartDate = localStorage.getItem("bookingInitStartDate");
    window.localStorage.setItem("bookingFilterDate", initStartDate);
  };
  const validate = () => {
    debugger;
    if (hqMode && branch === null) {
      return validateAlert("กรุณาเลือกสาขา");
    }
    if (name === "") {
      return validateAlert("กรุณากรอกชื่อลูกค้า");
    }
    if (startDate === null) {
      return validateAlert("กรุณาเลือกเวลา");
    }
    if (bed === null || bed.length < 1) {
      return validateAlert("กรุณาเลือกห้อง");
    }
    if (selectTime === false) {
      return validateAlert("กรุณาเลือกเวลา");
    }
    // if (startDate.getHours() <=     endDate.getHours()  ) {
    //   return validateAlert("เวลาต้องอย่างน้อย 30 นาที");
    // }

    return null;
  };
  const convertToJob = () => {
    if (validate()) return;
    //--UPDATE BEFORE GO--
    updateBooking(true, "to_pos");
    //--------------------
    let pickedBed = _.cloneDeep(bed[0]);
    let room = pickedBed.room;

    if (pickedBed.room) {
      delete pickedBed.room;
    }

    // let storeUid = props.shop.uid;
    let memberUid = `temp${moment().valueOf()}`;

    dispatch({
      type: "APP_STATE_SET",
      key: "room",
      payload: room.roomUid,
    });

    let data = {
      meta: {
        room: {
          ...room,
          label: room.name,
          value: room.roomUid,
        },
        bed: {
          ...pickedBed,
          label: pickedBed.name,
          value: pickedBed.bedUid,
        },
        bedUid: pickedBed.bedUid,
        startDate: startDate.toISOString(),
      },
      member: {
        label: name.value,
        name: name.value,
        type: "temporary",
        uid: memberUid,
        value: memberUid,
      },
      bookingDetail: {
        bookUid: initEditData.bookUid || null,
        remark: getText(remark.value),
        guest: {
          name: getText(name.value),
          mobile: getText(mobile.value),
        },
        isPaid: isPaid ? isPaid : null,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        transactionUid:
          (initEditData.data && initEditData.data.transactionUid) || null,
        data: initEditData.data || null,
      },
    };
    if (hasCourse) {
      let courses = attachTimestamp(initEditData.course);

      data.items = courses;

      let qtyHolder = {};
      _.forEach(courses, (item) => {
        let uid = item.uid;
        if (!qtyHolder[uid]) {
          qtyHolder[uid] = 1;
        } else {
          qtyHolder[uid]++;
        }
      });

      data.qtyHolder = qtyHolder;
      //PUSH TO CART STORE
      cartStore.dispatch({
        type: "CART_RESTORE",
        payload: {
          items: courses,
          // qtyHolder: data.qtyHolder,
          options: [],
        },
      });
    }

    if (initEditData.data && initEditData.data.discount) {
      data.options = [
        {
          mode: 1,
          value: initEditData.data.discount,
          name: "Discount",
          sign: -1,
          uid: "J6SUONBE7D",  //hardcode  disocunt divana
        },
      ];
    }

    //PUSH TO MAIN STORE
    actions.restore(data);

    setTimeout(() => history.push(`/spa_pos/0`), 100);
  };
  const printCourseThermal = () => {
    let tmpDipatch = dispatch;
    let print = new PrintCourse(tmpDipatch, initEditData);

    print.build().print();
  };


  const updateChangeDate = async () => {
    //create new booking,
     
    var params = {
      TableName: "Spa_Booking",
      Item: {
        ...initEditData,
  
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        timestamp: moment().toISOString(),
        isPaid: isPaid ? isPaid : null,
         
        timeUid : `${initEditData.room.roomUid}#${moment(startDate).format("YYYY-MM-DD")}#${moment(startDate).format("HH:mm")}"-"${moment(endDate).format("HH:mm")}` ,
        bookUid : `${moment(startDate).format("YYYY-MM-DD")}#${initEditData.room.roomUid}#${initEditData.bed.bedUid }#${moment(startDate).format("HH:mm")}"-"${moment(endDate).format("HH:mm")}` ,
      },
    };
    
    await documentClient.put(params).promise();
     


    //remove old booking
    var params = {
      TableName: "Spa_Booking",
      Key: {
        storeUid: initEditData.storeUid,
        bookUid: initEditData.bookUid,
      },
    };
    await documentClient.delete(params).promise();

    alert("CHANGE DATE COMPLETE")
  }

  let bedOptions = hqMode
    ? roomsSelector.bedOptionsSelector({ spa: { rooms: localBranchRooms } })
    : props.bedOptions;

  return (
    <div className="m-4">
      <div className="row m-0">
        <div className="col-md-2 mt-5">
          <button
            className="btn btn-space btn-secondarty pr-1"
            style={{ width: 100, backgroundColor: "lightgray" }}
            onClick={handleBackPage}
          >
            {"< Back"}
          </button>
        </div>
        <div className="col-md-8 mb-5">
          <FormGroupRow
            label=""
            input={
              <div className="row m-0 justify-content-between">
                <div>
                  <span style={{ fontSize: 23, fontWeight: "bold" }}>
                    {mode === "create" ? "Create" : "Edit"} Booking
                  </span>
                </div>
                <div>
                  {hasCourse && (
                    <button
                      class="btn btn-space btn-primary"
                      onClick={printCourseThermal}
                    >
                      Print Course
                    </button>
                  )}
                </div>
              </div>
            }
          />
          {/* RICE */}
          {mode === "edit" && initEditData.originalCourses && (
            <FormGroupRow
              label=""
              input={
                <div
                  // className="card"
                  style={{
                    // height: 200,
                    width: "100%",
                    // background: "linear-gradient(#66ffff,transparent)",
                    // backgroundColor: "#ffccff" /*this your primary color*/
                  }}
                >
                  <div
                    className="p-2 card-card-contrast"
                    style={{
                      border: "1px solid #ddd",
                      backgroundColor: "lemonchiffon",
                    }}
                  >
                    <span style={{ fontSize: 17, fontWeight: "bold" }}>
                      Course from WEB
                    </span>
                    <br />
                    <br />
                    {initEditData.member && (
                      <p class="m-0">
                        Member :{" "}
                        <span style={styleColumnValue}>
                          {initEditData.member.firstName} -
                          {initEditData.member.lastName} - (
                          {initEditData.member.mobile})
                        </span>
                      </p>
                    )}

                    <p class="m-0">
                      Guest :{" "}
                      <span style={styleColumnValue}>{initEditData.name}</span>
                    </p>

                    <p class="m-0">
                      Transaction Uid :{" "}
                      <span style={styleColumnValue}>
                        {initEditData.transactionUid}
                      </span>
                    </p>
                    <p class="m-0">
                      Time Uid :{" "}
                      <span style={styleColumnValue}>
                        {initEditData.timeUid}
                      </span>
                    </p>
                    <p class="">
                      Create Time :{" "}
                      <span style={styleColumnValue}>
                        {" "}
                        {initEditData.data && initEditData.data.createtime}
                      </span>
                    </p>

                    <table class="table table-sm">
                      <thead>
                        <tr>
                          <td>SKU</td>
                          <td>Name</td>
                          <td>Time(mins)</td>
                          <td>Price</td>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(initEditData.originalCourses, (course) => {
                          return (
                            <tr>
                              <td>{course.sku}</td>
                              <td>{course.name}</td>
                              <td>{course.time}mins</td>
                              <td>{course.price}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    {/* promotion detail  obj*/}
                    {initEditData.data &&
                      initEditData.data.promotion_detail &&
                      _.isObject(initEditData.data.promotion_detail) && [
                        <p class="m-0">Promotion name : </p>,
                        <p class="pl-5">
                          Course : {initEditData.data.promotion_detail.course}
                        </p>,
                        <p class="pl-5">
                          Gift : {initEditData.data.promotion_detail.gift}
                        </p>,
                        <p class="pl-5">
                          Discount :{" "}
                          {initEditData.data.promotion_detail.discount || 0}
                        </p>,
                      ]}

                    {/* payment */}
                    <p class="m-0">
                      Total :{" "}
                      <span style={styleColumnValue}>
                        {initEditData.data && initEditData.data.total_price}
                      </span>{" "}
                    </p>
                    <p class="m-0">
                      Discount :{" "}
                      <span style={styleColumnValue}>
                        {(initEditData.data && initEditData.data.discount) || 0}
                      </span>{" "}
                    </p>
                    <p class="m-0">
                      GrandTotal :{" "}
                      <span style={styleColumnValue}>
                        {" "}
                        {initEditData.data &&
                          initEditData.data.grand_total}{" "}
                      </span>
                    </p>
                    <p class="m-0">
                      Payment Status :{" "}
                      <span style={styleColumnValue}>
                        {" "}
                        {displayBookingPaymentFromWeb(
                          initEditData.data && initEditData.data.payment_type
                        )}
                        {"    "}
                        {displayBookingStatusFromWeb(
                          initEditData.data && initEditData.data.book_status
                        )}
                      </span>
                    </p>
                    <p class="m-0">
                      Order Note :{" "}
                      <span style={styleColumnValue}>
                        {initEditData.data && initEditData.data.order_note
                          ? initEditData.data.order_note
                          : "-"}{" "}
                      </span>{" "}
                    </p>
                    {initEditData.void && (
                      <p class="m-0">
                        Void at :{" "}
                        <span style={styleColumnValue}>
                          <span style={{ color: "red" }}>
                            {moment(initEditData.void.timestamp).format()}
                          </span>
                        </span>{" "}
                      </p>
                    )}
                  </div>
                </div>
              }
            />
          )}

          {mode === "edit" && initEditData.course && (
            <FormGroupRow
              label=""
              input={
                <div
                  // className="card"
                  style={{
                    // height: 200,
                    width: "100%",
                    // background: "linear-gradient(#66ffff,transparent)",
                    // backgroundColor: "#ffccff" /*this your primary color*/
                  }}
                >
                  <div
                    className="p-2 card-card-contrast"
                    style={{
                      border: "1px solid #ddd",
                      backgroundColor: "azure",
                    }}
                  >
                    <span style={{ fontSize: 17, fontWeight: "bold" }}>
                      Course POS (Auto-Matched)
                    </span>
                    <br />
                    <br />

                    <table class="table table-sm">
                      <thead>
                        <tr>
                          <td>SKU</td>
                          <td>Name</td>
                          <td>Time(mins)</td>
                          <td>Price</td>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(initEditData.course, (course) => {
                          return (
                            <tr>
                              <td>{course.barcode}</td>
                              <td>{course.name}</td>
                              <td>{course.timeMinutes}mins</td>
                              <td>{course.price}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <p class="m-0">
                      Total :{" "}
                      <span style={styleColumnValue}>
                        {_.sumBy(initEditData.course, "price")}
                      </span>
                    </p>
                  </div>
                </div>
              }
            />
          )}
          {props.hqMode && (
            <FormGroupRow
              label="สาขา"
              input={
                <Select
                  value={branch}
                  onChange={handleSetBranch}
                  options={props.branchOptions}
                  isDisabled={mode !== "create"}
                />
              }
            />
          )}
          <FormGroupRow
            label="ชื่อ Guest"
            input={<input className="form-control" {...name} />}
          />
          <FormGroupRow
            label="เบอร์โทร"
            input={<input className="form-control" {...mobile} />}
          />
          <FormGroupRow
            label="ห้อง"
            input={
              <Select
                isMulti
                value={bed}
                onChange={setBed}
                options={bedOptions}
                isDisabled={mode !== "create"}
              />
            }
          />
          <FormGroupRow
            label="เริ่มเวลา"
            input={
              <div className="row m-0">
                <div>
                  <DatePicker
                    selected={startDate}
                    onChange={handleSetStartDate}
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                </div>
                <div style={{ width: 100, marginLeft: 10 }}>
                  <select
                    className="form-control"
                    value={startDate.getHours()}
                    onChange={(e) => {
                      let tmpStartDate = _.cloneDeep(startDate);
                      tmpStartDate.setHours(e.target.value);
                      setStartDate(tmpStartDate);
                      setSelectTime(true);
                    }}
                  >
                    {_.map(
                      [0, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
                      (i) => {
                        let index = i;
                        if (index === 0) {
                          return (
                            <option selected value={index}>
                              select
                            </option>
                          );
                        }
                        return <option value={index}>{index}</option>;
                      }
                    )}
                  </select>
                </div>
                <div className="align-self-center ml-2 mr-1">:</div>
                <div style={{ width: 100, marginLeft: 5 }}>
                  <select
                    className="form-control"
                    value={startDate.getMinutes()}
                    onChange={(e) => {
                      let tmpStartDate = _.cloneDeep(startDate);
                      tmpStartDate.setMinutes(e.target.value);
                      setStartDate(tmpStartDate);
                    }}
                  >
                    <option value={0}>00</option>
                    {/* <option value={10}>10</option> */}
                    {/* <option value={15}>15</option> */}
                    {/* <option value={20}>20</option> */}
                    <option value={30}>30</option>
                    {/* <option value={40}>40</option> */}
                    {/* <option value={45}>45</option> */}
                    {/* <option value={50}>50</option> */}
                  </select>
                </div>
                <div className="slign-self-center" style={{ marginLeft: 5 }}>
                  <button
                    className="btn btn-space btn-primary"
                    onClick={() => {
                      if (startDate.getHours() < 10) {
                        let tmpStartDate = _.cloneDeep(startDate);
                        tmpStartDate.setHours(10);

                        setStartDate(tmpStartDate);

                        setEndDate(plusTwoHours(tmpStartDate));
                      } else {
                        setEndDate(plusTwoHours(startDate));
                      }
                    }}
                  >
                    + 2 Hours
                  </button>
                </div>

                {/* <div style={{ width: 100 }}>
                  <DatePicker
                    selected={startDate}
                    onChange={setStartDate}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat="h:mm aa"
                    timeCaption="Time"
                    className="form-control"
                  />
                </div>
               */}
              </div>
            }
          />
          <FormGroupRow
            label="ถึงเวลา"
            input={
              <div className="row m-0">
                <div>
                  <DatePicker
                    selected={endDate}
                    onChange={setEndDate}
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    disabled
                  />
                </div>
                <div style={{ width: 100, marginLeft: 10 }}>
                  <select
                    className="form-control"
                    value={endDate.getHours()}
                    onChange={(e) => {
                      let tmpEndDate = _.cloneDeep(endDate);
                      tmpEndDate.setHours(e.target.value);
                      setEndDate(tmpEndDate);
                    }}
                  >
                    {_.map(
                      [0, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
                      (i) => {
                        let index = i;

                        if (index === 0) {
                          return (
                            <option selected value={index}>
                              select
                            </option>
                          );
                        }
                        return <option value={index}>{index}</option>;
                      }
                    )}
                  </select>
                </div>
                <div className="align-self-center ml-2 mr-1">:</div>
                <div style={{ width: 100, marginLeft: 5 }}>
                  <select
                    className="form-control"
                    value={endDate.getMinutes()}
                    onChange={(e) => {
                      let tmpEndDate = _.cloneDeep(endDate);
                      tmpEndDate.setMinutes(e.target.value);
                      setEndDate(tmpEndDate);
                    }}
                  >
                    {/* {_.times(6, i => {
                      return (
                        <option value={i * 10}>
                          {i * 10 < 10 ? "0" : ""}
                          {i * 10}
                        </option>
                      );
                    })} */}
                    <option value={0}>00</option>
                    {/* <option value={10}>10</option> */}
                    {/* <option value={15}>15</option> */}
                    {/* <option value={20}>20</option> */}
                    <option value={30}>30</option>
                    {/* <option value={40}>40</option> */}
                    {/* <option value={45}>45</option> */}
                    {/* <option value={50}>50</option> */}
                  </select>
                </div>
              </div>
            }
          />
          {hasBookingStatus == false && (
            <FormGroupRow
              label="สถานะการชำระเงิน"
              input={
                <select
                  value={isPaid}
                  onChange={(e) => {
                    if (e.target.value === "true") {
                      setIsPaid(true);
                    } else {
                      setIsPaid(false);
                    }
                  }}
                  className="form-control"
                >
                  <option selected={isPaid === false} value={false}>
                    ยังไม่ได้ชำระ
                  </option>
                  <option value={true} selected={isPaid === true}>
                    ชำระแล้ว
                  </option>
                </select>
              }
            />
          )}

          {hasBookingStatus && (
            <FormGroupRow
              label="สถานะการชำระเงิน"
              input={
                <h4>
                  {" "}
                  {displayBookingStatusFromWeb(
                    initEditData.data && initEditData.data.book_status
                  )}
                  <br />
                  {"    "}
                  {displayBookingPaymentFromWeb(
                    initEditData.data && initEditData.data.payment_type
                  )}
                </h4>
              }
            />
          )}

          <FormGroupRow
            label="หมายเหตุ"
            input={<textarea className="form-control" {...remark} />}
          />

          {initEditData && (
            <FormGroupRow
              label=""
              input={
                <h4>
                  {initEditData.status == "to_pos" ? "CONVERTED TO POS" : ""}
                </h4>
              }
            />
          )}

          <FormGroupRow
            label=""
            input={
              <div>
                {mode === "create" ? (
                  <button
                    className="btn btn-space btn-primary"
                    style={{ width: 100 }}
                    onClick={submitReserveData}
                  >
                    Submit
                  </button>
                ) : (
                  [
                    !hqMode && book_status !== "5" && (
                      <button
                        className="btn btn-space btn-primary"
                        style={{ minWidth: 100 }}
                        onClick={convertToJob}
                      >
                        Convert to Job
                      </button>
                    ),
                    book_status !== "5" && (
                      <button
                        className="btn btn-space btn-warning ml-1"
                        style={{ width: 100 }}
                        onClick={updateBooking}
                      >
                        Save
                      </button>
                    ),
                    hasBookingStatus === false && (
                      <button
                        className="btn btn-space btn-danger ml-1"
                        style={{ width: 100 }}
                        onClick={removeBooking}
                      >
                        Remove
                      </button>
                    ),
                  ]
                )}

                {mode === "create" && (
                  <button
                    className="btn btn-space btn-secondarty pr-1"
                    style={{ width: 100, backgroundColor: "lightgray" }}
                    // onClick={clearStates}
                    onClick={() => {
                      swal({
                        title: `Clear ?`,
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      }).then((willDelete) => {
                        if (willDelete) {
                          clearState();
                        }
                      });
                    }}
                  >
                    RESET
                  </button>
                )}
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}

// const mapStateToProps = state => {
//   return {
//   };
// };

// export default connect(mapStateToProps)(BookingForm);
export default BookingForm;

const FormGroupRow = ({ label, input }) => {
  return (
    <div className="form-group row">
      <label className="col-3 col-form-label text-left text-sm-right">
        {label}
      </label>
      <div className="col-9">{input}</div>
    </div>
  );
};

const attachTimestamp = (items) => {
  let arr = [];
  _.forEach(items, (item, idx) => {
    arr.push({
      ...item,
      timestamp: moment().valueOf() + (idx + 1),
    });
  });
  return arr;
};

const plusTwoHours = (date) => {
  let time = _.cloneDeep(date);
  time.setHours(time.getHours() + 2);
  return getTimeRound(time);
};

const getTimeRound = (date) => {
  let time = _.cloneDeep(date);
  let minute = time.getMinutes();
  minute = round_to_precision(minute, 30);
  time.setMinutes(minute);
  return time;
};

const round_to_precision = (x, precision) => {
  var y = +x + (precision === undefined ? 0.5 : precision / 2);
  return y - (y % (precision === undefined ? 1 : +precision));
};

// const CourseListCard = ({ courses, title, colorPreset = 1 }) => {
//   let color1 = {
//     first: "#66ffff", //blue
//     second: "#ffccff" //pink
//   };
//   let color2 = {
//     first: "#ffffcc", //yellow
//     second: "#ffccb3" //orange
//   };

//   let colorObj = colorPreset === 1 ? color1 : color2;

//   return (
//     <div
//       // className="card"
//       style={{
//         // height: 200,
//         width: "100%",
//         background: `linear-gradient(${colorObj.second},transparent)`,
//         backgroundColor: colorObj.first /*this your primary color*/
//       }}
//     >
//       <div className="p-2">
//         <span style={{ fontSize: 17, fontWeight: "bold" }}>{title}</span>
//         <br />
//         <br />
//         <ul>
//           {_.map(courses, course => {
//             return (
//               <li>
//                 {course.name} (Qty: {course.qty || 1})
//               </li>
//             );
//           })}
//         </ul>
//       </div>
//     </div>
//   );
// };
