import React, { useState } from "react";
import Select from "react-select";
import IntlTelInput from "react-intl-tel-input";
import _ from "lodash";
import swal from "sweetalert";

function ManageCustomerMobileForm(props) {
  //   const [count, setCount] = useState(0);
  const {
    country,
    setCountry,
    countryOptions,
    name,
    setName,
    gender,
    setGender,
    RadioBox,
    mobile,
    handleSelectMobileCountry,
    handleInputMobile,
    preferredCountries,
    date,
    setDate,
    year,
    setYear,
    email,
    setEmail,
    mode,
    editCustomer,
    closeModal,
    fetchCustomers,
    month,
    setMonth,
    startYear,
    createCustomer,
    memberId,
    setMemberId,
    businessUnit,
    setBusinessUnit,
  } = props;

  return (
    <div style={{ margin: 15 }}>
      <div className="form-group">
        <label className="form-control-label">Member ID</label>
        <input
          type="text"
          className="form-control"
          value={memberId}
          onChange={(e) => setMemberId(e.target.value.toUpperCase())}
          autoComplete="1o3g00eas2g"
        />
      </div>
      <div className="form-group">
        <label className="form-control-label">Country:</label>
        <Select
          // className="form-control"
          value={country}
          onChange={setCountry}
          options={countryOptions}
        />
      </div>
      {/* <div className="form-group">
        <label> Member Card ID:</label>
        <input
          type="text"
          className="form-control"
          value={cardId}
          onChange={e => setCardId(e.target.value)}
          autoComplete="1o3g00eas2g"
        />
      </div> */}
      <div className="form-group">
        <label>Full Name:</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter full name"
          value={name}
          onChange={(e) => setName(e.target.value.toUpperCase())}
          autoComplete="1o3g00eas2g"
        />
      </div>

      <div className="row m-0">
        <div className="col-6 p-0">
          <div className="form-group">
            <label>Gender:</label>
            <br />
            <RadioBox
              onClick={() => setGender("Male")}
              checked={gender === "Male"}
              label="Male"
            />
            <RadioBox
              onClick={() => setGender("Female")}
              checked={gender === "Female"}
              label="Female"
            />
            <br />
          </div>
        </div>
        <div className="col-6 p-0">
          <div className="form-group">
            <label>Business Unit:</label>
            <br />
            <RadioBox
              onClick={() => setBusinessUnit("spa")}
              checked={businessUnit === "spa"}
              label="Spa"
            />
            <RadioBox
              onClick={() => setBusinessUnit("retail")}
              checked={businessUnit === "retail"}
              label="Retail"
            />
            <br />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-control-label">Mobile Number:</label>
        <br />
        <IntlTelInput
          containerClassName="intl-tel-input"
          inputClassName="form-control"
          defaultCountry={mobile.countryData.iso2}
          // key={initKey}
          css={["intl-tel-input", "form-control"]}
          style={{ width: "100%" }}
          //   style={{ width: "auto" }}
          fieldId="mobile"
          onSelectFlag={handleSelectMobileCountry}
          // disabled={readOnly}
          value={mobile.inputNumber}
          onPhoneNumberChange={handleInputMobile}
          preferredCountries={preferredCountries}
          inputProps
        />
        <br />
        <div className="mt-1">
          {mobile.inputNumber !== "" &&
            (mobile.status ? (
              <span style={{ color: "green" }}>Format Valid</span>
            ) : (
              <span style={{ color: "red" }}>Format Invalid</span>
            ))}
        </div>
      </div>
      <div>
        <span>Birth Date:</span>
        <div className="form-group row">
          <div className="col-4 form-group-sub">
            <label className="form-control-label">Date</label>
            <select
              className="form-control"
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
              }}
            >
              <option value="">Select</option>
              {_.times(31, (i) => {
                let tmpDate = i + 1;
                if (tmpDate < 10) {
                  tmpDate = "0" + tmpDate;
                }
                return <option value={tmpDate}>{tmpDate}</option>;
              })}
            </select>
          </div>
          <div className="col-4 form-group-sub">
            <label className="form-control-label">Month</label>
            <select
              className="form-control"
              value={month}
              onChange={(e) => {
                setMonth(e.target.value);
              }}
            >
              <option value="">Select</option>
              {_.times(12, (i) => {
                let tmpMonth = i + 1;
                if (tmpMonth < 10) {
                  tmpMonth = "0" + tmpMonth;
                }
                return <option value={tmpMonth}>{tmpMonth}</option>;
              })}
            </select>
          </div>
          <div className="col-4 form-group-sub">
            <label className="form-control-label">Year</label>
            <select
              className="form-control"
              value={year}
              onChange={(e) => {
                setYear(e.target.value);
              }}
            >
              <option value="">Select</option>
              {_.times(100, (i) => {
                const tmpYear = startYear - i;
                return <option value={tmpYear}>{tmpYear}</option>;
              })}
            </select>
          </div>
        </div>
      </div>

      <div className="form-group">
        <label>Email address:</label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="1o3g00eas2g"
        />
      </div>
      {mode === "create" ? (
        <button
          type="button"
          class="btn btn-success"
          style={{ width: "100%", marginTop: 10 }}
          onClick={createCustomer}
        >
          CREATE
        </button>
      ) : (
        <button
          type="button"
          class="btn btn-warning"
          style={{ width: "100%", marginTop: 10 }}
          onClick={editCustomer}
        >
          EDIT
        </button>
      )}

      <button
        type="button"
        class="btn btn-secondary"
        style={{ width: "100%", marginTop: 10 }}
        onClick={() => {
          swal({
            title: `Close Modal ?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              closeModal && closeModal();
              fetchCustomers && fetchCustomers();
            }
          });
        }}
      >
        CANCEL
      </button>
    </div>
  );
}

export default ManageCustomerMobileForm;
