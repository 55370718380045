import React, { useState, useEffect } from "react";
import Select from "react-select";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import SpaPosCustomerSelect from "./SpaPosCustomerSelect";
import cartStore from "../PosCart/store";
import { Provider } from "react-redux";
import EndTime from "./EndTime";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import moment from "moment";
import mainAppStore from "../../store";
import posStore from "../PosCart/store";
import { makeid } from "../PosCart/actions";
// import MultipleTherapistModal from "../../shared/components/MultipleTherapistModal";
import MultipleTherapistModal from "../../shared/components/MultipleTherapistModal2";

import MatchVoucherModal from "../../shared/components/MatchVoucherModal";
import useFetchMemberVoucher from "../Member/useFetchMemberVoucher";

import InputMask from "react-input-mask";
import {
  displayBookingStatusFromWeb,
  displayBookingPaymentFromWeb,
} from "../../shared/utils/bookingFromWeb";

function Flow_Place(props) {
  const getDateMask = (time) => {
    let hours = time.getHours();
    let minutes = time.getMinutes();
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    let time2 = `${hours}:${minutes}`;
    return time2;
  };

  const getStartOfDay = () => {
    let date = new Date();
    date.setHours(0, 0);
    return date;
  };

  const { spaPos, settingMotherPos = {} , isRetial} = props;
  let { requireRegisteredMember } = settingMotherPos;

  const [timestamp, setTimeStamp] = useState(moment());
  const [room, setRoom] = useState(null);
  const [bed, setBed] = useState(null);
  const [sales, setSales] = useState(null);
  const [channels, setChannels] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [remark, setRemark] = useState("");
  const [startDate, setStartDate] = useState(getStartOfDay());
  const [startDateMask, setStartDateMask] = useState("__:__");
  const [endDate, setEndDate] = useState(new Date());
  const [requireEmp, setRequireEmp] = useState(false);
  const [refId, setRefId] = useState("");
  const [memberMode, setMemberMode] = useState(1); //0 ชั่วคราว , 1 ค้นหา
  const [therapistMode, setTherapistMode] = useState("single");
  const [therapistList, setTherapistList] = useState(null);
  const [therapistFilter, setTherapistFilter] = useState("local"); //local, global

  //MEMBER VOUCHER LIST
  const {
    voucherList,
    setVoucherList,
    fetchVoucherList,
    activeVoucherOptions,
    voucherOwner,
    setVoucherOwner,
  } = useFetchMemberVoucher(null);

  const [matchVoucher, setMatchVoucher] = useState(null);

  let medicalConditions = {};

  if (spaPos.member && spaPos.member.medicalConditions) {
    medicalConditions = spaPos.member.medicalConditions;
  }

  useEffect(() => {
    if (startDateMask === "__:__") {
      props.setIsNoStartTime(true);
    } else {
      props.setIsNoStartTime(false);
    }
  }, [startDateMask]);

  useEffect(() => {
    spaPos.matchVoucher && setMatchVoucher(spaPos.matchVoucher);
  }, [spaPos.matchVoucher]);

  useEffect(() => {
    // requireRegisteredMember && setMemberMode(1); //REGISTERED MEMBER ONLY
    let params = props.match.params;
    if (!params.jobUid) {
      setData(spaPos);
      setNewUid();
      return;
    }

    setInitEditData(params);
  }, []);

  const setNewUid = () => {
    // makeid
    props.actions.setSpaPosStepData("jobUid", new Date().toISOString());
    props.actions.setSpaPosStepData("orderId", makeid(6));
  };

  useEffect(() => {
    // setNewUid();

    posStore.dispatch({
      type: "SET_MAIN_APP_STORE",
      payload: mainAppStore,
    });
  }, []);

  const setInitEditData = async (jobRef) => {
    var params = {
      TableName: "Spa_Jobs",
      Key: {
        storeUid: jobRef.storeUid,
        jobUid: jobRef.jobUid,
      },
    };
    let data = await documentClient.get(params).promise();
    if (!data.Item) {
      swal("Failed", "โหลดข้อมูล Jobs ไม่สำเร็จ", "error");
      return;
    }

    setData(data.Item);
    props.actions.restore(data.Item);
    cartStore.dispatch({ type: "CART_RESTORE", payload: data.Item });
    setTimeStamp(moment());
  };

  const setData = (data) => {
    data.refId && setRefId(data.refId);
    data.sales && setSales(data.sales);
    data.therapist && setEmployee(data.therapist);
    if (data.therapistList && !_.isEmpty(data.therapistList)) {
      setTherapistMode("multiple");
      setTherapistList(data.therapistList);
    }
    data.remark && setRemark(data.remark);
    data.requireEmp && setRequireEmp(data.requireEmp);

    //REQUIRE REGISTERED MEMBER ONLY BUG PROTECT
    if (
      data.member &&
      data.member.type === "temporary" &&
      requireRegisteredMember
    ) {
      setMemberMode(0);
    } else if (data.member && data.member.type === "temporary") {
      setMemberMode(0);
    } else {
      setMemberMode(1);
    }

    data.voucherOwner && setVoucherOwner(data.voucherOwner);

    data.matchVoucher && setMatchVoucher(data.matchVoucher);

    if (!data.meta) return;
    const { meta } = data;
    meta.room && setRoom(meta.room);
    meta.bed && setBed(meta.bed);
    meta.startDate && setStartDate(new Date(meta.startDate));
    meta.startDate && setStartDateMask(getDateMask(new Date(meta.startDate)));
    meta.endDate && setEndDate(new Date(meta.endDate));
    meta.channels && setChannels(meta.channels);
  };

  useEffect(() => {
    saveToStore();
    cartStore.dispatch({ type: "CART_TIMESTAMP_SAVE" });
  }, [
    room,
    bed,
    sales,
    startDate,
    endDate,
    employee,
    therapistList,
    channels,
    remark,
    requireEmp,
    refId,
    matchVoucher,
    voucherOwner,
    therapistMode,
  ]);

  const saveToStore = () => {
    let data = {
      meta: {
        room: room || null,
        bed: bed || null,
        startDate: (startDate && startDate.toISOString()) || null,
        endDate: (endDate && endDate.toISOString()) || null,
        channels: channels || null,
        bedUid: (bed && bed.bedUid) || null,
      },
      sales: sales || null,
      therapist: employee || null,
      therapistList: therapistList || null,
      remark: remark || null,
      requireEmp: requireEmp || false,
      refId: refId,
      matchVoucher,
      voucherOwner,
      therapistMode,
    };
    props.actions.restore(data);
  };

  const handleSetTherapistMode = (mode) => {
    if (mode === therapistMode) return;

    if (mode === "single") {
      setTherapistList(null);
    } else if (mode === "multiple") {
      let posState = posStore.getState();
      if (posState.items.length < 1) {
        swal("กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น", "", "error");
        return;
      }
      setTherapistList([]);
      window
        .$("#multipleTherapistModal")
        .modal({ backdrop: "static", show: true, keyboard: false });
    }
    setEmployee(null);
    setTherapistMode(mode);
  };

  const handleOnChangeStartTimeMask = (time) => {
    let replaced = _.replace(time, new RegExp("_", "g"), "0");
    let splited = _.split(replaced, ":");

    if (splited[0] === "") {
      setStartDateMask("__:__");
      handleOnChangeStartTime(0, 0);
      return;
    }

    let hour = parseInt(splited[0]);
    let minute = parseInt(splited[1]);

    //HOUR VALIDATE
    if (parseInt(splited[0][0]) > 2) return;
    if (parseInt(splited[0]) > 23) return;

    //MINUTE VALIDATE
    if (parseInt(splited[1][0]) > 6) return;

    setStartDateMask(time);
    handleOnChangeStartTime(hour, minute);
  };

  const handleOnChangeStartTime = (hour, minute) => {
    let tmpStartDate = _.cloneDeep(startDate);
    tmpStartDate.setHours(hour, minute);
    setStartDate(tmpStartDate);
  };

  //FILTER THERAPIST
  let employeeOptions = props.employeeOptions;

  if (therapistFilter === "local") {
    employeeOptions = _.filter(
      props.employeeOptions,
      (i) => i.branchUid === props.shopDetail.uid
    );
  }

  return (
    <div class="p-5 card card-border-color card-border-color-primary">
      <Provider store={posStore}>
        <MultipleTherapistModal
          therapistList={therapistList}
          therapistOption={employeeOptions}
          setTherapistList={setTherapistList}
          itemsObj={props.itemsObj}
          employeeObj={props.employeeObj}
          therapistMode={therapistMode}
        />
        <MatchVoucherModal
          {...props}
          matchVoucher={matchVoucher}
          voucherList={voucherList}
          activeVoucherOptions={activeVoucherOptions}
          setMatchVoucher={setMatchVoucher}
          timestamp={timestamp}
          voucherOwner={voucherOwner}
          fetchVoucherList={fetchVoucherList}
        />
      </Provider>

      <div
        class="card-body"
        //   class="card-header"
      >
        <form>
          <FormGroupRow
            // label="Zone"
            input={
              <div>
                {room ? (
                  <span
                    style={{ color: "blue", fontWeight: "bold", fontSize: 17 }}
                  >
                    {room.label} - {bed && bed.label}
                  </span>
                ) : (
                  <span
                    style={{ color: "blue", fontWeight: "bold", fontSize: 17 }}
                  >
                    BUY PRODUCT
                  </span>
                )}
              </div>
            }
          />
          {spaPos.bookingDetail && (
            <FormGroupRow
              label={<span style={{ color: "orange" }}>Booking Detail</span>}
              input={
                <div>
                  {/* <span>Book Id: {spaPos.bookingDetail.bookUid}</span>
                  <br />
                  <br /> */}
                  <span>Guest: {spaPos.bookingDetail.guest.name}</span>
                  <br />
                  <br />
                  <span>Mobile: {spaPos.bookingDetail.guest.mobile}</span>
                  <br />
                  <br />
                  <span>
                    Start:{" "}
                    {moment(spaPos.bookingDetail.startDate).format(
                      "DD/MM/YYY HH:mm"
                    )}
                  </span>
                  <br />
                  <br />
                  <span>
                    End:{" "}
                    {moment(spaPos.bookingDetail.endDate).format(
                      "DD/MM/YYY HH:mm"
                    )}
                  </span>

                  {spaPos.bookingDetail.data == undefined && [
                    <br />,
                    <br />,
                    <h5>
                      Paid Status:{" "}
                      {spaPos.bookingDetail.isPaid ? (
                        <span style={{ color: "green" }}>Paid</span>
                      ) : (
                        <span style={{ color: "red" }}>Not Paid</span>
                      )}
                    </h5>,
                  ]}

                  {spaPos.bookingDetail.data && [
                    <br />,
                    <h4 style={{ color: "blue" }}>
                      Paid Detail:
                      {displayBookingPaymentFromWeb(
                        spaPos.bookingDetail.data.payment_type
                      )}{" "}
                      {displayBookingStatusFromWeb(
                        spaPos.bookingDetail.data.book_status
                      )}{" "}
                    </h4>,
                  ]}

                  {spaPos.bookingDetail.data && spaPos.bookingDetail.data.promotion_detail &&
                    _.isObject(spaPos.bookingDetail.data.promotion_detail) && [
                      <p class="m-0">Promotion name : </p>,
                      <p class="pl-5">
                        Course :{" "}
                        {spaPos.bookingDetail.data.promotion_detail.course}
                      </p>,
                      <p class="pl-5">
                        Gift : {spaPos.bookingDetail.data.promotion_detail.gift}
                      </p>,
                      <p class="pl-5">
                        Discount :{" "}
                        {spaPos.bookingDetail.data.promotion_detail.discount ||
                          0}
                      </p>,
                    ]}

                  {spaPos.bookingDetail.remark !== null && [
                    <br />,
                    <span>Remark: {spaPos.bookingDetail.remark}</span>,
                    <br />,
                  ]}
                </div>
              }
            />
          )}
          <FormGroupRow
            label="Ref ID"
            input={
              <input
                type="text"
                class="form-control"
                value={refId}
                onChange={(e) => setRefId(e.target.value)}
              />
            }
          />
          <FormGroupRow
            label={
              <span>
                Member <span style={{ color: "red" }}>(*)</span>
              </span>
            }
            input={
              <SpaPosCustomerSelect
                {...props}
                timestamp={timestamp}
                memberMode={memberMode}
                fetchVoucherList={fetchVoucherList}
                setMatchVoucher={setMatchVoucher}
              />
            }
            col3={
              <div>
                {(!requireRegisteredMember || memberMode === 0) && (
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                      let tmpMode = memberMode === 0 ? 1 : 0;
                      if (tmpMode === 0) {
                        setVoucherList([]);
                        setMatchVoucher(null);
                      }
                      setMemberMode(tmpMode);
                    }}
                  >
                    {memberMode === 0 ? "ค้นหา" : "ชั่วคราว"}
                  </button>
                )}
                <button
                  className="btn btn-success ml-1"
                  type="button"
                  onClick={() => {
                    setTimeStamp(moment());
                    window.$("#manageCustomerModal").modal("show");
                    setMemberMode(1);
                  }}
                >
                  Create
                </button>
              </div>
            }
          />
          {!_.isEmpty(medicalConditions) && (
            <FormGroupRow
              label={<div style={{ color: "red" }}>Medical Conditions</div>}
              input={
                <div style={{ color: "red" }}>
                  <div>Allergy: {medicalConditions.allergy}</div>
                  <div>Illness: {medicalConditions.illness}</div>
                  <div>
                    Pressure:{" "}
                    {medicalConditions.preference &&
                      medicalConditions.preference.pressure}
                  </div>
                </div>
              }
            />
          )}

            {/* MATCH VOUCHER */}
          {/* {voucherList.length > 0 && ( */}
            <FormGroupRow
            label={
              <span
              // style={{
              //   color:
              //     employee || (therapistList && therapistList.length > 0)
              //       ? "blue"
              //       : "red",
              //   fontWeight: "bold"
              // }}
              >
                Vouchers
              </span>
            }
            input={
              <div>
                <MatchVoucherDetail
                  voucherOwner={voucherOwner}
                  matchVoucher={matchVoucher}
                  items={cartStore.getState().items}
                />
              </div>
            }
          />
          {/* )} */}


          { isRetial ===false &&<FormGroupRow
            label={<div>Therapist Filter</div>}
            input={
              <div>
                <label
                  class="custom-control custom-radio custom-control-inline pt-0"
                  onClick={() => setTherapistFilter("local")}
                >
                  <input
                    class="custom-control-input"
                    type="radio"
                    checked={therapistFilter === "local"}
                  />
                  <span class="custom-control-label">พนักงาน(ในสาขา)</span>
                </label>
                <label
                  class="custom-control custom-radio custom-control-inline pt-0"
                  onClick={() => setTherapistFilter("global")}
                >
                  <input
                    class="custom-control-input"
                    type="radio"
                    checked={therapistFilter === "global"}
                  />
                  <span class="custom-control-label">พนักงาน(ทุกสาขา)</span>
                </label>
              </div>
            }
          />}
        

          {/* MATCH VOUCHER */}
          { isRetial ===false && <FormGroupRow
            label={
              <span
                style={{
                  color:
                    employee || (therapistList && _.size(therapistList) > 0)
                      ? "blue"
                      : "red",
                  fontWeight: "bold",
                }}
              >
                Therapist <span style={{ color: "red" }}>(*)</span>
              </span>
            }
            input={
              <div>
                {therapistMode === "single" ? (
                  <div className="row m-0">
                    <div className="col-9 p-0">
                      <Select
                        value={employee}
                        onChange={setEmployee}
                        options={employeeOptions}
                      />
                    </div>
                    <div className="col-3">
                      <div
                        class="be-checkbox custom-control custom-checkbox"
                        onClick={() => setRequireEmp(!requireEmp)}
                      >
                        <input
                          class="custom-control-input"
                          type="checkbox"
                          checked={requireEmp}
                        />
                        <label
                          class="custom-control-label"
                          for="check1"
                          style={{ paddingTop: 0 }}
                        >
                          Required
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <MultipleTherapistDetail
                    {...props}
                    therapistList={therapistList}
                  />
                )}
              </div>
            }
            col3={
              <div class="form-check p-0 m-0">
                <RadioBox
                  label="Single"
                  checked={therapistMode === "single"}
                  onClick={() => handleSetTherapistMode("single")}
                />
                <RadioBox
                  label="Multiple"
                  checked={therapistMode === "multiple"}
                  onClick={() => handleSetTherapistMode("multiple")}
                />
              </div>
            }
          />}
          <FormGroupRow
            label="Sales"
            input={
              <Select
                isClearable
                value={sales}
                onChange={setSales}
                options={props.salesOptions}
              />
            }
          />
          <FormGroupRow
            label={
              <span>
                Start Time <span style={{ color: "red" }}>(*)</span>
              </span>
            }
            input={
              <div className="row m-0">
                {props.spaPosSetting.revertBill !== true && (
                  <div style={{ width: 108 }}>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      // showTimeSelect
                      // timeIntervals={5}
                      // timeFormat="HH:mm"
                      // dateFormat="dd/MM/yyyy HH:mm"
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                    />
                  </div>
                )}

                <div className="ml-2" style={{ width: 100 }}>
                  {/* TIME */}
                  <InputMask
                    onChange={(e) =>
                      handleOnChangeStartTimeMask(e.target.value)
                    }
                    value={startDateMask}
                    mask="99:99"
                    maskChar="_"
                    className="form-control"
                    placeholder="__:__"
                  />
                </div>
                <div className="ml-2">
                  <Provider store={cartStore}>
                    <EndTime startTime={startDate} />
                  </Provider>
                </div>
              </div>
            }
          />
          {/* <FormGroupRow
            label="End Time"
            input={
              <Provider store={cartStore}>
                <EndTime startTime={startDate} />
              </Provider>
            }
          /> */}

          <FormGroupRow
            label="Channels"
            input={
              <Select
                value={channels}
                onChange={setChannels}
                options={props.channelOptions}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
              />
            }
          />
          <FormGroupRow
            label="Remark"
            input={
              <textarea
                class="form-control"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
            }
          />
        </form>
      </div>
    </div>
  );
}

export default Flow_Place;

const FormGroupRow = ({ label, input, col3 }) => {
  return (
    <div class="form-group row">
      <label class="col-2 text-sm-right">
        <b>{label}</b>
      </label>
      <div class="col-7">{input}</div>
      <div class="col-3 align-self-start">{col3}</div>
    </div>
  );
};

const RadioBox = ({ checked, label, onClick }) => {
  return (
    <label
      class="custom-control custom-radio custom-control-inline"
      style={{ paddingTop: 1, paddingRight: 5, margin: 0, marginBottom: 10 }}
    >
      <input
        class="custom-control-input"
        type="radio"
        name="radio-inline"
        checked={checked}
        onClick={onClick}
      />
      <span class="custom-control-label">{label}</span>
    </label>
  );
};

const MultipleTherapistDetail = ({ therapistList, productObj }) => {
  return (
    <div className="mb-2">
      <a
        href="#"
        style={{ color: "orange" }}
        onClick={(e) => {
          e.preventDefault();
          window
            .$("#multipleTherapistModal")
            .modal({ backdrop: "static", show: true, keyboard: false });
        }}
      >
        <span class="mdi mdi-edit"></span> Edit Therapists
      </a>

      {_.map(therapistList, (therapists, pickTime) => {
        let product = productObj[pickTime];

        if (!product) return;

        return (
          <div className="mt-1">
            <h4>
              <span style={{ color: "blue" }}>{product.qty} x </span>
              {product.name} ({product.timeMinutes * product.qty} mins)
            </h4>
            <ul>
              {_.map(therapists, (therObj) => {
                return (
                  <li>
                    <span style={{ color: "blue" }}></span>
                    {therObj.therapist.name} ({therObj.workMinutes} mins){" "}
                    {therObj.required && (
                      <span style={{ color: "red", fontSize: 13 }}>
                        (Required)
                      </span>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

const MatchVoucherDetail = ({ items, matchVoucher, voucherOwner }) => {
  let ordered = _.orderBy(items, "timestamp", "asc");
  return (
    <div className="mb-2">
      {voucherOwner.name && (
        <span>
          <span style={{ color: "green" }}>Owner: {voucherOwner.name}</span> -{" "}
        </span>
      )}

      <a
        href="#"
        style={{ color: "orange" }}
        onClick={(e) => {
          e.preventDefault();
          let posState = posStore.getState();
          if (posState.items.length < 1) {
            swal("กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น", "", "error");
            return;
          }
          window
            .$("#matchVoucherModal")
            .modal({ backdrop: "static", show: true, keyboard: false });
        }}
      >
        <span class="mdi mdi-edit"></span> Match Vouchers
      </a>
      <ul>
        {_.map(ordered, (item) => {
          if (!matchVoucher || !matchVoucher[item.timestamp]) return;
          return [
            <li>
              <span style={{ fontSize: "bold" }}>
                <span style={{ color: "red" }}>{item.qty}</span> x {item.name}{" "}
                (Price:{item.price})
              </span>
            </li>,
            <li style={{ listStyleType: "none" }}>
              <span style={{ color: "blue" }}>
                {matchVoucher[item.timestamp].name} (
                {matchVoucher[item.timestamp].voucherUid})
              </span>
            </li>,
          ];
        })}
      </ul>
    </div>
  );
};
