import Amplify, { Auth } from "aws-amplify";
import documentClient, { cognitoidentityserviceprovider } from "./dynamoDb";
import swal from "sweetalert";
import randomString from "random-string";

const userPoolId = "ap-southeast-1_cGBlOkVLn";
Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'ap-southeast-1:9b4a6853-a87e-4eaf-9c4e-908014bbbe04',

    // REQUIRED - Amazon Cognito Region
    region: "ap-southeast-1",

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    //  identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    // userPoolWebClientId: 'a1b2c3d4e5f6g7h8i9j0k1l2m3',
    userPoolWebClientId: "7hdaqppemlqpgkacaa115cnmq7",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // cookieStorage: {
    //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //     domain: '.yourdomain.com',
    //     // OPTIONAL - Cookie path
    //     path: '/',
    //     // OPTIONAL - Cookie expiration in days
    //     expires: 365,
    //     // OPTIONAL - Cookie secure flag
    //     secure: true
    // },

    // OPTIONAL - customized storage object
    //storage: new MyStorage(),

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
});
export const logIn = (username, password, cbSuccess) => {
  return Auth.signIn(username, password)
    .then((user) => {
      // console.log(user)
      if (cbSuccess) {
        cbSuccess();
        return;
      }
      window.location.href = "/";
    })
    .catch((err) => {
      if (err.code === "UserNotFoundException") {
        swal("เกิดข้อผิดพลาด", "ไม่พบบัญชีผู้ใช้ที่คุณกรอก", "error");
        return;
      }
      if (err.code === "NotAuthorizedException") {
        swal("เกิดข้อผิดพลาด", "Password ไม่ถูกต้อง", "error");
        return;
      }

      alert(err.message);
    });
};
// export const signUp = (user, cbSuccess, cbError, isAddToBranch) => { //test
export const signUp = (user, cbSuccess, isAddToBranch) => {
  Auth.signUp({
    username: user.username,
    password: user.password,
    attributes: {
      email: user.email, // optional
      // other custom attributes
    },
    // validationData: []  //optional
  })
    .then((data) => {
      // createUser({ ...user, userSub: data.userSub }, cbSuccess)
      console.log(data);
      var params = {
        UserPoolId: userPoolId /* required */,
        Username: user.username /* required */,
      };

      let userSub = data.userSub;

      cognitoidentityserviceprovider.adminConfirmSignUp(params, function (
        err,
        data
      ) {
        if (err) {
          console.log(err, err.stack);
        } // an error occurred
        else {
          // successful response
          console.log(data);
          if (isAddToBranch === true) {
            addUserToBranch(user, cbSuccess);
          } else {
            createUser({ ...user, userSub }, cbSuccess);
          }
        }
      });
    })
    .catch((err) => {
      console.log(err);
      // cbError(err)
      if (err.code === "UsernameExistsException") {
        swal("เกิดข้อผิดพลาด", "Username นี้ถูกใช้แล้ว", "error");
        return;
      }
      if (err.code === "InvalidParameterException") {
        swal("เกิดข้อผิดพลาด", "Invalid E-mail format", "error");
        return;
      }
      swal("เกิดข้อผิดพลาด", err.code, "error");
    });
};

export const addUserToBranch = (user, cbSuccess) => {
  let paramUser = {
    TableName: "Minor_Users",
    Item: {
      shopId: user.shopId,
      username: user.username,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      gender: user.gender,
      role: "admin",
    },
  };
  if (user.profileImg) {
    paramUser.Item.profileImg = user.profileImg;
  }
  documentClient.put(paramUser, (err, data) => {
    if (err) {
      console.log(err);
      swal("เกิดข้อผิดพลาด", err, "error");
      return;
    }
    cbSuccess && cbSuccess();
  });
};

const createUser = (user, cbSuccess) => {
  let randomShopId = randomString({
    length: 8,
    numeric: true,
    letters: false,
    special: false,
  });

  let newUserData = {
    shopId: randomShopId,
    username: user.username,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    role: "admin",
    gender: user.gender,
  };

  if (user.profileImg) {
    newUserData.profileImg = user.profileImg;
  }

  let paramUser = {
    TableName: "Minor_Users",
    Item: newUserData,
  };

  documentClient.put(paramUser, (err, data) => {
    if (err) {
      swal("เกิดข้อผิดพลาด", err, "error");
      return;
    }
    createShop(
      {
        ...user,
        uid: randomShopId,
        // shopName: user.shopName,
        // province: user.province,
        // tags: user.tags,
        // link: user.link,
        // branchId: user.branchId,
        // shippingAddress: user.shippingAddress,
        // alias: user.alias
      },
      cbSuccess
    );
  });
};

const createShop = (shopData, cbSuccess) => {
  const newShopData = {
    ...shopData,
    alias: shopData.alias !== "" ? shopData.alias : null,
  };
  if (shopData.tags) {
    newShopData.tags = shopData.tags;
  }

  if (shopData.link !== null) {
    newShopData.link = {
      inventory: shopData.link,
    };
  }

  newShopData.name = shopData.name || shopData.shopName;

  if (newShopData.shopName) {
    delete newShopData.shopName; //? ไม่ใช้
  }

  let paramShop = {
    TableName: "Minor_Shops",
    Item: newShopData,
  };

  documentClient.put(paramShop, (err, data) => {
    if (err) {
      console.log(err);
      alert(err);
      // swal("เกิดข้อผิดพลาด", err, "error");
      return;
    }
    cbSuccess && cbSuccess();
  });
};

export const deleteUser = (username, cbSuccess) => {
  var params = {
    UserPoolId: userPoolId /* required */,
    Username: username /* required */,
  };
  cognitoidentityserviceprovider.adminDeleteUser(params, (err, data) => {
    if (err) {
      swal("เกิดข้อผิดพลาด", err.code, "error");
      console.log(err);
      return;
    }
    cbSuccess && cbSuccess();
  });
};
