import _ from "lodash";
import documentClient from "../awsAPIs/dynamoDb";
import * as type from "../types/spa.voucher";

export const fetchSpaVoucher = cb => {
  return async (dispatch, getState) => {
    let { shop } = getState();
    try {
      //CURRENT
      let shopUid = shop.detail.uid;
      if (shop.linked.inventory) {
        shopUid = shop.linked.inventory;
      }
      var params = {
        TableName: "Spa_Voucher",
        KeyConditionExpression: "storeUid = :storeUid",
        ExpressionAttributeValues: {
          ":storeUid": shopUid
        }
      };
      let res = await documentClient.query(params).promise();
      if (res.Items) {
        dispatch({
          type: type.FETCH_SPA_VOUCHER,
          payload: _.orderBy(res.Items, "row", "ASC")
        });
      }

      cb && cb();
    } catch (error) {
      console.log(error);
    }
  };
};
