import React, { useState, useEffect } from "react";
import * as aws from "../../shared/awsAPIs/instance";

 import { connect }  from 'react-redux'


function useFetchJobs(shopId){
    const [jobs, setJobs] = useState([]);

    async function fetchJobs() {
        var params = {
          TableName: "Spa_Jobs",
          KeyConditionExpression: "storeUid = :hkey",
          ExpressionAttributeValues: {
            ":hkey": shopId
          }
        };
        var data = await aws.documentClient.query(params).promise();
        setJobs(data.Items);
      }

    useEffect(() => {
        fetchJobs()
    }, [ ])


    return {
        jobs,
         
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        shopUid: state.shop.detail.uid
    }
}

// export default connect(mapStateToProps, null)(useFetchJobs)
export default useFetchJobs