import _ from "lodash";
import moment from "moment";
import dynamodb from "../shared/awsAPIs/dynamoDb";

const ReceiptOfflineMiddleware = (store) => (next) => {
  //receipt id
  const RECEIPTS_OFFLINE_LOCAL = "RECEIPTS_OFFLINE_LOCAL";
  let localData = {
    // time1: { param1 }
  };

  try {
    localData =
      JSON.parse(window.localStorage.getItem(RECEIPTS_OFFLINE_LOCAL)) || {};
    store.dispatch({
      type: "APP_STATE_PENDING_PUSH_TRANSACTIONS",
      payload: _.size(localData),
    });
  } catch (error) {
    localData = {};
  }
  setTimeout(restore, 4000);

  setInterval(() => {
    restore();
  }, 60 * 1000 * 5);

  async function restore() {
    for (const key in localData) {
      const action = localData[key];
      let { payload: params, meta, type } = action;
      if (params === null) {
        delete localData[key];
        save();
        continue;
      }

      if (meta.mode === "delete") {
        await dynamodb.delete(params).promise();
      } else if (meta.mode === "put") {
        await dynamodb.put(params).promise();
      }

      delete localData[key];
      save();
    }
    save();
  }

  function save() {
    let filterPushBill = [];

    _.map(localData, (data, key) => {
      if (_.includes(key, "pushBill")) {
        filterPushBill.push(data);
      }
    });

    store.dispatch({
      type: "APP_STATE_PENDING_PUSH_TRANSACTIONS",
      payload: _.size(filterPushBill),
    });
    // debugger;
    let string = JSON.stringify(localData);
    window.localStorage.setItem(RECEIPTS_OFFLINE_LOCAL, string);
  }

  return (action) => {
    if (
      action.type === "SAVE_OFFLINE" &&
      action.meta &&
      action.meta.autosave === "RECEIPT"
    ) {
      //payload is params aws dynamodb
      if (action.payload === null) {
        delete localData[action.key];
      } else {
        localData[action.key] = action;
      }

      save();
    }

    return next(action);
  };
};

export default ReceiptOfflineMiddleware;
