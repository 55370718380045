import React, { useState } from "react";
import { Section, SummaryList, RowButton } from "./Components";
import Select from "react-select";

const BASE_PATH = "/reports3/spa";

function ReportMenu(props) {
  const { actions, hqViewBranch } = props;
  const [count, setCount] = useState(0);
  return (
    <div className="main-content p-0">
      <div class=" pt-5 pb-7" style={{ backgroundColor: "white" }}>
        {/* <Section title="Summary">
          <SummaryList>
            <RowButton
              title="สรุปการขายรายวัน"
              buttonLabel="View"
              path={_genPath('/summary-date')}
            />
          </SummaryList>
        </Section> */}

        {props.isHq && (
          <Section title={<span style={{ color: "blue" }}>เลือกสาขา</span>}>
            <div style={{ paddingLeft: 40 }}>
              <Select
                value={hqViewBranch}
                onChange={branch => actions.setViewBranch(branch)}
                options={props.branchOptions}
              />
            </div>
          </Section>
        )}

        <Section title="Receipts">
          <SummaryList>
            <RowButton
              title="บิลขายใบเสร็จรายวัน"
              buttonLabel="View"
              path={_genPath("/receipts")}
            />
            {/* <RowButton
              title="ยกเลิกใบเสร็จ (Void)"
              buttonLabel="View"
              path={_genPath('/receipts-void')}
            /> */}
            <RowButton
              title="บิลขายใบเสร็จ ช่วงเวลา"
              buttonLabel="View"
              path={_genPath("/receipts-range")}
            />
             <RowButton
              title="รายงานประจำวัน (แบบเดิม)"
              buttonLabel="View"
              path={_genPath("/summary-daily-sale")}
            />
          </SummaryList>
        </Section>
        <Section title="Payment Types">
          <SummaryList>
            <RowButton
              title="สรุปช่องทางการชำระเงิน"
              buttonLabel="View"
              path={_genPath("/payment-types")}
            />
          </SummaryList>
        </Section>
        <Section title="Products">
          <SummaryList>
            {/* <RowButton
              title="ยอดขายสินค้าและบริการ"
              buttonLabel="View"
              path={_genPath("/products")}
            /> */}
             <RowButton
              title="ยอดขายสินค้าและบริการ(ใหม่)"
              buttonLabel="View"
              path={_genPath("/products-new")}
            />
          </SummaryList>
        </Section>
        <Section title="City Ledger">
          <SummaryList>
            <RowButton
              title="รายงาน Agent "
              buttonLabel="View"
              path={_genPath("/city-ledger")}
            />
          </SummaryList>
        </Section>
        <Section title="Hotel / Sales">
          <SummaryList>
            <RowButton
              title="รายงาน Sales "
              buttonLabel="View"
              path={_genPath("/hotel-sales")}
            />
          </SummaryList>
        </Section>

        <Section title="Therapist">
          <SummaryList>
            <RowButton
              title="รวมชั่วโมงทำงานแต่ละวัน (รายเดือน)"
              buttonLabel="View"
              path={_genPath('/therapist-table-hours')}
              // onClick={printSummary}
            />
            <RowButton
              title="Working Coupons"
              buttonLabel="View"
              path={_genPath("/therapist-working-coupons")}
              // onClick={printSummary}
            />
          </SummaryList>
        </Section>

        <Section title="Options Addon">
          <SummaryList>
            {/* <RowButton
              title="Disconuts"
              buttonLabel="View"
              path={_genPath('/options-discount')}

            /> */}
            <RowButton
              title="Entertains"
              buttonLabel="View"
              path={_genPath("/options-entertain")}
            />
            {/* <RowButton 
              title="Prepaid"
              buttonLabel="View"
              path={_genPath('/options-prepaid')}

            /> */}
            {/* <RowButton
              title="Voucher"
              buttonLabel="View"
              path={_genPath('/options-voucher')}

            /> */}
          </SummaryList>
        </Section>
      </div>
    </div>
  );
}

export default ReportMenu;

function _genPath(p) {
  return BASE_PATH + p;
}
