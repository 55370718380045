import React, { useState, useEffect } from "react";
// import { Right } from './DashboardSpaSummary'
import _ from "lodash";
import {
  ReceiptRow,
  ReceiptHeader,
  ReceiptGroup,
  ReceiptGroupRow2,
  ReceiptGroupRow
} from "./ReceiptRow";
import { useRadioViewButtons } from "../Components";
import useReceiptsHook from "../../../shared/useHook/useReceiptsHook";
import useFilterList from "../useFilterList";

function PaymentTypes({ reportReducer, reportDispatch, hqViewBranch }) {
  const [groupReceipts, setGroupReceipts] = useState({});
  const [groupReceipts_filtered, setGroupReceipts_filtered] = useState(null);

  const [filterSelected, setFilterSelected] = useState(null);
  const { data, isloading } = useReceiptsHook(
    reportReducer.startDate,
    reportReducer.endDate
  );
  const [selected1, filterList] = useFilterList(
    "PAYMENT_TYPES",
    setFilterSelected,
    {
      isMulti: false
    }
  );

  //TODO FIlter only payments
  const [view, Component] = useRadioViewButtons("summary");
  function viewCallback(receipt) {
    reportDispatch({
      type: "SET_MODAL",
      payload: {
        data: receipt,
        visible: true
      }
    });
  }

  useEffect(() => {
    let receipts = _.filter(data, r => !r.void);

    let res = _.reduce(
      receipts,
      (acc, r) => {
        _.forEach(r.payment.paymentTypes, p => {
          acc[p.name] = acc[p.name] || { receipts: [], amount: 0, count: 0 };
          acc[p.name]["receipts"].push(r);
          acc[p.name]["amount"] += p.amount;
          acc[p.name]["count"]++;
        });

        return acc;
      },
      {}
    );

    setGroupReceipts(res);

    if (filterSelected) {
      let newFilter = {
        [filterSelected.name]: res[filterSelected.name] || []
      };
      setGroupReceipts_filtered(newFilter);
    } else {
      setGroupReceipts_filtered(null);
    }
  }, [data, filterSelected]);

  return (
    <div class=" ">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            {hqViewBranch && (
              <h3 style={{ color: "blue", fontWeight: "bold" }}>
                {hqViewBranch.name}
              </h3>
            )}

            <h4>{Component}</h4>

            <div class="row mb-3">
              <div class="col-1">
                <h4>Filter : </h4>{" "}
              </div>
              <div class="col-4">{filterList} </div>
            </div>

            <table class="table table-small table-striped">
              {view === "detail" && (
                <ReceiptHeader showAgent={false} showTherapist={false} />
              )}

              <tbody>
                {_.map(
                  groupReceipts_filtered || groupReceipts,
                  (data, gName) => {
                    return [
                      <ReceiptGroupRow2
                        colSpan1={5}
                        name={gName}
                        col1Text={
                          "GrandTotal : " + data.amount + ` (${data.count})`
                        }
                      />,
                      view === "detail" &&
                        _.map(data.receipts, (r, i) => (
                          <ReceiptRow
                            viewCallback={() => {
                              viewCallback(r);
                            }}
                            showTherapist={false}
                            showAgent={false}
                            showVoidButton={false}
                            index={i + 1}
                            linkToKey="entertains_cityLedger"
                            {...r}
                          />
                        ))
                    ];
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentTypes;
