import React, { useState, useEffect } from "react";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import _ from "lodash";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { displayBookingPaymentFromWeb, displayBookingStatusFromWeb } from "../../shared/utils/bookingFromWeb";

var nextDate = new Date();
nextDate.setDate(nextDate.getDate() + 30);

function BookingList(props) {
  // let initEndDate = new Date();
  // initEndDate.setDate(initEndDate.getDate() + 7);
  let initStartDate = new Date();
  initStartDate.setDate(initStartDate.getDate() - 0);
  const { hqMode = false, branchOptions = [], mainPageBranch } = props;

  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(nextDate);
  const [jobs, setJobs] = useState([]);
  const [branch, setBranch] = useState(null);
  // const [localBranchRooms, setLocalBranchRooms] = useState([]);
   

  async function fetchJobs({ forceStore }) {
    let shopUid = null;

    if (forceStore) {
      shopUid = forceStore.value;
    } else {
      shopUid = hqMode ? branch.value : props.shop.uid;
    }

    //BUG PROTECT
    if (hqMode && branchOptions.length < 1) return;

    var params = {
      TableName: "Spa_Booking",
      IndexName: "storeUid-startDate-index",
      KeyConditionExpression:
        "storeUid = :hkey AND startDate BETWEEN :startDate AND :endDate",
      ExpressionAttributeValues: {
        ":hkey": shopUid,
        ":startDate": moment(startDate).format("YYYY-MM-DD"),
        ":endDate": moment(endDate).format("YYYY-MM-DD") + "zzzzzzzzzzzzzzzzz"
      }
    };
    var data = await documentClient.query(params).promise();
    if (data.Items) {
      computeJobs(data.Items);
    }
  }

  const computeJobs = jobs => {
    let dateHolder = {};

    _.forEach(jobs, job => {
      let date = moment(job.startDate).format("DD/MM/YYYY");
      let roomUid = job.bed.room.roomUid;
      let bedUid = job.bed.bedUid;

      //DATE
      if (!dateHolder[date]) {
        dateHolder[date] = {
          date,
          rooms: {}
        };
      }
      //ROOM
      if (!dateHolder[date]["rooms"][roomUid]) {
        dateHolder[date]["rooms"][roomUid] = {
          ...job.bed.room,
          beds: {}
        };
      }
      //BED
      if (!dateHolder[date]["rooms"][roomUid]["beds"][bedUid]) {
        dateHolder[date]["rooms"][roomUid]["beds"][bedUid] = {
          row: job.bed.row,
          times: []
        };
      }

      let startTime = moment(job.startDate).format("HH:mm");
      let endTime = moment(job.endDate).format("HH:mm");
      let startUnit = moment(job.startDate).format("A");
      let endUnit = moment(job.endDate).format("A");

      dateHolder[date]["rooms"][roomUid]["beds"][bedUid]["times"].push({
        ...job,
        startTime,
        endTime,
        startUnit,
        endUnit
      });
    });

    let orderedByDate = _.orderBy(dateHolder, "date", "asc");
    setJobs(orderedByDate);
  };

  const handleSetBranch = branch => {
    props.fetchLocalBranchRooms(
      branch.value
      //   , items => {
      //   setLocalBranchRooms(items);
      // }
    );
    setBranch(branch);
  };

  useEffect(() => {
    let initBranch = null;
    //INIT SET PREVIOUS BRANCH
    if (hqMode && mainPageBranch) {
      initBranch = mainPageBranch;
    } else {
      initBranch = branchOptions[0];
    }

    fetchJobs({
      forceStore: hqMode ? initBranch : null
    });

    //FIRST LOAD
    if (hqMode && initBranch) {
      handleSetBranch(initBranch);
    }
  }, []);

  return (
    <div className="p-5">
      <div class="row m-0 justify-content-between">
        <div className="row m-0">
          {hqMode && (
            <div style={{ width: 300 }}>
              <label>เลือกสาขา</label>
              <Select
                value={branch}
                onChange={handleSetBranch}
                options={branchOptions}
                placeholder="เลือกสาขา"
              />
            </div>
          )}
          <div className="ml-2">
            <label>เริ่มวันที่</label>
            <br />
            <DatePicker
              selected={startDate}
              onChange={setStartDate}
              // timeIntervals={15}
              dateFormat="dd/MM/yyyy"
              className="form-control"
            />
          </div>
          <div className="ml-2">
            <label>ถึงวันที่</label>
            <br />
            <DatePicker
              selected={endDate}
              onChange={setEndDate}
              // timeIntervals={15}
              dateFormat="dd/MM/yyyy"
              className="form-control"
            />{" "}
            <button
              class="btn btn-space btn-primary"
              onClick={fetchJobs}
              style={{ height: 47 }}
            >
              Reload
            </button>
          </div>
        </div>
        <div className="align-self-end">
        

          <button
            class="btn btn-space btn-primary"
            onClick={props.goToBookingForm}
            style={{ height: 47 }}
          >
            New Book
          </button>
        </div>
      </div>
      <div class="dropdown-divider mt-4 mb-2" />
      <div className="mt-4" />
      <JobsSummaryData {...props} jobs={jobs} />
    </div>
  );
}

export default BookingList;

const JobsSummaryData = props => {
  const { jobs } = props;
  return (
    <div class="card card-table">
      <div class="card-body">
        <table class="table table-striped table-borderless">
          <thead>
            <tr>
              <th>Room</th>
              <th>Bed</th>
              <th>Customer</th>
              <th>Time</th>
              <th>Status</th>
              <th>Payment</th>

              <th class="actions"></th>
            </tr>
          </thead>
          <tbody>
            {_.map(jobs, job => {
              let roomOrderedByRow = _.orderBy(job.rooms, "row", "asc");
              return [
                <tr>
                  <td colSpan={7}>
                    <h3 style={{ marginTop: 45 }}>{job.date}</h3>
                  </td>
                </tr>,
                _.map(roomOrderedByRow, room => {
                  let bedOrderedByRow = _.orderBy(room.beds, "row", "asc");

                  return _.map(bedOrderedByRow, beds => {
                    let orderByStartTime = _.orderBy(
                      beds.times,
                      "startDate",
                      "asc"
                    );
                    return _.map(orderByStartTime, time => {
                      return (
                        <tr key={time.bookUid}>
                          <td>{room.name}</td>
                          <td>{time.bed.name}</td>
                          <td>{time.name}</td>
                          <td>
                            {time.startTime} {time.startUnit} - {time.endTime}{" "}
                            {time.endUnit}
                          </td>
                          <td>{time.status}</td>
                      
                        <td>
                           {displayBookingStatusFromWeb(
                          time.data && time.data.book_status
                        ) || (time.isPaid ? "ชำระแล้ว" : "รอจ่ายเงิน")}
                        </td>
                          <td>
                            <a
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                props.setInitEditData(time); 
                                props.goToBookingForm();
                              }}
                            >
                              View
                            </a>
                          </td>
                          {/* <td class="actions">
            <a class="icon" href="#">
              <i class="mdi mdi-delete"></i>
            </a>
          </td> */}
                        </tr>
                      );
                    });
                  });
                })
              ];
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
