import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import * as spaSalesSelector from "../../shared/selector/spaSalesSelector";

const MODE = {
  THERAPIST: "THERAPIST",
  CITY_LEDGER: "CITY_LEDGER", //city ledger,
  ENTERTAIN: "ENTERTAIN",
  SALES: "SALES",
  PAYMENT_TYPES  :   "PAYMENT_TYPES"
};
function useFilterList(mode = "THERAPIST", callBackSetValues, options= {}) {
  const [selected, setSelected] = useState(null);
  const list = useSelector(state => {
     ;
    let res = [];
    switch (mode) {
      case MODE.THERAPIST:
        res = spaSalesSelector.employeeOptionsSelector(state);
        break;
      case MODE.CITY_LEDGER:
        res = spaSalesSelector.agentOptionsSelector(state);
        break;
      case MODE.SALES:
        res = spaSalesSelector.salesOptionsSelector(state);
        break;
      case MODE.ENTERTAIN:
        res = spaSalesSelector.entertainOptionsSelector(state);
        break;
        case MODE.PAYMENT_TYPES:
          res = spaSalesSelector.paymentTypesSelector(state);
          break;
      default:
        break;
    }

    return res;
  });
  useEffect(() => {
    callBackSetValues && callBackSetValues(selected)
  }, [selected])

  return [
  selected,
  <Select
      isMulti={options.isMulti === false ? false : true }
      value={selected}
      onChange={setSelected}
      options={list || []}
      placeholder="All"
      isClearable={true}
    />
  ]
  return (
    <Select
      isMulti={options.isMulti === false ? false : true }
      value={selected}
      onChange={setSelected}
      options={list || []}
      placeholder="All"
      isClearable={true}
    />
  );
}

export default useFilterList;
