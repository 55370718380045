import _ from "lodash";
import csvUtil from "./service.generateCsv";
import moment from "moment";

export default class excelReportMember {
  dataArray = [];
  startDate = moment();
  dateType = "day";
  shopName = "";
  //   endDate = moment();
  constructor(members, isRetail) {
    this.isRetail = isRetail;
    this.members = members;
  }

  build() {
    let date = moment().format("DD-MM-YYYY HH:mm:ss");
    this.computeCsvData(date);

    new csvUtil().genCsv(`Members-${date}`, this.dataArray);
  }

  computeCsvData(date) {
    var row = [];

    if (this.isRetail) {
      row.push(["Retail Members"]);
    } else {
      row.push(["Members"]);
    }

    row.push([""]);
    row.push([`Exported at: ${date}`]);
    row.push([""]);
    row.push([
      "No.",
      "Create At",
      "ID",
      "Name",
      "Country",
      "E-mail",
      "Gender",
      "Mobile Inter",
      "Mobile Local",
      "Wallet Balance",
      "Grand Total",
      "Tier",
      "Last Visited",
    ]);

    _.forEach(this.members, (member, idx) => {
      if (!member.memberId || member.memberId === "") return;
      row.push([
        `${idx + 1}.`,
        moment(member.createAt).format("DD/MM/YYYY HH:mm"),
        member.memberId || "",
        member.name || "",
        member.country || "",
        member.email || "",
        member.gender || "",
        member.mobile || "",
        member.mobileLocal || "",
        member.walletBalance || "",
        member.grandTotal || "",
        // member.tier || "",
        "", //? tier
        member.lastVisited
          ? moment(member.lastVisited).format("DD/MM/YYYY HH:mm")
          : "",
      ]);
    });

    this.dataArray = row;

    return row;
  }
}
