import * as type from "../../types/setting";
import initData from "./initData";

const reducer = (state = initData, action) => {
  switch (action.type) {
    case type.FETCH_SETTING_MOTHER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
