import React, { useState } from "react";
import _ from "lodash";
import { ReceiptRow, ReceiptHeader, ReceiptTableContainer } from "./ReceiptRow";
function DashboardVoidBills({
  receipts,
  receiptVoids = [],
  dashboardDispatch
}) {
  function viewCallback(receipt) {
    dashboardDispatch({
      type: "SET_MODAL",
      payload: {
        data: receipt,
        visible: true
      }
    });
  }
  if (receiptVoids.length === 0) {
    return (
      <div class="pb-7">
        <div class="col-lg-12">
          <div class="card card-table">
            <div class="card-body">
              <h4>No Voids Bills data.</h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div class="pb-7">
      <div class="col-lg-12">
        <div class="card card-table">
          <div class="card-header">
            VOIDs
            <div class="tools dropdown">
              <span class="icon mdi mdi-download" />
              <a
                class="dropdown-toggle"
                href="#"
                role="button"
                data-toggle="dropdown"
              >
                <span class="icon mdi mdi-more-vert" />
              </a>
              <div class="dropdown-menu" role="menu">
                <a class="dropdown-item" href="#">
                  Action
                </a>
                <a class="dropdown-item" href="#">
                  Another action
                </a>
                <a class="dropdown-item" href="#">
                  Something else here
                </a>
                <div class="dropdown-divider" />
                <a class="dropdown-item" href="#">
                  Separated link
                </a>
              </div>
            </div>
          </div>
          <div class="card-body">
            <ReceiptTableContainer>
              <ReceiptHeader />
              <tbody>
                {_.map(receiptVoids, (r, i) => {
                  return ( 
                    <ReceiptRow
                      index={i + 1}
                      showVoidButton={false}
                      viewCallback={() => viewCallback(r)}
                      {...r}
                    />
                  );
                })}
              </tbody>
            </ReceiptTableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardVoidBills;
