import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import swal from "sweetalert";
import _ from "lodash";
import * as employeeActions from "../actions/employeeActions";
const initData = {
  name: "",
  oldPin: "",
  pin: "",
  permission: {},
  permissionPage: {},
  memberPermission: {},
  mode: "create",
  uid: "",
  role: "admin"
};
class EmployeeInfoModal extends React.Component {
  state = initData;

  componentWillReceiveProps(nextProps) {
    const { currentEmp, mode } = nextProps;
    if (mode === "create") {
      this.setState({
        ...initData
      });
      return;
    } else {
      this.setState({
        ...currentEmp,
        oldPin: currentEmp.pin,
        mode: "edit"
      });
      return;
    }
  }

  handleOnChange = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  createEmployee = () => {
    const { empList, actions } = this.props;
    const { pin, name } = this.state;
    if (name === "" || pin === "") {
      swal("เกิดข้อผิดพลาด", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
      return;
    }
    let foundEmp = _.find(empList, i => i.pin === pin);

    if (foundEmp) {
      swal("เกิดข้อผิดพลาด", "PIN นี้ถูกใช้แล้ว", "error");
      return;
    }
 
    actions.createEmployee(this.state, () => {
      swal("สำเร็จ", "เพิ่ม Employee สำเร็จ", "success").then(() => {
        window.$("#employeeInfoModal").modal("hide");
      });
    });
  };

  editEmployee = () => {
    const { empList, actions } = this.props;
    const { oldPin, pin, name } = this.state;
    let foundEmp = _.find(empList, i => i.pin === pin);
    if (name === "" || pin === "") {
      swal("เกิดข้อผิดพลาด", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
      return;
    }
    if (oldPin !== pin && foundEmp) {
      swal("เกิดข้อผิดพลาด", "PIN นี้ถูกใช้แล้ว", "error");
      return;
    }

    actions.editEmployee(this.state, () => {
      swal("สำเร็จ", "แก้ไขข้อมูล Employee สำเร็จ", "success").then(() => {
        window.$("#employeeInfoModal").modal("hide");
      });
    });
  };

  setPermission = (name, keyNode) => {
    let newPermission = _.cloneDeep(this.state[keyNode]);

    if (name !== "all" && newPermission.all) return; //ถ้ามี All แล้วกดไม่ได้

    if (newPermission[name]) {
      delete newPermission[name];
    } else {
      newPermission[name] = true;
    }
    this.setState({
      [keyNode]: newPermission
    });
  };

  CustomCheckBoxLeft = ({ keyVal, label }) => {
    const { permission } = this.state;

    return (
      <div
        class="custom-control custom-checkbox"
        onClick={() => this.setPermission(keyVal, "permission")}
      >
        <input
          class="custom-control-input"
          type="checkbox"
          checked={permission[keyVal] === true || permission.all === true}
        />
        <label class="custom-control-label">{label}</label>
      </div>
    );
  };

  CustomCheckBoxRight = ({ keyVal, label }) => {
    const { permissionPage } = this.state;

    return (
      <div
        class="custom-control custom-checkbox"
        onClick={() => this.setPermission(keyVal, "permissionPage")}
      >
        <input
          class="custom-control-input"
          type="checkbox"
          checked={
            permissionPage[keyVal] === true || permissionPage.all === true
          }
        />
        <label class="custom-control-label">{label}</label>
      </div>
    );
  };

  CustomCheckBoxMember = ({ keyVal, label }) => {
    const { memberPermission } = this.state;

    return (
      <div
        class="custom-control custom-checkbox"
        onClick={() => this.setPermission(keyVal, "memberPermission")}
      >
        <input
          class="custom-control-input"
          type="checkbox"
          checked={
            memberPermission[keyVal] === true || memberPermission.all === true
          }
        />
        <label class="custom-control-label">{label}</label>
      </div>
    );
  };

  render() {
    const { name, pin, mode, role } = this.state;

    return (
      <div class="modal" id="employeeInfoModal">
        <div class="modal-content" style={{ width: 400 }}>
          <div class="modal-header">
            <div>
              <span style={{ fontSize: 25 }}>
                <u>Employee's Information</u>
              </span>
            </div>
            <button
              class="close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close" />
            </button>
          </div>
          <div class="modal-body form" style={{ padding: 10 }}>
            <div class="form-group pt-2">
              <label>Name</label>
              <input
                class="form-control"
                value={name}
                name="name"
                onChange={this.handleOnChange}
              />
            </div>
            <div class="form-group pt-2">
              <label>PIN</label>
              <input
                class="form-control"
                value={pin}
                name="pin"
                onChange={this.handleOnChange}
                type="password"
              />
            </div>
            <div class="form-group pt-2">
              <label>Role</label>
              <select
                class="form-control"
                value={role}
                onChange={e => this.setState({ role: e.target.value })}
              >
                <option value="admin">Admin</option>
                <option value="custom">Custom</option>
              </select>
            </div>
            {role === "custom" && [
              <div className="row m-0 pt-2">
                <div className="col-6 p-0">
                  <label style={{ fontWeight: "bold" }}>POS Permission</label>
                  <this.CustomCheckBoxLeft keyVal="all" label="All" />
                  <this.CustomCheckBoxLeft
                    keyVal="voidBills"
                    label="Void Bills"
                  />
                  <this.CustomCheckBoxLeft keyVal="discount" label="Discount" />
                  <this.CustomCheckBoxLeft
                    keyVal="changePrice"
                    label="Change Price"
                  />
                </div>
                <div className="col-6 p-0">
                  <label style={{ fontWeight: "bold" }}>Permission Page</label>
                  <this.CustomCheckBoxRight keyVal="all" label="All" />
                  <this.CustomCheckBoxRight keyVal="pos" label="POS" />
                  <this.CustomCheckBoxRight keyVal="report" label="Report" />
                  <this.CustomCheckBoxRight keyVal="setting" label="Setting" />
                </div>
              </div>,
              <div className="row m-0 pt-2">
                <div className="col-6 p-0">
                  <label style={{ fontWeight: "bold" }}>
                    Member Permission
                  </label>
                  <this.CustomCheckBoxMember keyVal="all" label="All" />
                  <this.CustomCheckBoxMember
                    keyVal="topupWallet"
                    label="Topup Wallet"
                  />
                  <this.CustomCheckBoxMember
                    keyVal="topupPoint"
                    label="Topup Point"
                  />
                </div>
              </div>
            ]}
          </div>
          <div class="modal-footer p-0">
            <button
              class="btn btn-space btn-light m-0"
              type="button"
              style={{
                width: "50%",
                borderRadius: 0,
                height: 50,
                backgroundColor: "lightgrey",
                border: "none"
              }}
              onClick={() => {
                window.$("#employeeInfoModal").modal("hide");
              }}
            >
              Cancel
            </button>

            {mode === "create" ? (
              <button
                class={`btn btn-space btn-success m-0`}
                type="button"
                style={{ width: "50%", borderRadius: 0, height: 50 }}
                onClick={this.createEmployee}
              >
                Create
              </button>
            ) : (
              <button
                class={`btn btn-space btn-warning m-0`}
                type="button"
                style={{ width: "50%", borderRadius: 0, height: 50 }}
                onClick={this.editEmployee}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    empList: state.employee.list
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...employeeActions }, dispatch);

  return {
    actions
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeInfoModal);
