import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import axios from "axios";
import * as appStateAction from "../../../shared/actions/appStateActions";
import { downloadableLinkUrl } from "../../../shared/utils";

function Products(props) {
  const {
    // groups,
    // receipts = [],
    // receipts_entertain = [],
    shop,
    reportReducer,
    isHq,
    hqViewBranch,
  } = props;

  const [showNormalReceipt, setShowNormalReceipt] = useState(true);
  const [showEntertainReceipt, setShowEntertainReceipt] = useState(true);

  const item = useFetchProduct();
  const { products = [], openProduct = [] } = item;
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(appStateAction.loading(true));
    let shopUid = shop.uid;

    if (isHq && hqViewBranch) {
      shopUid = hqViewBranch.uid;
    }

    item.fetchProducts(
      shopUid,
      reportReducer.startDate,
      reportReducer.endDate,
      {
        includeEntertain: showEntertainReceipt,
        notInCludeNormalReceipt: !showNormalReceipt,
      },
      () => {
        //  dispatch(appStateAction.loading(false));
      }
    );
  }, [showNormalReceipt, showEntertainReceipt]);

  const [viewMode, setViewMode] = useState("group"); //top10amount, top10qty
  const [receiptsType, setReceiptsType] = useState("initialState");

  const downloadExcel = () => {
    let URL =
      "https://asia-east2-niceloop-dev.cloudfunctions.net/ExportExcelProducts";

    let options = {
      includeEntertain: showEntertainReceipt,
      notInCludeNormalReceipt: !showNormalReceipt,
    };

    let shopUid = shop.uid;

    if (isHq && hqViewBranch) {
      shopUid = hqViewBranch.uid;
    }

    let param = {
      storeUid: shopUid,
      startDate: reportReducer.startDate,
      endDate: reportReducer.endDate,
      view: "group", // group || bestSellerAmount || bestSellerQty
      ...options,
    };

    dispatch(appStateAction.loading(true));

    axios
      .post(URL, param)
      .then((res) => {
        downloadableLinkUrl("report-summary-excel.xlsx", res.data);
      })
      .catch((err) => alert(err))
      .finally(() => {
        dispatch(appStateAction.loading(false));
      });
  };

  return (
    <div className="card">
      <div class="row p-3">
        <div class="col-3">
          <DataFilter
            showNormalReceipt={showNormalReceipt}
            setShowNormalReceipt={setShowNormalReceipt}
            showEntertainReceipt={showEntertainReceipt}
            setShowEntertainReceipt={setShowEntertainReceipt}
            viewMode={viewMode}
            setViewMode={setViewMode}
          />
        </div>
        <div class="col-6">
          {hqViewBranch && (
            <h4 style={{ color: "blue", fontWeight: "bold" }}>
              {hqViewBranch.name}
            </h4>
          )}
          {viewMode === "group" && <GroupView products={products} />}
          {viewMode !== "group" && (
            <TopTenView products={products} viewMode={viewMode} />
          )}

          {openProduct.length > 0 && (
            <div className="mt-2">
              <TypeProduct name="Open Product" />
              <GroupProducts name="Open Product" items={openProduct} />
            </div>
          )}
        </div>
        <div class="col-3">
          <button class="btn btn-space btn-secondary" onClick={downloadExcel}>
            Export Excel
          </button>
        </div>
      </div>
    </div>
  );
}

export default Products;

function TypeProduct({ name }) {
  return <h3>{name}</h3>;
}

function GroupProducts({ name, items }) {
  return (
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th colSpan="2">{name}</th>
          <th>Qty</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {_.map(items, (item, i2) => {
          return (
            <tr>
              <td style={{ width: "4%" }}>{i2 + 1}.</td>
              <td style={{ width: "70%" }}>{item.name}</td>
              <td style={{ width: "10%" }}>{item.qty.toLocaleString()}</td>
              <td style={{ width: "15%" }}>
                {item.computedPrice.toLocaleString()}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function Seperator() {
  return <div class="dropdown-divider mt-0"></div>;
}

function useFetchProduct(initStoreUid, initStartDate, initEndDate) {
  const [products, setProducts] = useState([]);
  const [openProduct, setOpenProduct] = useState([]);

  const fetchProducts = (storeUid, startDate, endDate, options = {}, cb) => {
    debugger
    const URL =
      "https://bxiqydcg04.execute-api.ap-southeast-1.amazonaws.com/dev/report/product";
    axios
      .post(URL, { storeUid, startDate, endDate, ...options })
      .then((res) => {
        setProducts(res.data.products);
        setOpenProduct(res.data.openProduct);
      })
      .catch((err) => alert(err))
      .finally(() => {
        cb && cb();
      });
  };

  // useEffect(() => {
  //   fetchProducts(initStoreUid, initStartDate, initEndDate);
  // }, []);

  return {
    fetchProducts,
    openProduct,
    products,
  };
}

const GroupView = ({ products }) => {
  return (
    <div>
      {_.reduce(
        products,
        (acc, type) => {
          acc.push(<TypeProduct {...type} />);
          _.forEach(type.groups, (g) => {
            acc.push(<GroupProducts {...g} />);
          });
          acc.push(<Seperator />);
          return acc;
        },
        []
      )}
    </div>
  );
};

const TopTenView = ({ products, viewMode }) => {
  let items = [];
  _.forEach(products, (type) => {
    _.forEach(type.groups, (group) => {
      _.forEach(group.items, (item) => {
        items.push(item);
      });
    });
  });
  let orderByKey = viewMode === "top10amount" ? "computedPrice" : "qty";
  let orderedItems = _.orderBy(items, orderByKey, "desc");

  return (
    <div>
      <table class="table table-sm table-striped">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Qty</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {_.map(orderedItems.slice(0, 10), (item, idx) => {
            return (
              <tr>
                <td style={{ width: "4%" }}>{idx + 1}.</td>
                <td style={{ width: "70%" }}>{item.name}</td>
                <td style={{ width: "10%" }}>{item.qty.toLocaleString()}</td>
                <td style={{ width: "15%" }}>
                  {item.computedPrice.toLocaleString()}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const DataFilter = (props) => {
  return (
    <div>
      <h3>
        <u>Data</u>
      </h3>
      <div class="custom-control custom-checkbox">
        <input
          class="custom-control-input"
          type="checkbox"
          checked={props.showNormalReceipt}
          id="Receipts"
          onClick={() => props.setShowNormalReceipt(!props.showNormalReceipt)}
        />
        <label class="custom-control-label" for="Receipts">
          Receipts
        </label>
      </div>
      <div class="custom-control custom-checkbox">
        <input
          class="custom-control-input"
          type="checkbox"
          checked={props.showEntertainReceipt}
          onClick={() =>
            props.setShowEntertainReceipt(!props.showEntertainReceipt)
          }
          id="Entertains"
        />
        <label class="custom-control-label" for="Entertains">
          Entertains
        </label>
      </div>

      <h3 style={{ marginTop: 20 }}>
        <u>View</u>
      </h3>
      <label class="custom-control custom-radio">
        <input
          class="custom-control-input"
          type="radio"
          checked={props.viewMode === "group"}
          onClick={() => props.setViewMode("group")}
        />
        <span class="custom-control-label">Group</span>
      </label>
      <label class="custom-control custom-radio">
        <input
          class="custom-control-input"
          type="radio"
          checked={props.viewMode === "top10amount"}
          onClick={() => props.setViewMode("top10amount")}
        />
        <span class="custom-control-label">Best Seller (Amount)</span>
      </label>
      <label class="custom-control custom-radio">
        <input
          class="custom-control-input"
          type="radio"
          checked={props.viewMode === "top10qty"}
          onClick={() => props.setViewMode("top10qty")}
        />
        <span class="custom-control-label">Best Seller (Qty)</span>
      </label>
    </div>
  );
};
