import _ from "lodash";
import csvUtil from "./service.generateCsv";
import moment from "moment";

export default class excelReportProduct {
  dataArray = [];
  startDate = moment();
  dateType = "day";
  shopName = "";
  //   endDate = moment();
  constructor(menu) {
    this.menu = menu;
  }

  build() {
    let date = moment().format("DD-MM-YYYY HH:mm:ss");
    this.computeCsvData(date);

    new csvUtil().genCsv(`Product-List-${date}`, this.dataArray);
  }

  computeCsvData(date) {
    var row = [];

    row.push(["Product List"]);
    row.push([""]);
    row.push([`Exported at: ${date}`]);
    row.push([""]);
    row.push(["Name", "Price", "Unit", "Time", "SKU/Barcode"]);

    _.forEach(this.menu, type => {
      row.push([`####${type.name}`]);
      _.forEach(type.groups, group => {
        row.push([`##${group.name}`]);
        _.forEach(group.items, item => {
          row.push([
            item.name || "",
            item.price || "",
            item.unit || "",
            item.timeMinutes || "",
            item.barcode || ""
          ]);
        });
      });
    });

    this.dataArray = row;

    return row;
  }
}
