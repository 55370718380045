import csvUtil from "./service.generateCsv";
import documentClient from "../awsAPIs/dynamoDb";

var moment = require("moment");
var _ = require("lodash");

class exportCsv {
  //    receipts = [];

  constructor(receipts) {
    this.receipts = receipts;
  }

  build() {
    this.computeCsvData();
    new csvUtil().genCsv(`Divana_All_Receipts`, this.dataArray);
  }

  computeCsvData() {
    var row = [];

    row.push(["Divana's All Receipts"]);
    row.push([""]);
    row.push([`Exported at: ${moment().format("DD-MM-YYYY HH:mm:ss")}`]);
    row.push([""]);
    row.push([
      "Branch Id",
      "Receipt Id",
      "Ref Id",
      "Date",
      "Member Id",
      "Name",
      "Nation",
      "Gender",
      "BirthDate",
      "Age",
      "GrandTotal"
    ]);

    _.forEach(this.receipts, rec => {
      var { store, receiptId, refId, businessDate, member = {}, payment } = rec;
      let branchId = store.branchId || "";
      var age =
        member && member.birthDate
          ? moment().diff(moment(member.birthDate, "YYYY-MM-DD"), "years")
          : "";
      row.push([
        branchId,
        receiptId || "",
        refId || "",
        businessDate || "",
        (member && member.memberId) || "",
        (member && member.name) || "",
        (member && member.country) || "",
        (member && member.gender) || "",
        (member && member.birthDate) || "",
        age,
        payment.grandTotal || ""
      ]);
    });

    this.dataArray = row;

    return row;
  }
}

async function main() {
  var receipts = [];
  var fetchTime = 1;
  var lastKey = null;
  do {
    // ExclusiveStartKey
    var { resReceipts, newKey } = await asyncScanReceipts(lastKey);
    lastKey = newKey;
    receipts = [...receipts, ...resReceipts];
    console.log(fetchTime);
    fetchTime++;
  } while (newKey);

  let ordered = _.orderBy(
    receipts,
    ["store.branchId", "businessDate"],
    ["asc", "asc"]
  );

  new exportCsv(ordered).build();
}

function asyncScanReceipts(lastKey) {
  return new Promise(resolve => {
    let params = {
      TableName: "Spa_Receipts"
    };

    if (lastKey) {
      params.ExclusiveStartKey = lastKey;
    }

    documentClient.scan(params, (err, data) => {
      if (err) {
        return console.log(err);
      } else {
        var filtered = _.filter(data.Items, i => i.hqUid === "03577879");
        resolve({ resReceipts: filtered, newKey: data.LastEvaluatedKey });
      }
    });
  });
}

window.genDivanaReceipts = main;
