import React, { useState } from "react";
import InventoryItemList from "./InventoryItemList";
import VatOverride from "./VatOverride";
import BranchItemOverride from "./BranchItemOverride";

function InventoryItemContainer(props) {
  const [tab, setTab] = useState("main"); //main, vat, branchItemOverride
  return (
    <div className="main-content container-fluid p-0">
      <div class="tab-container">
        <ul class="nav nav-tabs" role="tablist">
          <Tab
            label="Main"
            active={tab === "main"}
            onClick={() => setTab("main")}
          />
          {/* <Tab
            label="Vat Override"
            active={tab === "vatOverride"}
            onClick={() => setTab("vatOverride")}
          /> */}
          <Tab
            label="Branch Price Override"
            active={tab === "branchItemOverride"}
            onClick={() => setTab("branchItemOverride")}
          />
        </ul>
        {/* <div class="tab-content">
          <div class="tab-pane active" id="home" role="tabpanel">
            edfkefkeofoekofe
          </div>
        </div> */}
      </div>

      <div className="p-3">
        {tab === "main" && <InventoryItemList {...props} />}
        {tab === "vatOverride" && <VatOverride {...props} />}
        {tab === "branchItemOverride" && <BranchItemOverride {...props} />}
      </div>
    </div>
  );
}

export default InventoryItemContainer;

const Tab = ({ active, label, onClick }) => {
  return (
    <li class="nav-item">
      <a
        class={`nav-link ${active ? "active" : ""}`}
        href="#home"
        data-toggle="tab"
        role="tab"
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
      >
        {label}
      </a>
    </li>
  );
};
