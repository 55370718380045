import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";

function MemberStoreVisited(props) {
  const { receipts } = props;
  const [store, setStore] = useState([]);

  useEffect(() => {
    computeProduct();
  }, [receipts]);

  const computeProduct = () => {
    let objStore = {};
    _.forEach(receipts, rec => {
      let store = rec.store;
      if (!objStore[store.uid]) {
        objStore[store.uid] = {
          ...store,
          time: 0,
          grandTotal: 0,
          lastVisit: rec.timestamp
        };
      }
      objStore[store.uid].time++;
      objStore[store.uid].grandTotal += rec.payment.grandTotal;

      //FIND NEWEST LAST VISIT
      let curVisit = moment(objStore[store.uid].lastVisit);
      let nextVisit = moment(rec.timestamp);
      // let ccc = curVisit.format("DD/MM/YYYY");
      // let nnn = nextVisit.format("DD/MM/YYYY");
      let diff = curVisit.diff(nextVisit, "days");

      if (diff < 0) {
        objStore[store.uid].lastVisit = rec.timestamp;
      }
    });
    let ordered = _.orderBy(objStore, "grandTotal", "desc");
    setStore(ordered);
  };

  return (
    <div class="card card-table">
      <div class="card-body">
        <div>
          <table
            class="table table-striped table-borderless"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Time</th>
                <th>Grand Total</th>
                <th>Last Visited</th>
              </tr>
            </thead>

            <tbody class="no-border-x">
              {_.map(store, item => {
                return (
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.time}</td>
                    <td>{item.grandTotal.toLocaleString()}</td>
                    <td>{moment(item.lastVisit).format("DD/MM/YYYY")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MemberStoreVisited;
