import logger from "redux-logger";
import ReduxThunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";

import InitMiddleware from "./middleware/init.middleware";
import reducers from "./combineReducers";
import PubSub from "pubsub-js";
import printerThermalMiddlwrare from "./printer/thermalprinter.middleware";
import ComputeProductMiddleware from "./middleware/compute.products.middleware";
import ReceiptOfflineMiddleware from "./middleware/receiptid.middleware";
import businessDateMiddleware from "./middleware/businessDate.middleware";
import rebuildTherapistDatabase from "./fix-script/re-database-therapist";
import fixMemberIdToUppercase from "./fix-script/fixMemberIdToUppercase";
// import * as  setSpaLiveScoreMiddleware from "./middleware/setSpaLiveScore.middleware";
const store = createStore(
  reducers,
  applyMiddleware(
    ReduxThunk,
    logger,
    InitMiddleware,
    printerThermalMiddlwrare,
    ComputeProductMiddleware,
    ReceiptOfflineMiddleware,
    businessDateMiddleware
    // setSpaLiveScoreMiddleware
  )
);

store.dispatch({
  type: "INIT"
});

// store.state.user.user1
// store.dispatch

// setTimeout(()=>{
//   debugger
//   setSpaLiveScoreMiddleware.createDivanaTherapist()
// },10000)

let lastInventoryState = null;
store.subscribe(() => {
  var inventoryState = store.getState().inventory;

  if (lastInventoryState !== inventoryState) {
    lastInventoryState = inventoryState;
    PubSub.publish("INVENTORY_REDUCER", inventoryState);
  }
});

export default store;
