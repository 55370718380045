import moment from "moment";
import Paper from "niceloop-paper";
import _ from "lodash";

// payment receipts
class PrintReceipt {
  booking = null;
  dispatch = null;
  printCmdResult = null;
  printType = "RECEIPT_FULL";

  constructor(dispatch, booking) {
    this.dispatch = dispatch;
    this.booking = booking;
    // this.build();
    // this.print();
  }

  build() {
    //obj is table instance

    var booking = this.booking;
    var Order = {};

    Order.UniqueId = this.getRandomInt(99999999999);

    Order.Printer = [0];
    Order.Title1 = null;
    Order.Title2 = `Customer : ${booking.name}`;

    Order.isPreview = true;

    Order.CustomHeaders1 = this.genSummaryHeader();
    Order.CustomHeaders2 = null;
    Order.CustomHeaders3 = null;
    Order.CustomHeaders4 = null;
    Order.CustomHeaders5 = null;
    Order.CustomHeaders6 = null;
    Order.CustomHeaders7 = null;
    Order.CustomHeaders8 = null;
    Order.CustomHeaders9 = null;
    Order.CustomHeaders10 = null;

    Order.Table = null;

    Order.OrderItems = null;
    Order.Summary1 = [];
    Order.Summary2 = [];
    Order.GrandTotal = null;
    // Order.CustomFooters1 = this.convertItemsToPrint();
    Order.CustomFooters1 = null;
    Order.CustomFooters2 = null;
    Order.CustomFooters3 = null;
    Order.CustomFooters4 = null;
    Order.CustomFooters5 = null;
    Order.CustomFooters6 = null;
    Order.CustomFooters7 = null;
    Order.CustomFooters8 = null;
    Order.CustomFooters9 = null;
    Order.CustomFooters10 = null;
    Order.Note = null;

    this.Order = Order;
    return this;
  }

  genSummaryHeader() {
    var paper = new Paper();
    let { transactionUid, timeUid, data, originalCourses } = this.booking;

    paper.title("Course from WEB");
    paper.lineFeed();
    paper.keyValue(`Transaction Uid : ${transactionUid}`);
    // paper.keyValue(`Time Uid : ${timeUid}`);
    paper.keyValue(`Create Time : ${data.createtime}`);
    paper.lineFeed();

    _.forEach(originalCourses, course => {
      paper.keyValue(`SKU: ${course.sku}`);
      //   paper.keyValue(`Name: ${course.name}`);
      if (course.name.length > 37) {
        let chunkName = chunkString(course.name, 35);

        _.forEach(chunkName, (text, idx) => {
          if (idx === 0) {
            paper.keyValue(`Name: ${text}`);
          } else {
            paper.keyValue(`           ${text}`);
          }
        });
      } else {
        paper.keyValue(`Name: ${course.name}`);
      }
      paper.keyValue(`Time: ${course.time}Mins`);
      paper.keyValue(`Price: ${course.price.toLocaleString()}฿`);
      paper.lineFeed();
    });

    paper.title("Course POS (Auto-Matched)");
    paper.lineFeed();
    _.forEach(this.booking.course, course => {
      paper.keyValue(`SKU: ${course.barcode}`);
      //   paper.keyValue(`Name: ${course.name}`);
      if (course.name.length > 37) {
        let chunkName = chunkString(course.name, 35);

        _.forEach(chunkName, (text, idx) => {
          if (idx === 0) {
            paper.keyValue(`Name: ${text}`);
          } else {
            paper.keyValue(`           ${text}`);
          }
        });
      } else {
        paper.keyValue(`Name: ${course.name}`);
      }
      paper.keyValue(`Time: ${course.timeMinutes} Mins`);
      paper.keyValue(`Price: ${course.price.toLocaleString()}฿`);
      paper.lineFeed();
    });

    return paper.gen();
  }

  //   convertItemsToPrint() {
  //     var paper = new Paper();
  //     let { therapist } = this.booking;

  //     let hasMultiTher = false;

  //     //ITEMS
  //     _.forEach(therapist.courses, course => {
  //       //SHOW COURSE TIME
  //       let time = convertMinuteToHour(course.workMinutes);
  //       if (course.workMinutes !== course.courseMinutes) {
  //         time += "*";
  //         hasMultiTher = true;
  //       }

  //       if (course.courseName.length > 30) {
  //         let chunkName = chunkString(course.courseName, 28);

  //         _.forEach(chunkName, (text, idx) => {
  //           if (idx === 0) {
  //             paper.keyValue(`${course.courseQty} x ${text}`, time);
  //           } else {
  //             paper.keyValue(`     ${text}`);
  //           }
  //         });
  //       } else {
  //         paper.keyValue(`${course.courseQty} x ${course.courseName}`, time);
  //       }
  //     });

  //     //SHOW TOTAL WORK TIME
  //     let totalHours = convertMinuteToHour(therapist.totalWorkMinutes);
  //     paper.lineFeed();
  //     paper.drawLine();
  //     // if (totalHours !== "0.0") {
  //     paper.keyValue("", "Total(Hr.)  " + totalHours);
  //     // }
  //     paper.draw2Line();

  //     if (hasMultiTher) {
  //       paper.comment("* Some courses has multiple therapists");
  //       paper.comment("  operated");
  //     }
  //     return paper.gen();
  //   }

  print() {
    this.dispatch({
      type: "PRINT",
      meta: {
        printType: this.printType,
        type: "PRINT"
      },
      payload: this.Order
    });
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
}

export default PrintReceipt;

const convertMinuteToHour = timeMinutes => {
  var hours = Math.floor(timeMinutes / 60);
  var minutes = timeMinutes % 60;
  let time = hours + "." + minutes;
  return time;
};

const chunkString = (str, len) => {
  var _size = Math.ceil(str.length / len),
    _ret = new Array(_size),
    _offset;
  for (var _i = 0; _i < _size; _i++) {
    _offset = _i * len;
    _ret[_i] = str.substring(_offset, _offset + len);
  }
  return _ret;
};
