import * as firestore from "../shared/firebaseApi/firebaseRef";

const businessDateMiddleware = store => next => {
  setTimeout(() => {
    let { shop } = store.getState();
    if (!shop.detail == undefined) {
      return;
    }
    firestore.appStateRef
      .doc(shop.detail.uid || "abc")
      .onSnapshot(function(doc) {
        console.log("Current data: ", doc.data());
        let data = doc.data() || {};

        store.dispatch({
          type: "APP_STATE_SET",
          key: "businessDate",
          payload: data["businessDate"] || null
        });
      });
  }, 3000);

  return action => {
    return next(action);
  };
};

export default businessDateMiddleware;
