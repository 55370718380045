import React, { Component } from "react";
import "react-datasheet/lib/react-datasheet.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import _ from "lodash";
import Container from "./layout/Container";
import "react-block-ui/style.css";
// import "./App.css";

import "./Custom.css";
import "react-notifications/lib/notifications.css";

import * as abc from './fix-script/createTherapist2020'
import * as recomputememberwalletbalance from './fix-script/reComputeMemberWalletBalance'
class App extends Component {
  render() {
    return (
      <div>
        <Provider store={this.props.store}>
          <Router>
            <Container />
          </Router>
        </Provider>
      </div>
    );
  }
}

export default App;
