import React, { useState, useEffect } from "react";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import _ from "lodash";
import moment from "moment";
import swal from "sweetalert";

function MemberInternalNote(props) {
  const [noteList, setNoteList] = useState([]);

  const fetchMemberInternalNotes = () => {
    var params = {
      TableName: "Crm_Users", 
      Key: {
        uid: props.member.uid
      }
    };
    documentClient.get(params, (err, data) => {
      if (err) {
        console.log(err);
        return;
      }
      setNoteList(data.Item.internalNotes || []);
    });
  };
  const updateNotes = (notes, cb) => {
    var params = {
      TableName: "Crm_Users",
      Key: { uid: props.member.uid },
      UpdateExpression: "set internalNotes = :internalNotes",
      //   ExpressionAttributeNames: { "#a": "Sum" },
      ExpressionAttributeValues: {
        ":internalNotes": notes
      }
    };
    documentClient.update(params, err => {
      if (err) return console.log(err);
      fetchMemberInternalNotes();
      cb && cb();
    });
  };

  useEffect(() => {
    fetchMemberInternalNotes();
  }, []);

  return (
    <div className="card">
      <div className="p-3">
        <h3>
          Notes <span style={{ color: "grey" }}>{noteList.length}</span>
        </h3>
        {_.map(noteList, (note, idx) => {
          return (
            <div className={`mt-${idx === 0 ? "0" : "2"}`}>
              <NoteLine
                {...props}
                note={note}
                noteList={noteList}
                updateNotes={updateNotes}
                idx={idx}
              />
            </div>
          );
        })}
        <div className="mt-3" />
        <AddNewNote {...props} noteList={noteList} updateNotes={updateNotes} />
      </div>
    </div>
  );
}

export default MemberInternalNote;

const iconStyle = { color: "grey", fontSize: 19 };

const NoteLine = props => {
  const { note, noteList, idx } = props;
  const [inputText, setInputText] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  let splitedNote = _.split(note.text, "\n");

  const handleSetIsEdit = () => {
    if (isEdit === false) {
      setInputText(note.text);
    } else {
      editNote();
    }
    setIsEdit(!isEdit);
  };

  const editNote = () => {
    let tmpNotes = [
      ...noteList.slice(0, idx),
      {
        text: inputText,
        timestamp: moment().toISOString(),
        employee: props.currentEmp
      },
      ...noteList.slice(idx + 1)
    ];

    props.updateNotes(tmpNotes, () => {
      setInputText("");
    });
  };
  const removeNote = () => {
    let tmpNotes = [...noteList.slice(0, idx), ...noteList.slice(idx + 1)];
    swal({
      title: `Remove this note ?`,
      icon: "warning",
      buttons: true
    }).then(willDelete => {
      if (willDelete) {
        props.updateNotes(tmpNotes);
      }
    });
  };

  return (
    <div
      style={{
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "lightgrey",
        borderRadius: 3,
        margintBottom: 10
      }}
    >
      <div className="p-3">
        {!isEdit &&
          _.map(splitedNote, (line, idx) => {
            return (
              <div
                className={`pt-${idx !== 0 ? "2" : "0"}`}
                // style={{ fontWeight: "bold" }}
              >
                {line}
              </div>
            );
          })}

        {isEdit && (
          <textarea
            className="form-control"
            value={inputText}
            onChange={e => setInputText(e.target.value)}
            style={{ minHeight: 160 }}
          />
        )}

        <div className="row m-0 mt-4 justify-content-between">
          <div>
            <span style={{ color: "grey" }}>
              {moment(note.timestamp).format("DD/MM/YYYY, HH:mm")}
            </span>{" "}
            {note.employee && <span>{note.employee.name}</span>}
          </div>
          <div>
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                handleSetIsEdit();
              }}
            >
              {isEdit === false ? (
                <span class="mdi mdi-edit" style={iconStyle}></span>
              ) : (
                <span class="mdi mdi-check" style={iconStyle}></span>
              )}
            </a>
            <a
              style={{ paddingLeft: 15 }}
              href="#"
              onClick={e => {
                e.preventDefault();
                removeNote();
              }}
            >
              <span class="mdi mdi-delete" style={iconStyle}></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddNewNote = props => {
  const [inputText, setInputText] = useState("");
  const createNote = () => {
    let tmpNotes = _.cloneDeep(props.noteList);
    tmpNotes.push({
      text: inputText,
      timestamp: moment().toISOString(),
      employee: props.currentEmp
    });

    props.updateNotes(tmpNotes, () => {
      setInputText("");
    });
  };

  return (
    <div>
      <div>
        <textarea
          className="form-control"
          value={inputText}
          onChange={e => setInputText(e.target.value)}
        />
      </div>
      <div className="pt-1">
        <button
          class="btn btn-space btn-primary"
          style={{ width: "100%" }}
          onClick={createNote}
        >
          <i class="icon icon-left mdi mdi-cloud-done"></i>Add
        </button>
      </div>
    </div>
  );
};
