import React, { useState, useReducer } from "react";
import ReportMenu from "./ReportMenu";
import ReportQuery from "./ReportQuery";
import _ from "lodash";
import {
  Router,
  Route,
  Switch,
  withRouter,
  HashRouter,
  Link
} from "react-router-dom";
import { initState, reducer } from "./reducer";
import Receipts from "./web/Receipts";
import SpaBillModal from "../../shared/components/SpaBillModal";
import PaymentTypes from "./web/PaymentTypes";
import Products from "./web/Products";
import { useSelector } from "react-redux";
import DashboardTherapistTable from "./web/TherapistTable";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as branchOptionsSelector from "../../shared/selector/branchOptionsSelector";
import * as hqReportActions from "../../shared/actions/hqReportActions";

const BUTTONS_ALL = ["web", "excel", "pdf", "thermal", "email"];
const BUTTONS_DOWNLOAD_ONLY = ["excel", "pdf"];
const BUTTONS_DOWNLOAD_AND_PRINT = ["excel", "pdf", "thermal"];
const DATE_MODE_SINGLE = "single";
const DATE_MODE_RANGE = "range";
const DATE_MODE_MONTH = "month";

const BASE_PATH = "/reports3/spa";
const PDF_URL =
  "https://asia-east2-niceloop-dev.cloudfunctions.net/PDFSummaryByDate";

const EXCEL_URL =
  "https://asia-east2-divana-project.cloudfunctions.net/excelReport";

const QueryRoute = [
  {
    //TODO: web, excel, pdf
    title: "Summary-Date",
    dateMode: DATE_MODE_SINGLE,
    path: "/summary-date",
    showButtons: BUTTONS_ALL,
    Component: ReportQuery,
    webPath: "web"
  },
  {
    //TODO: excel, pdf
    title: "Receipts",
    dateMode: DATE_MODE_SINGLE,
    path: "/receipts",
    showButtons: ["web", "excel"],
    Component: ReportQuery,
    webPath: "web",
    excel: {
      url: EXCEL_URL,
      type: "RECEIPTS" 
    }
  },

  {
    //TODO: excel, pdf
    title: "Receipts",
    dateMode: DATE_MODE_RANGE,
    path: "/receipts-range",
    showButtons: ["excel"],
    Component: ReportQuery,
    excel: {
      url: EXCEL_URL,
      type: "SUPER_REPORT"
    }
  },
  {
    //TODO: excel, pdf
    title: "Summary Daily Sale",
    dateMode: DATE_MODE_RANGE,
    path: "/summary-daily-sale",
    showButtons: ["excel"],
    Component: ReportQuery,
    excel: {
      url: EXCEL_URL,
      type: "SUMMARY_SALE_DAILY"
    }
  },

  // {
  //   title: "receipts-void",
  //   dateMode: DATE_MODE_RANGE,
  //   path: "/receipts-void",
  //   showButtons: BUTTONS_DOWNLOAD_ONLY,
  //   Component: ReportQuery,
  //   webPath: null
  // },
  {
    //TODO:  pdf, thermal
    title: "Payment Types",
    dateMode: DATE_MODE_RANGE,
    path: "/payment-types",
    showButtons: ["web", "excel"],
    Component: ReportQuery,
    webPath: "web",
    excel: {
      url: EXCEL_URL,
      type: "PAYMENT_TYPES"
    }
  },

  {
    //ok
    title: "Products",
    dateMode: DATE_MODE_RANGE,
    path: "/products",
    showButtons: ["web", "excel"],
    Component: ReportQuery,
    webPath: "web",
    excel: {
      url:
        "https://asia-east2-niceloop-dev.cloudfunctions.net/ExportExcelProducts",
      options: {
        view: "group",
        includeEntertain: true,
        notInCludeNormalReceipt: false
      }
    }
  },
  {
    //TODO: excel, pdf
    title: "Products",
    dateMode: DATE_MODE_RANGE,
    path: "/products-new",
    showButtons: ["excel"],
    Component: ReportQuery,
    excel: {
      url: EXCEL_URL,
      type: "PRODUCT_TYPES"
    }
  },
  {
    title: "Therapist Working Coupons",
    dateMode: DATE_MODE_RANGE,
    path: "/therapist-working-coupons",
    showButtons: ["excel", "pdf"],
    Component: ReportQuery,
    filterList: "THERAPIST",
    pdf: {
      url: PDF_URL,
      mode: "therapist" // agent //city ledger
    },
    excel: {
      url: EXCEL_URL,
      type: "THERAPIST"
    }
  },
  {
    //TODO:  web, excel
    title: "Therapist Working Hours",
    dateMode: DATE_MODE_MONTH,
    path: "/therapist-working-hours",
    absPath: "/reports2/spa",
    showButtons: ["web", "excel"],
    Component: ReportQuery,
    webPath: "web"
    // filterList: "THERAPIST",
  },
  {
    //TODO:  web, excel
    title: "Therapist Table Hours",
    dateMode: DATE_MODE_MONTH,
    path: "/therapist-table-hours",
    absPath: "/reports2/spa",
    showButtons: "excel",
    Component: ReportQuery,
    webPath: "web",
    excel: {
      url: EXCEL_URL,
      type: "THERAPIST_GROUP_BY_DATE"
      
    }
    // filterList: "THERAPIST",
  },

  {
    title: "City Ledger",
    dateMode: DATE_MODE_RANGE,
    path: "/city-ledger",
    showButtons: BUTTONS_DOWNLOAD_ONLY,
    Component: ReportQuery,
    filterList: "CITY_LEDGER",
    pdf: {
      url: PDF_URL,
      mode: "company" // agent //city ledger
    },
    excel: {
      url: EXCEL_URL,
      type: "CITY_LEDGER"
    }
  },
  {
    //TODO:  excel
    title: "Hotel Sales",
    dateMode: DATE_MODE_RANGE,
    path: "/hotel-sales",
    showButtons: BUTTONS_DOWNLOAD_ONLY,
    Component: ReportQuery,
    filterList: "SALES",
    pdf: {
      url: PDF_URL,
      mode: "sales"
    },
    excel: {
      url: EXCEL_URL,
      type: "SALES"
    }
  },

  // options
  {
    //TODO:  excel, pdf
    title: "Options Discount",
    dateMode: DATE_MODE_RANGE,
    path: "/options-discount",
    showButtons: BUTTONS_DOWNLOAD_ONLY,
    Component: ReportQuery
  },

  {
    //TODO:  excel, pdf
    //TODO:  MAIN

    title: "Options Entertain",
    dateMode: DATE_MODE_RANGE,
    path: "/options-entertain",
    showButtons: ["excel"],
    Component: ReportQuery,
    excel: {
      url: EXCEL_URL,
      type: "OPTIONS/ENTERTAIN"
    }
  },

  {
    //TODO:  excel, pdf

    title: "Options Prepaid",
    dateMode: DATE_MODE_RANGE,
    path: "/options-prepaid",
    showButtons: BUTTONS_DOWNLOAD_ONLY,
    Component: ReportQuery
  },

  {
    //TODO:  excel, pdf

    title: "Options Voucher",
    dateMode: DATE_MODE_RANGE,
    path: "/options-voucher",
    showButtons: BUTTONS_DOWNLOAD_ONLY,
    Component: ReportQuery
  }
];

function Index(props) {
  const { match, history, branchOptions } = props;
  const [reportReducer, reportDispatch] = useReducer(reducer, initState);
  const { shop, user } = useSelector(state => {
    return {
      user: state.user.loggedIn,
      shop: state.shop.detail
    };
  });

  function _genQueryView(title, dateMode, showButtons, options = {}) {
    return {
      showButtons,
      title,
      dateMode,
      reportReducer,
      shop,
      reportDispatch,
      ...options
    };
  }

  function _genReducer() {
    return {
      reportReducer,
      reportDispatch,
      shop
    };
  }

  return (
    <div class="row">
      <SpaBillModal
        reportDispatch={reportDispatch}
        {...reportReducer.modal.data}
      />

      {/* <div class="col-12">
        <Link to={BASE_PATH}>
          <button>Back</button>
        </Link>
      </div> */}

      {/* <div>
        {_.map(reportReducer, (v, k) => (
          <p>
            {k} : {v && v.toString()}
          </p>
        ))}
      </div> */}
      <div class="col-12">
        <Switch>
          {_.map(QueryRoute, (r, i) => {
            return (
              <Route
                key={i}
                exact={true}
                path={match.path + r.path}
                component={() => (
                  <r.Component
                    {...props}
                    {...r}
                    shop={shop}
                    reportReducer={reportReducer}
                    reportDispatch={reportDispatch}
                  />
                )}
              />
            );
          })}
        </Switch>
      </div>
      <div class="col-12">
        <Switch>
          {/* web */}
          <Route
            exact
            path={match.path + "/receipts/web"}
            component={() => (
              <Receipts {...props} {..._genReducer()} history={history} />
            )}
          />

          <Route
            exact
            path={match.path + "/therapist-working-hours/web"}
            component={() => (
              <DashboardTherapistTable
                {...props}
                {..._genReducer()}
                history={history}
              />
            )}
          />

          <Route
            exact
            path={match.path + "/payment-types/web"}
            component={() => (
              <PaymentTypes {...props} {..._genReducer()} history={history} />
            )}
          />

          <Route
            exact
            path={match.path + "/products/web"}
            component={() => (
              <Products {...props} {..._genReducer()} history={history} />
            )}
          />

          {/* route */}

          <Route
            exact
            path={match.path}
            render={nextProps => (
              <ReportMenu
                {...props}
                {...nextProps}
                branchOptions={branchOptions}
              />
            )}
            history={history}
          />
        </Switch>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isHq: !state.shop.linked.inventory,
    hqViewBranch: state.hqReport.viewBranch,
    branchOptions: branchOptionsSelector.branchOptionsSelector(state)
  };
};
function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators(
    {
      ...hqReportActions
    },
    dispatch
  );
  return {
    actions
    // dispatch
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Index));
