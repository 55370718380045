import React, { Component } from 'react'
import LeftAsideContainer from '../../shared/components/LeftAsideContainer'
import { connect } from 'react-redux'
import * as linkedSelector from '../../shared/selector/linkedSelector'

class ProductSummaryAside extends Component {

    state = {
        remark: ''
    }

    render() {
        const { totalQty, totalPrice, onClickPurchase, onClickEditInvoice, isEdit, isLinked, cartHqInfo, loading, handleClickBackStep } = this.props
        return (

            <div className="card">

                <div className="card-header card-header-divider">
                    <button className="btn btn-rounded btn-space btn-secondary" onClick={handleClickBackStep}>ย้อนกลับแก้ไข</button>
                </div>
                {
                    !isLinked &&
                    <div className="card-header card-header-divider"><span style={{ fontSize: 20, fontWeight: 'normal', color: 'blue' }}>{(cartHqInfo.destination && cartHqInfo.destination.label) || ''}</span></div>
                }
                <div class={`card-header card-header${!isLinked ? '' : '-divider'}`}><span style={{ fontSize: 20, fontWeight: 'normal' }}>ยอดรวมสุทธิ</span></div>
                <div className="card-body">
                    <table className="table table-sm table-hover table-bordered">
                        <tr>
                            <td>จำนวนสินค้า</td>
                            <td>{totalQty} ชิ้น</td>
                        </tr>
                        <tr>
                            <td>ยอดรวมทั้งหมด</td>
                            <td>{totalPrice.toLocaleString()} บาท</td>
                        </tr>



                    </table>
                    {
                        !isEdit &&
                        <div className="form-group pt-2">
                            <label for="">หมายเหตุ</label>
                            <textarea value={this.state.remark} className="form-control" onChange={e => this.setState({ remark: e.target.value })}></textarea>
                        </div>
                    }

                    {
                        isEdit ? <button onClick={onClickEditInvoice} style={{ width: '100%', marginTop: 10 }} className="btn btn-rounded btn-space btn-warning" disabled={loading}>{loading ? 'Loading...' : 'แก้ไข'}</button> :
                            <button onClick={() => onClickPurchase(this.state.remark)} style={{ width: '100%', marginTop: 10 }} className="btn btn-rounded btn-space btn-primary" disabled={loading}>{loading ? 'Loading...' : 'สั่งซื้อสินค้า'}</button>
                    }
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        cartHqInfo: state.cart.cartHqInfo,
        isLinked: linkedSelector.isLinkedSelector(state)
    }
}
function mapDispatchToProps(dispatch) {
    // let actions = bindActionCreators({ ...cartAction }, dispatch)
    return {
        // actions,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductSummaryAside)