import React, { useState } from "react";
import MemberList from "./MemberList";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import * as memberTxActions from "../../shared/actions/memberTxActions";
import * as settingSelector from "../../shared/selector/settingSelector";
import * as voucherSelector from "../../shared/selector/voucherSelector";

function Member(props) {
  const [tab, setTab] = useState("memberList"); //memberList, memberExpire

  const Tab = ({ label, keyNode }) => {
    return (
      <li class="nav-item">
        <a
          class={`nav-link ${tab === keyNode ? "active" : ""}`}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setTab(keyNode);
          }}
        >
          {label}
        </a>
      </li>
    );
  };

  return (
    <div>
      <div class="tab-container">
        <ul class="nav nav-tabs" role="tablist">
          <Tab label="Member List" keyNode="memberList" />
          <Tab label="Member Expired" keyNode="memberExpired" />
        </ul>
        {/* <div className="tab-content"> */}
        <div>
          {tab === "memberList" && <MemberList {...props} />}
          {tab === "memberExpired" && (
            <MemberList {...props} memberExpired={true} />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    linked: state.shop.linked.inventory,
    shop: state.shop.detail,
    currentEmp: state.employee.current,
    tagsOptions: settingSelector.tagOptionsSelector(state),
    voucherOptions: voucherSelector.voucherOptionsSelector(state),
  };
};
function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...memberTxActions }, dispatch);

  return {
    actions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Member);
