
import moment from 'moment'

export const downloadableLinkUrl = (fileName, url,)=>{

    let link = window.document.createElement("a");
    link.download = fileName;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
}



export const computeMinutes = (start, end)=>{

    return moment(end).diff(start, 'minute')
}


export const checkPermission = (currentEmployee ,  employeeList = [],  permissionKey) =>{

    if(currentEmployee === null){
        return true
    }
    if(employeeList.length === 0){
        return true
    }
    if(currentEmployee.role === 'admin' || currentEmployee.role === undefined){
        return true
      }

      if(currentEmployee.role === 'custom' && currentEmployee.permission && currentEmployee.permission[permissionKey]){
        return true

      }

      return false
}