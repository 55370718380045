import React, { useState, useEffect } from "react";
import _ from "lodash";
import * as inventorySelector from "../selector/inventorySelector";
function useComputeProductsReport(receipts, state) {
  // const [products, setState] = useState({});

  // useEffect(() => {
  //   debugger;
  //   compute();
  // }, [receipts]);

  let products = {};

  // const compute = () => {
  let groups = inventorySelector.groupItemSelector(state);

  let departmentTypes = inventorySelector.typeGroupItemSelector(state);

  let tmpProducts = {};
  let local_recepits = _.filter(receipts, (r) => !r.void);
  _.map(local_recepits, (rec) => {
    //กรอง promotion items ออก
    let filteredItems = _.filter(rec.items, (i) => i.promotion !== true);

    _.forEach(filteredItems, (item) => {
      if (!tmpProducts[item.uid]) {
        tmpProducts[item.uid] = {
          computedPrice: 0,
          qty: 0,
        };
      }
      //
      // tmpProducts[item.uid].computedPrice += item.price;
      tmpProducts[item.uid].computedPrice += item.extendedPrice;
      tmpProducts[item.uid].qty += item.qty;
    });
  });

  let productArr = [];

  _.forEach(groups, (group) => {
    let tmpItems = [];
    let computedPrice = 0;
    let qty = 0;
    _.forEach(group.items, (item) => {
      let currentProduct = tmpProducts[item.uid];
      if (currentProduct) {
        tmpItems.push({ ...item, ...currentProduct });
        computedPrice += currentProduct.computedPrice;
        qty += currentProduct.qty;
      }
    });
    if (tmpItems.length > 0) {
      productArr.push({
        ...group,
        items: tmpItems,
        computedPrice,
        qty,
      });
    }
  });

  let productArr2 = [];

  _.forEach(departmentTypes, (type) => {
    let filteredGroup = _.filter(productArr, (i) => i.type === type.uid);

    if (filteredGroup.length > 0) {
      let computedPrice = 0;
      let qty = 0;
      _.forEach(filteredGroup, (group) => {
        computedPrice += group.computedPrice;
        qty += group.qty;
      });
      productArr2.push({
        ...type,
        groups: filteredGroup,
        computedPrice,
        qty,
      });
    }
  });

  // setState(productArr2);
  // };
  return products;
}

export default useComputeProductsReport;
