import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import swal from "sweetalert";
import moment from "moment";
import * as cartActions from "./actions";

import * as posCartActions from "./actions";

function ShoppingCartProductListConatiner(props) {
  const [department, setDepartment] = useState(null);
  const [groupsList, setGroupsList] = useState([]);
  const [items, setItemsList] = useState([]);
  const [initLoad, setInitLoad] = useState(true);

  useEffect(() => {
    if (props.types[0]) {
      filterGroups(props.types[0].uid);
    }
  }, []);

  function filterGroups(typeUidToFind) {
    var res = _.filter(props.groups, g => g.type === typeUidToFind);

    var sorted = _.sortBy(res, g => g.row);
    setGroupsList(sorted);

    setDepartment(typeUidToFind);
    if (initLoad && sorted[0]) {
      filterItems(sorted[0].uid);
    }
    setInitLoad(false);
  }

  function filterItems(groupUid) {
    var res = _.filter(
      props.items,
      line => line.groupUid === groupUid && line.hidden !== true
    );

    var sorted = _.sortBy(res, g => g.row);
    setItemsList(sorted);
  }

  function addItem(item, qty = 1) {
    props.dispatch(posCartActions.addItem(item, qty));
  }

  const { qtyHolder } = props;
  return (
    <div>
      <div className="row">
        <div className="col-lg-4">
          <h5>Departments</h5>
          <select
            class="form-control"
            value={department}
            onChange={e => filterGroups(e.target.value)}
          >
            <option disabled={true} selected={true} value={-1}>
              Select Department
            </option>
            {_.map(props.types, (t, i) => {
              return <option value={t.uid}>{t.name}</option>;
            })}
          </select>
          <h5>Groups</h5>
          {_.map(groupsList, g => (
            <Line
              {...g}
              onClick={() => filterItems(g.uid)}
              qtyHolder={qtyHolder}
              type="group"
            />
          ))}
        </div>
        <div className="col-lg-8">
          <h5>Items</h5>
          {_.map(items, line => (
            <Line
              {...line}
              onClick={() => {
                addItem(line);
              }}
              qtyHolder={qtyHolder}
            />
          ))}
        </div>
      </div>
    
      <OpenProduct {...props} addItem={addItem} />
              
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.inventoryReducer,
    qtyHolder: state.qtyHolder,
    mainAppStore: state.mainAppStore
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingCartProductListConatiner);

function Line({
  onClick = () => {},
  name,
  unit,
  uid,
  barcode,
  price,
  qtyHolder,
  time,
  type = "item"
}) {
  return (
    <a
      className="list-group-item d-flex list-group-item-action"
      onClick={onClick}
    >
      {qtyHolder[uid] && (
        <span style={{ fontWeight: "bold", color: "blue", marginRight: 4 }}>
          {qtyHolder[uid]}X
        </span>
      )}
      <span className="text">
        {name}{" "}
        <span style={{ color: "gray", marginLeft: 8, fontWeight: "100" }}>
          {barcode} 
        </span>
        {type === "item" && parseFloat(time) > 0 &&(
        <span style={{ color: "gray", marginLeft: 8, fontWeight: "100" }}>
          {parseFloat(time) > 0 && time}hr.
        </span>
      )}
      </span>
       
      <span style={{ color: "black",  fontWeight: 'bolder' }}>{price >= 0 ? price + ' THB.' : ''}</span>
    </a>
  );
}

const OpenProduct = props => {
  // {
  // groupUid: "2FnQ2V6n";
  // name: "The Exquisite Romance of Siamese Rose"; **
  // price: 8550; **
  // row: 1567074407198;
  // shopId: "3180";
  // time: "4.20";
  // timeMinutes: 260; **
  // uid: "8XW8ccOu";
  // unit: "Course"; **
  // }
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [time, setTime] = useState("");
  const [unit, setUnit] = useState("");
  const [qty, setQty] = useState("1");

  const validate = () => {
    if (name === "") {
      return "กรุณากรอกชื่อสินค้า";
    }

    if (price === "") {
      return "กรุณากรอกราคาสินค้า";
    }

    if (qty === "") {
      return "กรุณากรอกจำนวนสินค้า";
    }

    return null;
  };

  const addProduct = () => {
    let errMsg = validate();
    if (errMsg) {
      swal(errMsg, "", "error");
      return;
    }

    let { shop } = props.mainAppStore.getState();

    let item = {
      groupUid: `tempGroup${cartActions.makeid(6)}`,
      name: `Open Product (${name})`,
      price: parseInt(price),
      row: moment().valueOf(),
      shopId: shop.hqDetail.uid || shop.detail.uid,
      uid: `temp${cartActions.makeid(6)}`,
      unit: unit !== "" ? unit : "ea",
      isOpenProduct: true
    };

    if (time !== "") {
      let timeMinutes = parseInt(time);
      var hours = Math.floor(timeMinutes / 60);
      var minutes = timeMinutes % 60;

      let timeHour = hours + "." + minutes;
      item.time = timeHour;
      item.timeMinutes = timeMinutes;
    }

    props.addItem(item, parseInt(qty));
    clearInputs();
  };

  const clearInputs = () => {
    setName("");
    setPrice("");
    setQty("1");
    setTime("");
    setUnit("");
  };

  return (
    <div>
      <div className="row m-0 justify-content-between mt-2">
        <div className="align-self-center">
          <p>
            <u>
              <a
                class="btn btn-primary"
                data-toggle="collapse"
                href="#collapse1"
                role="button"
                aria-expanded="false"
                aria-controls="collapse1"
              >
                Open Product (สินค้าชั่วคราว)
              </a>
            </u>
          </p>
        </div>
        <div class="collapse multi-collapse" id="collapse1">
          <button
            class="btn btn-space btn-secondary"
            style={{ width: 75 }}
            onClick={clearInputs}
          >
            Clear
          </button>
          <button
            class="btn btn-space btn-primary"
            style={{ width: 75 }}
            onClick={addProduct}
          >
            Add
          </button>
        </div>
      </div>

      <div class="collapse multi-collapse" id="collapse1">
        <div
          // class="card card-body"
          style={{
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "lightgray",
            borderRadius: 5
          }}
        >
          <div style={{ margin: 20 }}>
            <div className="row">
              <InputField
                label="Name"
                value={name}
                onChange={setName}
                col="6"
              />
              <InputField
                label="Price"
                value={price}
                onChange={setPrice}
                inputType="number"
                col="2"
              />
              <InputField
                label="Qty"
                inputType="number"
                value={qty}
                onChange={setQty}
                col="2"
              />
              <InputField
                label="Time (Mins)"
                value={time}
                onChange={setTime}
                inputType="number"
                col="2"
              />
            </div>
            {/* <div className="row mt-2">
              <div className="col-6"></div>
              <InputField
                label="Time (Mins)(Optional)"
                value={time}
                onChange={setTime}
                inputType="number"
                col="3"
              />

              <InputField
                label="Unit (Optional)"
                value={unit}
                onChange={setUnit}
                col="3"
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const InputField = ({ label, value, onChange, inputType = "text", col }) => {
  return (
    <div className={`col-${col}`}>
      <div class="form-group mb-0">
        <label for="inputEmail">{label}</label>
        <input
          class="form-control"
          // id="inputEmail"
          type={inputType}
          value={value}
          onChange={e => onChange(e.target.value)}
        />
      </div>
    </div>
  );
};
