// SET_SPA_POS_STEP_DATA
import _ from "lodash";
// import documentClient from "../awsAPIs/dynamoDb";
import swal from "sweetalert";
import * as type from "../types/spa.pos";
import moment from "moment";
import * as aws from "../awsAPIs/instance";
import AWS from "aws-sdk";
import PrintJobOrder from "../../printer/service.print.jobOrder";
import * as computeSelector from "../../pages/PosCart/selector";
import VoidReceipt from "../../printer/service.print.receipt.void";
import { spaPosTimeStampRef } from "../firebaseApi/firebaseRef";
import LinePushMessageApi from "../api/line.message.api";
import * as memberTxActions from "./memberTxActions";

export const clearSpaPosStepData = () => {
  return {
    type: type.CLEAR_SPA_POS_STEP_DATA
  };
};

export const setSpaPosStepData = (key, data) => {
  return {
    type: type.SET_SPA_POS_STEP_DATA,
    key: key,
    payload: data
  };
};

export const saveToDatabase = (cb, optionalData = {}) => {
  return async (dispatch, getState) => {
    let state = getState();
    let spaState = state.spa.pos;

    const { detail, linked } = state.shop;
    let store = {
      ...detail,
      // uid: detail.uid,
      // branchId: detail.branchId,
      // name: detail.name,
      hqUid: linked.inventory
    };
    let data = {
      ...spaState,
      refId: spaState.refId === "" ? null : spaState.refId,
      storeUid: state.shop.detail.uid,
      ...optionalData,
      store,
      hqUid: linked.inventory,
      reception: {
        name: state.employee.current.name,
        uid: state.employee.current.uid
      }
    };

    let params = {
      TableName: "Spa_Jobs",
      Item: data
    };

    //make endDate
    const { meta } = spaState;
    let endDate = _.cloneDeep(moment(meta.startDate));
    endDate.add(optionalData.totalItemMinuteAmount, "minutes");
    params.Item.meta.endDate = endDate.toISOString();

    await aws.documentClient.put(params).promise();
    spaPosTimeStampRef.child(detail.uid).set(moment().toISOString());
    swal("Completed !", "บันทึกสำเร็จ", "success").then(() => {
      cb && cb(data);
    });

    // dispatch(notify());
  };
};

export const printJobOrder = (items, options = {}) => {
  return (dispatch, getState) => {
    let state = getState();

    let posState = state.spa.pos;

    let businessDate = moment().format("YYYY-MM-DD");

    const { detail, linked } = state.shop;

    let store = state.spa.pos.store || {
      uid: detail.uid,
      branchId: detail.branchId,
      name: detail.name,
      hqUid: linked.inventory
    };

    let data = {
      // ...posState,
      // agent: posState.agent,
      inventory: posState.inventory,
      jobUid: posState.jobUid,
      member: posState.member,
      meta: posState.meta,
      remark: posState.remark,
      requireEmp: posState.requireEmp,
      tax: posState.tax,
      therapist: posState.therapist,
      orderId: posState.orderId,
      refId: posState.refId === "" ? null : posState.refId,

      // later for override
      // timestamp: moment().format("YYYY-MM-DD") +'#'+ moment().toISOString(),
      // timestamp: businessDate + '#' +moment().toISOString(),
      timestamp: moment().toISOString(),
      storeUid: state.shop.detail.uid,
      businessDate: businessDate,
      store,
      hqUid: linked.inventory,
      cashier: state.employee.current
        ? {
            name: state.employee.current.name,
            uid: state.employee.current.uid
          }
        : null,
      user: state.user.loggedIn,
      items
    };

    //compute End Date
    const totalItemTime = computeSelector.totalItemTimeSelector(state);
    let endTime = _.cloneDeep(new Date(data.meta.startDate));
    endTime.setMinutes(endTime.getMinutes() + totalItemTime);
    data.meta.endDate = moment(endTime).toISOString();
    let p = new PrintJobOrder(dispatch, data);
    p.build().print();

    if (options.cb) {
      options.cb();
    }

    // if(options.printPreview){
    //   let p = new PrintSettlePreview(dispatch, params.Item)
    //   p.build().print()

    //   return
    // }
  };
};

// notify when new job arrive
export const notify = () => {
  return (dispatch, getstate) => {
    // let accessKeyId = "AKIAY4QLEBA75CC3ZJ6R";
    // let secretAccessKey = "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";
    // let sns = new AWS.SNS({
    //   apiVersion: "2010-03-31",
    //   region: "ap-southeast-1",
    //   accessKeyId: accessKeyId,
    //   secretAccessKey: secretAccessKey
    // });
    // let params = {
    //   Message: "NEW DATA" /* required */,
    //   Subject: "DATA",
    //   TargetArn:
    //     "arn:aws:sns:ap-southeast-1:610982430783:endpoint/GCM/test_Push/c0de07bd-193c-35f9-a5f9-d10bea03c61c"
    //   // TopicArn: 'STRING_VALUE'
    // };
    // sns.publish(params, function(err, data) {
    //   if (err) console.log(err, err.stack);
    //   // an error occurred
    //   else console.log(data); // successful response
    // });
  };
};
export const restore = data => {
  return {
    type: type.SPA_RESTORE_JOB_DATA,
    payload: data
  };
};

export const closeShop = () => {
  return async (dispatch, getState) => {
    let { uid } = getState().shop.detail;

    // push line api
    new LinePushMessageApi().closeShop(uid);

    // mark close shop
    var params = {
      TableName: "Spa_Compute_Data",
      Key: { storeUid: uid, name: "close_shop_" + moment().format("YYYY") },
      UpdateExpression: "set #a = :yes",
      ExpressionAttributeNames: { "#a": moment().format("YYYY-MM-DD") },
      ExpressionAttributeValues: { ":yes": true }
    };

    await aws.documentClient.update(params).promise();
  };
};

export const voidBill = (receipt, reason, cb) => {
  //   approved :	boss Rice
  // reason :	test voidd na ja
  // timestamp :	2019-09-10
  // user : ""
  return async function(dispatch, getState) {
    let employee = getState().employee.current || {
      name: getState().user.loggedIn.usename
    };

    let voidBill = {
      approved: null,
      reason: reason || null,
      timestamp: moment().format(),
      user: employee.name,
      cashier: employee.name
    };
    let params = {
      TableName: "Spa_Receipts",
      Key: { storeUid: receipt.storeUid, timestamp: receipt.timestamp },
      UpdateExpression: "set #a = :x ",
      ExpressionAttributeNames: { "#a": "void" },
      ExpressionAttributeValues: {
        ":x": voidBill
      }
    };

    await aws.documentClient.update(params).promise();
    const { prepaidDetail } = receipt;

    //GIVE BACK WALLET BALANCE
    if (receipt.prepaidDetail) {
      const { used, member } = prepaidDetail;
      let giveBackAmount = Math.abs(used);

      dispatch(
        memberTxActions.pushMemberTx(member.uid, "wallet", {
          amount: giveBackAmount,
          type: "TOPUP",
          remark: "Void คืนเงิน"
        })
      );
    }

    new VoidReceipt(dispatch, receipt, {
      reason,
      employeeName: employee.name
    });

    let receipt_merged = { ...receipt, ["void"]: voidBill };
    new LinePushMessageApi().voidBill(receipt_merged);

    alert("Void completed");
    cb && cb();
  };
};

export const voidJob = (storeUid, jobUid, cb) => {
  return function(dispatch, getState) {
    let state = getState();

    const { detail } = state.shop;

    var params = {
      TableName: "Spa_Jobs",
      Key: {
        storeUid: storeUid,
        jobUid: jobUid
      }
    };
    aws.documentClient.delete(params, (err, res) => {
      if (err) {
        console.log("err");
        return;
      }
      cb && cb();
      spaPosTimeStampRef.child(detail.uid).set(moment().toISOString());
    });
  };
};
