import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import useFetchReceipt from "./useFetchReceipt";
import DatePicker from "react-datepicker";
import PrintReportProduct from "../../printer/service.print.report.product";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import Select from "react-select";
import swal from "sweetalert";
import useComputeProductsReport from "../../shared/useHook/useComputeProductsReport";
import { useSelector } from "react-redux";

function Products(props) {
  const { inventory } = props;
  const { receipts, fetchReceipts } = useFetchReceipt();
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now());
  const [fetchedStartDate, setFetchedStartDate] = useState(Date.now());
  const [fetchedEndDate, setFetchedEndDate] = useState(Date.now());
  // const [products, setProducts] = useState([]);
  const mainState = useSelector(state => state);
  let products = useComputeProductsReport(receipts, mainState);

  // const [branch, setBranch] = useState(null);

  const { branch } = props;

  //   menuItems
  useEffect(() => {
    fetchReceipts(props.user.shopId, {
      timestamp: moment().format("YYYY-MM-DD")
    });
  }, []);

   

  const getCurrentBranch = () => {
    if (branch && branch.value === "all") {
      return props.user.shopId;
    }
    if (branch) {
      return branch.value;
    } else {
      return props.user.shopId;
    }
  };

  const searchByDateRange = () => {
    let aaa = moment(startDate)
      .startOf("day")
      .format("YYYY-MM-DD");
    // aaa += "zzzzzzzzzzzzzzzz";
    let bbb = moment(endDate)
      .endOf("day")
      .format("YYYY-MM-DD");
    bbb += "zzzzzzzzzzzzzzzz";

    fetchReceipts(getCurrentBranch(), {
      dateRange: {
        startDate: aaa,
        endDate: bbb
      },
      //ทุกสาขา
      hqUid: branch && branch.value === "all" ? props.user.shopId : null
    });
    setFetchedStartDate(startDate);
    setFetchedEndDate(endDate);
  };

  const handleChangeBranch = branch => {
    // const date = dateObjToString(dateObj);
    // setBranch(branch);

    let aaa = moment(startDate)
      .startOf("day")
      .format("YYYY-MM-DD");
    // aaa += "zzzzzzzzzzzzzzzz";
    let bbb = moment(endDate)
      .endOf("day")
      .format("YYYY-MM-DD");
    bbb += "zzzzzzzzzzzzzzzz";

    fetchReceipts(branch.value, {
      dateRange: {
        startDate: aaa,
        endDate: bbb
      },
      //ทุกสาขา
      hqUid: branch.value === "all" ? props.user.shopId : null
    });
  };

  useEffect(() => {
    branch && handleChangeBranch(branch);
  }, [branch]);

  const printThermalReport = () => {
    // shop
    let data = {
      products,
      startDate: fetchedStartDate,
      endDate: fetchedEndDate
    };

    let shopName = branch ? branch.label : props.shop.detail.name;

    if (products.length < 1) {
      swal("ไม่มีข้อมูล", "ไม่มีข้อมูล", "error");
      return;
    }

    let print = new PrintReportProduct(props.dispatch, shopName, data);
    print.build();
    print.print();
  };

  return (
    <div>
      <div className="card card-table">
        <div className="card-header p-0 m-2">
          {/* Striped Tables */}
          <div className="row m-0">
            <div className="ml-3 mr-3 align-self-center"> Start Date </div>

            <div>
              <DatePicker
                className="form-control"
                selected={startDate}
                onChange={setStartDate}
                dateFormat="dd/MM/yyyy"
                id="date-pick"
                style={{ width: 200 }}
              />
            </div>
            <div className="ml-3 mr-3 align-self-center"> End Date </div>
            <div>
              <DatePicker
                className="form-control"
                selected={endDate}
                onChange={setEndDate}
                dateFormat="dd/MM/yyyy"
                id="date-pick"
                style={{ width: 200 }}
              />
            </div>
            <div className="ml-3">
              <button
                className="btn btn-space btn-lg btn-primary"
                onClick={searchByDateRange}
              >
                Submit
              </button>
              <button
                className="btn btn-space btn-lg btn-secondary ml-2"
                onClick={printThermalReport}
                disabled={_.size(products) < 1}
              >
                Print Thermal
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <table className="table table-striped table-borderless">
            <thead>
              <tr>
                <th>Name</th>
                <th>Quantity</th>
                <th>Total</th>
                {/* <th>Price</th> */}
              </tr>
            </thead>
            <tbody className="no-border-x">
              {_.map(products, type => {
                return [
                  <tr style={{ backgroundColor: "#89b5fa" }}>
                    <th>TYPE: {type.name}</th>
                    <td>{type.qty.toLocaleString()}</td>
                    <td>{type.computedPrice.toLocaleString()}</td>
                  </tr>,
                  _.map(type.groups, group => {
                    return [
                      <tr style={{ backgroundColor: "lightblue" }}>
                        <th

                        // colSpan={3}
                        >
                          Group: {group.name}
                        </th>
                        <td>{group.qty.toLocaleString()}</td>
                        <td>{group.computedPrice.toLocaleString()}</td>
                      </tr>,
                      _.map(group.items, item => {
                        return (
                          <tr>
                            <th>{item.name}</th>
                            <td>{item.qty.toLocaleString()}</td>
                            <td>{item.computedPrice.toLocaleString()}</td>
                          </tr>
                        );
                      })
                    ];
                  })
                ];
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const dateObjToString = date => {
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  const dateString = `${date.getFullYear().toString()}-${month.toString()}-${
    date.getDate() < 10
      ? "0" + date.getDate().toString()
      : date.getDate().toString()
  }`;
  return dateString;
};
export default Products;
