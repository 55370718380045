import React, { useState, useEffect } from "react";
import _ from "lodash";
import * as firebaseRef from "../shared/firebaseApi/firebaseRef";
import { version } from "../../package.json";
import swal from "sweetalert";

let jobIdTimestampHolder = {};

// air buzz
let url = "https://freesound.org/data/previews/57/57806_533680-lq.mp3"; //..

// boxing bell
let url2 = "https://freesound.org/data/previews/66/66951_634166-lq.mp3";

let audio = new Audio(url);
let firstLoadSatrate = true;

function AlertBar(props) {
  const [globalMsg, setGlobalMsg] = useState([]);
  const [hqMsg, setHqMsg] = useState([]);
  const [localMsg, setLocalMsg] = useState([]);
  const [warnNewVersion, setWarnNewVersion] = useState(null);

  useEffect(() => {
    fetchGlobalMessage();
    fetchHqMessage();
    fetchLocalMessage();
    checkVersion();
  }, []);

  const fetchGlobalMessage = () => {
    firebaseRef.globalMessageRef
      .where("visible", "==", true)
      .onSnapshot(function(querySnapshot) {
        let data = [];
        querySnapshot.forEach(function(doc) {
          data.push(doc.data());
        });
        //    ;
        setGlobalMsg(data);
      });
  };
  const fetchLocalMessage = () => {
    firebaseRef.alertMessageRef
      .where("storeUid", "==", props.shop.uid)
      .where("visible", "==", true)
      .onSnapshot(function(querySnapshot) {
        let data = [];
        querySnapshot.forEach(function(doc) {
          data.push({ ...doc.data(), id: doc.id });

          if (
            firstLoadSatrate == false &&
            jobIdTimestampHolder[doc.id] == undefined
          ) {
            // play sound alert
            playsound();
          }
          jobIdTimestampHolder[doc.id] = true;
        });
        firstLoadSatrate = false;
        setLocalMsg(data);
      });
  };
  const fetchHqMessage = () => {
    firebaseRef.alertMessageRef
      .where("hqUid", "==", props.linked || props.shop.uid)
      .where("visible", "==", true)
      .onSnapshot(function(querySnapshot) {
        let data = [];
        querySnapshot.forEach(function(doc) {
          data.push(doc.data());
        });

        setHqMsg(data);
      });
  };
  function playsound() {
    audio.play();
  }

  const checkVersion = () => {
    firebaseRef.appVersionRef.onSnapshot(function(querySnapshot) {
      let data = querySnapshot.data();
      try {
        if (version && version !== data.version) {
          setWarnNewVersion(data.version);
        } else {
          setWarnNewVersion(null);
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  return (
    <div>
      {/* VERSION ALERT*/}
      {false && warnNewVersion && (
        <Bar
          type={"info"}
          message={`App มี Version ใหม่แล้ว คลิกที่ปุ่ม Reload เพื่อทำการ Update |   Current: ( ${version} )   New: ( ${warnNewVersion} ) `}
          disableDismiss={true}
          reload={true}
          onDismiss={() => {
            swal({
              title: `Reload ?`,
              icon: "warning",
              buttons: true
            }).then(willDelete => {
              if (willDelete) {
                window.location.reload(true);
                // setWarnNewVersion(null);
              }
            });
          }}
        />
      )}

      {/* GLOBAL */}
      {_.map(globalMsg, msg => {
        return (
          <Bar type={msg.type} message={msg.message} disableDismiss={true} />
        );
      })}
      {/* HQ */}
      {_.map(hqMsg, msg => {
        return (
          <Bar type={msg.type} message={msg.message} disableDismiss={true} />
        );
      })}
      {/* LOCAL */}
      {_.map(localMsg, msg => {
        return (
          <Bar
            type={msg.type}
            message={msg.message}
            onDismiss={() => deleteLocalMessage(msg)}
          />
        );
      })}
    </div>
  );
}

export default AlertBar;

const Bar = ({ type, message, disableDismiss, onDismiss, reload }) => {
  return (
    <div
      class={`alert alert-${type} alert-dismissible m-0`}
      role="alert"
      style={{ borderBottom: "1px solid white" }}
    >
      {reload && (
        <button
          class="close ml-2"
          type="button"
          style={{ color: "white", opacity: 1 }}
          onClick={onDismiss}
        >
          Reload
        </button>
      )}

      {!disableDismiss && (
        <button
          class="close"
          type="button"
          style={{ color: "white", opacity: 1 }}
          onClick={onDismiss}
        >
          Dismiss
        </button>
      )}

      <div class="icon">
        {" "}
        <span class={getIcon(type)} />
      </div>
      <div class="message">{message}</div>
    </div>
  );
};

const getIcon = type => {
  if (type === "info") {
    return "mdi mdi-info-outline";
  }
  if (type === "danger") {
    return "mdi mdi-close-circle-o";
  }
  if (type === "warning") {
    return "mdi mdi-alert-triangle";
  }
};

const deleteLocalMessage = msg => {
  firebaseRef.alertMessageRef.doc(msg.id).delete();
};
