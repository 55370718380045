import React, { Component } from "react";
import Inventory from "../pages/inventory";
import PurchaseHistory from "../pages/PurchaseHistory";
import Setting from "../pages/Setting";
import { Route, Switch } from "react-router-dom";
import Shopping from "../pages/Shopping";
import ManageUser from "../pages/ManageUser";
import ManageShop from "../pages/ManageShop";
import ManageStock from "../pages/ManageStock";
import PosCart from "../pages/PosCart";
import Employee from "../pages/Employee";
import PromotionRetail from "../pages/PromotionRetail";
import Report from "../pages/Report";
import Test from "../pages/Test";
import SpaContainer from "../pages/Spa/index";
import SpaPos from "../pages/SpaPos";
import SpaReport from "../pages/SpaReport";
import SpaEmployee from "../pages/SpaEmployee";
import SpaRoomAndBed from "../pages/SpaRoomAndBed";
//import Wallet from "../pages/Wallet"; //chagen to this line becasue this always bugs
import SpaAgentItems from "../pages/SpaAgentItems";
import { useSelector } from "react-redux";
import useBlockFeatures from "../shared/useHook/useBlockFeatures";
import Member from "../pages/Member";
import Dashboard from "../pages/Spa/Dashboard";
import SpaReport2 from "../pages/SpaReport2";
import SpaReport3 from "../pages/SpaReport3";
import BookingPage from "../pages/Spa/BookingPage";
import Voucher from "../pages/Voucher";

function MainContent(props) {
  const { linked, location } = props;
  const [currentEmployee, employeeList] = useSelector((state) => {
    return [state.employee.current, state.employee.list];
  });

  const [isBlockPage, BlackScreen] = useBlockFeatures(
    currentEmployee,
    employeeList,
    location
  );

  if (isBlockPage) {
    return BlackScreen;
  }

  return (
    <div>
      {/* <Switch> */}
      <Route exact path="/setting" component={Setting} />
      <Route path="/manageStock" component={ManageStock} />
      <Route exact path="/setting/manageUser" component={ManageUser} />
      <Route exact path="/manageShop" component={ManageShop} />
      <Route
        path="/shopping/edit/:invoiceId/:shopId/:timestamp"
        component={Shopping}
      />
      <Route exact path="/pos/:pageNo" component={SpaContainer} />
      <Route exact path="/hqBooking" component={BookingPage} />

      <Route exact path="/pos" component={SpaContainer} />
      <Route
        exact
        path="/spa_pos/:backNumber/:storeUid/:jobUid"
        component={SpaPos}
      />
      <Route exact path="/spa_pos/:backNumber" component={SpaPos} />
      <Route exact path="/spa_pos" component={SpaPos} />
      {/* <Route exact path="/spa_pos" component={SpaPos} /> */}
      <Route exact path="/reports/spa" component={SpaReport} />
      {/* <Route exact path="/reports2/spa" component={() =>   <Dashboard  dateRange={true}/>  } /> */}
      <Route exact path="/reports2/spa" component={SpaReport2} />
      <Route path="/reports3/spa" component={SpaReport3} />

      <Route exact path="/setting/spa_employee" component={SpaEmployee} />
      <Route exact path="/setting/spa_room" component={SpaRoomAndBed} />
      <Route exact path="/spa_agent_items" component={SpaAgentItems} />

      <Route exact path="/shopping" component={Shopping} />
      <Route path="/inventory" component={Inventory} />
      {/* <Route exact path="/inventory/:type/:uid" component={Inventory} />  */}

      {/* <Route exact path="/wallet" component={Wallet} /> */}
      <Route exact path="/member" component={Member} />
      <Route
        exact 
        path="/memberRetail"
        render={(props) => <Member {...props} isRetail={true} />}
      />
      <Route exact path="/purchaseHistory" component={PurchaseHistory} />
      <Route exact path="/pos_retail" component={PosCart} />
      <Route exact path="/promotion_retail" component={PromotionRetail} />
      <Route exact path="/report" component={Report} />
      <Route exact path="/setting/employee" component={Employee} />
      <Route exact path="/test" component={Test} />
      <Route path="/voucher" component={Voucher} />
      {/* {linked ? (
          <Route exact path="/" component={SpaPos} />
        ) : (
          <Route exact path="/" component={PurchaseHistory} />
        )} */}
      <Route exact path="/" component={linked ? SpaContainer : ManageShop} />
      {/* <Route path={'/'} render={(props) => <Home {...props} mood={8} />} /> */}
      {/* </Switch> */}
    </div>
  );
}

export default MainContent;
