import React, { useState, useEffect } from "react";
import SettingGeneral from "./SettingGeneral";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as shopActions from "../../shared/actions/shopActions";
import * as settingActions from "../../shared/actions/settingActions";
import * as linkedSelector from "../../shared/selector/linkedSelector";
import SettingMultiInputForm from "./SettingMultiInputForm";
import PaymentOptions from "./PaymentOptions";
import SettingPos from "./SettingPos";

// import SettingPaymentType from "./SettingPaymentType";

function Setting(props) {
  const [tab, setTab] = useState(0);
  const { isLinked } = props;
  useEffect(() => {
    if (isLinked) {
      setTab(1);
    }
  }, []);
  const Tab = data => {
    return (
      <li className="nav-item">
        <a
          class={`nav-link ${tab === data.tabNo ? "active" : ""}`}
          href="#"
          // data-toggle="tab"
          role="tab"
          onClick={e => {
            e.preventDefault();
            setTab(data.tabNo);
          }}
        >
          {data.name || "...."}
        </a>
      </li>
    );
  };

  return (
    <div className="main-content container-fluid">
      <div className="card">
        {/* <div className="card-header">Default Tabs</div> */}
        <div className="card-body">
          <div className="tab-container">
            <ul className="nav nav-tabs" role="tablist">
              {!isLinked && <Tab tabNo={0} name="General" />}
              <Tab tabNo={1} name="Payment Type" />
              <Tab tabNo={2} name="Channel" />
              <Tab tabNo={3} name="Options" />
              <Tab tabNo={4} name="Tags" />
              <Tab tabNo={5} name="POS" />
            </ul>
            <div className="tab-content m-0">
              <div className="tab-pane active" id="home" role="tabpanel">
                {tab === 0 && !isLinked && <SettingGeneral {...props} />}
                {tab === 1 && (
                  <SettingMultiInputForm {...props} keyNode="paymentType" />
                  // <SettingPaymentType {...props} keyNode="paymentType" />
                )}
                {tab === 2 && (
                  <SettingMultiInputForm {...props} keyNode="channel" />
                )}
                {tab === 3 && <PaymentOptions {...props} keyNode="options" />}
                {tab === 4 && (
                  <SettingMultiInputForm {...props} keyNode="tags" />
                )}
                {tab === 5 && <SettingPos {...props} keyNode="pos" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    shopDetail: state.shop.detail,
    loggedIn: state.user.loggedIn,
    setting: state.setting,
    isLinked: linkedSelector.isLinkedSelector(state)
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators(
    { ...shopActions, ...settingActions },
    dispatch
  );
  return {
    actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
