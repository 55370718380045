import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import * as aws from "../../../shared/awsAPIs/instance";

import moment from "moment";
import Receipts from "../../SpaReport/Receipts";
import Products from "../../SpaReport/Products";
import * as inventorySelector from "../../../shared/selector/inventorySelector";
import DashboardActionsBar from "./DashboardActionsBar";
// import useSummaryReceiptsState from "../../../shared/useHook/useSummaryReceiptsState";
import ReceiptSummaryView from "../../../shared/components/ReceiptSummaryView";
import documentClient from "../../../shared/awsAPIs/dynamoDb";

const border = { border: "1px solid gray" };

function DashboardSpaPos({
  shopUid,
  receipts,
  receiptVoids,
  receipts_entertain,
  chartGroupBy = "time",
  startDate,
  endDate,
  shop
}) {
  const [computed, setComputed] = useState({});
  // const [receipts, setReceipts] = useState([]);

  useEffect(() => {
    genGraph();
    compute();
  }, [receipts]);

  //data => to gen graph
  function compute() {
    let grandTotal = _.sumBy(receipts, r => r.payment.grandTotal);

    let employees = _.chain(receipts)
      .reduce((acc, r) => {
        if (r.therapist === null) {
          return acc;
        }
        let salesName = r.therapist.name;
        if (acc[salesName] === undefined) {
          acc[salesName] = 0;
        }
        acc[salesName] += r.payment.grandTotal;

        return acc;
      }, {})
      .map((value, name) => {
        return { name, amount: value };
      })
      .orderBy(receipts, r => r.amount)
      .value();

    let agents = _.chain(receipts)
      .reduce((acc, r) => {
        if (r.agent === null || r.agent === undefined) {
          return acc;
        }
        let salesName = r.agent.name;
        if (acc[salesName] === undefined) {
          acc[salesName] = 0;
        }
        acc[salesName] += r.payment.grandTotal;

        return acc;
      }, {})
      .map((value, name) => {
        return { name, amount: value };
      })
      .orderBy(r => r.amount)
      .value();

    let paymentTypes = _.chain(receipts)
      .reduce((acc, r) => {
        if (r.payment.paymentTypes === null) {
          return acc;
        }
        let pTypes = r.payment.paymentTypes;
        _.forEach(pTypes, t => {
          if (acc[t.name] === undefined) {
            acc[t.name] = 0;
          }
          acc[t.name] += t.amount;
        });

        return acc;
      }, {})
      .map((value, name) => {
        return { name, amount: value };
      })
      .orderBy(r => r.amount)
      .value();

    let itemsTop = _.chain(receipts)
      .reduce((acc, r) => {
        if (r.items === null) {
          return acc;
        }

        _.forEach(r.items, t => {
          //remove promotion items
          if (t.promotion) return;
          if (acc[t.uid] === undefined) {
            acc[t.uid] = {
              amount: 0,
              name: t.name,
              count: 0,
              qty: 0
            };
          }

          // acc[t.uid]["amount"] += t.price * r.qtyHolder[t.uid];
          acc[t.uid]["amount"] += t.extendedPrice;
          acc[t.uid]["count"]++;
          // acc[t.uid]["qty"] +=  r.qtyHolder[t.uid] || 0;
          acc[t.uid]["qty"] += t.qty || 0;
        });

        return acc;
      }, {})
      .map((value, uid) => {
        return {
          ...value,
          col1: value.name,
          col2: value.qty,
          col3: value.amount
        };
      })
      .orderBy(r => r.amount)
      .value();

    let _receipts = _.map(receipts, r => {
      let memberName = (r.member && r.member.name) || "-";
      return {
        name:
          moment(r.timestamp).format("HH:mm DD/MM/YYYY   ") + " " + memberName,
        amount: r.payment.grandTotal,

        col1: moment(r.timestamp).format("HH:mm DD/MM/YYYY   "),
        col2: memberName,
        col3: r.payment.grandTotal
      };
    });

    let timeHolder = {};
    _.times(chartGroupBy === "time" ? 24 : 31, i => {
      timeHolder[i < 10 ? "0" + i : i.toString()] = {
        count: 0,
        amount: 0,
        name: i < 10 ? "0" + i : i.toString()
      };
    });

    let times = _.chain(receipts)
      .reduce((acc, r) => {
        if (r.meta.startDate === null) {
          return acc;
        }
        let keyGroup = chartGroupBy === "time" ? "HH" : "DD";

        var hh = moment(r.meta.startDate).format(keyGroup);
        if (hh === undefined) {
          hh = moment(r.timestamp).format(keyGroup);
        }

        if (!acc[hh]) {
          acc[hh] = {
            amount: 0,
            count: 0
          };
        }

        acc[hh]["amount"] += r.payment.grandTotal;
        acc[hh]["count"]++;

        return acc;
      }, timeHolder)

      .map((value, uid) => {
        return value;
      })
      .orderBy(r => r.name)
      .value();

    setComputed({
      grandTotal,
      employees,
      sales: agents,
      paymentTypes,
      products: itemsTop,
      receipts: _receipts,
      times
    });

    // genGraph(times);
  }

  async function genGraph() {
    var params = {
      TableName: "Spa_LiveScore",
      KeyConditionExpression:
        "storeUid = :storeUid AND businessDate BETWEEN :startDate AND :endDate",
      ExpressionAttributeValues: {
        ":storeUid": shop.uid,
        ":startDate": moment(startDate).format("YYYY-MM-DD"),
        ":endDate": moment(endDate).format("YYYY-MM-DD") + "zzzzzzzzzzzzzzz"
      }
    };

    let res = await documentClient.query(params).promise();

    const dateList = res.Items;

    window.Highcharts.chart("main-chart", {
      chart: {
        type: "column"
      },
      title: {
        text: chartGroupBy === "time" ? "ยอดขายตามช่วงเวลา" : "ยอดขายรายวัน"
      },
      xAxis: {
        categories: dateList.map(t =>
          moment(t.businessDate).format("DD-MM-YYYY")
        ),
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: "Amount THB"
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} THB</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [
        {
          name: chartGroupBy === "time" ? "Time (Hours)" : "Date",
          // data: times.map(t => t.amount)
          data: dateList.map(t => t.grandTotal)
        }
      ]
    });
  }

  return [
    <div class="row p-5">
      <div class="col-12">
        {/* <div class="row">
          <div class="col-3">
            {" "}
            <Widget label="Customers" value={5000} />{" "}
          </div>
          <div class="col-3">
            <Widget label="GrandTotal" value={computed.grandTotal} />{" "}
          </div>
          <div class="col-3">
            <Widget label="GrandTotal" value={computed.grandTotal} />{" "}
          </div>
          <div class="col-3">
            {" "}
            <Widget label="Customers" value={5000} />{" "}
          </div>
        </div> */}

        {/* GRAPH */}
        <div class="row">
          <div class="col-8">
            <div class="widget widget-fullwidth be-loading" style={{}}>
              {/* <div class="widget-head">
                <div class="tools">
                  <div class="dropdown">
                    <a class="dropdown-toggle" data-toggle="dropdown">
                      <span class="icon mdi mdi-more-vert d-inline-block d-md-none" />
                    </a>
                    <div class="dropdown-menu" role="menu">
                      <a class="dropdown-item" href="#">
                        Week
                      </a>
                      <a class="dropdown-item" href="#">
                        Month
                      </a>
                      <a class="dropdown-item" href="#">
                        Year
                      </a>
                      <div class="dropdown-divider" />
                      <a class="dropdown-item" href="#">
                        Today
                      </a>
                    </div>
                  </div>
                  <span class="icon mdi mdi-chevron-down" />
                  <span class="icon toggle-loading mdi mdi-refresh-sync" />
                  <span class="icon mdi mdi-close" />
                </div>
                <div class="button-toolbar d-none d-md-block">
                  <div class="btn-group">
                    <button class="btn btn-secondary" type="button">
                      Week
                    </button>
                    <button class="btn btn-secondary active" type="button">
                      Month
                    </button>
                    <button class="btn btn-secondary" type="button">
                      Year
                    </button>
                  </div>
                  <div class="btn-group">
                    <button class="btn btn-secondary" type="button">
                      Today
                    </button>
                  </div>
                </div>
                <span class="title">Recent Movement</span>
              </div> */}

              <div class="widget-chart-container">
                {/* <div class="widget-chart-info">
                  <ul class="chart-legend-horizontal">
                    <li>
                      <span data-color="main-chart-color1" /> Purchases
                    </li>
                    <li>
                      <span data-color="main-chart-color2" /> Plans
                    </li>
                    <li>
                      <span data-color="main-chart-color3" /> Services
                    </li>
                  </ul>
                </div>
                <div class="widget-counter-group widget-counter-group-right">
                  <div class="counter counter-big">
                    <div class="value">25%</div>
                    <div class="desc">Purchase</div>
                  </div>
                  <div class="counter counter-big">
                    <div class="value">5%</div>
                    <div class="desc">Plans</div>
                  </div>
                  <div class="counter counter-big">
                    <div class="value">5%</div>
                    <div class="desc">Services</div>
                  </div>
                </div>*/}
                <div
                  id="main-chart"
                  style={{ height: 400, padding: 0, position: "relative" }}
                />
              </div>
              <div class="be-spinner">
                <svg
                  width="40px"
                  height="40px"
                  viewBox="0 0 66 66"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    class="circle"
                    fill="none"
                    stroke-width="4"
                    stroke-linecap="round"
                    cx="33"
                    cy="33"
                    r="30"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="col-4">
            {/* Right */}
            <ReceiptSummaryView
              receipts={receipts}
              receipts_entertain={receipts_entertain}
              receiptVoids={receiptVoids}
            />
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <Table3Colnums
              title="Last 10 Orders"
              label1="Time"
              label2="Name"
              label3="Amount"
              list={computed.receipts}
            />
          </div>
          <div class="col-6">
            <Table3Colnums
              title="Best Seller Top 10"
              label1="Products"
              label2="Qty"
              label3="Amount"
              list={computed.products}
            />
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <TopPerson
              label1="Payment Types"
              label2="Detail"
              list={computed.paymentTypes}
            />
          </div>
          <div class="col-4">
            <TopPerson label1="Agents" label2="Name" list={computed.sales} />
          </div>
          <div class="col-4">
            <TopPerson
              label1="Employees"
              label2="Name"
              list={computed.employees}
            />
          </div>
        </div>
      </div>
    </div>
  ];
}

const mapStateToProps = (state, ownProps) => {
  const receipts = state.spa.dashboard.receipts;

  return {
    employees: state.prop,
    grandTotal: _.sumBy(receipts, "grandTotal"),
    shopUid: state.shop.detail.uid,
    menuItems: inventorySelector.groupItemSelector(state),
    inventory: inventorySelector.typeGroupItemSelector(state)
  };
};

export default connect(mapStateToProps, null)(DashboardSpaPos);

function Widget({ label, value }) {
  return (
    <div class="widget widget-tile" style={border}>
      <div class="chart sparkline" id="spark2" />
      <div class="data-info">
        <div class="desc">{label}</div>
        <div class="value">
          <span class="indicator indicator-positive mdi mdi-chevron-up" />
          <span
            class="number"
            data-toggle="counter"
            data-end="80"
            data-suffix="%"
          >
            {value}
          </span>
        </div>
      </div>
    </div>
  );
}

function TopPerson({ list = [], label1, label2, label3, onClick }) {
  return (
    <div class="card card-table" style={border}>
      <div class="card-header d-flex justify-content-between flex-row ">
        {/* <div class="tools dropdown">
            {onClick &&<a onClick={onClick}>view more</a>}
          <span class="icon mdi mdi-download" />
          <a
            class="dropdown-toggle"
            href="#"
            role="button"
            data-toggle="dropdown"
          >
            <span class="icon mdi mdi-more-vert" />
          </a>
          <div class="dropdown-menu dropdown-menu-right" role="menu">
            <a class="dropdown-item" href="#">
              Action
            </a>
            <a class="dropdown-item" href="#">
              Another action
            </a>
            <a class="dropdown-item" href="#">
              Something else here
            </a>
            <div class="dropdown-divider" />
            <a class="dropdown-item" href="#">
              Separated link
            </a>
          </div>
        </div> */}
        <div class="title ml-3">{label1}</div>
        <div class="title mr-3">
          {onClick && <a onClick={onClick}>view more</a>}
        </div>
      </div>
      <div
        class="card-body table-responsive"
        style={{ height: list.length > 10 ? "200px" : "", overflow: "overlay" }}
      >
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th style={{ width: "" }}>{label2}</th>
              <th style={{ width: "" }}>{label3 || "Amount"}</th>
            </tr>
          </thead>
          <tbody>
            {_.map(list, l => (
              <tr>
                <td>{l.name}</td>
                <td>{(l.amount || 0).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function Table3Colnums({ list = [], title, label1, label2, label3, onClick }) {
  return (
    <div class="card card-table" style={border}>
      <div class="card-header d-flex justify-content-between flex-row ">
        <div class="title ml-3">{title}</div>
        <div class="title mr-3">
          {/* {onClick && <a onClick={onClick}>view more</a>} */}
        </div>
      </div>
      <div
        class="card-body table-responsive"
        style={{ height: list.length > 10 ? "200px" : "", overflow: "overlay" }}
      >
        <table class="table table-sm table-striped table-hover">
          <thead>
            <tr>
              <th style={{ width: "" }}>{label1}</th>
              <th style={{ width: "" }}>{label2}</th>
              <th style={{ width: "" }}>{label3 || "Amount"}</th>
            </tr>
          </thead>
          <tbody>
            {_.map(_.take(list, 10), l => (
              <tr>
                <td>{l.col1}</td>
                <td>{l.col2}</td>
                <td>{l.col3}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
