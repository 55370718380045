import * as type from "../../types/hqReport";
import _ from "lodash";

const initData = {
  viewBranch: null
};

const reducer = (state = initData, action) => {
  switch (action.type) {
    case type.SET_VIEW_BRANCH:
      return { ...state, viewBranch: action.payload };
    default:
      return state;
  }
};

export default reducer;
