import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import * as promotionActions from "../../shared/actions/promotionActions";
import swal from "sweetalert";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle
} from "react-sortable-hoc";

class PromotionList extends React.Component {
  deletePromotion = promotion => {
    const { actions } = this.props;
    swal({
      title: `คุณต้องการลบ "${promotion.name}" ?`,
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        actions.deletePromotion(promotion, () => {
          swal("สำเร็จ", "ลบ Promotion สำเร็จ", "success");
        });
      }
    });
  };

  render() {
    const { history, match, promotionList, actions } = this.props;
    return (
      <div className="main-content container-fluid">
        <div className="row m-0">
          <div className="col-md-8 offset-md-2">
            <div className="card card-table">
              <div className="card-header">
                <div className="row m-0 justify-content-between">
                  <div>Promotion List</div>
                  <div>
                    <button
                      className="btn btn-space btn-success"
                      onClick={() => history.push(`${match.url}/add`)}
                      style={{ width: 100 }}
                    >
                      CREATE
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ width: 50 }} />
                      <th>Name</th>
                      <th className="actions" />
                    </tr>
                  </thead> 
                  <SortableComponent
                    actions={actions}
                    items={promotionList}
                    history={history}
                    match={match}
                    deletePromotion={this.deletePromotion}
                  />
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class SortableComponent extends React.Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    let { actions, items } = this.props;
    let newItems = arrayMove(items, oldIndex, newIndex);

    actions.reorderPromotionList(newItems, () => {
      swal("สำเร็จ !", "Reorder Complete", "success");
    });
  };
  render() {
    const { items, actions, history, match, deletePromotion } = this.props;
    return (
      <SortableList
        actions={actions}
        useDragHandle={true}
        items={items}
        onSortEnd={this.onSortEnd}
        history={history}
        match={match}
        pressDelay={200}
        deletePromotion={deletePromotion}
      />
    );
  }
}

const SortableList = SortableContainer(
  ({ items, history, match, deletePromotion }) => {
    return (
      <tbody>
        {_.map(items, (item, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              i={item}
              index={index}
              history={history}
              match={match}
              deletePromotion={deletePromotion}
            />
          );
        })}
      </tbody>
    );
  }
);

const DragHandle = SortableHandle(() => (
  <i className="icon icon-left mdi mdi-sort-amount-desc" />
));

const SortableItem = SortableElement(
  ({ i, history, match, deletePromotion }) => {
    return (
      <tr>
        <td>
          <DragHandle />
        </td>
        <td>{i.name}</td>
        <td className="actions">
          <a
            className="icon pr-3"
            href="#"
            style={{ color: "orange" }}
            onClick={e => {
              e.preventDefault();
              history.push(`${match.url}/edit/${i.hqId}/${i.uid}`);
            }}
          >
            <i className="mdi mdi-edit" />
          </a>
          <a
            className="icon"
            href="#"
            style={{ color: "red" }}
            onClick={e => {
              e.preventDefault();
              // history.push(`${match.url}/edit/${i.hqId}/${i.uid}`);
              deletePromotion(i);
            }}
          >
            <i className="mdi mdi-delete" />
          </a>
        </td>
      </tr>
    );
  }
);

const mapStateToProps = state => {
  return {
    promotionList: state.promotion.list
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...promotionActions }, dispatch);
  return {
    actions
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionList);
