import { combineReducers } from "redux";
import pos from "./spaPosReducer";
import rooms from "./spaRoomReducer";
import sales from "./spaSalesReducer";
import dashboard from "./spaDashboardReducer";
import posSetting from "./spaPosSettingReducer";
import voucher from "./spaVoucherReducer";

export default combineReducers({
  pos,
  posSetting,
  rooms,
  sales,
  dashboard,
  voucher
});
