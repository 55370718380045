import { createSelector } from "reselect";
import _ from "lodash";

const current = state => state.setting.current;
const mother = state => state.setting.mother;

export const paymentTypeSelector = createSelector(
  [current, mother],
  (current, mother) => {
    let motherPayment = mother.paymentType.value || [];
    let currentPayment = current.paymentType.value || [];
    let staticTypes = [
      { name: "Cash", row: -4, uid: "cash001" },
      { name: "City Ledger", row: -3, type: "cityLedger", uid: "city001" }
      // { name: "Entertain", row: -2, type: "entertain", uid: "entertain001" }
      // { name: "Prepaid", row: -1, uid: "prepaid001" }
    ];
    return [...staticTypes, ...motherPayment, ...currentPayment];
  }
);

export const optionsSelector = createSelector(
  [current, mother],
  (current, mother) => {
    let motherOption = mother.options.value || [];
    let currentOption = current.options.value || [];
    const staticOption = [
      {
        name: "Entertain",
        mode: 1,
        sign: -1,
        uid: "entertain001",
        row: 0,
        payload: null,
        isStatic: true
      },
      {
        name: "Prepaid",
        mode: 1,
        sign: -1,
        uid: "prepaid001",
        row: 1,
        isStatic: true
      }
    ];
    return [...motherOption, ...currentOption, ...staticOption];
  }
);

export const channelOptionsSelector = createSelector(
  [current, mother],
  (current, mother) => {
    let motherChannel = mother.channel.value || [];
    let currentChannel = current.channel.value || [];
    let options = getOptions([...motherChannel, ...currentChannel]);
    return options;
  }
);

export const tagOptionsSelector = createSelector(
  [current, mother],
  (current, mother) => {
    let motherTags = mother.tags.value || [];
    let currentTags = current.tags.value || [];
    let options = getOptions([...motherTags, ...currentTags]);
    return options;
  }
);

function getOptions(list) {
  let options = [];
  _.map(list, i => {
    options.push({
      ...i,
      value: i.name,
      label: i.name
    });
  });
  return options;
}

// function getOptions(list) {
//   let options = [];
//   _.map(list, i => {
//     options.push({
//       ...i,
//       value: i.uid,
//       label: i.name
//     });
//   });
//   return options;
// }
