

import React, { useState } from 'react';
import { useSelector} from 'react-redux'
function useEmployeeHook() {
const [count, setCount] = useState(0);
return useSelector(state => {
    return [ state.employee.current,
       state.employee.list]
  });

  //  return [currentEmployee, employeeList]
}

export default useEmployeeHook