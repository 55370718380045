import _ from "lodash";

export default class GenerateCsvFile {
   

  genCsv(filename, arrayData) {
    filename = filename.replace(".csv", "") + ".csv";
    var pom = document.createElement("a");
    var csvContent = this.convertToCsvContent(arrayData); //here we load our csv data
    var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    //var blob = new Blob([csvContent], { type: 'text/csv;charset=iso-8859-1;base64;' });
    var url = URL.createObjectURL(blob);
    pom.href = url;
    // pom.setAttribute('download', 'foo.csv');
    pom.setAttribute("download", filename);
    pom.click();
  }
  convertToCsvContent(arrayData) {
    var csvContent = "\ufeff";
    var dataString = "";
    var length = arrayData.length;

    arrayData.forEach(function(infoArray, index) {
      //-------------
      dataString = _.reduce(
        infoArray,
        function(combine, item) {
          return combine + '"' + item + '",';
        },
        ""
      );

      //-------
      //  dataString = infoArray.join(",");
      csvContent += index < length ? dataString + "\n" : dataString;
    });

    return csvContent;
  }

 }
