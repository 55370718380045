import React from "react";
import * as computeSelector from "./selector";
import ShoppingCartContainer from "./ShoppingCartContainer";
import PosCartShoppingCartPromotionList from "./ShoppingCartPromotion";
import ShoppingCartProductListConatiner from "./ShoppingCartProductListContainer";
import PosCartShoppingCartItemList from "./ShoppingCartItemList";
import * as posCartActions from "./actions";
import ShoppingCartLineActions from './ShoppingCartLineActions'
import { connect } from "react-redux";

function ProductPicker(props) {
  const { view, setView } = props;
  return (
    <ShoppingCartContainer view={view} setView={setView}>
      {/* shopping cart */}

      {view === 0 && ( 
        <ShoppingCartLineActions
          {...props}
          items={props.items || []}
          qtyHolder={props.qtyHolder}
        />
      )}

      {view === 0 && ( 
        <PosCartShoppingCartItemList 
          {...props}
          items={props.items || []}
          qtyHolder={props.qtyHolder}
        />
      )}
      {view === 0 && (
        <PosCartShoppingCartPromotionList
          promotionItems={props.promotionItems || []}
        />
      )}
      {/* <ProductList /> */} 
      {view === 1 && <ShoppingCartProductListConatiner />}
    </ShoppingCartContainer>
  );
}

const mapStateToProps = state => {
  return {
    items: state.items,
    qtyHolder: state.qtyHolder,
    promotionItems: computeSelector.promotionItems(state) || [],
    itemlinesSelected : state.itemlinesSelected,
    discountHolder : state.discountHolder
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    removeLine: (index, uid) => {
      dispatch(posCartActions.removeItemLine(index, uid));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductPicker);
