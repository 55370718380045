import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import MemberListTable from "./MemberListTable";
import MemberProfile from "./MemberProfile";
import MemberExpireTable from "./MemberExpireTable";

const Customer = props => {
  const [Customer, setCustomer] = useState(null);
  const [modalTimeStamp, setModalTimestamp] = useState(moment());
  const [page, setPage] = useState("list"); 

  const handleSetCustomer = Customer => {
    setCustomer(Customer);
    setPage("profile");
    setModalTimestamp(moment());
  };
  

  return (
    <div>
      {!props.memberExpired && page === "list" && (
        <MemberListTable   
          {...props}
          setModalTimestamp={setModalTimestamp}
          setCustomer={setCustomer}
          handleSetCustomer={handleSetCustomer}
          initData={Customer}
          timestamp={modalTimeStamp}
          customer={Customer}
        />
      )}
      {props.memberExpired && page === "list" && (
        <MemberExpireTable
          {...props}
          setModalTimestamp={setModalTimestamp}
          setCustomer={setCustomer}
          handleSetCustomer={handleSetCustomer}
          initData={Customer}
          timestamp={modalTimeStamp}
          customer={Customer}
        />
      )}

      {page === "profile" && (
        <MemberProfile
          {...props}
          backPage={() => setPage("list")}
          member={Customer}
          timestamp={modalTimeStamp}
          initData={Customer}
        />
      )}
    </div>
  );
};

export default Customer;
