import React, { useState, useEffect } from "react";
import ReactDataSheet from "react-datasheet";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import swal from "sweetalert";
import _ from "lodash";
import * as overrideActions from "../../shared/actions/overrideActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Select from "react-select";

function PriceOverride(props) {
  const [grid, setGrid] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const { pickedGroupUid, branchOptions } = props;
  const [fetchedHolder, setFetchedHolder] = useState({});

  useEffect(() => {
    createRows();
  }, [pickedGroupUid, selectedBranch]);

  const createRows = async () => {
    if (!selectedBranch) return;

    let items = _.filter(props.items, item => {
      return item.groupUid === pickedGroupUid;
    });

    let grid = [
      [
        { readOnly: true, value: "#", width: 50 },
        { readOnly: true, value: "Name", width: "60%" },
        { readOnly: true, value: "Default Price", width: "15%" },
        { readOnly: true, value: "Override Price" }
      ]
    ];

    let branch = getSelectedBranch();
    let resPrice = await fetchBranchOverridePrice();

    let priceOverride = {};
    if (resPrice.Item) {
      priceOverride = resPrice.Item.value;
      setFetchedHolder(resPrice.Item.value);
    }

    _.forEach(items, (item, idx) => {
      let priceValue = "";
      if (priceOverride[item.uid]) {
        priceValue = priceOverride[item.uid];
      }

      let itemName = item.name;
      if (item.barccode) {
        itemName += ` (${item.barcode})`;
      }
      grid.push([
        { readOnly: true, value: idx + 1, uid: item.uid },
        { readOnly: true, value: itemName },
        { readOnly: true, value: item.price.toLocaleString() },
        { value: priceValue }
      ]);
    });

    setGrid(grid);
  };

  const fetchBranchOverridePrice = async () => {
    if (!selectedBranch) return;

    var params = {
      TableName: "Spa_Override",
      Key: {
        storeUid: selectedBranch.uid,
        name: "price"
      }
    };

    return documentClient.get(params).promise();
  };

  const getSelectedBranch = () => {
    return _.find(props.branchList, i => i.uid === selectedBranch.uid);
  };

  const getWarnText = () => {
    if (!selectedBranch) {
      return <h1>กรุณาเลือกสาขา</h1>;
    }

    if (pickedGroupUid === "none") {
      return <h1>กรุณาเลือกลุ่ม</h1>;
    }

    return null;
  };

  const updateBranchPrice = async () => {
    let value = getUpdateValue();
    var params = {
      TableName: "Spa_Override",
      Key: { storeUid: selectedBranch.uid, name: "price" },
      ExpressionAttributeNames: { "#value": "value" },
      UpdateExpression: "set #value = :value",
      ExpressionAttributeValues: {
        ":value": value
      }
    };

    documentClient.update(params, err => {
      if (err) return console.log(err);
      swal("Success !", "แก้ไขข้อมูลสำเร็จ", "success");
    });
  };

  const getUpdateValue = () => {
    const gridToBeComputed = [...grid.slice(1)];
    const priceObj = _.cloneDeep(fetchedHolder);
    _.forEach(gridToBeComputed, data => {
      //3 = Price Value
      const uid = data[0].uid;
      const newPrice = data[3].value;
      if (priceObj[uid] && newPrice === "") {
        delete priceObj[uid];
        return;
      }
      if (newPrice === "") return;
      if (_.isNaN(parseInt(newPrice))) return; //TODO: ALERT ERROR

      priceObj[uid] = parseInt(newPrice);
    });
    return priceObj;
  };

  return (
    <div>
      <div className="row m-0 justify-content-between">
        <div className="col-6 mb-2 pl-0">
          <Select
            value={selectedBranch}
            onChange={setSelectedBranch}
            options={branchOptions}
          />
        </div>
        {selectedBranch && pickedGroupUid !== "none" && (
          <div>
            <button
              class="btn btn-space btn-success hover"
              style={{ width: 100 }}
              onClick={updateBranchPrice}
            >
              <i class="icon icon-left mdi mdi-check"></i> Save
            </button>
          </div>
        )}
      </div>
      {getWarnText() || (
        <div className="card">
          <div className="p-2">
            <ReactDataSheet
              // width="100%"
              data={grid}
              valueRenderer={cell => cell.value}
              onCellsChanged={changes => {
                const newGrid = grid.map(row => [...row]);
                changes.forEach(({ cell, row, col, value }) => {
                  newGrid[row][col] = { ...newGrid[row][col], value };
                });
                setGrid(newGrid);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...overrideActions }, dispatch);
  return {
    actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceOverride);
