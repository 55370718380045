import _ from "lodash";
import * as type from "../types/spa.pos.setting";

export const setRevertBill = payload => {
  debugger;
  window.localStorage.setItem("revertBill", payload);

  return {
    type: type.SET_REVERT_BILL,
    payload
  };
};

export const setRevertBillDate = payload => {
  window.localStorage.setItem("revertBillDate", payload.toISOString());
  return {
    type: type.SET_REVERT_BILL_DATE,
    payload
  };
};
