import React from "react";
import _ from "lodash";
import moment from "moment";
import useFetchJobs from "./useFetchJobs";

function Spa_Bill_Card(props) {
  const { jobs } = useFetchJobs(props.user.shopId);

  return (
    <div className="mt-4">
      <div className="row m-0">
        {/* <div className="col-10 offset-1"> */}
        <div className="col-6 offset-3">
          {_.map(jobs, bill => {
            return (
              <BillCard
                onClick={() => {
                  props.history.push(
                    `/spa_pos/3/${bill.storeUid}/${bill.jobUid}`
                  );
                }}
                bill={bill}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Spa_Bill_Card;

const BillCard = ({ bill, onClick }) => {
  const { meta, agent = {}, therapist = {}, items = [] } = bill;

  let productStr = "";
  _.forEach(items, (item, idx) => {
    productStr += item.name;
    if (idx !== items.length - 1) {
      productStr += ",  ";
    }
  });

  return (
    <div className="card">
      <div className="p-4">
        <div className="row m-0">
          <div
            className="col-9 m-0 pl-0"
            style={{
              borderRight: "solid",
              borderRightColor: "#d1cfcb",
              borderRightWidth: 1
            }}
          >
            <Row2
              col1="Room & Bed:"
              col2={`${meta.bed && meta.bed.name} - ${meta.room &&
                meta.room.name}`}
            />
            <Row2
              col1="Start - End:"
              col2={`${moment(meta.startDate).format("HH:mm ")} - ${moment(
                meta.endDate
              ).format("HH:mm")}`}
            />
            {/* <Row2
              col1={
                <span style={{ color: "blue", fontWeight: "bold" }}>
                  Member:
                </span>
              }
              col2={
                <span style={{ color: "blue", fontWeight: "bold" }}>
                  bill.member.name
                </span>
              }
            /> */}
            <Row2 col1="Member:" col2={bill.member.name} />
            {/* <Row2 col1="Agent:" col2={agent.name} /> */}
            <Row2 col1="Therapist:" col2={therapist && therapist.name} />
            <Row2
              col1={
                <span style={{ color: "blue", fontWeight: "bold" }}>
                  Grand Total:
                </span>
              }
              col2={
                <span style={{ color: "blue", fontWeight: "bold" }}>
                  {(bill.grandTotal || 0).toLocaleString()}
                </span>
              }
            />
            <Row2
              col1="Products:"
              col2={
                <div>
                  <span style={{ color: "grey" }}>{productStr}</span>
                </div>
              }
            />
          </div>
          <div className="col-3 m-0 align-self-center pr-0">
            <center>
              <a
                href="#"
                style={{ width: 100, color: "orange" }}
                onClick={e => {
                  e.preventDefault();
                  onClick();
                }}
              >
                <span style={{ fontSize: 30 }}>View</span>
              </a>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
};

const Row = ({ col1, col2, col3 }) => {
  return (
    <div className="row m-0 justify-content-between pb-3">
      <div>{col1}</div>
      <div>{col2}</div>
      {col3 && <div>{col3}</div>}
    </div>
  );
};

const Row2 = ({ col1, col2 }) => {
  return (
    <div className="row m-0 pb-1">
      <div className="col-4 p-0">{col1}</div>
      <div className="col-8 p-0">{col2}</div>
    </div>
  );
};
