import documentClient from "../shared/awsAPIs/dynamoDb";
import _ from "lodash";

async function main() {
  
  let members = await fetchMemberUser();
  let tx = await fetchTxForMember();
  let memberObjSumBalance = computeTxSumObj(tx.Items);
  
  debugger
  for (let index = 0; index < members.Items.length; index++) {
    const memberData = members.Items[index];

    try {
      if(memberObjSumBalance[memberData.uid] != undefined){
        let res = await updateMemberBalance(memberData.uid, memberObjSumBalance);
      }
      console.log(`member update success index: ${index}`, memberData);
    } catch (error) {
      console.log("=========error===========");
      console.log(error);
      console.log("=========error============");
    }
  }
}

function computeTxSumObj(tx) {
  let memberObj = {};
  _.forEach(tx, (t) => {
    if (memberObj[t.memberUid] === undefined) {
      memberObj[t.memberUid] = 0;
    }
    memberObj[t.memberUid] += t.amount || 0;
  });
  return memberObj;
}
function fetchMemberUser() {
  let p = new Promise(async (resolve) => {
    let res = [];
    let params = {
      TableName: "Crm_Users",
    };

    let data = await documentClient.scan(params).promise();
    res = res.concat(data.Items);
    while (data.LastEvaluatedKey) {
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      data = await documentClient.scan(params).promise();
      res = res.concat(data.Items);
    }
    

    resolve({
      Items :res
    });
  });

  return p;
}

function updateMemberBalance(uid, memberObj) {
  let params = {
    TableName: "Crm_Users",
    Key: {
      uid: uid,
    },
    UpdateExpression: "set #walletBalance = :walletBalance ",
    ExpressionAttributeNames: { "#walletBalance": "walletBalance" },
    ExpressionAttributeValues: {
      ":walletBalance": memberObj[uid] || 0,
    },
  };

  return documentClient.update(params).promise();
}

function fetchTxForMember() {
  let p = new Promise(async (resolve) => {
    let res = [];
    let params = {
      TableName: "Wallet_Transactions",
    };

    let data = await documentClient.scan(params).promise();
    res = res.concat(data.Items);
    while (data.LastEvaluatedKey) {
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      data = await documentClient.scan(params).promise();
      res = res.concat(data.Items);
    }

    resolve({
      Items :res
    });
  });

  return p;
}

window.recomputewallet = main;
