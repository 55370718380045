// endpoints:
//   GET - https://qmx3n6pt2h.execute-api.ap-southeast-1.amazonaws.com/dev/receipts/{shopId}/{yyyymm}
//   POST - https://qmx3n6pt2h.execute-api.ap-southeast-1.amazonaws.com/dev/receipts
//   GET - https://qmx3n6pt2h.execute-api.ap-southeast-1.amazonaws.com/dev/next-id-format_increment-now/{shopId}/{yyyymm}
// functions:
//   getCurrentReceiptId: receipts-id-dev-getCurrentReceiptId
//   incrementId: receipts-id-dev-incrementId
//   nextIdFormat_incrementNow: receipts-id-dev-nextIdFormat_incrementNow

const axios = require("axios");

function ReceiptIdService() {
  let LOCAL_RECEIPT_ID = "LOCAL_RECEIPT_ID";
  let localData;
  try {
    localData = JSON.parse(window.localStorage.getItem(LOCAL_RECEIPT_ID)) || {};
  } catch (error) {
    localData = {};
  }

  let lastReceiptNumber = {};
  let doneInit = false;
  let defaultOptions = {
    paddingZero: 4,
    urlCurrentId:
      "https://qmx3n6pt2h.execute-api.ap-southeast-1.amazonaws.com/dev/receipts/{shopId}/{yyyymm}",
    urlIncrement:
      "https://qmx3n6pt2h.execute-api.ap-southeast-1.amazonaws.com/dev/next-id-format_increment-now/{shopId}/{yyyymm}",

    storeUid: null,
    autoLeadZero: true,
    businessMonth: new Date().toISOString().substring(0, 7)
  };

  return {
    // getLastId: getLasttId,
    generateNextReceiptId: generateNextReceiptId, //auto sync next number
    // getCurrentReceiptNubmer: getCurrentReceiptNubmer
    init: init
  };

  async function init(_storeUid, options) {
    defaultOptions = { ...defaultOptions, ...options, storeUid: _storeUid };
    doneInit = true;
    await restore();
    fetch();
  }

  function fetch() {
    // restore()
    let { storeUid, businessMonth: yyyymm } = defaultOptions;
    let URL = defaultOptions.urlCurrentId.replace("{shopId}", storeUid);
    URL = URL.replace("{yyyymm}", yyyymm);
    axios.get(URL).then(res => {
      lastReceiptNumber[yyyymm] = res.data;
    });
  }

  function restore() {
    return new Promise(async resolve => {
      for (const key in localData) {
        const thisUrl = localData[key];
        await axios.get(thisUrl);
        delete localData[key];
        save();
      }
      resolve("done");
    });
  }

  function save() {
    window.localStorage.setItem(LOCAL_RECEIPT_ID, JSON.stringify(localData));
  }

  function generateNextReceiptId() {
    if (doneInit === false) {
      throw "you must init key first";
    }

    let yyyyMM = defaultOptions.businessMonth;
    let nextNum = (lastReceiptNumber[yyyyMM] =
      (lastReceiptNumber[yyyyMM] || 0) + 1);
    incrementNextToDatabase();
    return (
      defaultOptions.businessMonth +
      "-" +
      leadingZero(nextNum, defaultOptions.paddingZero)
    );
  }

  function incrementNextToDatabase() {
    let { storeUid, businessMonth: yyyymm } = defaultOptions;
    let URL = defaultOptions.urlIncrement.replace("{shopId}", storeUid);
    URL = URL.replace("{yyyymm}", yyyymm);

    let uid_random = new Date().valueOf();
    localData[uid_random] = URL;
    save();
    axios.get(URL).then(() => {
      console.log("next data id is completed");
      delete localData[uid_random];
      save();
    });
  }
}

function leadingZero(num, places) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

const ReceiptIdServiceInstance = new ReceiptIdService();

export default ReceiptIdServiceInstance;
