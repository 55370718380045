import moment from "moment";
import Paper from "niceloop-paper";
import _ from "lodash";
import getVoucherDiscount from "../shared/functions/getVoucherDiscount";

// payment receipts
class PrintSettlePreview {
  receipt = null;
  dispatch = null;
  printCmdResult = null;
  printType = "RECEIPT_FULL";

  constructor(dispatch, receipt, options = {}) {
    this.dispatch = dispatch;
    this.receipt = receipt;
    // this.build();
    // this.print();
    this.options = options;
  }

  build() {
    //obj is table instance

    var receipt = this.receipt;
    const { meta = { room: { name: "-" }, bed: { name: "-" } } } = receipt;
    var Order = {};

    Order.UniqueId = this.getRandomInt(99999999999);

    Order.Printer = [0];
    // Order.Title1 = "PREVIEW";
    // Order.Title1 = "2ff2f\nfefefef\nfkoefokeof";
    Order.Title1 = null;
    Order.Title2 = null;

    Order.isPreview = true;

    Order.CustomHeaders1 = this.genShopDetail();
    Order.CustomHeaders2 = this.genSummaryHeader();
    Order.CustomHeaders3 = null;
    Order.CustomHeaders4 = null;
    Order.CustomHeaders5 = null;
    Order.CustomHeaders6 = null;
    Order.CustomHeaders7 = null;
    Order.CustomHeaders8 = null;
    Order.CustomHeaders9 = null;
    Order.CustomHeaders10 = null;

    Order.Table = `${meta.room.name} | ${meta.bed.name}`;

    Order.OrderItems = this.convertItemsToPrint(receipt.items);

    Order.Summary1 = this.genOptions();
    Order.Summary2 = [];
    // Order.Summary2 = this.genRounding();
    Order.GrandTotal = (receipt.payment.grandTotal || 0).toLocaleString();

    Order.CustomFooters1 = this.genVat();
    Order.CustomFooters2 = this.genSign();
    // Order.CustomFooters3 = this.genMember();
    Order.CustomFooters3 = null;
    Order.CustomFooters4 = null;
    Order.CustomFooters5 = null;
    Order.CustomFooters6 = null;
    Order.CustomFooters7 = null;
    Order.CustomFooters8 = null;
    Order.CustomFooters9 = null;
    Order.CustomFooters10 = null;

    // Order.Note = receipt.note;
    Order.Note = null;

    this.Order = Order;
    return this;
  }

  genShopDetail() {
    var paper = new Paper();
    let { store = {} } = this.receipt;

    //ADDRESS
    if (
      store.shippingAddress &&
      store.shippingAddress !== "-" &&
      store.shippingAddress !== ""
    ) {
      let splitedLine = _.split(store.shippingAddress, "\n");
      _.forEach(splitedLine, (text, idx) => {
        paper.textLine(text);
      });
    }

    paper.lineFeed();
    paper.lineFeed();

    paper.title("RECEIPT (PREVIEW)");
    paper.lineFeed();
    return paper.gen();
  }

  genMember() {
    var paper = new Paper();
    let { member } = this.receipt;

    if (!member || !member.uid) {
      return null;
    }

    paper.title("สมาชิก");
    paper.textLine(`ชื่อ : K.${member.firstName} ${member.lastName} `);
    paper.textLine(`โทร  : ${member.phone}`, "");
    paper.keyValue(`Point ก่อนหน้านี้ :  ${member.point}`);
    paper.keyValue(`Point ในใบเสร็จนี้ :  ${member.gainPoint}`);
    paper.keyValue(`Point รวม :  **** ${member.gainPoint + member.point} ****`);

    return paper.gen();
  }

  genSummaryHeader() {
    var paper = new Paper();
    let {
      localTime,
      member = {},
      jobUid,
      receiptId,
      therapist = {},
      payment,
      cashier,
      refId,
      orderId,
      therapistList
    } = this.receipt;

    // .textLine("Job ID : " + jobUid)
    //   .textLine("Receipt ID : " + receiptId)
    paper.textLine(`Ref ID :  #${refId || orderId}`);
    paper.textLine("Date : " + moment(localTime).format("DD/MM/YYYY HH:mm:ss"));
    member &&
      paper.textLine(
        `Member : ${member.name}${
          member.memberId ? " (" + member.memberId + ")" : ""
        }`
      );
    // therapist && paper.keyValue("Therapist", therapist.name);

    if (therapist && _.size(therapist) > 0) {
      _.forEach(getTherapist(therapist), (ther, idx) => {
        paper.keyValue(`Therapist ${idx + 1}`, ther.name);
      });
    }

    cashier && paper.textLine(`Reception : ${cashier.name || "-"}`);

    return paper.gen();
  }

  genRounding() {
    let { rounding, beforeRounding } = this.receipt;

    if (rounding != 0) {
      var paper = new Paper();

      paper.keyValue("Before Rounding ", beforeRounding);
      paper.keyValue("Rounding ", rounding);
      return paper.gen();
    } else {
      return [];
    }
  }

  genSign() {
    var paper = new Paper();

    paper.lineFeed();

    paper.lineFeed();

    paper.textLine("___________________________");
    paper.textLine("  Customer Sign ");

    paper.lineFeed();
    paper.lineFeed();
    paper.textLine(
      "Cashier : " +
        (this.receipt.cashier.name || this.options.employeeName || "")
    );

    return paper.gen();
  }

  genOptions() {
    var paper = new Paper();

    const { payment, matchVoucher, items } = this.receipt;
    const {
      subTotal = 0,
      options = [],
      optionsAmount = 0,
      grandTotal = 0,
      vat = 0,
      promotions = 0
    } = payment;

    if (subTotal !== grandTotal) {
      paper.keyValue(`Sub Total:`, subTotal.toLocaleString());
      // paper.drawLine();
    }
    if (promotions !== 0) {
      paper.keyValue("Promotion Discounts:", promotions);
    }

    if (matchVoucher) {
      paper.keyValue(
        "Voucher Discounts:",
        getVoucherDiscount(items, matchVoucher)
      );
      _.forEach(matchVoucher, v => {
        paper.textLine(`- ${v.name} (${v.voucherUid})`);
      });
    }

    if (options.length > 0) {
      // paper.textLine("--Options--");

      _.forEach(options, option => {
        paper.keyValue(
          `${option.name} (${option.value} ${getOptionMode(option.mode)})`,
          `${(option.amount || 0).toLocaleString()}`
        );
      });

      // paper.lineFeed();
      // paper.keyValue(`Options Amount:`, optionsAmount.toLocaleString());
    }

    return paper.gen();
  }

  genVat() {
    var paper = new Paper();

    const { payment } = this.receipt;
    const { paymentTypes, received = 0, change = 0, grandTotal, vat } = payment;

    paper.comment("Vat (Included) : " + vat.toFixed(2));
    paper.comment("Before Vat : " + (grandTotal - vat).toFixed(2));

    return paper.gen();
  }

  genPayment() {
    var paper = new Paper();

    const { payment } = this.receipt;
    const { paymentTypes, received = 0, change = 0, grandTotal, vat } = payment;

    _.forEach(paymentTypes, payment => {
      paper.keyValue(`${payment.name}`, payment.amount.toLocaleString());
    });

    paper.keyValue(`Received:`, received.toLocaleString());
    paper.keyValue(`Change:`, change.toLocaleString());
    // paper.lineFeed()

    return paper.gen();
  }

  convertItemsToPrint(items) {
    var paper = new Paper();
    //ITEMS
    _.forEach(items, (item, i) => {
      if (item.promotion) return;
      // paper.keyValue(
      //   `${item.qty}   ${item.name}`,
      //   (item.qty * item.price).toLocaleString()
      // );

      if (item.name.length > 30) {
        let chunkName = chunkString(item.name, 30);

        _.forEach(chunkName, (text, idx) => {
          if (idx === 0) {
            paper.keyValue(
              `${item.qty}  ${text}`,
              item.discountedPrice.toLocaleString() ||
                item.extendedPrice.toLocaleString()
            );
          } else {
            paper.keyValue(`    ${text}`);
          }
        });
      } else {
        paper.keyValue(
          `${item.qty}  ${item.name}`,
          item.discountedPrice.toLocaleString() ||
            item.extendedPrice.toLocaleString()
        );
      }
    });

    //Promotion
    let promotions = _.filter(items, item => item.promotion === true);
    if (promotions.length > 0) {
      paper.textLine("-------------------------");
      _.forEach(promotions, promo => {
        if (promo.printName) {
          paper.keyValue(`    ${promo.printName[0]}`, "");
          paper.keyValue(
            `${promo.qty}   ${promo.printName[1]}`,
            promo.price.toLocaleString()
          );
        } else {
          paper.keyValue(
            `${promo.qty}   ${promo.name}`,
            promo.price.toLocaleString()
          );
        }
      });
    }

    return paper.gen();
  }

  print() {
    this.dispatch({
      type: "PRINT",
      meta: {
        printType: this.printType,
        type: "PRINT"
      },
      payload: this.Order
    });
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
}

export default PrintSettlePreview;

const getOptionMode = mode => {
  if (mode === 0) {
    return "%";
  }
  if (mode === 1) {
    return "THB";
  }
};

const chunkString = (str, len) => {
  var _size = Math.ceil(str.length / len),
    _ret = new Array(_size),
    _offset;
  for (var _i = 0; _i < _size; _i++) {
    _offset = _i * len;
    _ret[_i] = str.substring(_offset, _offset + len);
  }
  return _ret;
};

const getTherapist = therapistData => {
  let therObj = {};
  _.map(therapistData, course => {
    _.forEach(course.therapists, ther => {
      therObj[ther.uid] = ther;
    });
  });

  return _.values(therObj);
};
