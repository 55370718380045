import * as type from "../../types/spa.voucher";

let initData = { campaign: [] };

const reducer = (state = initData, action) => {
  switch (action.type) {
    case type.FETCH_SPA_VOUCHER:
      return { ...state, campaign: action.payload };
    default:
      return state;
  }
};

export default reducer;
