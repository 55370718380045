import React, { useState, useEffect } from "react";
import ActionsBar from "./ActionsBar";
import ProductList from "./ProductList";
import PosCartSummary from "./Summary";
import { Provider } from "react-redux";
import storeInit from "./store";
// import * as actions from "./actions";
import CartItemList from "./CartItemList";
import ProductPicker from "./ProductPicker";

let store = storeInit;
const POS_CART_VIEW = "POS_CART_VIEW"
function PosCart(props) {
  // const [store, setStore] = useState(storeInit);
  const [view, setView] = useState(()=>  {
    let res= window.localStorage.getItem(POS_CART_VIEW) === '0' ?  0  : 1 
    return res;
  });
  let state = store.getState();

  useEffect(() => {
    window.localStorage.setItem(POS_CART_VIEW, view)
  }, [view])

  function next() {
    props.handleNextStep && props.handleNextStep();
  }

  if (props.itemPickerOnly) {
    return (
      <Provider store={store}>
        <ProductPicker setView={setView} view={view} />
      </Provider>
    );
  }

  return (
    <Provider store={store}>
      <div className="row">
        <div className="col-lg-12">{/* <ActionsBar /> */}</div>

        {/* ==== Right ====*/}
        <div className="col-lg-4 mt-5">
          {/* Summary Bar */}
          {view === 0 && <PosCartSummary />} 
 
          {/* แสดงรายการสินค้าที่เลือก */}
          {view === 1 && <CartItemList {...props} />}
        </div>

        {/* ==== Left ====*/}
        <div className="col-lg-8 mt-5">
          {/* scan bar,  search bar */}
          <ProductPicker setView={setView} view={view} />
        </div>
      </div>
    </Provider>
  );
}

export default PosCart;
