import React, { useState } from "react";
import { RowDropDown, RowButton, SummaryList } from "./Dashboard/Components";
import { useDispatch, useSelector } from "react-redux";
import PrintReportSummary from "../../printer/service.print.report.summary";
import PrintReportProduct from "../../printer/service.print.report.product";
import useComputeProductsReport from "../../shared/useHook/useComputeProductsReport";
import moment from "moment";
import _ from "lodash";
import useFetchReceipts from "../SpaReport/useFetchReceipt";
import * as appStateAction from "../../shared/actions/appStateActions";
import axios from "axios";
import { downloadableLinkUrl } from "../../shared/utils";
import * as posActions from "../../shared/actions/spaPosActions";
import * as firestore from "../../shared/firebaseApi/firebaseRef";
import useReceiptsHook from "../../shared/useHook/useReceiptsHook";

function Closing({ drawerState, drawerDispatch, window, document }) {
  const shopId = useSelector((state) => {
    return state.shop.detail.uid;
  });
  const {
    receipts: not_use_Receipts,
    voidReceipts,
    entertainReceipts,
    fetchReceipts,
  } = useFetchReceipts(shopId);
  const globalState = useSelector((state) => state);

  const { data: receipts_all, isloading } = useReceiptsHook(
    globalState.appState.businessDate
  );

  const dispatch = useDispatch();
  const shopInfo = useSelector((state) => {
    return state.shop.detail;
  });
  const shopFull = useSelector((state) => {
    return state.shop;
  });
  const mainState = useSelector((state) => state);
  const [value, setValue] = useState("");
  const [summaryPrintCount, setSummaryPrintCount] = useState(1);

  const [productPrintCount, setProductPrintCount] = useState(1);
  let products = useComputeProductsReport(receipts_all, mainState);

  const downloadExcel = (mediaLink) => {
    let URL =
      "https://us-central1-divana-project.cloudfunctions.net/spaSummaryReport";
    let data = {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),

      shopName: shopInfo.name || "unknown",
      storeUid: shopInfo.uid,
      isCloseShop: true,
    };

    dispatch(appStateAction.loading(true));

    axios
      .post(URL, data)
      .then((res) => {
        downloadableLinkUrl("report-summary-excel.xlsx", res.data);
      })
      .catch((err) => alert(err))
      .finally(() => {
        dispatch(appStateAction.loading(false));
      });
  };

  const downloadPDF = () => {
    let URL =
      "https://us-central1-divana-project.cloudfunctions.net/pdfSummaryReport";
    let data = {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),

      shopName: shopInfo.name || "unknown",
      storeUid: shopInfo.uid,
      isCloseShop: true,
    };

    dispatch(appStateAction.loading(true));

    axios
      .post(URL, data)
      .then((res) => {
        downloadableLinkUrl("report-summary-excel.xlsx", res.data);
      })
      .catch((err) => alert(err))
      .finally(() => {
        dispatch(appStateAction.loading(false));
      });
  };

  function submit() {
    printSummary();
    printProduct();

    // downloadPDF()
    // downloadExcel();

    let a = window;

    let b = document;
    drawerDispatch({
      type: "DRAWER_END",
      payload: value || 0,
    });

    alert("Closed shop,  processing.....");
    dispatch(posActions.closeShop());

    setTimeout(() => {
      alert("Close shop completed.");

      drawerDispatch({
        type: "DRAWER_RESET",
        payload: value || 0,
      });

      let { shop } = globalState;

      firestore.appStateRef.doc(shop.detail.uid).delete();
    }, 500);
  }

  let receipts = _.filter(receipts_all, (r) => !r.void);
  const printSummary = () => {
    _.times(summaryPrintCount, () => {
      let options = {
        voidReceipts,
        entertainReceipts,
        isCloseShop: true,
      };
      let print = new PrintReportSummary(dispatch, shopFull, receipts, options);
      print.build().print();
    });
  };

  const printProduct = () => {
    _.times(productPrintCount, () => {
      // shop
      let data = {
        products,
        startDate: moment().startOf("day").format(),
        endDate: moment().format(),
        isCloseShop: true,
      };

      let shopName = shopFull.detail.name;

      let print = new PrintReportProduct(dispatch, shopName, data);
      print.build();
      print.print();
    });
  };

  return (
    <div class=" pt-5 pb-7">
      <div class="row mb-0">
        <div class="offset-0 col-4">
          <h4 class="text-right">Print Report</h4>
        </div>
        <div class="col-4">
          <div style={{ marginTop: -16 }}>
            <SummaryList>
              <RowDropDown
                title="Summary"
                buttonLabel="print"
                value={summaryPrintCount}
                onChange={(evt) => setSummaryPrintCount(evt.target.value)}
                // onClick={printSummary}
              />
              <RowDropDown
                title="Products"
                buttonLabel="print"
                value={productPrintCount}
                onChange={(evt) => setProductPrintCount(evt.target.value)}
              />
              {/* <RowButton title="Void" buttonLabel="print" /> */}
            </SummaryList>
          </div>
        </div>
      </div>

      <div class="row mb-5">
        <div class="offset-0 col-4">
          <h4 class="text-right">เงินในลิ้นชัก</h4>
        </div>
        <div class="col-4" style={{ paddingLeft: 40 }}>
          <input
            value={value}
            onChange={(evt) => setValue(evt.target.value)}
            class="form-control"
            id="inputText3"
            type="text"
          />
          <br />
          <button onClick={submit} class=" btn btn-space btn-primary btn-lg">
            <i class="icon icon-left mdi mdi-cloud-done" />
            Submit Close Shop
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div class="row mt-7">
      <div class="col-3"> </div>
      <div class="col-6">
        <div class="row">
          <div class="col-5">
            <h3 class="text-right">เงินในลิ้นชัก</h3>
          </div>
          <div class="col-7">
            <input
              value={value}
              onChange={(evt) => setValue(evt.target.value)}
              class="form-control"
              id="inputText3"
              type="text"
            />
          </div>

          <div class="col-5" />
          <div class="col-7 mt-5">
            <button onClick={submit} class=" btn btn-space btn-primary btn-lg">
              <i class="icon icon-left mdi mdi-cloud-done" />
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Closing;
