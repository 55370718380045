import React, { useState, useEffect, useRef } from "react";
import Option2Line from "./SummaryOptionLine";
import _ from "lodash";
import swal from "sweetalert";
import { prepaidPaymentRef } from "../../shared/firebaseApi/firebaseRef";

function Option2_Container(props) {
  const { optionList, paymentOptions, spaPos } = props;
  const { jobUid } = spaPos;

  const [list, setList] = useState([
    // { value: "", remark: "", mode: 0, name: "", sign: 1 }
  ]);
  const [firstLoad, setFirstLoad] = useState(true);
  const latestOptions = useRef(list);

  useEffect(() => {
    // debugger;
    if (firstLoad && optionList && optionList.length > 0) {
      setList(optionList);
      setFirstLoad(false);
    }
  }, [optionList]);

  useEffect(() => {
    listenPrepaid();
  }, [jobUid]);

  function addLine() {
    if (!paymentOptions[0]) {
      swal(
        "ไม่มี Options ให้เลือก",
        ``, // `คุณสามารถเพิ่ม Options ได้ที่หน้า Setting`,
        "error"
      );
    }

    let isFail = validate(list);
    if (isFail) {
      return;
    }
    setList([...list, paymentOptions[0]]);
  }

  async function handleRemove(index) {
    let isFail = false;
    if (list[index].uid === "prepaid001") {
      let cancelPrepaid = await swal({
        title: `Cancel Prepaid ?`,
        icon: "warning",
        buttons: true
      });
      if (!cancelPrepaid) isFail = true;
      removePrepaidData();
    }

    if (isFail) return;

    var newList = _.filter(list, (r, i) => i != index);
    setList(newList);
    save(newList, true);
  }

  function save(forceData) {
    let options = _.cloneDeep(forceData || list);

    if (validate(options)) {
      return;
    }

    props.saveOptions(options);
  }

  const validate = options => {
    let failIndex = null;
    let failText = "";
    let isFail = false;

    _.forEach(options, (option, idx) => {
      //BUG PROTECT REMARK
      if (option.remark === "") {
        option.remark = null;
      }

      //VALIDATE
      if (option.value === "" || !option.value) {
        failIndex = idx + 1;
        failText = "คุณกรอกข้อมูล Option ไม่ครบถ้วน";
        isFail = true;
      } else if (_.isNaN(parseInt(option.value))) {
        failIndex = idx + 1;
        failText = "กรุณากรอก Value เป็นตัวเลขเท่านั้น";
        isFail = true;
      } else if (option.uid === "entertain001" && !option.payload) {
        failIndex = idx + 1;
        failText = "กรุณาระบุชื่อผู้ Entertain";
        isFail = true;
      }
    });

    const failSubTitle = `กรุณาตรวจสอบ Option ลำดับที่ ${failIndex} (หากไม่ใช้กรุณาคลิกปุ่ม Remove)`;
    if (failIndex) {
      swal(failText, failSubTitle, "error");
    }
    return isFail;
  };

  function handleEdit(index, key, value) {
    let newList = _.cloneDeep(list);
    newList[index][key] = value;
    setList(newList);
  }
  async function handleChangeOption(index, option) {
    let newList = _.cloneDeep(list);

    let existEntertaionOption = _.find(newList, i => i.uid === "entertain001");
    if (option.uid === "entertain001" && existEntertaionOption) {
      swal("ไม่สามารถ Entertain ได้มากกว่า 1 รายการ", "", "error");
      handleRemove(index);
      return;
    }

    let existPrepaidOption = _.find(newList, i => i.uid === "prepaid001");
    if (option.uid === "prepaid001" && existPrepaidOption) {
      swal("ไม่สามารถ Preapaid ได้มากกว่า 1 รายการ", "", "error");
      handleRemove(index);
      return;
    }

    let data = {
      // ...newList[index],
      ...option,
      value: newList[index].value,
      mode: newList[index].mode
    };

    if (option.uid === "entertain001" || option.uid === "prepaid001") {
      data.mode = option.mode;
    }

    //PREPAID CONDITIONS
    if (option.uid === "prepaid001") {
      data.value = "";
      let resPrepaid = await asyncFetchPrepaidData();
      if (resPrepaid.exists) {
        const prepaidData = resPrepaid.data();
        data.value = prepaidData.amount;
      }
    }

    newList[index] = data;
    setList(newList);
  }

  const asyncFetchPrepaidData = () => {
    return prepaidPaymentRef.doc(jobUid).get();
  };

  const removePrepaidData = () => {
    prepaidPaymentRef.doc(jobUid).delete();
  };

  const listenPrepaid = () => {
    if (!jobUid) return;
    prepaidPaymentRef.doc(jobUid).onSnapshot(cbFirestore);
  };

  function cbFirestore(doc) {
    let currentOptions = latestOptions.current;

    // let paymentsValues = currentPayments.getValue();
    const data = doc.data();
    if (!data) return;

    let findPrepaidOption = _.find(paymentOptions, i => i.uid === "prepaid001");
    const amount = data.amount || "";

    let findPrepaidIdx = null;

    _.forEach(currentOptions, (i, idx) => {
      if (i.uid === "prepaid001") {
        findPrepaidIdx = idx;
      }
    });

    let prepaidOption = {
      ...findPrepaidOption,
      value: amount,
      prepaidData: data
    };

    //create prepaid option
    if (findPrepaidIdx === 0 || findPrepaidIdx !== null) {
      currentOptions[findPrepaidIdx] = prepaidOption;
    } else {
      currentOptions.push(prepaidOption);
    }

    setList(currentOptions);
    //ถ้า approve แล้วให้ save เปลี่ยน grandTotal เลย
    if (amount > 0) {
      save(currentOptions);
    }
  }
  return (
    <div>
      <div className="card-header card-header pt-0">
        {props.options}{" "}
        {!props.cantAddOption && (
          <button
            className="btn btn-space btn-outline-primary btn-space"
            onClick={() => {
              addLine();
            }}
          >
            {" "}
            Add{" "}
          </button>
        )}
        <span className="card-subtitle">Options</span>
      </div>
      {list.length >= 1 && (
        <div className="card-body mt-0">
          <div className="list-group d-flex">
            {_.map(list, (l, i) => {
              return (
                <Option2Line
                  {...props}
                  removeLine={handleRemove}
                  index={i}
                  handleEdit={handleEdit}
                  handleChangeOption={handleChangeOption}
                  data={l}
                  save={save}
                  allList={list}
                  paymentOptions={paymentOptions}
                  optionList={list}
                />
              );
            })}
          </div>
          <button
            onClick={() => save()}
            className="btn btn-space btn-primary"
            style={{ width: 100, marginTop: 10 }}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
}

export default Option2_Container;
