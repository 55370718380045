import { createSelector } from "reselect";
import _ from "lodash";

const voucherCampaign = state => state.spa.voucher.campaign;

export const voucherOptionsSelector = createSelector(
  [voucherCampaign],
  voucherCampaign => {
    let voucherOptions = [];
    _.map(voucherCampaign, voucher => {
      voucherOptions.push({
        ...voucher,
        value: voucher.voucherCampaignUid,
        label: `${voucher.name} (Qty:${voucher.qty} | Age:${voucher.age} Days)`
      });
    });
    return voucherOptions;
  }
);
