import { createSelector } from "reselect";
import _ from "lodash";

const roomList = state => state.spa.rooms;

export const roomOptionsSelector = createSelector(
  [roomList],
  roomList => {
    let roomOptions = [];
    _.map(roomList, room => {
      roomOptions.push({
        ...room,
        value: room.roomUid,
        label: room.name
      });
    });
    return roomOptions;
  }
);

export const bedOptionsSelector = createSelector(
  [roomList],
  roomList => {
    let bedOptions = [];
    _.map(roomList, room => {
      _.forEach(room.beds, bed => {
        bedOptions.push({
          ...bed,
          originalName: bed.name,
          value: bed.bedUid,
          label: `${room.name} | ${bed.name}`,
          room: {
            // name: room.name, roomUid: room.roomUid
            ...room
          }
        });
      });
    });
    return bedOptions;
  }
);
