import React, { useState, useEffect } from "react";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import swal from "sweetalert";
import randomUid from "../../shared/randomUid";
import moment from "moment";

function ManageVoucher(props) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [qty, setQty] = useState("");
  const [age, setAge] = useState("");
  const [mode, setMode] = useState("create"); //create, edit
  const { match } = props;
  const createVoucher = async () => {
    let isFail = validate();
    if (isFail) {
      return swal(isFail, "", "error");
    }
    var params = {
      TableName: "Spa_Voucher",
      Item: {
        storeUid: props.shop.uid,
        voucherCampaignUid: randomUid(10),
        name,
        description: description === "" ? null : description,
        qty: parseInt(qty),
        age: parseInt(age),
        row: moment().valueOf()
      }
    };
    try {
      await documentClient.put(params).promise();
      props.actions.fetchSpaVoucher(() => {
        swal("เพิ่ม Voucher สำเร็จ", "", "success");
        props.history.goBack();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const editVoucher = async () => {
    const { storeUid, voucherCampaignUid } = match.params;

    let isFail = validate();
    if (isFail) {
      return swal(isFail, "", "error");
    }

    var params = {
      TableName: "Spa_Voucher",
      Key: { storeUid, voucherCampaignUid },
      UpdateExpression:
        "set #name = :name, description = :description, qty =:qty, age =:age",
      ExpressionAttributeNames: { "#name": "name" },
      ExpressionAttributeValues: {
        ":name": name,
        ":description": description === "" ? null : description,
        ":qty": parseInt(qty),
        ":age": parseInt(age)
      }
    };
    try {
      await documentClient.update(params).promise();
      props.actions.fetchSpaVoucher(() => {
        swal("แก้ไข Voucher สำเร็จ", "", "success");
      });
    } catch (error) {
      console.log(error);
    }
  };

  const validate = () => {
    if (name === "") {
      return "กรุณากรอกชื่อสินค้า";
    }

    if (age === "") {
      return "กรุณากรอกราคาสินค้า";
    }

    if (qty === "") {
      return "กรุณากรอกจำนวนสินค้า";
    }

    return null;
  };

  useEffect(() => {
    if (!match.params.storeUid) return;
    fetchVoucher();
  }, []);

  const fetchVoucher = async () => {
    const { storeUid, voucherCampaignUid } = match.params;
    var params = {
      TableName: "Spa_Voucher",
      Key: {
        storeUid,
        voucherCampaignUid
      }
    };
    let resVoucher = await documentClient.get(params).promise();
    if (resVoucher.Item) {
      setInitData(resVoucher.Item);
    }
  };

  const setInitData = voucher => {
    setName(voucher.name);
    voucher.description && setDescription(voucher.description);
    setQty(voucher.qty);
    setAge(voucher.age);
    setMode("edit");
  };

  return (
    <div>
      <div className="row m-0">
        <div className="col-6 offset-3">
          <div class="card card-contrast">
            <div class="card-header card-header-contrast card-header-featured">
              <div className="row m-0 justify-content-between">
                <div>{mode === "create" ? "Create" : "Edit"} Voucher</div>
                <div>
                  <button
                    class="btn btn-space btn-secondary"
                    onClick={() => {
                      props.history.goBack();
                    }}
                  >
                    <i class="icon icon-left mdi mdi-chevron-left"></i>Back
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label>ชื่อ</label>
                <input
                  class="form-control"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label>รายละเอียด</label>
                <textarea
                  class="form-control"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                ></textarea>
              </div>
              <div className="row m-0">
                <div className="col-6 pl-0">
                  <div class="form-group">
                    <label>จำนวนครั้งที่ใช้ได้</label>
                    <input
                      class="form-control"
                      type="number"
                      value={qty}
                      onChange={e => setQty(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-6 pr-0">
                  <div class="form-group">
                    <label>อายุ Ex.: 30 วัน</label>
                    <input
                      class="form-control"
                      type="number"
                      value={age}
                      onChange={e => setAge(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {mode === "create" ? (
                <button
                  class="btn btn-space btn-success"
                  style={{ width: "100%" }}
                  onClick={createVoucher}
                >
                  CREATE
                </button>
              ) : (
                <button
                  class="btn btn-space btn-warning"
                  style={{ width: "100%" }}
                  onClick={editVoucher}
                >
                  EDIT
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageVoucher;
