import React, { useState } from "react";
import MemberProfileDetail from "./MemberProfileDetail";
import MemberVoucherList from "./MemberVoucherList";
import MemberActionBar from "./MemberActionBar";

function MemberProfile(props) {
  const [page, setPage] = useState("detail");
  return (
    <div>
      <MemberActionBar {...props} page={page} setPage={setPage} />
      {page === "detail" && <MemberProfileDetail {...props} />}
      {page === "voucher" && <MemberVoucherList {...props} />} 
    </div>
  );
}

export default MemberProfile;
