import React from "react";
import ReactDataSheet from "react-datasheet";
import swal from "sweetalert";
import _ from "lodash";
import * as inventoryItemActions from "../../shared/actions/inventoryItemActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class AddInventoryItem extends React.Component {
  state = {
    grid: [],
    groupUid: "none"
  };
  componentDidMount() {
    let { match } = this.props;
    this.setState({ groupUid: match.params.groupUid });
    this.createRows();
  }

  createRows = () => {
    let rowButton = (
      <a
        href="#"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          this.addRow();
        }}
      >
        {" "}
        เพิ่มแถว
      </a>
    );
    let grid = [
      [
        { readOnly: true, value: rowButton, width: 100 },
        {
          readOnly: true,
          value: (
            <span>
              Name<span style={{ color: "red" }}>*</span>
            </span>
          ),
          cantAdd: true
        },
        {
          readOnly: true,
          value: (
            <span>
              Price<span style={{ color: "red" }}>*</span>
            </span>
          )
        },
        { readOnly: true, value: "Unit" },
        { readOnly: true, value: "SKU/Barcode" },
        { readOnly: true, value: "Custom Search" },
        { readOnly: true, value: "Time (minute)" }
      ]
    ];
    for (let i = 1; i < 101; i++) {
      grid.push([
        { readOnly: true, value: i },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" }
      ]);
    }
    this.setState({ grid });
  };

  addRow = () => {
    let { grid } = this.state;
    let newGrid = [
      ...grid,
      [
        { readOnly: true, value: grid.length },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" }
      ]
    ];
    this.setState({ grid: newGrid });
  };

  submit = () => {
    let { actions, history } = this.props;
    let { grid, groupUid } = this.state;
    let items = [];
    if (groupUid === "none") {
      swal("เกิดข้อผิดพลาด", "กรุณาเลือกกลุ่ม", "error");
      return;
    }
    const gridToBeComputed = [...grid.slice(1)];
    let isFail = false;
    _.forEach(gridToBeComputed, i => {
      let count = 0;
      //NAME
      if (i[1].value !== "") {
        count++;
      }
      //PRICE
      if (i[2].value !== "" && !_.isNaN(parseFloat(i[2].value))) {
        count++;
      }

      // if (i[3].value !== "") {
      //   count++;
      // }

      if (count > 0 && count < 2) {
        isFail = true;
        return;
      }
      //TIME MINUTES
      if (i[6].value !== "" && _.isNaN(parseInt(i[6].value))) {
        isFail = true;
        return;
      }
      if (count === 2) {
        let tmp = {
          name: i[1].value,
          price: i[2].value,
          unit: i[3].value,
          customSearch: i[5].value,
          timeMinutes: i[6].value
        };
        if (i[4].value !== "") {
          tmp.barcode = i[4].value;
        }
        items.push(tmp);
      }
    });
    if (isFail === true) {
      swal("เกิดข้อผิดพลาด", "คุณกรอกข้อมูลผิดพลาดหรือกรอกไม่ครบถ้วน", "error");
      return;
    }
    actions.addInventoryItems(items, groupUid, () => {
      history.push("/inventory");
    });
  };

  selectGroup = e => {
    this.setState({ groupUid: e.target.value });
  };

  render() {
    let { groups, history } = this.props;
    let { groupUid } = this.state;

    return (
      <div>
        <div className="card card-contrast">
          <div className="card-header card-header-contrast card-header-featured">
            <div
              className="row justify-content-between"
              style={{ marginLeft: 15, marginRight: 15 }}
            >
              <div>
                <button
                  style={{ marginTop: 16 }}
                  onClick={() => history.push("/inventory")}
                  className="btn btn-space btn-secondary hover"
                >
                  <i className="icon icon-left mdi mdi-chevron-left" />
                  Back
                </button>
              </div>
              <div>
                <div style={{ marginTop: 16 }}>Add Inventory Items</div>
              </div>
              <div className="col-md-5">
                <div className="form-group row pt-1">
                  <label
                    style={{ marginTop: 3 }}
                    className="col-md-3 col-form-label text-sm-right"
                  >
                    Group:{" "}
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-control"
                      onChange={this.selectGroup}
                      value={groupUid}
                    >
                      <option value={"none"}>เลือกกลุ่ม</option>
                      {_.map(groups, group => {
                        return <option value={group.uid}>{group.name}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div>
                <button
                  style={{ marginTop: 16 }}
                  onClick={this.submit}
                  className="btn btn-space btn-success hover"
                >
                  <i className="icon icon-left mdi mdi-collection-plus" />
                  ADD
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div>
              <ReactDataSheet
                // width="100%"
                data={this.state.grid}
                valueRenderer={cell => cell.value}
                onCellsChanged={changes => {
                  const grid = this.state.grid.map(row => [...row]);
                  changes.forEach(({ cell, row, col, value }) => {
                    grid[row][col] = { ...grid[row][col], value };
                  });
                  this.setState({ grid });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    groups: state.inventory.groups
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...inventoryItemActions }, dispatch);
  return {
    actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddInventoryItem);
