import React, { useState } from "react";
import Summary from "./Summary";
import Receipts from "./Receipts";
import Products from "./Products";
import EmpAndAgent from "./EmpAndAgent";
import SatRate from "./SatRate";
import Select from "react-select";

import { connect } from "react-redux";
import * as spaPosActions from "../../shared/actions/spaPosActions";
import { bindActionCreators } from "redux";
import * as inventorySelector from "../../shared/selector/inventorySelector";
import * as spaSalesSelector from "../../shared/selector/spaSalesSelector";
import * as branchSelector from "../../shared/selector/branchOptionsSelector";

function SpaReport(props) {
  const [view, setView] = useState(0);
  const [branch, setBranch] = useState(null);

  return (
    <div>
      {/* <Navbar /> */}
      <div className="card m-0">
        {/* <div className="card-header">Icon Text Tabs</div> */}
        <div className="tab-container">
          {!props.linked ? (
            <div className="row m-0 justify-content-between">
              <div>
                <Tabs view={view} setView={setView} />
              </div>
              <div>
                <div style={{ minWidth: 300, marginRight: 3, marginTop: 3 }}>
                  <Select
                    value={branch}
                    onChange={setBranch}
                    options={[
                      { label: "ทุกสาขา", value: "all" },
                      ...props.branchOptions
                    ]}
                    placeholder="เลือกสาขา"
                  />
                </div>
              </div>
            </div>
          ) : (
            <Tabs view={view} setView={setView} />
          )}

          <div className="tab-content m-0 p-0">
            <div className="tab-pane active" id="home5" role="tabpanel">
              {view === 1 && <Receipts {...props} branch={branch} />}
              {view === 2 && <Products {...props} branch={branch} />}
            </div>
          </div>
        </div>
      </div>
      {view === 0 && <Summary {...props} branch={branch} />}
      {view === 3 && <EmpAndAgent {...props} target="emp" branch={branch} />}
      {view === 4 && <EmpAndAgent {...props} target="agent" branch={branch} />}
      {view === 5 && <SatRate {...props} branch={branch} />}
      {view === 6 && (
        <EmpAndAgent {...props} target="entertain" branch={branch} />
      )}
      {view === 7 && <EmpAndAgent {...props} target="sales" branch={branch} />}
    </div>
  );
}

const Tabs = ({ view, setView }) => {
  return (
    <ul className="nav nav-tabs" role="tablist">
      <Tab
        onClick={() => {
          setView(0);
        }}
        title="Summary"
        icon="icon mdi mdi-star"
        isActive={view === 0}
      />
      <Tab
        onClick={() => {
          setView(1);
        }}
        title="Receipt"
        icon="icon mdi mdi-receipt"
        isActive={view === 1}
      />
      <Tab
        onClick={() => {
          setView(2);
        }}
        title="Product"
        icon="icon mdi mdi-grid"
        isActive={view === 2}
      />
      <Tab
        onClick={() => {
          setView(3);
        }}
        title="Therapist"
        icon="icon mdi mdi-grid"
        isActive={view === 3}
      />
      <Tab
        onClick={() => {
          setView(4);
        }}
        title="Agent"
        icon="icon mdi mdi-grid"
        isActive={view === 4}
      />
      <Tab
        onClick={() => {
          setView(6);
        }}
        title="Entertain"
        icon="icon mdi mdi-grid"
        isActive={view === 6}
      />
      <Tab
        onClick={() => {
          setView(7);
        }}
        title="Sales"
        icon="icon mdi mdi-grid"
        isActive={view === 7}
      />
      <Tab
        onClick={() => {
          setView(5);
        }}
        title="Sat Rate"
        icon="icon mdi mdi-grid"
        isActive={view === 5}
      />
    </ul>
  );
};

const mapStateToProps = state => {
  return {
    spaPos: state.spa.pos,
    user: state.user.loggedIn,
    menuItems: inventorySelector.groupItemSelector(state),
    inventory: inventorySelector.typeGroupItemSelector(state),
    shop: state.shop,
    employeeObj: spaSalesSelector.employeeObjSelector(state),
    agentObj: spaSalesSelector.agentObjSelector(state),
    employeeOptions: spaSalesSelector.employeeOptionsSelector(state),
    entertainOptions: spaSalesSelector.entertainOptionsSelector(state),
    entertainObj: spaSalesSelector.entertainObjSelector(state),
    agentOptions: spaSalesSelector.agentOptionsSelector(state),
    salesOptions: spaSalesSelector.salesOptionsSelector(state),
    salesObj: spaSalesSelector.salesObjSelector(state),
    branchOptions: branchSelector.branchOptionsSelector(state),
    linked: state.shop.linked.inventory
  };
};
function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...spaPosActions }, dispatch);
  return {
    actions,
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpaReport);

const Tab = ({ title, icon, isActive, onClick }) => {
  return (
    <li className="nav-item">
      <a
        class={`nav-link ${isActive ? "active" : ""}`}
        href="#home5"
        data-toggle="tab"
        role="tab"
        onClick={onClick}
      >
        <span class={icon} />
        {title}
      </a>
    </li>
  );
};
