import React from "react";

const SummaryCard = ({
  title,
  subtitle,
  bgColor,
  height,
  color,
  paddingLeft
}) => {
  return (
    <div
      className="align-self-end"
      style={{
        flexGrow: 1,
        paddingLeft: paddingLeft || 0
      }}
    >
      <div
        className="card m-0"
        // style={{ minHeight: 100, marginLeft: 5 }}
        style={{
          backgroundColor: bgColor || "white",
          height: height || 70
        }}
      >
        <div className="card-body pt-1">
          <center>
            <span style={{ fontSize: 20, color: color || "#e2595f" }}>
              {title}
            </span>
          </center>
          <center>
            <span>{subtitle}</span>
          </center>
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;
