import React, { useState, useEffect } from "react";
import useFetchReceipt from "../SpaReport/useFetchReceipt";
import moment from "moment";
import _ from "lodash";

function ReceiptEvalute(props) {
  //   const [count, setCount] = useState(0);
  const receipts = useFetchReceipt();

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    receipts.fetchReceipts(props.shop.uid, {
      dateRange: {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD") + "zzzzzzzzzzzzzzzzz"
      }
    });
  };

  const requestEvaluate = data => {
    let popup = window.typeformEmbed.makePopup(
      `https://niceloop.typeform.com/to/HnpAuY?&storeuid=${props.shop.uid}&jobuid=${data.jobUid}&name=${data.member.name}`,
      {
        hideHeaders: true,
        hideFooter: true,
        onSubmit: () => {
          setTimeout(fetch, 2000);
        }
      }
    );
    popup.open();
  };

  return (
    <div>
      <div class="card card-table">
        <div class="card-body">
          <table class="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Member</th>
                <th>Sales</th>
                <th>Grand Total</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {_.map(receipts.receipts, rec => {
                if (rec.satRate) return <div />;
                if (_.isEmpty(rec.member) || !rec.therapist) return <div />;

                return (
                  <tr>
                    <td scope="row">
                      {moment(rec.timestamp).format("DD/MM/YYYY HH:mm A")}
                    </td>
                    <td className="cell-detail">
                      <span>{rec.member && rec.member.name}</span>
                    </td>
                    <td className="cell-detail">
                      <span>{rec.therapist && rec.therapist.name}</span>
                    </td>
                    <td>
                      <span style={{ color: "blue", fontWeight: "bold" }}>
                        {(rec.payment.grandTotal || 0).toLocaleString()}
                      </span>
                    </td>
                    <td>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          requestEvaluate(rec);
                        }}
                      >
                        Evaluate
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ReceiptEvalute;
