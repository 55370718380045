import React, { useState } from "react";
import storeInit from "../PosCart/store";
import * as actions from "../PosCart/actions";
import { Provider } from "react-redux";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import PosPaymentMain from "./PosPaymentMain";
import Numpad from "./Numpad";
import PosCartSummary from "../PosCart/Summary";
// import { grandTotalSelector } from "../PosCart/selector";
import * as settingSelector from "../../shared/selector/settingSelector";
import { connect } from "react-redux";
import _ from "lodash";
import * as computeSelector from "../PosCart/selector";
import PaymentCard from "./PaymentCard";
import computePaymentType from "../../shared/functions/computePaymentTypes";
import useDrawerReducer from "../Spa/hook.drawer.reducer";
import PrintReceipt from "../../printer/service.print.receipt.full";
import PrintSpaWorkingCoupon from "../../printer/service.print.spaWorkingCoupon";
import combineJobItemsToBillItems from "../../shared/functions/combineJobItemsToBillItems";
function PosPayment(props) {
  const [store, setStore] = useState(storeInit);
  const [amount, setAmount] = useState(0);
  const [numInput, setNumInput] = useState("");
  const [payments, setPayments] = useState([]);
  const { state: drawerState, dispatch: drawerDispatch } = useDrawerReducer();

  const getChange = () => {
    if (amount === "") {
      return "";
    }
    let change =
      parseFloat(amount) -
      (parseFloat(computeSelector.grandTotalSelector(store.getState())) || 0);

    return { string: change.toLocaleString(), int: change };
  };

  const getIsPop = () => {
    // let isPop = JSON.parse(window.localStorage.getItem("isPopEvaluate"));
    // if (isPop === null) {
    //   isPop = true;
    // }
    // return isPop;
  };

  const makePayment = () => {
    if (props.cashier === null || !props.cashier) {
      return swal(
        "No Cashier !",
        "กรุณาสร้าง Employee Pin เพื่อระบุพนักงานขาย",
        "error"
      );
    }
    if (getChange().int < 0) {
      return swal(
        "จำนวนเงินไม่ครบ",
        "จำนวนเงินที่ได้รับไม่เพียงพอต่อราคาสินค้า",
        "error"
      );
    }
    let data = { 
      drawerState
    };

    if (props.getCartStoreData) {
      let cartStoreData = props.getCartStoreData();
      let payment = _.cloneDeep(cartStoreData);
      delete payment["items"];
      delete payment["promotionItems"];
      delete payment["qtyHolder"];

      payment = {
        ...payment,
        received: amount,
        change: getChange().int,
        paymentTypes: computePaymentType(
          payments,
          getGrandTotal(),
          getChange().int
        )
      };

      let items = combineJobItemsToBillItems(
        cartStoreData.items,
        cartStoreData.promotionItems,
        cartStoreData.qtyHolder,
        cartStoreData.discountHolder
      );

      data = {
        ...data,
        items,
        payment
      };
    }

    store.dispatch(
      actions.payment(
        (data, cb) => {
          props.history.push("/pos/0");
          PrintThermal(data);
          cb && cb();
        },
        data
        // {
        //   evaluate: willDelete
        // }
      )
    );
  };

  const PrintThermal = data => {
    //PRINT RECEIPT
    let print = new PrintReceipt(props.dispatch, data, {
      employeeName: props.currentEmployee.name
    });
    print.build().print();

    if (!data.meta.room) return;

    setTimeout(() => {
      let therapists = getTherapistData(data.therapist);
      _.forEach(therapists, ther => {
        let printt = new PrintSpaWorkingCoupon(props.dispatch, {
          ...data,
          // items: ther.items,
          therapist: ther
          // isMultiple: true
        });
        printt.build().print();
      });
    }, 1000);
  };

  const getTherapistData = therapistData => {
    let therObj = {};
    _.map(therapistData, course => {
      let pickTime = course.pickTime;
      _.forEach(course.therapists, ther => {
        // therObj[ther.uid] = ther;
        if (!therObj[ther.uid]) {
          therObj[ther.uid] = {
            name: ther.name,
            courses: {},
            totalWorkMinutes: 0
          };
        }
        //+ WORK MINS
        therObj[ther.uid].totalWorkMinutes += ther.workMinutes;

        //ADD COURSE
        if (!therObj[ther.uid].courses[pickTime]) {
          therObj[ther.uid].courses[pickTime] = {
            ...course,
            courseMinutes: 0,
            workMinutes: 0
          };
          //DELETE UNNECESSARY DATA
          delete therObj[ther.uid].courses[pickTime].therapists;
        }
        //เวลาทั้งหมดของคอร์ส
        therObj[ther.uid].courses[pickTime].courseMinutes +=
          course.courseMinutes;
        //เวลาที่ปฏิบัติงาน
        therObj[ther.uid].courses[pickTime].workMinutes += ther.workMinutes;
      });
    });

    return _.values(therObj);
  };

  function getGrandTotal() {
    return computeSelector.grandTotalSelector(store.getState());
  }

  const NumPadButtons = () => {
    return (
      <Numpad
        {...props}
        setAmount={char => {
          setNumInput(numInput + char);
        }}
        delAmount={() => {
          setNumInput(numInput.slice(0, numInput.length - 1));
        }}
        clear={() => {
          setNumInput("");
        }}
        enter={makePayment}
        enablePayButton={true}
        makePayment={makePayment}
        dispatch={props.dispatch}
        fillAmount={() => {
          //NOT FOR PREPAID-----
          let checkPayment = payments[payments.length - 1];
          if (checkPayment.uid === "prepaid001") {
            return;
          }
          //--------------------

          let grandTotal = getGrandTotal();
          if (payments.length === 1) {
            setNumInput(grandTotal);
            return;
          }

          let currentAmount = 0;
          let cuttedPaymentType = payments.slice(0, payments.length - 1);
          _.forEach(cuttedPaymentType, payment => {
            currentAmount += parseInt(payment.amount);
          });

          let totalRemain = grandTotal - currentAmount;
          setNumInput(totalRemain);
        }}
      />
    );
  };

  if (props.disableSummary) {
    return (
      <Provider store={store}>
        <div className="row m-0">
          <div className="col-6" style={{ overflowY: "auto", height: 500 }}>
            <PaymentCard
              getChange={getChange}
              amount={amount}
              setAmount={setAmount}
              paymentTypes={props.paymentTypes}
              setPayments={setPayments}
              numInput={numInput}
              setNumInput={setNumInput}
            />
          </div>
          <div className="col-6">
            <NumPadButtons />
          </div>
        </div>
      </Provider>
    );
  }

  return (
    <Provider store={store}>
      <div className="row">
        <div className="col-8">
          <div className="card card-flat">
            <div className="row no-gutters">
              <div className="col-5">
                <PosCartSummary showSubmitButton={false} />
              </div>
              <div className="col-7">
                <PaymentCard
                  getChange={getChange}
                  amount={amount}
                  setAmount={setAmount}
                  paymentTypes={props.paymentTypes}
                  numInput={numInput}
                  setNumInput={setNumInput}
                  setPayments={setPayments}
                />
              </div>

              {/*  BUTTON */}
              <div className="col-1" />
              <div className="col-3">
                {props.spaSave && (
                  <center>
                    <button
                      className="btn btn-space btn-secondary btn-big"
                      style={{ height: 48, borderRadius: 8, width: "90%" }}
                      onClick={props.spaSave}
                    >
                      <i
                        class="icon icon-left mdi mdi-check"
                        style={{ display: "inline", fontSize: 16 }}
                      />
                      Save
                    </button>
                  </center>
                )}
              </div>
              <div className="col-1" />

              <div className="col-7">
                <div
                  //   onMouseEnter={() => setIsGrandTotalHover(true)}
                  //   onMouseLeave={() => setIsGrandTotalHover(false)}
                  className="card-header pt-0 text-center card-header"
                  //   style={
                  //     isGrandTotalHover ? grandTotal_hoder_Style : grandTotalStyle
                  //   }
                >
                  <div className="row m-0">
                    <div className="col-6 p-0 pr-1">
                      <button
                        className="btn btn-space btn-secondary btn-big w-100"
                        style={{ height: 48, borderRadius: 8 }}
                        // onClick={payment}
                      >
                        <i
                          style={{ display: "inline", fontSize: 16 }}
                          class="icon icon-left mdi mdi-check"
                        />
                        Print Sattlement
                      </button>
                    </div>
                    <div className="col-6 p-0 pl-1">
                      <button
                        className="btn btn-space btn-secondary btn-big w-100"
                        style={{ height: 48, borderRadius: 8 }}
                        onClick={makePayment}
                      >
                        <i
                          style={{ display: "inline", fontSize: 16 }}
                          class="icon icon-left mdi mdi-check"
                        />
                        PAY
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>

        <div className="col-4">
          <NumPadButtons />
        </div>
      </div>
    </Provider>
  );
}

const mapStateToProps = state => {
  return {
    paymentTypes: settingSelector.paymentTypeSelector(state),
    currentEmployee: state.employee.current,
    spaPos: state.spa.pos,
    cashier: state.employee.current
  };
};
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PosPayment)
);
