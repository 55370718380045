import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import Select from "react-select";

import "./bookingGrid.css";

const timeList = [];
const startt = moment().set({ minute: 0, hours: 10, second: 0 });
_.times(24, i => {
  timeList.push(startt.format("HH:mm"));
  startt.add(30, "minutes");
});

let lastName = null;
let lastGuest = null;


const fullScreenstyle = { 
  width : '100%',
  position : 'fixed',
  height: 'calc(100%)',
  backgroundColor : 'white',
  top : 0,
  left : 0,
  zIndex : 99999,
  overflow : 'auto',
}

function BookingGridRoom(props) {
  const [bedsHolder, setBedsHolder] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [ SPLIT_ROWS  , SET_SPLIT_ROWS] = useState(12)
  const [branch, setBranch] = useState(null);
  const [zoom, setZoom] = useState(1);
const [fullScreen, setFullScreen] = useState(false)
  const [roomsHolder, setRoomsHolder] = useState({});
  const [localBranchRooms, setLocalBranchRooms] = useState([]);
const [dynamicStoreUid, setDynamicStoreUid] = useState(null);
  const { hqMode = false, branchOptions = [], mainPageBranch } = props;

  useEffect(() => {
    let filterDate = localStorage.getItem("bookingFilterDate");
    let initBranch = null;
    //INIT SET PREVIOUS BRANCH
    if (hqMode && mainPageBranch) {
      initBranch = mainPageBranch;
    } else {
      initBranch = branchOptions[0];
    }
    setDynamicStoreUid (hqMode ? initBranch.value : null)
    if (filterDate) {
      fetchJobs({
        forceDate: filterDate,
        forceStore: hqMode ? initBranch : null
      });
      setStartDate(new Date(filterDate));
    } else {
      fetchJobs({
        forceStore: hqMode ? initBranch : null
      });
    }
    //FIRST LOAD
    if (!filterDate && hqMode && initBranch) {
      handleSetBranch(initBranch);
    }

  }, []);

  const handleSetStartDate = date => {
    fetchJobs({ forceDate: date });
    setStartDate(date);
  };

  const reload = ()=>{
    fetchJobs({ forceDate: startDate });
  }

  const handleSetBranch = (branch, isNotFetchJob) => {
    setBranch(branch);
    props.fetchLocalBranchRooms(branch.value, items => {
      setLocalBranchRooms(items);
    });
    props.setMainPageBranch(branch);
    !isNotFetchJob && fetchJobs({ forceStore: branch });
  };
  async function fetchJobs({ forceDate, forceStore }) {
    let date = moment(forceDate || startDate).format("YYYY-MM-DD");
    let shopUid = null;

    //BUG PROTECT
    if (hqMode && branchOptions.length < 1) return;

    if (forceStore) {
      shopUid = forceStore.value;
    } else {
      shopUid = hqMode ? branch.value : props.shop.uid;
    }

    var params = {
      TableName: "Spa_Booking",
      KeyConditionExpression:
        "storeUid = :hkey and begins_with(bookUid, :currentDate)",
      ExpressionAttributeValues: {
        ":hkey": shopUid,
        ":currentDate": date
      }
    };
    let data = await documentClient.query(params).promise();
    lastGuest = null;
    lastName = null;
    if (data.Items) {
      computeJobs(data.Items);
    }
    

    hqMode && handleSetBranch(forceStore || branch, true);

    setDynamicStoreUid(shopUid)
  }

  function zoomOut (){
    setZoom(zoom-0.05)
  }
  function zoomReset (){
    setZoom(1)
  }
  function zoomIn (){
    setZoom(zoom+0.05)
  }

  function computeJobs(items) {
    let beds = {};

    //FRACTION BY 10 MINS
    //1 hour = 6 * 10
    // let timeFraction = 6;
    let roomsHolder = {};

    _.forEach(items, line => {
      let roomUid = line.bed.room.roomUid; //bedUid;
      var startTime = moment(line.startDate);
      var endTime = moment(line.endDate);
      roomsHolder[roomUid] = roomsHolder[roomUid] || {};

      setMinutesTimesToSlot(startTime);
      setMinutesTimesToSlot(endTime, -30);

      while (startTime <= endTime) {
        let t = new moment(startTime).format("HH:mm");
        roomsHolder[roomUid][t] = line;

        startTime.add(30, "minutes");
      }
    });

    setRoomsHolder(roomsHolder);
  }

  function setMinutesTimesToSlot(startTime, ifLastReduceMM) {
    startTime.set("second", 0);

    if (startTime.get("minute") == 0) {
      startTime.set("minute", 0);
    }
    if (startTime.get("minute") == 30) {
      startTime.set("minute", 30);
    }

    if (startTime.get("minute") >= 1 && startTime.get("minute") <= 29) {
      startTime.set("minute", 30);
    }

    if (startTime.get("minute") >= 31 && startTime.get("minute") <= 59) {
      startTime.set("minute", 0);
    }

    if (ifLastReduceMM) {
      startTime.add(ifLastReduceMM, "minutes");
    }

    return startTime;
  }
  
  const setBookingInitStartDate = () => {
    window.localStorage.setItem(
      "bookingInitStartDate",
      moment(startDate).toISOString()
    );
  };

  const openCapacityTab = () => {
    let shopId = hqMode ? branch.uid : props.shop.uid;
    const url = `https://payment-confirm-dev-web.firebaseapp.com/booking/${shopId}`;
    const url2 = `https://divana-capacity.web.app/?uid=${shopId}`
    window.open(url2, "_blank");
  };

  let rooms = hqMode ? localBranchRooms : props.spaRooms;
  rooms = _.sortBy(rooms, 'row')
  return (
   
    <div style={ fullScreen ? fullScreenstyle : {}  }>
       {lastGuest = null}
      <div className="row mb-0">
        <div class="col-12 p-5 d-flex justify-content-between">
          <div className="row m-0">
            {/* <div>
              <span
                style={{
                  fontSize: 20
                  // fontWeight: "bold"
                }}
              >
                {hqMode ? "เลือกสาขา" : "ตารางการจอง"}
              </span>
            </div> */}
            {hqMode && (
              <div className="ml-2" style={{ width: 300 }}>
                <label>เลือกสาขา</label>
                <Select
                  value={branch}
                  onChange={handleSetBranch}
                  options={branchOptions}
                  placeholder="เลือกสาขา"
                />
              </div>
            )}
            <div className="ml-2">
              <label>เลือกวัน</label>
              <br />
              <DatePicker
                selected={startDate}
                onChange={handleSetStartDate}
                timeIntervals={15}
                dateFormat="dd/MM/yyyy"
                className="form-control"
              />
              {/* <button
                class="btn btn-space btn-primary"
                onClick={fetchJobs}
                style={{ height: 47 }}
              >
                Submit
              </button> */}
            </div>
            <div className="ml-2" style={{ }}>
                <label>Zoom</label>
                <div style={{display : 'block'}}>
                <button class="btn btn-space btn-primary" onClick={zoomOut}>-</button>
                 <button class="btn btn-space btn-primary"  onClick={zoomReset}>Reset</button>
                 <button class="btn btn-space btn-primary"  onClick={zoomIn}>+</button>
                 <button  class={`btn btn-space ${fullScreen ? "btn-warning" : "btn-primary"}`}  onClick={()=>{ setFullScreen(!fullScreen)  }}>{!fullScreen ? "FullScreen" : "EXIT FULLSCREEN" }</button>

                </div>
                
              </div>
            <props.Capacity   date={moment(startDate).format('YYYY-MM-DD')}   storeUid={dynamicStoreUid}/>
          </div>
          <div>
          <button
              class="btn btn-space btn-primary align-self-end"
              style={{ height: 40 }}
              onClick={reload}
            >
              Reload
            </button>

            <button
              class="btn btn-space btn-primary align-self-end"
              style={{ height: 40 }}
              onClick={openCapacityTab}
            >
              Capacity
            </button>
            <button
              class="btn btn-space btn-primary align-self-end ml-2"
              style={{ height: 40 }}
              onClick={() => {
                setBookingInitStartDate();
                props.goToBookingForm();
              }}
            >
              New Book
            </button>
          </div>
        </div>
      </div>
      <div
        className="p-3"
        //    style={{ width: "100%", overflowX: "scroll" }}
      >
        <ReactTooltip />
        <table class='table' style={{zoom : zoom}}>

          {_.times(    Math.ceil(rooms.length / SPLIT_ROWS) , i=>{
            
            return [ <thead>
              <tr>
                <th colSpan={1}>   </th>
                <th colSpan={1}>Beds  </th>

                {_.map(timeList, t => {
                  const isZeroHH = _.includes(t, ":00");
  
                  if (isZeroHH) {
                    return (
                      <th
                        colSpan={2}
                        style={{
                          borderLeft: isZeroHH
                            ? "1px solid #d6d7d8"
                            : "0px solid #d7d9da"
                        }}
                      >
                        {t}
                      </th>
                    );
                  } else {
                    return null;
                  }
                })}
              </tr>
            </thead>,
            <tbody>
              {_.map(_.slice(rooms, i*SPLIT_ROWS , (i+1)*SPLIT_ROWS ), (r, roomUid) => {
                return (
                  <tr style={{ borderBottom: "1px solid #f1f3f4" }}>
                    <td><b>{r.name}</b></td>
                    <td style={{color : 'gray'}}>({r.beds.length})</td>
  
                    {_.map(timeList, (t, i) => {
                      const isZeroHH = _.includes(t, ":00");
                      const width = 60;
                      const height = 40;
                      if (roomsHolder[r.roomUid] && roomsHolder[r.roomUid][t]) {
                        let _name = null
                        let fromPos =  roomsHolder[r.roomUid][t].fromPos || false;
                        let isVoid = roomsHolder[r.roomUid][t].void || false;
                        let member = roomsHolder[r.roomUid][t].member || null;

                        if (lastGuest !== roomsHolder[r.roomUid][t]) {
                          _name =  roomsHolder[r.roomUid][t].name;
                          lastGuest = roomsHolder[r.roomUid][t];
                        } else {
                           
                        }

                        if(member){
                          _name = member.firstName + member.lastName
                        }
  
                        return (
                          <td
                            data-tip="hello world"
                            style={{
                              width,
                              height,
  
                              backgroundColor: isVoid ? "red"  : fromPos ? "orange" :  "rgb(33, 150, 243)",
                              borderLeft: isZeroHH && _name
                                ? "1px solid #d6d7d8"
                                : "0px solid #d7d9da"
                            }}
                            onClick={() => {
                        
                              //props.history.push(`?startDate=${moment(startDate).format("YYYY-MM-DD")}`)

                              
                              setBookingInitStartDate();
                              // props.setInitEditData(lastGuest);
                              // props.goToBookingForm();

                              props.setTab()
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                marginLeft: 8,
                                marginTop: -8,
                                color: "white"
                              }}
                            >
                              {_name}
                            </div>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            style={{
                              width,
                              height,
                              borderLeft: isZeroHH
                                ? "1px solid #d6d7d8"
                                : "0px solid #d7d9da"
                            }}
                          ></td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
        ]
          })}
           </table>

        {/* {_.map(rooms, room => {
          return (
            <RoomLine
              {...props}
              room={room}
              bedsHolder={bedsHolder}
              setBookingInitStartDate={setBookingInitStartDate}
            />
          );
        })} */}
      </div>
    </div>
  );
}

const RoomLine = props => {
  const { room, bedsHolder } = props;

  return (
    <div className="mt-5">
      <div class="date-row-container">
        <div
          class="date"
          style={{
            color: "blue",
            fontWeight: "bold"
          }}
        >
          {room.name}
        </div>
        <TimeHeader bedLength={(room.beds && room.beds.length) || 0} />
      </div>

      {_.map(room.beds, bed => {
        let jobs = _.orderBy(
          bedsHolder[bed.bedUid],
          ["startDate", "timestamp"],
          ["asc", "asc"]
        );

        return (
          <div class="date-row-container">
            <div class="date date-bottom">{bed.name}</div>
            <div class="grid-container2">
              {_.map(jobs, job => {
                return [
                  <ReactTooltip id={job.bookUid} type="dark">
                    <div>Name: {job.name}</div>
                    <div>
                      Room: {job.bed.room.name} | {job.bed.name}
                    </div>
                    <div>
                      Time: {job.startTime} - {job.endTime}
                    </div>
                    {job.mobile && <div>Mobile: {job.mobile}</div>}
                    <div>
                      <em>Remark: {job.remark}</em>
                    </div>
                    <div>Paid Status: {job.isPaid ? "Yes" : "No"}</div>
                  </ReactTooltip>,
                  <div
                    data-tip
                    data-for={job.bookUid}
                    class="grid-item"
                    style={{
                      gridColumn: `${job.startLine}/${job.endLine}`,
                      backgroundColor: job.isPaid ? "green" : "#2196f3"
                      //   gridRow: "1"
                    }}
                    onClick={() => {
                      props.setBookingInitStartDate();
                      props.setInitEditData(job);
                      props.goToBookingForm();
                    }}
                  >
                    <div class="line1">{job.name}</div>
                    <div class="line2">
                      <em>{job.remark}</em>
                    </div>
                  </div>
                ];
              })}
            </div>
          </div>
        );
      })}

      {/* <div class="date-row-container">
        <div class="date">Bed 2</div>
        <div class=" grid-container2">
          <div class="grid-item event1">Event1</div>
          <div class="grid-item event2">Event2</div>
        </div>
      </div> */}
    </div>
  );
};

export default BookingGridRoom;

const TimeHeader = ({ bedLength }) => {
  let hour = 9;

  return (
    <div class="time-header" style={{ height: bedLength * 130 }}>
      {_.times(90, i => {
        if (i === 0) {
          return (
            <div
              className={`time-box-left`}
              style={{
                gridColumn: `${1}/${7}`,
                gridRow: "1"
              }}
            >
              <div>9:00</div>
            </div>
          );
        }

        if (i !== 0 && i % 6 === 0) {
          hour++;
          return (
            <div
              className={`time-box-left ${i === 89 ? "time-box-right" : ""}`}
              style={{
                gridColumn: `${i + 1}/${i + 1 + 7}`,
                gridRow: "1"
              }}
            >
              <div>{hour}:00</div>
            </div>
          );
        }
      })}
    </div>
  );
};
