import React, { Component } from "react";
import "./style.css";

const list = [
  {
    id: 0,
    title: "Apple",
    selected: false,
    key: "fruit"
  },
  {
    id: 1,
    title: "Orange",
    selected: false,
    key: "fruit"
  },
  {
    id: 2,
    title: "Grape",
    selected: false,
    key: "fruit"
  },
  {
    id: 3,
    title: "Pomegranate",
    selected: false,
    key: "fruit"
  },
  {
    id: 4,
    title: "Strawberry",
    selected: false,
    key: "fruit"
  }
];

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      headerTitle: this.props.title,
      list: list
    };
    this.close = this.close.bind(this);
  }

  componentDidUpdate() {
    const { listOpen } = this.state;
    setTimeout(() => {
      if (listOpen) {
        window.addEventListener("click", this.close);
      } else {
        window.removeEventListener("click", this.close);
      }
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.close);
  }

  close(timeOut) {
    this.setState({
      listOpen: false
    });
  }

  selectItem(title, id, stateKey) {
    this.setState(
      {
        headerTitle: title,
        listOpen: false
      },
      this.props.resetThenSet(id, stateKey)
    );
  }

  toggleList() {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }));
  }

  render() {
    const {
      list,
      handleSetQty,
      lineUid,
      handleSetPrice,
      index,
      handleSplitLine,
      item
    } = this.props;
    const { listOpen, headerTitle } = this.state;
    return (
      <div className="dd-wrapper">
        <div
          className="dd-header"
          onClick={e => {
            e.stopPropagation();
            this.toggleList();
          }}
        >
          <div className="dd-header-title">options</div>
          {/* <div className="dd-header-title">{headerTitle}</div> */}
        </div>
        {listOpen && (
          <ul className="dd-list" onClick={e => e.stopPropagation()}>
            <li
              className="dd-list-item"
              key="2"
              onClick={e => {
                e.stopPropagation();
                handleSetQty(item, index);
                this.toggleList();
              }}
            >
              <span>Set QTY</span>
            </li>

            <li
              className="dd-list-item"
              key="3"
              onClick={e => {
                e.stopPropagation();
                handleSetPrice(item, index);
                this.toggleList();
              }}
            >
              <span>Set Price</span>
            </li>

            <li
              className="dd-list-item"
              key="4"
              onClick={e => {
                e.stopPropagation();
                handleSplitLine(item, index);
                this.toggleList();
              }}
            >
              <span>Split Line</span>
            </li>
          </ul>
        )}
      </div>
    );
  }
}

export default Dropdown;
