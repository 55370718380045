import React, { useState, useEffect } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { bindActionCreators } from "redux";
import * as spaPosActions from "../../shared/actions/spaPosActions";
import * as spaPosSettingActions from "../../shared/actions/spaPosSettingActions";
import * as roomsSelector from "../../shared/selector/roomsSelector";
import * as spaSalesSelector from "../../shared/selector/spaSalesSelector";
import * as settingSelector from "../../shared/selector/settingSelector";
import SpaPosActionBar from "./SpaPosActionBar";
import PosPayment from "../PosPayment/index2";
import posStore from "../PosCart/store";
import PosCart from "../PosCart";
import Flow_Place from "./Flow_Place";
import mainAppStore from "../../store";

import swal from "sweetalert";
import cartStore from "../PosCart/store";
import { Provider } from "react-redux";
import PosCartSummary from "../PosCart/Summary";
import * as computeSelector from "../PosCart/selector";
import SpaPaymentModal from "../../shared/components/SpaPaymentModal";
import * as PosCartActions from "../PosCart/actions";
import combineJobItemsToBillItems from "../../shared/functions/combineJobItemsToBillItems";
import { spaPosTimeStampRef } from "../../shared/firebaseApi/firebaseRef";
import * as inventorySelector from "../../shared/selector/inventorySelector";
import documentClient from "../../shared/awsAPIs/dynamoDb";

function InPending(props) {
  const [page, setPage] = useState("place"); //main,cart
  const [modalVisible, setModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isNoStartTime, setIsNoStartTime] = useState(true);

  //view all,  member,  cart
  const [viewPanel, setViewPanel] = useState(() => {
    if (props.isBuyProduct) {
      return "member";
    } else {
      return "all";
    }
  });

  useEffect(() => {
    if (props.isBuyProduct) {
      // setTimeout(() => {
      //   setViewPanel("cart");
      // });
    }
  }, [props.isBuyProduct]);
  useEffect(() => {
    //Clear POS Data when Exit

    return () => {
      //clear cart store
      posStore.dispatch({
        type: "CART_CLEAR",
      });
      //clear spa pos store
      props.actions.clearSpaPosStepData();

      //set pos firebase timestamp
    };
  }, []);

  useEffect(() => {
    let params = props.match.params;
    if (params.jobUid) {
      setIsEdit(true);
    }
  }, []);

  const handleVoidJob = () => {
    const { jobUid, storeUid } = props.match.params;

    swal({
      title: `Void this job ?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        props.actions.voidJob(storeUid, jobUid, () => {
          swal("สำเร็จ", "Void job completed", "success").then(() => {
            props.history.push("/pos/0");
          });
        });
      }
    });
  };

  const getCartStoreData = () => {
    let cartState = cartStore.getState();
    let cs = computeSelector;
    let data = {
      items: cartState.items,
      qtyHolder: cartState.qtyHolder,
      promotionItems: cs.promotionItems(cartState) || [],
      grandTotal: cs.grandTotalSelector(cartState),
      subTotal: cs.subTotalAmountSelector(cartState),
      options: cartState.options,
      promotions: cs.promotionItemsAmountSelector(cartState),
      vat: cs.vatAmountSelector(cartState),
      discountHolder: cartState.discountHolder,
      rounding: cs.roundingAmountSelector(cartState),
      optionsAmount: cs.optionsAmountSelector(cartState),
      totalItemMinuteAmount: computeSelector.totalItemTimeSelector(cartState),
    };

    return data;
  };

  const saveToDatabase = (options) => {
    let optionalData = getCartStoreData();

    swal({
      title: `Save ?`,
      icon: "warning",
      buttons: true,
      // dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        props.actions.saveToDatabase(
          (data) => {
            // handlePrintJobOrder({ ...data, ...optionalData });
            props.history.push("/pos/0");
          },
          optionalData,
          options
        );
      }
    });
  };

  const handlePrintJobOrder = (cartStoreData) => {
    let data = {
      items: combineJobItemsToBillItems(
        cartStoreData.items,
        cartStoreData.promotionItems,
        cartStoreData.qtyHolder
      ),
    };

    props.actions.printJobOrder(data.items);
  };

  let productObj = {};

  _.map(cartStore.getState().items, (item) => {
    productObj[item.timestamp.toString()] = item;
  });

  return (
    <div style={{ backgroundColor: "#eeeeee" }}>
      <SpaPosActionBar
        {...props}
        setPage={setPage}
        page={page}
        saveToDatabase={saveToDatabase}
        viewPanel={viewPanel}
        setViewPanel={setViewPanel}
        isEdit={isEdit}
        productObj={productObj}
      />
      {modalVisible && [
        <SpaPaymentModal
          setModalVisible={setModalVisible}
          getCartStoreData={getCartStoreData}
          productObj={productObj}
        />,
        <div
          class="modal-backdrop show"
          onClick={() => setModalVisible(false)}
        />,
      ]}
      <div className="m-2">
        {page === "place" && (
          <div className="row">
            <div className="col-md-8 pt-0">
              {(viewPanel === "all" || viewPanel == "member") && (
                <Flow_Place
                  {...props}
                  setIsNoStartTime={setIsNoStartTime}
                  productObj={productObj}
                />
              )}
              <div className="card card-flat">
                {/* <div className="card-header">Items</div> */}
                <div className="card-body">
                  {(viewPanel === "all" || viewPanel == "cart") && (
                    <PosCart
                      itemPickerOnly={true}
                      mainAppState={props.spa}
                      mainAppActions={props.actions}
                      disableOnClickNext={true}
                      productObj={productObj}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <Summary
                {...props}
                save={saveToDatabase}
                setModalVisible={setModalVisible}
                getCartStoreData={getCartStoreData}
                isEdit={isEdit}
                handleVoidJob={handleVoidJob}
                isNoStartTime={isNoStartTime}
                productObj={productObj}

                // pay={saveToDatabase}
              />
            </div>
          </div>
        )}

        {/* {page === "payment" && <PosPayment spaSave={saveToDatabase} />} */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  // let productObj = {};

  // _.map(state.spa.pos.items, item => {
  //   productObj[item.timestamp.toString()] = item;
  // });

  return {
    spa: state.spa,
    spaPos: state.spa.pos,
    spaPosSetting: state.spa.posSetting,
    spaRoomOptions: roomsSelector.roomOptionsSelector(state),
    salesOptions: spaSalesSelector.salesOptionsSelector(state),
    agentOptions: spaSalesSelector.agentOptionsSelector(state),
    employeeOptions: spaSalesSelector.employeeOptionsSelector(state),
    employeeObj: spaSalesSelector.employeeObjSelector(state),
    channelOptions: settingSelector.channelOptionsSelector(state),
    itemsObj: inventorySelector.itemObjSelector(state),
    linked: state.shop.linked.inventory,
    shopDetail: state.shop.detail,
    // productObj,
    isBuyProduct: state.appState.room === null ? true : false,
    isRetial : state.appState.isRetail, 
    settingMotherPos:
      (state.setting.mother.pos && state.setting.mother.pos.value) || {},
  };
};
function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators(
    { ...spaPosActions, ...spaPosSettingActions },
    dispatch
  );
  let cartActions = bindActionCreators({ ...PosCartActions }, dispatch);

  return {
    actions,
    cartActions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InPending);

function Summary(props) {
  // useEffect(()=>{
  //   cartStore.dispatch({
  //     type : "CART_RESTORE",
  //     payload : {
  //       items : items,
  //       qtyHolder : qtyHolder
  //     }
  //   })
  // }, [items, qtyHolder] )

  return (
    <Provider store={cartStore}>
      <div>
        <div class="card card-border-color card-border-color-primary pt-5 pb-3">
          {/* <div class="card-header">asdsdas</div> */}
          <div class="card-body  pl-2 pr-2">
            <PosCartSummary {...props} />
          </div>
        </div>

        {props.isEdit && false && (
          <div>
            <h4
              class="text-right"
              onClick={props.handleVoidJob}
              style={{
                textDecoration: "underline",
                color: "gray",
                cursor: "pointer",
                display: "inline",
              }}
            >
              VOID
            </h4>
          </div>
        )}
        {props.isEdit && true && (
          <div class="card card-border-color card-border-color-danger pt-5 pb-3">
            {/* <div class="card-header pt-0 ml-0 pl-2" style={{ color: "red" }}>
              Danger Zone
            </div> */}
            <div class="card-body  pl-2 pr-2">
              <div className="row m-0 justify-content-between">
                <div>
                  <b>Void this job</b>
                  <br />
                  This job (all items) will be deleted
                </div>
                <div>
                  <button
                    class="btn btn-space btn-secondary"
                    onClick={props.handleVoidJob}
                  >
                    <span style={{ color: "red" }}>Void</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Provider>
  );
}
