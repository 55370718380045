import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import swal from "sweetalert";
import _ from "lodash";
import * as employeeActions from "../actions/employeeActions";
import { Auth } from "aws-amplify";
import IdleTimer from "react-idle-timer";
import { withRouter } from "react-router-dom";

class EmployeePinInputModal extends React.Component {
  state = {
    pinInput: "",
    isNiceloopTeam: false
  };
  signOut = () => {
    swal({
      title: `Sign Out ?`,
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        Auth.signOut();
        window.location.href = "/";
      }
    });
  };
  submit = () => {
    const { employeeList, actions } = this.props;
    const { pinInput } = this.state;

    this.setState({ pinInput: "" });

    if (pinInput == "0818460621" || pinInput == "niceloop") {
      actions.setLoggedInEmployee({
        role: "admin",
        pin: "SupportTeam",
        name: "SupportTeam",
        uid: "niceloop"
      });

      return;
    }

    let foundEmp = _.find(employeeList, i => i.pin === pinInput);
    if (foundEmp) {
      actions.setLoggedInEmployee(foundEmp);
    } else {
      swal("เกิดข้อผิดพลาด", "ไม่พบ PIN ที่คุณระบุ", "error");
    }
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.submit();
    }
  };

  getPromptTime = () => {
    const isLocalhost =
      _.includes(window.location.href, "localhost") ||
      _.includes(window.location.href, "192.");
    const { history } = this.props;
    let path = history.location.pathname;

    if (isLocalhost || _.includes(path, "spa_pos")) {
      return 300000000;
    }

    return 5000 * 60;
  };

  render() {
    const { visible, actions, employeeList } = this.props;

    return (
      <div>
        {employeeList.length > 0 && (
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            element={document}
            // onActive={this.onActive}
            onIdle={() => actions.setLoggedInEmployee(null)}
            // onAction={this.onAction}
            debounce={250}
            // timeout={1000 * 60 * 15}
            // timeout={isLocalhost ? 300000000 : 5000 * 60}
            timeout={this.getPromptTime()}
            // timeout={5000}
          />
        )}

        {visible && [
          <div
            class="modal fade show"
            id="employeePinInputModal"
            //   style={{ perspective: "none" }}
            style={{ display: "block" }}
          >
            <div class="modal-content">
              <div class="modal-header">
                <div>
                  <h3>
                    <span>Enter your PIN</span>
                  </h3>
                </div>
                <button class="close" type="button" onClick={this.signOut}>
                  <span class="mdi mdi-close" />
                </button>
              </div>
              <div class="modal-body">
                <div>
                  <div>
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="password"
                        value={this.state.pinInput}
                        onChange={e =>
                          this.setState({ pinInput: e.target.value })
                        }
                        onKeyPress={this.handleKeyPress}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer p-0">
                <button
                  class="btn btn-space btn-success m-0"
                  type="button"
                  style={{ width: "100%", borderRadius: 0, height: 50 }}
                  onClick={this.submit}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>,
          <div class="modal-backdrop fade show" />
        ]}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    employeeList: state.employee.list
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...employeeActions }, dispatch);

  return {
    actions
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployeePinInputModal)
);
