import React, { useState, useEffect } from "react";
import Pending from "./Pending";
import Booking from "./Booking";
import Booking2 from "./Booking2";
import Spa_Bill from "./Spa_Bill";
import Spa_Bill_Card from "./Spa_Bill_Card";
import BookingForm from "./BookingForm";
import BookingChart from "./BookingChart";
import BookingMain from "./BookingMain";
import ReceiptEvalute from "./ReceiptEvalute";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as spaPosActions from "../../shared/actions/spaPosActions";
import * as roomsSelector from "../../shared/selector/roomsSelector";
import * as inventorySelector from "../../shared/selector/inventorySelector";

import Opening from "./Opening";
import Closing from "./Closing";
// import DashboardSpaPos from "./DashboardSpaPos";
import DashboardSpaPos from "./Dashboard";

import useDrawerReducer from "./hook.drawer.reducer";
import InPending from "./InTable";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

function Index(props) {
  const [view, setView] = useState(10);
  const [initEditData, setInitEditData] = useState(null);
  const { state: drawerState, dispatch: drawerDispatch } = useDrawerReducer();
  const appState = useSelector(state => state.appState);

  const [isOpenedShop, serIsOpenedShop] = useState(false);
  // const isOpenedShop = drawerState.startDate;

  useEffect(() => {
    if (!_.isEmpty(props.match.params)) {
      setView(parseInt(props.match.params.pageNo));
    }
  }, []);

  useEffect(() => {
    let businessDate = appState.businessDate || null;
    serIsOpenedShop(businessDate);
  }, [appState]);

  return (
    <div className="main-content p-0">
      {/* <Navbar /> */}
      <div class="card">
        {/* <div class="card-header">Icon Text Tabs</div> */}
        <div class="tab-container">
          <ul class="nav nav-tabs" role="tablist">
            {!isOpenedShop && (
              <Tab
                onClick={() => {
                  setView(8);
                }}
                title="Opening"
                icon="icon mdi mdi-home"
                isActive={view === 8}
              />
            )}

            {isOpenedShop && (
              <Tab
                onClick={() => {
                  setView(10);
                }}
                title="Dashboard"
                icon="icon mdi mdi-home"
                isActive={view === 10}
              />
            )}
            {isOpenedShop && (
              <Tab
                onClick={() => {
                  setView(0);
                }}
                title="POS Beds"
                icon="icon mdi mdi-home"
                isActive={view === 0}
              />
            )}
            {isOpenedShop && (
              <Tab
                onClick={() => {
                  setView(2);
                }}
                title="Booking"
                icon="icon mdi mdi-grid"
                isActive={view === 2}
              />
            )}
            {/* <Tab
              onClick={() => {
                setView(2);
              }}
              title="บันทึกบิล/ booking2"
              icon="icon mdi mdi-receipt"
              isActive={false}
            /> */}
            {isOpenedShop && (
              <Tab
                onClick={() => {
                  setView(3);
                }}
                title="Open Bills"
                icon="icon mdi mdi-receipt"
                isActive={view === 3}
              />
            )}
            {isOpenedShop && (
              <Tab
                onClick={() => {
                  setView(4);
                }}
                title="Pending SatRate"
                icon="icon mdi mdi-receipt"
                isActive={view === 4}
              />
            )}

            {/* <Tab
              onClick={() => {
                setView(4);
              }}
              title="แบบฟอร์มการจอง"
              icon="icon mdi mdi-receipt"
              isActive={false}
            /> */}

            {isOpenedShop && (
              <Tab
                style={{ marginTop: -2, position: "absolute", right: 0 }}
                onClick={() => {
                  setView(30);
                }}
                title="Closing"
                icon="icon mdi mdi-home"
                isActive={view === 30}
              />
            )}
          </ul>
          <div class="tab-content m-0 p-0">
            <div class="tab-pane active" id="home5" role="tabpanel">
              {!isOpenedShop && view === 8 && (
                <Opening
                  {...props}
                  drawerState={drawerState}
                  drawerDispatch={drawerDispatch}
                  setView={setView}
                />
              )}
              {isOpenedShop && view === 10 && <DashboardSpaPos {...props} />}
              {isOpenedShop && view === 30 && (
                <Closing
                  {...props}
                  drawerState={drawerState}
                  drawerDispatch={drawerDispatch}
                  setView={setView}
                />
              )}

              {isOpenedShop && view === 0 && (
                <Pending drawerState={drawerState} {...props} />
              )}
              {/* {view === 1 && <Booking />} */}
              {/* {view === 2 && <Booking2 {...props} />}
              {isOpenedShop && view === 2 && (
                <BookingChart {...props} goToBookingForm={() => setView(90)} />
              )}  */}

              {isOpenedShop && view === 2 && (
                <BookingMain
                  {...props}
                  goToBookingForm={() => setView(90)}
                  setInitEditData={setInitEditData}
                />
              )}

              {/* {isOpenedShop && view === 3 && <Spa_Bill {...props} />} */}
              {isOpenedShop && view === 90 && (
                <BookingForm
                  {...props}
                  goToBookingChart={() => setView(2)}
                  initEditData={initEditData}
                  clearInitData={() => setInitEditData(null)}
                />
              )}
              {isOpenedShop && view === 4 && <ReceiptEvalute {...props} />}
              {isOpenedShop && view === 101 && <InPending />}
            </div>
          </div>
        </div>
      </div>
      {isOpenedShop && view === 3 && <Spa_Bill_Card {...props} />}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    spaPos: state.spa.pos,
    user: state.user.loggedIn,
    shop: state.shop.detail,
    spaRooms: state.spa.rooms,
    bedOptions: roomsSelector.bedOptionsSelector(state),
    menuItems: inventorySelector.groupItemSelector(state),
    inventory: inventorySelector.typeGroupItemSelector(state),
    businessDate : state.appState.businessDate
  };
};
function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...spaPosActions }, dispatch);
  return {
    actions,
    dispatch
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);

const Tab = ({ style = {}, title, icon, isActive, onClick }) => {
  return (
    <li className="nav-item" style={style}>
      <a
        class={`nav-link ${isActive ? "active" : ""}`}
        href="#home5"
        data-toggle="tab"
        role="tab"
        onClick={onClick}
      >
        <span class={icon} />
        {title}
      </a>
    </li>
  );
};
