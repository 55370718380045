import React, { Component } from "react";
import * as Amplify from "../../shared/awsAPIs/Amplify";
import swal from "sweetalert";
import _ from "lodash";
import province_th_eng from "../../shared/exampleData/thailandProvincesArray";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as shopActions from "../../shared/actions/shopActions";

const initData = {
  username: "", //for first time login user
  password: "", //for first time login user
  firstName: "", //for first time login user
  lastName: "", //for first time login user
  email: "abc@niceloop.com",
  rePassword: "",

  shopName: "",
  alias: "",
  branchId: "",
  shippingAddress: "",
  province: null,
  tags: [],
  onValidate: false,
  provinceOptions: [],

  vat: {
    mode: 0,
    value: "",
  },
};

class SignUp extends Component {
  state = initData;

  componentWillReceiveProps(nextProps) {
    const { isEdit } = nextProps;
    if (isEdit) {
      this.setInitEditData(nextProps);
    }
  }
  componentDidMount = () => {
    this.createProvinceOptions();
  };

  setInitEditData = (props) => {
    this.setState({ ...initData });
    const { selectedBranch } = props;

    let data = {
      branchId: selectedBranch.branchId,
      shopName: selectedBranch.name || selectedBranch.shopName || "",
      province: {
        label: selectedBranch.province,
        value: selectedBranch.province,
      },
      shippingAddress: selectedBranch.shippingAddress || "",
      alias: selectedBranch.alias || "",
    };
    let tags = [];
    if (selectedBranch.tags) {
      _.map(selectedBranch.tags, (bool, tagName) => {
        tags.push({ label: tagName, value: tagName, __isNew__: true });
      });
    }

    if (selectedBranch.vat) {
      data.vat = selectedBranch.vat;
      if (data.vat.value) {
        let isInclude = _.includes(data.vat.value.toString(), "-");
        if (isInclude) {
          data.vat.value = _.replace(data.vat.value, new RegExp("-", "g"), "");
        }
      }
    }

    data.tags = tags;

    this.setState({ ...data });
    this.createProvinceOptions();
  };
  handleClickSignUp = () => {
    const {
      username,
      password,
      rePassword,
      email,
      shopName,
      province,
      tags,
      branchId,
      shippingAddress,
      firstName,
      lastName,
      alias,
      vat,
    } = this.state;
    let { match, shopList } = this.props;
    let { params } = match;
    this.setState({ onValidate: true });

    if (
      branchId === "" ||
      shopName === "" ||
      username === "" ||
      password === "" ||
      rePassword === "" ||
      email === "" ||
      // shippingAddress === "" ||
      firstName === "" ||
      lastName === ""
    ) {
      swal("เกิดข้อผิดพลาด", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
      return;
    }
    if (password.length < 6) {
      swal(
        "เกิดข้อผิดพลาด",
        "กรุณากรอก Password อย่างน้อย 6 ตัวอักษร",
        "error"
      );
      return;
    }
    if (password !== rePassword) {
      swal(
        "เกิดข้อผิดพลาด",
        "กรุณากรอก Password ให้ตรงกันทั้งสองช่อง",
        "error"
      );
      return;
    }

    //CHECK BRANCH ID EXIST
    let filterExistBranchId = _.filter(
      shopList,
      (i) => i.branchId === branchId
    );

    if (filterExistBranchId.length > 0) {
      swal("เกิดข้อผิดพลาด", "รหัสสาขานี้ถูกใช้แล้ว", "error");
      return;
    }

    if (!province) {
      swal("เกิดข้อผิดพลาด", "กรุณาเลือกจังหวัด", "error");
      return;
    }

    let computedTags = {};

    if (tags === null || _.size(tags) < 1) {
      computedTags = null;
    } else {
      _.forEach(tags, (tag) => {
        computedTags[tag.label] = true;
      });
    }

    const computedVat = this.computeVatValue();

    Amplify.signUp(
      {
        shopName,
        username,
        password,
        firstName,
        lastName,
        email,
        province: province.value,
        tags: computedTags,
        link: params.shopId || null,
        shippingAddress: shippingAddress === "" ? "-" : shippingAddress,
        branchId,
        alias,
        vat: computedVat,
      },
      () => {
        swal("ยินดีด้วย!", "ลงทะเบียนสำเร็จ", "success").then(() => {
          if (_.isEmpty(params)) {
            window.location.href = "/logIn";
          } else {
            window.location.href = "/";
          }
        });
      }
    );
  };

  computeVatValue = () => {
    const { vat } = this.state;
    let checkedVat = _.cloneDeep(vat);

    if (parseInt(vat.mode) === 1) {
      checkedVat.value = -parseInt(checkedVat.value);
    } else {
      checkedVat.value = parseInt(checkedVat.value);
    }

    //PROTECT NaN
    if (vat.value === "" || vat.value === null) {
      checkedVat.value = null;
    }

    checkedVat.mode = parseInt(vat.mode);

    return checkedVat;
  };

  handleOnChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      onValidate: false,
    });
  };
  createProvinceOptions = () => {
    let provinceOptions = [];
    _.map(province_th_eng, (name) => {
      provinceOptions.push({ value: name, label: name });
    });

    this.setState({
      provinceOptions,
    });
  };
  handleChangeProvince = (province) => {
    this.setState({ province });
  };
  handleChangeTags = (newValue, actionMeta) => {
    this.setState({ tags: newValue });
  };
  editBranchDetail = () => {
    const {
      branchId,
      tags,
      province,
      shopName,
      shippingAddress,
      alias,
      vat,
    } = this.state;
    const { selectedBranch, actions, shopList, fetchShop } = this.props;

    if (branchId === "" || shopName === "" || !shopName) {
      swal("เกิดข้อผิดพลาด", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
      return;
    }

    //CHECK BRANCH ID EXIST
    let filterExistBranchId = _.filter(
      shopList,
      (i) => i.branchId === branchId
    );

    if (
      branchId !== selectedBranch.branchId &&
      filterExistBranchId.length > 0
    ) {
      swal("เกิดข้อผิดพลาด", "รหัสสาขานี้ถูกใช้แล้ว", "error");
      return;
    }

    if (!province) {
      swal("เกิดข้อผิดพลาด", "กรุณาเลือกจังหวัด", "error");
      return;
    }

    const computedVat = this.computeVatValue();

    let data = {
      name: shopName,
      branchId,
      province: province.value,
      shippingAddress: shippingAddress === "" ? "-" : shippingAddress,
      alias,
      vat: computedVat,
    };

    let computedTags = {};

    if (tags === null || _.size(tags) < 1) {
      computedTags = null;
    } else {
      _.forEach(tags, (tag) => {
        computedTags[tag.label] = true;
      });
    }

    data.tags = computedTags;

    actions.hqUpdateShopDetail(data, selectedBranch.uid, () => {
      fetchShop();
      window.$("#branchDetailModal").modal("hide");
      swal("ยินดีด้วย!", "บันทึกข้อมูลสำเร็จ", "success");
    });
  };
  handleSubmit = () => {
    const { isEdit } = this.props;

    if (isEdit) {
      this.editBranchDetail();
      return;
    }

    this.handleClickSignUp();
  };
  setVat = (key, value) => {
    //CAN'T TYPE MINUS
    if (key === "value" && _.includes(value, "-")) return;

    if (key === "value" && _.isNaN(parseInt(value)) && value !== "") return;

    let newVat = _.cloneDeep(this.state.vat);
    newVat[key] = value;
    this.setState({ vat: newVat });
  };

  render() {
    let {
      onValidate,
      username,
      password,
      rePassword,
      email,
      shopName,
      province,
      provinceOptions,
      tags,
      branchId,
      shippingAddress,
      firstName,
      lastName,
      alias,
      vat,
    } = this.state;
    let { match, isEdit } = this.props;
    let { params } = match;
    return [
      <div
        class={`card card-border-color card-border-color-${
          isEdit ? "warning" : "primary"
        }`}
      >
        <div className="card-header">
          {/* <img className="logo-img" src="assets/img/logo-xx.png" alt="logo" width="102" height="27"> */}
          <h2>{isEdit ? "จัดการข้อมูลร้านค้า" : "เปิดร้าน/สาขาใหม่"}</h2>
          {/* <span className="splash-description">Please enter your user information.</span></div> */}
        </div>
        <div className="card-body">
          <form>
            <div className="form-group">
              <label>รหัสสาขา</label>
              <input
                value={branchId}
                className="form-control parsley-error"
                onChange={this.handleOnChangeInput}
                name="branchId"
                type="text"
                required=""
                placeholder="Enter Branch ID"
                autoComplete="1o3g00eas2g"
                data-parsley-id="5"
                aria-describedby="parsley-id-1"
              />
              {onValidate === true && branchId === "" && <ValidateMessage />}
            </div>
            <FormGroup
              label="ชื่อร้าน/สาขา"
              input={
                <input
                  value={shopName}
                  className="form-control parsley-error"
                  onChange={this.handleOnChangeInput}
                  name="shopName"
                  type="text"
                  required=""
                  placeholder="Enter shop name"
                  autoComplete="1o3g00eas2g"
                  data-parsley-id="5"
                  aria-describedby="parsley-id-1"
                />
              }
            >
              {onValidate === true && shopName === "" && <ValidateMessage />}
            </FormGroup>

            <FormGroup
              label="Alias (จำกัด 5 ตัวอักษร)"
              input={
                <input
                  value={alias}
                  className="form-control parsley-error"
                  onChange={(e) => {
                    if (e.target.value.length === 6) return;
                    this.setState({ alias: e.target.value });
                  }}
                  name="alias"
                  type="text"
                  placeholder="Enter shop alias"
                  autoComplete="1o3g00eas2g"
                  data-parsley-id="5"
                  aria-describedby="parsley-id-1"
                />
              }
            />

            {/* shippingAddress */}
            <FormGroup
              label="ที่อยู่ในการจัดส่ง"
              input={
                <textarea
                  className="form-control"
                  value={shippingAddress}
                  onChange={this.handleOnChangeInput}
                  name="shippingAddress"
                  placeholder="กรอกที่อยู่ในการจัดส่ง"
                />
              }
            />

            {/* VAT */}
            <div className="row m-0">
              <div className="col-6 pl-0">
                <FormGroup
                  label="Vat Mode"
                  input={
                    <select
                      className="form-control"
                      onChange={(e) => this.setVat("mode", e.target.value)}
                      value={vat.mode}
                    >
                      <option value={0}>None</option>
                      <option value={1}>Inclusive</option>
                      <option value={2}>Exclusive</option>
                    </select>
                  }
                />
              </div>
              <div className="col-6 pr-0">
                <FormGroup
                  label={
                    <span>Vat Value {getVatSymbol(parseInt(vat.mode))}</span>
                  }
                  input={
                    <input
                      disabled={parseInt(vat.mode) === 0}
                      onChange={(e) => this.setVat("value", e.target.value)}
                      value={vat.value}
                      className="form-control"
                      placeholder="value"
                    />
                  }
                />
              </div>
            </div>

            {!isEdit && [
              <div className="form-group">
                <label>ชื่อจริง - นามสกุล</label>
                <div className="row m-0">
                  <div className="col-md-6 p-0">
                    <input
                      value={firstName}
                      className="form-control parsley-error"
                      onChange={this.handleOnChangeInput}
                      name="firstName"
                      type="text"
                      required=""
                      placeholder="First Name"
                      autoComplete="1o3g00eas2g"
                      data-parsley-id="5"
                    />
                  </div>
                  <div className="col-md-6 p-0">
                    <input
                      value={lastName}
                      className="form-control parsley-error"
                      onChange={this.handleOnChangeInput}
                      name="lastName"
                      type="text"
                      required=""
                      placeholder="Last Name"
                      autoComplete="1o3g00eas2g"
                      data-parsley-id="5"
                    />
                  </div>
                </div>
                {onValidate === true && firstName === "" && <ValidateMessage />}
              </div>,

              <FormGroup
                label="Username (สำหรับ Log in ครั้งแรก)"
                input={
                  <input
                    value={username}
                    className="form-control parsley-error"
                    onChange={this.handleOnChangeInput}
                    name="username"
                    type="text"
                    required=""
                    placeholder="Enter username"
                    autoComplete="1o3g00eas2g"
                    data-parsley-id="5"
                  />
                }
              >
                {onValidate === true && username === "" && <ValidateMessage />}
              </FormGroup>,
              <div className="form-group">
                <label>Password</label>
                <input
                  value={password}
                  className="form-control parsley-error"
                  onChange={this.handleOnChangeInput}
                  type="password"
                  name="password"
                  required=""
                  placeholder="Enter password"
                  autoComplete="1o3g00eas2g"
                  data-parsley-id="5"
                  aria-describedby="parsley-id-6"
                />
                {onValidate === true && password === "" && <ValidateMessage />}
              </div>,
              <div className="form-group">
                <label>Re-password</label>
                <input
                  value={rePassword}
                  className="form-control parsley-error"
                  onChange={this.handleOnChangeInput}
                  type="password"
                  name="rePassword"
                  required=""
                  placeholder="Re-enter password"
                  autoComplete="1o3g00eas2g"
                  data-parsley-id="5"
                  aria-describedby="parsley-id-7"
                />
                {onValidate === true && rePassword === "" && (
                  <ValidateMessage />
                )}
              </div>,
            ]}
            <FormGroup
              label="จังหวัด"
              input={
                <Select
                  value={province}
                  onChange={this.handleChangeProvince}
                  options={provinceOptions}
                />
              }
            >
              {onValidate === true && province === null && <ValidateMessage />}
            </FormGroup>

            <FormGroup
              label="Tags (optional)"
              input={
                <CreatableSelect
                  value={tags}
                  isMulti
                  onChange={this.handleChangeTags}
                  options={tags}
                />
              }
            />

            <div className="form-group login-submit">
              <a
                className={`btn btn-${isEdit ? "warning" : "primary"} btn-xl`}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleSubmit();
                }}
                data-dismiss="modal"
              >
                {isEdit ? "SAVE" : "SIGN UP"}
              </a>
            </div>
          </form>
        </div>
      </div>,
      !isEdit &&
        (_.isEmpty(params) ? (
          <div className="splash-footer">
            <span>
              Go <a href="/logIn">Back</a> to log in
            </span>
          </div>
        ) : (
          <div className="splash-footer">
            <span>
              Go <a href="/">Back</a> to homepage
            </span>
          </div>
        )),
    ];
  }
}

const mapStateToProps = (state) => {
  return {
    shopList: state.shop.list.inventory,
  };
};
function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...shopActions }, dispatch);

  return {
    actions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

const FormGroup = ({ label, input, children }) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      {input}
      {children}
    </div>
  );
};

const ValidateMessage = () => {
  return (
    <ul className="parsley-errors-list filled" id="parsley-id-1">
      <li className="parsley-required">This value is required.</li>
    </ul>
  );
};

const getVatSymbol = (mode) => {
  let textColor = "grey";
  let text = "none";

  if (mode === 1) {
    textColor = "red";
    text = "-";
  } else if (mode === 2) {
    textColor = "blue";
    text = "+";
  }

  return <span style={{ color: textColor }}>( {text} )</span>;
};
