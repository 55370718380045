import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as cartActions from '../../shared/actions/cartActions'
import { bindActionCreators } from 'redux'

class ProductValidate extends React.Component {
    render() {
        const { failList, actions } = this.props
        if (failList.length < 1) {
            return null
        }

        return (
            <div className=" mt-5 alert alert-danger alert-dismissible" role="alert" style={{ maxHeight: 200, minHeight: 50, overflow: 'scroll' }}>
                <button className="close" type="button"
                    // data-dismiss="alert"
                    onClick={() => actions.setCartFailList([])}
                    aria-label="Close" style={{ color: 'white' }}><span className="mdi mdi-close" aria-hidden="true" ></span></button>
                <div style={{ margin: 15 }}>
                    {
                        _.map(failList, line => {
                            return (
                                <div className="row m-0">
                                    <div className="col-md-3">
                                        {line.name}
                                    </div>
                                    <div className="col-md-3">สั่ง ({line.qty})</div>
                                    <div className="col-md-4">เหลือในสต๊อค ({line.balance})</div>
                                </div>
                            )
                            // return <div>{line.name}: จำนวนที่สั่ง ({line.qty}) จำนวนที่เหลือในสต๊อค ({line.balance})</div>
                        })
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        failList: state.cart.cartValidate.failList
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ ...cartActions }, dispatch)
    return {
        actions,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductValidate)