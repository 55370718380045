import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import documentClient from "../../awsAPIs/dynamoDb";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import _ from "lodash";

const TopUpForm = (props) => {
  const [inputAmount, setInputAmount] = useState("");
  const [inputRefId, setInputRefId] = useState("");
  const [inputRemark, setInputRemark] = useState("");
  const [topUpMode, setTopUpMode] = useState("wallet");
  const [expireDate, setExpireDate] = useState(null); //new Date()

  const { customer } = props;

  const setInitData = () => {
    if (customer.expireDate) {
      setExpireDate(new Date(customer.expireDate));
    }
  };
  useEffect(() => {
    setInitData();
  }, [customer]);

  const clerData = () => {
    setInputAmount("");
    setInputRefId("");
    setInputRemark("");
    setExpireDate(null);
  };

  const handleTopUp = () => {
    if (inputAmount === "") {
      swal("ข้อมูลไม่ครบ", "กรุณากรอกจำนวนตัวเลขที่ต้องการ Top Up");
      return;
    }

    //PUSH TX
    props.actions.pushMemberTx(
      customer.uid,
      topUpMode,
      {
        amount: parseInt(inputAmount),
        refId: inputRefId,
        remark: inputRemark,
        type: "TOPUP",
      },
      () => {
        //UPDATE Crm_Users
        swal("Success !", "Top Up completed !", "success");
        setTimeout(() => {
          props.fetchCustomers();
          clerData();
          Update_Users_Expire();
        }, 400);
      }
    );
  };

  const Update_Users_Expire = () => {
    if (!expireDate) return;

    let params = {
      TableName: "Crm_Users",
      Key: { uid: customer.uid },
      UpdateExpression: "SET expireDate =:expireDate",
      ExpressionAttributeValues: {
        ":expireDate": moment(expireDate).toISOString(),
      },
    };

    documentClient.update(params, (err, data) => {
      if (err) {
        console.log(err);
        return;
      }
    });
  };

  return (
    <div className="mb-3 mt-2">
      {/* <div class="form-group row ml-0 pb-0">
        <div>
          <RadioBox
            checked={topUpMode === "wallet"}
            label={"Wallet"}
            onClick={e => setTopUpMode("wallet")}
          />
          <RadioBox
            checked={topUpMode === "point"}
            label={"Point"}
            onClick={() => setTopUpMode("point")}
          />
        </div>
      </div> */}
      <div class="form-group">
        <label>Amount</label>
        <input
          class="form-control"
          type="number"
          value={inputAmount}
          onChange={(e) => {
            setInputAmount(e.target.value);
          }}
        />
      </div>
      <div class="form-group pt-1">
        <label>Ref Id</label>
        <input
          class="form-control"
          type="text"
          value={inputRefId}
          onChange={(e) => {
            setInputRefId(e.target.value);
          }}
        />
      </div>
      <div class="form-group pt-1">
        <label>Remark</label>
        <input
          class="form-control"
          type="text"
          value={inputRemark}
          onChange={(e) => {
            setInputRemark(e.target.value);
          }}
        />
      </div>
      <div class="form-group pt-1">
        <label>Expire Date</label>
        <br />
        <DatePicker
          selected={expireDate}
          onChange={(date) => setExpireDate(date)}
          dateFormat="dd/MM/yyyy"
          className="form-control"
        />
      </div>

      <button
        class="btn btn-space btn-success mt-2"
        style={{ width: "100%", height: 40 }}
        onClick={handleTopUp}
        disabled={props.getTopupPermission(topUpMode) !== true}
      >
        Top Up
      </button>
      {props.getTopupPermission(topUpMode) !== true && (
        <span style={{ color: "red" }}>Permission denied</span>
      )}
    </div>
  );
};

export default TopUpForm;

const RadioBox = ({ checked, label, onClick }) => {
  return (
    <label class="custom-control custom-radio custom-control-inline">
      <input
        class="custom-control-input"
        type="radio"
        checked={checked}
        onClick={onClick}
      />
      <span class="custom-control-label">{label}</span>
    </label>
  );
};
