import React, { useState, useEffect } from "react";
import _ from "lodash";
import swal from "sweetalert";
// import moment from "moment";

function SettingPos(props) {
  const [requireRegisteredMember, setRequireRegisteredMember] = useState(null);
  const [mode, setMode] = useState("current");

  function saveData() {
    let data = {
      requireRegisteredMember
    };
    props.actions.updateSetting(props.keyNode, data, () => {
      swal("สำเร็จ", "บันทึกข้อมูลสำเร็จ", "success");
      props.actions.fetchSetting();
      //   options.callBack && options.callBack();
    });
  }

  function setDataList(forceMode) {
    let tmpMode = forceMode || mode;

    try {
      let data = props.setting[tmpMode][props.keyNode].value || {};
      data.requireRegisteredMember &&
        setRequireRegisteredMember(data.requireRegisteredMember);
      setMode(tmpMode);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setDataList();
  }, [props.setting]);

  return (
    <div className="row m-0">
      <div className="col-md-8 offset-md-2">
        <Card>
          <RowBetween
            col1={
              <div>
                <div>
                  <Title text="Require registered member only." />
                </div>
                <div>เลือกได้เฉพาะสมาชิกที่ได้ทำการลงทะเบียนแล้วเท่านั้น</div>
              </div>
            }
            col2={
              <CheckBox
                value={requireRegisteredMember}
                onClick={() =>
                  setRequireRegisteredMember(!requireRegisteredMember)
                }
              />
            }
          />
        </Card>
        <button
          class="btn btn-space btn-success"
          style={{ width: 100 }}
          onClick={saveData}
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default SettingPos;

const Card = ({ children }) => {
  return (
    <div className="card mb-2" style={{ backgroundColor: "#f0f0f5" }}>
      <div className="p-4">{children}</div>
    </div>
  );
};

const RowBetween = ({ col1, col2 }) => {
  return (
    <div className="row m-0 justify-content-between">
      <div>{col1}</div>
      <div className="align-self-center">{col2}</div>
    </div>
  );
};

const Title = ({ text }) => {
  return <span style={{ fontSize: 18, fontWeight: "bold" }}>{text}</span>;
};

const CheckBox = ({ value, onClick }) => {
  return (
    <div
      className="custom-control custom-checkbox m-0 p-0"
      onClick={() => {
        onClick && onClick();
      }}
    >
      <input className="custom-control-input" type="checkbox" checked={value} />
      <span className="custom-control-label custom-control-color">
        {/* Option 1 */}
      </span>
    </div>
  );
};
