import moment from "moment";
import Paper from "niceloop-paper";
import _ from "lodash";

class PrintReportProduct {
  payload = null;
  dispatch = null;
  printCmdResult = null;
  printType = "PRINT_CUSTOM_V2";
  optionsData = {};
  isCloseShop = false;

  constructor(dispatch, shopName, optionsData) {
    this.dispatch = dispatch;
    this.shopName = shopName;
    this.optionsData = optionsData;
    this.isCloseShop = optionsData && optionsData.isCloseShop;
    // this.build();
    // this.print();
  }

  build = () => {
    //obj is table instance
    var Order = {};

    Order.UniqueId = this.getRandomInt(99999999999);
    Order.Printer = [0];
    Order.Title1 = this.shopName;
    Order.Title2 = "สรุปสินค้าขาย";

    Order.CustomHeadersArrays = [
      this._genDateRange(),
      this.genTypeSummary(),
      this.genGroupItemSummary(),
      this._genTimestamp()
    ];

    Order.CustomFootersArrays = [];

    this.Order = Order;

    return this;
  };

  _genDateRange() {
    var paper = new Paper();

    if (this.isCloseShop) {
      paper.comment("*****   Close Shop   *****");
      paper.lineFeed();
    }

    paper.lineFeed();
    paper.textLine(
      "วันที่ :   " + moment(this.optionsData.startDate).format("DD MMM YYYY")
    );
    paper.textLine(
      "ถึงวันที่ :   " + moment(this.optionsData.endDate).format("DD MMM YYYY")
    );
    paper.comment("printed at :   " + moment().format("DD MMM YYYY HH:mm"));
    paper.lineFeed();

    return paper.gen();
  }

  _genTimestamp() {
    var paper = new Paper();
    paper.comment("Printed at :   " + moment().format("HH:mm DD MMM YYYY"));
    paper.lineFeed();

    if (this.isCloseShop) {
      paper.lineFeed();
      paper.comment("*****   Close Shop   *****");
    }
    return paper.gen();
  }

  genTypeSummary = () => {
    var paper = new Paper();

    paper.title("Products by type");
    paper.lineFeed();

    _.forEach(this.optionsData.products, type => {
      paper.keyValue(type.name, getMoney(type.computedPrice));
    });

    paper.lineFeed();
    paper.drawLine();
    paper.lineFeed();

    return paper.gen();
  };

  genGroupItemSummary = () => {
    var paper = new Paper();

    paper.title("Detail");
    paper.lineFeed();

    _.forEach(this.optionsData.products, type => {
      // paper.textLine(`***** Type: ${type.name} *****`);
      _.forEach(type.groups, group => {
        paper.textLine(
          `*** ${group.name} : ${getMoney(group.computedPrice)} [${type.name}]`
        );
        _.forEach(group.items, item => {
          // paper.keyValue(`${item.name}`, getMoney(item.computedPrice));
          if (item.name.length > 30) {
            let chunkName = chunkString(item.name, 30);

            _.forEach(chunkName, (text, idx) => {
              if (idx === 0) {
                paper.keyValue(`${item.qty}  ${text}`, getMoney(item.computedPrice));
              } else {
                paper.keyValue(`   ${text}`);
              }
            });
          } else {
            paper.keyValue(`${item.qty}  ${item.name}`, getMoney(item.computedPrice));
          }
        });
        paper.lineFeed();
      });
      paper.lineFeed();
    });

    paper.lineFeed();
    paper.drawLine();
    paper.lineFeed();

    return paper.gen();
  };

  print() {
    this.dispatch({
      type: "PRINT",
      meta: {
        printType: this.printType,
        type: "PRINT"
      },
      payload: this.Order
    });
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
}

export default PrintReportProduct;

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

function getMoney(number) {
  return `฿ ${(number || 0).toLocaleString()}`;
}

const chunkString = (str, len) => {
  var _size = Math.ceil(str.length / len),
    _ret = new Array(_size),
    _offset;
  for (var _i = 0; _i < _size; _i++) {
    _offset = _i * len;
    _ret[_i] = str.substring(_offset, _offset + len);
  }
  return _ret;
};
