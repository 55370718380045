import React, { useState } from "react";
import Select from "react-select";
import randomUid from "../../shared/randomUid";
import swal from "sweetalert";
import moment from "moment";
import documentClient from "../awsAPIs/dynamoDb";

const MODAL_NAME = "memberAddVoucherModal";

function MemberAddVoucherModal(props) {
  const [voucher, setVoucher] = useState(null);
  const { member, voucherOptions } = props;

  const addMemberVoucher = async () => {
    if (!voucher) return swal("");

    let expireDate = moment();
    expireDate.add(voucher.age, "day");
    let expireDateIso = expireDate.toISOString(); //EXPIRE DATE
    let voucherPurchaseGroup = randomUid(6); //PURCHASE GROUP
    let createDate = moment().toISOString(); //CREATE DATE

    for (let i = 1; i <= voucher.qty; i++) {
      var params = {
        TableName: "Spa_Member_Voucher",
        Item: {
          hqUid: voucher.storeUid,
          memberUid: member.uid,
          voucherCampaignUid: voucher.voucherCampaignUid,
          voucherPurchaseGroup,
          voucherUid: randomUid(6),
          name: voucher.name,
          qty: voucher.qty,
          age: voucher.age,
          status: "active",
          expireDate: expireDateIso,
          createDate
          //   used: {
          //       storeUid:'001',
          //       timestamp:'fekofeofkoe'
          //   }
        }
      };

      try {
        await documentClient.put(params).promise();
      } catch (error) {
        console.log(error);
      }
    }
    setVoucher(null);
    props.fetchVoucherList();
    swal("เพิ่ม Voucher สำเร็จ", "", "success");
  };

  return (
    <div
      class="modal"
      id={MODAL_NAME}
      tabindex="-1"
      role="dialog"
      aria-labelledby={`${MODAL_NAME}Label`}
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style={{ overflow: "unset" }}>
          <div class="modal-header">
            <h4 class="modal-title" id={`${MODAL_NAME}Label`}>
              <b>Add Voucher to {member.name}</b>
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <Select
              value={voucher}
              onChange={setVoucher}
              options={voucherOptions}
            />
          </div>
          <div class="modal-footer pt-2">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              style={{ width: 100 }}
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-success pl-2"
              style={{ width: 100 }}
              onClick={addMemberVoucher}
            >
              Add
            </button>
            {/* <button type="button" class="btn btn-primary">
              Save changes
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberAddVoucherModal;
