import React from "react";
import Select from "react-select";
import IntlTelInput from "react-intl-tel-input";
import _ from "lodash";
import DatePicker from "react-datepicker";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

const animatedComponents = makeAnimated();

function ManageCustomerDesktopForm(props) {
  //   const [count, setCount] = useState(0);
  const {
    country,
    setCountry,
    countryOptions,
    name,
    setName,
    gender,
    setGender,
    RadioBox,
    mobile,
    handleSelectMobileCountry,
    handleInputMobile,
    preferredCountries,
    date,
    setDate,
    year,
    setYear,
    email,
    setEmail,
    mode,
    editCustomer,
    closeModal,
    fetchCustomers,
    month,
    setMonth,
    startYear,
    createCustomer,
    setExpireDate,
    expireDate,
    friendList,
    setFriendlist,
    tags,
    setTags,
    memberId,
    setMemberId,
    businessUnit,
    setBusinessUnit,
  } = props;

  return (
    <div className="row m-0">
      <div className="col-10 offset-1">
        <div style={{ margin: 15 }}>
          {props.initData.createAt && (
            <FormRow
              label="Create At"
              input={
                <span>
                  <b>
                    {moment(props.initData.createAt).format("DD/MM/YYYY HH:mm")}
                  </b>
                </span>
              }
            />
          )}
          <FormRow
            label="Member ID"
            input={
              <input
                type="text"
                className="form-control"
                value={memberId}
                onChange={(e) => setMemberId(e.target.value.toUpperCase())}
                autoComplete="1o3g00eas2g"
              />
            }
          />
          <FormRow
            label="Country"
            input={
              <Select
                // className="form-control"
                value={country}
                onChange={setCountry}
                options={countryOptions}
              />
            }
          />

          <FormRow
            label="Full Name"
            input={
              <input
                type="text"
                className="form-control"
                placeholder="Enter full name"
                value={name}
                onChange={(e) => setName(e.target.value.toUpperCase())}
                autoComplete="1o3g00eas2g"
              />
            }
          />
          <FormRow
            label="Gender"
            input={[
              <RadioBox
                onClick={() => setGender("Male")}
                checked={gender === "Male"}
                label="Male"
              />,
              <RadioBox
                onClick={() => setGender("Female")}
                checked={gender === "Female"}
                label="Female"
              />,
            ]}
          />
          <FormRow
            label="Business Unit"
            input={[
              <RadioBox
                onClick={() => setBusinessUnit("spa")}
                checked={businessUnit === "spa"}
                label="Spa"
              />,
              <RadioBox
                onClick={() => setBusinessUnit("retail")}
                checked={businessUnit === "retail"}
                label="Retail"
              />,
            ]}
          />

          <FormRow
            label="Mobile Number"
            input={[
              <IntlTelInput
                containerClassName="intl-tel-input"
                inputClassName="form-control"
                defaultCountry={mobile.countryData.iso2}
                // key={initKey}
                css={["intl-tel-input", "form-control"]}
                style={{ width: "100%" }}
                //   style={{ width: "auto" }}
                fieldId="mobile"
                onSelectFlag={handleSelectMobileCountry}
                // disabled={readOnly}
                value={mobile.inputNumber}
                onPhoneNumberChange={handleInputMobile}
                preferredCountries={preferredCountries}
                inputProps
              />,
              <br />,
              <div className="mt-1">
                {mobile.inputNumber !== "" &&
                  (mobile.status ? (
                    <span style={{ color: "green" }}>Format Valid</span>
                  ) : (
                    <span style={{ color: "red" }}>Format Invalid</span>
                  ))}
              </div>,
            ]}
          />

          <FormRow
            label="Birth Date"
            input={
              <div className="form-group row">
                <div className="col-4 form-group-sub">
                  <label className="form-control-label">Date</label>
                  <select
                    className="form-control"
                    value={date}
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    {_.times(31, (i) => {
                      let tmpDate = i + 1;
                      if (tmpDate < 10) {
                        tmpDate = "0" + tmpDate;
                      }
                      return <option value={tmpDate}>{tmpDate}</option>;
                    })}
                  </select>
                </div>
                <div className="col-4 form-group-sub">
                  <label className="form-control-label">Month</label>
                  <select
                    className="form-control"
                    value={month}
                    onChange={(e) => {
                      setMonth(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    {_.times(12, (i) => {
                      let tmpMonth = i + 1;
                      if (tmpMonth < 10) {
                        tmpMonth = "0" + tmpMonth;
                      }
                      return <option value={tmpMonth}>{tmpMonth}</option>;
                    })}
                  </select>
                </div>
                <div className="col-4 form-group-sub">
                  <label className="form-control-label">Year</label>
                  <select
                    className="form-control"
                    value={year}
                    onChange={(e) => {
                      setYear(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    {_.times(100, (i) => {
                      const tmpYear = startYear - i;
                      return <option value={tmpYear}>{tmpYear}</option>;
                    })}
                  </select>
                </div>
              </div>
            }
          />

          <FormRow
            label="Email"
            input={
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="1o3g00eas2g"
              />
            }
          />

          <FormRow
            label="Tags"
            input={
              // <CreatableSelect
              //   value={tags}
              //   isMulti
              //   onChange={setTags}
              //   options={props.tagsOptions}
              // />
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                // defaultValue={tags}
                onChange={setTags}
                isMulti
                value={tags}
                options={props.tagsOptions}
              />
            }
          />

          <FormRow
            label="Friends"
            input={
              <div>
                <CreatableSelect
                  value={friendList}
                  isMulti
                  onChange={(data) => {
                    let arr = [];
                    _.forEach(data, (friend) => {
                      arr.push({ label: friend.label, value: friend.value });
                    });
                    setFriendlist(arr);
                  }}
                  options={friendList}
                />
                <div className="mt-2" />
                <span>* พิมพ์แล้วกดปุ่ม Enter</span>
              </div>
            }
          />

          <FormRow
            label="Expire Date"
            input={
              <DatePicker
                selected={expireDate}
                onChange={(date) => setExpireDate(date)}
                dateFormat="dd/MM/yyyy"
                className="form-control"
              />
            }
          />

          <FormRow
            input={
              <div>
                {mode === "create" ? (
                  <button
                    type="button"
                    class="btn btn-success"
                    style={{ width: "100%" }}
                    onClick={createCustomer}
                  >
                    CREATE
                  </button>
                ) : (
                  <button
                    type="button"
                    class="btn btn-warning"
                    style={{ width: "100%" }}
                    onClick={editCustomer}
                  >
                    UPDATE
                  </button>
                )}
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ManageCustomerDesktopForm;

const FormRow = ({ label, input }) => {
  return (
    <div class="form-group row ">
      <label class="col-3 col-form-label text-right align-self-center pt-0">
        {label}
      </label>
      <div class="col-9">{input}</div>
    </div>
  );
};
