import React, { useState, useEffect } from "react";
import _ from "lodash";
import swal from "sweetalert";
import async from "async";
import documentClient from "../../shared/awsAPIs/dynamoDb";

function EmployeeGroupModal(props) {
  const [selectedGroup, selectGroup] = useState("");

  useEffect(() => {
    selectGroup("");
  }, [props]);

  const createAndMoveToNewGroup = () => {
    window.$("#employeeGroupModal").modal("hide");

    swal({
      title: "Move to new group",
      //   text: "กรอกเหตุผล",
      //   icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Group name"
        }
      },
      buttons: ["Cancel", "Move to"]
    }).then(name => {
      if (!name) throw null;
      moveToGroup(name);
    });
  };

  const moveToGroup = group => {
    let itemUidArr = [];
    _.map(props.multiEmpHolder, (data, uid) => {
      itemUidArr.push(uid);
    });
    async.each(
      itemUidArr,
      async (uid, cb) => {
        var params = {
          TableName: props.table,
          Key: { entityUid: props.shop.uid, uid },
          UpdateExpression: "set #group = :group",
          ExpressionAttributeNames: { "#group": "group" },
          ExpressionAttributeValues: {
            ":group": group
          }
        };
        try {
          await documentClient.update(params).promise();
          cb();
        } catch (error) {
          //   console.log(error);
          cb(error);
        }
      },
      err => {
        if (err) {
          return console.log(err);
        }
        props.fetch && props.fetch();
        swal("Completed", "ย้ายกลุ่มสำเร็จ", "success");
        window.$("#employeeGroupModal").modal("hide");
      }
    );
  };

  return (
    <div
      className="modal"
      id="employeeGroupModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="employeeGroupModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content" style={{ width: 400 }}>
          <div className="modal-header">
            <h4 className="modal-title" id="employeeGroupModalLabel">
              Move to group
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <select
              className="form-control"
              onChange={e => selectGroup(e.target.value)}
            >
              <option value={""}>เลือกกลุ่ม</option>
              {_.map(props.groupObj, (data, key) => { 
                return (
                  <option value={key} selected={selectedGroup === key}>
                    {key}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="modal-footer pt-2">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={selectedGroup === ""}
              onClick={() => moveToGroup(selectedGroup)}
            >
              Move
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={createAndMoveToNewGroup}
            >
              Move to new Group
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeGroupModal;
