import React, { useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import * as actions from "./actions";
function ShoppingCartLineActions(props) {
  const dispatch = useDispatch();
  function clickDiscountPercent() {
    let v = window.prompt("Enter Percent");
    let isInt = _.isInteger(parseInt(v));
    if (isInt) {
      dispatch(actions.discountLine(0, v));
    }
  }

  function clickDiscountAmount() {
    let v = window.prompt("Enter Amount");
    if (_.isInteger(parseInt(v))) {
      dispatch(actions.discountLine(1, v));
    }
  }
  return (
    <div className="row">
      <div class="col-12">
        <button
          onClick={clickDiscountPercent}
          class="btn btn-secondary btn-space"
        >
          <i class="icon icon-left mdi mdi-globe" />
          Discount Percent
        </button>
        <button
          onClick={clickDiscountAmount}
          class="btn btn-secondary btn-space"
        >
          <i class="icon icon-left mdi mdi-globe" />
          Discount Amount
        </button>
      </div>
    </div>
  );
}

export default ShoppingCartLineActions;
