import _ from "lodash";

export const genRooms = () => {
  let init = [];
  _.times(3, i => {
    var major = {
      name: "zone" + (i + 1),
      label: "zone" + (i + 1),
      list: [],
      value: i
    };
    _.times(5, j => {
      major.list.push({
        name: "room #" + (i + 1) + "00" + (j + 1),
        label: "room #" + (i + 1) + "00" + (j + 1),
        roomUid: i + 1 + "00" + (j + 1),
        value: j
      });
    });
    init.push(major);
  });

  return init;
};

export const genRoomsOneLevel = () => {
  let init = [];
  _.times(3, i => {
    let zoneName = "zone" + (i + 1);
    // var major = {
    //   name: zoneName,
    //   label: zoneName,
    //   list: [],
    //   value: i
    // };
    // init.push(major);

    _.times(5, j => {
      let roomName = zoneName + " | room#" + (i + 1) + "00" + (j + 1);
      init.push({
        name: roomName,
        label: roomName,
        roomUid: i + 1 + "00" + (j + 1),
        value: j
      });
    });
    // init.push(major);
  });

  return init;
};
