import * as type from "../types/appState";
import recepitApi from "../api/receipt_id";
export const loading = (isLoading) => {
  return {
    type: type.APP_STATE_LOADING,
    payload: isLoading,
    isRetail: false,
  };
};

export const init = () => {
  return (dispatch, getState) => {
    fetchLastReceiptId(dispatch, getState);
  };
};

function fetchLastReceiptId(dispatch, getState) {
  let state = getState();
  let storeUid = state.shop.detail.uid;
  recepitApi.init(storeUid);
}
