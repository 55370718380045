import React, { useState, useEffect } from "react";
import * as aws from "../../shared/awsAPIs/instance";
import _ from "lodash";

function useFetchReceipts(initShopId, initOptions) {
  const [receipts, setReceipts] = useState([]);
  const [voidReceipts, setVoidReceipts] = useState([]);
  const [entertainReceipts, setEntertainReceipts] = useState([]);

  async function fetchReceipts(shopId, options = {}) {
    if (!shopId) return;
    var params = {
      TableName: "Spa_Receipts", 
      KeyConditionExpression: "storeUid = :hkey",
      ExpressionAttributeValues: {
        ":hkey": shopId
      }
    };

    //ทุกสาขา
    if (options.hqUid) {
      params = {
        TableName: "Spa_Receipts",
        IndexName: "hqUid-timestamp-index",
        KeyConditionExpression: "hqUid = :hqUid",
        ExpressionAttributeValues: {
          ":hqUid": options.hqUid
        }
      };
    }
    // if (options.timestamp) {
    //   // fetch รายเดือน
    //   // params["FilterExpression"] = "contains(#timestamp, :timestamp)";
    //   // params["ExpressionAttributeValues"][":timestamp"] = options.timestamp;
    //   // params["ExpressionAttributeNames"] = { "#timestamp": "timestamp" };
    // }
    let startDate = "";
    let endDate = "";

    if (options.timestamp) {
      startDate = options.timestamp;
      endDate = options.timestamp;
    } else if (options.dateRange) {
      startDate = options.dateRange.startDate;
      endDate = options.dateRange.endDate;
    }
    if (startDate !== "") {
      params["KeyConditionExpression"] +=
        " AND #timestamp BETWEEN :startDate AND :endDate";
      params["ExpressionAttributeValues"][":startDate"] = startDate;
      params["ExpressionAttributeValues"][":endDate"] =
        endDate + "zzzzzzzzzzzzzzzzz";
      params["ExpressionAttributeNames"] = { "#timestamp": "timestamp" };
    }

    var data = await aws.documentClient.query(params).promise();
    let tmpReceipts = [];
    let tmpVoidReceipts = [];
    let tmpEntertainReceipts = [];

    _.forEach(data.Items, item => {
      if (item.void) {
        tmpVoidReceipts.push(item);
      } 
      else if (item.entertain) {
        tmpEntertainReceipts.push(item);
      } 
      else {
        tmpReceipts.push(item);
      }
    });
    setReceipts(tmpReceipts);
    setVoidReceipts(tmpVoidReceipts);
    setEntertainReceipts(tmpEntertainReceipts);
  }

  useEffect(() => {
    fetchReceipts(initShopId, initOptions);
  }, []);

  return {
    receipts,
    fetchReceipts,
    voidReceipts,
    entertainReceipts
  };
}

export default useFetchReceipts;
