import React, { useState, useEffect } from "react";
import _ from 'lodash'

function useSummaryReceiptsState(receipts) {
  const [state, setState] = useState({});

  useEffect(() => {
    compute();
  }, [receipts]);

  const compute = () => {
    let res = _.reduce(
      receipts,
      (acc, { payment }) => {
        for (const key in payment) {
          if (_.isNumber(payment[key])) {
            acc[key] =  acc[key] || 0; //init
            acc[key] += payment[key] || 0; //add
          }
        }

        return acc;
      },
      {}
    );

    setState(res)
  };

  return state;
}

//schema

// "payment": {
//     "change": 10,
//     "grandTotal": 2290,
//     "options": [],
//     "optionsAmount": 0,
//     "paymentTypes": [
//       {
//         "amount": 2290,
//         "name": "Cash"
//       }
//     ],
//     "promotions": -390,
//     "received": 2300,
//     "rounding": 0,
//     "subTotal": 2680,
//     "totalItemMinuteAmount": 210,
//     "vat": 187.6
//   },

export default useSummaryReceiptsState