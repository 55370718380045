
import React, { useState, useEffect } from "react";
 
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as spaPosActions from "../../shared/actions/spaPosActions";
import * as roomsSelector from "../../shared/selector/roomsSelector";
import * as inventorySelector from "../../shared/selector/inventorySelector";

 
import DashboardSpaPos from "../Spa/Dashboard";

import _ from "lodash";

function Index(props) {
  const [view, setView] = useState(10);


  useEffect(() => {
    if (!_.isEmpty(props.match.params)) {
      setView(parseInt(props.match.params.pageNo));
    }
  }, []);

  return (
    <div className="main-content p-0">
      {/* <Navbar /> */}
      <div class="card pt-3">
        {/* <div class="card-header">Icon Text Tabs</div> */}
         <DashboardSpaPos dateRange={true}   chartGroupBy="date"  {...props} />
       </div>
    </div>
  );
}


const mapStateToProps = state => {
    return {
      spaPos: state.spa.pos,
      user: state.user.loggedIn,
      shop: state.shop.detail,
      spaRooms: state.spa.rooms,
      bedOptions: roomsSelector.bedOptionsSelector(state),
      menuItems: inventorySelector.groupItemSelector(state),
      inventory: inventorySelector.typeGroupItemSelector(state)
    };
  };
  function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ ...spaPosActions }, dispatch);
    return {
      actions
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Index);
  