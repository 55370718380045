import React, { useState } from "react";
import _ from "lodash";
import Select from "react-select";
import { prepaidPaymentRef } from "../../shared/firebaseApi/firebaseRef";
import moment from "moment";
import swal from "sweetalert";

function Option2Line(props) {
  const {
    removeLine,
    index,
    handleEdit,
    handleChangeOption,
    save,
    allList,
    data,
    paymentOptions,
    optionList,
    entertainOptions,
    spaPos,
    grandTotal,
    mainAppState,
    products
  } = props;
  const { member, jobUid } = spaPos;

  let currentOption = optionList[index];

  const createPrepaidData = () => {
    prepaidPaymentRef
      .doc(jobUid)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          console.log("Document data:", doc.data());
        } else {
          // doc.data() will be undefined in this case
          let data = {
            grandTotal,
            expectAmount: grandTotal,
            amount: 0,
            hqUid: mainAppState.shop.linked.inventory,
            jobUid,
            products: products,
            store: mainAppState.shop.detail,
            prepaid_timestamp: moment().toISOString()
          };

          if (member.name !== "" && member.type !== "temporary") {
            data.member = member;
          }
          prepaidPaymentRef.doc(jobUid).set(data);
        }
      });
  };

  return (
    <a className="list-group-item d-flex list-group-item-action  pl-2 pr-2">
      <div className="row">
        <div className="col-3">Option</div>
        <div className="col-9">
          <select
            className="form-control form-control-xs"
            onChange={evt => {
              // let idx = parseInt(evt.target.value);
              let selectedOption = _.find(
                paymentOptions,
                option => option.uid === evt.target.value
              );
              handleChangeOption(index, selectedOption);
            }} 
            disabled={
              currentOption.uid === "prepaid001" && currentOption.value !== ""
            }
          >
            <option value="" disabled selected>
              Select Option
            </option>
            {_.map(paymentOptions, (o, i2) => {
              return (
                <option value={o.uid} selected={o.uid === currentOption.uid}>
                  ({o.sign === 1 ? "+" : "-"}) {o.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="col-3">Value</div>
        <div className="col-9">
          <div className="input-group mb-0">
            <input
              className="form-control form-control-xs"
              type="number"
              value={data.value}
              className="form-control form-control-xs"
              onChange={evt => handleEdit(index, "value", evt.target.value)}
              disabled={currentOption.uid === "prepaid001"}
            />
            <div className="input-group-append be-addon">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                disabled={currentOption.isStatic}
                style={
                  currentOption.isStatic ? { backgroundColor: "lightgray" } : {}
                }
              >
                {data.mode === 0 ? "%" : "THB"}
              </button>
              <div className="dropdown-menu">
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => handleEdit(index, "mode", 0)}
                >
                  Percent
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => handleEdit(index, "mode", 1)}
                >
                  AMOUNT
                </a>
              </div>
            </div>
          </div>
        </div>
        {currentOption.uid === "prepaid001" && [
          <div className="col-9 offset-3">
            {currentOption.value !== "" && currentOption.value > 0 ? (
              <button
                class="btn btn-space btn-success m-0"
                style={{ width: "100%" }}
              >
                Approved
              </button>
            ) : (
              <a
                href={`https://payment-confirm-dev-web.firebaseapp.com/payment/request/${jobUid}`}
                target="_blank"
              >
                <button
                  class="btn btn-space btn-primary m-0"
                  onClick={createPrepaidData}
                  style={{ width: "100%" }}
                >
                  Request Prepaid Form
                </button>
              </a>
            )}
          </div>
        ]}
        {currentOption.uid === "entertain001" && [
          <div className="col-3">Entertain</div>,
          <div className="col-9">
            <Select
              value={data.payload}
              onChange={ent => handleEdit(index, "payload", ent)}
              options={entertainOptions}
            />
          </div>
        ]}

        <div className="col-3">Remark</div>
        <div className="col-9">
          <input
            vale={data.remark}
            onChange={evt => handleEdit(index, "remark", evt.target.value)}
            className="form-control form-control-xs"
          />
        </div>
        <div className="col-3" />
        <div className="col-9">
          {/* <button onClick={() => save()}>Save</button> */}
          <button
            class="btn btn-space btn-danger m-0 mt-1"
            onClick={() => removeLine(index)}
          >
            Remove
          </button>
        </div>
      </div>
    </a>
  );
}

export default Option2Line;
