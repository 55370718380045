import moment from "moment";
import Paper from "niceloop-paper";
import _ from "lodash";
import {
  extractMinsFromSingleTherapist,
  extractMinsFromMultipleTherapist,
} from "../shared/utils/receipts";

class PrintReportSummary {
  payload = null;
  dispatch = null;
  printCmdResult = null;
  printType = "PRINT_CUSTOM_V2";
  isCloseShop = false;
  resultSummary = {};
  resultOptions = [];
  voidReceipts = [];
  resultPaymentTypes = {};
  resultAgentGroups = {};
  resultTherapistGroups = {};
  constructor(dispatch, shop, receipts, options = {}) {
    this.dispatch = dispatch;
    this.shop = shop;
    this.receipts = receipts;
    this.isCloseShop = options.isCloseShop;
    this.voidReceipts = options.receiptVoids || [];
    this.entertainReceipts = options.entertainReceipts || [];
    this.computeSummary();
    this.computeOptions();
    this.computePaymentTypes();

    this.resultAgentGroups = _.groupBy(this.receipts, (r) => {
      // let p = _.find(r.payment.paymentTypes, { type: "cityLedgend" });
      // return p.payload.name;
      if (r.agent) {
        return r.agent.name;
      }
      return "unknown";
    });

    this.resultEntertainGroups = _.groupBy(this.entertainReceipts, (r) => {
      // let p = _.find(r.payment.paymentTypes, { type: "entertain" });
      // return p.payload.name;
      if (r.entertain) {
        return r.entertain.name;
      }
      return "unknown";
    });

    this.resultTherapistGroups = _.groupBy(
      _.concat(this.receipts, this.entertainReceipts),
      (r) => (r.therapist && r.therapist.name) || "unknown"
    );

    this.resultTherapistGroups = _.reduce(
      _.concat(this.receipts, this.entertainReceipts),
      (acc, r) => {
        //single therpist
        if (r.therapist_backup) {
          var data = extractMinsFromSingleTherapist(r);
          if (acc[data.name] === undefined) {
            acc[data.name] = {
              time: 0,
              count: 0,
              name: data.name,
            };
          }
          acc[data.name].time += data.time;
          acc[data.name].count++;
        }

        //multiple
        if (r.therapistList_backup) {
          var dataMap = extractMinsFromMultipleTherapist(r);
          _.forEach(dataMap, (data, name) => {
            if (acc[data.name] === undefined) {
              acc[data.name] = {
                time: 0,
                name: data.name,
                count: 0,
              };
            }
            acc[data.name].time += data.time;
            acc[data.name].count++;
          });
        }

        return acc;
      },
      {}
    );
  }

  computeOptions() {
    let res = _.reduce(
      this.receipts,
      (acc, { payment }) => {
        let options = payment.options;
        _.forEach(options, (option) => {
          acc[option.name] = acc[option.name] || {
            name: option.name,
            amount: 0,
            qty: 0,
          }; //init
          acc[option.name].amount += option.amount || 0; //add
          acc[option.name].qty++;
        });

        return acc;
      },
      {}
    );

    this.resultOptions = _.values(res);
  }
  computePaymentTypes() {
    let res = _.reduce(
      this.receipts,
      (acc, { payment }) => {
        let types = payment.paymentTypes;
        _.forEach(types, (t) => {
          acc[t.name] = acc[t.name] || { amount: 0, count: 0, name: t.name }; //init
          acc[t.name].amount += t.amount;
          acc[t.name].count++;
        });

        return acc;
      },
      {}
    );

    this.resultPaymentTypes = res;
  }

  computeCreditCard() {
    const _creditList = ["VISA", "MASTERCARD", "UNIONPAY", "AMEX", "JCB"];
    function isCard(name) {
      if (_.includes(_creditList, name.toUpperCase())) {
        return true;
      } else {
        return false;
      }
    }

    let res = _.reduce(
      this.receipts,
      (acc, { payment }) => {
        let types = payment.paymentTypes;
        let sum = 0;
        _.forEach(types, (t) => {
          if (isCard(t.name)) {
            sum += t.amount;
          }
        });

        return acc + sum;
      },
      0
    );

    return res;
  }

  computeSumRetial() {
    let res_retial = _.filter(this.receipts, (r) => r.meta.room == null);
    let grandTotal_sum = _.sumBy(res_retial, "payment.grandTotal");
    return grandTotal_sum;
  }

  computeSummary() {
    let res = _.reduce(
      this.receipts,
      (acc, { payment }) => {
        for (const key in payment) {
          if (_.isNumber(payment[key])) {
            acc[key] = acc[key] || { amount: 0, qty: 0 }; //init
            acc[key].amount += payment[key] || 0; //add
            acc[key].qty++;
          }
        }

        return acc;
      },
      {}
    );

    this.resultSummary = res;
  }

  build() {
    var Order = {};

    Order.UniqueId = this.getRandomInt(99999999999);

    Order.Printer = [0];
    Order.Title1 = this.shop.detail.name;
    Order.Title2 = "สรุปรายงานขาย"; //this.isCloseBill ? '***** Close Bill *****' : 'ใบจัดส่งสินค้า'

    Order.isPreview = true;
    Order.CustomHeadersArrays = [
      this._genHeader(),

      this._genSummaryDetail(),
      this._genPaymentTypeSummary(),
      this._genOptions(),
      this._genRetail(),

      this._genTherapist(),
      this._genEntertain(),

      this._genAgentSummary(),
      this._genSalesSummary(),

      this._genTimestamp(),
    ];

    Order.CustomFootersArrays = [];
    this.Order = Order;
    return this;
  }

  _genSummaryDetail = () => {
    var paper = new Paper();
    paper.title(`Summary (${this.receipts.length} bills)`);
    paper.lineFeed();

    this.resultSummary.subTotal &&
      paper.keyValue(
        "SubTotal" + " (" + this.resultSummary.subTotal.qty + ")",
        getMoney(this.resultSummary.subTotal.amount)
      );
    this.resultSummary.promotions &&
      paper.keyValue(
        "Promotions" + " (" + this.resultSummary.promotions.qty + ")",
        getMoney(this.resultSummary.promotions.amount)
      );

    // paper.keyValue("Options", getMoney(this.resultSummary.optionsAmount));

    // OPTIONS
    _.forEach(this.resultOptions, (line) => {
      paper.keyValue(line.name + " (" + line.qty + ")", getMoney(line.amount));
    });

    this.resultSummary.vat &&
      paper.keyValue(
        "VAT " + "(" + this.resultSummary.vat.qty + ")",
        getMoney(this.resultSummary.vat.amount)
      );
    this.resultSummary.rounding &&
      paper.keyValue(
        "Rounding " + " (" + this.resultSummary.rounding.qty + ")",
        getMoney(this.resultSummary.rounding.amount)
      );

    paper.drawLine();
    this.resultSummary.grandTotal &&
      paper.keyValue(
        "Grand Total " + " (" + this.resultSummary.grandTotal.qty + ")",
        `*** ${getMoney(this.resultSummary.grandTotal.amount)}`
      );

    paper.draw2Line();

    paper.comment(
      "*** Voided Bills  :   " +
        _.sumBy(this.voidReceipts, "payment.grandTotal") +
        ` (${this.voidReceipts.length})`
    );
    paper.lineFeed();
    paper.lineFeed();

    return paper.gen();
  };

  _genPaymentTypeSummary = () => {
    var paper = new Paper();
    paper.title(`By Payment Type`);
    paper.lineFeed();
    let sort = _.values(this.resultPaymentTypes);
    sort = _.sortBy(sort, (line) => -line.amount);
    _.forEach(sort, (v, k) => {
      paper.keyValue(`${v.name}   (${v.count})`, getMoney(v.amount));
    });
    paper.lineFeed();

    let creditCardAmount = this.computeCreditCard();

    paper.comment("*** ALL CREDIT CARDS :  " + getMoney(creditCardAmount));
    paper.lineFeed();
    paper.lineFeed();

    return paper.gen();
  };

  _genOptions = () => {
    var paper = new Paper();
    paper.title(`รายการหักยอด`);
    paper.lineFeed();
    // OPTIONS
    // _.forEach(this.resultOptions, line => {
    //   paper.keyValue(line.name + " (" + line.qty + ")", getMoney(line.amount));
    // });

    let ent = _.find(this.resultOptions, (line) => {
      return line.name == "Entertain";
    });
    if (ent) {
      paper.keyValue(ent.name + " (" + ent.qty + ")", getMoney(ent.amount));
    }

    ent = _.find(this.resultOptions, (line) => {
      return line.name == "Prepaid";
    });
    if (ent) {
      paper.keyValue(ent.name + " (" + ent.qty + ")", getMoney(ent.amount));
    }

    paper.lineFeed();

    return paper.gen();
  };

  _genRetail = () => {
    var paper = new Paper();
    paper.title(`Retail Products Sales`);
    let sum = this.computeSumRetial();
    paper.keyValue("Total sales", getMoney(sum));

    paper.lineFeed();

    return paper.gen();
  };

  _genAgentSummary = () => {
    var paper = new Paper();
    paper.title(`By Agent (City Ledger)`);
    paper.lineFeed();
    _.forEach(this.resultAgentGroups, (receipts, name) => {
      if (name === "unknown") {
        return;
      }
      let sum = _.sumBy(receipts, "payment.grandTotal");
      paper.keyValue(name + `   (${_.size(receipts)})`, getMoney(sum));
    });

    paper.lineFeed();
    paper.lineFeed();
    paper.drawLine();
    paper.lineFeed();
    paper.lineFeed();

    return paper.gen();
  };

  _genSalesSummary = () => {
    var paper = new Paper();
    paper.title(`By Sales`);
    paper.lineFeed();
    let resultSales = _.filter(this.receipts, (r) => r.sales != undefined);
    let resultAgentGroups = _.groupBy(resultSales, (r) => r.sales.name);
    _.forEach(resultAgentGroups, (receipts, name) => {
      if (name === "unknown") {
        return;
      }
      let sum = _.sumBy(receipts, "payment.grandTotal");
      paper.keyValue(name + `   (${_.size(receipts)})`, getMoney(sum));
    });

    paper.lineFeed();
    paper.lineFeed();
    paper.drawLine();
    paper.lineFeed();
    paper.lineFeed();

    return paper.gen();
  };

  _genTherapist = () => {
    var paper = new Paper();
    paper.title(`By Therapist`);
    paper.lineFeed();
    let sorted = _.values(this.resultTherapistGroups);
    sorted = _.sortBy(sorted, (line) => -line.time);
    _.forEach(sorted, (data, i) => {
      paper.keyValue(
        data.name + ` (${data.count})`,
        `${Math.floor(data.time / 60)}h ${data.time % 60}m`
      );
    });

    paper.lineFeed();
    paper.lineFeed();
    paper.drawLine();
    paper.lineFeed();
    paper.lineFeed();

    return paper.gen();
  };

  _genEntertain = () => {
    var paper = new Paper();
    paper.title(`Entertain`);
    paper.lineFeed();
    _.forEach(this.resultEntertainGroups, (receipts, name) => {
      if (name === "unknown") {
        return;
      }
      let sum = _.sumBy(receipts, "payment.grandTotal");
      paper.keyValue(name + `   (${_.size(receipts)})`, getMoney(sum));
    });

    paper.lineFeed();
    paper.lineFeed();
    paper.drawLine();
    paper.lineFeed();
    paper.lineFeed();

    return paper.gen();
  };

  _genHeader = () => {
    var paper = new Paper();
    paper.lineFeed();

    if (this.isCloseShop) {
      paper.comment("*****   Close Shop   *****");
      paper.lineFeed();
    }

    paper.keyValue("Report", "Summary Income");
    paper.keyValue("Date", moment().format("DD/MM/YYYY"));
    paper.keyValue("Time:   ", "All Day");
    // paper.comment("Printed at :   " + moment().format("DD/MM/YYYY HH:mm:ss"));
    // paper.textLine("ถึงวันที่ :   " + moment().format("DD/MM/YYYY HH:mm"));
    paper.lineFeed();
    paper.drawLine();
    paper.lineFeed();
    paper.lineFeed();

    return paper.gen();
  };

  _genTimestamp() {
    var paper = new Paper();
    paper.comment("Printed at :   " + moment().format("DD/MM/YYYY HH:mm:ss"));
    paper.lineFeed();

    if (this.isCloseShop) {
      paper.comment("*****   Close Shop   *****");
    }
    paper.lineFeed();
    return paper.gen();
  }

  // _genTimestamp = () => {
  //   var paper = new Paper();
  //   paper.comment("Printed at :   " + moment().format("DD/MM/YYYY HH:mm:ss"));
  //   paper.lineFeed();
  //   return paper.gen();
  // };

  print() {
    this.dispatch({
      type: "PRINT",
      meta: {
        printType: this.printType,
        type: "PRINT",
      },
      payload: this.Order,
    });
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
}

export default PrintReportSummary;

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

function getMoney(number) {
  return `฿ ${(number || 0).toLocaleString()}`;
}
