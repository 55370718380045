import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import _ from "lodash";
import moment from "moment";
import swal from "sweetalert";

const MultipleTherapistModal = props => {
  const {
    products,
    therapistList,
    setTherapistList,
    therapistOption,
    productObj
    // therapistMode
  } = props;

  const [itemHolder, setItemHolder] = useState({});

  const handleAddTher = item => {
    //TODO: Add ไม่ได้ถ้าเวลาเต็ม
    let newHolder = _.cloneDeep(itemHolder);
    if (!newHolder[item.timestamp]) {
      newHolder[item.timestamp] = [];
    }

    newHolder[item.timestamp].push({ therapist: null, workMinutes: "" });
    setItemHolder(newHolder);
  };

  const handleChangeTherapist = (item, ther, idx) => {
    let newHolder = _.cloneDeep(itemHolder);
    newHolder[item.timestamp][idx]["therapist"] = ther;
    setItemHolder(newHolder);
  };
  const handleChangeTherapistWorkTime = (item, time, idx) => {
    let newHolder = _.cloneDeep(itemHolder);
    newHolder[item.timestamp][idx]["workMinutes"] = time;
    setItemHolder(newHolder);
  };

  const handleChangeTherapistRequire = (item, required, idx) => {
     
    let newHolder = _.cloneDeep(itemHolder);
    newHolder[item.timestamp][idx]["required"] = required;
    newHolder[item.timestamp][idx]["therapist"]["required"] = required;

    setItemHolder(newHolder);
  };

  const handleRemoveTherapist = (item, idx) => {
    let newHolder = _.cloneDeep(itemHolder);
    //PICK COURSE
    let oldThers = _.cloneDeep(itemHolder[item.timestamp]);
    //DELETE THER IN THAT COURSE
    let newTher = [...oldThers.slice(0, idx), ...oldThers.slice(idx + 1)];
    //SAVE TO NEW HOLDER
    newHolder[item.timestamp] = newTher;

    setItemHolder(newHolder);
  };

  const save = () => {
    let tmpHolder = {};
    let isFail = null;
    _.map(itemHolder, (list, timestamp) => {
      _.forEach(list, line => {
        if (line.therapist && line.workMinutes === "") {
          isFail = "คุณกรอกข้อมูลไม่ครบถ้วน";
          return;
        } else if (line.therapist && _.isNaN(parseInt(line.workMinutes))) {
          isFail = "กรุณากรอกนาทีเป็นตัวเลขเท่านั้น";
          return;
        }

        if (
          line.therapist &&
          line.workMinutes !== "" &&
          !_.isNaN(parseInt(line.workMinutes))
        ) {
          if (!tmpHolder[timestamp]) {
            tmpHolder[timestamp] = [];
          }
          tmpHolder[timestamp].push(line);
        }
      });
    });

    if (isFail) return swal("เกิดข้อผิดพลาด", isFail, "error");

    //SAVE ก่อนออก
    setItemHolder(tmpHolder);

    //SAVE TO STORE
    setTherapistList(tmpHolder);

    window.$("#multipleTherapistModal").modal("hide");
  };

  const initComputeData = products => {
    if (!therapistList) return;

    //BUG MISSING ITEM PROTECT
    let newTherList = {};
    _.map(therapistList, (course, key) => {
      if (productObj[key]) {
        newTherList[key] = course;
      }
    });

    setItemHolder(newTherList);
  };

  useEffect(() => {
    initComputeData(products);
  }, [products]);

  const getTotalEmpMins = itemHolder => {
    let sumEmpTime = 0;
    _.forEach(itemHolder, emp => {
      let empMins = parseInt(emp.workMinutes);
      sumEmpTime += isNaN(empMins) ? 0 : empMins;
    });
    return sumEmpTime;
  };

  return (
    <div
      class="modal"
      id="multipleTherapistModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="multipleTherapistModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog full-width modal-dialog-centered"
        // class="modal-dialog modal-dialog-centered"
        role="document"
        style={{ width: 750 }}
      >
        <div class="modal-content" style={{ overflow: "unset" }}>
          <div class="modal-header">
            <h3 class="modal-title" id="multipleTherapistModalLabel">
              Manage Therapists
            </h3>
          </div>
          <div class="modal-body">
            {_.map(products, item => {
              let totalTime = item.timeMinutes * item.qty;
              let sumEmpTime = getTotalEmpMins(itemHolder[item.timestamp]);
              let timeLeft = totalTime - sumEmpTime;

              return [
                <div className="row m-0 justify-content-between mt-2">
                  <div>
                    <span style={{ fontWeight: "bold", fontSize: 16 }}>
                      <span style={{ color: "blue" }}>{item.qty} x</span>{" "}
                      {item.name} <span>(Total {totalTime})</span>{" "}
                      <span style={{ color: timeLeft >= 0 ? "green" : "red" }}>
                        (Left {timeLeft})
                      </span>{" "}
                    </span>
                  </div>
                  <div>
                    <a
                      style={{ color: "blue", fontSize: 17 }}
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        handleAddTher(item);
                      }}
                    >
                      <span class="mdi mdi-plus-circle-o"></span>
                    </a>
                  </div>
                </div>,
                _.map(itemHolder[item.timestamp], (course, idx) => {
                  return (
                    <div className="row m-0 mt-1">
                      <div className="col-auto offset-1 pl-0 align-self-center">
                        {idx + 1}.
                      </div>
                      <div className="col-4 p-0">
                        <Select
                          placeholder="Select Therapist"
                          isClearable
                          value={course.therapist}
                          onChange={ther =>
                            handleChangeTherapist(item, ther, idx)
                          }
                          options={therapistOption}
                        />
                      </div>
                      <div className="col-2">
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          value={course.workMinutes}
                          placeholder="เวลา (นาที)"
                          onChange={e =>
                            handleChangeTherapistWorkTime(
                              item,
                              e.target.value,
                              idx
                            )
                          }
                        />
                      </div>
                      <div className="col-auto p-0">
                        <label
                          class="custom-control custom-checkbox custom-control-inline"
                          onClick={e => {
                            e.preventDefault();
                            handleChangeTherapistRequire(
                              item,
                              !course.required,
                              idx
                            );
                          }}
                        >
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            checked={course.required}
                          />
                          <span class="custom-control-label custom-control-color">
                            Required
                          </span>
                        </label>
                      </div>

                      <div className="col-2">
                        <a
                          style={{ color: "red", fontSize: 17 }}
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            handleRemoveTherapist(item, idx);
                          }}
                        >
                          <span class="mdi mdi-delete"></span>
                        </a>
                      </div>
                    </div>
                  );
                })
              ];
            })}
          </div>
          <div class="modal-footer pt-2">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              style={{ width: 100 }}
              // onClick={() => {
              //   window.$("#multipleTherapistModal").modal("hide");
              // }}
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => save()}
              style={{ width: 100 }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  let products = [];
  let productObj = {};

  _.map(state.items, item => {
    productObj[item.timestamp.toString()] = item;
    if (item.timeMinutes) products.push(item);
  });

  return {
    products,
    productObj,
    qtyHolder: state.qtyHolder
  };
};

export default connect(mapStateToProps)(MultipleTherapistModal);
