import React, { useState } from "react";
import useDrawerReducer from "../hook.drawer.reducer";
import _ from 'lodash'
const styleFont = { fontSize: "1.2rem", width : 160 };
function DashboardDrawer() {
  const [count, setCount] = useState(0);
  const { state: drawerState, dispatch: drawerDiapatch } = useDrawerReducer();
  return (
    <div class="col-12 col-lg-5 offset-lg-3">
      <div class="card">
        <div class="card-header">Drawer</div>
        <div class="card-body">
          <ul class="user-timeline user-timeline-compact">
            <li class="latest">
              <div class="user-timeline-date" style={styleFont}>
                {drawerState.startDate}
              </div>
              <div class="user-timeline-title">StartTime</div>
              <div class="user-timeline-description">
                Business Date : {drawerState.businessDate} <br />
                Shift : {drawerState.shift}
              </div>
            </li>
            <li>
              <div class="user-timeline-date" style={styleFont}>
                {drawerState.cashInit}
                <Action
                  label="Start"
                  drawerDiapatch={drawerDiapatch}
                  type="DRAWER_CASH_START"
                />
              </div>
              <div class="user-timeline-title">Cash at Start </div>
              <div class="user-timeline-description">(initial)</div>
            </li>

            <li>
              <div class="user-timeline-date" style={styleFont}>
                {drawerState.cashIn}
                <Action
                  label="Add"
                  drawerDiapatch={drawerDiapatch}
                  type="DRAWER_CASH_IN"
                />
              </div>
              <div class="user-timeline-title">Cash In</div>
              <div class="user-timeline-description">
                {drawerState.cashInCount} times
              </div>
            </li>

            <li>
              <div class="user-timeline-date" style={styleFont}>
                {drawerState.cashOut}
                <Action
                  label="Withdraw"
                  drawerDiapatch={drawerDiapatch}
                  type="DRAWER_CASH_OUT"
                />
              </div>
              <div class="user-timeline-title">Cash Out</div>
              <div class="user-timeline-description">
                {drawerState.cashOutCount} times
              </div>
            </li>

            <li>
              <div class="user-timeline-date" style={styleFont}>
                {drawerState.cashVoided}
              </div>
              <div class="user-timeline-title">Cash Voided</div>
              <div class="user-timeline-description">
                {drawerState.cashVoidedCount} times
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DashboardDrawer;

function Action({ label, drawerDiapatch, type }) {
  return (
    <button
      style={{width: 80, float : 'right'}}
      class="btn btn-rounded btn-space btn-secondary"
      onClick={() => {
        let num = window.prompt("Enter amount");
        if (num) {
          if (_.isNumber(parseInt(num))) {
            drawerDiapatch({
              type: type,
              payload: parseInt(num)
            });
          } else {
            alert("Error not a number");
          }
        } else {
        }
      }}
    >
      {label}
    </button>
  );
}
