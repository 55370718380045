import moment from "moment";
import Paper from "niceloop-paper";
import _ from "lodash";

class PrintReportProduct {
  payload = null;
  dispatch = null;
  printCmdResult = null;
  printType = "PRINT_CUSTOM_V2";

  constructor(dispatch, data) {
    this.dispatch = dispatch;
    this.data = data;

    // this.build();
    // this.print();
  }

  build = () => {
    //obj is table instance
    var Order = {};
    Order.UniqueId = this.getRandomInt(99999999999);
    Order.Printer = [0];
    Order.Title1 = `Order :  #${this.data.refId || this.data.orderId}`;
    Order.Title2 = "";

    Order.CustomHeadersArrays = [
      this._genDateRange(),
      this.genPerson(),
      this.genProducts(),
      this._genTimestamp()
    ];

    Order.CustomFootersArrays = [];

    this.Order = Order;

    return this;
  };

  _genDateRange() {
    var paper = new Paper();
    paper.lineFeed();
    paper.textLine(
      "วันที่ :  " +
        moment(this.data.meta.startDate).format("DD MMM YYYY HH:mm")
    );
    // paper.textLine(
    //   "ถึงวันที่ :  " +
    //     moment(this.data.meta.endDate).format("DD MMM YYYY HH:mm")
    // );
    // paper.comment("printed at :   " + moment().format("DD MMM YYYY HH:mm"));
    paper.lineFeed();

    return paper.gen();
  }

  _genTimestamp() {
    var paper = new Paper();
    paper.comment("Printed at :   " + moment().format("HH:mm DD MMM YYYY"));
    paper.lineFeed();
    return paper.gen();
  }

  genPerson = () => {
    var paper = new Paper();

    let memberName = "";

    if (this.data.member) {
      memberName = this.data.member.name;
    }

    let therapistName = "";

    if (this.data.therapist) {
      therapistName = this.data.therapist.name;
    }
    let receptionName = "";
    if (this.data.reception) {
      receptionName = this.data.reception.name;
    }

    paper.keyValue("Member", memberName);
    paper.keyValue("Therapist", therapistName);
    paper.keyValue("Reception", receptionName);

    paper.lineFeed();

    paper.drawLine();
    paper.lineFeed();

    return paper.gen();
  };

  genProducts = () => {
    var paper = new Paper();

    const { meta } = this.data;

    paper.title(`${meta.room.name} - ${meta.bed.name}`);
    paper.lineFeed();
    _.forEach(this.data.items, item => {
      if (item.promotion) return;
      paper.keyValue(`${item.qty} ${item.name}`);
    });

    paper.lineFeed();
    paper.drawLine();
    paper.lineFeed();

    return paper.gen();
  };

  print() {
    this.dispatch({
      type: "PRINT",
      meta: {
        printType: this.printType,
        type: "PRINT"
      },
      payload: this.Order
    });
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
}

export default PrintReportProduct;

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

function getMoney(number) {
  return `฿ ${(number || 0).toLocaleString()}`;
}
