import React from 'react'
import InventoryShops from './InventoryShops'

class ManageUser extends React.Component {

    render() {
        // className="main-content container-fluid"
        const { match } = this.props
        return (
            <div>
                {/* <InventoryShops match={match} /> */}
                <div className="tab-container">
                    {/* <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item"><a className="nav-link active show" href="#inventory" data-toggle="tab" role="tab" aria-selected="true">Inventory</a></li>
                    </ul> */}
                    <div className="tab-content">
                        <div className="tab-pane active show" id="inventory" role="tabpanel">
                            <InventoryShops match={match} />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ManageUser;