import React from "react";
import useSummaryReceiptsState from "../useHook/useSummaryReceiptsState";
import _ from 'lodash'


const smallStyle = {
  fontSize : '1em',
  fontStyle : 'italic',
  fontWeight : '300',
  color : 'gray'
}
function ReceiptSummaryView({ receipts, receipts_entertain = [] , receiptVoids =[] }) {
  const summary = useSummaryReceiptsState(receipts);

  return (
    <div class="card">
      <div class="card-body">
        <ul class="summary-list pl-0">
          <li class="summary-list-item mb-5">
            <p>
              <span class=" item-left">Customers</span>
              <span class=" item-right">{receipts.length}</span>
            </p>
          </li>

          <li class="summary-list-item">
            <p>
              <span class=" item-left">SubTotal</span>
              <span class=" item-right">
                {(summary.subTotal || 0).toLocaleString()}
              </span>
            </p>
          </li>

          <li class="summary-list-item">
            <p>
              <span class=" item-left">Promotion Discount</span>
              <span class=" item-right">
                {(summary.promotions || 0).toLocaleString()}
              </span>
            </p>
          </li>

          <li class="summary-list-item">
            <p>
              <span class=" item-left">Options</span>
              <span class=" item-right">
                {(summary.optionsAmount || 0).toFixed(2)}
              </span>
            </p>
          </li>

          <li class="summary-list-item">
            <p>
              <span class=" item-left">Vat</span>
              <span class=" item-right">{(summary.vat || 0).toFixed(2)}</span>
            </p>
          </li>

          <li class="summary-list-item">
            <p>
              <span class=" item-left">Rounding</span>
              <span class=" item-right">
                {(summary.rounding || 0).toFixed(2)}
              </span>
            </p>
          </li>

          <li class="summary-list-item">
            <p>
              <b>
                <span class=" item-left">Grand Total ({receipts.length})</span>
                <span class=" item-right">
                  {(summary.grandTotal || 0).toLocaleString()}
                </span>
              </b>
            </p>
          </li>


{/* entertain */}
          <li class="summary-list-item" style={smallStyle}>
            <p>
              <b>
                <span class=" item-left">Entertain ({receipts_entertain.length})</span>
                <span class=" item-right">
                  {(_.sumBy(receipts_entertain,'payment.grandTotal') || 0).toLocaleString()}
                </span>
              </b>
            </p>
          </li>

          
          {/* receiptVoids */}
          <li class="summary-list-item" style={smallStyle}>
            <p>
              <b>
                <span class=" item-left">Void ({receiptVoids.length})</span>
                <span class=" item-right">
                  {(_.sumBy(receiptVoids,'payment.grandTotal') || 0).toLocaleString()}
                </span>
              </b>
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ReceiptSummaryView;
