import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import axios from "axios";
import "./therapist-time.css";
import { downloadableLinkUrl } from "../../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import * as appStateAction from "../../../shared/actions/appStateActions";

const monthList = [
  { name: "2019-09", value: "2019-09" },
  { name: "2019-10", value: "2019-10" },
  { name: "2019-11", value: "2019-11" },
  { name: "2019-12", value: "2019-12" }
];

const URL =
  "https://asia-east2-niceloop-dev.cloudfunctions.net/genExelTherapistByDate";
function DashboardTherapistTable(props) {
  const dispatch = useDispatch();

  const [selectMonth, setSelectMonth] = useState(moment().format("YYYY-MM"));

  const shopInfo = useSelector(state => {
    return state.shop.detail;
  });
  const [data, setData] = useState(null);

  async function submit() {
    dispatch(appStateAction.loading(true));

    try {
      const data = {
        shopId: shopInfo.uid,
        timestamp: selectMonth || moment().format("YYYY-MM"),
        mode: "json"
      };
      let res = await axios.post(URL, data);

      let data2 = _.reduce(
        res.data,
        (acc, val, key) => {
          acc[key] = val;
          let date_tmp = [];
          let sum = 0;
          _.forEach(val.date, (val2, key2) => {
            if (key2.substring(0, 7) == selectMonth) {
              val2.date = key2;
              date_tmp.push(val2);
              sum += val2.totalMiniute;
            }
          });
          acc[key].date = _.sortBy(date_tmp, "date");
          acc[key].totalMiniute = sum;
          return acc;
        },
        {}
      );
      let r1 = _.values(data2);
      r1 = _.sortBy(r1, line => -line.totalMiniute);
      setData(r1);
      dispatch(appStateAction.loading(false));
    } catch (error) {
      dispatch(appStateAction.loading(false));
    }
  }

  const downloadPdf = () => {
    let URL =
      "https://asia-east2-niceloop-dev.cloudfunctions.net/genExelTherapistByDate";
    let data = {
      shopId: props.shop.uid,
      mode: "excel",
      timestamp: selectMonth
    };

    dispatch(appStateAction.loading(true));

    axios
      .post(URL, data)
      .then(res => {
        downloadableLinkUrl("report-summary-excel.xlsx", res.data);
      })
      .catch(err => alert(err))
      .finally(() => {
        dispatch(appStateAction.loading(false));
      });
  };

  return (
    <div>
      <div class="row m-0 justify-content-between mt-5 mr-5">
        <div style={{ fontSize: 18 }}>
          <label class="ml-4">Select Month </label>
          <select
            class="ml-4"
            value={selectMonth}
            onChange={e => setSelectMonth(e.target.value)}
          >
            {_.map(monthList, m => (
              <option value={m.value}>{m.name}</option>
            ))}
          </select>
          <button class="btn btn-primary ml-4" onClick={submit}>
            submit
          </button>
        </div>

        <div>
          <div>
            <button class="btn btn-space btn-secondary" onClick={downloadPdf}>
              Export Excel
            </button>
          </div>
        </div>
      </div>

      {data && (
        <div class="row no-gutters mt-5">
          <div class="col-2">
            <TherapistTimeHeader mode={0} />
            {_.map(data, (value, key) => {
              return (
                <TherapistTimeRow
                  name={value.name}
                  date={value.date}
                  index={key}
                  mode={0}
                  totalMiniute={value.totalMiniute}
                />
              );
            })}
          </div>

          <div class="col-10" style={{ overflow: "scroll" }}>
            <TherapistTimeHeader mode={1} />
            {_.map(data, (value, key) => {
              return (
                <TherapistTimeRow
                  name={value.name}
                  date={value.date}
                  index={key}
                  mode={1}
                  totalMiniute={value.totalMiniute}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default DashboardTherapistTable;
const zebra = "rgba(0,0,0,.05)";
function TherapistTimeRow({ date, index, name, mode, totalMiniute = 0 }) {
  let odd = index % 2 === 0;

  return (
    <div
      class="th-grid-container"
      style={{ display: mode === 0 ? "block" : "grid" }}
    >
      {mode === 0 && (
        <div
          class="data"
          style={{ backgroundColor: odd ? zebra : "inherit", color: "blue" }}
        >
          <div class="text-left">
            {" "}
            <span class="mr-2" style={{ color: "gray" }}>
              {index + 1}.
            </span>
            {name}{" "}
          </div>
          <div class="text-right">
            {totalMiniute !== 0 && (
              <span>{Math.floor(totalMiniute / 60)}h </span>
            )}
            <br />
            {totalMiniute !== 0 && <small> {totalMiniute % 60}m</small>}
          </div>
        </div>
      )}

      {mode === 1 && (
        <div
          class="th-grid-container-2"
          //   style={{ }}
        >
          {_.map(date, (line, keyDate) => {
            let col = moment(line.date).date();
            return (
              <div
                class="slot"
                style={{
                  gridColumn: `${col} / span 1`,
                  backgroundColor: odd ? zebra : "inherit"
                }}
              >
                {" "}
                <small style={{ color: "gray" }}>
                  {moment(line.date).format("DD/MMM")}
                </small>
                <br />
                {line.totalMiniute !== 0 && (
                  <span>{Math.floor(line.totalMiniute / 60)}h </span>
                )}
                <br />
                {line.totalMiniute !== 0 && (
                  <small> {line.totalMiniute % 60}m</small>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
function TherapistTimeHeader({ date, index, name, mode }) {
  return (
    <div
      class="th-grid-container"
      style={{ display: mode === 0 ? "block" : "grid" }}
    >
      {mode === 0 && <div class="data"> Name</div>}

      {mode === 1 && (
        <div class="th-grid-container-2">
          {_.times(31, i => {
            return (
              <div class="slot" style={{ gridColumn: `${i + 1} / span 1` }}>
                {i + 1}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
