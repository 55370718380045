import moment from "moment";
import Paper from "niceloop-paper";
import _ from "lodash";

// payment receipts
class PrintReceipt {
  receipt = null;
  dispatch = null;
  printCmdResult = null;
  printType = "RECEIPT_FULL";

  constructor(dispatch, receipt) {
    this.dispatch = dispatch;
    this.receipt = receipt;
    // this.build();
    // this.print();
  }

  build() {
    //obj is table instance

    var receipt = this.receipt;
    var Order = {};

    Order.UniqueId = this.getRandomInt(99999999999);

    Order.Printer = [0];
    Order.Title1 = "WORKING COUPON";
    Order.Title2 = receipt.meta.room.name + " - " + receipt.meta.bed.name;

    Order.isPreview = true;

    Order.CustomHeaders1 = this.genSummaryHeader();
    Order.CustomHeaders2 = null;
    Order.CustomHeaders3 = null;
    Order.CustomHeaders4 = null;
    Order.CustomHeaders5 = null;
    Order.CustomHeaders6 = null;
    Order.CustomHeaders7 = null;
    Order.CustomHeaders8 = null;
    Order.CustomHeaders9 = null;
    Order.CustomHeaders10 = null;

    Order.Table = null;

    Order.OrderItems = null;
    Order.Summary1 = [];
    Order.Summary2 = [];
    Order.GrandTotal = null;
    Order.CustomFooters1 = this.convertItemsToPrint();
    Order.CustomFooters2 = null;
    Order.CustomFooters3 = null;
    Order.CustomFooters4 = null;
    Order.CustomFooters5 = null;
    Order.CustomFooters6 = null;
    Order.CustomFooters7 = null;
    Order.CustomFooters8 = null;
    Order.CustomFooters9 = null;
    Order.CustomFooters10 = null;
    Order.Note = null;

    this.Order = Order;
    return this;
  }

  genSummaryHeader() {
    var paper = new Paper();
    let {
      therapist,
      receiptId,
      meta = {},
      member,
      refId,
      orderId,
      isMultiple,
      localTime,
      requireEmp
    } = this.receipt;
    // paper.keyValue(`Order No : ${jobUid}`);
    paper.keyValue(`Reciept ID : ${receiptId}`);
    paper.keyValue(`Ref ID :  #${refId || orderId}`);
    paper.keyValue(`Date : ${moment(localTime).format("DD/MM/YYYY HH:mm")}`);
    therapist && paper.keyValue(`Therapist : ${therapist.name || "-"}` ) //`${this.receipt.requireEmp ? ' (requested)' : ''}`);
    member && paper.keyValue(`Guest : ${member.name || "-"}`);

    // if (isMultiple) {
    //   paper.keyValue(`*** Multiple Therapist`);
    // } else {
    //   paper.keyValue(
    //     `Time : ${moment(meta.startDate).format("HH:mm")} - ${moment(
    //       meta.endDate
    //     ).format("HH:mm")}`
    //   );
    // }

    paper.lineFeed();

    return paper.gen();
  }

  convertItemsToPrint() {
    var paper = new Paper();
    let { therapist } = this.receipt;

    let hasMultiTher = false;

    //ITEMS
    _.forEach(therapist.courses, course => {
      //SHOW COURSE TIME
      let time = convertMinuteToHour(course.workMinutes);
      if (course.workMinutes !== course.courseMinutes) {
        time += "*";
        hasMultiTher = true;
      }

      if (course.courseName.length > 30) {
        let chunkName = chunkString(course.courseName, 28);

        _.forEach(chunkName, (text, idx) => {
          if (idx === 0) {
            paper.keyValue(`${course.courseQty} x ${text}`, time);
          } else {
            paper.keyValue(`     ${text}`);
          }
        });
      } else {
        paper.keyValue(`${course.courseQty} x ${course.courseName}`, time);
      }
    });

    //SHOW TOTAL WORK TIME
    let totalHours = convertMinuteToHour(therapist.totalWorkMinutes);
    paper.lineFeed();
    paper.drawLine();
    // if (totalHours !== "0.0") {
    paper.keyValue("", "Total(Hr.)  " + totalHours);
    // }
    paper.draw2Line();

    if (hasMultiTher) {
      paper.comment("* Some courses has multiple therapists");
      paper.comment("  operated");
    }
    return paper.gen();
  }

  print() {
    this.dispatch({
      type: "PRINT",
      meta: {
        printType: this.printType,
        type: "PRINT"
      },
      payload: this.Order
    });
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
}

export default PrintReceipt;

const convertMinuteToHour = timeMinutes => {
  var hours = Math.floor(timeMinutes / 60);
  var minutes = timeMinutes % 60;
  let time = hours + "." + minutes;
  return time;
};

const chunkString = (str, len) => {
  var _size = Math.ceil(str.length / len),
    _ret = new Array(_size),
    _offset;
  for (var _i = 0; _i < _size; _i++) {
    _offset = _i * len;
    _ret[_i] = str.substring(_offset, _offset + len);
  }
  return _ret;
};
