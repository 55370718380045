import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import useFetchReceipt from "./useFetchReceipt";
import SpaBillModal from "../../shared/components/SpaBillModal";
import DatePicker from "react-datepicker";
import PrintReportSummary from "../../printer/service.print.report.summary";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import Select from "react-select";

function Receipts(props) {
  const [modalContent, setModalContent] = useState({});
  const { receipts, fetchReceipts, voidReceipts } = useFetchReceipt();
  // const [branch, setBranch] = useState(null);

  const { branch } = props;

  useEffect(() => {
    fetchReceipts(props.user.shopId, {
      timestamp: moment().format("YYYY-MM-DD")
    });
  }, []);

  const [dateObj, setDateObj] = useState(new Date());
  const getCurrentBranch = () => {
    if (branch && branch.value === "all") {
      return props.user.shopId;
    }
    if (branch) {
      return branch.value;
    } else {
      return props.user.shopId;
    }
  };
  const setShowDate = dateObj => {
    const date = dateObjToString(dateObj);
    setDateObj(dateObj);
    fetchReceipts(getCurrentBranch(), {
      timestamp: date,
      hqUid: branch && branch.value === "all" ? props.user.shopId : null
    });
  };

  const printThermalReport = () => {
    let print = new PrintReportSummary(props.dispatch, props.shop, receipts);
    print.build().print();
  };
  // branchOptions
  const handleChangeBranch = branch => {
    const date = dateObjToString(dateObj);
    // setBranch(branch);
    fetchReceipts(branch.value, {
      timestamp: date,
      hqUid: branch.value === "all" ? props.user.shopId : null
    });
  };

  useEffect(() => {
    branch && handleChangeBranch(branch);
  }, [branch]);

  const Table = ({ data }) => {
    return (
      <table className="table table-striped table-borderless">
        <thead>
          <tr>
            <th>Date</th>
            {branch && branch.value === "all" && <th>Branch</th>}
            {/* <th>Start-End</th> */}
            <th>Member</th>
            <th>Therapist</th>
            <th>Grand Total</th>
            {/* <th className="number">Sales</th> */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="no-border-x">
          {_.map(data, rec => {
            // let meta = rec.meta || {};

            return (
              <tr>
                <th scope="row">
                  {moment(rec.timestamp).format("DD/MM/YYYY HH:mm A")}
                </th>
                {branch && branch.value === "all" && <td>{rec.store.name}</td>}
                <td className="cell-detail">
                  <span>{rec.member && rec.member.name}</span>
                </td>
                <td className="cell-detail">
                  <span>{rec.therapist && rec.therapist.name}</span>
                </td>
                <td>
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    {(rec.payment.grandTotal || 0).toLocaleString()}
                  </span>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setModalContent(rec);
                    }}
                    data-toggle="modal"
                    data-target="#spaBillModal"
                  >
                    View
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <SpaBillModal {...props} {...modalContent} />

      <div className="card card-table">
        <div className="card-header p-0 m-2">
          {/* Striped Tables */}
          <div className="row m-0 justify-content-between">
            <DatePicker
              className="form-control"
              selected={dateObj}
              onChange={setShowDate}
              dateFormat="dd/MM/yyyy"
              id="date-pick"
              style={{ width: 200 }}
            />
            <div>
              <button
                className="btn btn-space btn-lg btn-secondary ml-2"
                onClick={printThermalReport}
                disabled={_.size(receipts) < 1}
              >
                Print Summary Thermal
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <Table data={receipts} />
          {voidReceipts.length > 0 && (
            <div>
              {receipts.length > 0 && <div class="dropdown-divider m-0" />}
              <div style={{ paddingLeft: 15, color: "red", fontSize: 22 }}>
                Void
              </div>
              <Table data={voidReceipts} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const dateObjToString = date => {
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  const dateString = `${date.getFullYear().toString()}-${month.toString()}-${
    date.getDate() < 10
      ? "0" + date.getDate().toString()
      : date.getDate().toString()
  }`;
  return dateString;
};

export default Receipts;
