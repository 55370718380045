import _ from "lodash";
import moment from "moment";
export const groupPaymentTypes = receipts => {
  let res = _.reduce(
    receipts,
    (acc, r) => {
      _.forEach(r.payment.paymentTypes, p => {
        let keyName = p.mode ? p.name + `  (${p.mode})` : p.name;
        acc[keyName] = acc[keyName] || [];
        acc[keyName].push(p);
      });

      return acc;
    },
    {}
  );

  return res;
};

export const groupByDate = (receipts, node) => {
  let tmp = _.reduce(
    receipts,
    (acc, r) => {
      // filter out
      if (!r[node]) {
        return acc;
      }
      let key1 = (r[node] && r[node]["name"]) || "unknown"; //name
      let key2 = moment(r.timestamp).format("DD-MM-YYYY"); // date

      if (acc[key1] === undefined) {
        acc[key1] = {};
      }

      if (acc[key1][key2] === undefined) {
        acc[key1][key2] = {
          date: key2,
          receipts: []
        };
      }
      acc[key1][key2]["receipts"].push(r);
      return acc;
    },
    {}
  );

  return _.reduce(
    tmp,
    (acc, dates, groupName) => {
      let array = [];

      _.forEach(dates, (row, keyDate) => {
        array.push(row);
      });
      array = _.sortBy(array, "date");

      acc[groupName] = array;

      return acc;
    },
    {}
  );
};

export const extractMinsFromSingleTherapist = receipt => {
  let forceTimeMinutes = 0;
  _.forEach(receipt.items, item => {
    if (!item.timeMinutes) return;
    forceTimeMinutes += item.qty * item.timeMinutes;
  });

  return {
    name: receipt.therapist.name,
    time: forceTimeMinutes
  };
};

export const extractMinsFromMultipleTherapist = receipt => {
  var combinedTherGroup = {};

  _.forEach(receipt.therapistList, ther => {
    if (!combinedTherGroup[ther.name]) {
      combinedTherGroup[ther.name] = {
        time: 0,
        count: 0,
        name : ther.name
      };
    }
    //COMPUTE TIME
    let forceTimeMinutes = 0;
    _.forEach(ther.items, item => {
      if (!item.timeMinutes) return;
      forceTimeMinutes += item.qty * item.timeMinutes;
    });
    combinedTherGroup[ther.name].time += forceTimeMinutes;
    combinedTherGroup[ther.name].count++;
  });

  return combinedTherGroup;
};


export const getTherapistList = (receipt) =>{
  let nameList = _.reduce(
    receipt.therapist,
    (acc, course) => {
      _.forEach(course.therapists, ther => {
        let name = ther.name  
        if(ther.required && receipt.therapistMode == 'multiple' ){
          name = name +  " (requested)"
        }

        if(receipt.therapistMode == 'single' && receipt.requireEmp  ){
          name = name +  " (requested)"
        }

        acc.push(name);
      });
      return acc;
    },
    []
  );
  return nameList = _.uniq(nameList);
}