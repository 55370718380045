import React, { useState, useEffect } from "react";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import _ from "lodash";
// import moment from "moment";
import Async from "react-select/async";
// import Select from "react-select";

function Flow_Customer(props) {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, selectCustomer] = useState(null);

  const { fetchVoucherList, setMatchVoucher } = props;

  const asyncSearchCustomer = (inputName, callback) => {
    let params = {
      TableName: "Crm_Users",
      IndexName: "hqUid-index",
      KeyConditionExpression: "hqUid = :hqUid",
      FilterExpression: "contains(#name, :name)",
      ExpressionAttributeNames: {
        "#name": "name"
      },
      ExpressionAttributeValues: {
        // ":name": inputName.toLocaleLowerCase()
        ":name": (inputName || "").toUpperCase(),
        ":hqUid": props.linked
      }
    };

    documentClient.query(params, function(err, data) {
      if (err) return console.log(err);
      let ordered = _.orderBy(data.Items, "createAt", "desc");
      let options = getOptions(ordered);
      callback(options);
    });
  };
  function getOptions(list) {
    let options = [];
    _.map(list, i => {
      options.push({
        ...i,
        value: i.uid,
        label: i.name
      });
    });
    return options;
  }

  const handleSelectCustomer = customer => {
    fetchVoucherList(customer.uid, {
      onlyActive: true,
      onlyItem: true,
      owner: customer,
      stopOnFail: true,
      cb: () => {
        setMatchVoucher({});
        setTimeout(() => {
          setMatchVoucher(null);
        }, 300);
      }
    });

    // selectCustomer(customer);
  };

  return (
    <div className="row m-0">
      <div className="col-6 pl-0">
        <Async
          value={selectedCustomer}
          onChange={handleSelectCustomer}
          // options={[{ label: "+ Create Customer", value: "create" }, ...customers]}
          // onMenuOpen={fetchCustomers}
          cacheOptions
          // loadOptions={loadOptions}
          loadOptions={asyncSearchCustomer}
          // defaultOptions={[
          //   { label: "+ Create Customer", value: "create" },
          //   ...customers
          // ]}
          defaultOptions={customers}
          // onInputChange={handleSelectCustomer}
          placeholder={"ค้นหาเจ้าของ Voucher"}
        />
      </div>
    </div>
  );
}

export default Flow_Customer;
