import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
// memberUid-timestamp-index

function MemberReceipt(props) {
  const { receipts, setModalContent } = props;
  return (
    <div class="card card-table">
      {/* <div class="card-header">Receipts</div> */}
      <div class="card-body">
        <div
        //   style={{
        //     height: 500,
        //     overflowY: "auto"
        //   }}
        >
          <table
            class="table table-striped table-borderless"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th style={{ width: "30%" }}>Timestamp</th>
                <th>Grand Total</th>
                <th>Therapist</th>
                <th>Store</th>
                <th class="actions"></th>
              </tr>
            </thead>

            <tbody class="no-border-x">
              {_.map(receipts, rec => {
                return (
                  <tr>
                    <td>{moment(rec.localTime).format("DD/MM/YYYY HH:mm")}</td>
                    <td>{(rec.payment.grandTotal || 0).toLocaleString()}</td>
                    <td>{getTherapist(rec)}</td>
                    <td>{rec.store.name}</td>
                    <td class="actions">
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#spaBillModal"
                        onClick={e => {
                          e.preventDefault();
                          setModalContent(rec);
                        }}
                      >
                        View
                      </a>
                    </td>
                  </tr>
                );
                // });
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const getTherapist = lineData => {
  let therapistObj = {};
  let therapistElement = [];

  _.forEach(lineData.therapist, course => {
    _.forEach(course.therapists, ther => {
      if (!therapistObj[ther.name]) {
        therapistObj[ther.name] = true;
        therapistElement.push(<div>{ther.name}</div>);
      }
    });
  });

  if (therapistElement.length < 1) {
    return (
      <span style={{ color: "red" }}>
        <b>No Therapist</b>
      </span>
    );
  } else {
    return therapistElement;
  }
};

export default MemberReceipt;
