import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  ReceiptRow,
  ReceiptHeader,
  ReceiptGroup,
  ReceiptGroupRow,
  ReceiptGroupRow2
} from "./ReceiptRow";
import { Radio, useRadioViewButtons } from "./Components";
import { GroupDateTable } from "./ReceiptGroupDateRow";
import { groupByDate } from "../../../shared/utils/receipts";
import * as appStateAction from "../../../shared/actions/appStateActions";
import axios from "axios";
import { downloadableLinkUrl } from "../../../shared/utils";
import { useDispatch } from "react-redux";
import Select from "react-select";

function DashboardAgents(props) {
  const { receipts, dashboardDispatch } = props;
  const [agentGroups, setAgentGroups] = useState([]);
  const [therapistGroupsByDate, setTherapistGroupsByDate] = useState({});
  const dispatch = useDispatch();

  const [view, Component] = useRadioViewButtons("summary", { groupDate: true });
  const [filterAgent, setFilterAgent] = useState([]);

  function viewCallback(receipt) {
    dashboardDispatch({
      type: "SET_MODAL",
      payload: {
        data: receipt,
        visible: true
      }
    });
  }

  useEffect(() => {
    if (view === "date") {
      let res = groupByDate(receipts, "agent");
      setTherapistGroupsByDate(res);
    }
  }, [view, receipts]);

  useEffect(() => {
    let _res = computeGroup(receipts);
    setAgentGroups(_res);
  }, [receipts]);

  const downloadPaymentAgentPdf = () => {
    let URL =
      "https://asia-east2-niceloop-dev.cloudfunctions.net/PDFSummaryByDate";
    let data = {
      mode: "Company",
      startDate: props.startDate,
      endDate: props.endDate,
      shopId: props.shop.uid,
      filter: filterAgent.length > 0 ? filterAgent : null
    };

    dispatch(appStateAction.loading(true));

    axios
      .post(URL, data)
      .then(res => {
        downloadableLinkUrl("report-summary-excel.xlsx", res.data);
      })
      .catch(err => alert(err))
      .finally(() => {
        dispatch(appStateAction.loading(false));
      });
  };

  return (
    <div class="">
      <div class="col-lg-12">
        <div class="card card-table">
          <div class="card-header">
            {Component}
            Group by {_.keys(agentGroups).length} Agents
            <span style={{ marginLeft: 36, color: "blue" }}>
              found {_.reduce(agentGroups, (acc, v) => acc + v.length, 0)} bills
              from {receipts.length} bills
            </span>
            <div className="row m-0">
              <div className="col-6 pl-0">
                <Select
                  isMulti
                  value={filterAgent}
                  onChange={setFilterAgent}
                  options={props.agentOptions}
                  placeholder="All"
                />
              </div>
              <div>
                <button
                  class="btn btn-space btn-secondary"
                  onClick={downloadPaymentAgentPdf}
                >
                  Export PDF
                </button>
              </div>
            </div>
          </div>
          <div class="card-body pb-7">
            {view !== "date" && (
              <table class="table table-small table-striped">
                {view === "detail" && (
                  <ReceiptHeader showAgent={false} showTherapist={false} />
                )}
                <tbody>
                  {_.map(agentGroups, (receipts, gName) => {
                    return [
                      <ReceiptGroupRow2
                        name={gName}
                        col1Text={
                          "GrandTotal : " +
                          _.sumBy(receipts, "payment.grandTotal") +
                          ` (${receipts.length})`
                        }
                      />,
                      view === "detail" &&
                        _.map(receipts, (r, index) => (
                          <ReceiptRow
                            viewCallback={() => viewCallback(r)}
                            showAgent={false}
                            showTherapist={false}
                            linkToKey="cityLedger"
                            showVoidButton={false}
                            index={index + 1}
                            {...r}
                          />
                        ))
                    ];
                  })}
                </tbody>
              </table>
            )}

            {view === "date" && [
              <div class="row">
                <div class="col-2"> </div>
                <div class="col-8">
                  {" "}
                  <GroupDateTable
                    showPaymentType={true}
                    groupReceiptsByDateHolder={therapistGroupsByDate}
                  />{" "}
                </div>
              </div>
            ]}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardAgents;

function computeGroup(receipts) {
  let onlyAgentReceipts = _.filter(receipts, r => {
    let found_agent = _.some(r.payment.paymentTypes, { type: "cityLedger" });
    return found_agent;
  });

  return _.groupBy(onlyAgentReceipts, r => {
    let found_agent = _.find(r.payment.paymentTypes, { type: "cityLedger" });

    return found_agent.payload.name || "UNKNOWN";

    // return (r.agent && r.agent.name) || "UNKNOWN";
  });
}
