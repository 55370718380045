import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";

function SummaryTable(props) {
  // // <div className="row m-0 mt-2">
  // {
  //   /* <div className="col-8 offset-2"> */
  // }
  // {
  //   /* <div className="col-12"> */
  // }
  return (
    <div>
      <div>
        <div className="card card-table">
          {/* <div className="card-header p-0 m-0">
       
          </div> */}
          <div className="card-body">
            <table className="table table-striped table-borderless">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Grand Total</th>
                  {/* <th className="number">Sales</th> */}
                  {/* <th className="actions" /> */}
                </tr>
              </thead>
              <tbody className="no-border-x">
                {_.map(props.computedReceipts, rec => {
                  return (
                    <tr
                      style={
                        checkWeek(rec.timestamp)
                          ? {
                              borderTop: "solid",
                              borderTopColor: "lightgrey",
                              borderWidth: 3
                            }
                          : {}
                      }
                    >
                      <th scope="row">
                        {moment(rec.timestamp).format("DD MMMM YYYY ddd")}
                      </th>
                      <td>
                        <span style={{ color: "blue", fontWeight: "bold" }}>
                          {convertNumber(rec.grandTotal)}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SummaryTable;

const checkWeek = date => {
  let border = false;
  let date2 = moment(date).format("DD/MM/YYYY ddd");
  let startLength = date2.length - 3;
  let dateName = date2.slice(startLength, date2.length);

  if (dateName === "Sun") {
    border = true;
  }

  return border;
};

const convertNumber = number => {
  return parseInt((number || 0).toFixed(0)).toLocaleString();
};
