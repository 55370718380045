import React, { useState, useEffect } from "react";
import * as receiptsService from "../services/receipts";
import { useSelector } from "react-redux";

function useReceiptsHook(startDate, endDate) {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const { shop, user } = useSelector(state => {
    let shop = state.hqReport.viewBranch || state.shop.detail; //HQ or Local

    return {
      user: state.user.loggedIn,
      shop
    };
  });

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    //todo
    setLoading(true);
    let _receipts = await receiptsService.fetchBusinessDate(
      shop.uid,
      startDate,
      endDate
    );

    setData(_receipts);
    setLoading(false);
  }

  return {
    data,
    isLoading
  };
}

export default useReceiptsHook;
