import * as inventorySelector from "../shared/selector/inventorySelector";
import documentClient from "../shared/awsAPIs/dynamoDb";
import _ from "lodash";
import moment from "moment";
import randomUid from "../shared/randomUid";
import mock  from './mock'

// BRANCHES
//SOMKID - Scentuara
let branchScentuara = {
  alias: "SMKD",
  shippingAddress:
    "บริษัท ดีวานา เวลเนสจำกัด (สาขาที่4)\n\nเลขที่ 16/15 ซอยสมคิด\nแขวงลุมพินี เขตปทุมวัน\nกรุงเทพฯ 10330\nโทร +66(0)63474 6566\nEmail: ds@divana-dvn.com",
  province: "Bangkok",
  vat: {
    mode: 0,
    value: null
  },
  link: {
    inventory: "03577879"
  },
  name: "DIVANA SCENTUARA",
  uid: "32276347",
  branchId: "0001",
  tags: null,
  value: "32276347",
  label: "0001 - DIVANA SCENTUARA"
};

//VIRTUE
let branchVirtue = {
  alias: "dv",
  shippingAddress:
    "บริษัท ดีวานา เวลเนส จำกัด (สาขาที่2)\n\nบ้านเลขที่ 10 ถนน ศรีเวียง\nแขวง สีลม เขต บางรัก \n\nTax Id : 0105561179224",
  province: "Bangkok",
  link: {
    inventory: "03577879"
  },
  name: "DIVANA VIRTUE",
  uid: "50831513",
  branchId: "DV_VIRTUE",
  tags: null,
  value: "50831513",
  label: "DV_VIRTUE - DIVANA VIRTUE"
};

//NURTURE
let branchNurture = {
  alias: "dn",
  shippingAddress:
    "บริษัท ดีวานา เวลเนส จำกัด (สาขาที่3)\n\nเลขที่ 71 ซอยสุขุมวิท 13 \nแขวงคลองเตยเหนือ เขตวัฒนา\nกรุงเทพมหานคร\n\nTax Id : 105561179224",
  province: "Bangkok",
  link: {
    inventory: "03577879"
  },
  name: "DIVANA NURTURE SPA",
  uid: "76132710",
  branchId: "dn",
  tags: null,
  value: "76132710",
  label: "dn - DIVANA NURTURE SPA"
};

//DIVINE
let branchDivine = {
  alias: "dd",
  shippingAddress:
    "ดีวานา เวลเนส จำกัด (สาขาที่1)\n\nเลขที่ 103 ซ.อรรคพัฒน์\nแขวง คลองตันเหนือ\nเขต วัฒนา กรุงเทพ\n\nTax Id : 105561179224",
  province: "Bangkok",
  link: {
    inventory: "03577879"
  },
  hide: false,
  name: "Divana Divine ",
  uid: "01928552",
  branchId: "dd",
  tags: null,
  value: "01928552",
  label: "dd - Divana Divine "
};

//Dii
let branchDii = {
  alias: "Dii",
  shippingAddress:
    "Divana Clinique Co., Ltd. (สาขาที่4)\n\n1031 อาคารศูนย์การค้า Central Embassy \nชั้น 4 ห้องเลขที่ L4-02 ถนนเพลินจิต \nแขวงลุมพินี เขตปทุมวัน กรุงเทพฯ 10330\n\nTax Id : 0105554006841",
  province: "Bangkok",
  link: {
    inventory: "03577879"
  },
  name: "Dii wellness med spa",
  uid: "05593962",
  branchId: "dii",
  tags: null,
  value: "05593962",
  label: "dii - Dii wellness med spa"
};

function getTher(id = "", name = "", branch) {
  return {
    name,
    id,
    branch
  };
}
function getTherData(ther, idx) {
  let therTemplate = {
    row: moment().unix() + idx,
    entityUid: "03577879", //divanahq = 03577879, Niceloop_00 = 3180
    uid: ther.id.toString() || randomUid(6),
    name: ther.name, //TODO: dynamic
    id: ther.id, //TODO: dynamic
    // mobile: null,
    // email: null,
    // commissionRate: null,
    branch: ther.branch, //TODO: dynamic
    branchUid: ther.branch.uid, //TODO: dynamic
    flag: "2020" //TODO: FLAG
  };

  return therTemplate;
}

//THERAPISTS
let therVirtue = [
  getTher("20002", "JARIYA NGAMKHAM(YA)", branchVirtue),
  getTher("20003", "PHANSA ARWUTPHAN (JEAW))", branchVirtue),
  getTher("20005", "SAIPIN SUDSENPHOM (TUEN)", branchVirtue),
  getTher("20014", "BULALAY SAMORN (PONG)", branchVirtue),
  getTher("20021", "LADDAWAN BATTANEE(PLE)", branchVirtue),
  getTher("20019", "CHOTIKA PERKKHUNTOD (YAO)", branchVirtue),
  getTher("20016", "KANNIKA AKKARAWONG (JEAB)", branchVirtue),
  getTher("40009", "ARTHITTAYA SREERAT (NOY)", branchVirtue),
  getTher("20041", "CHIRAPHON KANTABUN (POND)", branchVirtue),
  getTher("20042", "NADLADA SOPA (DANG)", branchVirtue),
  getTher("20043", "MADTHANA THONGKASAENG (CARE)", branchVirtue),
  getTher("20046", "NATTWADEE SUKNIYOM (NAT)", branchVirtue),
  getTher("20052", "MATTANA CHANTHONG (NOI)", branchVirtue),
  getTher("40046", "NOPPAMAS TANGAON (NOP)", branchVirtue),
  getTher("20056", "ROTCHANA SADAO (RIN)", branchVirtue),
  getTher("20068", "NATTAKITTA KANTONG (DAO)", branchVirtue),
  getTher("20078", "SOMJIT CHUAIPHEN (JIT)", branchVirtue),
  getTher("20079", "ARUNEE JAMKANG (YO)", branchVirtue),
  getTher("20158", "NIPAPOND KOTA (AOI)", branchVirtue),
  getTher("20246", "THONGTHIP NILTHAI (LEUN)", branchVirtue),
  getTher("80255", "WISUDA DUENGJAI (FON)", branchVirtue),
  getTher("80266", "JANJIRA DEENAK (JANE)", branchVirtue),
  getTher("80273", "RATTANAWAREE (KAY )", branchVirtue),
  getTher("80287", "JIRAPORN MUENGSON (KAI) ", branchVirtue),
  getTher("20273", "CHIRAPORN WAMALUM (KIP)", branchVirtue),
  getTher("80287", "KANNIKA JANCHUEA (KAN)", branchVirtue),
  getTher("80307", "ATANYA JANTIMA (LAI)", branchVirtue)
];

let therScentuara = [
  getTher("10003", "น.ส.ชุติมา พันธุ์ชา (ก้อย)", branchScentuara),
  getTher("10004", "นางอังคณา มารวย (นวย)", branchScentuara),
  getTher("10005", "นางพรนภา บาศรี (นภา)", branchScentuara),
  getTher("10007", "นางพนิดา ยังมั่ง (แก้ว)", branchScentuara),
  getTher("10010", "น.ส.สมคิด จีนเมือง (คิด)", branchScentuara),
  getTher("10017", "น.ส.ลัดดา อัครวงค์  (หน่อย)", branchScentuara),
  getTher("10020", "นางธัญญาพร หอมจันทร์ (แต)", branchScentuara),
  getTher("10021", "น.ส.พรทิพย์พา โคตนนท์ (ยอม)", branchScentuara),
  getTher("10022", "น.ส.จิรสุตา ธรรมรี(สัง)", branchScentuara),
  getTher("10026", "นางสุรัตดา วิทยา (ปาน)", branchScentuara),
  getTher("10027", "นางสา สายน้อย (สา)", branchScentuara),
  getTher("10028", "น.ส.ดวงพร หวินกำปัง (นาง)", branchScentuara),
  getTher("10029", "น.ส.กรรณิการ์ นามวงศ์ (เล็ก)", branchScentuara),
  getTher("10030", "น.ส.ชุติมา ชูโชติ (หน่อย) (ชุ)", branchScentuara),
  getTher("20009", "น.ส.สุชานันท์ จังพล (เดือนฉาย)", branchScentuara),
  getTher("10034", "น.ส.ยุพา แววดี (ยุ)", branchScentuara),
  getTher("10052", "น.ส.สมจิตร นากลาง (จุ๋ม)", branchScentuara),
  getTher("10059", "น.ส.เขมิกา กันฑ์อเนก (เข็ม)", branchScentuara),
  getTher("10058", "นางทองคำ ไชยมงคล (คำ)", branchScentuara),
  getTher("10062", "น.ส.สุกัญญา รจนัย (ยา)", branchScentuara),
  getTher("10064", "นางสาวจริยาภรณ์ สฤษชสมบัติ (ส้ม)", branchScentuara),
  getTher("10083", "นางสาวนิศาชน ยาทะเล (มะปราง)", branchScentuara),
  getTher("10084", "นางสาวดาววิภา แสวงมงคล (ดาว)", branchScentuara),
  getTher("80283", "นางสาวจิราวรรณ ตาโส (ลักษณ์)", branchScentuara),
  getTher("80284", "นางสาววาสนา เมืองโครต (กุ้ง)", branchScentuara),
  getTher("80285", "นางสาวรมย์ชลี สิทธิวงษา (บัว)", branchScentuara),
  getTher("80286", "นางสาวฐิติกาญจน์ คนไว (หยก)", branchScentuara),
  getTher("80274", "นางสาวปานตา ตั้งใจตรง (ปานตา) โรงเรียน", branchScentuara),
  getTher("80302", "สิบเอกหญิงรัชดา แก้วมา (ดา)", branchScentuara),
  getTher("10127", "นางสาวดวงฤทัย แสงสกุล (บุ๋ม)", branchScentuara),
  getTher("80303", "นางสาวธนพร  ยิ้มย่อง (ต่าย)", branchScentuara),
  getTher("80314", "นางสาวปรัญดา จันทการย์เจริญ (อ้อม)", branchScentuara),
  getTher("80315", "นางสาวพัชราวรรณ  ปัญโญ (หญิง)", branchScentuara),
  getTher("80316", "นางสาวญาสุรีย์ พัชรนันทวัจน์ (ญาญ่า)", branchScentuara)
];

let therNurture = [
  getTher("10014", "นางสงกรานต์  ทาดี (กรานต์)", branchNurture),
  getTher("10015", "นางน้ำผึ้ง  อินธักสมบัติ(ผึ้ง)", branchNurture),
  getTher("40012", "น.ส.เนตรดาว บุตรวิชา (ดาว)", branchNurture),
  getTher("40013", "น.ส.รัตนา ศรีฉายา (รัตน์)", branchNurture),
  getTher("10041", "น.ส.ธัญญาลักษณ์ ศรีสุข (อ้อน)", branchNurture),
  getTher("70020", "นางสุนทรี โพธิ์ชัย  (ปุ้ย)", branchNurture),
  getTher("70007", "น.ส.พรทิพย์ บัวพา(ปุ๊ก)", branchNurture),
  getTher("70055", "น.ส.สุภาพร ปทุมทอง (พร)", branchNurture),
  getTher("70036", "น.ส.ฟองรัตน์ดา พิเนตร (แนน)", branchNurture),
  getTher("70038", "น.ส.ภาวิณี ดวงนิล ( แวว )", branchNurture),
  getTher("70037", "น.ส.สุธาทิพย์ กุณะ (สุ)", branchNurture),
  getTher("70073", "น.ส. พรพนา คำพา  (นา)", branchNurture),
  getTher("70074", "น.ส.โสภา  เบ็นหะซัน (สะมะ)", branchNurture),
  getTher("70081", "น.ส.ประไพรภรณ์ ใจเอื้อ (สน)", branchNurture),
  getTher("20088", "น.ส. ธัญญา บุญเรือง (ไก่)", branchNurture),
  getTher("70090", "น.ส. แคทรียา เอกสานิชชา (แคท)", branchNurture),
  getTher("10089", "นางสาวนวลละออ หมื่นจินะ (นวล)", branchNurture),
  getTher("10087", "นางสาวรัตนา สมานมาก ( บัว)", branchNurture),
  getTher("10097", "นางสาวขนิษฐา สังข์เมือง (ยุ้ย)", branchNurture),
  getTher("10106", "นางสาวอริศรา ศรีจองแสง (จง)", branchNurture),
  getTher("20236", "นางสาวลักษวรรณ ลาหมื่นกู่ (ฟ้า)", branchNurture),
  getTher("10128", "นางสาวพัทราพร ภูเลี่ยมคำ (แจง)", branchNurture),
  getTher("10138", "นางสาวเพียงอัมพร เสนกพงษ์ (เพียง)", branchNurture),
  getTher("10137", "นางสาวเกศรินทร์ แก้วขันที (เกตุ)", branchNurture),
  getTher("80245", "นางสาวมาริณี พรมสี (ณี)", branchNurture),
  getTher("80248", "นางสาวประนิดาวรรณ หมั่นสกุล (วรรณ)", branchNurture),
  getTher("80243", "นางสาวจิรประภาพร รังสิริ (ตาล)", branchNurture),
  getTher("80241", "นางสาวทิวารี เพลิดพราว (แน็ค)", branchNurture),
  getTher("80256", "นางสาวอารยา ใจลึก (เกมส์)", branchNurture),
  getTher("80268", "นางสาววรรณภา ทองพรม (รัก)", branchNurture),
  getTher("80270", "นางสาวศิรินทร์กิตติยา นวนจันทร์ (จอย)", branchNurture),
  getTher("80267", "นางสาวรวิวรรณ เปรมเพชร (กุ้งกิ้ง)", branchNurture),
  getTher("80305", "นางสาวจีรนันท์ ถึกหมื่นไวย์ (แพรว)", branchNurture),
  getTher("80304", "นางสาวพีรมณฑ์ ทองปั้น (เป้ย)", branchNurture),
  getTher("80269", "นางสาวอริยาภรณ์ ครองราชย์ (หนุงหนิง)", branchNurture),
  getTher("20082", "น.ส.อนุสรณ์  แก้วคำหอม (ป่าน)", branchNurture)
];

let therDivine = [
  getTher("20004", "น.ส.ณัชยาภรณ์ พันธุรา (เล็ก)", branchDivine),
  getTher("20008", "น.ส.สีแพร ไกรสร (แพร)", branchDivine),
  getTher("20013", "น.ส.สุนิศา วงอินทร์ (สา)", branchDivine),
  getTher("20025", "น.ส.อุทัย คลังแก้ว (ทัย)", branchDivine),
  getTher("20034", "น.ส.โสภา มีเลิศ (โสภา)", branchDivine),
  getTher("20036", "นางวราพร    พลเคน (พร)", branchDivine),
  getTher("20038", "น.ส.โสรยา สุขแสวง (เปิ้ล)", branchDivine),
  getTher("20031", "น.ส.จันทนา หมายสุข (ต๋อง)", branchDivine),
  getTher("20085", "นางสาวเพ็ญจันทร์ จำปาหอม (พลอย (จันทร์)) ", branchDivine),
  getTher("20086", "นางสาวณชนก  ตาติจันทร์ (ติ๊ก)", branchDivine),
  getTher("20199", "นางสาวจุฑาทิพย์ มาเยอะ (มาลี)", branchDivine),
  getTher("20200", "นางสาวแพรวนภา  นนทพรม (แพรว)", branchDivine),
  getTher("20033", "น.ส.ปิณฑิรา  เกษกำแพง (เหว่า)", branchDivine),
  getTher("80282", "นางสาวสุพัดตรา คำมาดี (ตาล)", branchDivine),
  getTher("80260", "นางสาวกนกรัตน์ รักขะภัย (เมย์) โรงเรียน", branchDivine),
  getTher("80246", "นางสาวจุรีลักษณ์  ยอดน้ำคำ (ปู) โรงเรียน", branchDivine),
  getTher("80281", "นางสาว ภาวินี โหมฮึก (นก)", branchDivine),
  getTher("20029", "น.ส.ยุพักตร์  แคนติ (ภัค)", branchDivine)
];

let therDii = [
  getTher("50007", "ชนิสรา ทรัพย์ละมุลสุข (ทิพย์)", branchDii),
  getTher("50014", "ประกาย ชัยลา (ช่อ)", branchDii),
  getTher("50055", "พรรณนิภา ฤาชาบุตร (ปั๊ป)", branchDii),
  getTher("50059", "โศภิน วงศ์พรม (นิว)", branchDii),
  getTher("10016", "น.ส.วรัญญา เพียรเสมอ (ญา)", branchDii)
];

//Use this
let allTher = [
  ...therVirtue,
  ...therScentuara,
  ...therNurture,
  ...therDivine,
  ...therDii
];

// export const createDivanaTherapist = () => {
//   _.forEach(allTher, (ther, idx) => {
//     // getTherData
//     var params = {
//       TableName: "Spa_Employees",
//       Item: getTherData(ther, idx)
//     };
//     documentClient.put(params, err => {
//       if (err) console.log(err);
//     });
//   });
// };

export const repostReceipt = async (isDelete) => {
  //fetch

  const RECEIPT_ID_START  = "2020-07-0427"
  const RECEIPT_ID_END  = "2020-07-0430"

  const OLD_BUSINESS_DATE  = "2020-07-10"
  const NEW_BUSINESS_DATE  = "2020-07-09"
  const STORE_UID = "32276347" // somkid

  //
  let res = await fetch(STORE_UID, OLD_BUSINESS_DATE);
  debugger;
  res = _.filter(
    res,
    r => r.receiptId >= RECEIPT_ID_START && r.businessDate == OLD_BUSINESS_DATE &&r.receiptId <= RECEIPT_ID_END 
  );
 

  console.log(res);
  window.aaa = res;
 
  if (true) {

    _.forEach(res, r => {
      
        let timestampOld = r.timestamp;
        let timestampNew = r.timestamp.replace(OLD_BUSINESS_DATE, NEW_BUSINESS_DATE);
      //# re-post
      var params = {
        TableName: "Spa_Receipts",
        Item: {
          ...r,
          timestamp: timestampNew,
          businessDate: NEW_BUSINESS_DATE,
          flag : 'edited'
        }
      };
      let data = documentClient.put(params).promise();

      
      //## delete
      var params = {
        TableName: "Spa_Receipts",
        Key: {
          storeUid: r.storeUid,
          timestamp: timestampOld
        }
      };
      let data2 = documentClient.delete(params).promise();

     });
  }
};

async function fetch(storeUid, date) {
  var params = {
    TableName: "Spa_Receipts",
    KeyConditionExpression: "#hkey = :hkey  AND  begins_with(#timestamp, :date)",
    // FilterExpression: "version = :version",
    ExpressionAttributeNames: {
      "#hkey": "storeUid",
      '#timestamp': 'timestamp',
    },
    ScanIndexForward: false,
    ExpressionAttributeValues: {
      ":hkey": storeUid,
      ":date": date
    }
  };
  var lasyKey = null;
  var res = [];
  do {
    let _last = lasyKey ? (params.ExclusiveStartKey = lasyKey) : null;
    var data = await documentClient.query(params).promise();
    res = res.concat(data.Items);
    lasyKey = data.LastEvaluatedKey;
  } while (data.LastEvaluatedKey);

  return res;
}

// window.createDivanaTherapist =createDivanaTherapist;
// window.allTher = allTher

window.repostReceipt = repostReceipt;
