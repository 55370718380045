import React, { useState , useReducer} from "react";
import _ from "lodash";
import { connect } from "react-redux";
import useFetchDynamodb from "../../shared/useHook/useFetchDynamodb";
import * as aws from "../../shared/awsAPIs/instance";

function hookReducer(state = {}, action) {

    // let isAlready = state[action.payload] ? true : false;
    // if(isAlready){
    //    return  _.omit(state, action.payload)
    // }
     
  switch (action.type) {
    case "mode_1": // all sub folders
      return { ...state, [action.payload]: 1 };
    case "mode_2": // custom
      return { ...state, [action.payload]: 2 };
    case "mode_0": // off nothing
      return { ...state, [action.payload]: null };
    case "restore": // from database
      return action.payload;
    default:
      break;
  }
}

function SpaAgentItems({ types, groups, items, shopUid, hqUid }) {
  const [uidHolder, dispatch] = useReducer(hookReducer, {});

  useFetchDynamodb("Spa_Agents_Items", hqUid, 'default', (items)=>{

    let res= {}
    if(items[0]){
        res = items[0].data
    }
    dispatch({
        type : 'restore',
        payload : res
    })
  })

  async function  save (){
      var params = {
      TableName : 'Spa_Agents_Items',
      Item : {
         'entityUid': hqUid,
         'name' : 'default',
         'data' : uidHolder,
      }
      };
      let data = await aws.documentClient.put(params).promise()
      alert('save completed');
  }

  return (
    <div class="main-content row">
      <div class="col-md-8 offset-md-4">
        <h1>SPA agent Items
          
        <button class="btn btn-space btn-primary" onClick={save}>Save</button>
           </h1>
      </div>

      <div class="col-md-8 offset-md-2">
        <table class='table table-sm table-striped'>
          <tbody>
            {_.reduce(
              types,
              (acc, t) => {
                acc.push(<TrLine line={t} tab={0} uidHolder={uidHolder} dispatch={dispatch}/>);
                if (uidHolder[t.uid] === 2  ) {
                  let v = _.filter(groups, g => g.type === t.uid);
                  _.forEach(v, g => {
                    acc.push(<TrLine line={g} tab={1}  uidHolder={uidHolder} dispatch={dispatch}/>);

                    if (uidHolder[g.uid] === 2  ) {
                        let its = _.filter(items, it => it.groupUid === g.uid);
                        _.forEach(its, (it)=>{
                            acc.push(<TrLine line={it} tab={2} uidHolder={uidHolder} dispatch={dispatch}/>);
                        })
                    }
                  });   
                }

                return acc;
              },
              []
            )}
          </tbody>
        </table>
      </div>

     
    </div>
  );
}

function TrLine({ line: t, tab , uidHolder, dispatch}) {
  return (
    <tr>
      <td> 
        { tab === 0 && <h3 class="mt-3">{t.name}</h3>}
        { tab === 1 &&<h4>{t.name}</h4>}
        { tab === 2 &&<p>{t.name}</p>}
       </td>
      {/* <td>
        <div class="form-group row pt-1 pb-1">
          <div class="col-12   form-check mt-1">
            <label class="custom-control custom-radio custom-control-inline">
              <input
                class="custom-control-input"
                type="radio"
                name="radio-inline"
                checked=""
              />
              <span class="custom-control-label">Option 1</span>
            </label>
            <label class="custom-control custom-radio custom-control-inline">
              <input
                class="custom-control-input"
                type="radio"
                name="radio-inline"
              />
              <span class="custom-control-label">Option 2</span>
            </label>
            <label class="custom-control custom-radio custom-control-inline">
              <input
                class="custom-control-input"
                type="radio"
                name="radio-inline"
              />
              <span class="custom-control-label">Option 3</span>
            </label>
          </div>
        </div>
      </td>*/}
      <td> 
        {" "}
        <div class="be-checkbox custom-control custom-checkbox">
          <input
            class="custom-control-input"
            type="checkbox"
            id={t.uid + "_all"}
            name="line"
            checked={ uidHolder[t.uid] === 1 }
            onClick={()=>{  dispatch(({ type :  'mode_1', payload : t.uid }) )}}
          />
          <label class="custom-control-label"  style={{marginLeft : 30}} for={t.uid + "_all"}>
            All in this folder
          </label>
        </div>
      </td>
      <td>
        {" "}
        <div class="be-checkbox custom-control custom-checkbox">
          <input
            class="custom-control-input"
            type="checkbox"
            name="line"
            id={t.uid + "_custom"}
            checked={ uidHolder[t.uid] === 2 }
            onClick={()=>{  dispatch(({ type :  'mode_2', payload : t.uid }) )}}
          />
          <label  style={{marginLeft : 30}} class="custom-control-label" for={t.uid + "_custom"}>
            Custom
          </label>
        </div>
      </td>
   
      <td>
        {" "}
        <div class="be-checkbox custom-control custom-checkbox">
          <input
            class="custom-control-input"
            type="checkbox"
            name="line"
            id={t.uid + "_none"}
            checked={ !uidHolder[t.uid]   }
            onClick={()=>{  dispatch(({ type :  'mode_0', payload : t.uid }) )}}
          />
          <label  style={{marginLeft : 30}} class="custom-control-label" for={t.uid + "_none"}>
            None
          </label>
        </div>
      </td>
  
    </tr>
  );
}

function TypeLine({ hasSub }) {
  return (
    <div class="row">
      <div class="col-md-4">Depamter AAA</div>
      <div class="col-md-4">
        <div class="be-checkbox custom-control custom-checkbox">
          <input class="custom-control-input" type="checkbox" id="" />
          <label class="custom-control-label" for="" style={{marginLeft : 30}}>
            All in this folder
          </label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="be-checkbox custom-control custom-checkbox">
          <input class="custom-control-input" type="checkbox" id="" />
          <label class="custom-control-label" for=""  style={{marginLeft : 30}}>
            Custom
          </label>
        </div>
      </div>
      <GroupLine hasSub={true} />
      <GroupLine hasSub={true} />
      <GroupLine hasSub={true} />
      <GroupLine hasSub={true} />
    </div>
  );
}

function GroupLine({ hasSub }) {
  return (
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-4 pl-7">Group AAA</div>
        <div class="col-md-4">
          <div class="be-checkbox custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" id="" />
            <label class="custom-control-label" for="">
              All in this folder
            </label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="be-checkbox custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" id="" />
            <label class="custom-control-label" for="">
              Custom
            </label>
          </div>
        </div>

        <ItemLine />
        <ItemLine />

        <ItemLine />

        <ItemLine />
      </div>{" "}
    </div>
  );
}

function ItemLine({ hasSub }) {
  return (
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-4  " style={{ paddingLeft: "6rem" }}>
          Item AAA
        </div>
        <div class="col-md-4">
          <div class="be-checkbox custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" id="" />
            <label class="custom-control-label" for="">
              All in this folder
            </label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="be-checkbox custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" id="" />
            <label class="custom-control-label" for="">
              Custom
            </label>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    types: state.inventory.types || [],
    items: state.inventory.items || [],
    groups: state.inventory.groups || [],
    shopUid: state.shop.detail.uid,
    hqUid : state.shop.detail.uid
  };
};
export default connect(
  mapStateToProps,
  null
)(SpaAgentItems);
