import React, { useState, useEffect } from "react";
import _ from "lodash";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import moment from "moment";
import swal from "sweetalert";

function useFetchEmpAndAgent(initMemberUid, initOptions = {}) {
  const [voucherList, setVoucherList] = useState([]);
  const [activeVoucherOptions, setActiveVoucherOptions] = useState([]);
  const [voucherOwner, setVoucherOwner] = useState({});

  async function fetchVoucherList(memberUid, options = {}) {
    var params = {
      TableName: "Spa_Member_Voucher",
      IndexName: "memberUid-index",
      KeyConditionExpression: "memberUid = :memberUid",
      ExpressionAttributeValues: {
        ":memberUid": memberUid
      }
    };

    documentClient.query(params, function(err, data) {
      if (err) {
        console.log(err);
      } else {
        let groups = computeGroup(data.Items, options);

        let voucherOptions = getActiveOptions(groups);
        if (options.stopOnFail && voucherOptions < 1) {
          swal("ไม่พบ Voucher", "", "error");
          return;
        }
        setActiveVoucherOptions(voucherOptions);
        setVoucherList(groups);

        options.owner && setVoucherOwner(options.owner);
        options.cb && options.cb();
      }
    });
  }
  const getActiveOptions = groups => {
    let voucherOptions = [];
    _.forEach(groups, group => {
      //   voucherOptions = [...items, ...group.list];
      _.forEach(group.list, line => {
        voucherOptions.push({
          ...line,
          label: `${line.name} - ${line.voucherUid}`,
          value: line.voucherUid
        });
      });
    });
    return voucherOptions;
  };

  useEffect(() => {
    if (!initMemberUid) return;
    fetchVoucherList(initMemberUid, initOptions);
  }, []);

  return {
    voucherList,
    setVoucherList,
    fetchVoucherList,
    activeVoucherOptions,
    voucherOwner,
    setVoucherOwner
  };
}

const computeGroup = (vouchers, options = {}) => {
  let voucherGroups = {};
  _.forEach(vouchers, v => {
    if (!voucherGroups[v.voucherPurchaseGroup]) {
      voucherGroups[v.voucherPurchaseGroup] = {
        ...v,
        list: []
      };
    }
    voucherGroups[v.voucherPurchaseGroup].list.push(v);
  });

  //SORT BY PURCHASE DATE
  let ordered = _.orderBy(voucherGroups, "createDate", "desc");

  //SORT BY STATUS
  _.forEach(ordered, group => {
    group.list = _.orderBy(group.list, "status", "desc");

    _.forEach(group.list, line => {
      //CHECK EXPIRED
      let diffExpire = moment(line.expireDate).diff(moment());

      //SET STATUS
      if (diffExpire < 0 && line.status === "active") {
        line.status = "expired";
      }
    });

    //CHECK REMAIN
    let filterActive = _.filter(group.list, i => i.status === "active");
    group.remainQty = filterActive.length; //REMAIN QTY

    //IF ONLY ACTIVE
    if (options.onlyActive) {
      group.list = filterActive;
    }
  });

  return ordered;
};

export default useFetchEmpAndAgent;
