import React from "react";
import Loader from "react-loader-spinner";

function Loading() {
  return (
    <div>
      <div className="loaderModal">
        <div className="loaderModalContent">
          <center>
            <Loader type="ThreeDots" color="#00BFFF" height="300" width="300" />
          </center>
        </div>
      </div>
    </div>
  );
}

export default Loading;
