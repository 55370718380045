import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import Select from "react-select";

import "./bookingGrid.css";

function BookingGrid(props) {
  const [bedsHolder, setBedsHolder] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [branch, setBranch] = useState(null);

  const [localBranchRooms, setLocalBranchRooms] = useState([]);

  const { hqMode = false, branchOptions = [], mainPageBranch } = props;

  useEffect(() => {
    let filterDate = localStorage.getItem("bookingFilterDate");
    let initBranch = null;
    //INIT SET PREVIOUS BRANCH
    if (hqMode && mainPageBranch) {
      initBranch = mainPageBranch;
    } else {
      initBranch = branchOptions[0];
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const startDateQuery = params.get("startDate");
    if (startDateQuery) {
      setStartDate(startDateQuery);
      fetchJobs({ forceDate: startDateQuery ,  forceStore: hqMode ? initBranch : null,});
    } else if (filterDate) {
      fetchJobs({
        forceDate: filterDate,
        forceStore: hqMode ? initBranch : null,
      });
      setStartDate(new Date(filterDate));
    } else {
      fetchJobs({
        forceStore: hqMode ? initBranch : null,
      });
    }
    //FIRST LOAD
    if (!filterDate && hqMode && initBranch) {
      handleSetBranch(initBranch);
    }
  }, []);

  useEffect(() => {}, []);

  const handleSetStartDate = (date) => {
    fetchJobs({ forceDate: date });
    setStartDate(date);
  };

  const handleSetBranch = (branch, isNotFetchJob) => {
    setBranch(branch);
    props.fetchLocalBranchRooms(branch.value, (items) => {
      setLocalBranchRooms(items);
    });
    props.setMainPageBranch(branch);
    !isNotFetchJob && fetchJobs({ forceStore: branch });
  };
  async function fetchJobs({ forceDate, forceStore }) {
    let date = moment(forceDate || startDate).format("YYYY-MM-DD");
    let shopUid = null;

    //BUG PROTECT
    if (hqMode && branchOptions.length < 1) return;

    if (forceStore) {
      shopUid = forceStore.value;
    } else {
      shopUid = hqMode ? branch.value : props.shop.uid;
    }

    var params = {
      TableName: "Spa_Booking",
      KeyConditionExpression:
        "storeUid = :hkey and begins_with(bookUid, :currentDate)",
      ExpressionAttributeValues: {
        ":hkey": shopUid,
        ":currentDate": date,
      },
    };
    let data = await documentClient.query(params).promise();

    if (data.Items) {
      computeJobs(data.Items);
    }

    hqMode && handleSetBranch(forceStore || branch, true);
  }
  const reload = () => {
    fetchJobs({ forceDate: startDate });
  };

  function computeJobs(items) {
    let beds = {};

    //FRACTION BY 10 MINS
    //1 hour = 6 * 10
    // let timeFraction = 6;

    _.map(items, (line) => {
      //   if (!rooms[line.bed.room.roomUid]) {
      //     rooms[line.bed.room.roomUid] = {};
      //   }
      if (!beds[line.bed.bedUid]) {
        beds[line.bed.bedUid] = [];
      }

      let startHour = moment(line.startDate).format("H");
      let endHour = moment(line.endDate).format("H");
      let lineFraction = 8 * 6;
      startHour = parseInt(startHour) * 6 - lineFraction;
      endHour = parseInt(endHour) * 6 - lineFraction;

      let startHourMin = moment(line.startDate).format("mm");
      let endHourMin = moment(line.endDate).format("mm");

      //Hour Fraction + Minute Fraction /10 - 5
      let startFractionQty = startHour + startHourMin / 10 - 5;
      let endFractionQty = endHour + endHourMin / 10 - 5;

      let startLine = startFractionQty;
      let endLine = endFractionQty;
      let startTime = moment(line.startDate).format("HH:mm A");
      let endTime = moment(line.endDate).format("HH:mm A");

      beds[line.bed.bedUid].push({
        ...line,
        startHour,
        endHour,
        startLine,
        endLine,
        startTime,
        endTime,
      });
    });

    setBedsHolder(beds);
  }

  const setBookingInitStartDate = () => {
    window.localStorage.setItem(
      "bookingInitStartDate",
      moment(startDate).toISOString()
    );
  };

  const openCapacityTab = () => {
    let shopId = hqMode ? branch.uid : props.shop.uid;
    const url = `https://payment-confirm-dev-web.firebaseapp.com/booking/${shopId}`;
    window.open(url, "_blank");
  };

  let rooms = hqMode ? localBranchRooms : props.spaRooms;
  rooms = _.sortBy(rooms, "row");

  return (
    <div>
      <div className="row mb-0">
        <div class="col-12 p-5 d-flex justify-content-between">
          <div className="row m-0">
            {/* <div>
              <span
                style={{
                  fontSize: 20
                  // fontWeight: "bold"
                }}
              >
                {hqMode ? "เลือกสาขา" : "ตารางการจอง"}
              </span>
            </div> */}
            {hqMode && (
              <div className="ml-2" style={{ width: 300 }}>
                <label>เลือกสาขา</label>
                <Select
                  value={branch}
                  onChange={handleSetBranch}
                  options={branchOptions}
                  placeholder="เลือกสาขา"
                />
              </div>
            )}
            <div className="ml-2">
              <label>เลือกวัน</label>
              <br />
              <DatePicker
                selected={startDate}
                onChange={handleSetStartDate}
                timeIntervals={15}
                dateFormat="dd/MM/yyyy"
                className="form-control"
              />
              {/* <button
                class="btn btn-space btn-primary"
                onClick={fetchJobs}
                style={{ height: 47 }}
              >
                Submit
              </button> */}
            </div>
          </div>
          <div>
            <button
              class="btn btn-space btn-primary align-self-end"
              style={{ height: 40 }}
              onClick={reload}
            >
              Reload
            </button>

            <button
              class="btn btn-space btn-primary align-self-end"
              style={{ height: 40 }}
              onClick={openCapacityTab}
            >
              Capacity
            </button>
            <button
              class="btn btn-space btn-primary align-self-end ml-2"
              style={{ height: 40 }}
              onClick={() => {
                setBookingInitStartDate();
                props.goToBookingForm();
              }}
            >
              New Book
            </button>
          </div>
        </div>
      </div>
      <div
        className="p-3"
        //    style={{ width: "100%", overflowX: "scroll" }}
      >
        {_.map(rooms, (room) => {
          return (
            <RoomLine
              {...props}
              room={room}
              bedsHolder={bedsHolder}
              setBookingInitStartDate={setBookingInitStartDate}
            />
          );
        })}
      </div>
    </div>
  );
}

const RoomLine = (props) => {
  const { room, bedsHolder } = props;

  return (
    <div className="mt-5">
      <div class="date-row-container">
        <div
          class="date"
          style={{
            color: "blue",
            fontWeight: "bold",
          }}
        >
          {room.name}
        </div>
        <TimeHeader bedLength={(room.beds && room.beds.length) || 0} />
      </div>

      {_.map(room.beds, (bed) => {
        let jobs = _.orderBy(
          bedsHolder[bed.bedUid],
          ["startDate", "timestamp"],
          ["asc", "asc"]
        );

        return (
          <div class="date-row-container">
            <div class="date date-bottom">{bed.name}</div>
            <div class="grid-container2">
              {_.map(jobs, (job) => {
                return [
                  <ReactTooltip id={job.bookUid} type="dark">
                    { job.member && <div>Booking : {job.member.firstName} {job.member.lastName}</div>}
                    <div>Guest : {job.name}</div>
                    <div>
                      Room: {job.bed.room.name} | {job.bed.name}
                    </div>
                    <div>
                      Time: {job.startTime} - {job.endTime}
                    </div>
                    {job.mobile && <div>Mobile: {job.mobile}</div>}
                    <div>
                      <em>Remark: {job.remark}</em>
                    </div>
                    <div>Paid Status: {job.isPaid ? "Yes" : "No"}</div>
                  </ReactTooltip>,
                  <div
                    data-tip
                    data-for={job.bookUid}
                    class="grid-item"
                    style={{
                      gridColumn: `${job.startLine}/${job.endLine}`,
                      backgroundColor: job.void
                        ? "red"
                        : job.fromPos
                        ? "orange"
                        : "#2196f3",
                      //   gridRow: "1"
                    }}
                    onClick={() => {
                      props.setBookingInitStartDate();
                      props.setInitEditData(job);
                      props.goToBookingForm();
                    }}
                  >
                    {job.member && <div class="line1">Booking : {job.member.firstName} {job.member.lastName} </div>}
                    <div class="line1">{job.name}</div>
                    <div class="line2">
                      <em>{job.remark}</em>
                    </div>
                    <div class="line3">
                      <em>{job.status == "to_pos" ? "converted" : ""}</em>
                    </div>
                  </div>,
                ];
              })}
            </div>
          </div>
        );
      })}

      {/* <div class="date-row-container">
        <div class="date">Bed 2</div>
        <div class=" grid-container2">
          <div class="grid-item event1">Event1</div>
          <div class="grid-item event2">Event2</div>
        </div>
      </div> */}
    </div>
  );
};

export default BookingGrid;

const TimeHeader = ({ bedLength }) => {
  let hour = 9;

  return (
    <div class="time-header" style={{ height: bedLength * 130 }}>
      {_.times(90, (i) => {
        if (i === 0) {
          return (
            <div
              className={`time-box-left`}
              style={{
                gridColumn: `${1}/${7}`,
                gridRow: "1",
              }}
            >
              <div>9:00</div>
            </div>
          );
        }

        if (i !== 0 && i % 6 === 0) {
          hour++;
          return (
            <div
              className={`time-box-left ${i === 89 ? "time-box-right" : ""}`}
              style={{
                gridColumn: `${i + 1}/${i + 1 + 7}`,
                gridRow: "1",
              }}
            >
              <div>{hour}:00</div>
            </div>
          );
        }

        // return (
        //   <div
        //     className={`time-box-left ${i === 89 ? "time-box-right" : ""}`}
        //     // style={{ width: 35 }}
        //   >
        //     {i === 0 && <div>{i + 9}:00</div>}
        //     {i !== 0 && i % 6 === 0 && <div>{i / 6 + 9}:00</div>}
        //   </div>
        // );
      })}
    </div>
  );
};


