import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import MemberVoucherModal from "../../shared/components/MemberVoucherModal";
import MemberAddVoucherModal from "../../shared/components/MemberAddVoucherModal";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import useFetchMemberVoucher from "./useFetchMemberVoucher";
import swal from "sweetalert";
import { reject } from "async";

function MemberVoucherList(props) {
  const { initData, voucherOptions } = props;
  const { voucherList, fetchVoucherList } = useFetchMemberVoucher(initData.uid);

  const voidVoucher = async voucher => {
    let reason = await swal({
      text: "กรุณากรอกเหตุผล",
      content: "input",
      dangerMode: true,
      buttons: ["Cancel", "Void"]
    });

    if (!reason || reason === "") return;

    let params = {
      TableName: "Spa_Member_Voucher",
      Key: { hqUid: voucher.hqUid, voucherUid: voucher.voucherUid },
      UpdateExpression: "set #status = :status, voidReason =:voidReason",
      ExpressionAttributeNames: { "#status": "status" },
      ExpressionAttributeValues: {
        ":status": "void",
        ":voidReason": reason
      }
    };

    await documentClient.update(params).promise();
    fetchVoucherList(initData.uid);
  };
  const addDays = async vouchers => {
    let count = 0;

    let days = await swal({
      text: "กรอกจำนวนวันที่ต้องการเพิ่ม (ตัวเลขเท่านั้น)",
      content: "input",
      buttons: ["Cancel", "Add"]
    });

    if (!days || days === "") return;

    if (_.isNaN(parseInt(days))) {
      return swal("เกิดข้อผิดพลาด", "กรุณากรอกตัวเลขเท่านั้น", "error");
    }

    await new Promise((resolve, reject) => {
      _.forEach(vouchers, v => {
        let newDate = moment(v.expireDate).add(parseInt(days), "day");

        var params = {
          TableName: "Spa_Member_Voucher",
          Key: { hqUid: v.hqUid, voucherUid: v.voucherUid },
          UpdateExpression: "set expireDate = :expireDate, age =:age",
          ExpressionAttributeValues: {
            ":expireDate": newDate.toISOString(),
            ":age": parseInt(days) + v.age
          }
        };
        documentClient.update(params, err => {
          if (err) {
            console.log(err);
            reject(err);

            return;
          }
          count++;
          if (count === vouchers.length) {
            resolve();
          }
        });
      });
    });
    fetchVoucherList(initData.uid);
    swal("สำเร็จ", "เพิ่มวันสำเร็จ", "success");
  };

  return (
    <div>
      <MemberAddVoucherModal
        member={initData}
        voucherOptions={voucherOptions}
        fetchVoucherList={() => fetchVoucherList(initData.uid)}
      />
      <MemberVoucherModal
        memberName={initData.name}
        voucherName={"Voucher A"}
        voucherCampaignUid={"1234"}
      />
      <div class="card card-contrast">
        <div class="card-header row justify-content-between">
          <div>
            <b>{initData.name}'s Vouchers</b>
          </div>
          <div>
            <button
              class="btn btn-space btn-success"
              //   onClick={goToManageVoucher}
              data-toggle="modal"
              data-target="#memberAddVoucherModal"
            >
              <i
                class="icon icon-left mdi mdi-plus"
                style={{ color: "white" }}
              ></i>
              Voucher
            </button>
          </div>
        </div>
        <div class="card-body">
          {/* <div class="card-title">Card with Header</div> */}
          <table class="table table-sm table-hover table-bordered table-striped">
            <thead>
              <tr>
                <th style={{ width: "10%" }}>No.</th>
                <th style={{ width: "15%" }}>Voucher ID</th>
                <th>Status</th>
                <th>Used At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {_.map(voucherList, voucher => {
                return [
                  <tr>
                    <td
                      style={{
                        fontSize: 18
                      }}
                      colSpan={5}
                    >
                      <div className="mt-5">
                        <div className="row m-0 justify-content-between">
                          <div>
                            <span
                              style={{
                                color: "blue",
                                fontWeight: "bold",
                                fontSize: 21
                              }}
                            >
                              {voucher.name}
                            </span>
                          </div>
                          <div>
                            <button
                              class="btn btn-primary"
                              type="button"
                              onClick={() => addDays(voucher.list)}
                            >
                              + Days
                            </button>
                          </div>
                        </div>

                        <span

                        // className="pl-5"
                        >
                          (Remain <b>{voucher.remainQty}</b> of{" "}
                          <b>{voucher.list.length}</b>)
                        </span>
                        <span className="pl-5">
                          (Purchase Date:{" "}
                          {moment(voucher.createDate).format(
                            "DD/MM/YYYY HH:mm A"
                          )}
                          )
                        </span>
                        <span className="pl-5">
                          (Expire at:{" "}
                          {moment(voucher.expireDate).format(
                            "DD/MM/YYYY HH:mm A"
                          )}{" "}
                          [{voucher.age} Days])
                        </span>
                      </div>
                    </td>
                  </tr>,
                  _.map(voucher.list, (line, idx) => {
                    return (
                      <tr>
                        <td>{idx + 1}.</td>
                        <td>{line.voucherUid}</td>
                        <td>
                          <b>
                            {getStatus(line.status)}{" "}
                            {line.status === "void" && (
                              <span>(Reason: {line.voidReason})</span>
                            )}
                          </b>
                        </td>
                        <td>
                          {line.used && (
                            <span>
                              {line.used.store.name} at{" "}
                              {moment(line.used.timestamp).format(
                                "DD/MM/YYYY HH:mm A"
                              )}{" "}
                              ({line.used.remark})
                            </span>
                          )}
                        </td>
                        <td>
                          {line.status === "active" && (
                            <a
                              href="#"
                              style={{ color: "red" }}
                              onClick={e => {
                                e.preventDefault();
                                voidVoucher(line);
                              }}
                            >
                              <u>Void</u>
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ];
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MemberVoucherList;

const getStatus = status => {
  let color = "black";
  switch (status) {
    case "active":
      color = "green";
      break;
    case "used":
      color = "red";
      break;
    case "void":
      color = "red";
      break;
    case "expired":
      color = "gray";
      break;
  }

  return <span style={{ color: color }}>{status.toUpperCase()}</span>;
};
