import * as actions from "./actions";
import _ from "lodash";
import moment from "moment";
//ส้งเท่านี้พอ ที่เหลือเอาใน selector
let jobInit = {
  items: [],
  options: [],
  qtyHolder: {},
  customers: 1,
  ref1: "RICE", //table, job
  ref2: "", //comment
  uuid: "",
  // employee : {},
  timestamp: "",
  mainAppStore: null,
  menu_items: [],
  inventoryReducer: {},
  saveInstance: {},
  promotionList: [], // from original outside,
  itemlinesSelected: {
    // uuid : true
  }
};

export default (state = jobInit, action) => {
  switch (action.type) {
    case actions.CART_ADD_ITEM:
      let uid = action.payload.item.uid;

      var newItems = null;

      // find item
      let foundItem = _.find(state.items, (line, index) => {
        return line.uid === uid && !line.isUnique;
      });

      if (foundItem == null) {
        newItems = [
          ...state.items,
          {
            ...action.payload.item,
            qty: action.payload.qty || 1,
            discount: { mode: 0, value: 0 },
            timestamp : moment().valueOf()
          }
        ];
      } else {
        //found in list
        newItems = _.map(state.items, (line, index) => {
          if (line.uid == uid && !line.isUnique) {
            return {
              ...line,
              qty: (line.qty || 1) + (action.payload.qty || 1)
            };
          }

          return line;
        });
      }
      var newQtyHolder = computeQtyHolder(newItems);
      return {
        ...state,
        qtyHolder: newQtyHolder,
        items: newItems,
        itemlinesSelected: {}
      };

    case actions.CART_ITEMS_DISCOUNT_LINE:
      let selectedIndexHolder = state.itemlinesSelected;
      var newItems = _.map(state.items, (line, index) => {
        if (selectedIndexHolder[index]) {
          return {
            ...line,
            discount: action.payload
          };
        }

        return line;
      });

      return { ...state, items: newItems };
    case actions.CART_ITEM_SELECTED:
      return {
        ...state,
        itemlinesSelected: cartSelectedHelper(state.itemlinesSelected, action)
      };
    case actions.CART_CLEAR:
      return {
        ...state,
        qtyHolder: {},
        items: [],
        options: [],
        discountHolder: {},
        itemlinesSelected: {}
      };

    case actions.CART_REMOVE_ITEMLINE:
      var newItems = state.items.filter((line, i) => {
        return i !== action.payload.index;
      });
      var newStateQty = computeQtyHolder(newItems);
      return {
        ...state,
        qtyHolder: newStateQty,
        items: newItems,
        itemlinesSelected: {},
        timestamp: moment().toISOString()
      };
    case "CART_ITEM_SET_QTY":
      var newItems = _.map(state.items, (line, index) => {
        if (index === action.index) {
          return { ...line, qty: action.payload || 1 };
        }

        return line;
      });

      var newStateQty = computeQtyHolder(newItems);

      return { ...state, items: newItems, qtyHolder: newStateQty };
    case "CART_ITEM_SET_PRICE":
      var newItems = _.map(state.items, (line, index) => {
        if (index === action.index) {
          return { ...line, price: action.payload, isUnique: true };
        }

        return line;
      });

      var newStateQty = computeQtyHolder(newItems);

      return { ...state, items: newItems, qtyHolder: newStateQty };
    case "CART_ITEM_SPLIT_LINE":
      let newQtyToSplit = action.payload;
      var newItems = _.reduce(
        state.items,
        (acc, line, i) => {
          if (i === action.index) {
            let currentLine = {
              ...line,
              qty: line.qty - newQtyToSplit,
              
            };
            acc.push(currentLine);

            let currentLine_splitted = {
              ...line,
              qty: newQtyToSplit,
              isUnique: true,
              timestamp : moment().valueOf()
            };
            acc.push(currentLine_splitted);
          } else {
            acc.push(line);
          }

          return acc;
        },
        []
      );

      return {
        ...state,
        qtyHolder: computeQtyHolder(newItems),
        items: newItems,
        itemlinesSelected: {}
      };

    case "MENU_ITEMS":
      return { ...state, menu_items: action.payload };
    case "INVENTORY_REDUCER":
      return { ...state, inventoryReducer: action.payload };
    case actions.CART_OPTIONS_SAVE:
      return { ...state, options: action.payload };
    case actions.CART_TIMESTAMP_SAVE:
      return { ...state, timestamp: moment().toISOString() };
    case "PROMOTION_LIST":
      return { ...state, promotionList: action.payload };
    case actions.CART_RESTORE:
      return { ...state, ...action.payload };
    case "SET_MAIN_APP_STORE":
      return { ...state, mainAppStore: action.payload };

    default:
      return state;
  }
};

function cartSelectedHelper(itemlinesSelectedState, action) {
  return { ...itemlinesSelectedState, [action.index]: action.payload };
}

function computeQtyHolder(itemList) {
  return _.reduce(
    itemList,
    (acc, line) => {
      if (acc[line.uid] === undefined) {
        acc[line.uid] = 0;
      }
      acc[line.uid] += line.qty || 1;
      return acc;
    },
    {}
  );
}
