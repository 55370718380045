import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import ManageCustomerModal from "../../shared/components/ManageCustomerModal";
import WalletModal from "../../shared/components/WalletModal";
import exportAllMemberCsv from "./exportAllMemberCsv";
import excelReportember from "../../shared/services/service.export.memberCsv";

function MemberListTable(props) {
  const { isRetail } = props;

  //   const [count, setCount] = useState(0);
  const [customerList, setCustomerList] = useState([]);
  const [inputCustomer, setInputCustomer] = useState("");
  const [lastKey, setLastKey] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [filterMode, setFilterMode] = useState("name"); //name,mobileLocal,memberId
  const [loadingCsv, setLoadingCsv] = useState(false);

  useEffect(() => {
    setInputCustomer("");
  }, [filterMode]);

  const fetchCustomers =  (pageQty = 0, isClear) => {
   
    //ALREADY HAS
    let nextPage = pageNo + pageQty;
    if (pageQty > 0 && customerList[nextPage]) {
      setPageNo(nextPage);
      return;
    }

    //FETCH MORE
    let params = {
      TableName: "Crm_Users",
      IndexName: "hqUid-createAt-index",
      KeyConditionExpression: "hqUid = :hqUid",
      ExpressionAttributeValues: {
        ":hqUid": props.linked || props.shop.uid,
      },
      ScanIndexForward: false,
      Limit: 50,
    };

    //? CHECK IS_RETEAIL
    if (isRetail) {
      params.FilterExpression = "businessUnit = :businessUnit";
      params.ExpressionAttributeValues[":businessUnit"] = "retail";
    } else {
      params.FilterExpression = "businessUnit <> :businessUnit";
      params.ExpressionAttributeValues[":businessUnit"] = "retail";
    }

    if (lastKey !== "max" && isClear !== true) {
      params.ExclusiveStartKey = lastKey;
    }

    documentClient.query(params, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        //SET LASTKEY
        if (data.LastEvaluatedKey) {
          setLastKey(data.LastEvaluatedKey);
        } else {
          setLastKey("max");
        }

        setInputCustomer("");
        setModalTimestamp(moment());

        if (isClear) {
          setCustomerList([data.Items]);
          setPageNo(0);
          return;
        }

        if (data.Items.length > 0) {
          setCustomerList([...customerList, data.Items]);
          setPageNo(nextPage);
        }
      }
    });
  };

  const exportAllMemberToCsv = async () => {
    setLoadingCsv(true);
    const members = await exportAllMemberCsv(
      props.linked || props.shop.uid,
      isRetail
    );

    new excelReportember(members, isRetail).build();

    setLoadingCsv(false);
  };

 

  const fetchCustomersByFilter = async() => {
    let params = {
      TableName: "Crm_Users",
      IndexName: "hqUid-createAt-index",
      KeyConditionExpression: "hqUid = :hqUid",
      FilterExpression: "contains(#filterMode, :input)",
      ExpressionAttributeNames: {
        "#filterMode": filterMode,
      },
      ExpressionAttributeValues: {
        ":input": inputCustomer,
        ":hqUid": props.linked || props.shop.uid,
      },
      ScanIndexForward: false,
    };

    //? CHECK IS_RETEAIL
    if (isRetail) { 
      params.FilterExpression += "and businessUnit = :businessUnit";
      params.ExpressionAttributeValues[":businessUnit"] = "retail";
    } else {
      params.FilterExpression += "and businessUnit <> :businessUnit";
      params.ExpressionAttributeValues[":businessUnit"] = "retail";
    }


    var lasyKey = null;
    var res = [];
    do {
     
      if(lasyKey){
        params.ExclusiveStartKey = lasyKey
      }else{
        params.ExclusiveStartKey=null
      }
      var data = await documentClient.query(params).promise();
      res = res.concat(data.Items);
      lasyKey = data.LastEvaluatedKey;
    } while (data.LastEvaluatedKey);
  
    //let filtered = _.orderBy(res, "createAt");
   

        setPageNo(0);
        setLastKey(null);
        setCustomerList([res]);
   

    // documentClient.query(params, (err, data) => {
    //   if (err) {
    //     console.log(err);
    //   } else {
    //     // let ordered = _.orderBy(data.Items, "createAt", "desc");
    //     // let options = getOptions(ordered);
    //     debugger
    //     setPageNo(0);
    //     setLastKey(null);
    //     setCustomerList([data.Items]);
    //   }
    // });
  };

  const backPage = () => {
    if (pageNo === 0) return;

    setPageNo(pageNo - 1);
  };
  useEffect(() => {
    // fetchCustomers(25);
    fetchCustomers();
    //fetchMember2()

    //TODO: DELETE THIS
    // convertReceive();
  }, []);

  const { setModalTimestamp, setCustomer, handleSetCustomer } = props;

  const filterHeight = 35;

  const handleKeyPressSearchByName = async(event) => {
    if (event.key === "Enter") {
      await fetchCustomersByFilter();
    }
  };

  const PageButtons = () => {
    return [
      <button
        style={{ height: filterHeight }}
        class="btn btn-primary"
        type="button"
        onClick={() => fetchCustomers(0, true)}
      >
        {"<< First"}
      </button>,

      <button
        style={{ height: filterHeight }}
        class="btn btn-primary ml-1"
        type="button"
        onClick={backPage}
        disabled={pageNo === 0}
      >
        {"< Back"}
      </button>,
      <div
        className="ml-3 mr-3 align-self-center"
        style={{ display: "inline" }}
      >
        <b>{pageNo + 1}</b>
      </div>,
      <button
        style={{ height: filterHeight }}
        class="btn btn-primary mr-2"
        type="button"
        onClick={() => fetchCustomers(1)}
        disabled={lastKey === "max" && pageNo + 1 === customerList.length}
      >
        {"Next >"}
      </button>,
    ];
  };

  return (
    <div
    // className="p-2"
    >
      <ManageCustomerModal
        {...props}
        title="Edit Customer"
        fetchCustomers={() => fetchCustomers(0, true)}
      />
      <WalletModal {...props} fetchCustomers={() => fetchCustomers(0, true)} />
      <div class="card card-table">
        <div class="card-header">
          <div className="row m-0 justify-content-between">
            <div className="align-self-center">
              <div className="row m-0">
                <div>Member</div>
                <div className="ml-3">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <button
                        class={`btn btn-${
                          filterMode === "name" ? "warning" : "secondary"
                        }`}
                        type="button"
                        onClick={() => setFilterMode("name")}
                      >
                        Name
                      </button>
                      <button
                        class={`btn btn-${
                          filterMode === "mobileLocal" ? "warning" : "secondary"
                        }`}
                        type="button"
                        onClick={() => setFilterMode("mobileLocal")}
                      >
                        Mobile
                      </button>
                      <button
                        class={`btn btn-${
                          filterMode === "memberId" ? "warning" : "secondary"
                        }`}
                        type="button"
                        onClick={() => setFilterMode("memberId")}
                      >
                        Member ID
                      </button>
                    </div>
                    <input
                      style={{ height: filterHeight, width: 200 }}
                      class="form-control"
                      type={filterMode === "mobileLocal" ? "number" : "text"}
                      // placeholder="Search by name"
                      value={inputCustomer}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          fetchCustomers(0, true);
                        }
                        setInputCustomer(
                          filterMode === "name"
                            ? e.target.value.toUpperCase()
                            : e.target.value
                        );
                      }}
                      onKeyPress={handleKeyPressSearchByName}
                    />
                    <div class="input-group-append">
                      <button
                        style={{ height: filterHeight }}
                        class="btn btn-primary"
                        type="button"
                        onClick={fetchCustomersByFilter}
                      >
                        Search
                      </button>
                      <button
                        style={{ height: filterHeight }}
                        class="btn btn-success ml-1"
                        type="button"
                        onClick={exportAllMemberToCsv}
                        disabled={loadingCsv}
                      >
                        Export CSV
                      </button>
                      {loadingCsv && (
                        <span className="pl-1" stle={{ fontSize: 16 }}>
                          Loading...
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div>
                <div>
                  {inputCustomer === "" && (
                    <div className="row m-0">
                      <PageButtons />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-striped table-sm table-borderless">
            <thead>
              <tr>
                <th>Member ID</th>
                <th>Name</th>
                <th>Gender</th>
                <th>Country</th>
                <th>Mobile</th>
                {/* <th>Create At</th> */}
                <th>Prepaid</th>
                {/* <th>Point</th> */}
                {/* <th>Last Visited</th> */}
                <th>Tags</th>
                {/* <th>Create At</th> */}
                <th class="actions" style={{ textAlign: "right" }}></th>
              </tr>
            </thead>
            <tbody class="no-border-x">
              {_.map(customerList[pageNo], (customer) => {
                if (!customer.memberId || customer.memberId === "") return; //! ไม่โชว์คนที่ไม่มี memberId

                return (
                  <tr>
                    <td>{customer.memberId}</td>
                    <td>{customer.name}</td>
                    <td>{customer.gender}</td>
                    <td>{customer.country}</td>
                    <td>{customer.mobileLocal}</td>
                    {/* <td>
                    {moment(customer.createAt).format("DD/MM/YYYY HH:mm")}
                  </td> */}
                    <td>
                      {customer.walletBalance &&
                        customer.walletBalance.toLocaleString()}
                    </td>
                    {/* <td>
                      {customer.pointBalance &&
                        customer.pointBalance.toLocaleString()}
                    </td> */}
                    {/* <td>
                      {customer.lastVisited
                        ? moment(customer.lastVisited).format(
                            "DD-MM-YYYY HH:mm"
                          )
                        : ""}
                    </td> */}
                    <td>
                      {_.map(customer.tags, (tag) => {
                        return (
                          <span>
                            &nbsp;
                            <span class="badge badge-pill badge-primary">
                              {tag.name}
                            </span>
                          </span>
                        );
                      })}
                    </td>
                    {/* <td>
                      {moment(customer.createAt).format("DD/MMM/YYYY HH:mm")}
                    </td> */}
                    <td class="actions">
                      <button
                        class="btn btn-space btn-primary"
                        onClick={() => {
                          handleSetCustomer(customer);
                          setModalTimestamp(moment());
                          // window.$("#manageCustomerModal").modal("show");
                        }}
                      >
                        Profile
                      </button>
                      <button
                        class="btn btn-space btn-primary"
                        onClick={() => {
                          setCustomer(customer);
                          setModalTimestamp(moment());
                          window.$("#walletModal").modal("show");
                        }}
                      >
                        Wallet
                      </button>
                      {/* <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setCustomer(customer);
                        setModalTimestamp(moment());
                        window.$("#manageCustomerModal").modal("show");
                      }}
                    >
                      View
                    </a> */}
                      {/* <a class="icon" href="#">
                      <i class="mdi mdi-delete"></i>
                    </a> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {inputCustomer === "" && (
        <div className="mt-4 mb-4" style={{ textAlign: "center" }}>
          <PageButtons />
        </div>
      )}
    </div>
  );
}

export default MemberListTable;

// const convertReceive = async () => {
//   //this bring memberUid outside member node

//   let items = [];
//   let _lastkey = null;
//   // scanReceipt
//   do  {
//     scanReceipt(data => {
//       items = [...items, ...data.Items];
//       console.log(items);
//       if (data.LastEvaluatedKey) {
//         _lastkey = data.LastEvaluatedKey;
//         debugger;
//       } else {
//         _lastkey = null;
//         debugger;
//       }
//     }, _lastkey);
//   } while (_lastkey != null);

// _.forEach(items, (item, idx) => {
//   if (item.member) {
//     updateReceiptMemberUid(item, idx);
//   }
// });

//   let shopIds = [1,2,4,3180,3181];

//  // let res = await fetch(shopIds[0])
//   let res = []
//   _.forEach(shopIds, async (id)=>{
//     let tmp_res = await fetch(id)
//     res = [...res, ...tmp_res]

//   })

// for (let index = 0; index < shopIds.length; index++) {
//   const id = shopIds[index];
//   let tmp_res = await fetch(id)
//   res = [...res, ...tmp_res]
// }
// if (res.length > 0) {
//   console.log(res.length)
// }
// };

// const scanReceipt = _lastkey => {
//   var params = {
//     TableName: "Spa_Receipts"
//   };

//   if (_lastkey) {
//     params.ExclusiveStartKey = _lastkey;
//   }

//   return documentClient.scan(params).promise();
// };

// const updateReceiptMemberUid = async (item, idx) => {
//   var params = {
//     TableName: "Spa_Receipts",
//     Key: { storeUid: item.storeUid, timestamp: item.timestamp },
//     UpdateExpression: "SET memberUid = :memberUid",
//     // ExpressionAttributeNames: { "#a": "Sum" },
//     ExpressionAttributeValues: {
//       ":memberUid": item.member.uid
//     }
//   };
//   documentClient.update(params, (err, data) => {
//     if (err) {
//       console.log(err);
//     } else {
//       console.log(`completed ${item.storeUid}    ${item.timestamp}`);
//     }
//   });
// };
