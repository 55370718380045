import React, { useState , useEffect} from "react";
import _ from 'lodash'
import { groupPaymentTypes } from "../../../shared/utils/receipts";

export const GroupDateHeader = () =>{ 
    return <thead>
        <tr>
            <td>Date</td>
            <td>Bills</td>
            <td>GrandTotal</td>
            <td>Payment Types</td>

        </tr>
    </thead>
}

export const GroupNameRowSection  = ({name}) => {
    return <tr  >
        <td  colSpan="4">
        <div >
            <h4 style={{marginTop : 60}}>{name}</h4>
        </div></td>
    </tr>
}


export const GroupDateRow = ({ date, receipts = [], showPaymentType = true }) =>{ 
    return <tr>
     
         
        <td class='pl-7' style={{ verticalAlign :  'top'  }}>{date}</td>
         
        <td style={{ verticalAlign :  'top'  }}>{receipts.length} bills</td>
        <td style={{ verticalAlign :  'top'  }}>{_.sumBy(receipts, 'payment.grandTotal')}</td>
        <td style={{ verticalAlign :  'top'  }}> 
            {showPaymentType && <PaymentTypesSummary receipts={receipts}/>}
         </td>

    </tr>
}


export const GroupDateTable = ({groupReceiptsByDateHolder, showPaymentType}) => {
    return  <table class="table table-small table-striped  " >
    <GroupDateHeader />
    <tbody>
      {_.map(groupReceiptsByDateHolder, (groupDateLine, name) => {
         

        return [
          <GroupNameRowSection name={name}/>,
           _.map ( groupDateLine,  (t) => <GroupDateRow  showPaymentType={showPaymentType || false}  date={t.date} receipts={t.receipts} />  ) 
        ];
      })}
    </tbody>
  </table>

}

export const PaymentTypesSummary = ({receipts}) =>{
    let res =  groupPaymentTypes  (receipts)
    return <div>
        {_.map(res, (rList, paymentName) => [<span>{paymentName} : {_.sumBy(rList, 'amount')} ({rList.length}) </span>,<br/>])}
    </div>
}