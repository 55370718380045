import React, { useState, useEffect } from "react";
import { Radio } from "./Components";
import DatePicker from "react-datepicker";
import moment from "moment";
function DashboardRangeDatesBar(props) {
  const { dispatch, fetch, startDate, endDate } = props;
  const [view, setView] = useState("single");
  const [dateObj, setDateObj] = useState(new Date());
  const [sDate, setSDate] = useState(new Date());
  const [eDate, setEDate] = useState(new Date());

  useEffect(() => {
    if (props.match.path === "/reports2/spa") {
      //SET LAST 7 DAYS
      var date = new Date();
      date.setDate(date.getDate() - 7);
      setSDate(date);
    }
  }, []);

  const setShowDate = (name, dateObj) => {
    console.log(dateObj);
    let m = moment(dateObj);
    let iso = "";
    if (name === "startDate") {
      // iso = m.startOf("day").toISOString();
      iso = m.toISOString();
      // setSDate(dateObjToString(dateObj));
      setSDate(dateObj);
    } else {
      // iso = m.endOf("day").toISOString();
      iso = m.toISOString();
      // setEDate(dateObjToString(dateObj));
      setEDate(dateObj);
    }

    dispatch({
      type: "SET",
      key: name,
      payload: iso
    });
  };

  const dateObjToString = date => {
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    const dateString = `${date.getFullYear().toString()}-${month.toString()}-${
      date.getDate() < 10
        ? "0" + date.getDate().toString()
        : date.getDate().toString()
    }`;
    return dateString;
  };
  return [
    <div class="col-12" style={{ margin: 20, marginBottom: 10, marginTop: 10 }}>
      <span style={{ marginRight: 32 }}>Select Dates </span>
      {/* <Radio
        label="Single Date"
        name="date-range"
        checked={view === "single"}
        onClick={() => setView("single")}
      />{" "}
      <Radio
        label="Range"
        name="date-range"
        checked={view === "range"}
        onClick={() => setView("range")}
      />{" "} */}
      <span style={{ width: 60 }}> </span>
      <span style={{ marginLeft: 32, marginRight: 12 }}>Start </span>
      <DatePicker
        className="form-control form-control-sm"
        selected={sDate}
        onChange={evt => {
          setShowDate("startDate", evt);
        }}
        dateFormat="dd/MM/yyyy"
        id="date-pick"
        style={{ width: 150, height: 38 }}
        maxDate={moment("31/12/2019", 'DD/MM/YYYY').toDate()}

      />
      <span style={{ marginLeft: 32, marginRight: 12 }}>End </span>
      <DatePicker
        className="form-control form-control-sm"
        selected={eDate}
        onChange={evt => {
          setShowDate("endDate", evt);
        }}
        dateFormat="dd/MM/yyyy"
        id="date-pick"
        style={{ width: 150, height: 38 }}
        maxDate={moment("31/12/2019", 'DD/MM/YYYY').toDate()}

            
      />
      <button
        onClick={fetch}
        style={{ marginLeft: 24, marginRight: 12 }}
        class="btn btn-space btn-primary"
      >
        SET
      </button>
    </div>,
    <div className="dropdown-divider mt-0 mb-0" />
  ];
}

export default DashboardRangeDatesBar;
