import * as type from "../../types/spa.pos";
import moment from 'moment'

const initState = {
  receipts : [],
  bookings : [],

};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case "FETCH_SPA_DASHBAORD_RECEITPS":
      return { ...state, receipts : action.payload || [] };
    case "FETCH_SPA_DASHBAORD_BOOKINGS":
      return initState;
    case type.SPA_RESTORE_JOB_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
