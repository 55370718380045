import React, { useState, useEffect } from "react";
import _ from "lodash";
import * as aws from "../../shared/awsAPIs/instance";
import moment from "moment";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import swal from "sweetalert";
import { spaPosTimeStampRef } from "../../shared/firebaseApi/firebaseRef";

function Pending(props) {
  const [jobBedsHolder, setJobBedsHolder] = useState({});
  const [filterMode, setFilterMode] = useState("all");
  const [roomInput, setRoomInput] = useState("all");
  const [hoverBed, setHoverBed] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [isAvailable, setIsAvailable] = useState(true);
  const [statusFilter, setStatusFilter] = useState("All");

  async function fetchJobs() {
    var params = {
      TableName: "Spa_Jobs",
      KeyConditionExpression:
        // "storeUid = :storeUid AND begins_with(jobUid, :currentDate)",
        "storeUid = :storeUid ",

      ExpressionAttributeValues: {
        ":storeUid": props.user.shopId,
        // ":currentDate": moment().format("YYYY-MM-DD")
      },
    };
    var data = await aws.documentClient.query(params).promise();
    compute(data.Items);
  }
  function compute(items) {
    let jobBedHolder = {};

    _.forEach(items, (item) => {
      if (!jobBedHolder[item.meta.bedUid]) {
        jobBedHolder[item.meta.bedUid] = item;
      } else {
        if (!jobBedHolder[item.meta.bedUid]["duplicated"]) {
          jobBedHolder[item.meta.bedUid]["duplicated"] = [];
        }

        jobBedHolder[item.meta.bedUid]["duplicated"] = [
          ...jobBedHolder[item.meta.bedUid]["duplicated"],
          item,
        ];
      }
    });

    // let res = _.reduce(
    //   items,
    //   (acc, line) => {
    //     var bedUid = line.meta.bedUid;
    //     acc[bedUid] = line;

    //     return acc;
    //   },
    //   {}
    // );

    setJobBedsHolder(jobBedHolder);
  }
  useEffect(() => {
    const callback = (snapshot) => {
      fetchJobs();
    };
    spaPosTimeStampRef.child(props.shop.uid).on("value", callback);

    return () => {
      spaPosTimeStampRef.child(props.shop.uid).off("value", callback);
    };
  }, []);

  const handleSetFilterMode = (mode) => {
    setFilterMode(mode);
  };
  // useEffect(() => {
  //   // CLEAR ZONE INPUT
  //   setRoomInput("all");
  // }, [filterMode]);

  const checkExistEvaluateJob = async (data) => {
    var params = {
      TableName: "Feedback_Jobs",
      Key: {
        shopUid: data.storeUid,
        timestamp: data.jobUid,
      },
    };
    return documentClient.get(params).promise();
  };

  const sendEvaluateJob = async (data) => {
    let res = await checkExistEvaluateJob(data);
    if (res.Item) {
      return swal(
        "แบบฟอร์มถูกส่งไปแล้ว",
        "คุณได้ส่งแบบฟอร์มของงานนี้ไปเรียบร้อยแล้ว",
        "info"
      );
    }
    var params = {
      TableName: "Feedback_Jobs",
      Item: {
        ...data,
        shopUid: data.storeUid,
        timestamp: data.jobUid,
        date: moment(data.jobUid).format("YYYY-MM-DD"),
        month: moment(data.jobUid).format("YYYY-MM"),
        name: data.member.name,
        status: "pending",
        customerUid: data.member.uid,
        secretData: {
          name: data.member.name,
          customerUid: data.member.uid,
          birthDate: data.member.birthDate,
        },
      },
    };

    try {
      await documentClient.put(params).promise();
      swal("Success", "สร้างแบบฟอร์มการประเมินสำเร็จ", "success");
    } catch (error) {
      alert("Failed send evaluate form");
    }
  };

  return (
    <div>
      {/* //TODO: filter room */}
      <div style={{ margin: 20, marginBottom: 10 }}>
        <div className="row m-0 justify-content-between">
          <div class="col-10">
            {/* <button
              onClick={() => {
                setIsActive(!isActive);
              }}
              className={`btn btn-space btn-${
                isActive === true ? "warning" : "secondary"
              } `}
            >
              Active
            </button> */}
            {/* <button
              onClick={() => {
                setIsAvailable(!isAvailable);
              }}
              className={`btn btn-space btn-${
                isAvailable === true ? "warning" : "secondary"
              } `}
            >
              Available
            </button> */}
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              style={{ width: 100, display: "inline" }}
              className="form-control"
            >
              <option>All</option>
              <option>Available</option>
              <option>Process</option>
              <option>Complete</option>
              <option>Preview</option>
            </select>
            <button
              className={`btn btn-space btn-${
                roomInput === "all" ? "primary" : "secondary"
              } ml-1`}
              onClick={() => {
                setRoomInput("all");
                handleSetFilterMode("all");
              }}
            >
              All
            </button>
            {_.map(props.spaRooms, (room) => {
              return (
                <button
                  selected={roomInput === room.roomUid}
                  onClick={() => {
                    handleSetFilterMode("room");
                    setRoomInput(room.roomUid);
                  }}
                  className={`btn btn-space btn-${
                    roomInput === room.roomUid ? "primary" : "secondary"
                  }`}
                >
                  {room.name}
                </button>
              );
            })}
          </div>
          <div class="col-2">
            <button
              style={{ float: "right" }}
              className={`btn btn-rounded btn-space btn-primary`}
              onClick={() => {
                props.dispatch({
                  type: "APP_STATE_SET",
                  key: "isRetail",
                  payload: true,
                });
                props.dispatch({
                  type: "APP_STATE_SET",
                  key: "room",
                  payload: null,
                });
                setTimeout(() => props.history.push(`/spa_pos/0`), 100);
              }}
            >
             = Buy Products / Retail =
            </button>
          </div>
        </div>
      </div>
      <div className="dropdown-divider mt-0" />
      {/* //TODO: open when new pos schema done */}
      <Table
        data={jobBedsHolder}
        {...props}
        zones={props.spaRooms}
        filterMode={filterMode}
        roomInput={roomInput}
        sendEvaluateJob={sendEvaluateJob}
        setHoverBed={setHoverBed}
        hoverBed={hoverBed}
        isActive={isActive}
        isAvailable={isAvailable}
        statusFilter={statusFilter}
      />
    </div>
  );
}

export default Pending;

const RadioBox = ({ label, checked, onClick }) => {
  return (
    <label
      className="custom-control custom-radio custom-control-inline"
      onClick={onClick}
    >
      <input className="custom-control-input" type="radio" checked={checked} />
      <span className="custom-control-label">{label}</span>
    </label>
  );
};

function Table({
  data,
  history,
  actions,
  dispatch,
  zones,
  businessDate,
  filterMode,
  roomInput,
  sendEvaluateJob,
  setHoverBed,
  hoverBed,
  isActive,
  isAvailable,
  statusFilter,
}) {
  const getTherapist = (lineData) => {
    if (lineData.therapistList) {
      return (
        <div>
          {_.map(lineData.therapistList, (ther) => {
            return <div>{ther.name}</div>;
          })}
        </div>
      );
    } else if (lineData.therapist) {
      return <span>{lineData.therapist.label}</span>;
    } else {
      return (
        <span style={{ color: "red" }}>
          <b>No Therapist</b>
        </span>
      );
    }
  };
  const getStatus = (lineData) => {
    // debugger;
    if (lineData.isPrintedPreview) {
      return (
        <span style={{ color: "orange", fontWeight: "bold" }}>Preview</span>
      );
    }

    let endDate = lineData.meta.endDate;
    let diff = moment(endDate).diff(moment());
    if (diff < 0) {
      return (
        <span style={{ color: "green", fontWeight: "bold" }}>Complete</span>
      );
    } else {
      return "Processing";
    }
  };
  const BedLine = (data) => {
    return (
      <tr
        key={data.bed.bedUid}
        style={{
          borderLeft: !data.activeData ? "4px solid  forestgreen" : "",
        }}
      >
        <td />

        {/* <td className="cell-detail">
        <span>{tx.name}</span>
        <span className="cell-detail-description">{tx.name}</span>
      </td> */}

        <td className="cell-detail">
          <b
            style={{
              cursor: data.bed.bedUid === hoverBed ? "pointer" : "pointer",
              textDecoration:
                data.bed.bedUid === hoverBed ? "underline" : "underline",
            }}
            onMouseEnter={() =>
              setHoverBed(!data.activeData && data.bed.bedUid)
            }
            onMouseLeave={() => setHoverBed(null)}
            onClick={(e) => {
              if (data.activeData) {
                e.preventDefault();
                dispatch({
                  type: "APP_STATE_SET",
                  key: "room",
                  payload: data.tx.roomUid,
                });
                dispatch({
                  type: "APP_STATE_SET",
                  key: "isRetail",
                  payload: false,
                });
                history.push(
                  `/spa_pos/0/${data.lineData.storeUid}/${data.lineData.jobUid}`
                );
                return;
              }
              e.preventDefault();
              actions.restore({
                meta: {
                  room: {
                    ...data.tx,
                    label: data.tx.name,
                    value: data.tx.roomUid,
                  },
                  bed: {
                    ...data.bed,
                    label: data.bed.name,
                    value: data.bed.bedUid,
                  },
                  bedUid: data.bed.bedUid,
                },
              });
              setTimeout(() => history.push(`/spa_pos/0`), 100);
            }}
          >
            <span>
              {data.tx.name} - {data.bed.name}
            </span>
          </b>
          {data.activeData && (
            <span
              style={{ fontSize: "1em" }}
              className="cell-detail-description"
            >
              {data.activeData.refId}
            </span>
          )}
        </td>

        <td className="cell-detail">
          {data.meta.startDate && [
            <span>
              {moment(data.meta.startDate).format("HH:mm ")} -{" "}
              {moment(data.meta.endDate).format("HH:mm")}
            </span>,
            <span className="cell-detail-description">
              {moment(data.meta.startDate).format("DD MMM YYYY")}
            </span>,
          ]}
        </td>

        <td className="cell-detail">
          {data.activeData && (
            <h3>
              <b> {data.lineData.member.name} </b>
            </h3>
          )}
        </td>

        {/* <td className="cell-detail">
        <span>
          {lineData.agent && lineData.agent.label}
        </span>
      </td> */}
        <td className="cell-detail">
          {data.activeData && <span>{getTherapist(data.lineData)}</span>}
        </td>
        <td className="cell-detail">
          <h3 style={{ color: "blue" }}>
            <span>
              {data.lineData.grandTotal &&
                data.lineData.grandTotal.toLocaleString()}
            </span>
          </h3>
          {/* <span className="cell-detail-description">
          {lineData.jobUid}
        </span> */}
        </td>

        <td className="cell-detail">
          <span>
            {data.lineData.grandTotal ? getStatus(data.lineData) : "Avaliable"}
          </span>
        </td>
        <td className="cell-detail">
          {data.meta.startDate && (
            <span>
              {" "}
              {moment().diff(moment(data.meta.startDate), "m")} minutes
            </span>
          )}
          {data.meta.endDate && (
            <span className="cell-detail-description">
              IN {moment(data.meta.endDate).diff(moment(), "m")} minutes
            </span>
          )}
        </td>
        <td className="cell-detail">
          {data.activeData ? (
            <a
              className="btn btn-rounded btn-space btn-secondary"
              href="#"
              style={{ width: 100 }}
              // style={{ color: "orange" }}
              onClick={(e) => {
                e.preventDefault();
                dispatch({
                  type: "APP_STATE_SET",
                  key: "room",
                  payload: data.tx.roomUid,
                });
                history.push(
                  `/spa_pos/0/${data.lineData.storeUid}/${data.lineData.jobUid}`
                );
              }}
            >
              View
            </a>
          ) : (
            <a
              href="#"
              className="btn btn-rounded btn-space btn-secondary"
              style={{ width: 100 }}
              onClick={(e) => {
                if (moment().diff(moment(businessDate), "hours") >= 30) {
                  alert("please close shop  and reopen.");
                  return;
                }

                e.preventDefault();
                dispatch({
                  type: "APP_STATE_SET",
                  key: "isRetail",
                  payload: false,
                });
                dispatch({
                  type: "APP_STATE_SET",
                  key: "room",
                  payload: data.tx.roomUid,
                });
                actions.restore({
                  meta: {
                    room: {
                      ...data.tx,
                      label: data.tx.name,
                      value: data.tx.roomUid,
                    },
                    bed: {
                      ...data.bed,
                      label: data.bed.name,
                      value: data.bed.bedUid,
                    },
                    bedUid: data.bed.bedUid,
                  },
                });
                setTimeout(() => history.push(`/spa_pos/0`), 100);
              }}
            >
              Add
            </a>
          )}
          {data.canEvaluate && (
            <a
              className="btn btn-rounded btn-space btn-secondary"
              href="#"
              // style={{ color: "blue", marginLeft: 10 }}
              style={{ width: 100 }}
              onClick={(e) => {
                e.preventDefault();
                sendEvaluateJob(data.lineData);
              }}
            >
              Send Evaluate
            </a>
          )}
        </td>
      </tr>
    );
  };

  return (
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th />
          <th>Beds</th>
          <th>Start-End</th>
          <th>Guest</th>
          {/* <th>Agent</th> */}
          <th>Therapist</th>
          <th>GrandTotal</th>
          <th>Status</th>
          <th>Running</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {_.reduce(
          zones,
          (acc, tx, index) => {
            if (filterMode === "room" && roomInput !== tx.roomUid.toString()) {
              return acc; //MODE ZONE
            }

            _.forEach(tx.beds, (bed) => {
              let activeData = data[bed.bedUid];
              // if (filterMode === "active" && !activeData) return; //MODE ACTIVE

              if (!isActive && activeData) return; //ไม่ Show Active
              if (!isAvailable && !activeData) return; //ไม่ Show Active

              let currentStatus = null;

              if (!activeData) {
                currentStatus = "Available";
              } else {
                let endDate = activeData.meta.endDate;
                let diff = moment(endDate).diff(moment());

                if (activeData.isPrintedPreview) {
                  currentStatus = "Preview";
                } else if (diff < 0) {
                  currentStatus = "Complete";
                } else {
                  currentStatus = "Process";
                }
              }

              let isShow = true;
              if (statusFilter === "All") {
                isShow = true;
              } else if (currentStatus !== statusFilter) {
                isShow = false;
              }

              if (isShow === false) return;

              let canEvaluate = false;
              if (
                activeData &&
                !_.isEmpty(activeData.member) &&
                activeData.therapist
              ) {
                canEvaluate = true;
              }
              var lineData = activeData || {
                agent: {},
                therapist: {},
                member: {},
                meta: {},
              };

              let meta = lineData.meta || {};
              var line = (
                <BedLine
                  meta={meta}
                  lineData={lineData}
                  canEvaluate={canEvaluate}
                  bed={bed}
                  activeData={activeData}
                  tx={tx}
                  meta={meta}
                />
              );

              acc.push(line);

              _.forEach(lineData.duplicated, (line) => {
                let canEvaluate = false;
                if (!_.isEmpty(line.member) && line.therapist) {
                  canEvaluate = true;
                }

                let meta2 = line.meta || {};
                acc.push(
                  <BedLine
                    meta={meta}
                    lineData={line}
                    canEvaluate={canEvaluate}
                    bed={bed}
                    activeData={line}
                    tx={tx}
                    meta={meta2}
                  />
                );
              });
            });
            return acc;
          },
          []
        )}
      </tbody>
    </table>
  );
}

//TODO: filter zone

{
  /* <h4>Zone</h4> */
}
{
  /* <div style={{ margin: 20, marginBottom: 10 }}>
        <RadioBox
          label="All"
          checked={filterMode === "all"}
          onClick={() => handleSetFilterMode("all")}
        />
        <RadioBox
          label="Active"
          checked={filterMode === "active"}
          onClick={() => handleSetFilterMode("active")}
        />
        <RadioBox
          label="Zone"
          checked={filterMode === "zone"}
          onClick={() => handleSetFilterMode("room")}
        />
        {filterMode === "zone" && (
          <select
            className="form-control"
            onChange={e => setRoomInput(e.target.value)}
            style={{ display: "inline-block", width: 300 }}
          >
            <option value="none" selected={roomInput === "none"}>
              Select Zone...
            </option>
            {_.map(props.spaRooms, zone => {
              return (
                <option
                  value={zone.roomUid}
                  selected={roomInput === zone.roomUid}
                >
                  {zone.name}
                </option>
              );
            })}
          </select>
        )}
      </div> */
}
