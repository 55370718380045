import _ from "lodash";
import documentClient from "../awsAPIs/dynamoDb";
// import swal from 'sweetalert'
import * as type from "../types/shop";

export const fetchShopDetail = (forceShopId, cb, cbError) => {
  return (dispatch, getState) => {
    const { user } = getState();
    const shopId = forceShopId ? forceShopId : user.loggedIn.shopId;
    let param = {
      TableName: "Minor_Shops",
      Key: {
        uid: shopId
      }
    };
    fetchGet(param, data => {
      dispatch({
        type: type.FETCH_SHOP_DETAIL,
        payload: (data && data.Item) || {}
      });
      cb && cb();

      try {
        if (data.Item.link) {
          dispatch(fetchHqShopDetail(data.Item.link.inventory));
        }
      } catch (error) {
        cbError && cbError(`ไม่มี Shop ที่ระบุ (${shopId})`);
      }
    });
  };
};

export const fetchHqShopDetail = shopId => {
  return (dispatch, getState) => {
    let param = {
      TableName: "Minor_Shops",
      Key: {
        uid: shopId
      }
    };
    fetchGet(param, data => {
      dispatch({
        type: type.FETCH_HQ_SHOP_DETAIL,
        payload: (data && data.Item) || {}
      });
    });
  };
};

const fetchGet = (params, cb, cbError) => {
  documentClient.get(params, (err, data) => {
    if (err) {
      cbError && cbError(err);
      console.log(err);
      // alert(err)
    } else {
      cb(data);
    }
  });
};

export const hqUpdateShopDetail = (data, uid, cb) => {
  return (dispatch, getState) => {
    const { user } = getState();
    let params = {
      TableName: "Minor_Shops",
      Key: {
        uid: uid
      },
      UpdateExpression:
        "set #name = :newName,alias = :newAlias, shippingAddress = :newShippingAddress, province = :newProvince, branchId = :newBranchId, tags = :newTags,vat =:vat",
      ExpressionAttributeNames: {
        "#name": "name"
      },
      ExpressionAttributeValues: {
        ":newShippingAddress": data.shippingAddress,
        ":newName": data.name,
        ":newProvince": data.province,
        ":newBranchId": data.branchId,
        ":newTags": data.tags,
        ":newAlias": data.alias !== "" ? data.alias : null,
        ":vat": data.vat
      }
    };
    documentClient.update(params, function(err, data) {
      if (err) {
        alert(err);
      } else {
        dispatch(fetchShopList(cb));
        // dispatch(fetchShopDetail());
      }
    });
  };
};

export const setHideBranch = (branch, cb) => {
  return (dispatch, getState) => {
    var params = {
      TableName: "Minor_Shops",
      Key: { uid: branch.uid },
      UpdateExpression: "set hide = :hide",
      // ExpressionAttributeNames: {'#a' : 'Sum'},
      ExpressionAttributeValues: {
        ":hide": !branch.hide
      }
    };
    documentClient.update(params, err => {
      if (err) {
        console.log(err);
        return;
      }
      dispatch(fetchShopList(cb));
    });
  };
};

export const selfUpdateShopDetail = (data, cb) => {
  return (dispatch, getState) => {
    const { user } = getState();
    let params = {
      TableName: "Minor_Shops",
      Key: {
        uid: user.loggedIn.shopId
      },
      UpdateExpression: "set #name = :newName, emailReport = :newEmailReport",
      ExpressionAttributeNames: {
        "#name": "name"
      },
      ExpressionAttributeValues: {
        ":newName": data.name,
        ":newEmailReport": data.emailReport
      }
    };
    documentClient.update(params, function(err, data) {
      if (err) {
        alert(err);
      } else {
        cb && cb();
        dispatch(fetchShopDetail());
      }
    });
  };
};

export const updateLogoUrl = (imageUrl, cb) => {
  return (dispatch, getState) => {
    const { user } = getState();
    let params = {
      TableName: "Minor_Shops",
      Key: {
        uid: user.loggedIn.shopId
      },
      UpdateExpression: "set logoUrl = :newLogoUrl",
      // ExpressionAttributeNames: {
      //     "#name": "name",
      // },
      ExpressionAttributeValues: {
        ":newLogoUrl": imageUrl
      }
    };
    documentClient.update(params, function(err, data) {
      if (err) {
        alert(err);
      } else {
        cb && cb();
        dispatch(fetchShopDetail());
      }
    });
  };
};

export const fetchShopList = (cb, forceShopUid) => {
  return (dispatch, getState) => {
    let { user } = getState();
    let param = {
      TableName: "Minor_Shops"
    };
    scan(param, data => {
      let shopList = [];

      _.forEach(data.Items, shop => {
        if (!shop.link) {
          return;
        }
        if (
          shop.link.inventory &&
          shop.link.inventory === (forceShopUid || user.loggedIn.shopId)
        ) {
          shopList.push(shop);
        }
      });

      shopList = _.orderBy(shopList, "branchId", "asc");

      dispatch({
        type: type.FETCH_SHOP_LIST,
        payload: { inventory: shopList }
      });

      setTimeout(cb, 500);
    });
  };
};

const scan = (params, cb) => {
  documentClient.scan(params, (err, data) => {
    if (err) {
      console.log(err);
      // alert(err)
    } else {
      cb(data);
    }
  });
};
