import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import ManageVoucher from "./ManageVoucher";
import VoucherList from "./VoucherList";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as spaVoucherActions from "../../shared/actions/spaVoucherActions";

function Voucher(props) {
  const { match } = props;
  return (
    <div className="main-content">
      <Switch>
        <Route
          path={`${match.url}/manage/:storeUid/:voucherCampaignUid`}
          render={rProps => <ManageVoucher {...props} {...rProps} />}
        />
        <Route
          path={`${match.url}/manage`}
          render={rProps => <ManageVoucher {...props} {...rProps} />}
        />
        <Route
          path={`${match.url}`}
          render={rProps => <VoucherList {...props} {...rProps} />}
        />
      </Switch>
    </div>
  );
}

const mapStateToProps = state => {
  //   let link = { inventory: null };
  //   if (state.shop.detail.link) {
  //     link = state.shop.detail.link.inventory;
  //   }
  return {
    // linked: link,
    // branchOptions: branchSelector.branchOptionsSelector(state)
    isHq: !state.shop.linked.inventory,
    shop: state.shop.detail,
    voucherCampaign: state.spa.voucher.campaign
  };
};
function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators(
    {
      ...spaVoucherActions
    },
    dispatch
  );
  return {
    actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Voucher);
