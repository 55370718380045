import React from "react";
import _ from "lodash";
import BranchDetailModal from "./BranchDetailModal";
import moment from "moment";
import getVatName from "../functions/getVatName";
import Loader from "./Loader";

class ShopListTable extends React.Component {
  state = {
    selectedBranch: {},
    loading: false
  };

  getTagsString = tagsObj => {
    let strTags = [];
    let index = 0;
    _.map(tagsObj, (bool, tagName) => {
      strTags.push(
        <span
          class="badge badge-pill badge-primary"
          style={{ marginLeft: index === 0 ? 0 : 5 }}
        >
          {tagName}
        </span>
      );
      index++;
    });
    return strTags;
  };
  selectBranch = branch => {
    this.setState({
      selectedBranch: {
        ...branch,
        timestamp: moment().toISOString() //FORCE STATE TO CHANGE
      }
    });
  };

  setHideBranch = branch => {
    const { fetchShop, actions } = this.props;
    this.setState({ loading: true });
    actions.setHideBranch(branch, () => {
      fetchShop();
      this.setState({ loading: false });
    });
  };

  render() {
    const { shops, match, fetchShop } = this.props;
    const { selectedBranch, loading } = this.state;
    return (
      <div>
        {loading && <Loader />}
        <BranchDetailModal
          match={match}
          selectedBranch={selectedBranch}
          timestamp={moment().toISOString()}
          fetchShop={fetchShop}
        />
        <table className="table table-sm table-hover table-striped table-bordered">
          <thead>
            <tr>
              <th>รหัสสาขา</th>
              <th>Alias</th>
              <th>ชื่อร้าน/สาขา</th>
              <th>จังหวัด</th>
              <th>Vat</th>
              <th>Tags</th>
              <th>Hide</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.map(shops, (shop, i) => {
              return (
                <tr>
                  <td>
                    <span style={{ paddingLeft: 8 }}>{shop.branchId}</span>
                  </td>
                  <td>
                    <span style={{ paddingLeft: 3 }}>{shop.alias}</span>
                  </td>
                  <td>
                    <span style={{ paddingLeft: 3 }}>{shop.name}</span>
                  </td>
                  <td>
                    <span style={{ paddingLeft: 3 }}>
                      {(shop.province && shop.province.label) || shop.province}
                    </span>
                  </td>
                  <td>
                    {shop.vat && shop.vat.mode !== 0 && (
                      <span>
                        {getVatName(shop.vat.mode)}{" "}
                        {shop.vat.mode !== 0 && <span>{shop.vat.value}%</span>}
                      </span>
                    )}
                  </td>
                  <td>
                    <span style={{ paddingLeft: 3 }}>
                      {this.getTagsString(shop.tags)}
                    </span>
                  </td>
                  <td>
                    <div
                      className="be-checkbox custom-control custom-checkbox"
                      onClick={() => this.setHideBranch(shop)}
                    >
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        checked={shop.hide === true}
                      />
                      <label className="custom-control-label" for="check1" />
                    </div>
                  </td>
                  <td>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#branchDetailModal"
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.selectBranch(shop);
                      }}
                    >
                      View
                    </a>

                    <a
                      href="#"
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.props.deleteShop(shop);
                      }}
                      style={{ color: "red", paddingLeft: 10 }}
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ShopListTable;
