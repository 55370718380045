import { createSelector } from "reselect";
import _ from "lodash"; 

const empList = state => state.spa.sales.employees;
const agentList = state => state.spa.sales.agents;
const entertaintList = state => state.spa.sales.entertains;
const salesList = state => state.spa.sales.sales;
const paymentTypesState = state => state.setting.mother.paymentType.value;



export const paymentTypesSelector = createSelector([paymentTypesState], (paymentList)=>{
  return getOptions(paymentList)
})


export const agentOptionsSelector = createSelector(
  [agentList],
  agentList => {
    return getOptions(agentList);
  }
);
export const salesOptionsSelector = createSelector(
  [salesList],
  salesList => {
    return getOptions(salesList);
  }
);

export const salesObjSelector = createSelector(
  [salesList],
  salesList => {
    return getObj(salesList);
  }
);

export const entertainOptionsSelector = createSelector(
  [entertaintList],
  entertaintList => {
    return getOptions(entertaintList);
  }
);

export const employeeOptionsSelector = createSelector(
  [empList],
  empList => {
    return getOptions(empList);
  }
);

export const employeeObjSelector = createSelector(
  [empList],
  empList => {
    return getObj(empList);
  }
);

export const agentObjSelector = createSelector(
  [agentList],
  agentList => {
    return getObj(agentList);
  }
);

export const entertainObjSelector = createSelector(
  [entertaintList],
  entertaintList => {
    return getObj(entertaintList);
  }
);

function getObj(list) {
  let obj = {};
  _.map(list, i => {
    obj[i.uid] = i;
  });
  return obj;
}

function getOptions(list) {
  let options = [];
  _.map(list, i => {
    options.push({
      ...i,
      value: i.uid,
      label: i.name
    });
  });
  return options;
}
