import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  ReceiptRow,
  ReceiptHeader,
  ReceiptGroup,
  ReceiptGroupRow2,
  ReceiptGroupRow
} from "./ReceiptRow";
import { useRadioViewButtons } from "./Components";
import { computeMinutes } from "../../../shared/utils";
import moment from "moment";
import {
  GroupDateHeader,
  GroupDateRow,
  GroupNameRowSection,
  GroupDateTable
} from "./ReceiptGroupDateRow";
import {
  groupByDate,
  groupByDateMultiTherapist
} from "../../../shared/utils/receipts";
import DashboardTherapistTable from "./DashboardTherapistTable";
import axios from "axios";
import { downloadableLinkUrl } from "../../../shared/utils";
import { useDispatch } from "react-redux";
import * as appStateAction from "../../../shared/actions/appStateActions";
import Select from "react-select";

function DashboardTherapist(props) {
  const { receipts, receipts_entertain, dashboardDispatch } = props;
  const dispatch = useDispatch();

  const [therapistGroups, setTherapistGroups] = useState([]);
  const [therapistGroupsByDate, setTherapistGroupsByDate] = useState({});
  const [view, Component] = useRadioViewButtons("detail", { groupDate: true });

  const [filterTherapist, setFilterTherapist] = useState([]);

  useEffect(() => {
    let _res = computeGroup(
      _.concat(receipts, receipts_entertain),
      props.employeeObj
    );
    // let ordered = _.orderBy
    setTherapistGroups(_res);
  }, [receipts, receipts_entertain]);

  useEffect(() => {
    if (view === "date") {
      // split therapist list  to => single therapist
      let receipts_added_multiple_therapist = _.reduce(
        receipts,
        (acc, r) => {
          if (r.therapistList && r.therapistList.length >= 1) {
            r.therapistList.map(t =>
              acc.push({ ...r, therapist: { name: t.name } })
            );
          } else {
            acc.push(r);
          }

          return acc;
        },
        []
      );

      let res = groupByDate(receipts_added_multiple_therapist, "therapist"); // single therapist;
      setTherapistGroupsByDate(res);
    }
  }, [view, receipts]);

  function viewCallback(receipt) {
    dashboardDispatch({
      type: "SET_MODAL",
      payload: {
        data: receipt,
        visible: true
      }
    });
  }

  const downloadPdf = () => {
    let URL =
      "https://asia-east2-niceloop-dev.cloudfunctions.net/PDFSummaryByDate";
    let data = {
      mode: "Therapist",
      startDate: props.startDate,
      endDate: props.endDate,
      shopId: props.shop.uid,
      filter: filterTherapist.length > 0 ? filterTherapist : null
    };

    dispatch(appStateAction.loading(true));

    axios
      .post(URL, data)
      .then(res => {
        downloadableLinkUrl("report-summary-excel.xlsx", res.data);
      })
      .catch(err => alert(err))
      .finally(() => {
        dispatch(appStateAction.loading(false));
      });
  };

  return (
    <div class="">
      <div class="col-lg-12">
        <div class="card card-table">
          <div class="card-header">
            {Component}
            Group by {_.keys(therapistGroups).length} Employees
            <div className="row m-0">
              <div className="col-6 pl-0">
                <Select
                  isMulti
                  value={filterTherapist}
                  onChange={setFilterTherapist}
                  options={props.therapistOptions}
                  placeholder="All"
                />
              </div>
              <div>
                <button
                  class="btn btn-space btn-secondary"
                  onClick={downloadPdf}
                >
                  Export PDF
                </button>
              </div>
            </div>
          </div>

          <div class="card-body pb-7">
            {view !== "date" && (
              <table class="table table-small table-striped">
                {view === "detail" && (
                  <ReceiptHeader showAgent={false} showTherapist={false} />
                )}
                <tbody>
                  {_.map(therapistGroups, (group, idx) => {
                    let receipts = group.list;
                    return [
                      <ReceiptGroupRow2
                        colSpan1={5}
                        name={group.name}
                        col1Text={"Time : " + sumComputeMinute(receipts) + " "}
                        col2Text={
                          "GrandTotal : " +
                          _.sumBy(receipts, "payment.grandTotal") +
                          ` (${receipts.length})`
                        }
                      />,
                      view === "detail" &&
                        _.map(receipts, (r, i) => {
                          return (
                            <ReceiptRow
                              viewCallback={() => viewCallback(r)}
                              showTherapist={false}
                              showAgent={false}
                              showVoidButton={false}
                              index={i + 1}
                              {...r}
                            />
                          );
                        })
                    ];
                  })}
                </tbody>
              </table>
            )}
            {view === "date" && [
              <div class="row">
                <div class="col-2"> </div>
                <div class="col-8">
                  {" "}
                  <GroupDateTable
                    groupReceiptsByDateHolder={therapistGroupsByDate}
                  />{" "}
                </div>
              </div>
            ]}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardTherapist;

function computeGroup(receipts, employeeObj) {
  let singleTherReceipts = [];
  let multiTherReceipts = [];
  let unknownTherReceipts = [];
  let combinedTherGroup = {};
  _.forEach(receipts, rec => {
    if (rec.therapistList && rec.therapistList.length >= 1) {
      multiTherReceipts.push(rec);
    } else if (rec.therapist_backup) {
      singleTherReceipts.push(rec);
    } else {
      unknownTherReceipts.push(rec);
    }
  });

  //SINGLE
  combinedTherGroup = _.groupBy(
    singleTherReceipts,
    r => `${r.therapist_backup.name}#NICE#${r.therapist_backup.uid}`
  );

  //MULTI THER
  _.forEach(multiTherReceipts, rec => {
    _.forEach(rec.therapistList, ther => {
      if (!combinedTherGroup[ther.name]) {
        combinedTherGroup[ther.name] = [];
      }
      //COMPUTE TIME
      let forceTimeMinutes = 0;
      _.forEach(ther.items, item => {
        if (!item.timeMinutes) return;
        forceTimeMinutes += item.qty * item.timeMinutes;
      });
      combinedTherGroup[ther.name].push({ ...rec, forceTimeMinutes });
    });
  });

  //DON'T HAVE THERAPIST
  combinedTherGroup["Retail"] = unknownTherReceipts;
  //convert to array
  let groupArr = [];
  _.map(combinedTherGroup, (arr, key) => {
    let splited = _.split(key, "#NICE#");
    let name = splited[0];
    let uid = splited[1];
    let data = {
      name: name,
      uid: uid,
      list: arr,
      row: moment().valueOf()
    };

    if (employeeObj[uid]) {
      data.row = employeeObj[uid].row;
    }
    groupArr.push(data);
  });

  let ordered = _.orderBy(groupArr, "row", "asc");

  return ordered;
}

function sumComputeMinute(receipts) {
  let totalMins = _.reduce(
    receipts,
    (acc, r) => {
      if (r.forceTimeMinutes) {
        acc += r.forceTimeMinutes;
      } else {
        acc = acc + computeMinutes(r.meta.startDate, r.meta.endDate);
      }
      return acc;
    },
    0
  );

  let time = `${Math.floor(totalMins / 60)}h  ${(totalMins % 60).toFixed(0)}m `;

  return time;
}
