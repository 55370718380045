import _ from "lodash";
const combineJobItemsToBillItems = (items, promotionItems) => {
  let newPromoItems = [];
  _.forEach(promotionItems, item => {
    newPromoItems.push({ ...item, promotion: true });
  });

  let newItems = [];
  _.forEach(items, item => {
    let disAmount = 0;
    let disMode = item.discount && item.discount.mode;
    if (disMode === 0) {
      disAmount = (item.price * item.discount.value) / 100;
    } else if (disMode === 1) {
      disAmount = item.discount.value;
    } else {
    }
    newItems.push({
      ...item,

      extendedPrice: (item.qty || 1) * item.price,

      discountedPrice: (item.qty || 1) * (item.price - disAmount)
    });
  });

  let combined = [...newItems, ...newPromoItems];
  return combined;
};

export default combineJobItemsToBillItems;
