import React, { Component } from "react";
import { connect } from "react-redux";
import Navbar from "./navbar";
import LeftSideBarDev from "./leftSideBarDev";
import LeftSideBarProd from "./leftSideBarProd";

import MainContent from "./MainContent";
import "react-datasheet/lib/react-datasheet.css";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import BlockUi from "react-block-ui";
import EmployeePinInputModal from "../shared/components/EmployeePinInputModal";
import "./Loading.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import AlertBar from "./AlertBar";
import BlockScreenMainContent from "../shared/components/BlockScreenMainContent";
import Loader from "../shared/components/Loader";
const isLocalhost =
  _.includes(window.location.href, "localhost") ||
  _.includes(window.location.href, "192.");

class Container extends Component {
  componentDidMount = () => {
    // NotificationManager.info('Info message');
  };

  showPinPromptModal = () => {
    const { empList, currentEmp } = this.props;
    if (empList.length < 1) {
      return false;
    } else if (!currentEmp) {
      return true;
    } else {
      return false;
    }
  };

  

  render() {
    const { history, currentEmp, initLoad, linked } = this.props;
    if (initLoad) {
      return <Loader />;
      // return <div className="main-content">Loading...</div>;
    }

    let path = history.location.pathname;
    let containerClassName = "be-wrapper";
    if (
      _.includes(path, "/inventory") ||
      // _.includes(path, "/promotion_retail") ||
      _.includes(path, "/shopping") ||
      _.includes(path, "/spa_room")
    ) {
      containerClassName = "be-wrapper be-aside be-fixed-sidebar";
    }

    return (
      <div className={containerClassName}>
        {this.props.isLoading && <BlockScreenMainContent />}
 
        <EmployeePinInputModal visible={this.showPinPromptModal()} />

        <NotificationContainer />
        <AlertBar {...this.props} />
        <Navbar />

        {/* {isLocalhost ? (
            <LeftSideBarDev history={this.props.history} />
          ) : (
            <LeftSideBarProd history={this.props.history} />
          )} */}
        {/* <LeftSideBarDev history={this.props.history} /> */}

        <LeftSideBarProd history={this.props.history} />

        <div className="be-content">
          <MainContent linked={linked} {...this.props} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.appState.loading,
    currentEmp: state.employee.current,
    empList: state.employee.list,
    linked: state.shop.linked.inventory,
    initLoad: state.loading.initLoad,
    shop: state.shop.detail
  };
};
export default withRouter(connect(mapStateToProps)(Container));
