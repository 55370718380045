import React, { useState } from "react";

function DashboardActionsBar(props) {
  const [count, setCount] = useState(0);
  return [
    <div style={{ overflow: "hidden" }}>
      <div
        // class="col-12"
        style={{
          margin: 20,
          marginBottom: 10,
          width: "97%",
          overflowX: "auto",
          whiteSpace: "nowrap"
        }}
      >
        {/* <ButtonTab label="Chart" to="chart" {...props} forceOnClick={() => {
          alert('ขออภัย, หน้านี้ยังอยู่ในขั้นตอนการปรับปรุง')
        }}/> */}
        <ButtonTab label="Receipts" to="receipts" {...props} />
        <ButtonTab label="Payment Types" to="payment" {...props} />
        {/* <ButtonTab label="Payment Agent" to="agent" {...props} /> */}
        {/* <ButtonTab label="Payment Entertain" to="entertain" {...props} /> */}

        <ButtonTab label="Products" to="products" {...props} />
        {/* <ButtonTab label="Product Table" to="product-table" {...props} /> */}
        {/* <ButtonTab label="Drawer" to="drawer" {...props} /> */}

        {/* <ButtonTab label="Voids" to="voids" {...props} /> */}
        {/* <ButtonTab label="Sales" to="sales" {...props} /> */}

        {/* <ButtonTab label="Therapist" to="therapist" {...props} />
        <ButtonTab label="Therapist Table" to="therapist-table" {...props} /> */}

        {/* <ButtonTab label="Member"  to='member'  {...props}/> */}
        {/* <ButtonTab label="SatRate" to="satrate" {...props} /> */}
        <ButtonTab label="Export / Print" to="export" {...props} />
      </div> 
    </div>,
    <div className="dropdown-divider mt-0 mb-0" />
  ];
}

export default DashboardActionsBar;

function ButtonTab({ label, view, to, dispatch ,forceOnClick}) {
  return (
    <button
      className={`btn btn-space ${
        view === to ? "btn-primary" : "btn-secondary"
      }`}
      onClick={() => {
        if(forceOnClick){
          forceOnClick()
          return
        }
        dispatch({
          type: "SET",
          key: "view",
          payload: to
        });
      }}
    >
      {label}
    </button>
  );
}
