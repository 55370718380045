import React, { useState } from "react";

const MODAL_NAME = "customContentModal";

function CustomContentModal(props) {
  return (
    <div
      class="modal"
      id={MODAL_NAME}
      tabindex="-1"
      role="dialog"
      aria-labelledby={`${MODAL_NAME}Label`}
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id={`${MODAL_NAME}Label`}>
              {props.title}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">{props.children}</div>
          <div class="modal-footer pt-2">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            {/* <button type="button" class="btn btn-primary">
              Save changes
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomContentModal;
