import React, { useState, useEffect } from "react";
import ManageCustomer from "../SpaPos/ManageCustomer";
import MemberReceipt from "./MemberReceipt";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import SpaBillModal from "../../shared/components/SpaBillModal";
import SummaryCard from "../SpaReport/SummaryCard";
import MemberProduct from "./MemberProduct";
import MemberStoreVisited from "./MemberStoreVisited";
import _ from "lodash";
import MemberTxTable from "../../shared/components/WalletModal/MemberTxTable";
import MemberInternalNote from "./MemberInternalNote";
import moment from "moment";

function MemberProfileDetail(props) {
  const [receipts, setReceipts] = useState([]);
  const [modalContent, setModalContent] = useState({});
  const [tab, setTab] = useState("receipt");
  const [walletTxList, setWalletTxList] = useState([]);

  const [sumGrandTotal, setSumGrandTotal] = useState(0);

  useEffect(() => {
    let sum = _.sumBy(receipts, rec => {
      return rec.payment.grandTotal;
    });
    setSumGrandTotal(sum);
  }, [receipts]);

  useEffect(() => {
    fetchMemberWalletTx();
  }, []);

  const fetchMemberWalletTx = () => {
    // let mode = forceMode || txViewMode;
    let mode = "wallet";

    props.actions.fetchMemberTx(props.member.uid, mode, items => {
      setWalletTxList(items);
    });
  };

  const fetchMemberReceipts = () => { 
    var params = {
      TableName: "Spa_Receipts",
      IndexName: "memberUid-timestamp-index",
      KeyConditionExpression: "memberUid = :memberUid",
      ExpressionAttributeValues: {
        ":memberUid": props.member.uid
      }
    };

    documentClient.query(params, function(err, data) {
      if (err) console.log(err);
      else {
        setReceipts(data.Items);
      }
    });
  };

  useEffect(() => {
    fetchMemberReceipts();
  }, []);

  return (
    <div>
      <SpaBillModal {...props} {...modalContent} />
      <div className="row m-0 mt-3">
        <div className="col-6">
          <div className="card">
            <ManageCustomer {...props} isDesktop={true} />
          </div>
        </div>
        <div className="col-6">
          <div className="row m-0 mb-2">
            <SummaryCard
              title={sumGrandTotal.toLocaleString()}
              subtitle="Grand Total"
            />
            <SummaryCard
              title={_.size(receipts)}
              subtitle="Bills"
              paddingLeft={10}
            />
            <SummaryCard
              title={(props.member.walletBalance || 0).toLocaleString()}
              subtitle="Wallet"
              paddingLeft={10}
            />
            {props.member.lastVisited && (
              <SummaryCard
                title={moment(props.member.lastVisited).format("DD/MM/YYYY")}
                subtitle={`Last Visited (${moment(
                  props.member.lastVisited,
                  "YYYYMMDD"
                ).fromNow()})`}
                paddingLeft={10}
              />
            )}
          </div>

          <div class="tab-container">
            <ul class="nav nav-tabs" role="tablist">
              <Tab
                label="Receipt"
                active={tab === "receipt"}
                onClick={() => setTab("receipt")}
              />
              <Tab
                label="Product"
                active={tab === "product"}
                onClick={() => setTab("product")}
              />
              <Tab
                label="Store Visited"
                active={tab === "store"}
                onClick={() => setTab("store")}
              />
              <Tab
                label="Wallet Transaction"
                active={tab === "walletTx"}
                onClick={() => setTab("walletTx")}
              />
              <Tab
                label="Internal Notes"
                active={tab === "internalNotes"}
                onClick={() => setTab("internalNotes")}
              />
            </ul>
            {tab === "receipt" && (
              <MemberReceipt
                {...props}
                receipts={receipts}
                setModalContent={setModalContent}
              />
            )}
            {tab === "product" && (
              <MemberProduct {...props} receipts={receipts} />
            )}
            {tab === "store" && (
              <MemberStoreVisited {...props} receipts={receipts} />
            )}
            {tab === "walletTx" && (
              <div className="card">
                <MemberTxTable txList={walletTxList} />
              </div>
            )}

            {tab === "internalNotes" && (
              <div className="card">
                <MemberInternalNote member={props.member} {...props} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberProfileDetail;

const Tab = ({ active, label, onClick }) => {
  return (
    <li class="nav-item">
      <a
        class={`nav-link ${active ? "active" : ""}`}
        href="#home"
        data-toggle="tab"
        role="tab"
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
      >
        {label}
      </a>
    </li>
  );
};
