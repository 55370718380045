import React from 'react'
import ReactDataSheet from 'react-datasheet';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as inventoryGroupActions from '../../shared/actions/inventoryGroupActions'
import * as inventoryTypeActions from '../../shared/actions/inventoryTypeActions'

import _ from 'lodash'
import swal from 'sweetalert'

class AddInventoryGroupAndType extends React.Component {
 
    state = {
        grid: [],
        pickedTypeUid: 'none'
    }
    componentDidMount() {
        this.createRows()

        const { match, mode, types } = this.props
        let checkType = _.filter(types, i => i.uid === match.params.typeUid)
        if (mode !== 'type') {
            if (checkType.length > 0) {
                this.setState({
                    pickedTypeUid: match.params.typeUid
                })
            }
        }
    }

    createRows = () => {
        let rowButton = < a href="#"
            onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                this.addRow()
            }}> เพิ่มแถว</a >
        let grid = [
            [{ readOnly: true, value: rowButton },
            { readOnly: true, value: 'Name', cantAdd: true },
            ]
        ]
        for (let i = 1; i < 16; i++) {
            grid.push([{ readOnly: true, value: i }, { value: '' }])
        }
        this.setState({ grid })
    }

    addRow = () => {
        let { grid } = this.state
        let newGrid = [...grid, [{ readOnly: true, value: grid.length }, { value: '' }]]
        this.setState({ grid: newGrid })
    }

    submit = () => {
        let { actions, history, mode } = this.props
        let { grid, pickedTypeUid } = this.state
        let groups = []

        const gridToBeComputed = [...grid.slice(1)]

        _.forEach(gridToBeComputed, (i) => {
            if (i[1].value !== '') {
                groups.push(i[1].value)
            }
        })

        if (groups.length > 0) {
            if (mode === 'type') {
                actions.addInventoryTypes(groups, () => history.push('/inventory'))
            }
            else {
                if (pickedTypeUid === 'none') {
                    swal("เกิดข้อผิดพลาด", 'กรุณาเลือก Type', "error");
                    return
                }
                actions.addInventoryGroups(groups, pickedTypeUid, () => history.push('/inventory'))
            }
        }
        else {
            swal("เกิดข้อผิดพลาด", 'กรุณากรอกข้อมูล', "error");
        }
    }

    render() {
        let { history, mode, types } = this.props
        const { pickedTypeUid } = this.state
        return (
            <div style={{ marginTop: 20 }}>
                <div className="row m-0">
                    <div className="col-md-8 offset-md-2">
                        <div className="card card-contrast" >
                            <div className="card-header  card-header-featured">
                                <div className="row justify-content-between" style={{ marginLeft: 15, marginRight: 15 }}>
                                    <div>
                                        <button style={{ marginTop: 16 }}
                                            onClick={() => history.push('/inventory')}
                                            className="btn btn-space btn-secondary hover">
                                            <i className="icon icon-left mdi mdi-chevron-left"></i>
                                            Back</button>
                                    </div>
                                    <div style={{ marginTop: 16 }}>{mode === 'type' ? `Add Inventory Type` : `Add Inventory Groups`}</div>
                                    {
                                        mode !== 'type' &&
                                        <div>
                                            <select className="form-control"
                                                onChange={e =>
                                                    this.setState({
                                                        pickedTypeUid: e.target.value
                                                    })}>
                                                <option value="none" selected={pickedTypeUid === "none"}>เลือก Type</option>
                                                {_.map(types, type => {
                                                    return <option value={type.uid} selected={type.uid === pickedTypeUid}>{type.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    }
                                    <div >
                                        <button style={{ marginTop: 16 }}
                                            onClick={this.submit}
                                            className="btn btn-space btn-success hover">
                                            <i className="icon icon-left mdi mdi-collection-plus"></i>Add</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body" style={{ padding: 0 }}>
                                <div >

                                    <ReactDataSheet
                                        // width="100%"
                                        data={this.state.grid}
                                        valueRenderer={(cell) => cell.value}
                                        onCellsChanged={changes => {
                                            const grid = this.state.grid.map(row => [...row])
                                            changes.forEach(({ cell, row, col, value }) => {
                                                grid[row][col] = { ...grid[row][col], value }
                                            })
                                            this.setState({ grid })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        )
    }
}


const mapStateToProps = (state) => {
    return {
        types: state.inventory.types,
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ ...inventoryGroupActions, ...inventoryTypeActions }, dispatch)
    return {
        actions,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddInventoryGroupAndType)