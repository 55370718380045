import React, { useState } from "react";
import PrintOpenShop from "../../printer/service.print.openshop";
import {useDispatch , useSelector} from 'react-redux';
import useDrawerReducer from "./hook.drawer.reducer";
import * as firestore from '../../shared/firebaseApi/firebaseRef'
import moment from 'moment'
function Opening({ drawerState, drawerDispatch, setView }) {
  const [value, setValue] = useState(0);
  const globalDispatch  = useDispatch()
  const   globalState =    useSelector ((state)=>state)
  function submit() {
    drawerDispatch({
      type: "DRAWER_START",
      payload: value || 0,
      callback (newDrawerState){
        print(newDrawerState)
      }
    });

    let { shop } = globalState;

    firestore.appStateRef.doc(shop.detail.uid).set({
      businessDate : moment().format("YYYY-MM-DD"),
      openTime : moment().format(),
      drawer : {
        ...drawerState
      },
      info : shop.detail
    })

    alert("Shop opened.")

    
  }

  async function print (newDrawerState){
    debugger
      console.log(drawerState)
    let print = new PrintOpenShop ( globalState , globalDispatch, newDrawerState   );
    print.build().print();
  }

  return (
    <div class="row mt-7">
      <div class="col-3"> </div>
      <div class="col-6">
        <div class="row">
          <div class="col-3">
            <h3 class="text-right">เงินเริ่มต้น</h3>
          </div>
          <div class="col-9">
            <input
              value={value}
              onChange={evt => setValue(evt.target.value)}
              class="form-control"
              id="inputText3"
              type="text"
            />
          </div>

          <div class="col-3" />
          <div class="col-9 mt-5">
            <button onClick={submit} class=" btn btn-space btn-primary btn-lg">
              <i class="icon icon-left mdi mdi-cloud-done" />Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  
}

export default Opening;
