export const displayBookingStatusFromWeb = (status) => {
  let text = "UNKNOWN";
  switch (status) {
    case "1":
      text = "New";
      break;
    case "2":
      text = "Cancel";
      break;

    case "3":
      text = "Paid";
      break;
    case "4":
      text = "Wait, (Online booking ยังไม่ตัดเงิน)";
      break;

    case "5":
      text = "Refund";
      break;

    case "6":
      text = "Confirm";
      break;

    case "7":
      text = "Reject";
      break;
    default:
      text  = null;
      break;
  }
  return text
};

export const displayBookingPaymentFromWeb = (status) => {
    let text = "";
    switch (status) {
      case 1:
        text = "Exclusive";
        break;
      case 2:
        text = "Online";
        break;
  
      
      default:
        break;
    }
    return text
  };
  