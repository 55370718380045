import _ from "lodash";
import documentClient from "../awsAPIs/dynamoDb";
import * as type from "../types/setting";
// import * as appStateActions from "./appStateActions";

export const fetchSetting = () => {
  //FETCH CURRENT AND MOTHER
  return (dispatch, getState) => {
    const { user, shop } = getState();
    let shopId = user.loggedIn.shopId;
    const link = shop.detail.link;
    let motherShopId = null;

    if (link) {
      if (link.inventory ) {
        motherShopId = link.inventory;
      }
    }

    let params = {
      TableName: "Settings",
      KeyConditionExpression: "uid = :uid",
      ExpressionAttributeValues: {
        ":uid": shopId
      }
    };
    fetch(params, data => {
      dispatch({
        type: type.FETCH_SETTING_CURRENT,
        payload: convertToObj(data.Items)
      });
    });
    if (!motherShopId) return;

    let params2 = {
      TableName: "Settings",
      KeyConditionExpression: "uid = :uid",
      ExpressionAttributeValues: {
        ":uid": motherShopId
      }
    };
    fetch(params2, data => {
      dispatch({
        type: type.FETCH_SETTING_MOTHER,
        payload: convertToObj(data.Items)
      });
    });
  };
};

const fetch = (params, cb) => {
  documentClient.query(params, (err, data) => {
    if (err) {
      console.log(err);
      // alert(err)
    } else {
      cb(data);
    }
  });
};

const convertToObj = datas => {
  let obj = {};
  _.forEach(datas, data => {
    obj[data.name] = data;
  });
  return obj;
};

//UPDATE BY KEY
export const updateSetting = (key, data, cb) => {
  return async (dispatch, getState) => {
    const { shop } = getState();
    const shopId = shop.detail.uid;
    var params = {
      TableName: "Settings",
      Item: {
        uid: shopId,
        name: key,
        value: data
      }
    };
    try {
      await documentClient.put(params).promise();
      cb && cb();
    } catch (error) {
      console.log(error);
    }
  };
};
