import _ from "lodash";
const getVoucherDiscount = (items, vouchers) => {
  let total = 0;

  let voucherObj = {};
  _.forEach(vouchers, v => {
    voucherObj[v.cartRow] = v;
  });
  _.forEach(items, item => {
    if (voucherObj[item.timestamp]) {
      total += item.price * item.qty;
    }
  });
  debugger;
  return total;
};

export default getVoucherDiscount;
