import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { ReceiptRow, ReceiptHeader, ReceiptTableContainer } from "./ReceiptRow";
import VoidReceipt from "./VoidReceipt";
import { voidBill } from "../../../shared/actions/spaPosActions";
import { checkPermission } from "../../../shared/utils";
import useEmployeeHook from "../../../shared/useHook/useEmployeeHook";
import * as receiptsService from "../../../shared/services/receipts";
import { useSelector } from "react-redux";
import useReceiptsHook from "../../../shared/useHook/useReceiptsHook";
import moment from "moment";

function DashboardReceipts(props) {
  const {
    reportReducer,
    reportDispatch,
    history,
    hqViewBranch,
    hideBackBtn = true,
  } = props;

  const dispatch = useDispatch(); //main-store
  const [receipts, setReceipts] = useState([]);
  const [receiptVoids, setReceiptVoids] = useState([]);

  const { data, isloading } = useReceiptsHook(reportReducer.startDate);

  // useEffect(() => {
  //   if (props.isHq) {
  //     // fetchReceipts();
  //   }
  // }, []);

  useEffect(() => {
    setReceiptVoids(_.filter(data, (r) => r.void));

    setReceipts(_.filter(data, (r) => !r.void));
  }, [data]);
  //TODO: filter tag,   retail,  entertain,  prepaid, voucher, refID
  //TODO: download  PDF,  Excel,  Thermal

  const [currentEmployee, employeeList] = useEmployeeHook();
  function viewCallback(receipt) {
    reportDispatch({
      type: "SET_MODAL",
      payload: {
        data: receipt,
        visible: true,
      },
    });
  }

  function deleteCallback(receipt) {
    let access = checkPermission(currentEmployee, employeeList, "voidBills");
    if (access === false) {
      return alert("You don't have permission to delete bills.");
    }

    let reason = window.prompt("Enter reason.");
    if (reason) {
      dispatch(voidBill(receipt, reason, () => {}));
    }
  }
  return (
    <div class="pb-7">
      <div>
        {!hideBackBtn && (
          <button
            class="btn btn-space btn-secondary"
            onClick={() => history.goBack()}
          >
            <i class="icon icon-left mdi mdi-chevron-left"></i>Back
          </button>
        )}
        <div class="card card-table">
          <div class="card-header">
            {hqViewBranch && (
              <span style={{ color: "blue", fontWeight: "bold" }}>
                {hqViewBranch.name}
              </span>
            )}{" "}
            {moment(reportReducer.startDate).format("DD/MM/YYYY")}{" "}
            <span class="ml-3">
              {" "}
              Receipts : {_.sumBy(receipts, "payment.grandTotal")}
            </span>{" "}
            ({receipts.length})
            <div class="tools dropdown">
              <span class="icon mdi mdi-download" />
              <a
                class="dropdown-toggle"
                href="#"
                role="button"
                data-toggle="dropdown"
              >
                <span class="icon mdi mdi-more-vert" />
              </a>
              <div class="dropdown-menu" role="menu">
                <a class="dropdown-item" href="#">
                  Action
                </a>
                <a class="dropdown-item" href="#">
                  Another action
                </a>
                <a class="dropdown-item" href="#">
                  Something else here
                </a>
                <div class="dropdown-divider" />
                <a class="dropdown-item" href="#">
                  Separated link
                </a>
              </div>
            </div>
          </div>
          <div class="card-body pb-7">
            <ReceiptTableContainer>
              <ReceiptHeader />
              <tbody>
                {_.map(receipts, (r, i) => {
                  return (
                    <ReceiptRow
                      deleteCallback={() => deleteCallback(r)}
                      viewCallback={() => viewCallback(r)}
                      linkToKey="entertains_cityLedger"
                      {...r}
                      index={i + 1}
                    />
                  );
                })}
              </tbody>
            </ReceiptTableContainer>
          </div>
          <VoidReceipt
            receipts={receipts}
            receiptVoids={receiptVoids}
            reportDispatch={reportDispatch}
          />
        </div>
      </div>
    </div>
  );
}

export default DashboardReceipts;
