import randomString from "random-string";
import _ from "lodash";
import documentClient from "../awsAPIs/dynamoDb";
import moment from "moment";
import async from "async";
import swal from "sweetalert";
import * as type from "../types/inventoryItems";
import * as appStateActions from "./appStateActions";
import * as inventorySelector from "../selector/inventorySelector";
import { s3 } from "../awsAPIs/s3";

export const fetchInventory = cbSuccess => {
  return async (dispatch, getState) => {
    const { user, shop } = getState();
    let shopId = user.loggedIn.shopId;
    const link = shop.detail.link;

    if (link) {
      if (link.inventory) {
        shopId = link.inventory;
      }
    }
    let paramItem = {
      TableName: "Minor_Inventory_Items",
      KeyConditionExpression: "shopId = :shopId",
      ExpressionAttributeValues: {
        ":shopId": shopId
      }
    };
    let resOverridePrice = await fetchBranchOverridePrice(user.loggedIn.shopId);

    fetch(paramItem, data => {
      let computedItems = data.Items || [];
      if (resOverridePrice.Item && data.Items) {
        let priceOverrideObj = resOverridePrice.Item.value;
        computedItems = mergeOverridePrice(data.Items, priceOverrideObj);
      }
      dispatch({
        type: type.FETCH_INVENTORY_ITEMS,
        // payload: (data && _.sortBy(data.Items, i => i.row)) || []
        payload: _.sortBy(computedItems, i => i.row)
      });
      cbSuccess && cbSuccess();
    });
  };
};

const fetchBranchOverridePrice = async storeUid => {
  var params = {
    TableName: "Spa_Override",
    Key: {
      storeUid,
      name: "price"
    }
  };

  return documentClient.get(params).promise();
};
const mergeOverridePrice = (items, priceOverrideObj) => {
  let itemList = [];
  _.forEach(items, item => {
    let tmpItem = {
      ...item,
      defaultPrice: item.price, //ราคาจาก HQ
      price: priceOverrideObj[item.uid] || item.price //ราคา Override
    };
    itemList.push(tmpItem);
  });
  return itemList;
};

const fetch = (params, cb) => {
  documentClient.query(params, (err, data) => {
    if (err) {
      console.log(err);
      // alert(err)
    } else {
      cb(data);
    }
  });
};

export function addInventoryItems(items, groupUid, callBack) {
  return (dispatch, getState) => {
    const { user } = getState();

    async.eachSeries(
      items,
      function(item, cb) {
        let randomUID = randomString({
          length: 8,
          numeric: true,
          letters: true,
          special: false
        });
        //REMOVE COMMA (,)
        let price = _.replace(item.price, new RegExp(",", "g"), "");

        let params = {
          TableName: "Minor_Inventory_Items",
          Item: {
            shopId: user.loggedIn.shopId,
            groupUid,
            uid: randomUID,
            name: item.name,
            price: parseFloat(price),
            row: moment().valueOf(),
            unit: cuttedText(item.unit),
            barcode: item.barcode,
            customSearch:item.customSearch
          }
        };

        //COMPUTE TIME
        if (cuttedText(item.timeMinutes)) {
          let timeMinutes = parseInt(item.timeMinutes);
          var hours = Math.floor(timeMinutes / 60);
          var minutes = timeMinutes % 60;
          let time = hours + "." + minutes;

          params.Item.timeMinutes = timeMinutes;
          params.Item.time = time;
        }

        documentClient.put(params, function(err, data) {
          if (err) {
            console.log(err);
            return cb(err);
          } else {
            cb(null);
          }
        });
      },
      function(err) {
        if (err) {
          swal("เกิดข้อผิดพลาด", err, "error");
        } else {
          dispatch(fetchInventory());
          if (callBack !== undefined) {
            callBack();
          }
        }
      }
    );
  };
}

export const reorderInventoryItems = (items, callBack) => {
  return (dispatch, getState) => {
    const { user } = getState();

    let index = 1;
    async.eachSeries(
      items,
      (item, cb) => {
        let params = {
          TableName: "Minor_Inventory_Items",
          Key: {
            shopId: user.loggedIn.shopId,
            uid: item.uid
          },
          UpdateExpression: "set #row = :newRow",
          ExpressionAttributeNames: { "#row": "row" },
          ExpressionAttributeValues: {
            ":newRow": index
          }
        };
        documentClient.update(params, function(err, data) {
          if (err) {
            // alert(err)
            console.log(err);
          } else {
            index++;
            cb();
          }
        });
      },
      err => {
        if (err) {
          swal("เกิดข้อผิดพลาด", err, "error");
        } else {
          dispatch(fetchInventory());
          if (callBack !== undefined) {
            callBack();
          }
        }
      }
    );
  };
};

export const editItemName = (itemName, item) => {
  return (dispatch, getState) => {
    const { user } = getState();

    let params = {
      TableName: "Minor_Inventory_Items",
      Key: {
        shopId: user.loggedIn.shopId,
        uid: item.uid
      },
      UpdateExpression: "set #name = :newName",
      ExpressionAttributeNames: { "#name": "name" },
      ExpressionAttributeValues: {
        ":newName": itemName
      }
    };
    documentClient.update(params, function(err, data) {
      if (err) {
        // alert(err)
        console.log(err);
      } else {
        swal("สำเร็จ", "เปลี่ยนชื่อสินค้าสำเร็จ", "success");
        dispatch(fetchInventory());
      }
    });
  };
};

export const editItem = (attrName, attrValue, item) => {
  return (dispatch, getState) => {
    const { user } = getState();

    let params = {
      TableName: "Minor_Inventory_Items",
      Key: {
        shopId: user.loggedIn.shopId,
        uid: item.uid
      },
      UpdateExpression: "set #attrName = :attrValue",
      ExpressionAttributeNames: { "#attrName": attrName },
      ExpressionAttributeValues: {
        ":attrValue": attrValue
      }
    };
    documentClient.update(params, function(err, data) {
      if (err) {
        // alert(err)
        console.log(err);
      } else {
        // swal("สำเร็จ", "เปลี่ยนข้อมูลสินค้าสำเร็จ", "success");
        dispatch(fetchInventory());
      }
    });
  };
};

export const deleteItem = item => {
  return (dispatch, getState) => {
    const { user } = getState();

    let params = {
      TableName: "Minor_Inventory_Items",
      Key: {
        shopId: user.loggedIn.shopId,
        uid: item.uid
      }
    };
    documentClient.delete(params, function(err, data) {
      if (err) {
        // alert(err)
        console.log(err);
      } else {
        swal("สำเร็จ", "ลบสินค้าสำเร็จ", "success");
        dispatch(fetchInventory());
      }
    });
  };
};

export const moveItemsToAnotherGroup = (
  pickedItemsUid,
  selectedGroupUid,
  cb
) => {
  // inventorySelector.itemObjSelector
  return (dispatch, getState) => {
    let itemsObj = inventorySelector.itemObjSelector(getState());
    _.map(pickedItemsUid, (bool, itemUid) => {
      let params = {
        TableName: "Minor_Inventory_Items",
        Key: {
          shopId: itemsObj[itemUid].shopId,
          uid: itemsObj[itemUid].uid
        },
        UpdateExpression: "set groupUid = :newGroupUid",
        ExpressionAttributeValues: {
          ":newGroupUid": selectedGroupUid
        }
      };
      documentClient.update(params, function(err, data) {
        if (err) {
          // alert(err)
          console.log(err);
        } else {
          cb();
          dispatch(fetchInventory());
        }
      });
    });
  };
};

export const uploadItemImage = (item, image) => {
  if (image === undefined || image === null) {
    return;
  } 
  return (dispatch, getState) => {
    var name = image.name;

    dispatch(appStateActions.loading(true));
    let fileName = item.uid; //moment().valueOf()
    var params = {
      Bucket: "inventory-management",
      Key: `items/${item.shopId}/${item.uid}.${_.last(name.split("."))}`,
      Body: image
      // ContentType: "application/pdf",
      // ContentDisposition: 'inline'
    };

    s3.upload(params, (err, res) => {
      console.log("upload complete pdf");
      dispatch(
        updateItemImage(
          item,
          res.Location.replace("items", "items_thumb"),
          fileName
        )
      );

      setTimeout(() => {
        dispatch(appStateActions.loading(false));
        alert("upload complete");
      }, 200);
    });
  };
};

export const deleteItemImage = item => {
  return (dispatch, getState) => {
    var params = {
      Bucket: "inventory-management" /* required */,
      Delete: {
        /* required */
        Objects: [
          /* required */
          {
            Key: `items/${item.shopId}/${item.image.name}` /* required */
            // VersionId: 'STRING_VALUE'
          }
          /* more items */
        ],
        Quiet: true || false
      }
      // MFA: 'STRING_VALUE',
      // RequestPayer: requester
    };
    s3.deleteObjects(params, function(err, data) {
      if (err) console.log(err, err.stack);
      // an error occurred
      else console.log(data); // successful response
    });
  };
};

export const updateItemImage = (item, imgUrl, fileName) => {
  return (dispatch, getState) => {
    let params = {
      TableName: "Minor_Inventory_Items",
      Key: {
        shopId: item.shopId,
        uid: item.uid
      },
      UpdateExpression: "set image = :newImgUrl",
      ExpressionAttributeValues: {
        ":newImgUrl": {
          url: imgUrl,
          name: fileName
        }
      }
    };
    documentClient.update(params, function(err, data) {
      if (err) {
        console.log(err);
      } else {
        if (item.image !== undefined) {
          dispatch(deleteItemImage(item));
        }
        dispatch(fetchInventory());
      }
    });
  };
};

const cuttedText = text => {
  if (text === "") return null;

  return text;
};
