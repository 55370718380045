import React, { useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import Dropdown from "../../shared/components/Dropdown";

function PosCartShoppingCartItemList(props) {
  const {
    qtyHolder,
    items,
    removeLine,
    discountHolder,
    itemlinesSelected
  } = props;
  const dispatch = useDispatch();

  function checkAll() {
    _.map(items, (line, i) => {
      dispatch({
        type: "CART_ITEM_SELECTED",
        index: i,
        key: line.qtyUid || line.uid,
        payload: true
      });
    });
  }

  function handleSplitLine(item, index) {
    getInt("Enter Number to split", intToSplit => {
      if (item.qty - intToSplit >= 1) {
        dispatch({
          type: "CART_ITEM_SPLIT_LINE",
          key: item.uid,
          payload: intToSplit,
          index
        });
      }
    });
  }

  function getInt(title, cb) {
    let int = prompt(title);
    int = parseInt(int);
    if (_.isFinite(int) && int >= 1) {
      cb && cb(int);
    }
  }

  function handleSetQty(item, index) {
    let int = prompt("Enter New Qty");
    int = parseInt(int);
    if (_.isFinite(int) && int >= 1) {
      dispatch({
        type: "CART_ITEM_SET_QTY",
        index,
        key: item.uid,
        item,
        payload: int,
        index
      });
    }
  }

  function handleSetPrice(item, index) {
    let int = prompt("Enter New Price");
    int = parseInt(int);
    if (_.isFinite(int) && int >= 1) {
      dispatch({
        type: "CART_ITEM_SET_PRICE",
        index,
        key: item.uid,
        item,
        payload: int,
        index
      });
    }
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <table className="table table-sm table-striped">
          <thead>
            <tr>
              <th style={{ width: "5%", cursor: "pointer" }} onClick={checkAll}>
                All
              </th>

              <th style={{ width: "5%" }}>Qty</th>

              <th
              // style={{ width: "55%" }}
              >
                Item
              </th>
              <th className="number" style={{ width: "10%" }}>
                Price
              </th>
              <th className="number" style={{ width: "10%" }}>
                Discount
              </th>
              <th className="number" style={{ width: "10%" }}>
                Total
              </th>
              <th className="actions" style={{ width: "10%" }} />

              <th className="actions" style={{ width: "5%" }} />
            </tr>
          </thead>
          <tbody>
            {_.map(items, (line, index) => {
              return (
                <tr
                  onClick={() => {
                    dispatch({
                      type: "CART_ITEM_SELECTED",
                      key: line.uid,
                      index,
                      payload: !itemlinesSelected[index]
                    });
                  }}
                >
                  <td class="text-center">
                    {itemlinesSelected[index] ? (
                      <span class="mdi mdi-check-square"></span>
                    ) : (
                      <span class="mdi mdi-square-o"></span>
                    )}
                  </td>
                  <td className=" " style={{ width: 48 }}>
                    <b>{line.qty}</b>
                  </td>

                  <td>
                    {line.name}
                    {parseFloat(line.time) > 0 && (
                      <span style={{ color: "blue", marginLeft: 7 }}>
                        {line.time}hr.{" "}
                      </span>
                    )}
                  </td>
                  <td className="number">{line.price}</td>
                  <td className="number">
                    <DiscountLine line={line} />
                  </td>
                  <td className="number">
                    <DiscountedPrice line={line} />
                  </td>
                  <td className="actions">
                    <Dropdown
                      lineUid={line.uid}
                      item={line}
                      index={index}
                      handleSplitLine={handleSplitLine}
                      handleSetQty={handleSetQty}
                      handleSetPrice={handleSetPrice}
                    />
                  </td>
                  <td className="actions">
                    {removeLine && (
                      <a
                        className="icon"
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          removeLine(index, line.uid);
                        }}
                      >
                        {/* <i className="mdi mdi-minus" /> */}x
                      </a>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PosCartShoppingCartItemList;

function DiscountLine({ line }) {
  if (line.discount == undefined) {
    return null;
  }

  if (line.discount.mode === 0) {
    return <span>-{line.discount.value}%</span>;
  }
  if (line.discount.mode === 1) {
    return <span>-{line.discount.value}</span>;
  }
  return null;
}

function DiscountedPrice({ line }) {
  let disAmount = 0;
  let disMode = line.discount && line.discount.mode;
  if (disMode === 0) {
    disAmount = (line.price * line.discount.value) / 100;
  } else if (disMode === 1) {
    disAmount = line.discount.value;
  } else {
  }

  return line.qty * (line.price - disAmount);
}
