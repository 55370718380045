import React from "react";
import { connect } from "react-redux";
import * as computeSelector from "../PosCart/selector";
import _ from "lodash";
import moment from "moment";

function EndTime(props) {
  let endTime = _.cloneDeep(props.startTime);
  endTime.setMinutes(endTime.getMinutes() + props.totalItemTime); 
  return (
    <div className="row m-0">
      <div>
        <label class="text-sm-right" style={{ paddingTop: 4 }}>
          End Time
        </label>
      </div>
      <div style={{ paddingTop: 4, marginLeft: 10 }}>
        <div>{moment(endTime).format("HH:mm")} น.</div>
        <div style={{color : 'gray', fontStyle : 'italic'}}>(+{props.totalItemTime} นาที)</div>
      </div>
    </div>
  );

  // return <div>{moment(endTime).format("DD/MM/YYYY HH:mm")}</div>;
}

const mapStateToProps = state => {
  return {
    totalItemTime: computeSelector.totalItemTimeSelector(state) 
  };
};

export default connect(
  mapStateToProps
  //   mapDispatchToProps
)(EndTime);
