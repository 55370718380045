import React, { useState } from "react";
import moment from "moment";
import _ from "lodash";
import PrintReceipt from "../../printer/service.print.receipt.full";
import VoidReceipt from "../../printer/service.print.receipt.void";
import { useDispatch } from "react-redux";
import getVoucherDiscount from "../functions/getVoucherDiscount";

function SpaBillModal(props) {
  const dispatch = useDispatch();
  const {
    meta = { room: {}, bed: {} },
    timestamp,
    localTime,
    member = {},
    items = [],
    payment = {},
    therapist = {},
    therapistList,
    sales = {},
    store = {},
    prepaidDetail = {},
    receiptId = "",
    refId,
    cashier = {},
    matchVoucher = null,
    agentDetail = {},
  } = props;

  const PrintReceiptThermal = () => {
    let data = {
      ...props,
    };

    let tmpDipatch = dispatch;
    let print = new PrintReceipt(tmpDipatch, data);

    print.build().print();
  };

  const PrintVoidThermal = () => {
    let receipt = props;

    new VoidReceipt(dispatch, receipt, {
      reason: props.void.reason,
      employeeName: props.void.user,
    });
  };

  let filteredItems = [];
  let filteredPromotionItems = [];
  _.forEach(items, (item) => {
    if (item.promotion) {
      filteredPromotionItems.push(item);
    } else {
      filteredItems.push(item);
    }
  });

  return (
    <div
      class="modal"
      id="spaBillModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="spaBillModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog full-width modal-dialog-centered"
        role="document"
        style={{ width: 1000 }}
      >
        <div class="modal-content" style={props.void ? {} : {}}>
          <div>
            <div class="modal-header">
              <h4 class="modal-title" id="spaBillModalLabel">
                {moment(props.localTime).format("DD/MM/YYYY HH:mm:ss ")}
                {props.void ? <VoidText title="VOIDED" /> : <span></span>}
                {props.void && [
                  <span> | </span>,
                  <VoidText title={"Void Reason :  " + props.void.reason} />,
                  // <Row col1="Void Reason:" col2={props.void.reason} />
                ]}
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="dropdown-divider" />
            <div class="modal-body">
              <div className="row m-0">
                <div className="col-6">
                  <ProductTable items={filteredItems} />
                  {(filteredPromotionItems || []).length > 0 && (
                    <PromotionTable promotionItems={filteredPromotionItems} />
                  )}
                  {therapistList && [
                    <div class="dropdown-divider" />,
                    <div className="mt-3">
                      <div className="mb-3">
                        <span style={{ fontSize: 17 }}>Therapists</span>
                      </div>
                      <div>
                        {_.map(therapistList, (ther) => {
                          return (
                            <div className="row m-0 mb-1 justify-content-between">
                              <div>{ther.name}</div>
                              <div>{sumComputeMinute(ther.items)}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>,
                  ]}
                  {therapist && [
                    <div class="dropdown-divider" />,
                    <div className="mt-3">
                      <div className="mb-3">
                        <span style={{ fontSize: 17 }}>Therapist</span>
                      </div>
                      <div>
                        {_.flatMap(therapist, (course) => {
                          return course.therapists.map((t) => (
                            <div className="row m-0 mb-1 justify-content-between">
                              <div>{t.name}</div>
                              {/* <div>{sumComputeMinute(ther.items)}</div> */}
                            </div>
                          ));
                        })}
                      </div>
                    </div>,
                  ]}
                </div>
                <div className="col-6">
                  {/* <Row col1="Branch:" col2={store.name} /> */}
                  <Row col1="Receipt ID :" col2={receiptId} />
                  {refId && <Row col1="Ref ID :" col2={refId} />}

                  {/* {refId && <Row col1="Ref ID:" col2={refId} />} */}
                  {meta.room && (
                    <Row
                      col1="Room & Bed:"
                      col2={`${meta.room.name} | ${meta.bed.name}`}
                    />
                  )}
                  {/* {meta.bed && <Row col1="Bed:" col2={meta.bed.name} />} */}
                  {member && member.name && (
                    <Row
                      col1="Member:"
                      col2={`${member.name}${
                        member.memberId ? " (" + member.memberId + ")" : ""
                      }`}
                    />
                  )}

                  {/* <Row col1="Sales:" col2={agent.name || "-"} /> */}
                  {therapist &&
                    therapist.name && [
                      <Row col1="Therapist:" col2={therapist.name} />,
                      <div class="dropdown-divider" />,
                    ]}
                  {sales && sales.name && (
                    <Row col1="Sales:" col2={sales.name} />
                  )}
                  <div class="dropdown-divider" />
                  <Row
                    col1="Sub Total:"
                    col2={(payment.subTotal || 0).toLocaleString()}
                  />

                  {(payment.options || []).length > 0 && [
                    <div class="dropdown-divider" />,
                    <div style={{ marginBottom: 20 }}>
                      <span style={{ fontWeight: "bold" }}>
                        <u>Options</u>
                      </span>
                    </div>,
                    _.map(payment.options, (line) => {
                      return (
                        <Row
                          col1={
                            <span style={{ color: "gray" }}>{line.name}:</span>
                          }
                          col2={
                            <span style={{ color: "gray" }}>
                              {line.value.toLocaleString()}{" "}
                              {getOptionMode(line.mode)}
                            </span>
                          }
                        />
                      );
                    }),
                    <Row
                      col1="Options Amount:"
                      col2={`${(payment.optionsAmount || 0) > 0 ? "+" : ""}${(
                        payment.optionsAmount || 0
                      ).toLocaleString()}`}
                    />,
                    <div class="dropdown-divider" />,
                  ]}

                  {payment.promotions !== 0 && (
                    <Row
                      col1="Promotions Discount:"
                      col2={(payment.promotions || 0).toLocaleString()}
                    />
                  )}
                  {matchVoucher && (
                    <div>
                      <Row
                        col1="Voucher Discount:"
                        col2={getVoucherDiscount(
                          items,
                          matchVoucher
                        ).toLocaleString()}
                      />
                      <ul>
                        {_.map(matchVoucher, (v) => {
                          return (
                            <li>
                              <span style={{ color: "gray" }}>
                                {v.name} ({v.voucherUid})
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                  {payment.rounding !== 0 && (
                    <Row col1="Rounding:" col2={payment.rounding} />
                  )}

                  <div class="dropdown-divider" />

                  <Row
                    col1={<span style={{ color: "blue" }}>Grand Total:</span>}
                    col2={
                      <span style={{ color: "blue" }}>
                        {(payment.grandTotal || 0).toLocaleString()}
                      </span>
                    }
                  />
                  <div class="dropdown-divider" />
                  <div style={{ marginBottom: 20 }}>
                    <span style={{ fontWeight: "bold" }}>
                      <u>Payment Types</u>
                    </span>
                  </div>
                  {_.map(payment.paymentTypes, (line) => {
                    let payloadName = null;
                    if (
                      line.type === "cityLedger" ||
                      line.type === "entertain"
                    ) {
                      payloadName = (line.payload && line.payload.name) || null;
                    }
                    return (
                      <Row
                        col1={
                          <span style={{ color: "gray" }}>
                            {line.name} {payloadName && `(${payloadName})`}:
                          </span>
                        }
                        col2={
                          <span style={{ color: "gray" }}>
                            {line.amount.toLocaleString()}
                          </span>
                        }
                      />
                    );
                  })}

                  {agentDetail.bank && (
                    <Row
                      col1={<span style={{ color: "green" }}>Bank:</span>}
                      col2={
                        <span style={{ color: "green" }}>
                          {agentDetail.bank.label}
                        </span>
                      }
                    />
                  )}

                  {/* <Row
                    col1="Received:"
                    col2={(payment.received || 0).toLocaleString()}
                  /> */}
                  {/* <Row
                    col1="Change:"
                    col2={(payment.change || 0).toLocaleString()}
                  /> */}
                  {!_.isEmpty(prepaidDetail) && (
                    <div>
                      <div class="dropdown-divider" />
                      <div style={{ marginBottom: 20 }}>
                        <span style={{ fontWeight: "bold" }}>
                          <u>Prepaid Detail</u>
                        </span>
                      </div>
                      <Row
                        col1={<span style={{ color: "gray" }}>Member:</span>}
                        col2={
                          <span style={{ color: "gray" }}>
                            {prepaidDetail.member &&
                              `${prepaidDetail.member.name} ${
                                prepaidDetail.member.memberId
                                  ? "- " + prepaidDetail.member.memberId
                                  : ""
                              }`}
                          </span>
                        }
                      />
                      <Row
                        col1={<span style={{ color: "gray" }}>Before:</span>}
                        col2={
                          <span style={{ color: "gray" }}>
                            {(prepaidDetail.before || 0).toLocaleString()}
                          </span>
                        }
                      />
                      <Row
                        col1={<span style={{ color: "gray" }}>Use:</span>}
                        col2={
                          <span style={{ color: "gray" }}>
                            {(prepaidDetail.used || 0).toLocaleString()}
                          </span>
                        }
                      />
                      <Row
                        col1={<span style={{ color: "gray" }}>Balance:</span>}
                        col2={
                          <span style={{ color: "gray" }}>
                            {(prepaidDetail.after || 0).toLocaleString()}
                          </span>
                        }
                      />
                    </div>
                  )}

                  <Row col1="Cashier:" col2={cashier.name} />
                </div>
              </div>
            </div>
            <div class="modal-footer pt-2">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              {!props.void ? (
                <button
                  type="button"
                  class="btn btn-secondary ml-2"
                  onClick={PrintReceiptThermal}
                >
                  Print Receipt
                </button>
              ) : (
                <button
                  type="button"
                  class="btn btn-secondary ml-2"
                  onClick={PrintVoidThermal}
                >
                  Print Void Receipt
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpaBillModal;

const Row = ({ col1, col2 }) => {
  return (
    <div
      className="row m-0 mb-3 justify-content-between"
      // style={{ fontSize: 17 }}
    >
      <div>{col1}</div>
      <div>{col2}</div>
    </div>
  );
};

const getOptionMode = (mode) => {
  if (mode === 0) {
    return "%";
  }
  if (mode === 1) {
    return "THB";
  }
};

const ProductTable = ({ items }) => {
  return (
    <div>
      <div class="card-header m-0">
        <u>Products</u>
      </div>
      <table class="table table-striped table-borderless">
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Extended Price</th>
            {/* <th>Qty</th> */}
            {/* <th>Unit</th> */}
          </tr>
        </thead>
        <tbody class="no-border-x">
          {_.map(items, (item) => {
            return (
              <tr>
                <td>
                  <span style={{ color: "blue" }}>{item.qty}</span> X{" "}
                  {item.name}
                </td>
                <td>{item.price}</td>
                <td>{item.extendedPrice}</td>
                {/* <td>{item.qty}</td> */}
                {/* <td>{item.unit}</td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const PromotionTable = ({ promotionItems }) => {
  return (
    <div>
      <div class="card-header m-0">
        <u>Promotions</u>
      </div>
      <table class="table table-striped table-borderless">
        <thead>
          <tr>
            <th>Name</th>
            <th>Qty</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody class="no-border-x">
          {_.map(promotionItems, (item) => {
            return (
              <tr>
                <td>{item.name}</td>
                <td>{item.qty}</td>
                <td>{item.price.toLocaleString()}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

function sumComputeMinute(items) {
  let totalMins = 0;
  _.forEach(items, (item) => {
    if (!item.timeMinutes) return;
    totalMins += item.qty * item.timeMinutes;
  });

  let time = `${Math.floor(totalMins / 60)}h  ${(totalMins % 60).toFixed(0)}m `;

  return time;
}

function VoidText({ title }) {
  return (
    <span class="ml-3 mr-3" style={{ backgroundColor: "red", color: "white" }}>
      {" "}
      {title || "VOIDED"}{" "}
    </span>
  );
}

const voidStyle = {
  // backgroundImage: `url(${require("../image/d.jpg")})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "noRepeat",
  backgroundColor: "rgba(255,255,255,0.8)",
  backgroundBlendMode: "lighten",
};
