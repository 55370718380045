import React from "react";
import SummaryCard from "./SummaryCard";

function SummaryMonthPicker(props) {
  return (
    <div className="card mb-2">
      <div className="p-4 pt-0">
        <div className="row m-0 justify-content-between">
          <div>{props.getMonthPicker()}</div>
          <div
            style={{ width: 200 }}
            // className="align-self-center"
          >
            <SummaryCard
              title={props.sumGrandTotal.toLocaleString()}
              subtitle="Grand Total"
              bgColor="lightgrey"
              height={70}
              color="blue"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SummaryMonthPicker;
