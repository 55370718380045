import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";

function SummaryChart(props) {
  // const [count, setCount] = useState(0);

  useEffect(() => {
    createChart();
  }, [props.computedReceipts]);

  const createChart = () => {
    let dateArr = props.getDateListDesc(
      moment(props.month).format("YYYY-MM-DD")
    );
    let dataList = _.reverse(_.cloneDeep(props.computedReceipts));

    let grandTotalList = [];

    _.forEach(dataList, line => {
      if (line.grandTotal) {
        grandTotalList.push(line.grandTotal);
      } else {
        grandTotalList.push(0);
      }
    });

    window.Highcharts.chart("summaryChart", {
      chart: {
        type: "column"
      },
      title: {
        text: `ยอดขายเดือน ${moment(props.month).format("MMM YYYY")}`
      },
      // subtitle: {
      //   text: "Source: WorldClimate.com"
      // },
      xAxis: {
        categories: _.times(dateArr.length, t => t + 1),
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: "Grand Total"
        }
      },
      // plotOptions: {
      //   series: {
      //     dataLabels: {
      //       enabled: true
      //     }
      //   }
      // },
      tooltip: {
        headerFormat:
          '<span style="font-size:10px">วันที่ {point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} THB</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [
        {
          name: props.linked
            ? props.shop.detail.name
            : props.branch
            ? props.branch.label
            : "-",
          data: grandTotalList
        }
      ]
    });
  };

  return (
    <div>
      <div
        id="summaryChart"
        style={{ height: 400, padding: 0, position: "relative" }}
      />
    </div>
  );
}

export default SummaryChart;
