import React from "react";
import moment from "moment";
import _ from "lodash";

const MemberTxTable = props => {
  const GetTxTypeColor = type => {
    switch (type) {
      case "TOPUP":
        return "green";
      case "SPEND":
        return "red";
      default:
        break;
    }
  };

  return (
    <div>
      <table class="table table-sm table-hover table-bordered table-striped">
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Type</th>
            <th class="number">Amount</th>
            <th>Store</th>
            <th>Employee</th>
            <th>Ref ID</th>
            <th>Remark</th>
          </tr>
        </thead>
        <tbody>
          {_.map(props.txList, i => {
            return (
              <tr>
                <td>{moment(i.timestamp).format("DD MMM YYYY HH:mm")}</td>
                <td
                  style={{ color: GetTxTypeColor(i.type), fontWeight: "bold" }}
                >
                  {i.type}
                </td>
                <td class="number">{i.amount}</td>
                <td>{i.store && i.store.name}</td>
                <td>{i.employee && i.employee.name}</td>
                <td>{i.refId}</td>
                <td>{i.remark}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MemberTxTable;
