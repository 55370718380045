import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";
import * as historySelector from "../../shared/selector/historySelector";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as purchaseHistoryAction from "../../shared/actions/purchaseHistoryActions";
import * as historyPageActions from "../../shared/actions/historyPageActions";

// import ButtonInvoiceStatus from "../../shared/components/ButtonInvoiceStatus";
import * as isHqSelector from "../../shared/selector/isHqSelector";
import getStatusColorButton from "../../shared/functions/getStatusColorButton";
// import getStatusName from "../../shared/functions/getStatusName";
import * as branchOptionsSelector from "../../shared/selector/branchOptionsSelector";
import Select from "react-select";

class HistoryList extends Component {
  viewTX = tx => {
    let { history, match } = this.props;
    // history.push(`${match.url}/${tx.shopId}/${tx.timestamp}`)
    history.push(`purchaseHistory/${tx.shopId}/${tx.timestamp}`);
  };

  handleChangeInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  componentDidMount() {
    this.initFetchInvoice();
  }
  componentWillReceiveProps(nextProps) {
    if (
      !_.isEqual(
        this.props.notification.lastUpdate,
        nextProps.notification.lastUpdate
      )
    ) {
      this.props.actions.fetchPurchaseHistoryPending();
    }
  }
  initFetchInvoice = () => {
    const { actions } = this.props;
    actions.fetchPurchaseHistoryPending();
  };

  computeTransactions = () => {
    const {
      tab,
      transactionsPending,
      transactionsComplete,
      transactionsSearch
    } = this.props;
    if (tab === "pending") {
      return transactionsPending;
    } else if (tab === "completed") {
      const { currentPage, transactions } = transactionsComplete;
      return transactions[currentPage] || [];
    } else {
      const { currentPage, transactions } = transactionsSearch;
      return transactions[currentPage] || [];
    }
  };

  changeTab = (e, newTab) => {
    const { actions, tab } = this.props;
    e.stopPropagation();
    e.preventDefault();
    if (newTab === tab) {
      return;
    }

    if (newTab === "pending") {
      actions.clearPurchaseHistory();
      actions.clearPurchaseHistorySearch();
      actions.clearHistoryBranch();
      actions.fetchPurchaseHistoryPending();
    } else if (newTab === "completed") {
      actions.clearHistoryBranch();
      actions.clearPurchaseHistorySearch();
      setTimeout(() => {
        actions.fetchPurchaseHistory();
      }, 100);
    } else {
      actions.clearPurchaseHistory();
      setTimeout(() => {
        actions.fetchPurchaseSearchHistory();
      }, 100);
    }
    actions.setHistoryTab(newTab);
  };
  reload = () => {
    const { actions, tab } = this.props;

    if (tab === "pending") {
      actions.fetchPurchaseHistoryPending();
    } else if (tab === "completed") {
      actions.clearPurchaseHistory();
      setTimeout(() => {
        actions.fetchPurchaseHistory();
      }, 100);
    } else {
      actions.clearHistoryBranch();
      actions.clearPurchaseHistorySearch();
      setTimeout(() => {
        actions.fetchPurchaseSearchHistory();
      }, 100);
    }
  };
  handleChangeBranch = selectedBranch => {
    const { actions } = this.props;
    actions.clearPurchaseHistorySearch();
    if (selectedBranch.value !== null) {
      setTimeout(() => {
        actions.fetchPurchaseSearchHistory(undefined, selectedBranch.value);
      }, 100);
    }

    actions.setHistoryBranch(selectedBranch);
  };

  cantNextPage = () => {
    const {
      transactionsComplete,
      transactionsSearch,
      tab,
      selectedBranch
    } = this.props;
    if (tab === "search" && selectedBranch.value === null) {
      return true;
    }
    let cantNext = false;
    let tc;
    if (tab === "completed") {
      tc = transactionsComplete;
    } else {
      tc = transactionsSearch;
    }
    if (
      tc.LastEvaluatedKey === undefined &&
      tc.currentPage === _.size(tc.transactions) - 1
    ) {
      cantNext = true;
    }
    return cantNext;
  };
  cantGoBack = () => {
    let cantBack = false;
    const { transactionsComplete, transactionsSearch, tab } = this.props;
    let tc;
    if (tab === "completed") {
      tc = transactionsComplete;
    } else {
      tc = transactionsSearch;
    }
    if (tc.currentPage === 0) {
      cantBack = true;
    }
    return cantBack;
  };

  render() {
    const {
      isInventoryHq,
      tab,
      transactionsComplete,
      actions,
      branchOptions,
      selectedBranch,
      transactionsSearch
    } = this.props;

    let transactions = this.computeTransactions();

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            {tab === "search" && (
              <div
                className="card-header"
                style={{ fontSize: 16, paddingTop: 10, paddingLeft: 10 }}
              >
                <div className="row m-0 justify-content-between mt-3">
                  <div
                    className="d-flex flex-row"
                    style={{
                      width: 500,
                      visibility:
                        tab === "search" && isInventoryHq ? "visible" : "hidden"
                    }}
                  >
                    <div className="align-self-center">เลือกสาขา</div>
                    <div className="flex-fill ml-5">
                      <Select
                        style={{
                          diplay: "inline-block",
                          width: "70%",
                          marginLeft: 8
                        }}
                        value={selectedBranch}
                        onChange={this.handleChangeBranch}
                        options={branchOptions}
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn btn-space btn-primary"
                      onClick={this.reload}
                    >
                      Reload
                    </button>
                  </div>
                </div>
              </div>
            )}

            {tab != "search" && (
              <div className="card-header">
                <div className="row m-0 flex-row justify-content-between">
                  <div>
                    <h3
                      style={{ marginLeft: 12, marginTop: 0, marginBottom: 0 }}
                    >
                      {tab === "pending"
                        ? "Pending " + _.size(transactions) + " transactions"
                        : "Completed / Void"}
                    </h3>
                  </div>
                  <div>
                    <button
                      className="btn btn-space btn-primary"
                      onClick={this.reload}
                    >
                      Reload
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="tab-container">
              <div className="row m-0 justify-content-between">
                <div>
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className={`nav-link show ${
                          tab === "pending" ? "active" : ""
                        }`}
                        href="#home5"
                        role="tab"
                        onClick={e => this.changeTab(e, "pending")}
                      >
                        <span className="icon mdi mdi-time" />
                        Pending
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link show ${
                          tab === "completed" ? "active" : ""
                        }`}
                        href="#profile5"
                        role="tab"
                        onClick={e => this.changeTab(e, "completed")}
                      >
                        <span className="icon mdi mdi-check" />
                        Comfirmed / Voideds
                      </a>
                    </li>
                    {isInventoryHq && (
                      <li className="nav-item">
                        <a
                          className={`nav-link show ${
                            tab === "search" ? "active" : ""
                          }`}
                          href="#profile5"
                          role="tab"
                          onClick={e => this.changeTab(e, "search")}
                        >
                          <span className="icon mdi mdi-search" />
                          Search
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
                <div style={{ marginRight: 20 }} className="align-self-center">
                  {tab === "completed" || tab === "search" ? (
                    [
                      <span className="email-pagination-indicator">
                        Page:{" "}
                        {tab === "completed"
                          ? transactionsComplete.currentPage + 1
                          : transactionsSearch.currentPage + 1}
                      </span>,
                      <div className="btn-group email-pagination-nav">
                        <button
                          className="btn btn-secondary"
                          type="button"
                          disabled={this.cantGoBack()}
                          onClick={() => {
                            if (tab === "completed") {
                              actions.fetchPurchaseHistory(
                                transactionsComplete.currentPage - 1,
                                selectedBranch.value
                              );
                            } else {
                              actions.fetchPurchaseSearchHistory(
                                transactionsSearch.currentPage - 1,
                                selectedBranch.value
                              );
                            }
                          }}
                        >
                          <i className="mdi mdi-chevron-left" />
                        </button>
                        <button
                          className="btn btn-secondary"
                          type="button"
                          disabled={this.cantNextPage()}
                          onClick={() => {
                            if (tab === "completed") {
                              actions.fetchPurchaseHistory(
                                transactionsComplete.currentPage + 1,
                                selectedBranch.value
                              );
                            } else {
                              actions.fetchPurchaseSearchHistory(
                                transactionsSearch.currentPage + 1,
                                selectedBranch.value
                              );
                            }
                          }}
                        >
                          <i className="mdi mdi-chevron-right" />
                        </button>
                      </div>
                    ]
                  ) : (
                    <span className="email-pagination-indicator">
                      {_.size(transactions)} Orders
                    </span>
                  )}
                </div>
              </div>
              <div className="tab-content" style={{ padding: 0 }}>
                <div
                  className="tab-pane show active"
                  id="profile5"
                  role="tabpanel"
                >
                  <HistoryTable
                    transactions={transactions}
                    viewTX={this.viewTX}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let branchOptions = [{ value: null, label: "เลือกสาขา" }];
  branchOptions = [
    ...branchOptions,
    ...branchOptionsSelector.branchIdOptionsSelector(state)
  ];
  return {
    transactionsComplete: state.history.purchaseComplete,
    transactionsSearch: state.history.purchaseSearch,
    transactionsPending: historySelector.purchasePendingArraySelector(state),
    isInventoryHq: isHqSelector.isInventoryHq(state),
    tab: state.history.historyPage.tab,
    selectedBranch: state.history.historyPage.selectedBranch,
    branchOptions,
    notification: state.notification
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators(
    { ...purchaseHistoryAction, ...historyPageActions },
    dispatch
  );
  return {
    actions
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryList);

const rowUnreadStyle = {
  borderLeft: "3px solid #4285f4",
  cursor: "pointer"
};
function HistoryTable({ transactions, viewTX }) {
  return (
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th />
          <th>Invoice.ID</th>
          <th>Date</th>
          <th>Store</th>
          <th>Items</th>
          <th>Status</th>
          <th>User</th>
        </tr>
      </thead>
      <tbody>
        {_.map(transactions, (tx, index) => {
          return (
            <tr
              style={!tx.read ? rowUnreadStyle : { cursor: "pointer" }}
              onClick={() => viewTX(tx)}
              key={`t${index}`}
            >
              <td />

              {/* Id */}
              <td className="cell-detail">
                <span>{tx.invoiceId}</span>
              </td>
              {/* Datetime */}
              <td className="cell-detail">
                <span>{moment(tx.timestamp).format("MMM D, YYYY")}</span>
                <span className="cell-detail-description">
                  {moment(tx.timestamp).format("H:mm")}
                </span>
              </td>
              {/* Store Info */}
              <td className="cell-detail">
                {" "}
                <span>{tx.shopName}</span>
                <span className="cell-detail-description">{tx.branchId}</span>
              </td>
              {/* Items Info */}
              <td className="cell-detail">
                {" "}
                <span>{tx.grandTotal.toLocaleString()} บาท</span>
                <span className="cell-detail-description">
                  {tx.qtyAll.toLocaleString()} รายการ
                </span>
              </td>
              <td>
                <label className="custom-control-label">
                  {getStatusColorButton(tx.status)}
                </label>
              </td>
              {/* User */}
              <td className="user-avatar cell-detail user-info">
                {/* <img src="assets/img/avatar6.png" alt="Avatar" /> */}
                <img
                  key={moment().toISOString() + "2"}
                  src={`https://inventory-management.s3.ap-southeast-1.amazonaws.com/profileImg/${
                    tx.employee.username
                  }`}
                  onError={e => {
                    e.target.onerror = null;
                    e.target.src = "assets/img/avatar6.png";
                  }}
                  alt="Avatar"
                />

                <span>{tx.employee.name}</span>
                <span className="cell-detail-description">
                  {tx.employee.username || "-"}
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
