import AWS from "aws-sdk";

const API_KEY = "AKIA2J6ITW3PKUHFHRFN";
const SECERT_KEY = "Daqi0/FBmWnoJCfwqg9J4TcBdaQIG7sFsGgl05p2";

const documentClient = new AWS.DynamoDB.DocumentClient({
  region: "ap-southeast-1",
  endpoint: "dynamodb.ap-southeast-1.amazonaws.com",
  accessKeyId: API_KEY,
  secretAccessKey: SECERT_KEY
});
export const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider(
  {
    apiVersion: "2016-04-18",
    region: "ap-southeast-1",
    accessKeyId: API_KEY,
    secretAccessKey: SECERT_KEY
  }
);

export default documentClient;
