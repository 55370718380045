import * as type from '../types/loading'


export const setInitLoading = (mode) => {
    return (dispatch, getState) => {

        dispatch({
            type: type.SET_INIT_LOADING,
            payload: mode
        });
    };
};