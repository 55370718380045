import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import _ from "lodash";
import moment from "moment";
import makeAnimated from "react-select/animated";
import MemberVoucherSelect from "../../pages/SpaPos/MemberVoucherSelect";

const animatedComponents = makeAnimated();

function MatchVoucherModal(props) {
  const {
    products,
    setMatchVoucher,
    activeVoucherOptions,
    voucherOwner
  } = props;

  let index = 0; //DON'T DELETE

  const [itemHolder, setItemHolder] = useState({});
  const [loading, setLoading] = useState(false);
  const [timestamp, setTimestamp] = useState(moment());

  useEffect(() => {
    initComputeData();
  }, [products]);

  //ON CLOSE MODAL
  useEffect(() => {
    window.$("#matchVoucherModal").on("shown.bs.modal", function() {
      setTimestamp(moment());
    });
  }, []);

  useEffect(() => {
    // initComputeData(true);
    initComputeData();
  }, [props.matchVoucher]);

  const initComputeData = dontSave => {
    if (!props.matchVoucher) return;
    setLoading(true);

    setItemHolder(props.matchVoucher);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const handleSetMatchVoucher = (data, pickTime, item, cartRow) => {
    let items = _.cloneDeep(itemHolder);
    items[pickTime] = {
      ...data,
      productName: item.name,
      productPrice: item.price,
      productTimeMinutes: item.timeMinutes,
      cartRow
    };
    setItemHolder(items);
  };
  const removeItem = pickTime => {
    let items = _.cloneDeep(itemHolder);
    delete items[pickTime];
    setItemHolder(items);
  };

  const save = forceComputed => {
    let matchVoucher = forceComputed || itemHolder;
    if (_.isEmpty(matchVoucher)) {
      setMatchVoucher(null);
    } else {
      setMatchVoucher(matchVoucher);
    }

    window.$("#matchVoucherModal").modal("hide");
  };

  const getOptions = () => {
    let matchedUid = {};
    _.map(itemHolder, voucher => {
      matchedUid[voucher.voucherUid] = true;
    });

    let remainVoucher = [];
    _.forEach(activeVoucherOptions, voucher => {
      if (!matchedUid[voucher.voucherUid]) {
        remainVoucher.push(voucher);
      }
    });

    return remainVoucher;
  };
  return (
    <div
      class="modal"
      id="matchVoucherModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="matchVoucherModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style={{ overflow: "unset" }}>
          <div class="modal-header">
            <h3 class="modal-title" id="matchVoucherModalLabel">
              Match Items with Vouchers -{" "}
              <span style={{ color: "green" }}>
                <b>{voucherOwner.name}</b>
              </span>
            </h3>
            {/* <button
              type="button"
              class="close" 
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button> */}
          </div>
          {loading ? (
            <div>
              <center>
                <h3>Loading...</h3>
              </center>
            </div>
          ) : (
            <div class="modal-body">
              <MemberVoucherSelect {...props} />
              <table class="table table-sm table-hover table-bordered table-striped">
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>Product</th>
                    <th style={{ width: "50%" }}>Therapist</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(products, item => {
                    let pickTime = item.timestamp.toString();
                    return (
                      <tr>
                        <td>
                          <span style={{ color: "blue" }}>{item.qty}</span> x{" "}
                          {item.name}
                        </td>
                        <td>
                          <Select
                            isClearable
                            components={animatedComponents}
                            value={itemHolder[pickTime]}
                            onChange={data => {
                              if (data) {
                                handleSetMatchVoucher(
                                  data,
                                  pickTime,
                                  item,
                                  pickTime
                                );
                              } else {
                                removeItem(pickTime);
                              }
                            }}
                            options={getOptions(activeVoucherOptions)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          <div class="modal-footer pt-2">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              // onClick={() => {
              //   window.$("#matchVoucherModal").modal("hide");
              // }}
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => save()}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  let products = state.items;

  return {
    products
  };
};

export default connect(mapStateToProps)(MatchVoucherModal);
