import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle
} from "react-sortable-hoc";
import swal from "sweetalert";
import randomUid from "../../shared/randomUid";

const OptionsStatic = () => {
  return [
    <CustomInput name="Entertain" mode={1} sign={-1} readOnly={true} />,
    <CustomInput name="Prepaid" mode={1} sign={-1} readOnly={true} />
  ];
};

//name
//sign 1 = + ,-1 = -
//mode 0 = % , 1 = THB
//row
//type [none,city,entertain]

function PaymentOptions(props) {
  const form = MultipleInput([]);
  const [mode, setMode] = useState("current");

  useEffect(() => {
    setDataList();
  }, [props.setting]);

  function setDataList(forceMode) {
    let tmpMode = forceMode || mode;

    try {
      let list = props.setting[tmpMode][props.keyNode].value || [];
      form.setValue(list);
      setMode(tmpMode);
    } catch (error) {
      console.log(error);
    }
  }

  function saveData(options = {}) {
    let list = form.value;
    if (options.data) {
      list = [...list, { ...options.data, row: moment().valueOf(), uid : _.random(10000000).toString() }];
    }
    props.actions.updateSetting(props.keyNode, list, () => {
      swal("สำเร็จ", "บันทึกข้อมูลสำเร็จ", "success");
      props.actions.fetchSetting();
      options.callBack && options.callBack();
    });
  }

  let readOnly = mode === "mother";

  function setDataList(forceMode) {
    let tmpMode = forceMode || mode;

    try {
      let list = props.setting[tmpMode][props.keyNode].value || [];
      form.setValue(list);
      setMode(tmpMode);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      {/* <h3>{mode.toLocaleUpperCase()}</h3> */}

      <div className="row m-0">
        <div className="col-md-8 offset-md-2">
          {props.isLinked && (
            <CustomRow>
              <div className="pb-2">
                <label>เลือกสาขา</label>
                <select
                  className="form-control"
                  onChange={e => setDataList(e.target.value)}
                  style={{ width: 150 }}
                  value={mode}
                >
                  <option value="current">Current</option>
                  <option value="mother">HQ</option>
                </select>
              </div>
            </CustomRow>
          )}
          <OptionsStatic />
          <SortableComponent 
            {...props}
            items={form.value}
            form={form}
            readOnly={readOnly}
          />
          {!readOnly && <RowAdd addRow={form.addRow} saveData={saveData} />}
        </div>
      </div>
    </div>
  );
}

export default PaymentOptions;

const RowAdd = props => {
  let [name, setName] = useState("");
  let [mode, setMode] = useState(0);
  let [sign, setSign] = useState(1);

  function handleAddRow() {
    props.addRow({ name, mode, sign }, () => {
      setName("");
      setMode(0);
      setSign(1);
    });
  }
  return (
    <div className="row m-0">
      <div className="col-12 p-0">
        <CustomInput
          name={name}
          mode={mode}
          sign={sign}
          setName={setName}
          setMode={setMode}
          setSign={setSign}
          submit={handleAddRow}
          btnClass="primary"
        />

        <CustomRow>
          <button
            className="btn btn-space btn-primary"
            style={{ width: "100%" }}
            onClick={() => {
              if (name !== "") {
                props.saveData({
                  data: { name, mode, sign },
                  callBack: () => {
                    setName("");
                    setMode(0);
                    setSign(1);
                  }
                });
              } else {
                props.saveData();
              }
            }}
          >
            <span>SAVE</span>
          </button>
        </CustomRow>
      </div>
    </div>
  );
};

class SortableComponent extends React.Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    let newItems = arrayMove(this.props.items, oldIndex, newIndex);
    _.forEach(newItems, (data, idx) => {
      data.row = idx;
    });
    this.props.form.setValue(newItems);
  };
  render() {
    return (
      <SortableList
        {...this.props}
        useDragHandle={true}
        items={this.props.items}
        onSortEnd={this.onSortEnd}
      />
    );
  }
}

const SortableList = SortableContainer(props => {
  return (
    <div>
      {_.map(props.items, (value, index) => (
        <SortableItem
          {...props}
          key={`item-${index}`}
          index={index}
          index2={index}
          value={value}
        />
      ))}
    </div>
  );
});

const SortableItem = SortableElement(props => {
  let { value, index2, form, readOnly } = props;

  return (
    <CustomInput
      name={value.name}
      mode={value.mode}
      sign={value.sign}
      setName={text => {
        form.onChangeKey("name", text, index2);
      }}
      setMode={mode => {
        form.onChangeKey("mode", parseInt(mode), index2);
      }}
      setSign={sign => {
        form.onChangeKey("sign", parseInt(sign), index2);
      }}
      submit={() => form.removeLine(index2)}
      btnClass="danger"
      readOnly={readOnly}
    />
  );
});

// const Row = ({ col1, col2, col3 }) => {
//   return (
//     <div className="row m-0 mb-3">
//       <div className="col-md-1 align-self-center">{col1}</div>
//       <div className="col-md-1 align-self-center">{col2}</div>
//       <div className="col-md-8">{col3}</div>
//     </div>
//   );
// };

function MultipleInput(initValue) {
  const [value, setValue] = useState(initValue || []);

  function onChangeKey(key, val, index) {
    let newVal = _.cloneDeep(value);
    newVal[index][key] = val;
    setValue(newVal);
  }

  function addRow(data, cb) {
    if (data.name === "") {
      return swal("ข้อมูลไม่ครบถ้วน", "กรุณากรอกชื่อ Option", "error");
    }
    let newVal = _.cloneDeep(value);
    newVal.push({ ...data, row: moment().valueOf(), uid: randomUid(10) });
    setValue(newVal);
    cb && cb();
  }

  function removeLine(idx) {
    let newVal = [...value.slice(0, idx), ...value.slice(idx + 1)];
    setValue(newVal);
  }

  return {
    value,
    onChangeKey,
    setValue,
    addRow,
    removeLine
  };
}

const DragHandle = SortableHandle(() => (
  <i
    style={{ fontSize: 20 }}
    className="icon icon-left mdi mdi-sort-amount-desc"
  />
));

const CustomInput = ({
  name,
  mode,
  sign,
  setName,
  setMode,
  setSign,
  submit,
  btnClass,
  readOnly
}) => {
  return (
    <div className="row m-0 mb-3">
      <div className="col-1 pl-0">{!readOnly && <DragHandle />}</div>
      <div className="col-6">
        <input
          className="form-control"
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
          disabled={readOnly}
        />
      </div>
      <div className="col-2">
        <CustomSelect
          options={[
            { value: 1, label: "+" },
            { value: -1, label: "-" }
          ]}
          value={sign}
          onChange={setSign}
          readOnly={readOnly}
        />
      </div>
      <div className="col-2">
        <CustomSelect
          options={[
            { value: 0, label: "%" },
            { value: 1, label: "THB" }
          ]}
          value={mode}
          onChange={setMode}
          readOnly={readOnly}
        />
      </div>
      <div className="col-1 pr-0 align-self-center">
        {!readOnly && (
          <div className="input-group-append">
            <button
              className={`btn btn-${btnClass}`}
              type="button"
              onClick={submit}
              // style={{ width: 52 }}
            >
              {btnClass === "primary" ? (
                "Add"
              ) : (
                <span className="mdi mdi-delete" />
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const CustomSelect = ({ value, options, onChange, readOnly }) => {
  return (
    <select
      className="form-control"
      value={value}
      onChange={e => onChange(e.target.value)}
      disabled={readOnly}
    >
      {_.map(options, option => {
        return <option value={option.value}>{option.label}</option>;
      })}
    </select>
  );
};

const CustomRow = props => {
  return (
    <div className="row m-0 mb-3">
      <div className="col-12 offset-1">{props.children}</div>
    </div>
  );
};
