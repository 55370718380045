import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
export const ReceiptRow = function({
  viewCallback,
  deleteCallback = ()=>{} ,

  // show COL
  showTherapist = true,
  showAgent = true,
  showViewButton = true,
  showVoidButton = true,
  showLinkTo = true,

  linkToKey = "agent",

  //data
  member,
  payment,
  meta,

  therapist,
  therapistList , 
  cashier,
  index,
  ...receipt
}) {
  const isVoid = receipt.void;
  let hasManyPayment = payment.paymentTypes.length >=2 
  return [
    !receipt.void ? null : (
      <tr>
        <td colspan="11">
          <h5 class="mt-7 mb-0" style={{}}>
            <em>
              <span class="mr-5">##VOIDED## </span>
              <span class="mr-5">
                time :{" "}
                {moment(receipt.void.timestamp).format("HH:mm DD/MMM/YYYY")},{" "}
              </span>
              <span class="mr-5">reason : {receipt.void.reason}, </span>
              <span class="mr-5">by :{receipt.void.user} ,</span>
              <span class="mr-5">
                {" "}
                approved : {receipt.void.approved}{" "}
              </span>{" "}
            </em>
          </h5>
        </td>
      </tr>
    ),
    <tr style={{ textDecoration: isVoid ? "line-through" : "" }}>
      <td style={{ color: "gray" }}>{index}.</td>
      <td>{moment(   _.includes( receipt.timestamp, '#') ?  receipt.timestamp.split('#')[1]  :   receipt.timestamp).format("HH:mm:ss DD/MMM")}</td>
      <td>{receipt.receiptId}</td>
      <td>{receipt.refId}</td>

      <td>
        {meta.room && meta.room.name} {meta.bed && meta.bed.name}{" "}
      </td>
      <td>{member && member.name}</td>

      {showTherapist && 
      <td>
      {therapist && therapist.name}
      {therapistList && therapistList.map( t=> [<span>{t.name}</span>,<br/>])}
      
      </td>}

      <td>
        <strong>
          {payment.grandTotal.toLocaleString()}{" "}
          {receipt.forceTimeMinutes !== undefined && (
            <span style={{ color: "grey", fontSize: "0.85rem" }}>(multi)</span>
          )}
        </strong>
      </td>

      <td>


        {/* many payment types */}
        {payment.paymentTypes.map(t => {
          let name = t.name.toString();
          return [
            name,
            hasManyPayment ? <span class='ml-1 mr-1'>{t.amount.toLocaleString()}</span> : <span></span>,
            t.mode ? (
              <span style={{ color: "gray", fontStyle: "italic" }}>
                {" "}
                ({t.mode})
              </span>
            ) : null,
            <br/>
          ];
        })}
        {_.map(
          payment.paymentTypes.map(t => {
            if (_.includes(linkToKey, t.type)) {
              return (
                <span
                  style={{
                    display: "block",
                    color: "gray",
                    fontStyle: "italic"
                  }}
                >
                  {t.payload.name}
                </span>
              );
            }
            return null;
          })
        )}
      </td>

      {/* {showLinkTo && (
        <td>
          {_.map(
            payment.paymentTypes.map(t => {
              if (_.includes(linkToKey, t.type)) {
                return t.payload.name;
              }
              return null;
            })
          )}
        </td>
      )} */}
      <td>{(cashier && cashier.name) || "-"}</td>
      <td>
        {showViewButton && (
          <a
            class="mr-3"
            href="#"
            onClick={viewCallback}
            data-toggle="modal"
            data-target="#spaBillModal"
          >
            View
          </a>
        )}
        {showVoidButton && (
          <a class="mr-3" href="#" onClick={deleteCallback}>
            Void
          </a> 
        )}
      </td>
    </tr>
  ];
};

export const ReceiptTableContainer = props => {
  return (
    <table
      class="table table-small table-striped"
      style={{
        display: "block",
        overflowX: "auto",
        whiteSpace: "nowrap"
      }}
    >
      {props.children}
    </table>
  );
};

export const ReceiptHeader = function({
  showTherapist = true,
  showAgent = true,
  showLinkTo = true
}) {
  return (
    <thead>
      <tr>
        <th>No</th>
        <th>Timestamp</th>
        <th>ReceiptId</th>
        <th>RefId</th>

        <th>Room</th>
        <th>Member</th>

        {showTherapist && <th>Therapist</th>}
        <th>GrandTotal</th>

        <th>Payment</th>
        {/* {showLinkTo && <th>Link to</th>} */}

        <th>Cashier</th>

        <th>Actions</th>
        {/* <th style={{ width: "50%" }}>Name</th>
      <th style={{ width: "10%" }}>Date</th>
      <th class="number">Rate</th>
      <th class="number">Sales</th>
      <th class="actions" /> */}
      </tr>
    </thead>
  );
};

export const ReceiptGroupRow = function({
  colSpan1 = 7,
  colSpan2 = 4,
  name,
  amount
}) {
  return (
    <tr>
      <td colSpan={colSpan1}>
        <h4 style={{ marginTop: 40 }}>{name}</h4>
      </td>
      <td colSpan={colSpan2}>
        <h4 style={{ marginTop: 40 }}>{amount}</h4>
      </td>
    </tr>
  );
};

export const ReceiptGroupRow2 = function({
  name,

  col1Text,
  col2Text
}) {
  return (
    <tr>
      <td colSpan={11}>
        <div class="d-flex">
          <h4 style={{ marginTop: 40 }}>{name}</h4>

          <h4 style={{ marginTop: 40, position: "absolute", left: "25%" }}>
            {col1Text}
          </h4>

          <h4 style={{ marginTop: 40, position: "absolute", left: "45%" }}>
            {col2Text}
          </h4>
        </div>
      </td>
    </tr>
  );
};
