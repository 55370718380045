import React, { useState } from "react";
import { Link } from "react-router-dom";

export const Section = function({ children, title }) {
  return (
    <div class="row  ">
      <div class="offset-2 col-6">
          {/* card */}
        <div class="">
          <div class="card-body">
            <div class="row">
              <div class="col-3">
                <h4 class="text-left pl-3">{title}</h4>
              </div>
              <div class="col-9">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Section0 = function({ children, title }) {
  return (
    <div class="row mb-5">
      <div class="offset-0 col-4">
        <h4 class="text-right">{title}</h4>
      </div>
      <div class="col-4">
        <div style={{ marginTop: -16 }}>{children}</div>
      </div>
    </div>
  );
};

export const SummaryList = function(props) {
  return (
    
        <ul class="summary-list">{props.children}</ul>
    
  );
};

export const RowButton = function({ title, onClick = ()=>{} , buttonLabel,  path="/abc" }) {
  return (
    <li class="summary-list-item">
      <p>
        <span class=" item-left">{title}</span>

        <span class=" item-right">
          <Link to={path}>
          
         
          <button class="btn btn-space btn-secondary" onClick={onClick}>
            {buttonLabel}
          </button>
          </Link>
        </span>
      </p>
    </li>
  );
};

export const RowDropDown = function({
  title,
  onClick,
  buttonLabel,
  value,
  onChange
}) {
  return (
    <li class="summary-list-item">
      <p>
        <span class=" item-left">{title}</span>

        <span class=" item-right">
          <select
            class=" "
            style={{ width: 60 }}
            value={value}
            onChange={onChange}
          >
            <option value="1" selected="">
              1
            </option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </span>
      </p>
    </li>
  );
};

export const Row = function(props) {
  return (
    <li class="summary-list-item">
      <p>{props.children}</p>
    </li>
  );
};

export const Right = function(props) {
  return <span class=" item-right">{props.children}</span>;
};

export const Left = function(props) {
  return <span class=" item-left">{props.children}</span>;
};

export const Radio = ({ label, checked, onClick, name }) => {
  return (
    <label
      class="custom-control custom-radio custom-control-inline"
      onClick={onClick}
    >
      <input
        class="custom-control-input"
        type="radio"
        name={name || "radio-inline"}
        checked={checked}
      />
      <span class="custom-control-label">{label}</span>
    </label>
  );
};

export const useRadioViewButtons = function useRadioViewButtons(
  startView,
  { groupDate = false } = {}
) {
  const [view, setView] = useState(startView || "summary");

  const render = [
    <span style={{ marginRight: 32 }}>Select View </span>, 
    <Radio
      label="Summary"
      checked={view === "summary"}
      onClick={() => setView("summary")}
    />,
    groupDate && (
      <Radio
        label="Date"
        checked={view === "date"}
        onClick={() => setView("date")}
      />
    ),
    <Radio
      label="Detail"
      checked={view === "detail"}
      onClick={() => setView("detail")}
    />,
    <span style={{ width: 60 }}> </span>
  ];

  return [view, render];
};
