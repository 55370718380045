import React from "react";
import _ from "lodash";

function SumamryPaymentType(props) {
  return (
    <div className="card card-table">
      <div className="card-header p-0 m-0">
        {/* Striped Tables */}
        <div className="m-4 pt-4">
          <h3 className="m-0">Payment Type</h3>
        </div>
      </div>
      <div className="card-body">
        <table className="table table-striped table-borderless">
          <thead>
            <tr>
              <th>Name</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody className="no-border-x">
            {_.map(props.sumPaymentType, (amount, name) => {
              return (
                <tr>
                  <th scope="row">{name}</th>
                  <td>
                    <span style={{ color: "blue", fontWeight: "bold" }}>
                      {amount.toLocaleString()}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SumamryPaymentType;
