import React, { useState } from "react";
import storeInit from "../PosCart/store";
import * as actions from "../PosCart/actions";
import { Provider } from "react-redux";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import PosPaymentMain from "./PosPaymentMain";
import Numpad from "./Numpad";
import PaymentType from "./PaymentType";
import PosCartSummary from "../PosCart/Summary";
import {
  subTotalAmountSelector,
  grandTotalSelector
} from "../PosCart/selector";
import * as settingSelector from "../../shared/selector/settingSelector";
import { connect } from "react-redux";
import _ from "lodash";
import * as spaSalesSelector from "../../shared/selector/spaSalesSelector";

function PosPayment(props) {
  const [store, setStore] = useState(storeInit);
  const [amount, setAmount] = useState(0);
  const [numInput, setNumInput] = useState("");
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [stopRender, setStopRender] = useState(false);

  const getChange = () => {
    if (amount === "") {
      return "";
    }
    let change =
      parseFloat(amount) -
      (parseFloat(grandTotalSelector(store.getState())) || 0);

    return { string: change.toLocaleString(), int: change };
  };

  function payment() {
    if (getChange().int < 0) {
      return swal(
        "จำนวนเงินไม่ครบ",
        "จำนวนเงินที่ได้รับไม่เพียงพอต่อราคาสินค้า",
        "error"
      );
    }
    let data = {
      received: amount,
      change: getChange().int
    };

    data.paymentTypes = computePaymentType();

    store.dispatch(
      actions.payment(() => {
        props.history.push("/spa/0");

        swal("Payment Success.", "บันทึกสำเร็จ", "success");
      }, data)
    );
  }

   //TODO:   code not good
  function computePaymentType() {
     
    //===Compute Payment Type===
    let tmpPaymentTypes = [];
    //Filter
    _.forEach(paymentTypes, line => {
      let tmpAmount = parseInt(line.amount);
      if (line.amount === "" || _.isNaN(tmpAmount)) return;
      tmpPaymentTypes.push({ ...line, amount: tmpAmount });
    });

    let newPaymentType = _.cloneDeep(tmpPaymentTypes);

    if (_.size(paymentTypes) === 1) {
      newPaymentType[0].amount -= getChange().int;
    } else {
      let sliced = tmpPaymentTypes.slice(0, paymentTypes.length - 1);
      let sum = 0;
      _.forEach(sliced, item => {
        sum += item.amount;
      });
      newPaymentType[paymentTypes.length - 1].amount = Math.abs(
        sum - getGrandTotal()
      );
    }
    return newPaymentType;
  }

  function getGrandTotal() {
    return grandTotalSelector(store.getState());
  }

  const PaymentCard = (props) => {
    return (
      <div className=" card card-flat">
        <div className="card-header card-header-divider">Payment</div>
        <div className="card-header">
          GrandTotal
          <span className="" style={{ float: "right" }}>
            {getGrandTotal()}
          </span>
        </div>

        <div className="card-body">
          <PaymentType
            amount={amount}
            setAmount={setAmount}
            {...props}
            setPaymentTypes={setPaymentTypes}
            grandTotal={getGrandTotal()}
            numInput={numInput}
            setNumInput={setNumInput}
            stopRender={stopRender}
            setStopRender={setStopRender}
          /> 
        </div>

        {getChange().int > 0 && (
          <div className="card-header" style={{ color: "blue" }}>
            Change
            <span className="" style={{ float: "right" }}>
              {/* 50000 */}
              {getChange().string}
            </span>
          </div>
        )}
      </div>
    );
  };

  const NumPadButtons = () => {
    return (
      <Numpad
        setAmount={char => {
          setNumInput(numInput + char);
          setStopRender(false);
        }}
        delAmount={() => {
          setNumInput(numInput.slice(0, numInput.length - 1));
          setStopRender(false);
        }}
        clear={() => {
          setStopRender(false);
          setNumInput("");
        }}
        enter={payment}
      />
    );
  };

  if (props.disableSummary) {
    return (
      <Provider store={store}>
        <div className="row m-0">
          <div className="col-6">
            <PaymentCard {...props} />
          </div>
          <div className="col-6">
            <NumPadButtons />
          </div>
        </div>
      </Provider>
    );
  }

  return (
    <Provider store={store}>
      <div className="row">
        <div className="col-8">
          <div className="card card-flat">
            <div className="row no-gutters">
              <div className="col-5">
                <PosCartSummary showSubmitButton={false} />
              </div>
              <div className="col-7  ">
                <PaymentCard />
              </div>

              {/*  BUTTON */}
              <div className="col-1" />
              <div className="col-3">
                {props.spaSave && (
                  <center>
                    <button
                      className="btn btn-space btn-secondary btn-big"
                      style={{ height: 48, borderRadius: 8, width: "90%" }}
                      onClick={props.spaSave}
                    >
                      <i
                        class="icon icon-left mdi mdi-check"
                        style={{ display: "inline", fontSize: 16 }}
                      />
                      Save
                    </button>
                  </center>
                )}
              </div>
              <div className="col-1" />

              <div className="col-7">
                <div
                  //   onMouseEnter={() => setIsGrandTotalHover(true)}
                  //   onMouseLeave={() => setIsGrandTotalHover(false)}
                  className="card-header pt-0 text-center card-header"
                  //   style={
                  //     isGrandTotalHover ? grandTotal_hoder_Style : grandTotalStyle
                  //   }
                >
                  <div className="row m-0">
                    <div className="col-6 p-0 pr-1">
                      <button
                        className="btn btn-space btn-secondary btn-big w-100"
                        style={{ height: 48, borderRadius: 8 }}
                        // onClick={payment}
                      >
                        <i
                          style={{ display: "inline", fontSize: 16 }}
                          class="icon icon-left mdi mdi-check"
                        />
                        Print Sattlement
                      </button>
                    </div>
                    <div className="col-6 p-0 pl-1">
                      <button
                        className="btn btn-space btn-secondary btn-big w-100"
                        style={{ height: 48, borderRadius: 8 }}
                        onClick={payment}
                      >
                        <i
                          style={{ display: "inline", fontSize: 16 }}
                          class="icon icon-left mdi mdi-check"
                        />
                        PAY
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>

        <div className="col-4">
          <NumPadButtons />
        </div>
      </div>
    </Provider>
  );
}

const mapStateToProps = state => {
  return {
    // subTotal: subTotalAmountSelector(state),
    paymentTypes: settingSelector.paymentTypeSelector(state),
    // agents: spaSalesSelector.agentOptionsSelector(state),
    // entertains: spaSalesSelector.entertainOptionsSelector(state)
  };
};

export default withRouter(connect(mapStateToProps)(PosPayment));
