import * as type from "../../types/spa.pos";
import moment from "moment";

const initState = {
  // place: null,
  // customer: null,
  member: null,
  items: null,
  payment: null,
  store: null,
  inventory: null,
  tax: null,
  therapist: null,
  therapistList: {},
  agent: null,
  orderId: "",
  refId: "",
  //----------------
  meta: null,
  //  { table: "A5",
  //   zone: "A", //uppercase
  //   startDate : '',
  //   endDate : ''}
  //----------------
  matchVoucher: null,

  jobUid: moment().toISOString(),
  voucherOwner: {},
  therapistMode: "single"
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case type.SET_SPA_POS_STEP_DATA:
      return { ...state, [action.key]: action.payload };
    case type.CLEAR_SPA_POS_STEP_DATA:
      return initState;
    case type.SPA_RESTORE_JOB_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
