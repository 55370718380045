import React, { useState, useEffect } from "react";
import _ from "lodash";

function MemberProduct(props) {
  const { receipts } = props;
  const [products, setProducts] = useState([]);

  useEffect(() => {
    computeProduct();
  }, [receipts]);

  const computeProduct = () => {
    let objProduct = {};
    _.forEach(receipts, rec => {
      _.forEach(rec.items, item => {
        if (!objProduct[item.uid]) {
          objProduct[item.uid] = {
            ...item,
            qty: 0,
            extendedPrice: 0
          };
        }
        objProduct[item.uid].qty += item.qty;
        objProduct[item.uid].extendedPrice += item.extendedPrice;
      });
    });
    let ordered = _.orderBy(objProduct, "extendedPrice", "desc");
    setProducts(ordered);
  };

  return (
    <div class="card card-table">
      <div class="card-body">
        <div>
          <table
            class="table table-striped table-borderless"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Qty</th>
                <th>Total</th>
              </tr>
            </thead>

            <tbody class="no-border-x">
              {_.map(products, item => {
                return (
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.qty}</td>
                    <td>{item.extendedPrice.toLocaleString()}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MemberProduct;
