import React, { useState, useEffect } from "react";
import _ from 'lodash' 
function useBlockFeatures( currentEmployee, employeeList   , location ) {
  const [blockPage, setBlockPage] = useState(false);
  useEffect(() => {




    if(employeeList.length === 0 ){
        return setBlockPage(false)
    }

    if(currentEmployee === null){
        return setBlockPage(true);
    } 

    if(currentEmployee.role === 'admin' || currentEmployee.role === undefined ){
        return setBlockPage(false)
    }


   


    let permissionPage = currentEmployee.permissionPage  

    if (permissionPage.all) {
        return setBlockPage(false)
    }


    
     
    let route = location.pathname.split("/")[1];

    let found = _.find(permissionPage, (value, key) => {
      return _.includes( route, key);
    });
    if (found) {
      return setBlockPage(false);
    }
    return setBlockPage(true);


  }, [currentEmployee, location]);
  return [
    blockPage,
    <div>
      <h1 class='mt-7 text-center'>Please use PIN with permission <br/> to access this Page.</h1>
    </div>
  ];
}

export default useBlockFeatures;
