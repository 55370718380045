import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import swal from "sweetalert";
import EmployeeLockBtn from "../shared/components/EmployeeLockBtn";
import { cognitoSignOut } from "../shared/awsAPIs/Cognito";
import ReactTooltip from "react-tooltip";

class Navbar extends Component {
  signOut = e => {
    e.preventDefault();
    swal({
      title: `Sign Out ?`,
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        Auth.signOut();
        window.location.href = "/";
      }
    });
    // cognitoSignOut();
    // window.location.href = "/";
  };
  render() {
    const {
      user,
      shop,
      hqDetail,
      actions,
      pendingPushTransactions,
      businessDate
    } = this.props;
    let logoUrl = shop.logoUrl || hqDetail.logoUrl;
    return (
      <nav className="navbar navbar-expand fixed-top be-top-header">
        <div className="container-fluid">
          <div className="be-navbar-header">
            {logoUrl ? (
              <img
                src={logoUrl}
                alt="Avatar"
                style={{
                  paddingTop: 6,
                  paddingBottom: 6,
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
                className="center"
                height={60}
              />
            ) : (
              <div />
            )}

            {/* <a className="navbar-brand" href="index.html"></a> */}
          </div>
          <div className="page-title d-flex">
            <div class="">
              <span>
                {shop.name || "Loading..."} - {shop.branchId || ""}
              </span>
            </div>

            {businessDate && (
              <div class="ml-5">
                <div>
                  <h4 class="mt-3 mb-0">
                    {moment(businessDate).format("DD/MM/YYYY")}
                  </h4>
                </div>
                <div style={{ color: "gray", fontSize: "0.85em" }}>
                  <h6 class="mt-1 mb-0">Business Date</h6>
                 
                </div>
              </div>
            )}
 {businessDate && (
              <div class="ml-1">
                
                <div style={{ color: "gray", fontSize: "0.85em" }}>
                   {(moment().diff( moment(businessDate), 'hours' ) >= 30  ) && <h4 class='' style={{ color : 'white', backgroundColor : 'red' }}>It's running previuos date. PLEASE CLOSE SHOP BEFORE USING.</h4> }    

                </div>
              </div>
            )}
          </div>
          <div className="be-right-navbar">
            <ul className="nav navbar-nav float-right be-user-nav align-items-baseline">
              <li className="nav-item align-self-center  show mr-1">
                <div className="text-right">
                  {/* <a data-tip data-for="happyFace">
                    {" "}
                    d(`･∀･)b{" "}
                  </a> */}
                  <ReactTooltip id="happyFace" type="dark">
                    <span>Pending transactions to be sent..</span>
                  </ReactTooltip>
                  {pendingPushTransactions > 0 && (
                    <a
                      class="badge badge-pill badge-primary"
                      data-tip
                      data-for="happyFace"
                      style={{ color: "white", width: 35 }}
                    >
                      {(pendingPushTransactions || 0).toLocaleString()}
                    </a>
                  )}
                </div>
              </li>

              <EmployeeLockBtn />
              <InfoSlot label="Username" text={(user && user.username) || ""} />

              {/* <li className="nav-item align-self-center  show ">
                <div className="text-right">
                  {(user && user.firstName) || ""}{" "}
                  {(user && user.lastName) || ""}
                </div>
              </li> */}
              <li
                style={{ marginRight: 8, marginLeft: 8 }}
                className="ml-3 nav-item dropdown show"
              >
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  data-toggle="dropdown"
                  role="button"
                  aria-expanded="true"
                >
                  <img
                    src={user.profileImg || `/assets/img/avatar.png`} //TODO: ใส่รูปจริง
                    alt="Avatar"
                    key={moment().toISOString() + "1"}
                  />

                  <span
                    className="user-name"
                    style={{ textTransform: "uppercase" }}
                  >
                    .....
                  </span>
                </a>
              </li>

              {/* <li className="nav-item show" style={{ cursor: "pointer" }}>
                <EmployeeLockBtn />
              </li> */}
              <li
                className="nav-item  ml-3 show"
                style={{ cursor: "pointer" }}
                onClick={e => this.signOut(e)}
                // style={{ marginRight: 12 }}
              >
                <button class="btn btn-space btn-danger">
                  <span className="icon mdi mdi-power" /> Logout
                </button>
              </li>
            </ul>

            {/* PASTE BACK HERE */}

            {/* <div id="version">abc</div> */}
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.loggedIn,
    shop: state.shop.detail,
    hqDetail: state.shop.hqDetail,
    pendingPushTransactions: state.appState.pendingPushTransactions,
    businessDate: state.appState.businessDate || null
  };
};

// function mapDispatchToProps(dispatch) {
//   let actions = bindActionCreators({ ...employeeActions }, dispatch);
//   return {
//     actions
//   };
// }

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(Navbar);

function InfoSlot({ label, text = "" }) {
  return (
    <li class="nav-item align-self-center  show">
      <div>
        {/* class="ml-5 mr-3" */}
        <h4 class="mt-1 mb-0">{text}</h4>
      </div>
      <div style={{ color: "gray", fontSize: "0.85em" }}>
        <h6 class="mt-1 mb-0">{label}</h6>
      </div>
    </li>
  );
}

function Lock({ label, text = "" }) {
  return (
    <li class="nav-item align-self-center ml-3 mr-3 btn btn-space btn-secondary hover show">
      <div class="d-flex pl-4 pr-4 ">
        <div
          class="d-flex mr-1"
          style={{ flexDirection: "column", justifyContent: "center" }}
        >
          <i style={{fontSize : 24}} class="icon icon-left mdi mdi-lock-outline" />
        </div>
        <div
          style={{
            height: 46,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end"
          }}
        >
          <div>
            {/* class="ml-5 mr-3" */}
            <h4 class="mt-1 mb-0">{text}</h4>
          </div>
          <div style={{ color: "gray", fontSize: "0.85em" }}>
            <h6 class="mt-1 mb-0">{label}</h6>
          </div>
        </div>
      </div>
    </li>
  );
}
