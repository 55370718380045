import * as type from "../../types/spa.room";

const reducer = (state = [], action) => {
  switch (action.type) {
    case type.FETCH_SPA_ROOM:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
